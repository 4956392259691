import React, {
  useState, useEffect, useCallback, useRef, memo,
} from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { List, AutoSizer } from 'react-virtualized';
import {
  values,
  keys,
  filter,
  find,
  toLower,
  includes,
} from 'lodash';

import useDebounce from '../../hooks/useDebounce';
import isNumeric from '../../lib/isNumeric';

const InputDropdown = ({
  items,
  onChange,
  selected,
  placeholder,
  defaultSorting,
  readOnly,
  onBlurPost,
  name,
  rowHeight,
  dropdownListHeightPx,
  getAll,
  hasGetAllFunctionality,
  propsValue,
}) => {
  const [listHeight, setListHeight] = useState(dropdownListHeightPx);

  const [filtered, setFiltered] = useState([]);
  const [dropDownVisibility, setDropDownVisibility] = useState(false);

  const [filterValue, setFilterValue] = useState('');

  const debouncedSearchItem = useDebounce(filterValue, 200);

  const textInput = useRef();

  const calculateCurrentHeight = useCallback((listLength = 0) => {
    const currentHeight = listLength * rowHeight;

    const calculatedHeight = dropdownListHeightPx > currentHeight
      ? currentHeight
      : dropdownListHeightPx;

    setListHeight(listLength ? calculatedHeight : 30);
  }, []);

  const setFilteredItems = useCallback((value) => {
    let filteredItems = defaultSorting ? [...values(items)
      .sort()] : [...values(items)];
    filteredItems = filter(filteredItems, i => includes(toLower(i), toLower(value)));

    setFiltered(filteredItems);
  }, [items]);

  useEffect(() => {
    setFilteredItems(filterValue);
  }, [items, debouncedSearchItem]);

  useEffect(() => {
    calculateCurrentHeight(filtered.length);
  }, [filtered]);

  const getKeyByValue = useCallback(
    (object, value) => find(keys(object), key => object[key] === value),
    [],
  );

  const setChange = val => () => {
    const value = getKeyByValue(items, val);
    const correctValue = isNumeric(value) ? parseInt(value) : value;

    onChange(correctValue);

    if (onBlurPost && propsValue !== correctValue) {
      onBlurPost({
        name,
        value: correctValue,
      });
    }

    setFilterValue('');
    setDropDownVisibility(false);
  };

  const onToggle = useCallback((state) => {
    if (readOnly) {
      return;
    }

    if (!state) {
      setFilterValue('');
      textInput.current.blur();
    }
    setDropDownVisibility(state);
  }, [readOnly]);

  const getAllItems = useCallback(() => {
    getAll();
    setDropDownVisibility(false);
  }, []);

  const onChangeInput = useCallback(e => setFilterValue(e.target.value), []);

  const renderRow = ({ index, key, style }) => (
    <div
      title={filtered[index]}
      key={`dropdown-item-${name + key + 1}`}
      className={`dropdown-item dropdown-input__menu-item ${items[selected] === filtered[index] ? 'item-active' : ''}`}
      style={style}
      onClick={setChange(filtered[index])}
    >
      {filtered[index]}
    </div>
  );

  return (
    <Dropdown
      className={`dropdown-input ${readOnly ? 'read-only' : ''} dropdown-input-${name}`}
      onToggle={onToggle}
      show={dropDownVisibility}
    >
      <Dropdown.Toggle
        as="div"
        className="dropdown-input__toggle"
        title={items[selected]}
      >
        <If condition={filterValue.length === 0}>
          <input
            className={`dropdown-selected ${!selected ? 'placeholder' : ''}`}
            value={selected ? items[selected] : placeholder}
            disabled
          />
        </If>
        <input
          className="dropdown-search"
          type="text"
          value={filterValue}
          onChange={onChangeInput}
          ref={textInput}
        />
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-input__menu" style={{ height: listHeight + 2 }}>
        <Choose>
          <When condition={filtered.length > 0}>
            <If condition={hasGetAllFunctionality && selected}>
              <span
                className="dropdown-item dropdown-input__menu-item dropdown-input__menu-item--show-all"
                onClick={getAllItems}
              >
                Show all
              </span>
            </If>
            <AutoSizer>
              {({ height, width }) => (
                <List
                  // TODO: move logic with calculation height to method
                  height={hasGetAllFunctionality && selected ? height - 42 : height - 2}
                  rowCount={filtered.length}
                  rowHeight={rowHeight}
                  rowRenderer={renderRow}
                  width={width - 2}
                />
              )}
            </AutoSizer>
          </When>
          <Otherwise>
            <div className="dropdown-message">
              No results found.
            </div>
          </Otherwise>
        </Choose>
      </Dropdown.Menu>
    </Dropdown>
  );
};

InputDropdown.defaultProps = {
  onBlurPost: null,
  rowHeight: 35,
  readOnly: false,
  defaultSorting: true,
  placeholder: '',
  name: 'default-input',
  selected: null,
  dropdownListHeightPx: 300,
  getAll: () => {
  },
  hasGetAllFunctionality: false,
  propsValue: null,
};

InputDropdown.propTypes = {
  items: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.string,
  rowHeight: PropTypes.number,
  placeholder: PropTypes.string,
  defaultSorting: PropTypes.bool,
  readOnly: PropTypes.bool,
  hasGetAllFunctionality: PropTypes.bool,
  onBlurPost: PropTypes.func,
  name: PropTypes.string,
  dropdownListHeightPx: PropTypes.number,
  getAll: PropTypes.func,
  propsValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default memo(InputDropdown);
