import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { withRouter } from 'react-router-dom';

import CreateTransactionPage from '../../../components/Pages/Transactions/CreateTransactionPage';
import { DefaultChildHeader } from '../../../components/HOC/headerHelper';
import {
  beneficiary, account, transactionsValidationSchema, readOnlyPropertiesTable,
} from '../../../schemes/forms/transactions';
import transactionsActions from '../../../actions/transactions';

const {
  getTransactionDetails,
} = transactionsActions.transactions;

const mapStateToProps = (state) => {
  const data = state.getIn(['transactions', 'details']);

  return {
    grid: [beneficiary, account],
    validationSchema: transactionsValidationSchema,
    fields: readOnlyPropertiesTable,
    data,
    one: {},
    toPage: 'Transactions ',
    toLink: '/transactions',
    path: 'Transaction info',
    formId: 'view-transaction',
    header: DefaultChildHeader,
    fetching: state.getIn(['transactions', 'details', 'fetching']),
  };
};

const mapDispatchToProps = dispatch => ({
  fetchGetData: data => dispatch(getTransactionDetails.action(data)),
  cleanData: () => {
    // dispatch(clearOneBeneficiary());
    // dispatch(clearOneAccount());
  },
});

export default connect(mapStateToProps,
  mapDispatchToProps)(withImmutablePropsToJS(withRouter(CreateTransactionPage)));
