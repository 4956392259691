import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-actions';
import { isEmpty } from 'lodash';

import { personalInfoFields } from '../../schemes/forms/companyInfo';
import { ownershipFields } from '../../schemes/forms/ownership';
import { accountActivityFields } from '../../schemes/forms/accountActivity';
import { prepareContentForToastr } from '../../helpers/other';
import userActions from '../user/userActions';

import {
  UPLOAD_FILE,
  USER_REGISTRATION,
  GET_REGISTRATION_INFO,
  UPDATE_REGISTRATION_INFO,
  CREATE_PASSWORD_LOGIC,
  CLEAN_REGISTRATION_DATA,
  CREATE_CONTACT,
  DELETE_CONTACT,
  DELETE_FILE,
  DOWNLOAD_FILE,
  CONFIRM_REGISTRATION,
} from '../../constants/constants';

import { apiCallAction } from '../actionHelpers';
import { apiCallActionHandler } from '../commonLogic/requestLogic';
import history from '../../lib/history';
import {
  notifCongratulationProfileRegister,
  notifCongratulationUserRegister,
  notifCreateCustomerContact,
  notifDeleteCustomerContact,
  notifUpdateCustomerField, notifUploadDoc, notifWarningRequiredFieldsAndDocsTitle
} from '../../helpers/notificatios';

const { logout } = userActions;

const actions = {
  uploadDocument: apiCallAction(UPLOAD_FILE),
  deleteDocument: apiCallAction(DELETE_FILE),
  downloadDocument: apiCallAction(DOWNLOAD_FILE),
  userRegistration: apiCallAction(USER_REGISTRATION),
  getRegistrationInfo: apiCallAction(GET_REGISTRATION_INFO),
  updateRegistrationInfo: apiCallAction(UPDATE_REGISTRATION_INFO),
  createPassword: apiCallAction(CREATE_PASSWORD_LOGIC),
  createContact: apiCallAction(CREATE_CONTACT),
  deleteContact: apiCallAction(DELETE_CONTACT),
  cleanRegistrationData: createAction(CLEAN_REGISTRATION_DATA),
  confirmRegistration: apiCallAction(CONFIRM_REGISTRATION),
};

const uploadDocumentLogic = apiCallActionHandler({
  name: 'uploadDocument',
  fetchAction: actions.uploadDocument,
  makeApiCall(payload, { api }) {
    return api.registration.uploadDocument(payload);
  },
  onSuccess(response, { fileName }) {
    toastr.success('Success', notifUploadDoc(fileName));
  },
  transformResponse({ data }, { documentTypeId, fileName, description = null }, { getState }) {
    return {
      documentTypeList: getState().getIn(['lists', 'documentTypes', 'data']),
      data: {
        id: data,
        documentType: documentTypeId,
        fileName,
        description,
      },
    };
  },
});

const downloadDocumentLogic = apiCallActionHandler({
  name: 'downloadDocument',
  fetchAction: actions.downloadDocument,
  makeApiCall(payload, { api }) {
    return api.registration.downloadDocument(payload);
  },
  transformResponse(response) {
    return response;
  },
});

const deleteDocumentLogic = apiCallActionHandler({
  name: 'deleteDocument',
  fetchAction: actions.deleteDocument,
  makeApiCall(payload, { api }) {
    return api.registration.deleteDocument(payload);
  },
  onSuccess(response, payload) {
    toastr.success('Success', notifDeleteCustomerContact(payload), { timeOut: 10000 });
  },
  transformResponse(response, payload) {
    return payload;
  },
});

const registrationLogic = apiCallActionHandler({
  name: 'userRegistration',
  fetchAction: actions.userRegistration,
  makeApiCall({ values }, { api }) {
    return api.registration.userRegistration(values);
  },
  onSuccess() {
    toastr.success('Success',
      notifCongratulationUserRegister,
      { timeOut: 5000 });
  },
});

const createPasswordLogic = apiCallActionHandler({
  name: 'createPassword',
  fetchAction: actions.createPassword,
  makeApiCall(payload, { api }) {
    return api.registration.createPassword(payload);
  },
  onSuccess({ resultMessage }) {
    toastr.success('Success', resultMessage);
    const redirectPath = '/auth/login';
    history.push(redirectPath);
  },
});

const getRegistrationInfoLogic = apiCallActionHandler({
  name: 'getRegistrationInfo',
  fetchAction: actions.getRegistrationInfo,
  makeApiCall(payload, { api }) {
    return api.registration.getRegistrationInfo(payload);
  },
  transformResponse(response) {
    if (response && response.data) {
      const { contacts, enduser: { address, ...item }, ...rest } = response.data;

      const transformContacts = contacts.map(({ contactAddress, ...item }) => {
        return { ...contactAddress, ...item };
      });

      return {
        contacts: transformContacts,
        enduser: { ...address, ...item },
        ...rest,
      };
    }

    return response;
  },
});

const confirmRegistrationLogic = apiCallActionHandler({
  name: 'confirmRegistration',
  fetchAction: actions.confirmRegistration,
  makeApiCall(payload, { api }) {
    return api.registration.confirmRegistration(payload);
  },
  transformResponse(response) {
    return response;
  },
  onSuccess({ data }, payload, { dispatch, getState }) {
    const { documents, fields } = data;

    if (isEmpty(documents) && isEmpty(fields)) {
      toastr.success(
        'Success',
        notifCongratulationProfileRegister,
        {
          timeOut: 15000,
        },
      );
      dispatch(logout());
      history.push('/auth/login');
      return;
    }

    const registrationFields = [
      ...personalInfoFields,
      ...ownershipFields,
      ...accountActivityFields,
    ];
    const documentsList = getState()
      .getIn(['lists', 'documentTypes', 'data'])
      .toJS();

    const emptyFields = fields.map((item) => {
      const currentItem = registrationFields.find(elem => {
        return elem.id.toLowerCase() === item.toLowerCase();
      });
      return currentItem ? `\n ${currentItem.label}` : '';
    });

    const emptyDocuments = documents.map(item => {
      return documentsList[item];
    });
    toastr.warning(notifWarningRequiredFieldsAndDocsTitle,
      `${!isEmpty(emptyFields) ? 'Fields:' + emptyFields.toString() : ''}
        ${!isEmpty(emptyDocuments) ? 'Documents:' + emptyDocuments.toString() : ''}`, {
        timeOut: 15000,
      });
  },
});

const updateRegistrationInfoLogic = apiCallActionHandler({
  name: 'updateRegistrationInfo',
  fetchAction: actions.updateRegistrationInfo,
  makeApiCall(payload, { api }) {
    return api.registration.updateRegistrationInfo(payload);
  },
  // TODO: the same code as customer profile.
  onSuccess(response, { fieldName, value, objectName }, other) {
    const { lists } = other.getState()
      .toJS();

    let content = {
      name: '',
      value: '',
    };

    switch (objectName) {
      case 'enduser':
        content = prepareContentForToastr(personalInfoFields, fieldName, value, lists);
        break;
      case 'accountActivity':
        content = prepareContentForToastr(accountActivityFields, fieldName, value, lists);
        break;
      case 'contacts':
        content = prepareContentForToastr(ownershipFields, fieldName, value, lists);
        break;
      default:
      // code block
    }

    toastr.success('Success', notifUpdateCustomerField(content.name, content.value), { timeOut: 10000 });
  },
  transformResponse(res, data) {
    return data;
  },
});

const createContactLogic = apiCallActionHandler({
  name: 'createContact',
  fetchAction: actions.createContact,
  makeApiCall(payload, { api }) {
    return api.registration.createContact(payload);
  },
  onSuccess({ id }) {
    toastr.success('Success', notifCreateCustomerContact(id), { timeOut: 10000 });
  },
});

// TODO: Delete duplicate
const deleteContactLogic = apiCallActionHandler({
  name: 'deleteContact',
  fetchAction: actions.deleteContact,
  makeApiCall(payload, { api }) {
    return api.registration.deleteContact(payload);
  },
  onSuccess(response, payload, other) {
    const {
      registration: {
        registrationInfo: {
          data: {
            contacts,
          },
        },
      },
    } = other.getState()
      .toJS();
    const { firstName, lastName } = contacts.find(item => item.id === payload);
    toastr.success('Success', notifDeleteCustomerContact(firstName, lastName), { timeOut: 10000 });
  },
  transformResponse(res, data) {
    return data;
  },
});

export const logics = [
  uploadDocumentLogic,
  registrationLogic,
  getRegistrationInfoLogic,
  updateRegistrationInfoLogic,
  createPasswordLogic,
  createContactLogic,
  deleteContactLogic,
  deleteDocumentLogic,
  downloadDocumentLogic,
  confirmRegistrationLogic,
];

export default actions;
