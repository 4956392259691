import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import LoginForm from '../../components/Auth/LoginForm';

import userActions from '../../actions/user';

const { loginUser } = userActions.user;

const mapStateToProps = state => ({
  fetching: state.getIn(['userInfo', 'fetching']),
});

export default connect(mapStateToProps, {
  login: loginUser.action,
})(withImmutablePropsToJS(LoginForm));
