import React from 'react';
import { customFilter, textFilter } from 'react-bootstrap-table2-filter';

import CustomSelectFilter from '../../components/CustomTable/CustomFilter';
import selectionTransactionFormatterContainer
  from '../../containers/Modals/SelectionTransactionFormatterContainer';
import selectionTransactionFormatterHeaderContainer
  from '../../containers/Modals/SelectionTransactionFormatterHeaderContainer';
import {
  sortHeaderFormatter,
  dateFormatter,
  priceFormatter,
  defaultFormatter,
} from '../tableUtils/tablesUtils';
import TransactionsStatusFormatter from '../tableUtils/TransactionFormatters/TransactionsStatusFormatter';
import TransactionIdFormatter from '../tableUtils/TransactionFormatters/TransactionIdFormatter';
import customSelectionFilter from '../customSelectionFilter';
import { convertDateToLocaleFormat } from '../../helpers/dates';
import { currencyFormatter } from '../../helpers/other';
import { selectUniqValues } from '../../helpers/status';

const transactionsColumns = (currentData, userRole) => {
  const transactionsStatuses = selectUniqValues(currentData, 'status', {}, [
    'Pending',
    'Canceled',
    'Processing',
    'Approved',
    'Declined',
    'Exception',
    'Failed',
  ]);
  const processingTypes = selectUniqValues(currentData, 'processingType');
  const urgentTypes = selectUniqValues(currentData, 'isUrgent', {
    false: 'Unurgent',
    true: 'Urgent',
  });

  const fields = [
    {
      dataField: 'displayTransactionId',
      text: 'Id',
      sort: true,
      formatter: TransactionIdFormatter,
      headerStyle: () => ({ width: '15%' }),
      wpx: 200,
      pdfWidth: 50,
      headerClasses: 'with-filter',
      filter: customFilter({
        onFilter: (filterObj, data) => {
          if (filterObj.type === 'select') {
            if (filterObj.value === '') {
              return data;
            }

            return customSelectionFilter({
              type: 'select',
              value: filterObj.value !== 'Unurgent',
            }, data, 'isUrgent');
          }
          return customSelectionFilter(filterObj, data, 'displayTransactionId');
        },
      }),
      filterRenderer: (onFilter, column) => (
        <CustomSelectFilter
          onFilter={onFilter}
          column={column}
          selectItems={urgentTypes}
          formClass="custom-filter-status"
        />
      ),
    },
    {
      dataField: 'processDate',
      text: 'Process Date',
      sort: true,
      formatter: (cell, { id }) => dateFormatter(cell, id),
      headerFormatter: sortHeaderFormatter,
      headerStyle: () => ({ width: '6%' }),
      transformationItem: convertDateToLocaleFormat,
      wpx: 120,
    },
    {
      dataField: 'postDate',
      text: 'Post Date',
      sort: true,
      formatter: (cell, { id }) => dateFormatter(cell, `${id}_for_create_date`),
      headerFormatter: sortHeaderFormatter,
      headerStyle: () => ({ width: '6%' }),
      transformationItem: convertDateToLocaleFormat,
      wpx: 120,
    },
    {
      dataField: 'accountName',
      text: 'Account',
      sort: true,
      classes: 'main-field',
      formatter: defaultFormatter,
      headerFormatter: sortHeaderFormatter,
      headerStyle: () => ({ width: '9%' }),
      wpx: 140,
    },
    {
      dataField: 'beneficiaryName',
      text: 'Beneficiary',
      sort: true,
      formatter: defaultFormatter,
      headerFormatter: sortHeaderFormatter,
      headerStyle: () => ({ width: '9%' }),
      wpx: 120,
    },
    {
      dataField: 'accountCurrency',
      text: 'Account Currency',
      sort: true,
      filter: textFilter(),
      formatter: defaultFormatter,
      headerFormatter: sortHeaderFormatter,
      headerStyle: () => ({ width: '6%' }),
      wpx: 80,
    },
    {
      dataField: 'transactionCurrency',
      text: 'Transaction  Currency',
      sort: true,
      formatter: defaultFormatter,
      headerFormatter: sortHeaderFormatter,
      headerStyle: () => ({ width: '7%' }),
      wpx: 80,
    },
    {
      dataField: 'amount',
      text: 'Amount',
      sort: true,
      formatter: priceFormatter,
      headerFormatter: sortHeaderFormatter,
      headerStyle: () => ({ width: '5%' }),
      wpx: 80,
      transformationItem: currencyFormatter,
    },
    {
      dataField: 'processingType',
      text: 'Processing Type',
      sort: true,
      headerClasses: 'with-filter',
      formatter: defaultFormatter,
      headerStyle: () => ({ width: '8%' }),
      filter: customFilter({
        onFilter: (filterObj, data) => customSelectionFilter(filterObj, data, 'processingType'),
      }),
      filterRenderer: (onFilter, column) => (
        <CustomSelectFilter
          onFilter={onFilter}
          column={column}
          selectItems={processingTypes}
          formClass="custom-filter-processing-type"
        />
      ),
      wpx: 120,
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      headerClasses: 'with-filter',
      classes: 'status',
      headerStyle: () => ({ width: '7%' }),
      formatter: (cell, item) => (
        <TransactionsStatusFormatter cell={cell} item={item} userRole={userRole} />
      ),
      filter: customFilter({
        onFilter: (filterObj, data) => customSelectionFilter(filterObj, data, 'status'),
      }),
      filterRenderer: (onFilter, column) => (
        <CustomSelectFilter
          onFilter={onFilter}
          column={column}
          selectItems={transactionsStatuses}
          formClass="custom-filter-status"
        />
      ),
      wpx: 60,
    },
  ];

  const adminFields = [
    {
      dataField: 'processTransaction',
      text: 'processTransaction',
      headerClasses: 'selection-cell-header',
      classes: 'selection-cell',
      headerStyle: () => ({ width: '8%' }),
      headerFormatter: () => selectionTransactionFormatterHeaderContainer(currentData),
      formatter: selectionTransactionFormatterContainer,
      hidden: currentData && currentData.every(it => !(it && it.status === 'Pending' && it.isVerified)),
      isExport: false,
    },
  ];

  if (userRole === 'Admin') {
    return [...fields, ...adminFields];
  }

  return fields;
};

export default transactionsColumns;
