import { connect } from 'react-redux';

import RemoveTransactionFormatter from './RemoveSelectTransactionFormatter';
import feesChargesActions from '../../actions/feesCharges';

const { selectFee } = feesChargesActions.feesCharges;

const mapDispatchToProps = dispatch => ({
  removeTransaction: id => dispatch(selectFee({ addNew: false, key: id })),
});

export default connect(null, mapDispatchToProps)(RemoveTransactionFormatter);
