import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';

import userActivityActions from '../../actions/userActivity';
import RangeCalendar from '../../components/Layouts/RangeCalendar';
import Search from '../../components/Layouts/Search';
import ExportData from '../../components/Layouts/ExportData';
import Button from '../../components/generic/Button';
import { defaultDateRange } from '../../constants/constants';

const { dateFromPreviousHoursFormatted, dateToWithHours } = defaultDateRange;

const UserActivityHeader = ({
  rangeCalendarAction,
  onExport,
  fetchGetData,
  setUserActivityType,
  type,
  canExport,
}) => {
  useEffect(() => {
    return () => setUserActivityType('');
  }, []);

  return (
    <>
      <div className="breadcrumbs-block">
        <div>Admin / User Activity</div>

        <Button
          onClick={() => {
            setUserActivityType('');
            fetchGetData('');
          }}
          color="green"
          type="primary"
          size="sm"
          text="All"
          disabled={type === ''}
        />

        <Button
          onClick={() => {
            setUserActivityType('/GetImportant');
            fetchGetData('/GetImportant');
          }}
          color="green"
          type="primary"
          size="sm"
          text="Important"
          disabled={type === '/GetImportant'}
        />

        <Button
          onClick={() => {
            setUserActivityType('/GetErrors');
            fetchGetData('/GetErrors');
          }}
          color="green"
          type="primary"
          size="sm"
          text="Errors"
          disabled={type === '/GetErrors'}
        />
      </div>

      <div className="data_tools">
        <RangeCalendar
          rangeCalendarAction={rangeCalendarAction}
          type={type}
          customDefaultDate={
            {
              startDate: new Date(dateFromPreviousHoursFormatted),
              endDate: new Date(dateToWithHours),
            }
          }
          hasHours
        />

        <Search />

        <ExportData onExport={onExport} canExport={canExport} />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    type: state.getIn(['userActivity', 'type']),
  };
};

const { getUserActivity, setUserActivityType } = userActivityActions.userActivity;

const mapDispatchToProps = (dispatch) => {
  return {
    rangeCalendarAction: payload => dispatch(getUserActivity.action(payload)),
    fetchGetData: type => dispatch(getUserActivity.action({ type })),
    setUserActivityType: type => dispatch(setUserActivityType(type)),
  };
};

UserActivityHeader.propTypes = {
  rangeCalendarAction: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  setUserActivityType: PropTypes.func.isRequired,
  fetchGetData: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  canExport: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps,
  mapDispatchToProps)(withImmutablePropsToJS(UserActivityHeader));
