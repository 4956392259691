import React, { memo } from 'react';
import PropTypes from 'prop-types';

import TransactionPendingFormatter from '../../../containers/Modals/TransactionPendingFormatter';
import SearchContainer from '../../../containers/Modals/SearchContainer';
import InfoReason from '../../../components/Layouts/InfoReason';
import { confirmProcessOneTransaction } from '../../../helpers/notificatios';

const TransactionsStatusFormatter = ({
  cell, item, userRole,
}) => {
  const {
    status, id, isVerified, declineReason,
  } = item;
  const stat = status ? status.toString()
    .toLowerCase() : 'N/a';

  if (stat === 'pending') {
    return (
      <>
        <TransactionPendingFormatter
          processText={confirmProcessOneTransaction}
          id={id}
          isVerified={isVerified}
          declineReason={declineReason}
        />
      </>
    );
  }
  return (
    <div className={`status-block status-block__${stat}`}>
      <span className={`point point__${stat}`} />
      <SearchContainer text={cell} className="content" />
      {(declineReason && declineReason !== 'n/a' && userRole === 'Admin') && (
        <InfoReason
          declineReason={declineReason}
          id={id}
        />
      )}
    </div>
  );
};

export default memo(TransactionsStatusFormatter);

TransactionsStatusFormatter.propTypes = {
  cell: PropTypes.string.isRequired,
  item: PropTypes.shape({
    status: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    isVerified: PropTypes.bool.isRequired,
    declineReason: PropTypes.string.isRequired,
  }).isRequired,
  userRole: PropTypes.string.isRequired,
};
