import * as yup from 'yup';
import moment from 'moment';
import { latinFieldWithNumbersAndPunctuationMarks } from '../../helpers/fieldsValidation';

export const personalInfoFields = [
  {
    id: 'legalName',
    name: 'legalName',
    label: 'Legal Name',
    type: 'text',
    validation: true,
    gridArea: '1 / 1 / 2 / 4',
    required: true,
  }, {
    id: 'tradeName',
    name: 'tradeName',
    label: 'Trade Name',
    type: 'text',
    validation: true,
    gridArea: '1 / 4 / 2 / 7',
    required: true,
  }, {
    id: 'typeOfBusinessId',
    name: 'typeOfBusinessId',
    label: 'Type of Business',
    type: 'select',
    validation: true,
    gridArea: '2 / 4 / 2 / 7',
    required: true,
    list: 'businessTypes',
    defaultSorting: true,
  },{
    id: 'industryTypeId',
    name: 'industryTypeId',
    label: 'Industry Type',
    type: 'select',
    validation: true,
    gridArea: '2 / 1 / 2 / 4',
    required: true,
    list: 'industryTypes',
    defaultSorting: true,
  }, {
    id: 'addressLine1',
    name: 'addressLine1',
    label: 'Registered Company Address',
    type: 'geo',
    relativeFieldsForGeo: [
      { key: 'addressLine1', geoKey: 'address' },
      { key: 'postTown', geoKey: 'city' },
      { key: 'stateOrProvince', geoKey: 'state' },
      { key: 'countryId', geoKey: 'country', listId: 'countryId' },
      { key: 'postCode', geoKey: 'zip' },
    ],
    required: true,
    validation: true,
    gridArea: '3 / 1 / 3 / 7',
  },
  {
    id: 'countryId',
    name: 'countryId',
    label: 'Country',
    type: 'select',
    validation: true,
    gridArea: '4 / 1 / 4 / 3',
    required: true,
    list: 'countries',
    defaultSorting: true,
  },
  {
    id: 'postTown',
    name: 'postTown',
    label: 'City',
    type: 'text ',
    validation: true,
    gridArea: '4 / 3 / 4 / 4',
    required: true,
  },
  {
    id: 'stateOrProvince',
    name: 'stateOrProvince',
    label: 'State',
    type: 'text',
    validation: true,
    gridArea: '4 / 4 / 4 / 6',
    required: true,
  },
  {
    id: 'postCode',
    name: 'postCode',
    label: 'Zip',
    type: 'text',
    validation: true,
    gridArea: '4 / 6 / 4 / 7',
    required: true,
  },
  {
    id: 'email',
    name: 'email',
    label: 'E-mail',
    type: 'text',
    validation: true,
    gridArea: '5 / 1 / 5 / 3',
    required: true,
  },
  {
    id: 'skypeId',
    name: 'skypeId',
    label: 'Skype Id',
    type: 'text',
    validation: true,
    gridArea: '5 / 3 / 5 / 3',
  },
  {
    id: 'taxId',
    name: 'taxId',
    label: 'VAT/Tax ID',
    type: 'text',
    validation: true,
    gridArea: '5 / 4 / 5 / 5',
    required: true,
  },
  {
    id: 'webSite',
    name: 'webSite',
    label: 'Website',
    // placeholder: 'Select Salutation',
    type: 'text',
    validation: true,
    gridArea: '5 / 5 / 5 / 7',
  },
  {
    id: 'phone',
    name: 'phone',
    label: 'Phone Number',
    type: 'phone-number',
    validation: true,
    gridArea: '6 / 1 / 6/ 3',
    required: true,
  },
  {
    id: 'registrationNumber',
    name: 'registrationNumber',
    label: 'Company Registration Number',
    // placeholder: 'Select gender',
    type: 'text',
    validation: true,
    gridArea: '6 / 3 / 6 / 5',
    required: true,
  },
  {
    id: 'registrationDate',
    name: 'registrationDate',
    label: 'Company Registration Date',
    type: 'date',
    validation: true,
    gridArea: '6 / 5 /6 / 7',
    required: true,
    maxDate: new Date(moment().add(6, 'months').format()),
  },
  {
    id: 'detailedDescription',
    name: 'detailedDescription',
    label: 'Detailed Description of the Business',
    type: 'textarea',
    validation: true,
    gridArea: '7 / 1 / 7 / 7',
    rows: 1,
    cols: 100,
  },
];

export const adminUserPersonalInfoFields = [
  {
    id: 'operatorId',
    name: 'operatorId',
    label: 'Operator ID',
    type: 'text',
    validation: true,
    gridArea: '8 / 1 / 8 / 7',
  },
];

export const personalInfoValidationSchema = yup.object({
  legalName: latinFieldWithNumbersAndPunctuationMarks().trim().required('Required!'),
  tradeName: latinFieldWithNumbersAndPunctuationMarks().trim().required('Required!'),
  typeOfBusinessId: yup.number().required('Required!'),
  industryTypeId: yup.number().required('Required!'),
  addressLine1: yup.string().trim().required('Required!'),
  postTown: yup.string().trim().required('Required!'),
  stateOrProvince: yup.string().trim().required('Required!'),
  postCode: yup.string().trim().required('Required!'),
  countryId: yup.string().trim().required('Required!'),
  email: yup.string().trim().email('email address is not valid').required('Required!'),
  taxId: yup.string().trim().required('Required!'),
  registrationNumber: yup.string().trim().required('Required!'),
  registrationDate: yup.date().required('Required!'),
  phone: yup.string().required('Required!'),
  operatorId: yup.string().trim(),
});
