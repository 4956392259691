import React, { memo, useCallback, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { isEmpty } from 'lodash';

import { extractId } from '../../../lib/history';
import CustomSpinner from '../../Spinners/CustomSpinner';
import UniversalInputsForm from '../../generic/UniversalInputsForm';
import Button from '../../generic/Button';
import Header from '../../Layouts/Header';
import ReadOnlyPermission from '../../HOC/ReadOnlyPermissionHOC';
import { tooltipCantDeleteBeneficiary, notifBeneficiaryFields } from '../../../helpers/notificatios';

const BeneficiaryPage = (props) => {
  const {
    location: { pathname },
    history,
    editBeneficiary,
    submitForm,
    fetchGetData,
    header,
    data: { data, fetching },
    lists,
    toLink,
    toPage,
    path,
    formId,
    fields,
    beneficiaryTables,
    beneficiaryValidationSchema,
    beneficiaryType,
    switchBeneficiaryType,
    isViewMode,
    clearData,
    userRole,
    fetchSetStatus,
    setCorrectBeneficiarySchema,
  } = props;

  // const [stateBeneficiaryType, setStateBeneficiaryType] = useState(beneficiaryType);
  const { currencyId: currencyIdList, bankCountryId: bankCountryIdList } = lists;
  const [prepareFields, setPrepareFields] = useState(fields);
  const [bankCountryId, setBankCountryId] = useState('');
  const [currencyId, setCurrencyId] = useState(null);
  const id = extractId(pathname);

  useEffect(() => {
    if (editBeneficiary) {
      fetchGetData(id);
    }

    if (beneficiaryType === 'individual') switchBeneficiaryType('company');

    return () => {
      clearData();
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(data)) {
      setBankCountryId(data.bankCountryId);
      setCurrencyId(data.currencyId);
    }
  }, [data]);

  useEffect(() => {
    if (bankCountryId && !isEmpty(currencyIdList) && !isEmpty(bankCountryIdList)) {
      setPrepareFields(setCorrectBeneficiarySchema(currencyId, bankCountryId));
    }
  }, [currencyId, bankCountryId, currencyIdList, bankCountryIdList]);

  const HeaderTools = header;

  const onSubmitForm = (values) => {
    if (editBeneficiary) {
      submitForm({
        values,
        id,
      });
    } else {
      values.beneficiaryTypeId = beneficiaryType === 'company' ? 2 : 1;
      submitForm(values);
    }
  };

  const onChange = ({ name, value }) => {
    if (name === 'currencyId') {
      setCurrencyId(value);
    }
    if (name === 'bankCountryId') {
      setBankCountryId(value);
    }
  };

  const isDeleteButtonDisable = useMemo(
    () => (userRole === 'Admin' && data.statusId === 5) || (userRole === 'User' && data.statusId === 8),
    [data],
  );

  const deleteItem = useCallback(() => {
    const statusId = userRole === 'Admin' ? 5 : 8; // Hidden : Canceled
    fetchSetStatus({ id, statusId });
  }, [id, userRole]);

  return (
    <div className={`beneficiary-page full-height main_padding ${formId}`}>
      <Header>
        {HeaderTools && <HeaderTools toLink={toLink} toPage={toPage} path={path} />}
      </Header>
      <div className={`forms-page right-labels ${fetching ? 'spinner' : ''}`}>
        <If condition={formId === 'create-beneficiary'}>
          <div className={`switch-toggle ${!fetching ? 'content--show' : 'content--hide-without-transition'}`}>
            <h4 className="switch-toggle__title">Select Beneficiary Type:</h4>
            <div className="switch-toggle__content">
              <span className="first-switch">Company</span>
              <Form.Check
                type="switch"
                id="custom-switch"
                label="Individual"
                onChange={() => (beneficiaryType === 'company'
                  ? switchBeneficiaryType('individual')
                  : switchBeneficiaryType('company'))}
              />
            </div>
          </div>
        </If>
        <CustomSpinner fetching={fetching} />
        <div className={`page-content-container ${!fetching ? 'content--show' : 'content--hide'}`}>
          <If condition={formId === 'create-beneficiary'}>
            <UniversalInputsForm
              className={beneficiaryType === 'company' ? 'company' : 'individual'}
              multiForm={beneficiaryTables}
              validationSchema={beneficiaryValidationSchema}
              fields={prepareFields}
              onSubmit={onSubmitForm}
              formId={formId}
              additionalHandler={onChange}
              withLabel
              propsData={editBeneficiary ? data : {}}
              lists={lists}
            />
          </If>
          <If condition={formId === 'edit-beneficiary'}>
            <UniversalInputsForm
              multiForm={beneficiaryTables}
              validationSchema={beneficiaryValidationSchema}
              fields={prepareFields}
              onSubmit={onSubmitForm}
              formId={formId}
              additionalHandler={onChange}
              withLabel
              propsData={editBeneficiary ? data : {}}
              lists={lists}
            />
          </If>
          <div className="disclaimer">
            {notifBeneficiaryFields}
          </div>
          <div className="buttons-group">
            <If condition={!isViewMode}>
              <Button
                color="gray"
                type="primary"
                size="lg"
                submit
                form={formId}
                text="Save"
              />
            </If>
            <If condition={editBeneficiary && !isViewMode}>
              <ReadOnlyPermission
                tooltipId="delete-beneficiary-btn"
                declinePermissionText={tooltipCantDeleteBeneficiary}
                readOnlyComponent={(
                  <Button
                    data-tip="delete-beneficiary-btn"
                    data-for="delete-beneficiary-btn"
                    className="disabled"
                    color="gray"
                    type="primary"
                    size="lg"
                    text={`${userRole === 'Admin' ? 'Delete' : 'Disable'}`}
                  />
                )}
              >
                <Button
                  onClick={deleteItem}
                  color="gray"
                  type="primary"
                  size="lg"
                  text={`${userRole === 'Admin' ? 'Delete' : 'Disable'}`}
                  disabled={isDeleteButtonDisable}
                />
              </ReadOnlyPermission>
            </If>
            <Button
              onClick={history.goBack}
              color="gray"
              type="primary"
              size="lg"
              text="Cancel"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

BeneficiaryPage.defaultProps = {
  fetchGetData: null,
  fetchSetStatus: null,
  editBeneficiary: false,
  path: 'Beneficiares',
  beneficiaryType: '',
  switchBeneficiaryType: () => {},
  isViewMode: false,
  userRole: null,
};

BeneficiaryPage.propTypes = {
  data: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.string,
    PropTypes.array,
    PropTypes.bool,
  ])).isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  submitForm: PropTypes.func.isRequired,
  header: PropTypes.func.isRequired,
  lists: PropTypes.shape({
    currencyId: PropTypes.object,
    bankCountryId: PropTypes.object,
  }).isRequired,
  toLink: PropTypes.string.isRequired,
  userRole: PropTypes.string,
  toPage: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  path: PropTypes.string,
  fetchGetData: PropTypes.func,
  editBeneficiary: PropTypes.bool,
  fetchSetStatus: PropTypes.func,
  beneficiaryTables: PropTypes.arrayOf(PropTypes.object).isRequired,
  beneficiaryValidationSchema: PropTypes.shape().isRequired,
  beneficiaryType: PropTypes.string,
  switchBeneficiaryType: PropTypes.func,
  isViewMode: PropTypes.bool,
  clearData: PropTypes.func.isRequired,
};

export default memo(BeneficiaryPage);
