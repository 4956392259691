import { customFilter } from 'react-bootstrap-table2-filter';
import React from 'react';

import CustomSelectFilter from '../../components/CustomTable/CustomFilter';
import {
  sortHeaderFormatter,
  dateFormatter,
  defaultFormatter,
  customerStatusFormatter,
} from '../tableUtils/tablesUtils';
import CustomerNameFormatter from '../tableUtils/CustomerNameFormatter';
import customSelectionFilter from '../customSelectionFilter';
import { convertDateToLocaleFormat } from '../../helpers/dates';
import { selectUniqValues } from '../../helpers/status';

const customersColumns = (currentData, userRole) => {
  const customerStatuses = selectUniqValues(currentData, 'status');
  return (
    [{
      dataField: 'name',
      text: 'Name',
      sort: true,
      classes: 'main-field',
      formatter: (cell, { id, status }) => (
        <CustomerNameFormatter cell={cell} id={id} userRole={userRole} status={status} />
      ),
      headerFormatter: sortHeaderFormatter,
      headerStyle: () => ({ width: '18%' }),
      wpx: 160,
    }, {
      dataField: 'email',
      text: 'Email',
      sort: true,
      formatter: defaultFormatter,
      headerFormatter: sortHeaderFormatter,
      headerStyle: () => ({ width: '18%' }),
      wpx: 50,
    }, {
      dataField: 'createdDate',
      text: 'Created Date',
      sort: true,
      formatter: (cell, { id }) => dateFormatter(cell, `${id}-createdDate`),
      headerFormatter: sortHeaderFormatter,
      headerStyle: () => ({ width: '10%' }),
      transformationItem: cell => convertDateToLocaleFormat(cell),
      wpx: 100,
    }, {
      dataField: 'registrationDate',
      text: 'Company Registration Date',
      sort: true,
      formatter: (cell, { id }) => dateFormatter(cell, `${id}-registrationDate`),
      headerFormatter: sortHeaderFormatter,
      headerStyle: () => ({ width: '10%' }),
      transformationItem: cell => convertDateToLocaleFormat(cell),
      wpx: 100,
    }, {
      dataField: 'registrationNumber',
      text: 'Company Registration Number',
      sort: true,
      formatter: defaultFormatter,
      headerFormatter: sortHeaderFormatter,
      headerStyle: () => ({ width: '15%' }),
      wpx: 100,
    }, {
      dataField: 'status',
      text: 'Status',
      sort: true,
      headerClasses: 'with-filter',
      classes: 'status',
      headerStyle: () => ({ width: '11%' }),
      formatter: customerStatusFormatter,
      // formatter: defaultFormatter,
      filter: customFilter({
        onFilter: (filterObj, data) => customSelectionFilter(filterObj, data, 'status'),
      }),
      filterRenderer: (onFilter, column) => (
        <CustomSelectFilter
          onFilter={onFilter}
          column={column}
          selectItems={customerStatuses}
          formClass="custom-filter-status"
        />
      ),
      wpx: 70,
    }]
  );
};

export default customersColumns;
