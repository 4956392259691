import React, { memo, useState, useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';

import Button from '../generic/Button';
import UniversalInputsForm from '../generic/UniversalInputsForm';
import CustomSpinner from '../Spinners/CustomSpinner';
import { newAdjustmentFieldsValidationSchema } from '../../schemes/forms/newAdjustment';

// TODO: create function set fields to required and replace with that func

const AdjustmentModal = props => {
  const {
    isModalOpen,
    handleClose,
    title,
    modalId,
    submitAction,
    lists,
    fields,
    // validationSchema,
    id,
    fetching,
    transactionFees,
  } = props;

  const [chargeType, switchChargeType] = useState(false);

  const validationSchema = useMemo(() => newAdjustmentFieldsValidationSchema(transactionFees.data), [transactionFees]);

  const preparedList = useMemo(() => {
    const correctList = {
      currentFees: lists.currentFees,
      feeId: chargeType ? lists.chargeFees : lists.transactionsFees,
    };

    return correctList;
  }, [lists, chargeType]);

  const onSubmitForm = (formData) => {
    submitAction({
      ...formData,
      id,
    });
  };

  const onChange = ({ name, value }) => {
    switch (name) {
      case 'isCharge':
        switchChargeType(value);
        break;
    }
  };

  return (
    <Modal
      show={isModalOpen}
      onHide={handleClose}
      centered
      className={`custom-modal right-labels ${modalId}`}
    >
      <Modal.Header closeButton>
        <h3 className="custom-modal__title">{title}</h3>
      </Modal.Header>

      <Modal.Body>
        <div className="custom-modal__content">
          <CustomSpinner fetching={fetching} />
          <div className={`info-table ${!fetching ? 'content--show' : 'content--hide-without-transition'}`}>
            <UniversalInputsForm
              fields={fields}
              validationSchema={validationSchema}
              onSubmit={onSubmitForm}
              formId={modalId}
              withLabel
              propsData={{
                isCharge: chargeType,
              }}
              additionalHandler={onChange}
              lists={preparedList}
            />
          </div>
        </div>

      </Modal.Body>

      <Modal.Footer>
        <Button
          color="gray"
          type="primary"
          size="lg"
          submit
          form={modalId}
          text="Submit"
          disabled={fetching}
        />
        <Button
          onClick={handleClose}
          color="gray"
          type="primary"
          size="lg"
          text="Close"
        />
      </Modal.Footer>
    </Modal>
  );
};

AdjustmentModal.propTypes = {
  modalId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  fetching: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired,
  lists: PropTypes.objectOf(PropTypes.object).isRequired,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  // validationSchema: PropTypes.objectOf(PropTypes.oneOfType([
  //   PropTypes.object,
  //   PropTypes.number,
  //   PropTypes.string,
  //   PropTypes.array,
  //   PropTypes.bool,
  //   PropTypes.func,
  // ])).isRequired,
};

const review = (prevProps, newProps) => isEqual(prevProps, newProps);

export default memo(AdjustmentModal, review);
