import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { withRouter } from 'react-router-dom';

import UniversalModal from '../../components/Modals/UniversalModal';

import modalActions from '../../actions/modal';

const { closeModal, cleanModalData } = modalActions;

const mapStateToProps = (state) => {
  const {
    modalFormInfo, fetching, data,
  } = state.get('modal').toJS();

  return {
    userRole: state.getIn(['userInfo', 'data', 'role']),
    modalFormInfo,
    fetching,
    data,
    globalLists: state.get('lists').toJS(),
  };
};

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal()),
  cleanModalData: () => dispatch(cleanModalData()),
  dispatchAction: action => dispatch(action),
});

export default connect(mapStateToProps,
  mapDispatchToProps)(withImmutablePropsToJS(withRouter(UniversalModal)));
