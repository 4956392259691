import { createLogic } from 'redux-logic';
import { toastr } from 'react-redux-toastr';
import { deauthenticateUser } from '../localStorage';

export const errorHandling = (dispatch, action, errorPayload) => (e) => {
  const {
    name, message, statusCode, reasonPhrase,
  } = e;
  if (name === 'UserError') {
    if (statusCode === 401 || statusCode === 403) {
      toastr.warning('', 'Your authorization token has expired, please login.');
      deauthenticateUser();
    } else {
      toastr.error(
        reasonPhrase,
      );
      dispatch(action.error({
        message,
        errorPayload,
      }));
    }
  } else {
    dispatch(action.failure({
      message,
      errorPayload,
    }));
  }
};

export const apiCallActionHandler = ({
  name,
  fetchAction,
  makeApiCall,
  transformPayload,
  transformResponse,
  onFetch,
  onSuccess,
  onError,
  onDone,
  progress,
  preventSuccess,
  ...rest
}) => {
  if (!fetchAction) {
    throw new Error('"fetchAction" is undefined');
  } else if (!makeApiCall) {
    throw new Error('"makeApiCall" is undefined');
  }

  const { type } = fetchAction.action();

  return createLogic({
    name: name || type,
    type,
    async process({ getState, action, api }, dispatch, done) {
      let { payload } = action;

      if (transformPayload) payload = transformPayload(payload, { getState });

      dispatch(fetchAction.fetching(payload));

      if (onFetch) {
        await onFetch(payload, {
          dispatch,
          getState,
          action: fetchAction,
          done,
        });
      }

      try {
        const apiCall = makeApiCall(payload, { api });

        const res = await apiCall;

        let { data } = res;

        if (transformResponse) data = transformResponse(res, payload, { getState });
        if (onSuccess) {
          await onSuccess(data, payload, {
            dispatch,
            getState,
            action: fetchAction,
            done,
          });
        }

        if (preventSuccess) {
          if (onDone) {
            onDone({
              dispatch,
              getState,
              action: fetchAction,
              payload,
            });
          }

          done();
        } else {
          dispatch(fetchAction.success(data));
        }
      } catch (e) {
        if (onError) {
          onError(e, payload, {
            dispatch,
            getState,
            action: fetchAction,
          });
        }
        errorHandling(dispatch, fetchAction, payload)(e);
      } finally {
        if (onDone) {
          onDone({
            dispatch,
            getState,
            action: fetchAction,
            payload,
          });
        }

        done();
      }
    },
    ...rest,
  });
};
