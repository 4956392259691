import React, { useState } from 'react';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';

import { Modal } from 'react-bootstrap';
import classNames from 'classnames';

import accountsActions from '../../actions/accounts';
import listActions from '../../actions/lists';
import withLists from '../../components/HOC/withLists';
import CustomSpinner from '../../components/Spinners/CustomSpinner';
import DropZone from '../../components/generic/DropZone';
import Button from '../../components/generic/Button';

import { fileValidationForBatch } from '../../helpers/fieldsValidation';
import { batchFileFieldsValidationSchema, batchFileFields } from '../../schemes/forms/batchFile';
import UniversalInputsForm from '../../components/generic/UniversalInputsForm';

const {
  uploadBatchFile,
} = accountsActions.accounts;
const {
  getBatchPlatforms,
} = listActions.lists;

const UploadButchFile = (props) => {
  const {
    isModalOpen,
    handleClose,
    title,
    modalId,
    uploadBatch,
    fetching,
    defaultFile,
    validationSchema,
    lists,
    fields,
  } = props;

  const [{ status, fileName, content }, setFile] = useState(defaultFile);

  const submitUploadBatch = (formData) => {
    uploadBatch({
      ...formData,
      content,
    });
  };

  return (
    <Modal
      show={isModalOpen}
      onHide={handleClose}
      className={`custom-modal ${modalId}`}
    >
      <Modal.Header closeButton>
        <h3 className="custom-modal__title">{title}</h3>
      </Modal.Header>

      <Modal.Body>
        <div className="custom-modal__content">
          <CustomSpinner fetching={fetching} />
          <div
            className={`info-table ${!fetching ? 'content--show' : 'content--hide-without-transition'}`}
          >
            <UniversalInputsForm
              fields={fields}
              validationSchema={validationSchema}
              onSubmit={submitUploadBatch}
              formId={modalId}
              withLabel
              lists={lists}
            />
            <Choose>
              <When condition={content}>
                <div
                  className={classNames('uploaded', status)}
                >
                  <div className="document">
                    <i className="iconfont-file-all-format document_icon" />
                    <span className="document_name">{fileName}</span>
                    <div className="document_tools">
                      <i
                        className="iconfont-close remove_document"
                        onClick={() => setFile(defaultFile)}
                      />
                    </div>
                  </div>
                </div>
              </When>
              <Otherwise>
                <DropZone
                  fileType=".csv"
                  onDrop={setFile}
                  validationSchema={fileValidationForBatch}
                />
              </Otherwise>
            </Choose>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <If condition={content}>
          <Button
            color="gray"
            type="primary"
            size="lg"
            submit
            form={modalId}
            text="Upload"
            disabled={fetching}
          />
        </If>
        <Button
          onClick={handleClose}
          color="gray"
          type="primary"
          size="lg"
          text="Close"
        />
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  const { isModalOpen } = state.get('modal').toJS();
  const { batchPlatforms } = state.get('lists').toJS();

  return {
    requiredLists: [
      {
        data: batchPlatforms.data,
        action: getBatchPlatforms.action,
      },
    ],
    lists: {
      platformId: batchPlatforms.data,
    },
    fields: batchFileFields,
    validationSchema: batchFileFieldsValidationSchema,
    isModalOpen,
    modalId: 'upload-batch-file',
    fetching: state.getIn(['modal', 'fetching']),
  };
};

const mapDispatchToProps = dispatch => ({
  uploadBatch: payload => dispatch(uploadBatchFile.action(payload)),
});

export default connect(mapStateToProps,
  mapDispatchToProps)(withLists(withImmutablePropsToJS(UploadButchFile)));

UploadButchFile.propTypes = {
  modalId: PropTypes.string.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  fetching: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  uploadBatch: PropTypes.func.isRequired,
  defaultFile: PropTypes.shape({
    status: PropTypes.string,
    fileName: PropTypes.string,
    content: PropTypes.any,
    type: PropTypes.string,
  }),
  lists: PropTypes.objectOf(PropTypes.object).isRequired,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  validationSchema: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.string,
    PropTypes.array,
    PropTypes.bool,
    PropTypes.func,
  ])).isRequired,
};

UploadButchFile.defaultProps = {
  defaultFile: {
    status: null,
    fileName: null,
    content: null,
    type: null,
    id: '',
  },
};
