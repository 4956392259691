import ConfirmModalWithReason from '../components/Modals/ConfirmModalWithReason';
import UploadDocumentSelectModal from '../components/Modals/UploadDocumentSelectModal';
import UploadCustomDocumentModal from '../components/Modals/UploadCustomDocumentModal';

import accountsActions from '../actions/accounts';
import journalActions from '../actions/journal';
import transactionsActions from '../actions/transactions';
import feesChargesActions from '../actions/feesCharges';
import beneficiariesActions from '../actions/beneficiaries';
import registrationActions from '../actions/registration';
import customersActions from '../actions/customers';
import userActions from '../actions/user';
import adminUserActions from '../actions/adminUser';

import { newAccountFields, newAccountValidationSchema } from '../schemes/forms/newAccount';
import {
  viewAccountFields,
  editAccountFields,
  editAccountValidationSchema,
} from '../schemes/forms/Account';
import {
  createAdminUserFields,
  createUserFields,
  adminUserValidationSchema,
  userValidationSchema,
} from '../schemes/forms/newUser';
import { viewAdminUserFields, editAdminUserFields, editAdminUserValidationSchema } from '../schemes/forms/editAdminUser';

import { newFeeFields, newFeeValidationSchema, multiFields } from '../schemes/forms/newFee';

import FeesModalContainer from '../containers/Modals/FeesModalContainer';
import { filterStatuses } from '../helpers/status';
import {
  valuesForAccountStatus,
  valueForAdminUserStatus,
} from '../schemes/templatesForLists';
import EditJournalModal from '../containers/Modals/EditJournalModalContainer';
import AdjustmentModal from '../containers/Modals/CreateAdjustmentModalContainer';
import TransactionInvoiceModalContainer from '../containers/Modals/TransactionInvoiceModalContainer';
import MonthlyStatementsModalContainer from '../containers/Modals/MonthlyStatementsModalContainer';
import UploadBatchModalContainer from '../containers/Modals/UploadBatchModalContainer';
import DownloadBatchModalContainer from '../containers/Modals/DownloadBatchFileModal';
import CreateChatModalContainer from '../containers/Modals/CreateChatModalContainer';

const { declineOneFee } = feesChargesActions.feesCharges;
const { setChangeBeneficiaryStatus } = beneficiariesActions.beneficiaries;
const {
  getOneTransaction,
  declineOneTransaction,
} = transactionsActions.transactions;
const { getOneJournal } = journalActions.journal;
const {
  createAccount,
  editAccount,
  getOneAccount,
  setAccountStatusToCanceled,
} = accountsActions.accounts;
const { uploadDocument } = registrationActions.registration;
const { changeCustomerStatus, uploadCustomerDocument } = customersActions.customers;
const { changeUserStatus, createSubUserByAdmin, createSubUser } = userActions.user;
const {
  getOneAdminUser,
  editAdminUser,
  createAdminUser,
  changeAdminUserStatus,
} = adminUserActions.adminUser;

export default function getModalFormFields(formId = '', lists = [], userRole = '') {
  const {
    banks,
    currencies,
    customers,
    ledgerStatuses,
    ledgerTypes,
    moneyGradation,
  } = lists;

  switch (formId) {
    case 'create-account':
      return {
        fields: newAccountFields,
        validationSchema: newAccountValidationSchema(banks.data),
        title: 'New Account',
        lists: {
          platformId: banks.data,
          currencyId: currencies.data,
          enduserId: customers.data,
          statusId: ledgerStatuses.data,
          ledgerType: ledgerTypes.data,
        },
        submitAction: createAccount.action,
      };
    case 'edit-account':
      return {
        fields: editAccountFields,
        validationSchema: editAccountValidationSchema,
        title: 'Edit Account',
        obtainInformation: true,
        specialSubmitRequest: true,
        lists: {
          platform: banks.data,
          platformId: banks.data,
          currencyId: currencies.data,
          enduserId: customers.data,
          statusId: filterStatuses(valuesForAccountStatus, ledgerStatuses.data),
          ledgerType: ledgerTypes.data,
        },
        submitAction: editAccount.action,
        getAction: getOneAccount.action,
      };
    case 'view-account':
      return {
        fields: viewAccountFields,
        title: 'Account Info',
        obtainInformation: true,
        specialSubmitRequest: true,
        lists: {
          platform: banks.data,
          platformId: banks.data,
          currencyId: currencies.data,
          enduserId: customers.data,
          statusId: filterStatuses(valuesForAccountStatus, ledgerStatuses.data),
          ledgerType: ledgerTypes.data,
        },
        hideSubmitBtn: true,
        getAction: getOneAccount.action,
      };
    case 'create-admin-user':
      return {
        fields: createAdminUserFields,
        title: 'New Admin User',
        obtainInformation: true,
        specialSubmitRequest: true,
        validationSchema: adminUserValidationSchema,
        submitAction: createAdminUser.action,
      };
    case 'create-user':
      return {
        fields: createUserFields(userRole),
        title: 'New User',
        obtainInformation: true,
        specialSubmitRequest: true,
        validationSchema: userValidationSchema(userRole),
        submitAction: createSubUser.action,
      };
    case 'create-user-by-admin':
      return {
        fields: createUserFields(userRole),
        title: 'New User',
        obtainInformation: true,
        lists: {
          enduserId: customers.data,
        },
        specialSubmitRequest: true,
        validationSchema: userValidationSchema(userRole),
        submitAction: createSubUserByAdmin.action,
      };
    case 'edit-admin-user':
      return {
        fields: editAdminUserFields,
        validationSchema: editAdminUserValidationSchema,
        obtainInformation: true,
        specialSubmitRequest: true,
        lists: {
          statusId: filterStatuses(valueForAdminUserStatus, ledgerStatuses.data),
        },
        title: 'Edit Admin User',
        submitAction: editAdminUser.action,
        getAction: getOneAdminUser.action,
      };
    case 'view-admin-user':
      return {
        fields: viewAdminUserFields,
        obtainInformation: true,
        lists: {
          statusId: filterStatuses(valueForAdminUserStatus, ledgerStatuses.data),
        },
        hideSubmitBtn: true,
        title: 'Admin User Info',
        getAction: getOneAdminUser.action,
      };
    case 'create-adjustment':
      return {
        title: 'Create adjustment',
        customModal: AdjustmentModal,
        isCustomModal: true,
      };
    case 'upload-batch-file':
      return {
        title: 'Upload Batch File',
        customModal: UploadBatchModalContainer,
        isCustomModal: true,
      };
    case 'download-batch-file':
      return {
        title: 'Download Batch File',
        customModal: DownloadBatchModalContainer,
        isCustomModal: true,
      };
    case 'create-chat':
      return {
        title: 'New Chat',
        customModal: CreateChatModalContainer,
        isCustomModal: true,
      };
    case 'create-fee':
      return {
        fields: newFeeFields,
        validationSchema: newFeeValidationSchema,
        title: 'Fees',
        idInPathname: true,
        hideSubmitBtn: true,
        multiFields,
        customModal: FeesModalContainer,
        isCustomModal: true,
        lists: {
          ['Incoming Cross-Border Payment Over Amount']: moneyGradation.data,
          ['Incoming Local Payment Over Amount']: moneyGradation.data,
          ['Outgoing Cross-Border Payment Over Amount']: moneyGradation.data,
          ['Outgoing Local Payment Over Amount']: moneyGradation.data,
        },
      };
    case 'edit-journal':
      return {
        title: 'Journal',
        obtainInformation: true,
        specialSubmitRequest: true,
        customModal: EditJournalModal,
        isCustomModal: true,
        getAction: getOneJournal.action,
      };
    case 'transaction-info':
      return {
        title: 'Transaction status info',
        dataParsing: true,
        obtainInformation: true,
        hideSubmitBtn: true,
        closeBtnText: 'Close',
        getAction: getOneTransaction.action,
      };
    case 'response-body-info':
      return {
        title: 'Response body info',
        dataParsing: true,
        // obtainInformation: true,
        hideSubmitBtn: true,
        closeBtnText: 'Close',
        getAction: getOneTransaction.action,
      };
    case 'reason-transaction-modal':
      return {
        title: `Would you like to ${userRole === 'Admin' ? 'decline' : 'cancel'} this transaction?`,
        submitAction: declineOneTransaction.action,
        customModal: ConfirmModalWithReason,
        isCustomModal: true,
        submitBtnText: 'Confirm',
        closeBtnText: 'Close',
      };
    case 'reason-feesCharges-modal':
      return {
        title: 'Would you like to cancel this fee charge?',
        submitAction: declineOneFee.action, // declineOneTransaction.action,
        customModal: ConfirmModalWithReason,
        isCustomModal: true,
        submitBtnText: 'Confirm',
        closeBtnText: 'Close',
      };
    case 'reason-account-modal':
      return {
        title: 'Would you like to cancel this account?',
        submitAction: setAccountStatusToCanceled.action,
        customModal: ConfirmModalWithReason,
        isCustomModal: true,
      };
    // case 'reason-customer-modal':
    //   return {
    //     title: 'Would you like to inactive this customer?',
    //     submitAction: payload => changeCustomerStatus.action({
    //       ...payload,
    //       statusId: '12',
    //     }),
    //     customModal: ConfirmModalWithReason,
    //     isCustomModal: true,
    //   };
    case 'inactive-customer-modal':
      return {
        title: 'Would you like change status to Inactive?',
        submitAction: payload => changeCustomerStatus.action({
          ...payload,
          statusId: '12',
        }),
        customModal: ConfirmModalWithReason,
        isCustomModal: true,
      };
    case 'onHold-customer-modal':
      return {
        title: 'Would you like change status to OnHold?',
        submitAction: payload => changeCustomerStatus.action({
          ...payload,
          statusId: '11',
        }),
        customModal: ConfirmModalWithReason,
        isCustomModal: true,
      };
    case 'inactive-user-modal':
      return {
        title: 'Would you like change status to Inactive?',
        submitAction: payload => changeUserStatus.action({
          ...payload,
          statusId: '12',
        }),
        customModal: ConfirmModalWithReason,
        isCustomModal: true,
      };
    case 'onHold-user-modal':
      return {
        title: 'Would you like change status to OnHold?',
        submitAction: payload => changeUserStatus.action({
          ...payload,
          statusId: '11',
        }),
        customModal: ConfirmModalWithReason,
        isCustomModal: true,
      };
    case 'reason-admin-user-modal':
      return {
        title: 'Would you like to inactive this Admin user?',
        submitAction: payload => changeAdminUserStatus.action({
          ...payload,
          statusId: '12',
        }),
        customModal: ConfirmModalWithReason,
        isCustomModal: true,
      };
    case 'reason-beneficiary-modal':
      return {
        title: 'Would you like to cancel this beneficiary?',
        submitAction: setChangeBeneficiaryStatus.action,
        customModal: ConfirmModalWithReason,
        isCustomModal: true,
      };
    case 'identity-proof':
      return {
        title: 'Select one of proof of identity documents',
        submitAction: uploadDocument.action,
        customModal: UploadDocumentSelectModal,
        isCustomModal: true,
      };
    case 'residential-proof':
      return {
        title: 'Select one of proof of residential address of all the beneficial owners',
        submitAction: uploadDocument.action,
        customModal: UploadDocumentSelectModal,
        isCustomModal: true,
      };
    case 'other-document':
      return {
        title: 'Upload optional document and input description',
        submitAction: uploadDocument.action,
        customModal: UploadCustomDocumentModal,
        documentTypeId: '1',
        isCustomModal: true,
      };
    case 'identity-proof-customer':
      return {
        title: 'Select one of proof of identity documents',
        submitAction: uploadCustomerDocument.action,
        customModal: UploadDocumentSelectModal,
        isCustomModal: true,
      };
    case 'residential-proof-customer':
      return {
        title: 'Select one of proof of residential address of all the beneficial owners',
        submitAction: uploadCustomerDocument.action,
        customModal: UploadDocumentSelectModal,
        isCustomModal: true,
      };
    case 'other-document-customer':
      return {
        title: 'Upload optional document and input description',
        submitAction: uploadCustomerDocument.action,
        customModal: UploadCustomDocumentModal,
        documentTypeId: '1',
        isCustomModal: true,
      };

    case 'transaction-invoice':
      return {
        title: 'File preview',
        customModal: TransactionInvoiceModalContainer,
        // documentTypeId: '1',
        isCustomModal: true,
      };
    case 'monthly-statement':
      return {
        title: 'Monthly statements',
        customModal: MonthlyStatementsModalContainer,
        isCustomModal: true,
      };
    default:
      return {
        fields: [],
        title: '',
        text: '',
        lists: {},
        validationSchema: [],
      };
  }
}
