import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import UniversalInputsForm from '../../generic/UniversalInputsForm';
import Button from '../../generic/Button';
import CommentsContainer from '../../../containers/AccountSetup/CommentsContainer';

const AccountSetupStep = ({
  formName,
  validationSchema,
  onSubmit,
  fields,
  lists,
  hasPrevButton,
  hasNextButton,
  prevTab,
  isLastTab,
  propsData,
  customClass,
  fetchingFields,
  updateField,
  enduserId,
  isRegistrationTool,
}) => {
  const onBlurPost = useCallback(({ name, ...rest }) => {
    const correctValue = {
      ...rest,
      fieldName: name,
    };
    if (updateField) updateField(correctValue);
  }, [updateField]);

  return (
    <div className={`account-setup_step ${customClass || formName}`}>
      <div className="account-setup_step-container">
        <UniversalInputsForm
          fetchingFields={fetchingFields}
          onBlurPost={onBlurPost}
          propsData={propsData}
          validationSchema={validationSchema}
          fields={fields}
          onSubmit={onSubmit}
          formId={formName}
          withLabel
          lists={lists}
          className="account-setup_step_form"
        />
      </div>

      <div className="account-setup_step_footer">
        <If condition={hasPrevButton}>
          <Button
            color="gray"
            type="primary"
            className="prev"
            size="lg"
            onClick={prevTab}
            text="Previous"
          />
        </If>

        <If condition={hasNextButton}>
          <Button
            color="gray"
            type="primary"
            size="lg"
            submit
            form={formName}
            text="Next"
          />
        </If>

        <If condition={isLastTab}>
          <Button
            color="gray"
            type="primary"
            size="lg"
            submit
            form={formName}
            text="Submit"
          />
        </If>
      </div>
    </div>
  );
};

AccountSetupStep.defaultProps = {
  hasPrevButton: false,
  hasNextButton: false,
  isLastTab: false,
  onSubmit: null,
  prevTab: null,
  updateField: null,
  customClass: null,
  fetchingFields: [],
  propsData: {},
  lists: {},
  enduserId: '',
  isRegistrationTool: false,
};

AccountSetupStep.propTypes = {
  enduserId: PropTypes.string,
  lists: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.object,
  ])),
  formName: PropTypes.string.isRequired,
  validationSchema: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.string,
    PropTypes.array,
    PropTypes.bool,
    PropTypes.func,
  ])).isRequired,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  hasPrevButton: PropTypes.bool,
  hasNextButton: PropTypes.bool,
  isLastTab: PropTypes.bool,
  prevTab: PropTypes.func,
  onSubmit: PropTypes.func,
  updateField: PropTypes.func,
  customClass: PropTypes.string,
  propsData: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.string,
    PropTypes.array,
    PropTypes.bool,
  ])),
  fetchingFields: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.string,
    PropTypes.array,
    PropTypes.bool,
  ])),
  isRegistrationTool: PropTypes.bool,
};

export default AccountSetupStep;
