import * as yup from 'yup';

import { setFieldsToEdit } from '../../helpers/other';

export const loginFields = [
  {
    id: 'userName',
    name: 'userName',
    label: 'Name',
    type: 'text',
    placeholder: 'Email',
    validation: true,
  },
  {
    id: 'password',
    name: 'password',
    label: 'Password',
    placeholder: 'Password',
    type: 'password',
    validation: true,
  },
];

export const forgotPassFields = [
  {
    id: 'email',
    name: 'email',
    label: 'Enter Email',
    placeholder: 'Email Address',
    type: 'email',
    validation: true,
  },
];

export const newPassFields = [
  {
    id: 'password',
    name: 'password',
    label: 'New Password',
    placeholder: 'Password',
    type: 'password',
    validation: true,
  },
  {
    id: 'confirmPassword',
    name: 'confirmPassword',
    label: 'Confirm new Password',
    placeholder: 'Confirm Password',
    type: 'password',
    validation: true,
  },
];

const registrationFields = [
  {
    id: 'firstName',
    name: 'firstName',
    label: 'First Name',
    placeholder: 'First Name',
    type: 'text',
    validation: true,
  },
  {
    id: 'lastName',
    name: 'lastName',
    label: 'Last Name',
    placeholder: 'Last Name',
    type: 'text',
    validation: true,
  },
  {
    id: 'phoneNumber',
    name: 'phoneNumber',
    label: 'Phone Number',
    placeholder: 'Phone Number',
    type: 'phone-number',
    validation: true,
  },
  {
    id: 'email',
    name: 'email',
    label: 'Email Address',
    placeholder: 'Email Address',
    type: 'email',
    validation: true,
  },
];

export const registrationFieldsForNewUser = setFieldsToEdit(registrationFields);
export const registrationFieldsForSubUser = setFieldsToEdit(registrationFields, ['email']);

export const loginValidationSchema = yup.object({
  userName: yup.string().trim().required('Required!'),
  // email: yup.string().trim().required('Required!'),
  password: yup.string().required('Required!'),
});

export const forgotPassValidationSchema = yup.object({
  email: yup.string().trim().required('Required!'),
});

export const newPassValidationSchema = yup.object({
  password: yup.string().required('Required!')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase and One Number'
    ),
  confirmPassword: yup.string()
    .oneOf([yup.ref('password')], 'Passwords must match'),
});

export const registrationValidationSchema = yup.object({
  firstName: yup.string().required('Required!'),
  lastName: yup.string().required('Required!'),
  phoneNumber: yup.string().required('Required!'),
  email: yup.string().trim().email('email address is not valid').required('Required!'),
});
