import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import ForgotPassForm from '../../components/Auth/PasswordForm';
import userActions from '../../actions/user';
import {
  forgotPassFields,
  forgotPassValidationSchema,
} from '../../components/Auth/LoginFormConfig';


const { getLinkForRestorePassword } = userActions.user;

const mapStateToProps = (state) => {
  return {
    titleForm: 'Forgot Password',
    formId: 'forgot-password',
    submitBtnText: 'Send',
    validationSchema: forgotPassValidationSchema,
    fields: forgotPassFields,
    fetching: state.getIn(['userInfo', "fetching"]),
  };
};

export default connect(mapStateToProps, {
  submitForm: getLinkForRestorePassword.action,
})(withImmutablePropsToJS(ForgotPassForm));
