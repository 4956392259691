import React from 'react';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';

import userActions from '../../actions/user';
import Tooltip from '../../components/generic/Tooltip';
import { notifResetPassUser } from '../../helpers/notificatios';

const { getLinkForResetPassword } = userActions.user;

const ResetPasswordFormatterForUser = ({
  id, email, recoveryPassword, userInfoId, userRole, subRole,
}) => {
  const confirmModal = () => toastr.confirm(notifResetPassUser, {
    onOk: () => recoveryPassword({ id, email }),
  });

  const tipId = `${id}-reset-password`;

  return (
    <If condition={(userRole === 'Admin' && subRole !== 'ReadOnlyAdmin') || userInfoId === id || subRole === 'User'}>
      <i
        className="reset-password-formatter__icon iconfont-key clickable"
        onClick={confirmModal}
        data-tip={tipId}
        data-for={tipId}
      />
      <Tooltip id={tipId} text="Reset password" />
    </If>
  );
};

ResetPasswordFormatterForUser.propTypes = {
  email: PropTypes.string.isRequired,
  recoveryPassword: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  userInfoId: PropTypes.string.isRequired,
  subRole: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  userRole: state.getIn(['userInfo', 'data', 'role']),
  userInfoId: state.getIn(['userInfo', 'data', 'sub']),
  subRole: state.getIn(['userInfo', 'data', 'subRole']),
});

const mapDispatchToProps = dispatch => ({
  recoveryPassword: options => dispatch(getLinkForResetPassword.action(options)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withImmutablePropsToJS(ResetPasswordFormatterForUser));
