import React, { memo } from 'react';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';

import MenuItems from './MenuItems';
import { menuElementsByRoleSeparation } from '../../../router/routerItems/mainRoutes';

import logo from '../../../../img/gray_logo.png';


const SideMenu = ({ role, subRole }) => (
  <aside className="main-block__side-menu">
    <img src={logo} className="main-block__side-menu__logo hide-tablet" alt="logo" />
    <i className="hide-desktop iconfont-logo" />
    <nav className="main-block__side-menu__nav" id="nav__scrollbar">
      <ul>
        <MenuItems
          items={menuElementsByRoleSeparation(role, subRole)}
          role={role}
          subRole={subRole}
        />
      </ul>
    </nav>
  </aside>
);

SideMenu.propTypes = {
  role: PropTypes.string.isRequired,
  subRole: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    role: state.getIn(['userInfo', 'data', 'role']),
    subRole: state.getIn(['userInfo', 'data', 'subRole']),
  };
};

export default connect(mapStateToProps)(withImmutablePropsToJS(memo(SideMenu)));
