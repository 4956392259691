import * as yup from 'yup';

import {
  yupExceptionLatinFields,
  yupExceptionValidAmount,
  yupExceptionFileSizeLimit,
  yupExceptionLatinFieldWithNumbers,
  yupExceptionLettersFieldWithNumbersAndPunctuationMarks,
} from './notificatios';

const latinRegExp = /^[a-zA-Z- ]+(\s?[a-zA-Z])*$/;
const latinWithNumbersRegExp = /^[A-Za-z0-9]+$/;
const latinWithNumbersAndSpaceRegExp = /^[A-Za-z0-9 ]+$/;
const latinWithNumbersAndPunctuationMarksRegExp = /^[A-Za-z0-9]+(\s?[A-Za-z0-9.,/'%@_\-])*$/;

export const latinField = (errorText = yupExceptionLatinFields) => yup.string()
  .matches(latinRegExp, errorText);

export const latinFieldWithNumbers = (errorText = yupExceptionLatinFieldWithNumbers) => yup.string()
  .matches(latinWithNumbersRegExp, errorText);

export const latinFieldWithNumbersZip = (errorText = yupExceptionLatinFieldWithNumbers) => yup.string()
  .matches(latinWithNumbersAndSpaceRegExp, errorText);

export const latinFieldWithNumbersAndPunctuationMarks = (errorText = yupExceptionLettersFieldWithNumbersAndPunctuationMarks) => yup.string()
  .matches(latinWithNumbersAndPunctuationMarksRegExp, errorText);

export const moneyField = (errorText = 'Amount can\'t be 0 or empty') => yup.number()
  .typeError('Number is expected')
  .test('money', errorText, value => value !== 0);

export const transactionsMoneyField = (feeList, errorText = yupExceptionValidAmount) => yup.number()
  .test('money', errorText, function (value) {
    if (this.resolve(yup.ref('isCharge'))) {
      return value <= 0;
    }

    const currentFee = feeList[this.resolve(yup.ref('feeId'))];
    const isPositiveAmount = ['Adjustment', 'Incoming Cross-Border Payment', 'Incoming Local Payment'].includes(currentFee);

    return isPositiveAmount ? true : value <= 0;
  })
  .required('Required!');
const FILE_SIZE = 300000000; // 300MB
const fileSizeError = yupExceptionFileSizeLimit;

const FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/gif',
  'image/png',
  'application/pdf',
];

const FORMATS_FOR_REGISTRATION = [
  'image/jpeg',
  'image/gif',
  'image/tiff',
  'image/png',
  'text/csv',
  'text/plain', // txt
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation', // pptx
  'application/msword', // msword
  'application/vnd.ms-excel', // xls
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
];

const formatsError = 'Unsupported Format, only .pdf, .doc, .docx, .png, .jpeg, .jpg, .gif';
const formatsForRegistrationError = 'Unsupported Format, only .pdf, .doc, .docx, .txt, .excel, .xls, .xlsx, .png, .jpeg, .jpg, .gif, .tif, .csv';

export const fileValidation = yup
  .mixed()
  .test('size', fileSizeError, value => (value ? value.size <= FILE_SIZE : true))
  .test('type', formatsError, value => (value ? value && FORMATS.includes(value.type) : true));

export const fileValidationRequired = yup
  .mixed()
  .required('A file is required')
  .test('size', fileSizeError, value => value && value.size <= FILE_SIZE)
  .test('type', formatsError, value => value && FORMATS.includes(value.type));

export const fileValidationForRegistration = yup
  .mixed()
  .test('size', fileSizeError, value => (value ? value.size <= FILE_SIZE : true))
  .test('type', formatsForRegistrationError, value => (value ? value && FORMATS_FOR_REGISTRATION.includes(value.type) : true));

export const fileValidationForBatch = yup
  .mixed()
  .test('size', fileSizeError, value => (value ? value.size <= FILE_SIZE : true))
  .test('type', 'Unsupported Format, only .csv file', value => (value ? value && ['text/csv', 'application/vnd.ms-excel'].includes(value.type) : true));

export const fileValidationForRegistrationRequired = yup
  .mixed()
  .required('A file is required')
  .test('size', fileSizeError, value => value && value.size <= FILE_SIZE)
  .test('type', formatsForRegistrationError, value => value && FORMATS_FOR_REGISTRATION.includes(value.type));
