import { createAction } from 'redux-actions';
import { toastr } from 'react-redux-toastr';

import {
  CHANGE_DEFAULT_TEMPLATE,
  CLEAN_DEFAULT_TEMPLATE,
  CHANGE_FEES_FIELDS_FOR_JOURNALS_OF_ACCOUNT,
  GET_FEES_BY_LEDGER_ID,
  CHANGE_FEES_TEMPLATE_FOR_LEDGER_BY_ID,
  UPDATE_FEE_TEMPLATE,
} from '../../constants/constants';
import {
  notifApplyPricingTemplate,
  notifApplyPricingTemplateField,
  notifUpdatePricingTemplate,
} from '../../helpers/notificatios';
import { apiCallAction } from '../actionHelpers';
import { apiCallActionHandler } from '../commonLogic/requestLogic';

const actions = {
  changeDefaultTemplate: createAction(CHANGE_DEFAULT_TEMPLATE),
  cleanDefaultTemplate: createAction(CLEAN_DEFAULT_TEMPLATE),
  changeFeesFieldsForLedgerById: apiCallAction(CHANGE_FEES_FIELDS_FOR_JOURNALS_OF_ACCOUNT),
  changeFeesTemplateForLedgerById: apiCallAction(CHANGE_FEES_TEMPLATE_FOR_LEDGER_BY_ID),
  getFeesByLedgerId: apiCallAction(GET_FEES_BY_LEDGER_ID),
  updateFeeTemplate: apiCallAction(UPDATE_FEE_TEMPLATE),
};

const changeFeesFieldsForLedgerByIdLogic = apiCallActionHandler({
  name: 'changeFeesFieldsForLedgerById',
  fetchAction: actions.changeFeesFieldsForLedgerById,
  onSuccess() {
    toastr.success(
      'Success',
      notifApplyPricingTemplateField,
    );
  },
  makeApiCall(payload, { api }) {
    return api.fees.changeFeesFieldsForLedgerById(payload);
  },
});

const changeFeesTemplateForLedgerByIdLogic = apiCallActionHandler({
  name: 'changeFeesTemplateForLedgerById',
  fetchAction: actions.changeFeesTemplateForLedgerById,
  onSuccess() {
    toastr.success(
      'Success',
      notifApplyPricingTemplate,
    );
  },
  makeApiCall(payload, { api }) {
    return api.fees.changeFeesTemplateForLedgerById(payload);
  },
});

const getFeesByLedgerIdLogic = apiCallActionHandler({
  name: 'getFeesByLedgerId',
  fetchAction: actions.getFeesByLedgerId,
  makeApiCall(payload, { api }) {
    return api.fees.getFeesByLedgerId(payload);
  },
});

const updateFeeTemplateLogic = apiCallActionHandler({
  name: 'updateFeeTemplate',
  fetchAction: actions.updateFeeTemplate,
  onSuccess() {
    toastr.success(
      'Success',
      notifUpdatePricingTemplate,
    );
  },
  makeApiCall(payload, { api }) {
    return api.fees.updateFeeTemplate(payload);
  },
});

export const logics = [
  changeFeesFieldsForLedgerByIdLogic,
  getFeesByLedgerIdLogic,
  changeFeesTemplateForLedgerByIdLogic,
  updateFeeTemplateLogic,
];

export default actions;
