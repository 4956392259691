import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import UniversalModalButton from '../../containers/Modals/UniversalModalButton';
import Tooltip from '../../components/generic/Tooltip';
import ResetPasswordFormatter from './ResetPasswordFormatter';

const AdminUserNameFormatter = ({
  cell, id, subRole, userInfoId, email,
}) => (
  <div className="journal-block">
    {/*<NavLink*/}
    {/*  to={`/admin-users/activity/${id}`}*/}
    {/*  activeClassName="active"*/}
    {/*  className="journal-block__activity-link"*/}
    {/*>*/}
    {/*  <i*/}
    {/*    className="journal-icon iconfont-journal"*/}
    {/*    data-tip="journal-icon"*/}
    {/*    data-for={`admin-users-activity-${id}`}*/}
    {/*  />*/}
    {/*  <Tooltip id={`admin-users-activity-${id}`} text="Activity" />*/}
    {/*</NavLink>*/}
    <Choose>
      <When condition={subRole === 'SuperAdmin'}>
        <span className="journal-block__info-form" data-tip="edit-adminUser" data-for={`edit-adminUser-${id}`}>
          <UniversalModalButton
            modalId="edit-admin-user"
            text={cell}
            chosenItemId={id}
            inlineButton
          />
        </span>

        <Tooltip id={`edit-adminUser-${id}`} text="Edit Admin User" />
      </When>
      <Otherwise>
        <span className="journal-block__info-form" data-tip="view-adminUser" data-for={`view-adminUser-${id}`}>
          <UniversalModalButton
            modalId="view-admin-user"
            text={cell}
            chosenItemId={id}
            inlineButton
          />
        </span>

        <Tooltip id={`view-adminUser-${id}`} text="Admin User Info" />
      </Otherwise>
    </Choose>
    <ResetPasswordFormatter email={email} id={id} />
  </div>
);

AdminUserNameFormatter.propTypes = {
  cell: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  subRole: PropTypes.string.isRequired,
  userInfoId: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  subRole: state.getIn(['userInfo', 'data', 'subRole']),
  userInfoId: state.getIn(['userInfo', 'data', 'sub']),
});

export default connect(mapStateToProps)(AdminUserNameFormatter);
