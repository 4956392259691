import {
  apiDownloadFile,
  makeSecureRequest,
  makeSecureRequestWithFormData,
} from './apiCallHandlers/index';

export default () => ({
  getCustomers: (params) => {
    return makeSecureRequest('get', 'Enduser', null, params);
  },
  getOneCustomer: id => makeSecureRequest('get', `Enduser/${id}`),
  changeCustomerStatus: ({ id, statusId, comment: value }) => {
    return makeSecureRequest('put', `Enduser/${id}/SetStatus`, {
      statusId,
      value,
    });
  },
  updateCustomerField: ({ profileId, ...payload }) => {
    return makeSecureRequest('put', `Enduser/${profileId}`, payload);
  },
  downloadCustomerDocument: ({ profileId, id }) => {
    return apiDownloadFile('get', `Enduser/${profileId}/Document/${id}/Download`);
  },
  uploadCustomerDocument: ({
    profileId,
    documentTypeId,
    content: formFile,
    description = '',
    dispatchProgress,
  }) => {
    return makeSecureRequestWithFormData('post', `Enduser/${profileId}/Document/Upload`,
      {
        documentTypeId,
        formFile,
        description,
      }, null, dispatchProgress);
  },
  deleteCustomerDocument: ({ profileId, id }) => {
    return makeSecureRequest('delete', `Enduser/${profileId}/Document/${id}`);
  },
  deleteCustomerContact: ({ profileId, id }) => {
    return makeSecureRequest('delete', `Enduser/${profileId}/DeleteContact/${id}`);
  },
  createCustomerContact: (id) => {
    return makeSecureRequest('post', `Enduser/${id}/CreateContact`);
  },
  getCommentsForCustomer: id => {
    return makeSecureRequest('get', 'Enduser/GetCommentsForCustomer', null, { customerId: id });
  },
  addCommentsForCustomer: ({ enduserId, text }) => {
    return makeSecureRequest('post', 'Enduser/AddCommentsForCustomer', { enduserId, text });
  },
  removeCommentForCustomer: ({ id }) => {
    return makeSecureRequest('delete', 'Enduser/RemoveCommentForCustomer', null, { commentId: id });
  },
  editCommentsForCustomer: ({ id, text, adminId }) => {
    return makeSecureRequest('put', 'Enduser/EditCommentsForCustomer', { commentaryId: id, text, adminId });
  },
});
