import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import PricingPage from '../../components/Pages/PricingPage';
import { DefaultHeader } from '../../components/HOC/headerHelper';
import withLists from '../../components/HOC/withLists';

import listsActions from '../../actions/lists';
import feesActions from '../../actions/fees';

const { updateFeeTemplate } = feesActions.fees;
const { getFeesTemplates } = listsActions.lists;

const mapStateToProps = (state) => {
  const lists = state.get('lists').toJS();
  const { fetching } = state.get('fees').toJS();

  const templates = lists.feesTemplates.data.feesTemplates;

  const getDefaultTemplate = (object = {}, value) => Object.keys(object).find(
    key => object[key] === value,
  );

  const subRole = state.getIn(['userInfo', 'data', 'subRole']);

  return {
    defaultTemplate: getDefaultTemplate(templates, 'Medium Risk to Indirect Marketing'), // '047aa141-71e5-4926-9a69-68dad9b42ab1',
    fetching,
    subRole,
    globalLists: lists,
    path: 'Admin / Pricing',
    formId: 'create-fee',
    header: DefaultHeader,
    requiredLists: [
      {
        data: lists.feesTemplates.data,
        action: getFeesTemplates.action,
      },
    ],
  };
};

const mapDispatchToProps = dispatch => ({
  updateFeeTemplate: options => dispatch(updateFeeTemplate.action(options)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLists(withImmutablePropsToJS(PricingPage)));
