import React, { useCallback } from 'react';
import { toastr } from 'react-redux-toastr';
import PropsTypes from 'prop-types';

import SearchContainer from '../../containers/Modals/SearchContainer';
import UniversalModalButton from '../../containers/Modals/UniversalModalButton';
import InfoReason from '../../components/Layouts/InfoReason';
import ReadOnlyPermission from '../../components/HOC/ReadOnlyPermissionHOC';

import {
  tooltipCantDeclineBeneficiary,
  tooltipCantDeclineTransaction,
  tooltipCantProcessTransaction,
} from '../../helpers/notificatios';

export const confirmModal = (func, text, status, id) => toastr.confirm(text, {
  onOk: () => func({
    id,
    status,
  }),
});

export const AdminUserStatusHelper = (props) => {
  const {
    processAction,
    processText,
    id,
    status,
    subRole,
  } = props;

  const confirm = useCallback(
    () => confirmModal(processAction, processText, 'Active', id),
    [processAction, processText, id],
  );

  const stat = status ? status.toString()
    .toLowerCase() : 'N/a';

  return (
    <>
      <If condition={stat === 'active' && subRole === 'SuperAdmin'}>
        <UniversalModalButton
          modalId="reason-admin-user-modal"
          inlineButton
          iconClasses="iconfont-close clickable"
          customIcon
          chosenItemId={id}
        />
      </If>

      <SearchContainer text={status} className="content" />

      <If condition={stat === 'inactive' && subRole === 'SuperAdmin'}>
        <i onClick={confirm} className="iconfont-pending clickable" />
      </If>
    </>
  );
};

export const FeesChargesStatusHelper = (props) => {
  const {
    isVerified,
    processAction,
    processText,
    id,
  } = props;
  const confirm = useCallback(() => toastr.confirm(processText, { onOk: () => processAction(id) }),
    [processAction, processText, id]);

  return (
    <>
      <ReadOnlyPermission
        tooltipId="decline-button"
        declinePermissionText={tooltipCantDeclineTransaction}
        readOnlyComponent={(
          <span
            data-tip="decline-button"
            data-for="decline-button"
          >
            <i className="iconfont-close disabled" />
          </span>
        )}
      >
        <UniversalModalButton
          modalId="reason-feesCharges-modal"
          inlineButton
          iconClasses="iconfont-close clickable"
          customIcon
          chosenItemId={id}
        />
      </ReadOnlyPermission>

      <SearchContainer text="Pending" className="content" />

      <Choose>
        <When condition={isVerified}>
          <ReadOnlyPermission
            tooltipId="decline-button"
            declinePermissionText={tooltipCantProcessTransaction}
            readOnlyComponent={(
              <span
                data-tip="decline-button"
                data-for="decline-button"
              >
                <i className="iconfont-pending disabled" />
              </span>
            )}
          >
            <i onClick={confirm} className="iconfont-pending clickable" />
          </ReadOnlyPermission>
        </When>
      </Choose>
    </>
  );
};

export const JournalStatusHelper = (props) => {
  const {
    id,
    isVerified,
    processAction,
    processText,
  } = props;
  const confirm = useCallback(() => confirmModal(processAction, processText, 'Approved', id),
    [processAction, processText, id]);

  return (
    <>
      <SearchContainer text="Pending" className="content" />
      {isVerified
      && (
        <i
          onClick={confirm}
          className="iconfont-pending clickable"
        />
      )}
    </>
  );
};

export const BeneficiaryStatusPendingHelper = ({ id }) => (
  <>
    <UniversalModalButton
      modalId="reason-beneficiary-modal"
      inlineButton
      iconClasses="iconfont-close clickable"
      customIcon
      chosenItemId={id}
    />
    <SearchContainer text="Pending" className="content" />
  </>
);

export const BeneficiaryStatusHelper = (props) => {
  const {
    id,
    content,
    declineReason,
    userRole,
  } = props;
  if (content === 'OnHold') {
    return (
      <>
        <If condition={userRole === 'Admin'}>
          <ReadOnlyPermission
            tooltipId="decline-beneficiary-btn"
            declinePermissionText={tooltipCantDeclineBeneficiary}
            readOnlyComponent={(
              <span
                className="disabled"
                data-tip="decline-beneficiary-btn"
                data-for="decline-beneficiary-btn"
              >
                <UniversalModalButton
                  inlineButton
                  iconClasses="iconfont-close"
                  customIcon
                />
              </span>
            )}
          >
            <UniversalModalButton
              modalId="reason-beneficiary-modal"
              inlineButton
              iconClasses="iconfont-close clickable"
              customIcon
              chosenItemId={id}
            />
          </ReadOnlyPermission>
        </If>
        <SearchContainer text={content} className="content" />
        {declineReason && declineReason !== 'n/a' && (
          <InfoReason
            key={`${id}+1`}
            declineReason={declineReason}
            id={id}
          />
        )}
      </>
    );
  }

  return (
    <>
      <span className={`point point__${content.toLowerCase()}`} />
      <SearchContainer text={content} className="content" />
      <If condition={content === 'Declined' || content === 'canceled'}>
        {declineReason && declineReason !== 'n/a' && (
          <InfoReason
            key={`${id}+1`}
            declineReason={declineReason}
            id={id}
          />
        )}
      </If>
      <If condition={content === 'Approved' && userRole === 'Admin'}>
        <ReadOnlyPermission
          tooltipId="decline-beneficiary-btn"
          declinePermissionText={tooltipCantDeclineBeneficiary}
          readOnlyComponent={(
            <span
              className="disabled"
              data-tip="decline-beneficiary-btn"
              data-for="decline-beneficiary-btn"
            >
              <UniversalModalButton
                inlineButton
                iconClasses="iconfont-close"
                customIcon
              />
            </span>
          )}
        >
          <UniversalModalButton
            modalId="reason-beneficiary-modal"
            inlineButton
            iconClasses="iconfont-close clickable"
            customIcon
            chosenItemId={id}
          />
        </ReadOnlyPermission>
      </If>
    </>
  );
};

AdminUserStatusHelper.propTypes = {
  processAction: PropsTypes.func.isRequired,
  processText: PropsTypes.string.isRequired,
  id: PropsTypes.string.isRequired,
  status: PropsTypes.string.isRequired,
  subRole: PropsTypes.string.isRequired,
};

FeesChargesStatusHelper.defaultProps = {
  isVerified: false,
};

FeesChargesStatusHelper.propTypes = {
  isVerified: PropsTypes.bool,
  processAction: PropsTypes.func.isRequired,
  processText: PropsTypes.string.isRequired,
  id: PropsTypes.string.isRequired,
};

JournalStatusHelper.defaultProps = {
  isVerified: false,
};

JournalStatusHelper.propTypes = {
  id: PropsTypes.string.isRequired,
  isVerified: PropsTypes.bool,
  processAction: PropsTypes.func.isRequired,
  processText: PropsTypes.string.isRequired,
};

BeneficiaryStatusPendingHelper.propTypes = {
  id: PropsTypes.string.isRequired,
};

BeneficiaryStatusHelper.defaultProps = {
  declineReason: '',
};

BeneficiaryStatusHelper.propTypes = {
  id: PropsTypes.string.isRequired,
  content: PropsTypes.string.isRequired,
  declineReason: PropsTypes.string,
  userRole: PropsTypes.string.isRequired,
};
