import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const InputStandart = (props) => {
  const {
    error,
    type,
    id,
    name,
    value,
    placeholder,
    onChange,
  } = props;

  const [isPasswordShowing, setPasswordShowing] = useState(false);

  const onChangeValue = ({ target: { value } }) => {
    onChange(value);
  };

  return (
    <>
      <input
        className={classNames('basic-form__group__block__control input',
          {
            error: !!error,
          })}
        type={isPasswordShowing ? 'text' : 'password'}
        id={id}
        name={name}
        value={value}
        onChange={onChangeValue}
        placeholder={placeholder}
      />
      <i className={`${isPasswordShowing ? 'iconfont-eye' : 'iconfont-eye--hide'} clickable`}
         onClick={() => setPasswordShowing(!isPasswordShowing)}/>
    </>
  );
};

InputStandart.defaultProps = {
  value: '',
  error: '',
  onChange: () => {
  },
  id: '',
  icon: false,
  iconClassName: '',
  placeholder: '',
};

InputStandart.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  error: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  icon: PropTypes.bool,
  iconClassName: PropTypes.string,
  placeholder: PropTypes.string,
};

export default InputStandart;
