import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import BeneficiaryDeclinedFormatter from '../../lib/statusFormatters/BeneficiaryDeclinedFormatter';
import beneficiariesActions from '../../actions/beneficiaries';

const {
  setChangeBeneficiaryStatus,
} = beneficiariesActions.beneficiaries;

const mapStateToProps = (state) => {
  const currentState = state.getIn(['beneficiaries', 'withChangedStatus']);
  return {
    userRole: state.getIn(['userInfo', 'data', 'role']),
    requestData: currentState,
  };
};

export default connect(mapStateToProps, {
  statusAction: setChangeBeneficiaryStatus.action,
})(withImmutablePropsToJS(BeneficiaryDeclinedFormatter));
