import { combineActions, handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import { combineReducers } from 'redux-immutable';

import beneficiariesActions from '../actions/beneficiaries';

import userActions from '../actions/user/userActions';

const {
  getOneBeneficiary,
  getOneBeneficiaryWithList,
  getBeneficiaries,
  setChangeBeneficiaryStatus,
  editBeneficiary,
  createBeneficiary,
  deleteBeneficiary,
  switchBeneficiaryType,
  clearOneBeneficiary,
} = beneficiariesActions.beneficiaries;
const { logout } = userActions;

const initialState = fromJS({
  list: {
    data: [],
    markedItemId: null,
    error: false,
    fetching: false,
  },
  one: {
    data: {},
    error: false,
    fetching: false,
    beneficiaryType: 'company',
  },
  withChangedStatus: {
    data: {},
    requestId: '',
    error: false,
    fetching: false,
  },
});

const beneficiariesReducer = handleActions(
  {
    [getBeneficiaries.fetching]: state => state
      .set('fetching', true)
      .set('error', false)
      .set('data', []),
    [combineActions(
      getBeneficiaries.done,
      getBeneficiaries.failure,
      getBeneficiaries.error,
    )]: state => state
      .set('fetching', false)
      .set('error', true),
    [setChangeBeneficiaryStatus.success]: (state, { payload }) => {
      const currItem = state.get('data')
        .findKey(obj => obj.get('id') === payload.id);

      return state.setIn(['data', currItem], fromJS({ ...payload, marked: true }));
    },
    [getBeneficiaries.success]: (state, { payload }) => {
      const markedItemId = state.get('markedItemId');

      if (markedItemId) {
        const response = payload.map(item => {
          return item.id === markedItemId ? {
            ...item,
            marked: true,
          } : item;
        });

        return state.set('data', fromJS(response))
          .set('markedItemId', null)
          .set('fetching', false);
      }
      return state.set('data', fromJS(payload))
        .set('fetching', false);
    },
    [combineActions(
      editBeneficiary.success,
      createBeneficiary.success,
    )]: (state, { payload }) => state.set('markedItemId', fromJS(payload.id)),
    [logout]: () => initialState.get('list'),
  },
  initialState.get('list'),
);

const oneBeneficiaryReducer = handleActions(
  {
    [switchBeneficiaryType]: (state, { payload }) => state.set('beneficiaryType', payload),
    [combineActions(
      getOneBeneficiaryWithList.fetching,
      getOneBeneficiary.fetching,
    )]: state => state
      .set('fetching', true)
      .set('error', false)
      .set('data', {}),
    [combineActions(
      getBeneficiaries.done,
      getBeneficiaries.failure,
      getBeneficiaries.error,
    )]: state => state
      .set('fetching', false)
      .set('error', true),
    [combineActions(
      createBeneficiary.fetching,
      editBeneficiary.fetching,
      deleteBeneficiary.fetching,
    )]: state => state.set('fetching', true),
    [combineActions(
      editBeneficiary.success,
      createBeneficiary.success,
      deleteBeneficiary.success,
    )]: state => state.set('fetching', false),
    [combineActions(
      getOneBeneficiary.success,
      getOneBeneficiaryWithList.success,
    )]: (state, { payload }) => state.set('data', fromJS(payload))
      .set('fetching', false),
    [createBeneficiary.success]: state => state.set('beneficiaryType', 'company'),
    [combineActions(
      createBeneficiary.failure,
      createBeneficiary.error,
      editBeneficiary.failure,
      editBeneficiary.error,
      getOneBeneficiary.failure,
      getOneBeneficiary.error,
      getOneBeneficiaryWithList.failure,
      getOneBeneficiaryWithList.error,
      deleteBeneficiary.error,
      deleteBeneficiary.failure,
      deleteBeneficiary.error,
    )]: state => state.set('fetching', false)
      .set('error', true),
    [combineActions(
      logout,
      clearOneBeneficiary,
    )]: () => initialState.get('one'),
  },
  initialState.get('one'),
);

const changeBeneficiaryStatusReducer = handleActions(
  {
    [combineActions(
      setChangeBeneficiaryStatus.fetching,
    )]: (state, { payload }) => state.set('requestId', payload)
      .set('fetching', true)
      .set('error', false),
    [combineActions(
      setChangeBeneficiaryStatus.failure,
      setChangeBeneficiaryStatus.error,
      setChangeBeneficiaryStatus.done,
    )]: state => state
      .set('fetching', false)
      .set('error', true),
    [combineActions(
      setChangeBeneficiaryStatus.success,
    )]: (state, { payload }) => state.set('data', fromJS(payload))
      .set('fetching', false),
    [logout]: () => initialState.get('withChangedStatus'),
  },
  initialState.get('withChangedStatus'),
);

export default combineReducers({
  list: beneficiariesReducer,
  one: oneBeneficiaryReducer,
  withChangedStatus: changeBeneficiaryStatusReducer,
});
