import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';

import RangeCalendar from '../../components/Layouts/RangeCalendar';
import Search from '../../components/Layouts/Search';
import ExportData from '../../components/Layouts/ExportData';
import {
  datesFromPreviousMonthFormatted,
  datesFromPreviousMonth,
} from '../../constants/constants';
import reportsActions from '../../actions/reports';

const {
  getReportForProcessedTransactions,
  getTransactionNonPosted,
  getTransactionFailedOutsidePreSet,
  getTransactionFailed,
  getTransactionERP,
} = reportsActions.reports;

const TransactionsReportHeader = ({
  rangeCalendarAction, onExport, title, canExport, datesForRequest, datesForCalendar,
}) => {
  useEffect(() => {
    rangeCalendarAction(datesForRequest);
  }, []);

  return (
    <>
      <div className="breadcrumbs-block">
        <div>{`Admin / ${title}`}</div>
      </div>

      <div className="data_tools">
        <RangeCalendar
          rangeCalendarAction={rangeCalendarAction}
          customDefaultDate={datesForCalendar}
        />

        <Search />

        <ExportData onExport={onExport} canExport={canExport} />
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch, { title }) => {
  const getFuncForCalendar = () => {
    switch (title) {
      case 'Processed Transactions':
        return range => dispatch(getReportForProcessedTransactions.action(range));
      case 'Failed Transactions':
        return range => dispatch(getTransactionFailed.action(range));
      case 'Out of Limits Transactions':
        return range => dispatch(getTransactionFailedOutsidePreSet.action(range));
      case 'Unknown Exception Transactions':
        return range => dispatch(getTransactionNonPosted.action(range));
      case 'ERP Transactions':
        return range => dispatch(getTransactionERP.action(range));
    }
    return () => {};
  };

  return {
    rangeCalendarAction: getFuncForCalendar(),
  };
};

TransactionsReportHeader.defaultProps = {
  title: '',
};

TransactionsReportHeader.propTypes = {
  rangeCalendarAction: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  title: PropTypes.string,
  canExport: PropTypes.bool.isRequired,
  datesForRequest: PropTypes.shape({
    dateFromFormatted: PropTypes.string.isRequired,
    dateToFormatted: PropTypes.string.isRequired,
  }).isRequired,
  datesForCalendar: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date).isRequired,
    endDate: PropTypes.instanceOf(Date).isRequired,
  }).isRequired,
};

const mapStateToProps = () => ({
  datesForRequest: datesFromPreviousMonthFormatted(),
  datesForCalendar: datesFromPreviousMonth(),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(withImmutablePropsToJS(TransactionsReportHeader));
