import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import TransactionsContainer from '../../../containers/Pages/Transactions/TransactionsContainer';
import CreateTransactionContainer
  from '../../../containers/Pages/Transactions/CreateTransactionContainer';
import ViewTransactionContainer
  from '../../../containers/Pages/Transactions/ViewTransactionContainer';
import withLists from '../../HOC/withLists';
import listsActions from '../../../actions/lists';
import userActions from '../../../actions/user';

const {
  getCountries,
  getCurrencies,
  getRoutingTypes,
} = listsActions.lists;

const {
  getUserCustomers,
} = userActions.user;

const Transactions = () => (
  <Switch>
    <Route exact path="/transactions/" component={TransactionsContainer} />
    <Route path="/transactions/create" component={CreateTransactionContainer} />
    <Route exact path="/transactions/:id" component={ViewTransactionContainer} />
    <Redirect to="/transactions/" />
  </Switch>
);

const mapStateToProps = (state) => {
  const {
    currencies,
    countries,
    routingTypes,
  } = state.get('lists')
    .toJS();
  const { userCustomers } = state.get('userInfo').toJS();

  const userRole = state.getIn(['userInfo', 'data', 'role']);

  const lists = [
    {
      data: currencies.data,
      action: getCurrencies.action,
    },
    {
      data: countries.data,
      action: getCountries.action,
    },
    {
      data: routingTypes.data,
      action: getRoutingTypes.action,
    },
  ];

  const listForUser = [
    {
      data: userCustomers,
      action: getUserCustomers.action,
    },
  ];

  return userRole === 'Admin'
    ? {
      requiredLists: lists,
    } : {
      requiredLists: [...lists, ...listForUser],
    };
};

export default connect(mapStateToProps)(withLists(withImmutablePropsToJS(Transactions)));
