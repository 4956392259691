import * as yup from 'yup';

import {
  latinFieldWithNumbers,
  latinFieldWithNumbersZip,
  latinFieldWithNumbersAndPunctuationMarks,
} from '../../../helpers/fieldsValidation';
import { setFieldsToReadOnly } from '../../../helpers/other';
import {
  bankCodeTypeAutocomplete,
  bankCodeSchema, getBankCodeType,
} from './beneficiaryHelpers';

export const editBeneficiaryTable = {
  title: 'Beneficiary',
  fields: [
    'enduserId',
    'beneficiaryTypeId',
    'currencyId',
    'beneficiaryName',
    'beneficiaryFirstName',
    'beneficiaryLastName',
    'address',
    'city',
    'state',
    'countryId',
    'zip',
    'accountNumber',
    'statusId',
    'isValidated',
  ],
};

export const editBankTable = {
  title: 'Bank',
  fields: [
    'bankName',
    'bankAddress',
    'bankCity',
    'bankCountryId',
    'swiftCode',
    'bankCode',
    'bankCodeType',
  ],
};
export const editSelectList = {
  currency: ['USD', 'EUR', 'PHP'],
  bankCountry: ['BR', 'BR', 'BR'],
  country: ['BR', 'BR', 'BR'],
  bankCodeType: ['BR', 'BR', 'BR'],
};

export const editBeneficiaryFields = [
  {
    id: 'enduserId',
    name: 'enduserId',
    label: 'Customer',
    placeholder: 'Select customer',
    type: 'select',
    defaultSorting: true,
  },
  {
    id: 'beneficiaryTypeId',
    name: 'beneficiaryTypeId',
    label: 'Beneficiary Type',
    type: 'select',
  },
  {
    id: 'beneficiaryName',
    name: 'beneficiaryName',
    label: 'Name',
    placeholder: '',
    type: 'text',
  },
  {
    id: 'beneficiaryFirstName',
    name: 'beneficiaryFirstName',
    label: 'First Name',
    placeholder: '',
    type: 'text',
  },
  {
    id: 'beneficiaryLastName',
    name: 'beneficiaryLastName',
    label: 'Last Name',
    placeholder: '',
    type: 'text',
  },
  {
    id: 'currencyId',
    name: 'currencyId',
    label: 'Currency',
    placeholder: 'Select Currency',
    type: 'select',
    defaultSorting: true,
    dependedFunctionForValues: (setValues, e, lists, values) => bankCodeTypeAutocomplete(setValues, e, 'currencyId', values, lists),
  },
  {
    id: 'address',
    name: 'address',
    label: 'Address',
    placeholder: 'Enter address',
    type: 'geo',
    relativeFieldsForGeo: [
      {
        key: 'address',
        geoKey: 'address',
      },
      {
        key: 'city',
        geoKey: 'city',
      },
      {
        key: 'state',
        geoKey: 'state',
      },
      {
        key: 'countryId',
        geoKey: 'country',
        listId: 'countryId',
      },
      {
        key: 'zip',
        geoKey: 'zip',
      },
    ],
  },
  {
    id: 'city',
    name: 'city',
    label: 'City',
    placeholder: '',
    type: 'text',
  },
  {
    id: 'state',
    name: 'state',
    label: 'State',
    placeholder: '',
    type: 'text',
  },
  {
    id: 'countryId',
    name: 'countryId',
    label: 'Country',
    placeholder: 'Select country of the beneficiary',
    type: 'select',
    defaultSorting: true,
  },
  {
    id: 'zip',
    name: 'zip',
    label: 'Zip',
    placeholder: '',
    type: 'text',
  },
  {
    id: 'accountNumber',
    name: 'accountNumber',
    label: 'Account Number',
    placeholder: '',
    type: 'text',
  },
  {
    id: 'statusId',
    name: 'statusId',
    label: 'Status',
    placeholder: 'Select status',
    type: 'select',
    defaultSorting: true,
  },
  {
    id: 'bankName',
    name: 'bankName',
    label: 'Name',
    placeholder: '',
    type: 'text',
  },
  {
    id: 'bankAddress',
    name: 'bankAddress',
    label: 'Address',
    placeholder: 'Enter address',
    type: 'geo',
    relativeFieldsForGeo: [
      {
        key: 'bankAddress',
        geoKey: 'address',
      },
      {
        key: 'bankCity',
        geoKey: 'city',
      },
      {
        key: 'bankCountryId',
        geoKey: 'country',
        listId: 'bankCountryId',
      },
    ],
  },
  {
    id: 'bankCity',
    name: 'bankCity',
    label: 'City',
    placeholder: '',
    type: 'text',
  },
  {
    id: 'bankCountryId',
    name: 'bankCountryId',
    label: 'Country',
    placeholder: 'Select country of the bank',
    type: 'select',
    defaultSorting: true,
    dependedFunctionForValues: (setValues, e, lists, values) => bankCodeTypeAutocomplete(setValues, e, 'bankCountryId', values, lists),
  },
  {
    id: 'swiftCode',
    name: 'swiftCode',
    label: 'SWIFT Code',
    placeholder: '',
    type: 'text',
  },
  {
    id: 'bankCode',
    name: 'bankCode',
    label: 'Bank Code',
    placeholder: '',
    type: 'text',
  },
  {
    id: 'bankCodeType',
    name: 'bankCodeType',
    label: 'Type',
    placeholder: 'Select type of the bank',
    type: 'select',
    defaultSorting: true,
  },
];

const isValidatedSchema = {
  id: 'isValidated',
  name: 'isValidated',
  label: 'Validated',
  placeholder: '',
  type: 'checkbox',
};

export const editBeneficiarySchema = (role) => {
  return role === 'Admin'
    ? [...editBeneficiaryFields, isValidatedSchema]
    : editBeneficiaryFields;
};

export const viewBeneficiarySchema = setFieldsToReadOnly(editBeneficiaryFields);

const validationForAll = (currenciesList, sepaCountriesList) => ({
  beneficiaryTypeId: yup.string()
    .trim()
    .required('Required!'),
  currencyId: yup.string()
    .trim()
    .required('Required!'),
  address: latinFieldWithNumbersAndPunctuationMarks().trim().when(['currencyId', 'bankCountryId'], (currencyId, bankCountryId, schema) => {
    return getBankCodeType({ currencyId, bankCountryId }, currenciesList, sepaCountriesList) === 5
      ? schema
      : schema.required('Required!');
  }),
  city: latinFieldWithNumbersAndPunctuationMarks().trim().when(['currencyId', 'bankCountryId'], (currencyId, bankCountryId, schema) => {
    return getBankCodeType({ currencyId, bankCountryId }, currenciesList, sepaCountriesList) === 5
      ? schema
      : schema.required('Required!');
  }),
  state: latinFieldWithNumbersAndPunctuationMarks().trim().when(['currencyId', 'bankCountryId'], (currencyId, bankCountryId, schema) => {
    return getBankCodeType({ currencyId, bankCountryId }, currenciesList, sepaCountriesList) === 5
      ? schema
      : schema.required('Required!');
  }),
  countryId: yup.string()
    .trim()
    .required('Required!'),
  zip: latinFieldWithNumbersZip().trim().when(['currencyId', 'bankCountryId'], (currencyId, bankCountryId, schema) => {
    return getBankCodeType({ currencyId, bankCountryId }, currenciesList, sepaCountriesList) === 5
      ? schema
      : schema.required('Required!');
  }),
  accountNumber: latinFieldWithNumbers()
    .trim()
    .required('Required!'),
  bankName: latinFieldWithNumbersAndPunctuationMarks()
    .trim()
    .required('Required!'),
  bankAddress: latinFieldWithNumbersAndPunctuationMarks().trim().when(['currencyId', 'bankCountryId'], (currencyId, bankCountryId, schema) => {
    return getBankCodeType({ currencyId, bankCountryId }, currenciesList, sepaCountriesList) === 5
      ? schema
      : schema.required('Required!');
  }),
  bankCity: latinFieldWithNumbersAndPunctuationMarks().trim().when(['currencyId', 'bankCountryId'], (currencyId, bankCountryId, schema) => {
    return getBankCodeType({ currencyId, bankCountryId }, currenciesList, sepaCountriesList) === 5
      ? schema
      : schema.required('Required!');
  }),
  bankCountryId: yup.string()
    .trim()
    .required('Required!'),
  swiftCode: latinFieldWithNumbers()
    .trim()
    .required('Required!'),
  bankCode: bankCodeSchema(currenciesList, sepaCountriesList),
  bankCodeType: bankCodeSchema(currenciesList, sepaCountriesList),
  statusId: yup.string()
    .trim()
    .required('Required!'),
  enduserId: yup.string()
    .trim()
    .required('Required!'),
});

export const editBeneficiaryValidationSchemaForCompany = (
  currencies, sepaCountries,
) => yup.object({
  ...validationForAll(currencies, sepaCountries),
  beneficiaryName: latinFieldWithNumbersAndPunctuationMarks()
    .trim()
    .required('Required!'),
});

export const editBeneficiaryValidationSchemaForIndividual = (
  currencies, sepaCountries,
) => yup.object({
  ...validationForAll(currencies, sepaCountries),
  beneficiaryLastName: latinFieldWithNumbersAndPunctuationMarks()
    .trim()
    .required('Required!'),
  beneficiaryFirstName: latinFieldWithNumbersAndPunctuationMarks()
    .trim()
    .required('Required!'),
});
