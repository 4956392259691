import Geocode from 'react-geocode';

import config from 'config';

const { MAP_API_KEY } = config;

const componentForm = {
  street_number: 'short_name',
  route: 'long_name',
  locality: 'long_name',
  administrative_area_level_1: 'short_name',
  administrative_area_level_2: 'long_name',
  administrative_area_level_3: 'long_name',
  country: 'long_name',
  postal_code: 'short_name',
};

export default async function getLocationData(lat, lng) {
  await Geocode.setApiKey(MAP_API_KEY);
  await Geocode.setLanguage('en');
  await Geocode.setRegion('es');
  // const locationFromLatLong = await Geocode.fromLatLng(lat, lng);

  return Geocode.fromLatLng(lat, lng)
    .then(response => parseGeoLocation(response.results[0]))
    .catch(() => ({
      address: '',
      city: '',
      state: '',
      country: '',
      zip: '',
    }));
}


export function parseGeoLocation(place = {}) {
  const form = {};

  for (let i = 0; i < place.address_components.length; i++) {
    const addressType = place.address_components[i].types[0];
    if (componentForm[addressType]) {
      form[addressType] = place.address_components[i][componentForm[addressType]];
    }
  }

  const {
    street_number = '',
    route = '',
    locality = '',
    administrative_area_level_1 = '',
    administrative_area_level_2 = '',
    administrative_area_level_3 = '',
    country = '',
    postal_code = '',
  } = form;

  return {
    address: `${route} ${street_number}`,
    city: locality || administrative_area_level_2 || administrative_area_level_3,
    state: administrative_area_level_1,
    country: country === 'United States'
      ? 'United States of America'
      : country,
    zip: postal_code,
  };
}
