export default {
  API_URL: 'https://portalapi.wirebloom.com',
  //API_URL: "https://localhost:5001",
  PROJECT_NAME: 'WireBloom',
  // MAP_API_KEY: 'AIzaSyAEwTHCMT5M7OtV16-eHVZEjFMM4A5kdjI',
  MAP_API_KEY: 'AIzaSyAFqPKNORR3Ctvc2Rzj8YX5oebd7xpSGVs',
  GOOGLE_MAP_URL: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAFqPKNORR3Ctvc2Rzj8YX5oebd7xpSGVs&v=3.exp&libraries=geometry,drawing,places&language=en',
  DEFAULT_COORDINATES: { lat: 51.51906527539954, lng: -0.17337869870822142 },
  DEFAULT_MAP_ZOOM: 9,
};
