import React from 'react';
import PropsTypes from 'prop-types';

import CustomCheckbox from '../../components/generic/CustomCheckbox';
import ReadOnlyPermission from '../../components/HOC/ReadOnlyPermissionHOC';
import { tooltipCantDeclineTransaction } from '../../helpers/notificatios';

const SelectionTransactionFormatter = ({
  transaction,
  selectTransaction,
  selected,
  inProcessing,
  fetching,
}) => {
  const { id, status, isVerified } = transaction;
  const onChange = e => selectTransaction({ addNew: e.target.checked, key: id });

  const isCorrect = status === 'Pending' && isVerified;
  const isInProcessing = inProcessing.indexOf(id) !== -1 && fetching;

  return (
    <ReadOnlyPermission
      tooltipId={`checkbox-${id}`}
      key="adjustment"
      declinePermissionText={tooltipCantDeclineTransaction}
      readOnlyComponent={(
        <span
          data-tip={`checkbox-${id}`}
          data-for={`checkbox-${id}`}
        >
          <CustomCheckbox
            className="disabled"
            onChange={e => e.preventDefault()}
            disabled
          />
        </span>
      )}
    >
      <CustomCheckbox
        onChange={onChange}
        checked={selected.indexOf(id) !== -1}
        disabled={!isCorrect || isInProcessing}
      />
    </ReadOnlyPermission>
  );
};

SelectionTransactionFormatter.propTypes = {
  selected: PropsTypes.arrayOf(
    PropsTypes.string,
  ).isRequired,
  fetching: PropsTypes.bool.isRequired,
  selectTransaction: PropsTypes.func.isRequired,
  inProcessing: PropsTypes.arrayOf(
    PropsTypes.object,
  ).isRequired,
  transaction: PropsTypes.shape({
    id: PropsTypes.string.isRequired,
    status: PropsTypes.string.isRequired,
    isVerified: PropsTypes.bool.isRequired,
  }).isRequired,
};

export default SelectionTransactionFormatter;
