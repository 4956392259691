import React, { useState } from 'react';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';

import { Modal } from 'react-bootstrap';

import transactionsActions from '../../actions/transactions';
import listActions from '../../actions/lists';
import withLists from '../../components/HOC/withLists';
import CustomSpinner from '../../components/Spinners/CustomSpinner';
import Button from '../../components/generic/Button';

import { batchFileFieldsValidationSchema, batchFileFields } from '../../schemes/forms/batchFile';
import UniversalInputsForm from '../../components/generic/UniversalInputsForm';
import RangeCalendar from '../../components/Layouts/RangeCalendar';
import { defaultDateRange } from '../../constants/constants';

const {
  downloadBatchFile,
} = transactionsActions.transactions;
const {
  getBatchPlatforms,
} = listActions.lists;

const { dateFrom, dateTo } = defaultDateRange;

const UploadButchFile = (props) => {
  const {
    isModalOpen,
    handleClose,
    title,
    modalId,
    downloadBatch,
    fetching,
    validationSchema,
    lists,
    fields,
  } = props;

  const defaultDate = {
    startDate: new Date(dateFrom),
    endDate: new Date(dateTo),
  };

  const [rangeDate, setRangeDate] = useState({
    dateFromFormatted: dateFrom,
    dateToFormatted: dateTo,
  });

  const submitDownloadBatch = (formData) => {
    downloadBatch({
      ...formData,
      DateFrom: rangeDate.dateFromFormatted,
      DateTo: rangeDate.dateToFormatted,
    });
  };

  return (
    <Modal
      show={isModalOpen}
      onHide={handleClose}
      className={`custom-modal ${modalId}`}
    >
      <Modal.Header closeButton>
        <h3 className="custom-modal__title">{title}</h3>
      </Modal.Header>

      <Modal.Body>
        <div className="custom-modal__content">
          <CustomSpinner fetching={fetching} />
          <div
            className={`info-table ${!fetching ? 'content--show' : 'content--hide-without-transition'}`}
          >
            <RangeCalendar rangeCalendarAction={setRangeDate} customDefaultDate={defaultDate} />
            <UniversalInputsForm
              fields={fields}
              validationSchema={validationSchema}
              onSubmit={submitDownloadBatch}
              formId={modalId}
              withLabel
              lists={lists}
            />
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          color="gray"
          type="primary"
          size="lg"
          submit
          form={modalId}
          text="Download"
          disabled={fetching}
        />
        <Button
          onClick={handleClose}
          color="gray"
          type="primary"
          size="lg"
          text="Close"
        />
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  const { isModalOpen } = state.get('modal')
    .toJS();
  const { batchPlatforms } = state.get('lists').toJS();

  return {
    requiredLists: [
      {
        data: batchPlatforms.data,
        action: getBatchPlatforms.action,
      },
    ],
    lists: {
      platformId: batchPlatforms.data,
    },
    fields: batchFileFields,
    validationSchema: batchFileFieldsValidationSchema,
    isModalOpen,
    modalId: 'download-batch-file',
    fetching: state.getIn(['modal', 'fetching']),
  };
};

const mapDispatchToProps = dispatch => ({
  downloadBatch: payload => dispatch(downloadBatchFile.action(payload)),
});

export default connect(mapStateToProps,
  mapDispatchToProps)(withLists(withImmutablePropsToJS(UploadButchFile)));

UploadButchFile.propTypes = {
  modalId: PropTypes.string.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  fetching: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  downloadBatch: PropTypes.func.isRequired,
  defaultFile: PropTypes.shape({
    status: PropTypes.string,
    fileName: PropTypes.string,
    content: PropTypes.any,
    type: PropTypes.string,
  }),
  lists: PropTypes.objectOf(PropTypes.object).isRequired,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  validationSchema: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.string,
    PropTypes.array,
    PropTypes.bool,
    PropTypes.func,
  ])).isRequired,
};

UploadButchFile.defaultProps = {
  defaultFile: {
    status: null,
    fileName: null,
    content: null,
    type: null,
    id: '',
  },
};
