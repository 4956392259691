import { filter } from 'lodash';

import TransactionsDashboardContainer from '../../containers/Pages/Dashboard/TransactionsDashboardContainer';
import ApplicationsDashboardContainer from '../../containers/Pages/Dashboard/applications/ApplicationsDashboardContainer';
import Accounts from '../../components/Pages/AccountsPage/Accounts';
import Customers from '../../components/Pages/CustomersPage/Customers';
import Transactions from '../../components/Pages/Transactions/Transactions';
import Beneficiaries from '../../components/Pages/Beneficiary/Beneficiaries';
import MessagesContainer from '../../containers/Pages/Messages/MessagesPageContainer';
import CustomersContainer from '../../containers/Pages/Customers/CustomersContainer';
import Users from '../../components/Pages/UsersPage/Users';
import AdminUsersContainer from '../../containers/Pages/Admin/AdminUsersContainer';
// import FeesContainer from '../../containers/Pages/FeesContainer';
import PricingContainer from '../../containers/Pages/PricingContainer';
// import ExchangeRateContainer from '../../containers/Pages/ExchangeRateContainer';
// import ExceptionsContainer from '../../containers/Pages/ExceptionsContainer';
import JournalContainer from '../../containers/Pages/JournalContainer';
// import BankFeesContainer from '../../containers/Pages/BankFeesContainer';
import FeesChargesContainer from '../../containers/Pages/FeesChargesContainer';
import TransactionsReportContainer from '../../containers/Pages/Reports/TransactionsReportContainer';
// import FeesTransferContainer from '../../containers/Pages/FeesTransferContainer';
import UserActivityContainer from '../../containers/Pages/UserActivityContainer';
import ProcessingErrorContainer from '../../containers/Pages/ProcessingErrorContainer';
// import AccountSetupContainer from '../../containers/Pages/AccountSetup/AccountSetupContainer';

import { filteredItems, getIds } from '../../lib/commonHelpers';
import TransactionNotPostedContainer
  from '../../containers/Pages/Reports/TransactionNotPostedContainer';
import TransactionFailedContainer from '../../containers/Pages/Reports/TransactionFailedContainer';
import TransactionFaillingOutsidePreSetContainer
  from '../../containers/Pages/Reports/TransactionFailedOutsidePreSetContainer';
import TransactionERPContainer from '../../containers/Pages/Reports/TransactionERPContainer';

import {
  notAllowedForAdmin,
  notAllowedForReadOnlyAdmin,
  notAllowedForUser,
} from '../../helpers/routes';

export const mainRoutes = [
  // {
  //   id: 'dashboard',
  //   title: 'Dashboard',
  //   path: '/dashboard',
  //   component: '',
  //   icon: 'squares',
  //   isMenu: true,
  //   isParent: true,
  //   withChildren: true,
  // },
  // {
  //   id: 'dashboard-transactions',
  //   title: 'Transactions',
  //   path: '/dashboard-transactions',
  //   component: TransactionsDashboardContainer,
  //   isMenu: true,
  //   parentId: 'dashboard',
  // },
  // {
  //   id: 'dashboard-applications',
  //   title: 'Applications',
  //   path: '/dashboard-applications',
  //   component: ApplicationsDashboardContainer,
  //   isMenu: true,
  //   parentId: 'dashboard',
  // },
  {
    id: 'accounts',
    title: 'Accounts',
    path: '/accounts',
    component: Accounts,
    icon: 'users',
    isMenu: true,
  },
  {
    id: 'transactions',
    title: 'Transactions',
    path: '/transactions',
    component: Transactions,
    icon: 'transactions',
    isMenu: true,
  },
  {
    id: 'beneficiaries',
    title: 'Beneficiaries',
    path: '/beneficiaries',
    component: Beneficiaries,
    icon: 'beneficiaries',
    isMenu: true,
  },
  // {
  //   id: 'messages',
  //   title: 'Messages',
  //   path: '/messages',
  //   component: MessagesContainer,
  //   icon: 'messages',
  //   isMenu: true,
  // },
  {
    id: 'customers',
    title: 'Customers',
    path: '/customers',
    component: Customers,
    icon: 'molecule',
    isMenu: true,
  },
  {
    id: 'reports',
    title: 'Reports',
    path: '/reports',
    component: '',
    icon: 'seo-report',
    isMenu: true,
    isParent: true,
    withChildren: true,
  },
  {
    id: 'processed-transactions',
    title: 'Processed Transactions',
    path: '/processed-transactions',
    component: TransactionsReportContainer,
    parentId: 'reports',
  },
  {
    id: 'failed-transactions',
    title: 'Failed Transactions',
    path: '/failed-transactions',
    component: TransactionFailedContainer,
    parentId: 'reports',
  },
  {
    id: 'out-of-limits-transactions',
    title: 'Out of Limits Transactions',
    path: '/out-of-limits-transactions',
    component: TransactionFaillingOutsidePreSetContainer,
    parentId: 'reports',
  },
  {
    id: 'unknown-exception-transactions',
    title: 'Unknown Exception Transactions',
    path: '/unknown-exception-transactions',
    component: TransactionNotPostedContainer,
    parentId: 'reports',
  },
  {
    id: 'erp-transactions',
    title: 'ERP Transactions',
    path: '/erp-transactions',
    component: TransactionERPContainer,
    parentId: 'reports',
  },
  {
    id: 'admin',
    title: 'Admin',
    path: '/admin',
    component: '',
    icon: 'code-terminal',
    isMenu: true,
    isParent: true,
    withChildren: true,
  },
  {
    id: 'users',
    title: 'Users',
    path: '/users',
    component: Users,
    isMenu: true,
    parentId: 'admin',
  },
  {
    id: 'simple-users',
    title: 'Users',
    path: '/users',
    component: Users,
    icon: 'user',
    isMenu: true,
  },
  {
    id: 'adminUsers',
    title: 'Admin Users',
    path: '/admin-users',
    component: AdminUsersContainer,
    isMenu: true,
    parentId: 'admin',
  },
  // {
  //   id: 'fees',
  //   title: 'Fees',
  //   path: '/fees',
  //   component: FeesContainer,
  //   isMenu: true,
  //   parentId: 'admin',
  // },
  {
    id: 'pricing',
    title: 'Pricing',
    path: '/pricing',
    component: PricingContainer,
    isMenu: true,
    parentId: 'admin',
  },
  // {
  //   id: 'exchange_rate',
  //   title: 'Exchange Rate',
  //   path: '/exchange_rate',
  //   component: ExchangeRateContainer,
  //   isMenu: true,
  //   parentId: 'admin',
  // },
  // {
  //   id: 'exceptions',
  //   title: 'Exceptions',
  //   path: '/exceptions',
  //   component: ExceptionsContainer,
  //   isMenu: true,
  //   parentId: 'admin',
  // },
  {
    id: 'journal',
    title: 'Journal',
    path: '/journal',
    component: JournalContainer,
    isMenu: true,
    parentId: 'admin',
  },
  // {
  //   id: 'bank_fees',
  //   title: 'Bank Fees',
  //   path: '/bank_fees',
  //   component: BankFeesContainer,
  //   isMenu: true,
  //   parentId: 'admin',
  // },
  {
    id: 'fees-charges',
    title: 'Fees Charges',
    path: '/fees-charges',
    component: FeesChargesContainer,
    isMenu: true,
    parentId: 'admin',
  },
  // {
  //   id: 'fees_transfer',
  //   title: 'Fees Transfer',
  //   path: '/fees_transfer',
  //   component: FeesTransferContainer,
  //   isMenu: true,
  //   parentId: 'admin',
  // },
  // {
  //   id: 'invalid_transactions',
  //   title: 'Invalid transactions',
  //   path: '/invalid-transactions',
  //   component: HomeContainer,
  //   isMenu: true,
  //   parentId: 'admin',
  // },
  {
    id: 'user-activity',
    title: 'User Activity',
    path: '/user-activity',
    component: UserActivityContainer,
    isMenu: true,
    parentId: 'admin',
  }, {
    id: 'processing-errors',
    title: 'Processing Errors',
    path: '/processing-errors',
    component: ProcessingErrorContainer,
    isMenu: true,
    parentId: 'admin',
  },
];

const fieldIds = getIds(mainRoutes);

const getAccessLevels = (subRole) => {
  const accessLevelForAdmin = subRole === 'ReadOnlyAdmin'
    ? filteredItems(fieldIds, notAllowedForReadOnlyAdmin)
    : filteredItems(fieldIds, notAllowedForAdmin);

  return ({
    Admin: accessLevelForAdmin,
    User: filteredItems(fieldIds, notAllowedForUser),
  });
};

export const routesByRoleSeparation = (role, subRole) => (
  filteredItems(mainRoutes, getAccessLevels(subRole)[role], true, 'id')
);

export const menuElementsByRoleSeparation = (role, subRole) => (
  filter(routesByRoleSeparation(role, subRole), item => item.isMenu)
);

export const getSubItemsByParentId = (parentId, role, subRole) => (
  filter(routesByRoleSeparation(role, subRole), item => item.parentId === parentId)
);
