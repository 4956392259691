import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import AuthLayout from '../../components/Auth/AuthLayout';

import userActions from '../../actions/user';

const { loginUser } = userActions.user;

export default connect(null, {
  login: loginUser.action,
})(withImmutablePropsToJS(AuthLayout));
