import { combineActions, handleActions } from 'redux-actions';
import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';

import adminUserActions from '../actions/adminUser';
import userActions from '../actions/user';

const {
  getAdminUsers,
  getOneAdminUser,
  createAdminUser,
  editAdminUser,
  changeAdminUserStatus,
} = adminUserActions.adminUser;

const {
  logout,
} = userActions.user;

const initialState = fromJS({
  list: {
    data: [],
    error: false,
    fetching: false,
  },
  one: {
    data: {},
    error: false,
    fetching: false,
  },
});

const adminUsersReducer = handleActions(
  {
    [combineActions(
      getAdminUsers.fetching,
    )]: state => state
      .set('fetching', true)
      .set('error', false),
    [combineActions(
      getAdminUsers.done,
      getAdminUsers.failure,
      getAdminUsers.error,
    )]: state => state
      .set('fetching', false)
      .set('error', true),
    [combineActions(
      createAdminUser.success,
    )]: (state, { payload }) => {
      const preventList = state.getIn(['data']);
      const newList = fromJS([{ ...payload, marked: true }, ...preventList]);

      return state
        .set('data', newList);
    },
    [combineActions(
      changeAdminUserStatus.success,
      editAdminUser.success,
    )]: (state, { payload }) => {
      const itemIndex = state.get('data')
        .findKey(obj => obj.get('id') === payload.id);

      return state.setIn(['data', itemIndex], fromJS({
        ...payload,
        marked: true,
      }));
    },
    [getAdminUsers.success]: (state, { payload }) => state
      .set('data', fromJS(payload)).set('fetching', false),
    [logout]: () => initialState.get('list'),
  },
  initialState.get('list'),
);

const oneAdminUserReducer = handleActions(
  {
    [combineActions(
      getOneAdminUser.fetching,
    )]: state => state
      .set('fetching', true)
      .set('error', false),
    [combineActions(
      getOneAdminUser.done,
      getOneAdminUser.failure,
      getOneAdminUser.error,
    )]: state => state
      .set('fetching', false)
      .set('error', true),
    [getOneAdminUser.success]: (state, { payload }) => state
      .set('data', fromJS(payload))
      .set('fetching', false),
    [logout]: () => initialState.get('one'),
  },
  initialState.get('one'),
);

export default combineReducers({
  list: adminUsersReducer,
  one: oneAdminUserReducer,
});
