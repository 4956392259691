import React, { useMemo, memo } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import config from 'config';

import Spinner from '../Spinners/CustomSpinner';
import FilePreview from '../Layouts/FilePreview';

const { API_URL } = config;

const FilePreviewModal = (props) => {
  const {
    isModalOpen,
    handleClose,
    title,
    width,
    doc,
    chosenItemId,
    modalId,
    saveFile,
    fetching,
    children,
  } = props;

  const string = useMemo(() => {
    switch (modalId) {
      case 'transaction-invoice':
        return {
          url: `${API_URL}/api/Transactions/Invoice/${chosenItemId}/Download`,
          httpHeaders: {
            Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
            'access-control-allow-credentials': true,
            'Access-Control-Allow-Origin': '*',
          },
        };
      case 'monthly-statement':
        return doc.output('datauristring');
      default:
        return null;
    }
  }, [doc]);

  return (
    <Modal
      show={isModalOpen}
      onHide={handleClose}
      className="custom-modal file-preview-modal with-submit"
      centered
    >
      <Modal.Header closeButton>
        <h3 className="custom-modal__title">{title}</h3>
      </Modal.Header>

      <Modal.Body className={`custom-modal file-preview-modal_body ${fetching ? 'spinner-wrapper' : ''}`}>
        <Spinner fetching={fetching} />

        <If condition={!fetching}>
          <div className="file-preview">
            <If condition={children}>
              {children}
            </If>
            <FilePreview string={string} width={width} saveFile={saveFile} />
          </div>
        </If>
      </Modal.Body>
    </Modal>
  );
};

FilePreviewModal.defaultProps = {
  doc: {},
  saveFile: null,
  fetching: false,
};

FilePreviewModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  chosenItemId: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  doc: PropTypes.shape(),
  modalId: PropTypes.string.isRequired,
  saveFile: PropTypes.func,
  fetching: PropTypes.bool,
};

export default memo(FilePreviewModal);
