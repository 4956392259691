import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import listsActions from '../../../actions/lists';
import withLists from '../../HOC/withLists';
import AccountsContainer from '../../../containers/Pages/Accounts/AccountsContainer';
import JournalOfSelectedAccountContainer
  from '../../../containers/Pages/Accounts/JournalOfSelectedAccountContainer';
import userActions from '../../../actions/user';

const {
  getBanks,
  getCurrencies,
  getCustomersList,
  getLedgerStatuses,
  getFeesTemplates,
  getChargeFees,
  getTransactionFees,
} = listsActions.lists;

const {
  getUserCustomers,
} = userActions.user;

const Accounts = () => (
  <Switch>
    <Route exact path="/accounts/" component={AccountsContainer} />
    <Route path="/accounts/journal/:id" component={JournalOfSelectedAccountContainer} />

    <Redirect to="/accounts/" />
  </Switch>
);

const mapStateToProps = (state) => {
  const {
    banks, ledgerStatuses, feesTemplates,
    chargeFees, transactionFees,
  } = state.get('lists').toJS();

  const { userCustomers } = state.get('userInfo').toJS();

  const role = state.getIn(['userInfo', 'data', 'role']);

  const lists = [
    {
      data: banks.data,
      action: getBanks.action,
    },
    {
      data: transactionFees.data,
      action: getTransactionFees.action,
    },
    {
      data: {}, // that for list request for every render
      action: getCurrencies.action,
    },
    { //  TODO change back after release, it can be mistake
      data: {}, // that for list request for every render
      action: getCustomersList.action,
    },
    {
      data: ledgerStatuses.data,
      action: getLedgerStatuses.action,
    },
  ];

  const listsForAdmin = [{
    data: feesTemplates.data,
    action: getFeesTemplates.action,
  }, {
    data: chargeFees.data,
    action: getChargeFees.action,
  }, {
    data: transactionFees.data,
    action: getTransactionFees.action,
  }];

  const listsForUser = [
    {
      data: userCustomers,
      action: getUserCustomers.action,
    },
  ];

  return role === 'Admin' ? {
    requiredLists: [...lists, ...listsForAdmin],
  } : { requiredLists: [...lists, ...listsForUser] };
};

export default connect(mapStateToProps)(withLists(withImmutablePropsToJS(Accounts)));
