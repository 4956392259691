import { combineActions, handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import { combineReducers } from 'redux-immutable';

import journalActions from '../actions/journal';
import userActions from '../actions/user/userActions';

const { logout } = userActions;

const {
  getJournal,
  getOneJournal,
  editOneJournal,
} = journalActions.journal;

// const initialState = fromJS(fetchReducer);
const initialState = fromJS({
  list: {
    data: [],
    error: false,
    fetching: false,
  },
  one: {
    data: {
      beneficiaryIdNameDictionary: {},
    },
    error: false,
    fetching: false,
  },
});

const journalReducer = handleActions(
  {
    [combineActions(
      getJournal.fetching,
    )]: state => state
      .set('fetching', true)
      .set('error', false),
    [combineActions(
      getJournal.done,
      getJournal.failure,
      getJournal.error,
    )]: state => state
      .set('fetching', false)
      .set('error', true),
    [getJournal.success]: (state, { payload }) => state
      .set('data', fromJS(payload)).set('fetching', false),
    [editOneJournal.success]: (state, { payload }) => {
      const itemIndex = state.get('data')
        .findKey(obj => obj.get('id') === payload.id);
      return state.setIn(['data', itemIndex], fromJS({...payload, marked: true}));
    },
    [logout]: () => initialState.get('list'),
  },
  initialState.get('list'),
);

const oneJournalReducer = handleActions(
  {
    [combineActions(
      getOneJournal.fetching,
      editOneJournal.fetching,
    )]: state => state
      .set('fetching', true)
      .set('error', false),
    [editOneJournal.fetching]: state => state.set('successAction', false),
    [combineActions(
      getOneJournal.done,
      getOneJournal.failure,
      getOneJournal.error,
      editOneJournal.done,
      editOneJournal.failure,
      editOneJournal.error,
    )]: state => state
      .set('fetching', false)
      .set('error', true),
    [getOneJournal.success]: (state, { payload }) => state
      .set('data', fromJS(payload)).set('fetching', false),
    [editOneJournal.success]: (state, { payload }) => state
      .set('successAction', true)
      .set('fetching', false),
    [logout]: () => initialState.get('one'),
  },
  initialState.get('one'),
);

export default combineReducers({
  list: journalReducer,
  one: oneJournalReducer,
});
