import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import listsActions from '../../../actions/lists';
import userActions from '../../../actions/user';
import withLists from '../../HOC/withLists';
import UsersContainer from '../../../containers/Pages/Users/UsersContainer';
import UserActivityLogsContainer from '../../../containers/Pages/Users/UserActivityLogsContainer';

const {
  getBanks,
  getCurrencies,
  getChargeFees,
  getTransactionFees,
  getCustomersList,
} = listsActions.lists;

const {
  getUserCustomers,
} = userActions.user;
const Users = () => (
  <Switch>
    <Route exact path="/users/" component={UsersContainer} />
    <Route path="/users/activity/:id" component={UserActivityLogsContainer} />

    <Redirect to="/users/" />
  </Switch>
);

const mapStateToProps = (state) => {
  const {
    banks, currencies, chargeFees, transactionFees, customers,
  } = state.get('lists').toJS();
  const { userCustomers } = state.get('userInfo').toJS();

  const role = state.getIn(['userInfo', 'data', 'role']);

  const lists = [
    {
      data: banks.data,
      action: getBanks.action,
    },
    {
      data: currencies.data,
      action: getCurrencies.action,
    },
  ];

  const listsForAdmin = [{
    data: chargeFees.data,
    action: getChargeFees.action,
  }, {
    data: transactionFees.data,
    action: getTransactionFees.action,
  }, {
    data: customers.data,
    action: getCustomersList.action,
  }];

  const listsForUser = [
    {
      data: userCustomers,
      action: getUserCustomers.action,
    },
  ];

  return role === 'Admin' ? {
    requiredLists: [...lists, ...listsForAdmin],
  } : { requiredLists: [...lists, ...listsForUser] };
};

export default connect(mapStateToProps)(withLists(withImmutablePropsToJS(Users)));
