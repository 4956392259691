import { latinFieldWithNumbers } from '../../../helpers/fieldsValidation';

export const getBankCodeType = ({ currencyId, bankCountryId }, currencies, sepaCountries) => {
  if (bankCountryId) {
    if (bankCountryId === 'mx') return 7; // Mexico - clabe
    if (bankCountryId === 'cn') return 8; // China - cnaps
    if (bankCountryId === 'au') return 3; // Australia - bsb_code
    if (bankCountryId === 'us') return 2; // United States - aba
  }
  if (bankCountryId && currencyId) {
    if (currencies[currencyId] === 'CAD' && bankCountryId === 'ca') return 4; // Canada - institution_no
    if (currencies[currencyId] === 'GBP' && bankCountryId === 'gb') return 1; // United Kingdom - sort_code
    if (currencies[currencyId] === 'EUR' && Object.keys(sepaCountries).includes(bankCountryId)) return 5; // SEPA - bank_code
  }
  return ''; // without bank code type
};

export const bankCodeTypeAutocomplete = (setValues, e, mainField, values, lists) => {
  const { currencyId: currencyIdList, sepaCountries } = lists;
  const bankCodeType = getBankCodeType({
    ...values, [mainField]: e.target.value,
  }, currencyIdList, sepaCountries);

  setValues({
    [mainField]: e.target.value,
    bankCodeType,
  });
};

export const bankCodeSchema = (currencies, sepaCountries) => latinFieldWithNumbers().trim()
  .when(['currencyId', 'bankCountryId'], (currencyId, bankCountryId, schema) => {
    const bankCodeType = getBankCodeType({ currencyId, bankCountryId }, currencies, sepaCountries);

    const requiredTypes = [1, 2, 4, 7];

    if (requiredTypes.includes(bankCodeType)) {
      return schema.required('Required!');
    }
    return schema;
  });
