import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';

import UniversalInputForm from '../generic/UniversalInputsForm';
import Button from '../generic/Button';
import CustomSpinner from '../Spinners/CustomSpinner';


const PasswordForm = ({
  submitForm,
  validationSchema,
  fields,
  titleForm,
  submitBtnText,
  formId,
  match,
  fetching,
}) => {
  const onSubmit = (values) => {
    if (formId === 'create-pass-form' || formId === 'reset-pass-form') {
      const { id, token } = match.params;
      submitForm({
        userId: id,
        token,
        ...values,
      });

      return;
    }

    submitForm({ ...values });
  };

  return (
    <>
      <CustomSpinner fetching={fetching} fullDimScreen />
      <div className="auth-form auth-form--forgot-pass">
        <div className="auth-form__header">
          <div className="auth-form__title">
            {titleForm}
          </div>
          <NavLink to="/auth/login" className="links">
            Return to Login
          </NavLink>
        </div>
        <div className="auth-form__content">
          <UniversalInputForm
            validationSchema={validationSchema}
            fields={fields}
            onSubmit={onSubmit}
            formId={formId}
            withLabel
          />
          <div className="auth-form__submit-button">
            <Button
              color="gray"
              type="primary"
              size="lg"
              text={submitBtnText}
              form={formId}
              submit
            />
          </div>
        </div>
      </div>
    </>
  );
};

PasswordForm.defaultProps = {
  submitBtnText: 'Submit',
};

PasswordForm.propTypes = {
  submitForm: PropTypes.func.isRequired,
  validationSchema: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.string,
    PropTypes.array,
    PropTypes.bool,
    PropTypes.func,
  ])).isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.object,
  ).isRequired,
  titleForm: PropTypes.string.isRequired,
  submitBtnText: PropTypes.string,
  formId: PropTypes.string.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
      token: PropTypes.string.isRequired,
    }),
  }).isRequired,
  fetching: PropTypes.bool.isRequired,
};

export default withRouter(PasswordForm);
