import React from 'react';
import PropTypes from 'prop-types';
import DualListBox from 'react-dual-listbox';
import classNames from 'classnames';

const InputDuallist = ({
  onChange,
  value,
  items,
  error,
}) => {
  return (
    <div className={classNames('basic-form__group__block__control dual-list',
      {
        error: !!error,
      })}>
      {/*<div className="input-dual-list">*/}

      <DualListBox
        name="samples"
        canFilter
        filterPlaceholder="Filter..."
        options={items}
        selected={value}
        onChange={onChange}
      />
    </div>
  );
};

InputDuallist.defaultProps = {
  value: [],
  items: [],
};

InputDuallist.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.array,
  ]),
  items: PropTypes.arrayOf(PropTypes.object),
};

export default InputDuallist;
