import React, { memo } from 'react';

import AnimatedSpinner from '../../components/Spinners/AnimatedSpinner';
import { BeneficiaryStatusHelper } from '../statusHelper/statusHelper';

const BeneficiaryDeclinedFormatter = ({
  statusText,
  statusAction,
  id,
  requestData: { requestId, fetching },
  // component,
  content,
  declineReason,
  userRole,
}) => {

  // const StatusComponent = component;

  return (
    <>
      <AnimatedSpinner fetching={id === requestId.id && fetching}/>
      <div
        className={`status-block status-block__${content.toLowerCase()} ${id === requestId.id && fetching ? 'content--hide' : 'content--show'}`}>
        <BeneficiaryStatusHelper
          id={id}
          statusAction={statusAction}
          statusText={statusText}
          content={content}
          declineReason={declineReason}
          userRole={userRole}
        />
      </div>
    </>
  );
};

export default memo(BeneficiaryDeclinedFormatter);
