import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FileDrop } from 'react-file-drop';
import classNames from 'classnames';
import { cutFileNameLength } from '../../helpers/other';

const DropZone = ({
  onDrop, errorMessage, fileType, validationSchema, fileNameLength,
}) => {
  const [validErrorMessage, setValidErrorMessage] = useState(null);

  const handleDrop = (params, e) => {
    if (e) {
      e.preventDefault();
    }
    const currentFile = params[0];

    validationSchema
      .validate(currentFile)
      .then(() => {
        const validFile = {
          status: 'valid',
          fileName: cutFileNameLength(currentFile.name, fileNameLength),
          content: currentFile,
          type: currentFile.type,
        };

        onDrop(validFile);
        setValidErrorMessage(null);
      })
      .catch(({ message }) => {
        setValidErrorMessage(message);
      });
  };

  const handleBrowse = (e) => {
    const { files } = e.target;
    if (files) handleDrop(files);
  };

  return (
    <>
      <FileDrop
        onDrop={handleDrop}
        targetClassName="drop_zone"
        draggingOverFrameClassName="drop_zone_over_frame"
        draggingOverTargetClassName="drop_zone_over_target"
      >
        <label className="drop_zone_description" htmlFor="dropDownInput">
          {`Drag ${fileType ? `${fileType} ` : ''}file here or`}
          {' '}
          <span className="drop_zone_description_button">Browse</span>
        </label>
        <input
          className={classNames('input file d-none', { error: !!errorMessage })}
          type="file"
          id="dropDownInput"
          onChange={handleBrowse}
        />
      </FileDrop>
      <div
        className={classNames(
          'basic-form__group__block__invalid-feedback',
          'document',
          { show: validErrorMessage },
        )}
      >
        {validErrorMessage}
      </div>
    </>
  );
};

DropZone.defaultProps = {
  onDrop: null,
  fileType: '',
  fileNameLength: 25,
  errorMessage: '',
};

DropZone.propTypes = {
  onDrop: PropTypes.func,
  fileType: PropTypes.string,
  fileNameLength: PropTypes.number,
  validationSchema: PropTypes.shape().isRequired,
  errorMessage: PropTypes.string,
};

export default DropZone;
