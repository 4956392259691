import PropsTypes from 'prop-types';
import React, { useCallback } from 'react';
import { toastr } from 'react-redux-toastr';

import UniversalModalButton from '../../containers/Modals/UniversalModalButton';
import SearchContainer from '../../containers/Modals/SearchContainer';
import Tooltip from '../../components/generic/Tooltip';
import ReadOnlyPermission from '../../components/HOC/ReadOnlyPermissionHOC';
import {
  tooltipCantDeclineTransaction,
  tooltipTransactionHasNotBeneficiary,
  tooltipCantDeclineTransactionWithoutBank,
  tooltipCantProcessTransaction,
} from '../../helpers/notificatios';

const ProcessTransactionModal = ({
  isVerified,
  approveTransaction,
  processAction,
  processText,
  id,
  createBeneficiaryOnPlatform,
  isAvailableBeneficiaryAtBank,
  userRole,
}) => {
  const confirm = useCallback(() => toastr.confirm(processText,
    { onOk: () => processAction(id) }), [processAction, processText, id]);

  const confirmApproveTransaction = useCallback(() => toastr.confirm('Would you like Approve this transaction?',
    { onOk: () => approveTransaction(id) }), []);

  return (
    <>
      <ReadOnlyPermission
        tooltipId="decline-button"
        declinePermissionText={tooltipCantDeclineTransaction}
        readOnlyComponent={(
          <span
            data-tip="decline-button"
            data-for="decline-button"
          >
            <i className="iconfont-close disabled" />
          </span>
        )}
      >
        <UniversalModalButton
          modalId="reason-transaction-modal"
          inlineButton
          iconClasses="iconfont-close clickable"
          customIcon
          chosenItemId={id}
        />
      </ReadOnlyPermission>

      <SearchContainer text="Pending" className="content" />
      <If condition={userRole === 'Admin'}>
        <ReadOnlyPermission
          tooltipId="process-button-without-bank"
          declinePermissionText={tooltipCantDeclineTransactionWithoutBank}
          readOnlyComponent={(
            <span
              data-tip="process-button-without-bank"
              data-for="process-button-without-bank"
            >
              <i className="iconfont-pending iconfont-pending--process disabled" />
            </span>
          )}
        >
          <i
            onClick={confirmApproveTransaction}
            className="iconfont-pending iconfont-pending--process clickable"
            data-tip={`iconfont-pending--process-${id}`}
            data-for={`iconfont-pending--process-${id}`}
          />
          <Tooltip
            id={`iconfont-pending--process-${id}`}
            text="Approve transaction without bank"
          />
        </ReadOnlyPermission>
        <Choose>
          <When condition={isVerified}>
            <ReadOnlyPermission
              tooltipId="process-button"
              declinePermissionText={tooltipCantProcessTransaction}
              readOnlyComponent={(
                <span
                  data-tip="process-button"
                  data-for="process-button"
                >
                  <i className="iconfont-pending disabled" />
                </span>
              )}
            >
              <>
                <i
                  onClick={confirm}
                  className="iconfont-pending clickable"
                  data-tip={`iconfont-pending-${id}`}
                  data-for={`iconfont-pending-${id}`}
                />
                <Tooltip
                  id={`iconfont-pending-${id}`}
                  text="Bank transaction processing"
                />
              </>
            </ReadOnlyPermission>
          </When>

          <When condition={isAvailableBeneficiaryAtBank}>
            <ReadOnlyPermission
              tooltipId="process-button-is-available"
              declinePermissionText={tooltipTransactionHasNotBeneficiary}
              readOnlyComponent={(
                <span
                  data-tip="process-button-is-available"
                  data-for="process-button-is-available"
                >
                  <i className="iconfont-pending disabled" />
                </span>
              )}
            >
              <div>
                <i
                  onClick={() => toastr.confirm(
                    processText,
                    { onOk: () => createBeneficiaryOnPlatform(id) },
                  )}
                  className="iconfont-pending clickable"
                  data-tip={`info-reason-${id}`}
                  data-for={`info-reason-${id}`}
                />

                <Tooltip
                  id={`info-reason-${id}`}
                  text="This transaction hasn't beneficiary in the bank, and it takes a bit more time for processing this transaction"
                />
              </div>
            </ReadOnlyPermission>
          </When>
        </Choose>
      </If>
    </>
  );
};

ProcessTransactionModal.defaultProps = {
  isVerified: false,
  createBeneficiaryOnPlatform: () => {},
  isAvailableBeneficiaryAtBank: false,
};

ProcessTransactionModal.propTypes = {
  isVerified: PropsTypes.bool,
  processAction: PropsTypes.func.isRequired,
  approveTransaction: PropsTypes.func.isRequired,
  processText: PropsTypes.string.isRequired,
  id: PropsTypes.string.isRequired,
  createBeneficiaryOnPlatform: PropsTypes.func,
  isAvailableBeneficiaryAtBank: PropsTypes.bool,
  userRole: PropsTypes.string.isRequired,
};

export default ProcessTransactionModal;
