import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import feesChargesActions from '../../actions/feesCharges';

import FeesChargesPendingFormatter from '../../lib/statusFormatters/FeesChargesPendingFormatter';

const { executeOneFee } = feesChargesActions.feesCharges;

const mapStateToProps = state => {
  return {
    transactionsStatusChanging: state.getIn(['feesCharges', 'feeStatusChanging']),
  };
};

export default connect(mapStateToProps, {
  processAction: executeOneFee.action,
  createBeneficiaryOnPlatform: e => e.preventDefault(),
})(withImmutablePropsToJS(FeesChargesPendingFormatter));
