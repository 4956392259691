import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';

import { SearchForm, SelectForm } from '../generic/CustomInputs';

const CustomSelectFilter = ({ onFilter, selectItems, formClass }) => {
  const [show, setShow] = useState(false);
  const [filterVal, setFilterVal] = useState('');

  const onBlur = e => {
    const { currentTarget } = e;
    setTimeout(() => {
      // Check if the new activeElement is a child of the original container
      if (!currentTarget.contains(document.activeElement)) {
        // You can invoke a callback or add custom logic here
        setShow(false);
      }
    }, 0);
  };

  const filter = (value, type) => {
    if (typeof value === 'string') {
      setFilterVal(value);
      onFilter({
        type,
        value,
      });
    }

    if (type === 'select') {
      setShow(!show);
    }
  };

  return (
    <div
      className={`filter-wrapper ${show ? 'show' : ''}`}
      onClick={e => e.stopPropagation()}
      onBlur={onBlur}
      tabIndex="10"
    >
      <i
        onClick={() => setShow(!show)}
        className={`iconfont-filter filter-icon ${(show || filterVal) ? 'active' : ''}`}
      />

      <div className={`custom-filter ${formClass}`}>
        <SearchForm inputChange={value => filter(value, 'text')} showItem={filterVal} />

        <SelectForm
          selectChange={value => filter(value, 'select')}
          selectItems={selectItems}
          showItem={filterVal}
        />
      </div>
    </div>
  );
};

CustomSelectFilter.defaultProps = {
  formClass: 'basic',
  selectItems: {
    items: [],
    classes: [],
  },
};

CustomSelectFilter.propTypes = {
  onFilter: PropTypes.func.isRequired,
  selectItems: PropTypes.shape({
    items: PropTypes.array,
    classes: PropTypes.array,
  }),
  formClass: PropTypes.string,
};

export default memo(CustomSelectFilter);
