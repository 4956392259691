import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-actions';
import { pick } from 'lodash';

import {
  GET_TRANSACTIONS,
  GET_ONE_TRANSACTION,
  GET_TRANSACTION_DETAILS,
  SELECT_TRANSACTION,
  SELECT_MANY_TRANSACTION,
  CLEAR_MANY_TRANSACTION,
  PROCESS_ONE_TRANSACTION,
  APPROVE_ONE_TRANSACTION,
  DECLINE_ONE_TRANSACTION,
  CREATE_TRANSACTION,
  GET_CONVERTED_BALANCE,
  DOWNLOAD_INVOICE,
  DOWNLOAD_BUTCH_FILE,
} from '../../constants/constants';

import { selectionOfFilledValues, getUnique } from '../../helpers/other';
import { apiCallAction } from '../actionHelpers';
import { apiCallActionHandler } from '../commonLogic/requestLogic';
import { prepareFormDataWithFile } from '../commonLogic/utilsForActions';
import history from '../../lib/history';
import {
  notifCreateTransaction,
  notifDeclineTransaction, notifErrFailedTransaction, notifErrFailedTransactionServerError,
  notifProcessTransaction, notifApprovedTransaction,
} from '../../helpers/notificatios';
// import * as transactionsTestData from '../../helpers/transactions.json';

const actions = {
  getTransactions: apiCallAction(GET_TRANSACTIONS),
  getOneTransaction: apiCallAction(GET_ONE_TRANSACTION),
  getTransactionDetails: apiCallAction(GET_TRANSACTION_DETAILS),
  processOneTransaction: apiCallAction(PROCESS_ONE_TRANSACTION),
  approveOneTransaction: apiCallAction(APPROVE_ONE_TRANSACTION),
  declineOneTransaction: apiCallAction(DECLINE_ONE_TRANSACTION),
  selectTransaction: createAction(SELECT_TRANSACTION),
  createTransaction: apiCallAction(CREATE_TRANSACTION),
  getConvertedBalance: apiCallAction(GET_CONVERTED_BALANCE),
  selectAllTransactions: createAction(SELECT_MANY_TRANSACTION),
  clearAllTransactions: createAction(CLEAR_MANY_TRANSACTION),
  downloadInvoice: apiCallAction(DOWNLOAD_INVOICE),
  downloadBatchFile: apiCallAction(DOWNLOAD_BUTCH_FILE),
};

const getTransactionsLogic = apiCallActionHandler({
  name: 'getTransactions',
  fetchAction: actions.getTransactions,
  makeApiCall(payload, { api }) {
    return api.transactions.getTransactions(payload);
  },
  transformResponse({ data }) {
    return getUnique(data, 'id');
  },
  transformPayload({ dateFromFormatted, dateToFormatted }) {
    return {
      dateFrom: dateFromFormatted,
      dateTo: dateToFormatted,
    };
  },
});

const getTransactionDetailsLogic = apiCallActionHandler({
  name: 'getTransactions',
  fetchAction: actions.getTransactionDetails,
  makeApiCall(payload, { api }) {
    return api.transactions.getTransactionDetails(payload);
  },
});

const getOneTransactionLogic = apiCallActionHandler({
  name: 'getOneTransaction',
  fetchAction: actions.getOneTransaction,
  makeApiCall(payload, { api }) {
    return api.transactions.getOneTransaction(payload);
  },
  transformResponse({ data }) {
    return selectionOfFilledValues(data);
  },
});

const processOneTransactionLogic = apiCallActionHandler({
  name: 'processOneTransaction',
  fetchAction: actions.processOneTransaction,
  makeApiCall(payload, { api }) {
    return api.transactions.processOneTransaction(payload);
  },
  onSuccess({ id, error }) {
    if (error) {
      toastr.error('Error', notifErrFailedTransaction(id, error));
    } else {
      toastr.success(
        'Success',
        notifProcessTransaction(id),
        { className: 'success_transaction_processing' },
      );
    }
  },
  onError(response, id) {
    toastr.error('Error', notifErrFailedTransactionServerError(id));
  },
});

const approveOneTransactionLogic = apiCallActionHandler({
  name: 'approveOneTransaction',
  fetchAction: actions.approveOneTransaction,
  makeApiCall(payload, { api }) {
    return api.transactions.approveOneTransaction(payload);
  },
  onSuccess({ id, error }) {
    if (error) {
      toastr.error('Error', notifErrFailedTransaction(id, error));
    } else {
      toastr.success(
        'Success',
        notifApprovedTransaction(id),
        // { className: 'success_transaction_processing' },
      );
    }
  },
  onError(response, id) {
    toastr.error('Error', notifErrFailedTransactionServerError(id));
  },
});

const declineOneTransactionLogic = apiCallActionHandler({
  name: 'declineOneTransaction',
  fetchAction: actions.declineOneTransaction,
  makeApiCall(payload, { api }) {
    return api.transactions.declineOneTransaction(payload);
  },
  transformPayload(response, { getState }) {
    return { ...response, userRole: getState().getIn(['userInfo', 'data', 'role']) };
  },
  onSuccess({ displayTransactionId }, { userRole }) {
    toastr.success('Success', notifDeclineTransaction(displayTransactionId, userRole));
  },
  onError(response, { id }) {
    toastr.error('Error', notifErrFailedTransactionServerError(id));
  },
});

const downloadInvoiceLogic = apiCallActionHandler({
  name: 'downloadInvoice',
  fetchAction: actions.downloadInvoice,
  makeApiCall(payload, { api }) {
    return api.transactions.downloadInvoice(payload);
  },
});

const createTransactionLogic = apiCallActionHandler({
  name: 'createTransaction',
  fetchAction: actions.createTransaction,
  makeApiCall(payload, { api }) {
    return api.transactions.createTransaction(payload);
  },
  transformPayload({ amount, ...payload }) {
    const submitTemplate = ['beneficiaryId', 'ledgerId', 'postDate',
      'reference', 'isUrgent', 'isInBeneficiaryCurrency', 'file'];
    const negativeAmount = -Math.abs(amount);
    const d = pick(payload, submitTemplate);

    return prepareFormDataWithFile({
      ...d,
      amount: negativeAmount,
    });
  },
  onSuccess() {
    history.push('/transactions');
    toastr.success(
      'Success',
      notifCreateTransaction,
    );
  },
});

const getConvertedBalanceLogic = apiCallActionHandler({
  name: 'getConvertedBalance',
  fetchAction: actions.getConvertedBalance,
  makeApiCall(payload, { api }) {
    return api.transactions.getConvertedBalance(payload);
  },
});

const downloadBatchFileLogic = apiCallActionHandler({
  name: 'downloadButchFile',
  fetchAction: actions.downloadBatchFile,
  makeApiCall(payload, { api }) {
    return api.transactions.downloadBatchFile(payload);
  },
});

export const logics = [
  getTransactionsLogic,
  getOneTransactionLogic,
  getTransactionDetailsLogic,
  processOneTransactionLogic,
  declineOneTransactionLogic,
  createTransactionLogic,
  downloadInvoiceLogic,
  getConvertedBalanceLogic,
  downloadBatchFileLogic,
  approveOneTransactionLogic,
];

export default actions;
