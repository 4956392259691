import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { keys, pick } from 'lodash';
import {
  BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar,
} from 'recharts';

import { COLORS, compareCurrenciesLabelsHelper } from '../../../helpers/other';
import RenderLegend from './RenderLegend';
import { tooltipFormatter } from './StackedBarChart';

const SimpleBarChart = ({
  items,
}) => {
  const [hidingItems, toggleHidingItems] = useState([]);

  const toggleItemFromChart = (item) => {
    if (hidingItems.includes(item)) {
      toggleHidingItems(hidingItems.filter(el => el !== item));
    } else {
      toggleHidingItems([...hidingItems, item]);
    }
  };

  const elem = keys(pick(items[0], keys(compareCurrenciesLabelsHelper)));

  return (
    <div
      className="flexible-block"
    >
      <div className="flexible-block__header">
        <h3 className="flexible-block__title">Comparison of transactions currency, %</h3>
      </div>
      <BarChart
        width={800}
        height={550}
        data={items}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="currencyName" />
        <YAxis />
        <Tooltip
          formatter={(value, name, props) => tooltipFormatter(value, name, props, keys)}
        />
        <Legend content={(
          <RenderLegend
            onChangeFunc={toggleItemFromChart}
            hidingItems={hidingItems}
          />
        )}
        />
        {elem.map((entry, idx) => (
          <Bar
            dataKey={
              hidingItems.includes(compareCurrenciesLabelsHelper[entry])
                ? 'none'
                : `${entry}.percent`
            }
            key={`cell-${idx}`}
            fill={COLORS[entry]}
            name={compareCurrenciesLabelsHelper[entry]}
          />
        ))}
      </BarChart>
    </div>
  );
};

SimpleBarChart.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.object.isRequired,
  ).isRequired,
};

export default SimpleBarChart;
