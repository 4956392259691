import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import ListPage from '../../../components/Pages/ListPage';
import JournalOfSelectedAccountHeader from '../../Headers/JournalOfSelectedAccountHeader';
import feesActions from '../../../actions/fees';
import accountsActions from '../../../actions/accounts';

const { getJournalsOfAccount, getMonthlyBalanceOfAccount } = accountsActions.accounts;
const { getFeesByLedgerId } = feesActions.fees;

const mapStateToProps = (state) => {
  const currentState = state.getIn(['accounts', 'journalsOfAccount']);

  const userRole = state.getIn(['userInfo', 'data', 'role']);

  return {
    pageWithId: true,
    data: currentState,
    account: [],
    header: JournalOfSelectedAccountHeader,
    title: 'Journal by Account',
    userRole,
    defaultSorted: [{
      dataField: 'postDate',
      order: 'desc',
    }],
  };
};

const mapDispatchToProps = dispatch => ({
  fetchGetData: (id, role) => {
    dispatch(getJournalsOfAccount.action(id));
    dispatch(getMonthlyBalanceOfAccount.action(id));

    if (role === 'Admin') {
      dispatch(getFeesByLedgerId.action(id));
    }
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withImmutablePropsToJS(ListPage));
