import { defaultDateRange } from '../../constants/constants';
import { makeSecureRequest } from '../apiCallHandlers/index';

export default route => ({
  getReportForProcessedTransactions: (payload = {
    dateFromFormatted: defaultDateRange.dateFrom,
    dateToFormatted: defaultDateRange.dateToFormatted,
  }) => {
    const { dateFromFormatted, dateToFormatted } = payload;
    return makeSecureRequest('get', 'Report/ProcessedTransactions', null, {
      dateFrom: dateFromFormatted,
      dateTo: dateToFormatted,
    });
  },
  getTransactionFailed: (payload = {
    dateFromFormatted: defaultDateRange.dateFrom,
    dateToFormatted: defaultDateRange.dateToFormatted,
  }) => {
    const { dateFromFormatted, dateToFormatted } = payload;
    return makeSecureRequest('get', 'Report/TransactionException/Failed', null, {
      dateFrom: dateFromFormatted,
      dateTo: dateToFormatted,
    });
  },
  getTransactionFailedOutsidePreSet: (payload = {
    dateFromFormatted: defaultDateRange.dateFrom,
    dateToFormatted: defaultDateRange.dateToFormatted,
  }) => {
    const { dateFromFormatted, dateToFormatted } = payload;
    return makeSecureRequest('get', 'Report/TransactionException/FallOutLimits', null, {
      dateFrom: dateFromFormatted,
      dateTo: dateToFormatted,
    });
  },
  getTransactionNonPosted: (payload = {
    dateFromFormatted: defaultDateRange.dateFrom,
    dateToFormatted: defaultDateRange.dateToFormatted,
  }) => {
    const { dateFromFormatted, dateToFormatted } = payload;
    return makeSecureRequest('get', 'Report/TransactionException/UnknownStatus', null, {
      dateFrom: dateFromFormatted,
      dateTo: dateToFormatted,
    });
  },
  getTransactionERP: (payload = {
    dateFromFormatted: defaultDateRange.dateFrom,
    dateToFormatted: defaultDateRange.dateToFormatted,
  }) => {
    const { dateFromFormatted, dateToFormatted } = payload;
    return makeSecureRequest('get', 'Report/ERPReport', null, {
      dateFrom: dateFromFormatted,
      dateTo: dateToFormatted,
    });
  },
});
