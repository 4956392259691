import { makeSecureRequest } from './apiCallHandlers/index';

export default route => ({
  getLedgerStatuses: () => makeSecureRequest('get', 'List/LedgerStatuses'),
  getBanks: () => makeSecureRequest('get', 'List/Banks'),
  getBeneficiariesList: payload => makeSecureRequest('get', 'List/BeneficiaryList', null,
    payload && payload.enduserId && { enduserId: payload.enduserId }),
  getCustomersList: () => makeSecureRequest('get', 'List/Customers'),
  getFeesTypes: () => makeSecureRequest('get', 'List/FeesTypes'),
  getRoutingTypes: () => makeSecureRequest('get', 'List/RoutingTypes'),
  getTransactionStatuses: () => makeSecureRequest('get', 'List/TransactionStatuses'),
  getCurrencies: enduserId => makeSecureRequest('get', 'List/Currencies', null,
    enduserId && { enduserId }),
  getEnduserTypes: () => makeSecureRequest('get', 'List/EnduserTypes'),
  getCountries: () => makeSecureRequest('get', 'List/Countries'),
  getSepaCountries: () => makeSecureRequest('get', 'List/SepaCounties'),
  getFees: () => makeSecureRequest('get', 'List/Fees'),
  getChargeFees: () => makeSecureRequest('get', 'List/ChargeFees'),
  getTransactionFees: () => makeSecureRequest('get', 'List/TransactionFees'),
  getTransactionTypes: () => makeSecureRequest('get', 'List/TransactionTypes'),
  getLedgers: payload => makeSecureRequest('get', 'List/Ledgers', null,
    payload && payload.enduserId && { enduserId: payload.enduserId }),
  getTypesOfOwnership: () => makeSecureRequest('get', 'List/TypesOfOwnership'),
  getFeesTemplates: () => makeSecureRequest('get', 'Fee/FeesTemplates'),
  getIndustryTypes: () => makeSecureRequest('get', 'List/IndustryType'),
  getBusinessTypes: () => makeSecureRequest('get', 'List/BusinessType'),
  getDocumentTypes: () => makeSecureRequest('get', 'List/DocumentTypes'),
  getBatchPlatforms: () => makeSecureRequest('get', 'List/BatchPlatforms'),
});
