import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';
import { includes } from 'lodash';

import userActions from '../../actions/user';
import RangeCalendar from '../../components/Layouts/RangeCalendar';
import Search from '../../components/Layouts/Search';
import ExportData from '../../components/Layouts/ExportData';
import UniversalModalButton from '../Modals/UniversalModalButton';
import { periodForLongRequests, periodForLongRequestsForCalendar } from '../../helpers/dates';
import ReadOnlyPermission from '../../components/HOC/ReadOnlyPermissionHOC';
import { tooltipCantCreateSubUser } from '../../helpers/notificatios';
import Button from '../../components/generic/Button';

const { getUsers } = userActions.user;

const UsersHeader = ({
  rangeCalendarAction,
  onExport,
  canExport,
  userRole,
  userCustomers,
}) => {
  useEffect(() => {
    rangeCalendarAction(periodForLongRequests);
  }, []);

  const canCreateSubUser = useMemo(() => {
    if (userRole === 'Admin') {
      return true;
    }
    return includes(userCustomers, 'User');
  }, [userCustomers, userRole]);

  return (
    <>
      <div className="breadcrumbs-block">
        <div>{`${userRole === 'Admin' ? 'Admin / ' : ''}Users`}</div>
        <If condition={canCreateSubUser}>
          <ReadOnlyPermission
            tooltipId="create_user"
            key="create_user"
            declinePermissionText={tooltipCantCreateSubUser}
            readOnlyComponent={(
              <span
                data-tip="create_user"
                data-for="create_user"
              >
                <Button
                  type="secondary"
                  text="+"
                  color="green"
                  size="sm"
                  disabled
                  className="disabled"
                />
              </span>
            )}
          >
            <UniversalModalButton
              modalId={`create-user${userRole === 'Admin' ? '-by-admin' : ''}`}
              text="+"
              color="green"
              type="primary"
              size="sm"
            />
          </ReadOnlyPermission>
        </If>

      </div>
      <div className="data_tools">
        <RangeCalendar
          rangeCalendarAction={rangeCalendarAction}
          customDefaultDate={periodForLongRequestsForCalendar}
        />

        <Search />

        <ExportData onExport={onExport} canExport={canExport} />
      </div>
    </>
  );
};

UsersHeader.propTypes = {
  userRole: PropTypes.string.isRequired,
  rangeCalendarAction: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  userCustomers: PropTypes.objectOf(PropTypes.string).isRequired,
  canExport: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  userRole: state.getIn(['userInfo', 'data', 'role']),
  subRole: state.getIn(['userInfo', 'data', 'subRole']),
  userCustomers: state.getIn(['userInfo', 'userCustomers']),
});

export default connect(mapStateToProps, {
  rangeCalendarAction: getUsers.action,
})(withImmutablePropsToJS(UsersHeader));
