import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withImmutablePropsToJS from 'with-immutable-props-to-js/lib/index';

import Tooltip from '../generic/Tooltip';

const ReadOnlyPermission = ({
  subRole,
  userRole,
  userCustomers,
  tooltipId,
  declinePermissionText,
  children,
  readOnlyComponent,
}) => {
  const isViewOnly = useMemo(() => subRole === 'ReadOnlyAdmin'
    || (userRole === 'User' && Object.values(userCustomers).every(i => i === 'ReadOnlySubUser')),
  [subRole, userCustomers, userRole]);
  return (
    <Choose>
      <When condition={isViewOnly}>
        <>
          {readOnlyComponent}
          <Tooltip
            id={tooltipId}
            text={declinePermissionText}
          />
        </>
      </When>
      <Otherwise>
        {children}
      </Otherwise>
    </Choose>
  );
};

ReadOnlyPermission.defaultProps = {
  declinePermissionText: '',
  tooltipId: '',
};

ReadOnlyPermission.propTypes = {
  declinePermissionText: PropTypes.string,
  tooltipId: PropTypes.string,
  subRole: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  readOnlyComponent: PropTypes.element.isRequired,
  userCustomers: PropTypes.objectOf(PropTypes.string).isRequired,
};

const mapStateToProps = state => ({
  subRole: state.getIn(['userInfo', 'data', 'subRole']),
  userRole: state.getIn(['userInfo', 'data', 'role']),
  userCustomers: state.getIn(['userInfo', 'userCustomers']),
});

export default connect(mapStateToProps)(withImmutablePropsToJS(ReadOnlyPermission));
