import { defaultDateRange } from '../../constants/constants';
import { makeSecureRequest } from '../apiCallHandlers/index';

export default route => ({
  getJournal: (payload = defaultDateRange) => {
    const { dateFromFormatted, dateToFormatted } = payload;
    return makeSecureRequest('get', 'Journal', null, {
      dateFrom: dateFromFormatted,
      dateTo: dateToFormatted,
    });
  },
  getOneJournal: id => makeSecureRequest('get', `Journal/${id}`),
  editOneJournal: ({id, data}) => makeSecureRequest('put', `Journal/${id}`, data),
});
