import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import ListPage from '../../../components/Pages/ListPage';
import AccountsHeader from '../../Headers/AccountsHeader';


const mapStateToProps = (state) => {
  const currentState = state.getIn(['accounts', 'list']);
  return {
    data: currentState,
    header: AccountsHeader,
    title: 'Accounts',
    defaultSorted: [{
      dataField: 'createdDate',
      order: 'desc',
    }],
  };
};

export default connect(mapStateToProps, null)(withImmutablePropsToJS(ListPage));
