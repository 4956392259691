import { combineActions, handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import { combineReducers } from 'redux-immutable';

import reportsActions from '../actions/reports';

import userActions from '../actions/user/userActions';

const {
  getReportForProcessedTransactions,
  getTransactionNonPosted,
  getTransactionFailed,
  getTransactionFailedOutsidePreSet,
  getTransactionERP,
} = reportsActions.reports;
const { logout } = userActions;

const initialState = fromJS({
  processedTransactions: {
    data: [],
    error: false,
    fetching: false,
  },
  notPostedTransaction: {
    data: [],
    error: false,
    fetching: false,
  },
  failedTransactions: {
    data: [],
    error: false,
    fetching: false,
  },
  failedOutsidePreSetTransactions: {
    data: [],
    error: false,
    fetching: false,
  },
  erpTransactions: {
    data: [],
    error: false,
    fetching: false,
  },
});

const processedTransactionsReducer = handleActions(
  {
    [combineActions(
      getReportForProcessedTransactions.fetching,
    )]: state => state
      .set('fetching', true)
      .set('error', false)
      .set('data', []),
    [combineActions(
      getReportForProcessedTransactions.done,
      getReportForProcessedTransactions.failure,
      getReportForProcessedTransactions.error,
    )]: state => state
      .set('fetching', false)
      .set('error', true),
    [combineActions(
      getReportForProcessedTransactions.success,
    )]: (state, { payload }) => state.set('data', fromJS(payload)).set('fetching', false),
    [logout]: () => initialState.get('processedTransactions'),
  },
  initialState.get('processedTransactions'),
);

const notPostedTransactionReducer = handleActions(
  {
    [combineActions(
      getTransactionNonPosted.fetching,
    )]: state => state
      .set('fetching', true)
      .set('error', false)
      .set('data', []),
    [combineActions(
      getTransactionNonPosted.done,
      getTransactionNonPosted.failure,
      getTransactionNonPosted.error,
    )]: state => state
      .set('fetching', false)
      .set('error', true),
    [combineActions(
      getTransactionNonPosted.success,
    )]: (state, { payload }) => state.set('data', fromJS(payload)).set('fetching', false),
    [logout]: () => initialState.get('notPostedTransaction'),
  },
  initialState.get('notPostedTransaction'),
);

const failedTransactionsReducer = handleActions(
  {
    [combineActions(
      getTransactionFailed.fetching,
    )]: state => state
      .set('fetching', true)
      .set('error', false)
      .set('data', []),
    [combineActions(
      getTransactionFailed.done,
      getTransactionFailed.failure,
      getTransactionFailed.error,
    )]: state => state
      .set('fetching', false)
      .set('error', true),
    [combineActions(
      getTransactionFailed.success,
    )]: (state, { payload }) => state.set('data', fromJS(payload)).set('fetching', false),
    [logout]: () => initialState.get('failedTransactions'),
  },
  initialState.get('failedTransactions'),
);

const failedOutsidePreSetTransactionsReducer = handleActions(
  {
    [combineActions(
      getTransactionFailedOutsidePreSet.fetching,
    )]: state => state
      .set('fetching', true)
      .set('error', false)
      .set('data', []),
    [combineActions(
      getTransactionFailedOutsidePreSet.done,
      getTransactionFailedOutsidePreSet.failure,
      getTransactionFailedOutsidePreSet.error,
    )]: state => state
      .set('fetching', false)
      .set('error', true),
    [combineActions(
      getTransactionFailedOutsidePreSet.success,
    )]: (state, { payload }) => state.set('data', fromJS(payload)).set('fetching', false),
    [logout]: () => initialState.get('failedOutsidePreSetTransactions'),
  },
  initialState.get('failedOutsidePreSetTransactions'),
);

const erpTransactionsReducer = handleActions(
  {
    [combineActions(
      getTransactionERP.fetching,
    )]: state => state
      .set('fetching', true)
      .set('error', false)
      .set('data', []),
    [combineActions(
      getTransactionERP.done,
      getTransactionERP.failure,
      getTransactionERP.error,
    )]: state => state
      .set('fetching', false)
      .set('error', true),
    [combineActions(
      getTransactionERP.success,
    )]: (state, { payload }) => state.set('data', fromJS(payload)).set('fetching', false),
    [logout]: () => initialState.get('erpTransactions'),
  },
  initialState.get('erpTransactions'),
);

export default combineReducers({
  processedTransactions: processedTransactionsReducer,
  notPostedTransaction: notPostedTransactionReducer,
  failedTransactions: failedTransactionsReducer,
  failedOutsidePreSetTransactions: failedOutsidePreSetTransactionsReducer,
  erpTransactions: erpTransactionsReducer,
});
