import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const SearchForm = ({ inputChange, showItem }) => {
  const [value, setValue] = useState('');
  const changeValue = (e) => {
    const val = e.target.value;
    inputChange(val);
    setValue(val);
  };

  return (<div className="search-form">
    <i className="iconfont-search search-icon"/>

    <input
      tabIndex="-1"
      type="text"
      placeholder="Search..."
      className="search-form__input"
      value={value === showItem ? value : ''}
      onChange={changeValue}
    />
  </div>);
};

export const SelectForm = ({ selectChange, selectItems, showItem }) => {
  const { items, classes, countItems } = selectItems;

  const customSelectChange = (val) => {
    if (countItems[val] !== 0) {
      selectChange(val);
    }
  };

  const selectsWithIcons = (item, clazz, selectChangeFunc, idx) => (
    <div
      className={(showItem.toLowerCase() === item.toLowerCase()) ? 'active' : ''}
      onClick={() => selectChangeFunc(item)}
      key={idx}
    >
      <span className={clazz} />
      <span className="content">{item}</span>
    </div>
  );

  const getClasses = item => ([
    showItem === item ? 'active' : '',
    countItems[item] === 0 ? 'disabled' : '',
  ].filter(i => i).join(' '));

  return (
    <div className="select-form with-search">
      <If condition={showItem}>
        <div onClick={() => customSelectChange('')} className="show-all" key="100">All</div>
      </If>
      {
        items.map((item, idx) => (
          <div
            onClick={() => customSelectChange(item)}
            key={idx}
            className={`${getClasses(item)} padding-box`}
          >
            {`${item} (${countItems[item]})`}
          </div>
        ))
        }
    </div>
  );
};

SearchForm.propTypes = {
  inputChange: PropTypes.func.isRequired,
  showItem: PropTypes.string.isRequired,
};

SelectForm.propTypes = {
  selectChange: PropTypes.func.isRequired,
  selectItems: PropTypes.shape().isRequired,
  showItem: PropTypes.string.isRequired,
};
