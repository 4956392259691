import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-number-input/max';

const InputPhoneNumber = ({
  selected = '',
  onChange,
  onBlurPost,
  name,
  readOnly,
  propsValue,
  ...props
}) => {
  const [value, setValue] = useState(selected);

  const onChangeValue = (val) => {
    onChange(val);
    setValue(val);
  };

  useEffect(() => {
    setValue(selected);
  }, [selected]);

  const onBlurValue = (e) => {
    const val = e.target.value;
    if (!onBlurPost || val === propsValue) {
      e.preventDefault();
      return;
    }

    onBlurPost({
      name,
      value: val,
    });
  };

  return (
    <>
      <If condition={readOnly}>
        <div className="read-only">
          <PhoneInput
            placeholder="Enter phone number"
            international
            value={value}
            onChange={onChangeValue}
            onBlur={onBlurValue}
            readOnly
          />
        </div>
      </If>
      <If condition={!readOnly}>
        <PhoneInput
          placeholder="Enter phone number"
          international
          value={value}
          onChange={onChangeValue}
          onBlur={onBlurValue}
        />
      </If>
    </>
  );
};

InputPhoneNumber.defaultProps = {
  propsValue: null,
  readOnly: false,
};

InputPhoneNumber.propTypes = {
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  propsValue: PropTypes.string,
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

export default InputPhoneNumber;
