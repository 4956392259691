import React from 'react';

import PropsTypes from 'prop-types';
import Tooltip from '../generic/Tooltip';

const InfoReason = ({ declineReason, id }) => (
  <>
    <span
      className="item-info"
      data-tip={`info-reason-${id}`}
      data-for={`info-reason-${id}`}
    >
      <i className="iconfont-infobox" />
    </span>
    <Tooltip id={`info-reason-${id}`} text={declineReason} />
  </>
);

InfoReason.propTypes = {
  declineReason: PropsTypes.string.isRequired,
  id: PropsTypes.string.isRequired,
};

export default InfoReason;
