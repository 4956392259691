import { connect } from 'react-redux';
import NotificationBar from '../../../components/Pages/Messages/NotificationBar';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

const mapStateToProps = (state) => {
  const unreadMessData = state.getIn(['messages', 'unReadChatList']);
  const userRole = state.getIn(['userInfo', 'data', 'role']);

  return {
    chatList: unreadMessData.get('chatList'),
    fetching: unreadMessData.get('fetching'),
    unReadMess: unreadMessData.get('unReadMess'),
    userRole,
  };
};

export default connect(mapStateToProps)(withImmutablePropsToJS(NotificationBar));
