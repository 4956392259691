import reportsEndpoint from './reportsEndpoint';
import processingErrorsEndpoint from './processingErrorsEndpoint';
import UserActivityEndpoint from './userActivityEndpoint';
import journalEndpoint from './journalEndpoint';

export default {
  reportsEndpoint,
  processingErrorsEndpoint,
  UserActivityEndpoint,
  journalEndpoint,
};
