import { connect } from 'react-redux';
import React, {
  memo, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import moment from 'moment';

import withImmutablePropsToJS from 'with-immutable-props-to-js/lib/index';
import FilePreviewModal from '../../components/Modals/FilePreviewModal';
import accountActions from '../../actions/accounts';
import InputDropdown from '../../components/generic/InputDropdown';
import { nameOfMonth } from '../../lists/lists';
import { generateMonthlyStatementPdfFile } from '../../helpers/pdfFiles';

const {
  getMonthlyStatement,
  getAvailableStatementsDates,
  clearMonthlyStatement,
} = accountActions.accounts;

const MonthlyStatementsModalContainer = (props) => {
  const {
    fetchDates,
    getStatement,
    clearStatement,
    monthlyStatement: {
      data,
      statements,
    },
    id,
  } = props;

  const [{ year, month }, setSubmitDate] = useState({});
  const [selectYearKey, setYearKey] = useState(0);

  const availableYears = useMemo(() => {
    if (!isEmpty(statements)) {
      return statements.map((item => item.year)).sort((a, b) => b - a);
    }
    return [];
  }, [statements]);

  const availableMonths = useMemo(() => {
    if (!isEmpty(availableYears)) {
      const yearData = statements.find(i => i.year === availableYears[selectYearKey]);
      if (yearData) {
        const sorMonths = yearData.months.sort((a, b) => b - a);
        if (!month) {
          setSubmitDate({
            year: availableYears[selectYearKey],
            month: sorMonths[0],
          });
        }
        return sorMonths;
      }
    }
    return [];
  }, [availableYears, selectYearKey]);

  useEffect(() => {
    fetchDates({
      ledgerId: id,
    });
    return () => {
      clearStatement();
    };
  }, []);

  useEffect(() => {
    if (year && month) {
      getStatement({
        ledgerId: id,
        statementDate: moment(`${month}-01-${year}`).format('MM.DD.YYYY'),
      });
    }
  }, [year, month]);

  const newDoc = useMemo(() => {
    return generateMonthlyStatementPdfFile(data);
  }, [data]);

  const saveFile = () => {
    newDoc.save('Monthly statement');
  };

  return (
    <FilePreviewModal {...props} doc={newDoc} saveFile={saveFile}>
      <div className="statement-menu">
        <InputDropdown
          selected={selectYearKey.toString()}
          items={availableYears}
          onChange={value => setYearKey(value)}
        />
        <div className="main-block__side-menu statement-buttons">
          <ul>
            {availableMonths.map(m => (
              <li
                key={m}
                className={`${m === month && year === availableYears[selectYearKey] ? 'active' : ''}`}
                onClick={() => setSubmitDate({
                  year: availableYears[selectYearKey],
                  month: m,
                })}
              >
                <a className="menu-item">{nameOfMonth[m]}</a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </FilePreviewModal>

  );
};

const mapStateToProps = (state) => {
  const monthlyStatement = state.getIn(['accounts', 'journalsOfAccount', 'monthlyStatement']);
  const { isModalOpen, modalFormInfo: { chosenItemId, width, modalId } } = state.get('modal')
    .toJS();
  return {
    monthlyStatement,
    width,
    chosenItemId,
    isModalOpen,
    modalId,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchDates: payload => dispatch(getAvailableStatementsDates.action(payload)),
  getStatement: payload => dispatch(getMonthlyStatement.action(payload)),
  clearStatement: () => dispatch(clearMonthlyStatement()),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(memo(withImmutablePropsToJS(MonthlyStatementsModalContainer)));

MonthlyStatementsModalContainer.defaultProps = {
  monthlyStatement: {
    data: {
      openingBalance: 0,
      closingBalance: 0,
      debitSum: 0,
      creditSum: 0,
      ibanNumber: '',
      transactions: [],
      currency: '',
      ledgerName: '',
      periodFrom: '',
      periodTo: '',
      address: '',
      charges: 0,
    },
    statements: [],
  },
};

MonthlyStatementsModalContainer.propTypes = {
  fetchDates: PropTypes.func.isRequired,
  getStatement: PropTypes.func.isRequired,
  clearStatement: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  monthlyStatement: PropTypes.shape({
    data: {
      openingBalance: PropTypes.number,
      closingBalance: PropTypes.number,
      debitSum: PropTypes.number,
      creditSum: PropTypes.number,
      ibanNumber: PropTypes.string,
      transactions: PropTypes.arrayOf(PropTypes.object),
      currency: PropTypes.string,
      ledgerName: PropTypes.string,
      periodFrom: PropTypes.string,
      periodTo: PropTypes.string,
      address: PropTypes.string,
      charges: PropTypes.number,
    },
    statements: PropTypes.arrayOf(PropTypes.object),
  }),
};
