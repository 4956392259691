import React, { useEffect, memo, useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';

import getModalFormFields from '../../lib/getModalFormFields';
import { extractId } from '../../lib/history';

import UniversalInputsForm from '../generic/UniversalInputsForm';
import Button from '../generic/Button';
import CustomSpinner from '../Spinners/CustomSpinner';
import DataParsingContent from '../Layouts/DataParsingContent';

const UniversalModal = (props) => {
  const {
    userRole,
    fetching,
    globalLists,
    modalFormInfo,
    location: { pathname },
    closeModal,
    cleanModalData,
    dispatchAction,
  } = props;

  const {
    modalId, chosenItemId, data = {}, customModalOptions,
  } = modalFormInfo;

  const {
    fields,
    multiForm,
    multiFields,
    validationSchema,
    lists,
    submitAction,
    getAction,
    title,
    dataParsing = false,
    customModal = null,
    isCustomModal = false,
    obtainInformation = false,
    specialSubmitRequest = false,
    idInPathname = false,
    hideSubmitBtn = false,
    hideCloseBtn = false,
    submitBtnText = 'Submit',
    closeBtnText = 'Cancel',
    templates,
    children = null,
  } = getModalFormFields(modalId, globalLists, userRole);

  useEffect(() => {
    if (obtainInformation && chosenItemId) {
      dispatchAction(getAction(chosenItemId));
    }
  }, [obtainInformation, chosenItemId]);

  const handleClose = useCallback(() => {
    closeModal();
    cleanModalData();
  }, []);

  const onSubmit = useCallback((submitData) => {
    if (submitAction) {
      if (specialSubmitRequest) {
        const id = idInPathname ? extractId(pathname) : chosenItemId;

        dispatchAction(submitAction({
          id,
          data: submitData,
        }));
      } else {
        dispatchAction(submitAction(submitData));
      }
    }
  }, [idInPathname, chosenItemId]);

  const Children = children;

  if (isCustomModal) {
    const CustomModal = customModal;

    return (
      <CustomModal
        title={title}
        id={chosenItemId}
        submitAction={submitAction}
        handleClose={handleClose}
        templates={templates}
        fields={fields}
        multiFields={multiFields}
        fetching={fetching}
        modalId={modalId}
        data={data}
        lists={lists}
        closeBtnText={closeBtnText}
        hideSubmitBtn={hideSubmitBtn}
        hideCloseBtn={hideCloseBtn}
        validationSchema={validationSchema}
        withLabel
        customModalOptions={customModalOptions}
      />
    );
  }

  return (
    <Modal
      show
      onHide={handleClose}
      centered
      className={`custom-modal right-labels ${modalId}`}
    >
      <Modal.Header closeButton>
        <h3 className="custom-modal__title">{title}</h3>
      </Modal.Header>

      <Modal.Body>
        <div className="custom-modal__content">
          <CustomSpinner fetching={fetching} />
          <div className={`info-table ${!fetching ? 'content--show' : 'content--hide-without-transition'}`}>
            <Choose>
              <When condition={dataParsing}>
                <DataParsingContent data={data} />
              </When>

              <Otherwise>
                <UniversalInputsForm
                  propsData={chosenItemId ? data : {}}
                  multiForm={multiForm}
                  validationSchema={validationSchema}
                  fields={fields}
                  onSubmit={onSubmit}
                  formId={modalId}
                  withLabel
                  lists={lists}
                />
              </Otherwise>
            </Choose>
          </div>
        </div>

        {children && <Children />}
      </Modal.Body>

      <Modal.Footer>
        <If condition={!hideSubmitBtn}>
          <Button
            color="gray"
            type="primary"
            size="lg"
            submit
            form={modalId}
            text={submitBtnText}
            disabled={fetching}
          />
        </If>

        <If condition={!hideCloseBtn}>
          <Button
            onClick={handleClose}
            color="gray"
            type="primary"
            size="lg"
            text={closeBtnText}
          />
        </If>
      </Modal.Footer>
    </Modal>
  );
};

UniversalModal.defaultProps = {
  fetching: false,
  globalLists: {},
  modalFormInfo: {},
  dispatchAction: () => {},
};
UniversalModal.propTypes = {
  userRole: PropTypes.string.isRequired,
  fetching: PropTypes.bool,
  globalLists: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.object,
  ])),
  modalFormInfo: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ])),
  closeModal: PropTypes.func.isRequired,
  cleanModalData: PropTypes.func.isRequired,
  dispatchAction: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

const propsAreEqual = ({ globalLists, userRole, modalFormInfo,
}, nextProps) => {
  return (isEqual(globalLists, nextProps.globalLists) && isEqual(userRole, nextProps.userRole) && isEqual(modalFormInfo, nextProps.modalFormInfo))
};

// export default memo(UniversalModal, propsAreEqual);
export default memo(UniversalModal);
