import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-actions';
import {
  GET_FEES_CHARGES,
  EXECUTE_ONE_FEE,
  DECLINE_ONE_FEE,
  SELECT_FEE,
  SELECT_MANY_FEE,
  CLEAR_MANY_FEE,
} from '../../constants/constants';

import { apiCallAction } from '../actionHelpers';
import { apiCallActionHandler } from '../commonLogic/requestLogic';
import {
  notifDeclineOneFee,
  notifErrFailedFeeCharge,
  notifProcessFeeCharge,
} from '../../helpers/notificatios';

const actions = {
  getFeesCharges: apiCallAction(GET_FEES_CHARGES),
  executeOneFee: apiCallAction(EXECUTE_ONE_FEE),
  declineOneFee: apiCallAction(DECLINE_ONE_FEE),
  selectFee: createAction(SELECT_FEE),
  selectAllFees: createAction(SELECT_MANY_FEE),
  clearAllFees: createAction(CLEAR_MANY_FEE),
};

const getFeesChargesLogic = apiCallActionHandler({
  name: 'getFeesCharges',
  fetchAction: actions.getFeesCharges,
  makeApiCall(payload, { api }) {
    return api.fees_charges.getFeesCharges(payload);
  },
});

const executeOneFeeLogic = apiCallActionHandler({
  name: 'executeOneFee',
  fetchAction: actions.executeOneFee,
  makeApiCall(payload, { api }) {
    return api.fees_charges.executeOneFee(payload);
  },
  onSuccess({ id }) {
    toastr.success(
      'Success',
      notifProcessFeeCharge(id),
      { className: 'success_transaction_processing' },
    );
  },
  onError(response, id) {
    toastr.error('Error', notifErrFailedFeeCharge(id));
  },
});

const declineOneFeeLogic = apiCallActionHandler({
  name: 'declineOneFee',
  fetchAction: actions.declineOneFee,
  makeApiCall(payload, { api }) {
    return api.fees_charges.declineOneFee(payload);
  },
  onSuccess({ id }) {
    toastr.success(
      'Success',
      notifDeclineOneFee(id),
    );
  },
  onError(response, id) {
    toastr.error('Error', notifErrFailedFeeCharge(id));
  },
});

export const logics = [
  getFeesChargesLogic,
  executeOneFeeLogic,
  declineOneFeeLogic,
];

export default actions;
