import { customFilter, textFilter } from 'react-bootstrap-table2-filter';
import React from 'react';

import CustomSelectFilter from '../../components/CustomTable/CustomFilter';
import {
  sortHeaderFormatter,
  dateFormatter,
  defaultFormatter,
} from '../tableUtils/tablesUtils';
import BeneficiaryNameFormatter from '../tableUtils/BeneficiaryFormatters/BeneficiaryNameFormatter';
import BeneficiariesStatusFormatter from '../tableUtils/BeneficiaryFormatters/BeneficiaryStatusFormater';
import customSelectionFilter from '../customSelectionFilter';
import { convertDateToLocaleFormat } from '../../helpers/dates';
import { selectUniqValues } from '../../helpers/status';

const beneficiariesColumns = (currentData, userRole, subRole, isReadOnlySubUser) => {
  const beneficiaryStatuses = selectUniqValues(currentData, 'status', {}, [
    'Approved',
    'Canceled',
    'OnHold',
    'Hidden',
  ]);
  const beneficiaryIsValidated = selectUniqValues(currentData, 'isValidated', {
    true: 'Validated',
    false: 'Invalidated',
  });

  const beneficiaryName = {
    dataField: 'beneficiaryName',
    text: 'Beneficiary Name',
    sort: true,
    classes: 'main-field',
    formatter: (cell, props) => (
      <BeneficiaryNameFormatter
        cell={cell}
        props={props}
        subRole={subRole}
        userRole={userRole}
        isReadOnlySubUser={isReadOnlySubUser}
      />
    ),
    headerStyle: () => ({ width: '10%' }),
    headerClasses: 'with-filter',
    filter: customFilter({
      onFilter: (filterObj, data) => {
        if (filterObj.type === 'select') {
          if (filterObj.value === '') {
            return data;
          }

          return customSelectionFilter({
            type: 'select',
            value: filterObj.value !== 'Invalidated',
          }, data, 'isValidated');
        }
        return customSelectionFilter(filterObj, data, 'beneficiaryName');
      },
    }),
    filterRenderer: (onFilter, column) => (
      <CustomSelectFilter
        onFilter={onFilter}
        column={column}
        selectItems={beneficiaryIsValidated}
        formClass="custom-filter-status"
      />
    ),
    wpx: 160,
  };

  return (
    [{
      dataField: 'accountName',
      text: 'Customer Name',
      sort: true,
      formatter: defaultFormatter,
      headerFormatter: sortHeaderFormatter,
      headerStyle: () => ({ width: '8%' }),
      wpx: 170,
    }, beneficiaryName, {
      dataField: 'bankName',
      text: 'Bank Name',
      sort: true,
      formatter: defaultFormatter,
      headerFormatter: sortHeaderFormatter,
      headerStyle: () => ({ width: '7%' }),
      wpx: 160,
    }, {
      dataField: 'address',
      text: 'Address',
      sort: true,
      formatter: defaultFormatter,
      headerFormatter: sortHeaderFormatter,
      headerStyle: () => ({ width: '9%' }),
      wpx: 160,
    }, {
      dataField: 'city',
      text: 'City',
      sort: true,
      formatter: defaultFormatter,
      filter: textFilter(),
      headerFormatter: sortHeaderFormatter,
      headerStyle: () => ({ width: '5%' }),
      wpx: 100,
    }, {
      dataField: 'country',
      text: 'Country',
      sort: true,
      formatter: defaultFormatter,
      headerFormatter: sortHeaderFormatter,
      headerStyle: () => ({ width: '4%' }),
      wpx: 60,
    }, {
      dataField: 'accountNumber',
      text: 'Account Number',
      sort: true,
      formatter: defaultFormatter,
      headerFormatter: sortHeaderFormatter,
      headerStyle: () => ({ width: '9%' }),
      wpx: 160,
    }, {
      dataField: 'currency',
      text: 'Currency',
      sort: true,
      formatter: defaultFormatter,
      headerFormatter: sortHeaderFormatter,
      headerStyle: () => ({ width: '4%' }),
      wpx: 60,
    },
    {
      dataField: 'lastEditDate',
      text: 'Last Edit Date',
      sort: true,
      formatter: (cell, { id }) => dateFormatter(cell, id),
      headerFormatter: sortHeaderFormatter,
      headerStyle: () => ({ width: '5%' }),
      transformationItem: convertDateToLocaleFormat,
      wpx: 120,
    }, {
      dataField: 'createdDate',
      text: 'Created Date',
      sort: true,
      formatter: (cell, { id }) => dateFormatter(cell, id),
      headerFormatter: sortHeaderFormatter,
      headerStyle: () => ({ width: '5%' }),
      transformationItem: convertDateToLocaleFormat,
      wpx: 120,
    }, {
      dataField: 'status',
      text: 'Status',
      sort: true,
      headerStyle: () => ({ width: '5%' }),
      classes: 'status',
      formatter: (cell, data) => (
        <BeneficiariesStatusFormatter cell={cell} data={data} />
      ),
      headerClasses: 'with-filter',
      filter: customFilter({
        onFilter: (filterObj, data) => customSelectionFilter(filterObj, data, 'status'),
      }),
      filterRenderer: (onFilter, column) => (
        <CustomSelectFilter
          onFilter={onFilter}
          column={column}
          selectItems={beneficiaryStatuses}
          formClass="custom-filter-status"
        />
      ),
      wpx: 60,
    }]
  );
};

export default beneficiariesColumns;
