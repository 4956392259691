import print from 'print-js';
import XLSX, { utils } from 'xlsx';
import jsPDF from 'jspdf';
import { applyPlugin } from 'jspdf-autotable';
import { cloneDeep, isEmpty } from 'lodash';

applyPlugin(jsPDF);

const formattingForExport = (columns, rows) => {
  const formattingFields = columns.filter(it => it.transformationItem);
  const data = cloneDeep(rows);

  if (!isEmpty(formattingFields)) {
    return data.map((item) => {
      formattingFields.forEach(({ dataField, transformationItem }) => {
        item[dataField] = transformationItem(item[dataField]);
      });

      return item;
    });
  }
  return data;
};

const formattingColumns = columns => columns
  .filter(({ isExport = true }) => isExport);

const exportData = (type, cols = [], rows = [], title = '', fileHeaderText, fileNameText, userName = 'n/a') => {
  const columns = formattingColumns(cols);
  const data = formattingForExport(columns, rows);
  const headerText = fileHeaderText || `Wirebloom - generated for ${userName}`;
  const fileName = fileNameText || `Wirebloom - generated for ${userName}`;

  switch (type) {
    case 'print': {
      const headers = columns.map(it => ({
        field: it.dataField,
        displayName: it.text,
      }));

      print({
        properties: headers,
        printable: data,
        type: 'json',
        header: headerText,
        headerStyle: 'color: #000; text-align: center; font-size: 26px; font-weight: 400;',
        style: 'tr:nth-child(odd) {background-color: #f4f4f4;} @page { size: Letter landscape; }',
        gridHeaderStyle: 'text-align: center; color: #fff; padding: 4px 3px; text-transform: uppercase; background-color: rgb(45, 65, 84); font-weight: 400; font-size: 18px;',
        gridStyle: 'text-align: center; padding: 4px 3px; font-size: 18px;',
        showModal: true,
        // onLoadingEnd: () => console.log("stop_spinner"),
        // onError: () => console.log("stop_spinner"),
        // fallbackPrintable: () => console.log("stop_spinner"),
      });

      return;
    }
    case 'excel': {
      const dataForExcelExport = data.map((item) => {
        const obj = {};

        columns.forEach(({ dataField }) => {
          obj[dataField] = item[dataField];
        });

        return obj;
      });
      const binaryWS = utils.json_to_sheet(dataForExcelExport);
      const wb = utils.book_new();

      const alphabet = [
        'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M',
        'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
      ];

      binaryWS['!cols'] = columns.map(({ wpx, text, isUpperCase = true }, index) => {
        binaryWS[`${alphabet[index]}1`].v = isUpperCase ? text.toUpperCase() : text;

        return {
          wpx: wpx || 0,
        };
      });

      utils.book_append_sheet(wb, binaryWS, title);

      XLSX.writeFile(wb, `${fileName}.xlsx`, {
        bookType: 'xlsx',
        cellStyles: true,
      });
      return;
    }
    case 'pdf': {
      const headers = columns.map(it => it.text.toUpperCase());
      const body = data.map(item => columns.map(it => item[it.dataField]));
      const isLandscape = headers.length >= 9;

      const doc = jsPDF({ orientation: isLandscape ? 'landscape' : 'portrait' });

      doc.autoTable({
        startY: 5,
        margin: 10,
        theme: 'plain',
        styles: {
          halign: 'center',
          fontSize: 17,
        },
        body: [[headerText]],
      });

      const finalY = doc.previousAutoTable.finalY || 10;
      const columnsStyle = { ...columns.map(col => ({ cellWidth: col.pdfWidth })) };

      doc.autoTable({
        startY: finalY + 5,
        margin: 5,
        styles: {
          valign: 'middle',
          halign: 'left',
          fontSize: 9,
        },
        columnStyles: columnsStyle,
        headStyles: {
          fillColor: [45, 65, 84],
          halign: 'center',
        },
        bodyStyles: {
          textColor: 0,
          halign: 'center',
        },
        head: [headers],
        body,
      });

      doc.save(`${fileName}.pdf`);
    }
  }
};

export default exportData;
