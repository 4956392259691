import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { Redirect, Route, Switch } from 'react-router-dom';
import React from 'react';

import ListPage from '../../../components/Pages/ListPage';
import listsActions from '../../../actions/lists';
import withLists from '../../../components/HOC/withLists';
import AdminUsersHeader from '../../Headers/AdminUsersHeader';
import AdminUserActivityLogContainer from './AdminUserActivityLogContainer';

const {
  getLedgerStatuses,
} = listsActions.lists;

const mapStateToProps = (state) => {
  const currentState = state.getIn(['adminUsers', 'list']);
  const { ledgerStatuses } = state.get('lists').toJS();

  return {
    data: currentState,
    requiredLists: [
      {
        data: ledgerStatuses.data,
        action: getLedgerStatuses.action,
      },
    ],
    header: AdminUsersHeader,
    title: 'Admin Users',
    defaultSorted: [{
      dataField: 'createdDate',
      order: 'desc',
    }],
  };
};

const AdminUserContainer = connect(mapStateToProps)(withLists(withImmutablePropsToJS(ListPage)));

export default () => (
  <Switch>
    <Route exact path="/admin-users/" component={AdminUserContainer} />
    {/*<Route path="/admin-users/activity/:id" component={AdminUserActivityLogContainer} />*/}

    <Redirect to="/users/" />
  </Switch>
);
