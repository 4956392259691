import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import modalActions from '../../actions/modal';
import accountsActions from '../../actions/accounts/accountsActions';
import {
  adjustmentFields,
  newAdjustmentFieldsValidationSchema,
} from '../../schemes/forms/newAdjustment';
import AdjustmentModal from '../../components/Modals/AdjustmentModal';

const { createAdjustment } = accountsActions;
const { closeModal, cleanModalData } = modalActions;

const mapStateToProps = (state) => {
  const { isModalOpen } = state.get('modal')
    .toJS();

  const { chargeFees, currentFees, transactionFees } = state.get('lists')
    .toJS();

  const userRole = state.getIn(['userInfo', 'data', 'role']);
  // TODO: make this logic with lazy loading

  const lists = userRole === 'Admin' ? {
    chargeFees: chargeFees.data,
    currentFees: currentFees.data,
    transactionsFees: transactionFees.data,
  } : {};

  return {
    modalId: 'create-adjustment',
    fetching: state.getIn(['accounts', 'adjustment', 'fetching']),
    fields: adjustmentFields,
    userRole,
    lists,
    transactionFees,
    isModalOpen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(closeModal()),
    cleanModalData: () => dispatch(cleanModalData()),
    submitAction: payload => dispatch(createAdjustment.action(payload)),
  };
};

export default connect(mapStateToProps,
  mapDispatchToProps)(withImmutablePropsToJS(AdjustmentModal));
