import { makeSecureRequest, makeRequest } from './apiCallHandlers/index';
import { defaultDateRange } from '../constants/constants';

export default route => ({
  getOneUser: (id = null) => makeSecureRequest('get', `user/${id}`),
  getUsers: params => makeSecureRequest('get', 'User', null, params),
  getUserActivityLogs: ({
    dateFromFormatted = defaultDateRange.dateFromFormatted,
    dateToFormatted = defaultDateRange.dateToFormatted,
    id,
  }) => makeSecureRequest('get', `UserActivity/${id}/GetUserLog`, null, {
    dateFrom: dateFromFormatted,
    dateTo: dateToFormatted,
  }),
  getUserCustomers: () => makeSecureRequest('get', 'User/Customers'),
  resetPassword: ({ password, token, userId }) => makeRequest('put', 'Auth/PasswordRecovery', {
    token,
    userId,
    newPassword: password,
  }),
  getLinkForRestorePassword: values => makeRequest('get', 'Auth/ForgotPassword', null, values),
  getTokenForChangingUser: id => makeSecureRequest('post', `Auth/loginAsUser/${id}`),
  getLinkForResetPassword: ({ id }) => makeSecureRequest('get', `User/${id}/ResetPassword`),
  changeUserStatus: ({ id, statusId, comment: value }) => makeSecureRequest('put', `User/${id}/SetStatus`, {
    statusId,
    value,
  }),
  createUser: form => makeSecureRequest('post', 'User/', form),
  createSubUser: ({ data }) => makeSecureRequest('post', 'User/CreateSubUser', data),
  createSubUserByAdmin: ({ data }) => makeSecureRequest('post', 'User/AdminCreateSubUser', data),
  updateUser: (id = null, form) => makeSecureRequest('put', 'user/', form, { id }),
  deleteUser: (id = null) => makeSecureRequest('delete', 'user/', null, { id }),
  completeSubUser: ({ values }) => makeSecureRequest('put', 'user/CompleteSubUser', values),
});
