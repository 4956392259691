import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import React from 'react';

import SelectionTransactionFormatter
  from '../../lib/selectionTransactionFormatters/SelectionTransactionFormatter';
import transactionsActions from '../../actions/transactions';

const {
  selectTransaction,
} = transactionsActions.transactions;

const mapStateToProps = (state) => {
  const {
    selected,
    fetching,
    inProcessing,
  } = state.getIn(['transactions', 'transactionsStatusChanging']).toJS();

  return {
    selected,
    inProcessing,
    fetching,
  };
};

const SelectionTransactionFormatterContainer = connect(mapStateToProps,
  { selectTransaction })(withImmutablePropsToJS(SelectionTransactionFormatter));


export default (cell, transaction) => (
  <SelectionTransactionFormatterContainer
    transaction={transaction}
  />
);
