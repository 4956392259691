import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import PropsTypes from 'prop-types';

import Button from '../generic/Button';
import { confirmProcessTransactions } from '../../helpers/notificatios';

const TransactionSelectListToProcessing = ({
  onSubmit, isReady, transactions, fields,
}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = () => {
    if (isReady) {
      setShow(true);
    }
  };

  const handleSubmit = () => {
    onSubmit();
    setShow(false);
  };

  return (
    <>
      <i
        className={`iconfont-pending selection-icon ${isReady ? 'ready' : ''}`}
        onClick={handleShow}
      />

      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal info-modal transaction-select-list"
        centered
      >
        <Modal.Header closeButton>
          <h3 className="custom-modal__title">
            {confirmProcessTransactions}
          </h3>
        </Modal.Header>

        <Modal.Body className="pb-3">
          <BootstrapTable
            keyField="id"
            id="transaction-select-list-to-processing-table"
            classes="transaction-select-list_table"
            data={transactions}
            columns={fields}
            noDataIndication="There are no items yet"
            stripped
            condensed
            bordered={false}
            filter={filterFactory()}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={handleSubmit}
            color="green"
            type="primary"
            size="lg"
            text="Confirm"
          />
          <Button
            onClick={handleClose}
            color="gray"
            type="primary"
            size="lg"
            text="Close"
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

TransactionSelectListToProcessing.defaultProps = {
  isReady: false,
};

TransactionSelectListToProcessing.propTypes = {
  isReady: PropsTypes.bool,
  onSubmit: PropsTypes.func.isRequired,
  transactions: PropsTypes.arrayOf(
    PropsTypes.object,
  ).isRequired,
  fields: PropsTypes.arrayOf(
    PropsTypes.object,
  ).isRequired,
};

export default TransactionSelectListToProcessing;
