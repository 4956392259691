import React, { memo } from 'react';
import PropTypes from 'prop-types';

import AccountPendingFormatter from '../../../containers/Modals/AccountPendingFormatter';
import SearchContainer from '../../../containers/Modals/SearchContainer';
import InfoReason from '../../../components/Layouts/InfoReason';
import { confirmProcessAccount, confirmCancelAccount } from '../../../helpers/notificatios';

const AccountsStatusFormatter = ({
  cell,
  data: {
    status, id, isVerified, declineReason,
  },
}) => {
  const stat = status ? status.toString()
    .toLowerCase() : 'N/a';

  if (stat === 'onhold' || stat === 'active') {
    return (
      <>
        <AccountPendingFormatter
          declineText={confirmCancelAccount}
          processText={confirmProcessAccount}
          id={id}
          isVerified={isVerified}
          declineReason={declineReason}
          status={status}
        />
      </>
    );
  }
  return (
    <div className={`status-block status-block__${stat}`}>
      <span className={`point point__${stat}`} />
      <SearchContainer text={cell} className="content" />
      {declineReason && declineReason !== 'n/a' && (
        <InfoReason
          declineReason={declineReason}
          id={id}
        />
      )}
    </div>
  );
};

export default memo(AccountsStatusFormatter);

AccountsStatusFormatter.propTypes = {
  cell: PropTypes.string.isRequired,
  data: PropTypes.shape({
    status: PropTypes.string,
    id: PropTypes.string,
    isVerified: PropTypes.bool,
    declineReason: PropTypes.string,
  }).isRequired,
};
