export class UserError extends Error {
  constructor(props) {
    super(props);
    const { statusCode, error, message, reasonPhrase } = props;

    this.name = 'UserError';
    this.message = error || message;
    this.statusCode = statusCode;
    this.reasonPhrase = reasonPhrase;
  }
}

export class ServerError extends Error {
  constructor(props) {
    super(props);
    const { statusCode, message, reasonPhrase } = props;

    this.name = 'ServerError';
    this.message = message;
    this.statusCode = statusCode;
    this.reasonPhrase = reasonPhrase;
  }
}
