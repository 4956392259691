import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../generic/Tooltip';
import { tooltipCantExportFiles } from '../../helpers/notificatios';

const ExportData = ({
  onExport,
  canExport,
  fileHeaderText,
  fileNameText,
}) => (
  <>
    <div
      className={`export_data-wrapper hide-tablet ${canExport ? '' : 'disabled'}`}
      data-for="onExport"
      data-tip="onExport"
    >
      <i className="icons print iconfont-printer" onClick={() => onExport('print', fileHeaderText, fileNameText)} />
      <i className="icons excel iconfont-excel" onClick={() => onExport('excel', fileHeaderText, fileNameText)} />
      <i className="icons pdf iconfont-pdf" onClick={() => onExport('pdf', fileHeaderText, fileNameText)} />
    </div>
    <If condition={!canExport}>
      <Tooltip id="onExport" text={tooltipCantExportFiles} />
    </If>
  </>
);

ExportData.defaultProps = {
  canExport: false,
  fileHeaderText: null,
  fileNameText: null,
};

ExportData.propTypes = {
  onExport: PropTypes.func.isRequired,
  canExport: PropTypes.bool,
  fileHeaderText: PropTypes.string,
  fileNameText: PropTypes.string,
};

export default ExportData;
