import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import ListPage from '../../components/Pages/ListPage';
import JournalHeader from '../Headers/JournalHeader';
import withLists from '../../components/HOC/withLists';
import listsActions from '../../actions/lists';

const {
  getChargeFees,
  getTransactionFees,
  getTransactionStatuses,
  getLedgers,
  getTransactionTypes,
} = listsActions.lists;

const mapStateToProps = (state) => {
  const currentState = state.getIn(['journal', 'list']);
  const { chargeFees, transactionFees, transactionStatuses, ledgers, transactionTypes } = state.get('lists').toJS();

  return {
    data: currentState,
    requiredLists: [
      {
        data: chargeFees.data,
        action: getChargeFees.action,
      },
      {
        data: transactionFees.data,
        action: getTransactionFees.action,
      },
      {
        data: transactionStatuses.data,
        action: getTransactionStatuses.action,
      },
      {
        data: ledgers.data,
        action: getLedgers.action,
      },
      {
        data: transactionTypes.data,
        action: getTransactionTypes.action,
      },
    ],
    header: JournalHeader,
    title: 'Journal',
    defaultSorted: [{
      dataField: 'postDate',
      order: 'desc',
    }],
  };
};

export default connect(mapStateToProps)(withLists(withImmutablePropsToJS(ListPage)));
