import { combineActions, handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import { combineReducers } from 'redux-immutable';

import processingErrorsActions from '../actions/processingErrors';
import userActions from '../actions/user/userActions';

const { logout } = userActions;

const { getProcessingErrors } = processingErrorsActions.processingErrors;

const initialState = fromJS({
  list: {
    data: [],
    error: false,
    fetching: false,
  },
  one: {
    data: {},
    error: false,
    fetching: false,
  }
});

const processingErrorsReducer = handleActions(
  {
    [combineActions(
      getProcessingErrors.fetching,
    )]: state => state
      .set('fetching', true)
      .set('error', false),
    [combineActions(
      getProcessingErrors.done,
      getProcessingErrors.failure,
      getProcessingErrors.error,
    )]: state => state
      .set('fetching', false)
      .set('error', true),
    [getProcessingErrors.success]: (state, { payload }) => state
      .set('data', fromJS(payload))
      .set('fetching', false),
    [logout]: () => initialState.get('list'),
  },
  initialState.get('list'),
);


export default combineReducers({
  list: processingErrorsReducer,
});
