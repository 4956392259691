import { filter, map, includes } from 'lodash';

export const trimString = (str, n) => {
  const array = str.split('\n');
  const spliceArr = array.splice(0, n);
  return spliceArr.join('\n');
};

export const getIds = data => map(data, ('id'));


/**
 * @param {array} initialFieldsArr initial array of objects.
 * @param {array} fieldNames ['name_1', 'name_2', ...].
 * @param {boolean} include, true - include, false - exclude.
 * @param {null || string} key, fieldNames key name.
 * @return {array} filtered array of objects 'initialFieldsArr'.
 */

export const filteredItems = (initialFieldsArr, fieldNames = [],
  include = false, key = null) => (
  filter(initialFieldsArr, d => includes(fieldNames, key ? d[key] : d) === include)
);
