import { cloneDeep, isEmpty } from 'lodash';

const clearCache = (isReloadAfterClear = true) => {
  if ('caches' in window) {
    caches.keys().then((names) => {
      names.forEach(async (name) => {
        await caches.delete(name);
      });
    });

    if (isReloadAfterClear) window.location.reload();
  }
};

export const deauthenticateUser = (withClearCache = true) => {
  if (withClearCache) {
    clearCache();
    localStorage.setItem('lastRoute', window.location.pathname);
  } else {
    localStorage.removeItem('lastRoute');
  }
  localStorage.removeItem('auth_token');
  localStorage.removeItem('refresh_token');
};

export function parseJwt(token) {
  if (isEmpty(token) || token === 'null') {
    deauthenticateUser();
    return false;
  }
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

  return JSON.parse(decodeURIComponent(atob(base64)
    .split('')
    .map(c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`)
    .join('')));
}

export const addSubRoleToUserInfo = (userData) => {
  const data = cloneDeep(userData);

  if (data.role === 'Admin' || data.role === 'ReadOnlyAdmin' || data.role === 'SuperAdmin') {
    // data.subRole = 'ReadOnlyAdmin';
    data.subRole = userData.role;
    data.role = 'Admin';
  } else {
    // data.subRole = 'ReadOnlySubUser';
    // data.subRole = 'SubUser';
    data.subRole = userData.role;
    data.role = 'User';
  }

  return data;
};

export const authenticateUser = (authToken, refreshToken) => {
  localStorage.setItem('auth_token', authToken);
  localStorage.setItem('refresh_token', refreshToken);
};

export const changeUserToken = (token) => {
  localStorage.clear();
  authenticateUser(token, 'refresh_token');
  window.location.replace(window.location.origin);
};

export const getUserRole = () => {
  const userInfo = JSON.parse(localStorage.getItem('user_info'));
  return userInfo ? userInfo.role : 'make_logout';
};

export const isUserAuthenticated = () => !!localStorage.getItem('auth_token') && !!localStorage.getItem('refresh_token');
