import React from 'react';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import AccountSetupStep from '../../components/Pages/AccountSetup/AccountSetupStep';

const mapStateToProps = state => {
  const { countries, industryTypes, businessTypes } = state.get('lists')
    .toJS();

  return {
    // validationSchema: personalInfoValidationSchema,
    // fields: personalInfoFields,
    formName: 'personal-info',
    lists: {
      countryId: countries.data,
      industryTypeId: industryTypes.data,
      typeOfBusinessId: businessTypes.data,
    },
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmit: form => {
      ownProps.nextTab();
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withImmutablePropsToJS(AccountSetupStep));
