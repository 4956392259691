import { toastr } from 'react-redux-toastr';

import {
  GET_ADMIN_USERS,
  GET_ONE_ADMIN_USER,
  CREATE_ADMIN_USER,
  EDIT_ADMIN_USER,
  CHANGE_ADMIN_USER_STATUS,
} from '../../constants/constants';
import { apiCallAction } from '../actionHelpers';
import { apiCallActionHandler } from '../commonLogic/requestLogic';
import {
  notifChangedAdminUserStatus,
  notifCreateAdminUser,
  notifEditAdminUser
} from '../../helpers/notificatios';

const actions = {
  getAdminUsers: apiCallAction(GET_ADMIN_USERS),
  getOneAdminUser: apiCallAction(GET_ONE_ADMIN_USER),
  createAdminUser: apiCallAction(CREATE_ADMIN_USER),
  editAdminUser: apiCallAction(EDIT_ADMIN_USER),
  changeAdminUserStatus: apiCallAction(CHANGE_ADMIN_USER_STATUS),
};

const getAdminUsersLogic = apiCallActionHandler({
  name: 'getAdminUsers',
  fetchAction: actions.getAdminUsers,
  makeApiCall(payload, { api }) {
    return api.adminUser.getAdmins(payload);
  },
  transformPayload({ dateFromFormatted, dateToFormatted }) {
    return {
      dateFrom: dateFromFormatted,
      dateTo: dateToFormatted,
    };
  },
});

const getOneAdminUserLogic = apiCallActionHandler({
  name: 'getOneAdminUser',
  fetchAction: actions.getOneAdminUser,
  makeApiCall(payload, { api }) {
    return api.adminUser.getOneAdminUser(payload);
  },
});

const createAdminUserLogic = apiCallActionHandler({
  name: 'createAdminUser',
  fetchAction: actions.createAdminUser,
  makeApiCall(payload, { api }) {
    return api.adminUser.createAdminUser(payload);
  },
  onSuccess() {
    toastr.success(
      'Success',
      notifCreateAdminUser,
    );
  },
});

const editAdminUserLogic = apiCallActionHandler({
  name: 'editAdminUser',
  fetchAction: actions.editAdminUser,
  makeApiCall(payload, { api }) {
    return api.adminUser.editAdminUser(payload);
  },
  onSuccess() {
    toastr.success(
      'Success',
      notifEditAdminUser,
    );
  },
});

const changeAdminUserStatusLogic = apiCallActionHandler({
  name: 'changeAdminUserStatus',
  fetchAction: actions.changeAdminUserStatus,
  makeApiCall(payload, { api }) {
    return api.users.changeUserStatus(payload);
  },
  onSuccess() {
    toastr.success(
      'Success',
      notifChangedAdminUserStatus,
    );
  },
});

export const logics = [
  getAdminUsersLogic,
  getOneAdminUserLogic,
  createAdminUserLogic,
  editAdminUserLogic,
  changeAdminUserStatusLogic,
];

export default actions;
