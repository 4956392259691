export const ledgerStatuses = {
  '1': 'Pending',
  '2': 'Approved',
  '3': 'Declined',
};

export const banks = {
  '166ba6c2-762f-4ca2-9841-4d57cec69860': 'KBC',
  '09b33966-fe65-49fd-9711-0d56101b706b': 'LHV Bank',
  'a6a6711e-b4b6-422b-8dd2-ca82f5338708': 'BFC Bank',
  'c7762ebd-98b6-4f65-a149-50e49f9b41c7': 'Rietumu',
  '27e721c7-2f97-4a4e-bca1-d1aa3d9938df': 'Bank Frick',
  '652c0420-8a0f-4f0a-9e4c-fc69a691dd39': 'Modulr',
  'b2907360-4132-4be9-b3ce-cb1da955e39e': 'DHB',
  'e57559f9-08ca-423e-b3ee-59e6a25f3fef': 'Rails',
  '3649f0dd-612e-4ed2-9c15-5a9ed772bb79': 'DynaPay',
  'bb42cb0d-33ae-425d-aa5d-44fc224ba10f': 'CurrencyCloud',
};

export const feesTypes = {
  '1': 'FixedAmount',
  '2': 'Percentage',
};

export const currencies = {
  '1': 'GBP',
  '2': 'EUR',
  '3': 'AED',
  '4': 'AFN',
  '5': 'ALL',
  '6': 'AMD',
  '7': 'ANG',
  '8': 'AOA',
  '9': 'ARS',
  '10': 'AUD',
  '11': 'AWG',
  '12': 'AZN',
  '13': 'BAM',
  '14': 'BBD',
  '15': 'BDT',
  '16': 'BGN',
  '17': 'BHD',
  '18': 'BIF',
  '19': 'BMD',
  '20': 'BND',
  '21': 'BOB',
  '22': 'BOV',
  '23': 'BRL',
  '24': 'BSD',
  '25': 'BTN',
  '26': 'BWP',
  '27': 'BYN',
  '28': 'BZD',
  '29': 'CAD',
  '30': 'CDF',
  '31': 'CHE',
  '32': 'CHF',
  '33': 'CHW',
  '34': 'CLF',
  '35': 'CLP',
  '36': 'CNY',
  '37': 'COP',
  '38': 'COU',
  '39': 'CRC',
  '40': 'CUC',
  '41': 'CUP',
  '42': 'CVE',
  '43': 'CZK',
  '44': 'DJF',
  '45': 'DKK',
  '46': 'DOP',
  '47': 'DZD',
  '48': 'EGP',
  '49': 'ERN',
  '50': 'ETB',
  '51': 'FJD',
  '52': 'FKP',
  '53': 'GEL',
  '54': 'GHS',
  '55': 'GIP',
  '56': 'GMD',
  '57': 'GNF',
  '58': 'GTQ',
  '59': 'GYD',
  '60': 'HKD',
  '61': 'HNL',
  '62': 'HRK',
  '63': 'HTG',
  '64': 'HUF',
  '65': 'IDR',
  '66': 'ILS',
  '67': 'INR',
  '68': 'IQD',
  '69': 'IRR',
  '70': 'ISK',
  '71': 'JMD',
  '72': 'JOD',
  '73': 'JPY',
  '74': 'KES',
  '75': 'KGS',
  '76': 'KHR',
  '77': 'KMF',
  '78': 'KPW',
  '79': 'KRW',
  '80': 'KWD',
  '81': 'KYD',
  '82': 'KZT',
  '83': 'LAK',
  '84': 'LBP',
  '85': 'LKR',
  '86': 'LRD',
  '87': 'LSL',
  '88': 'LYD',
  '89': 'MAD',
  '90': 'MDL',
  '91': 'MGA',
  '92': 'MKD',
  '93': 'MMK',
  '94': 'MNT',
  '95': 'MOP',
  '96': 'MRU',
  '97': 'MUR',
  '98': 'MVR',
  '99': 'MWK',
  '100': 'MXN',
  '101': 'MXV',
  '102': 'MYR',
  '103': 'MZN',
  '104': 'NAD',
  '105': 'NGN',
  '106': 'NIO',
  '107': 'NOK',
  '108': 'NPR',
  '109': 'NZD',
  '110': 'OMR',
  '111': 'PAB',
  '112': 'PEN',
  '113': 'PGK',
  '114': 'PHP',
  '115': 'PKR',
  '116': 'PLN',
  '117': 'PYG',
  '118': 'QAR',
  '119': 'RON',
  '120': 'RSD',
  '121': 'RUB',
  '122': 'RWF',
  '123': 'SAR',
  '124': 'SBD',
  '125': 'SCR',
  '126': 'SDG',
  '127': 'SEK',
  '128': 'SGD',
  '129': 'SLL',
  '130': 'SOS',
  '131': 'SRD',
  '132': 'SSP',
  '133': 'STN',
  '134': 'SVC',
  '135': 'SYP',
  '136': 'SZL',
  '137': 'SHP',
  '138': 'THB',
  '139': 'TJS',
  '140': 'TMT',
  '141': 'TND',
  '142': 'TOP',
  '143': 'TRY',
  '144': 'TTD',
  '145': 'TWD',
  '146': 'TZS',
  '147': 'UAH',
  '148': 'UGX',
  '149': 'USD',
  '150': 'USN',
  '151': 'UYI',
  '152': 'UYU',
  '153': 'UYW',
  '154': 'UZS',
  '155': 'VES',
  '156': 'VND',
  '157': 'VUV',
  '158': 'WST',
  '159': 'XAF',
  '160': 'XAG',
  '161': 'XAU',
  '162': 'XBA',
  '163': 'XBB',
  '164': 'XBC',
  '165': 'XBD',
  '166': 'XCD',
  '167': 'XDR',
  '168': 'XOF',
  '169': 'XPD',
  '170': 'XPF',
  '171': 'XPT',
  '172': 'XSU',
  '173': 'XTS',
  '174': 'XUA',
  '175': 'XXX',
  '176': 'YER',
  '177': 'ZAR',
  '178': 'ZMW',
  '179': 'ZWL'
};

export const countries = {
  'e30bd58a-6d99-4848-ba8d-d0e2bdb9a3f8': 'Niger',
  '44e3c18c-7e17-4eeb-94bf-a28744c09e2d': 'Nigeria',
  '6fcb5d29-4c4f-447a-a876-ea96289faf0e': 'Oman',
  'b85c6476-31dd-4cd6-81cf-0586f7779780': 'Palau',
  'ba53b896-39c1-48d7-94b0-7da8bf577e6a': 'Palestine, State of',
  'f0ab1599-37c1-4ebf-9b42-f301876dc88b': 'Philippines',
  '4a0dd8e8-a6b1-401b-afa2-209d0c8cd5a9': 'Pitcairn',
  '1d209ac7-70bb-4e77-8499-6d258180a2ba': 'Poland',
  'd7a9272b-e8a0-4efa-8cc9-fbd2b3561a52': 'Puerto Rico',
  '058ba845-0014-4693-8139-941d14d0fb5a': 'Réunion',
  '1d394b0d-ce4f-4cc7-b898-ca6d561a79a6': 'Romania',
  '4299244c-1a33-48fb-9786-a14661f9b513': 'Rwanda',
  'e55883d1-9bed-4d42-87d9-c10aeddd7964': 'Saint Barthélemy',
  '0b854d17-8a91-4058-98ac-b5d5f0c32aed': 'Nepal',
  '02905192-50e7-43a0-a697-0b294b0e8fee': 'Namibia',
  '9ca75388-1c74-40e3-99ec-dae1ecf886b6': 'Libya',
  '261b1007-8197-4752-91de-1f967fc3d5dd': 'Lithuania',
  '94c5ad4b-de20-46e1-b959-eadbeb6ecd3f': 'Macao',
  '332ec735-53f5-4073-bf21-037bdaf8db3d': 'Macedonia',
  '08a76e7c-74bd-4bc3-95f8-6730f82a45d4': 'Madagascar',
  'a9890092-f60e-4953-a646-b82d2c8bac81': 'Malaysia',
  '7a253c0f-9956-4c77-a9d9-b8f9d6f9a294': 'Maldives',
  '503fa07b-366f-485c-aa71-869051b0ba01': 'Mauritania',
  '5e1568b0-5531-4bf3-969d-4d6a319b2332': 'Montenegro',
  'f2293037-81b6-411b-b644-9252b41b871a': 'Timor-Leste',
  'ecadd1ae-636a-4353-86d7-6720e8d3a7e8': 'Tokelau',
  '329584bb-f86b-4504-9f3c-c197291f9909': 'Trinidad and Tobago',
  'd647c162-eedd-4bd0-86e5-b81202b25567': 'Turks and Caicos Islands',
  '36572db0-9c56-47ce-9ba7-c0ab0be8107a': 'Uganda',
  '3c5df2a7-7dea-4607-9191-2927ce21ff34': 'Ukraine',
  '8ea7df44-dfc1-4ca5-8cc7-4d44600c782b': 'United Arab Emirates',
  'e3a0f1bf-f288-43c4-91e8-022ec8831210': 'United States of America',
  '45f6e20e-8a59-4791-9522-b4df6118d033': 'U.S. Virgin Islands',
  'e5ae9c3e-e4b7-4f17-b5fd-7f139b131073': 'Wallis and Futuna',
  '9050e65b-5003-4d2b-8c93-6a1be9a6fc5b': 'Yemen',
  '7bf782ae-c5a0-4274-937c-cd15828a315a': 'Tajikistan',
  'a9115a5c-f7e1-4673-a9e6-7f4086f51d18': 'Syrian Arab Republic',
  '0d578498-2bcf-4d14-85d3-50b5166f6912': 'Samoa',
  '748a680a-ab5f-4578-b3f6-7e9213eb0b40': 'Saudi Arabia',
  '2e9799df-6800-424b-84c6-43426ca053c3': 'Seychelles',
  '27151246-8959-4084-a31d-1134f01aae88': 'Somalia',
  '173be74d-6d9a-47d4-a178-a71f1522c140': 'Spain',
  '06bea97b-6c20-45b9-9eb5-613c20d2c7d9': 'Suriname',
  'dd092b3b-f660-4e80-9947-10c695c9d1dc': 'Svalbard and Jan Mayen',
  '24a0fc88-ba14-4598-a900-45d79e486a71': 'Switzerland',
  '04f7daaa-cc4c-4c6c-b55d-747703b7d423': 'Bulgaria',
  '8c0e82cc-ecfe-4b1f-83d8-0021938c5e64': 'Cocos (Keeling) Islands',
  '742809b0-3b95-4370-bbc2-9850b6b53aa7': 'Colombia',
  '50e41eb8-f9a7-49b2-876c-cc967f055f7c': 'Congo',
  '5a6cd71e-c2fc-4dcd-b5fb-35677718b47c': 'Côte d\'Ivoire',
  '1ef2bbfa-64f0-45a2-bb61-814ab6314687': 'Croatia',
  '7b05ee41-a7d8-465d-b70d-90941e831c30': 'Cyprus',
  'a35b5924-f2c1-4f1b-984f-f7a2124a4636': 'Brazil',
  'f094df50-50fa-4eb6-88e5-bf828bd34735': 'Bosnia and Herzegovina',
  '8b2f5db1-33b9-4f42-922a-6ee591657f0b': 'Andorra',
  'c615f669-5317-4eb4-bd47-4c35c16e1a78': 'Anguilla',
  '7ed580a6-36d7-4ce5-9573-49f2d6f1d964': 'Antigua and Barbuda',
  '27f1ffed-19f7-43c9-8b3d-a36cf46d7bab': 'Armenia',
  'ea87ad47-dc7c-4dc1-a0b4-c686ef7f3b18': 'Aruba',
  '1ef18fe5-edfb-4e22-b6d8-041e35b10440': 'Belarus',
  'ff86f5b4-bbd6-4b52-8710-997d62a6ab95': 'Benin',
  '36fc457d-f238-437d-a08d-65f3986e3560': 'Djibouti',
  '28fbb24c-e2d8-49fb-9038-a286a940bb0f': 'Haiti',
  '9d5f782d-4aec-4b99-8202-5b9621275807': 'Honduras',
  '3d728e1c-dc34-418b-98cc-75f354bd2f16': 'Hungary',
  'd62e410f-0b0f-4384-932b-1709fef4abfb': 'Indonesia',
  'e98c9923-cf71-4f64-bbc3-8d4cc06bb168': 'Iraq',
  'd0b5af9b-5707-4f83-84c6-b3de7fe97a2d': 'Ireland',
  '975080c2-250b-4182-a813-fa370fa213d0': 'Isle of Man',
  '2b9d4820-0370-42ae-b082-fd5f1760d679': 'Israel',
  'fe82087c-e7ae-4a22-9d66-9b3b8c4b5bcd': 'Italy',
  '96c83638-49f6-4631-8cc8-084a050a0cc0': 'Jamaica',
  'a6b4c34a-d852-44e1-841d-041dba09f5b2': 'Kazakhstan',
  '94852fb1-1f68-40fa-8658-fedca8d5ac19': 'Kenya',
  'fdd0cc8e-8499-4c95-847e-5129b804af0e': 'Republic ofKorea',
  '1c28d894-ffec-451b-8e99-14aa04779fce': 'Eritrea',
  '98819aee-d466-40eb-970a-ae8470931c74': 'Ethiopia',
  'f9a03afd-60fc-47f3-ab71-d7b76facea8f': 'Lebanon',
  '08a111d8-ce5d-4959-a12e-4ab753b9673b': 'French Southern Territories',
  'e15109f8-0dad-4488-97e4-c4c07caf5947': 'Gibraltar',
  '6b2a7aef-7e9b-47a5-82c5-3b672122f85e': 'Greece',
  '45b7cd69-bc0a-4661-bc4b-4437b1b60db4': 'Northern Mariana Islands',
  '6e2c9064-52b8-474a-b145-02cfb72dec1d': 'Norway',
  'b646f191-9b41-448b-b1a1-9e73a6678dc8': 'Papua New Guinea',
  '42038a7a-95c3-478e-89fe-96a4b5f24284': 'Portugal',
  'f008d2dd-6497-45e0-acb3-4e792bb66e46': 'Qatar',
  '655c6387-c68b-4a6a-9da6-20ebc6e656a2': 'Russian Federation',
  '313264b1-2f08-4944-bdc5-566d83b57cf7': 'Mali',
  'c796629c-56ac-4cf7-8442-accf7e89291f': 'Malta',
  'c7f5d6ae-0923-442a-81c8-9ef666b79d92': 'Marshall Islands',
  '12fc8794-5c17-4eda-a493-3af789adf316': 'Martinique',
  '37c3238d-ea9d-4dbf-ab51-c6f302900c31': 'Mauritius',
  '58aaf0f2-02f4-4377-94d8-586b90ccd068': 'Mayotte',
  '6c747d54-b757-4f0d-9d95-6e47462bb335': 'Micronesia',
  'e61c628e-92c5-4dde-99ec-ac4396111cb6': 'Monaco',
  '9e27f928-acf5-45cc-b30f-952b6ebfddbe': 'Montserrat',
  '9ebc7ac3-5aad-4366-9e37-9ffed5b06636': 'Saint Helena, Ascension and Tristan da Cunha',
  'e56a92d1-e318-4ec3-93c0-126ee841171e': 'Saint Lucia',
  '5d7b6e88-b822-436b-b575-d38232c3a4c9': 'Turkey',
  '29600175-edd0-44c7-82c7-55f8b23fc839': 'United Kingdom',
  '674dda0c-d109-4aa9-9951-5463ec9cdcf2': 'United States Minor Outlying Islands',
  'ea422d27-c941-4849-a28f-c969a1c1cdcb': 'Vanuatu',
  '9ee68f38-ff33-442a-a933-1422544f61a3': 'Tanzania, United Republic of',
  '9b98c9b9-1f63-4c7f-b13f-1de5fb6bfe8d': 'Taiwan',
  '327388f2-447f-4471-91eb-34112db70fd9': 'Saint Martin',
  '63b0b31a-80eb-4a71-a130-abb0f7bc57cd': 'Sao Tome and Principe',
  '1e2ed6cd-ffc2-48fc-a0ab-8374cd6bbb91': 'Sierra Leone',
  '15babd2c-e856-441d-8dcc-e7b942e67b55': 'Liberia',
  '8e29233d-e50c-4bbf-a7f4-43465cf3544d': 'South Georgia and the South Sandwich Islands',
  '58253722-6bff-4d6f-8b6d-790c9f581d2a': 'South Sudan',
  '9e8adef0-3abc-4161-bd14-84db45bddbbd': 'Sudan',
  'db3f4d97-e040-4de0-9a12-a46250dba086': 'Lesotho',
  '192c128d-08bf-4b9c-84a7-00b2ef41288f': 'Latvia',
  '80efd592-2ac3-4b40-9d33-a06d047a08bb': 'British Indian Ocean Territory',
  'e20ae660-f627-4f3a-81f8-c1473e5dac2c': 'Brunei Darussalam',
  'e2613a84-30d7-4d2a-b091-5504eb262c48': 'Burkina Faso',
  'f2f5ad85-d62d-46ec-9194-dd304d1d27bb': 'Burundi',
  'a9a4ba1e-b20c-4686-9827-8a7d47a02aed': 'Cambodia',
  'd90a69be-6f73-4aa1-aa0e-06e4cef470e6': 'Canada',
  '9d8b61f7-6af7-4ee7-9003-940b65fb158d': 'Cayman Islands',
  'b5d42b3b-ae27-48e1-960d-ce427f817c88': 'Chad',
  'ade70265-9274-425e-922c-488fbedbba21': 'Costa Rica',
  '1e032b62-322f-49a9-95fb-847dbcea7c35': 'Curaçao',
  '1433b784-c4f0-47b1-a3af-fb0bf69503c7': 'Czechia',
  'fee86eef-9500-4964-85fa-80ceb95510fa': 'Bouvet Island',
  'd42bc1e0-5160-4340-8df9-0866e4d7b940': 'Åland Islands',
  '5c83f82b-392f-47c5-8973-0c4ed5e12f65': 'Albania',
  'a621efe3-c85d-4c30-90b2-ae6f61b98acf': 'Algeria',
  'aedefed7-2196-49cf-b16a-711c04bacaec': 'American Samoa',
  'cddf313e-bce3-4564-9357-fbeef6c26e21': 'Angola',
  '9061b55c-3346-444e-8906-11ef8a363285': 'Argentina',
  '579d9fba-3d1d-4acb-b79d-8cb778a0241a': 'Australia',
  'ea94d10e-bf7d-4ab5-851a-67567c2c0246': 'Austria',
  '1f93017a-3b68-4b31-9f7a-5ccddd79abc3': 'Bahamas',
  '31e2be5b-5ebe-4b38-ae43-9c1c0ef3731e': 'Bangladesh',
  'a84a1d8a-d3d7-4afb-a53e-98e7e0a2ed6e': 'Barbados',
  '157897cb-e8a9-40ea-899a-f9d6642882ea': 'Bermuda',
  'c0875f9a-ecac-43ca-babe-4a4fcd7bba30': 'Bhutan',
  'b4fee3de-3006-4e1e-8290-b90d278d804b': 'Bonaire, Sint Eustatius and Saba',
  '075623e0-32e8-4641-a143-6cea44178ca3': 'Botswana',
  '8be1ca5f-ede3-42e0-8a8f-ee32289d644d': 'Dominica',
  '927dc9dd-fd99-4bc0-a1c2-283b78c960ec': 'Heard Island and McDonald Islands',
  'c62372a4-25dd-46b1-9f83-81e67fce9b1f': 'Holy See',
  'e82e5e7d-6bf4-4203-8def-1f93189d60ff': 'Hong Kong',
  '8f86f52c-13e7-4197-aa1a-2f53659fc667': 'Iran',
  '20017057-4ff3-4c70-bd8a-1351fde42673': 'Japan',
  'f45bf3e1-f523-4040-8d1e-8f6d20768672': 'Jersey',
  'd8002a7f-7992-452f-b1ab-d96ac9c97fd6': 'Jordan',
  'ba9950ca-254f-446d-bda8-6217f1ebdd9a': 'Kiribati',
  'fb1ab75b-e375-4f6d-a2ff-b6cbf12796cc': 'Kyrgyzstan',
  '5d1a892e-f6b7-46cc-b364-68a969264732': 'Lao People\'s Democratic Republic',
  '19f1f3d3-b35f-4d5c-a0d6-6efc9400a4bf': 'Guinea',
  '81ef7c4c-b6c1-4ccf-bf12-2a6533ecf2ca': 'Guernsey',
  '68e11468-7676-4b9a-87cf-3b94a1a9c799': 'Dominican Republic',
  'eebc7205-61ec-4637-b90b-c055fc1a2457': 'Ecuador',
  '90685b9f-1401-4616-a5e2-d8eabc53f9c2': 'Equatorial Guinea',
  '64ec4a59-6852-461e-a29f-3375d1626f90': 'Estonia',
  '668330e2-261f-453e-b26b-e7550f889df1': 'Faroe Islands',
  'f0997175-88ce-4384-b641-15384f35d938': 'Fiji',
  '23731387-7d1f-4fd3-8ff5-06a563bca49c': 'Georgia',
  '187a2d90-d565-4db8-95b3-36a469e4bda7': 'Germany',
  '183d9092-ae31-42f9-9fd4-860a1efab1f0': 'Greenland',
  '5df73057-1a45-496d-a1b0-5d3fe32d7a11': 'Grenada',
  'c0924c84-13e5-4da5-87ad-16d288d9549b': 'French Polynesia',
  'b65b91c2-a1b4-42c4-af45-45c6ddccf19a': 'Zimbabwe',
  '065f7d96-4277-495d-9845-41a0dbd24954': 'Afghanistan',
  '2b8e48ae-a590-4815-a102-83980141d267': 'New Caledonia',
  '39ca8f80-4377-434f-8f63-8e138dbdd2d7': 'New Zealand',
  '75693d45-12da-4a69-8d78-cd176971864b': 'Nicaragua',
  '1d570171-6ae0-433a-a92f-b61f2bed7db0': 'Niue',
  '18196b75-6827-43c3-9cf9-03e6ca0c3aa7': 'Norfolk Island',
  '88f12769-e12d-4007-adfc-a298f0b6e18d': 'Pakistan',
  '628ff1a4-4d1a-459c-b7e3-ff4b9cd9ee12': 'Panama',
  'c2ff0094-8a52-43db-9d80-bf16f976d594': 'Paraguay',
  '9cc5772f-4d17-4f60-9fe6-35a685d80148': 'Peru',
  'cbef917d-7728-4896-9ce5-8c97e365c39d': 'Netherlands',
  '9a631684-8694-4b5c-84b3-10b1fe8fd546': 'Liechtenstein',
  '11957e5b-4d0e-4137-baa6-e7bf2f84428c': 'Luxembourg',
  '594c3ef0-addb-4557-b24f-246ad02c70cc': 'Malawi',
  '3e0f4ab1-dffa-46da-bc6a-6696be77e2c7': 'Mexico',
  '52835c0d-7c23-418c-9a54-5a1cfaa00039': 'Moldova',
  '05567c4c-2228-4e86-a6e9-4f81e065641f': 'Mongolia',
  'dbb9ea8f-079d-47a8-a9c9-e59d10cc52bf': 'Morocco',
  '50a5e85f-8af8-4821-b7fe-34cab8e1ecba': 'Mozambique',
  '8f559f5d-87bc-416f-a13d-9b16b0c74b35': 'Myanmar',
  '7e54fbe6-9b93-4261-a3d3-2fe9caf1c902': 'Nauru',
  '72c456be-e4ee-41e4-b6a4-2b25520ebf04': 'Saint Kitts and Nevis',
  '0a67d5fe-4fcd-404c-83d8-c3186ab365e3': 'Thailand',
  '2048cf36-596a-4693-989c-d94f8adf6221': 'Togo',
  '2fdfbcdb-b4ff-49f5-b2b7-f1659ae7188e': 'Tonga',
  'cdd11429-9adc-4dca-8823-8db4a466894d': 'Tunisia',
  'e09a2405-2099-4995-ba73-2afa4b8396a2': 'Turkmenistan',
  '043f747b-f678-4ac0-aee3-8585c38ad527': 'Tuvalu',
  '5c1a31b4-84c6-4cd9-8ab1-4afcf632d005': 'Uruguay',
  '08580ab1-ed6f-4339-bbdc-dbae33cea2e2': 'Uzbekistan',
  '3ff878dc-5778-4421-aea5-21a2cfc81caa': 'Venezuela',
  'bd177228-1481-4e76-8e7e-d021e5840ebf': 'Viet Nam',
  '46d2778b-ffa3-4637-94d3-4a731a55e840': 'British Virgin Islands',
  'd8456eef-5f32-48e0-8285-f0ecde54ec3a': 'Western Sahara*',
  '2f1c5017-5a6a-498a-8faa-81cd75443c94': 'Saint Pierre and Miquelon',
  '343d09a8-ce11-4d04-8109-7daa408d466e': 'Saint Vincent and the Grenadines',
  'b2e22c38-0c50-496b-b0e0-59fe2e0c95df': 'San Marino',
  '9af65c4f-e6b7-4421-8432-6a96a11fa32e': 'Senegal',
  '94a26623-9eaf-4336-af1f-a74df234cb6c': 'Serbia',
  'e3714a73-7132-4c4b-970f-2ae466e2613c': 'Singapore',
  'd1b0860c-a353-40fd-8920-020f3b12fd24': 'Sint Maarten',
  'b8ae58a2-f079-4dce-b4d5-9c3db2c35ffd': 'Slovakia',
  'cbaed7f4-ee55-4856-a924-b3b15f3e8a5f': 'Solomon Islands',
  'd1238263-f570-4158-9591-ddffcf684313': 'South Africa',
  '4cd3a12c-daec-4a08-ab0a-189e780b974b': 'Sri Lanka',
  '5dbe7893-1fc4-4d1c-b92d-e2c90718101d': 'Swaziland',
  '7a34fccd-6056-46f3-a51e-9d54415e3cd1': 'Sweden',
  '7bc3efe8-4b5b-4ef0-adde-b631df1859d4': 'Slovenia',
  'f6989361-5044-401d-86f9-9d29ff1efc94': 'Zambia',
  'df4519f4-6f3c-400d-8f22-9fc2ad07521b': 'Cabo Verde',
  '3a32a99a-6f0f-46d4-a5de-dfc2555af805': 'Cameroon',
  '0cab164c-9f46-4b13-8932-658bed44a675': 'Central African Republic',
  '5585acbf-4017-41ce-a2f2-da90d63f6995': 'Chile',
  '1946ba40-ac78-48a5-b072-ce01b2e9f934': 'China',
  'a76dda8d-0b7f-4aad-bf83-d55208b1a0c3': 'Christmas Island',
  'cab71656-5713-4119-b03c-e19ba55628bd': 'Comoros',
  '3caaaa21-9781-4b54-a07d-f1f82295fbcf': 'Democratic Republic of the Congo',
  '8337cb3b-698f-4ee7-9702-6c33de7fbf88': 'Cook Islands',
  'b8c2e1bb-7740-4934-a225-7ee0d4ccc0b6': 'Cuba',
  'c60bcba5-6087-4f8e-9dba-583ac0d8ff1a': 'Antarctica',
  'ed4ee73f-2f5e-4161-9948-83080211b620': 'Azerbaijan',
  'dfadbb5b-f801-490d-a7e3-b7125a5536a6': 'Bahrain',
  '461f3963-38b6-4a15-99c2-5ca9471e9685': 'Belgium',
  '9c96efba-3844-4475-9e7e-81a74abf0871': 'Belize',
  '25b70e20-95fc-4561-9dfd-32a4bb78b146': 'Bolivia',
  'a8eab07a-2c1f-4c70-b656-50e45f755f4c': 'Denmark',
  '6e6beab7-70ec-4a29-97ad-a15505336284': 'Iceland',
  '3328b75d-37b1-498a-9f78-91b02e9ae091': 'India',
  '6a4a3285-1a37-47d8-8354-cbb2d648d29a': 'Democratic People\'s Republic of Korea',
  '30d2fb43-02e5-49ad-a8db-9a56647e0c50': 'Kuwait',
  '3bf7d266-4c59-42f1-b07c-07675dd7225c': 'Guyana',
  '35bf9337-3728-4912-9b64-ecd7c504e8c3': 'Guinea-Bissau',
  'f0cc10b4-bbca-4b55-a7d3-3bd1fc89c4b9': 'Egypt',
  'f30dd2f6-6bde-499e-b6e8-879c0207d6ad': 'El Salvador',
  '0c088395-a3ad-46a2-84da-dacd7eddb9e7': 'Falkland Islands',
  '614db5fc-fca9-41b4-a193-688459731a43': 'Finland',
  '39c03e7e-2fdb-42bd-8428-c20f49ee6d66': 'France',
  '9541a821-66da-4201-a7cb-4c17b77bc725': 'French Guiana',
  'fa0962fe-b8db-47f2-9b3f-dddcd714902e': 'Gabon',
  '6ac88679-f417-4cd4-80e5-704091249745': 'Gambia',
  '95acce22-818b-454c-8afd-ffabe7620d57': 'Ghana',
  '4dde6575-76c0-47bd-b25b-2bcd4b678abd': 'Guadeloupe',
  '3aa05e50-9ab7-4f0c-a3ea-60caa024a74b': 'Guam',
  '265647db-ad23-4065-89a3-35de4c1f6405': 'Guatemala',
};

export const customers = {
  '2754f1ff-8045-4bc0-8eb8-6e1ca2aafdef': 'WireBloom',
  '1afb9956-bdad-4ddf-970f-f55be0abda0b': 'Coronado Media Limited',
  'ba3abe39-6053-476a-af4e-a02efadce683': 'Coronado Media Limited',
  '6d8262b4-6e66-4c65-bd1c-890b83f0a70c': 'Orlando Bloom Corporated',
  '788f39fa-9cf4-490a-a7a8-c3fb96120289': 'CC 03111034 LIMITED',
};

export const transactionStatuses = {
  '1': 'Pending',
  '2': 'Processing',
  '3': 'Approved',
  '4': 'Declined',
  '5': 'Hidden',
  '6': 'Active',
  '7': 'Exception',
  '8': 'Canceled',
  '9': 'Closed',
  '10': 'Failed',
};

export const routingTypes = {
  '1': 'sort_code',
  '2': 'aba',
  '3': 'bsb_code',
  '4': 'institution_no',
  '5': 'bank_code',
  '6': 'branch_code',
  '7': 'clabe',
  '8': 'cnaps',
};

export const chargeTypesList = {
  '1': 'ours',
  '2': 'shared',
};

export const conversionFixedTypes = {
  '1': 'sell',
  '2': 'buy',
};

export const enduserTypes = {
  '1': 'individual',
  '2': 'company',
};

export const integrationStatuses = {
  '1': 'Pending',
  '2': 'Processing',
  '3': 'Approved',
  '4': 'Declined',
};

export const transactionsTypes = {
  '1': 'Sepa',
  '2': 'Swift',
  '3': 'FX Swift',
};

export const ledgerType = {
  1: 'Individual',
  2: 'Company',
};

export const moneyGradation = {
  5000: '5K',
  10000: '10K',
  25000: '25K',
  100000: '100K',
  500000: '500K',
  1000000: '1M',
  10000000: '10M',
};

export const nameOfMonth = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};
