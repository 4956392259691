import { makeSecureRequest } from '../apiCallHandlers';
import { defaultDateRange } from '../../constants/constants';
import { convertLocaleDateToUTCFormat } from '../../helpers/dates';

export default route => ({
  getUserActivity: (payload = {}) => {
    const {
      // dateFromFormatted = defaultDateRange.dateFromPreviousHoursFormatted,
      dateFromFormatted = convertLocaleDateToUTCFormat(defaultDateRange.dateFromPreviousHoursFormatted),
      // dateToFormatted = defaultDateRange.dateToWithHours,
      dateToFormatted = convertLocaleDateToUTCFormat(defaultDateRange.dateToWithHours),
      type = '',
    } = payload;

    return makeSecureRequest('get', `UserActivity${type}`, null, {
      dateFrom: dateFromFormatted,
      dateTo: dateToFormatted,
    });
  },
});

// dateFromFormatted = convertLocaleDateToUTCFormat(defaultDateRange.dateFromPreviousHoursFormatted),
//   dateToFormatted = convertLocaleDateToUTCFormat(defaultDateRange.dateToWithHours),
