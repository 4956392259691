import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LineChart,
} from 'recharts';

import EmptyValidation from '../../HOC/EmptyValidation';
import { COLORS, currencyFormatter } from '../../../helpers/other';
import CustomSpinner from '../../Spinners/CustomSpinner';

export const tooltipFormatter = (value, name, props, keys) => {
  const secondValue = props.payload[keys[name]];

  console.log(value, name, props, keys, secondValue);

  return value === 0
    ? [value, name]
    : [`${currencyFormatter(value)}%(${currencyFormatter(secondValue)})`, name];
};

const StackedBarChart = ({
  items, fetching, type, dataKey1, dataKey2, name1, name2, keys,
}) => (
  <>
    <CustomSpinner fetching={fetching} />
    <div
      className={`${!fetching ? 'content--show' : 'content--hide-without-transition'}`}
    >
      <EmptyValidation
        isNotEmpty={!isEmpty(items)}
        whenIsEmptyContent="There is no transactions."
      >
        <BarChart
          width={800}
          height={550}
          data={items}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={type} />
          <YAxis />
          <Tooltip
            formatter={(value, name, props) => tooltipFormatter(value, name, props, keys)}
          />
          <Legend />
          <Bar dataKey={dataKey1} stackId="a" fill={COLORS.declined} name={name1} />
          <Bar dataKey={dataKey2} stackId="a" fill={COLORS.approved} name={name2} />
        </BarChart>
      </EmptyValidation>
    </div>
  </>
);

StackedBarChart.defaultProps = {
  items: {},
};

StackedBarChart.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.object,
  ),
  keys: PropTypes.objectOf(
    PropTypes.string,
  ).isRequired,
  dataKey1: PropTypes.string.isRequired,
  dataKey2: PropTypes.string.isRequired,
  name1: PropTypes.string.isRequired,
  name2: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  fetching: PropTypes.bool.isRequired,
};

export default StackedBarChart;
