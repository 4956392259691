import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import Header from '../../../Layouts/Header';
import dashboardActions from '../../../../actions/dashboard';
import RangeCalendar from '../../../Layouts/RangeCalendar';
import { defaultDateRange } from '../../../../constants/constants';

const { dateFromPreviousWeekFormatted, datesFromPreviousMonth } = defaultDateRange;

const ApplicationsDashboardHeader = ({
  fetchGetData,
  clearData,
}) => {
  const [firstLoading, setFirstLoading] = useState(true);
  const [dates, setDates] = useState(datesFromPreviousMonth);
  const [active, setActive] = useState('month');

  useEffect(() => {
    if (firstLoading) {
      fetchGetData(datesFromPreviousMonth);
      setFirstLoading(false);
    } else {
      fetchGetData(dates);
    }
  }, [dates]);

  useEffect(() => {
    return function () {
      clearData();
    };
  }, []);

  const rangeCalendarAction = (selectedDates) => {
    setActive('calendar');
    setDates({
      dateFrom: new Date(selectedDates.dateFromFormatted),
      dateTo: new Date(selectedDates.dateToFormatted),
    });
  };

  const getFromMonth = () => {
    setActive('month');
    setDates({ ...datesFromPreviousMonth });
  };

  const getFromWeek = () => {
    setActive('week');
    setDates({
      dateFrom: new Date(dateFromPreviousWeekFormatted),
      dateTo: new Date(),
    });
  };

  return (
    <Header>
      <div className="breadcrumbs-block">
        <pre>Dashboard / Applications</pre>
      </div>
      <div className="select-group">
        <div className="select-group__block">
          <span className={`calendar-section--left ${active === 'calendar' ? 'active' : ''}`}>
            <RangeCalendar
              rangeCalendarAction={rangeCalendarAction}
              dayFormat="D/MMM"
              customDefaultDate={{
                startDate: dates.dateFrom,
                endDate: dates.dateTo,
              }}
            />
          </span>
          <span
            className={`select-group__item ${active === 'month' ? 'active' : ''}`}
            onClick={getFromMonth}
          >
            Month
          </span>
          <span
            className={`select-group__item ${active === 'week' ? 'active' : ''}`}
            onClick={getFromWeek}
          >
            Week
          </span>
        </div>
      </div>
    </Header>
  );
};

ApplicationsDashboardHeader.defaultProps = {};

ApplicationsDashboardHeader.propTypes = {
  clearData: PropTypes.func.isRequired,
  fetchGetData: PropTypes.func.isRequired,
  options: PropTypes.shape({
    ledgerId: PropTypes.string,
    amountType: PropTypes.string,
    currencyId: PropTypes.number,
    dateFrom: PropTypes.instanceOf(Date),
    dateTo: PropTypes.instanceOf(Date),
  }).isRequired,
};

const mapStateToProps = (state) => {
  const {
    ledgers,
  } = state.get('lists')
    .toJS();

  const options = state.getIn(['dashboard', 'options'])
    .toJS();

  return {
    options,
    lists: {
      ledgers: ledgers.data,
    },
  };
};

const {
  getChartUsersByStatus,
  clearTransactionsDashboard,
} = dashboardActions.dashboard;

const mapDispatchToProps = dispatch => ({
  fetchGetData: data => dispatch(getChartUsersByStatus.action(data)),
  clearData: () => dispatch(clearTransactionsDashboard()),
});

export default connect(mapStateToProps,
  mapDispatchToProps)(withImmutablePropsToJS(ApplicationsDashboardHeader));
