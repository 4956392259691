import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import SubUserRegistrationFormContainer from '../../containers/AuthContainers/SubUserRegistrationFormContainer';

const SubUserRegistrationRouters = () => (
  <div className="login-layout">
    <Switch>
      <Route exact path="/registration" component={SubUserRegistrationFormContainer} />

      <Redirect to="/registration" />
    </Switch>
  </div>
);

export default SubUserRegistrationRouters;
