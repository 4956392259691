/** * User Section  ** */
export const notifWaitApproving = 'Please wait your approving'; //
export const notifUserStatusChanging = 'User status has been successfully changed!';
export const notifUserCreated = 'User has been successfully created!';
export const notifUserChanged = 'User has been successfully changed!';
export const notifPasswordChanged = 'Password has been successfully changed!';
export const notifAccountInactive = 'Please contact support, your account is inactive';
export const notifCheckEmail = 'Please check your email';
export const notifCongratulationAccountCreation = 'Congratulations! You have successfully registered in Wirebloom!';
export const notifCheckEmailAnotherUser = email => `Please check ${email} for password recovery email!`;
export const notifActivateUser = 'Would you like change status to';
export const notifResetPassUser = 'Do you really want to send the reset password link to the user email?';
export const notifChangeUserAccount = userName => `Are you sure you want to impersonate ${userName} user?`;
/** *==** */

/** * Accounts Section  ** */
export const confirmProcessAccount = 'Would you like processing this account?';
export const confirmCancelAccount = 'Would you like to cancel this account?';//
export const notifWarnFirstCreateBeneficiary = 'Please, create beneficiary at first for this account';
export const notifCreateAccount = 'Account has been successfully created'; //
export const notifEditAccount = 'Account has been successfully edited'; //
export const notifDeleteAccount = 'Company has been successfully deleted';
export const notifCancelAccount = 'Account status has been successfully changed to Canceled';//
export const notifCreateAdjustment = 'Adjustment has been successfully created';
export const tooltipCantCreateAdjustment = "You don't have permission to create adjustment.";
export const tooltipCantCreateAccount = "You don't have permission to create account.";
export const tooltipCantChangeAccountStatus = 'You don\'t have permission to change account status.';
/** *==** */

/** * Admin User Section  ** */
export const notifChangedAdminUserStatus = 'Admin User status has been successfully changed!'; //
export const notifEditAdminUser = 'Admin User successfully edited'; //
export const notifCreateAdminUser = 'Admin User has been successfully created';
export const notifActivateAdmin = 'Would you like to activate this admin?'; //
export const tooltipCantCreateSubUser = "You don't have permission to create subUser.";
/** *==** */

/** * Beneficiaries Section  ** */
export const notifChangeBeneficiaryStatus = status => `Beneficiary status has been successfully changed to ${status}!`; //
export const notifCreateBeneficiaryForTransaction = 'New beneficiary for this transaction has created, now we are processing your transaction'; //
export const notifCreateBeneficiary = 'New beneficiary added!'; //
export const notifDeleteBeneficiary = 'Beneficiary has been successfully deleted!'; //
export const notifCreateAccountForBeneficiary = 'Please, contact to administrator for creating account for this beneficiary.';
export const notifChangeBeneficiary = 'Beneficiary data has been successfully changed.'; //
export const notifBeneficiaryFields = 'Please select the beneficiary currency and beneficiary country to fill \n' +
  '            out the required fields marked with an asterisk.'; //
export const tooltipCantDeclineBeneficiary = "You don't have permission to decline beneficiary"; //
export const tooltipCantDeleteBeneficiary = "You don't have permission to delete this beneficiary"; //
export const tooltipCantCreateBeneficiary = "You don't have permission to create beneficiary"; //
/** *==** */

/** * Customers Section  ** */
export const notifChangeCustomerStatus = 'Customer status has been successfully changed!';
export const notifDeleteDoc = docId => `Document ${docId} has been successfully deleted`;
export const notifUploadDoc = fileName => `Document ${fileName} has been successfully upload`;
export const notifDeleteCustomerContact = (firstName, lastName) => `${firstName} ${lastName} has been successfully deleted`;
export const notifCreateCustomerContact = id => `Contact with id ${id} has been successfully created`;
export const notifUpdateCustomerField = (fieldName, fieldValue) => `${fieldName} has been successfully updated to ${fieldValue}`;
export const notifActivateCustomer = 'Would you like change status to';
export const tooltipCantChangeStatus = "You don't have permission to change status";
export const tooltipCantAddDocument = "You don't have permission to add document";
export const tooltipCantRemoveDocument = "You don't have permission to remove document";
/** *==** */

/** * Registration Section  ** */
export const notifCongratulationUserRegister = 'Congratulations! You have successfully registered in Wirebloom, please check your email box to proceed with registration!';
export const notifCongratulationProfileRegister = 'Congratulation. Please wait for your profile approving by the administrator.';
export const notifWarningRequiredFieldsAndDocsTitle = 'There are empty required fields:';
/** *==** */

/** * Fees Section  ** */
export const notifUpdatePricingTemplate = 'Pricing template has been successfully updated!'; //
export const notifApplyPricingTemplate = 'Pricing template has been successfully applied!'; //
export const notifApplyPricingTemplateField = 'Fees fields has been successfully applied!'; //
export const tooltipCantAddFee = "You don't have permission to add Fee";
/** *==** */

/** * Fees Charges Section  ** */
export const notifProcessFeeCharge = feeChargeId => `Fee ${feeChargeId} status has been successfully changed to processing!`; //
export const notifErrFailedFeeCharge = feeChargeId => `Fee ${feeChargeId} has been failed`; //
export const notifDeclineOneFee = feeChargeId => `Fee ${feeChargeId} status has been successfully changed to canceled!`; //
export const notifExecuteFeeCharge = 'Would you like execute this fee charge?';
/** *==** */

/** * Journal Section  ** */
export const notifEditJournal = 'Journal has been successfully changed'; //
export const notifPermissionErrEditJournal = "You don't have permission to edit Journal"; //
export const notifUploadBatch = 'Batch File has been upload'; //
export const tooltipCantUploadBatch = "You don't have permission to upload batch file"; //
/** *==** */

/** * Transactions Section  ** */
export const notifCreateTransaction = 'Transaction has been successfully created!'; //
export const notifDeclineTransaction = (displayTransactionId, userRole) => `Transaction 
${displayTransactionId} status has been successfully changed to 
${userRole === 'Admin' ? 'declined' : 'canceled'}!`; //
export const notifProcessTransaction = transactionId => `Transaction ${transactionId} status has been successfully changed to processing!`; //
export const notifApprovedTransaction = transactionId => `Transaction ${transactionId} status has been successfully changed to Approved!`; //
export const confirmProcessOneTransaction = 'Would you like process this transaction?'; //
export const notifErrFailedTransaction = (transactionId, errorMessage) => `Transaction ${transactionId} has been failed. ${errorMessage}`; //
export const notifErrFailedTransactionServerError = transactionId => `Transaction ${transactionId} has been failed`; //
export const tooltipCantProcessTransaction = "You don't have permission to process Transactions"; //
export const tooltipTransactionHasNotBeneficiary = "This transaction hasn't beneficiary in the bank. You don't have permission to process Transactions";
export const tooltipCantDeclineTransaction = "You don't have permission to decline Transactions"; //
export const tooltipCantDeclineTransactionWithoutBank = "You don't have permission to decline Transactions without Bank"; //
export const tooltipCantCreateTransaction = "You don't have permission to create Transactions"; //
export const confirmProcessTransactions = 'Do you really want to process all these transactions?'; //
/** *==** */

/** *Forms fields error * * */
export const yupExceptionLatinFields = 'Should be only latin letters';
export const yupExceptionLatinFieldWithNumbers = 'Should be latin letters and numbers';
export const yupExceptionReferenceNotValid = 'Too long, should be less than 100 characters';
export const yupExceptionValidAmount = 'The amount should be 0 or less than 0';
export const yupExceptionFileSizeLimit = 'File should be less than 300MB';
export const yupExceptionLettersFieldWithNumbersAndPunctuationMarks = "Should be latin letters, numbers and . , ' - @ _ %";
/** *==* * */

export const tooltipCantExportFiles = 'There are not items for export.';
