import React, { memo, useCallback, useState } from 'react';
import { fill } from 'lodash';
import { Document, Page } from 'react-pdf/dist/umd/entry.webpack';
import PropTypes from 'prop-types';

import Spinner from '../Spinners/CustomSpinner';

const FilePreview = ({ string, width, saveFile }) => {
  const [numberPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = useCallback(({ numPages }) => {
    if (!numberPages) setNumPages(numPages);
  }, [numberPages]);

  return (
    <div className="file-preview__doc">
      <Document
        file={string}
        options={{
          cMapUrl: 'cmaps/',
          cMapPacked: true,
        }}
        loading={<div className="spinner-wrapper"><Spinner fetching /></div>}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <If condition={numberPages}>
          {fill(Array(numberPages), 1).map(
            (item, idx) => <Page pageNumber={idx + 1} key={idx} width={width} />,
          )}
        </If>
      </Document>
      <If condition={saveFile}>
        <div className="icon__container">
          <label className="labelForDownload">Download</label>
          <i
            id="download-monthly-statement"
            className="iconfont-download-file download_document clickable"
            onClick={saveFile}
          />
        </div>
      </If>
    </div>
  );
};

export default memo(FilePreview);

FilePreview.defaultProps = {
  width: null,
  saveFile: null,
};

FilePreview.propTypes = {
  saveFile: PropTypes.func,
  string: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  width: PropTypes.number,
};
