import React, { memo } from 'react';
import PropsTypes from 'prop-types';

import AnimatedSpinner from '../../components/Spinners/AnimatedSpinner';
import InfoReason from '../../components/Layouts/InfoReason';
import ProcessTransactionModal from '../statusHelper/ProcessTransactionModal';

const TransactionPendingFormatter = ({
  declineAction,
  processAction,
  approveTransaction,
  processText,
  id,
  isVerified,
  transactionsStatusChanging,
  declineReason,
  createBeneficiaryOnPlatform,
  userRole,
  subRole,
}) => {
  const { inProcessing, fetching } = transactionsStatusChanging;

  const isAvailableBeneficiaryAtBank = declineReason
    ? declineReason.indexOf('AccountNumber has not been found') !== -1
    : false;

  const isProcessing = inProcessing.some(it => it === id) && fetching;
  const hasInfoReason = declineReason && declineReason !== 'n/a'
    && !isAvailableBeneficiaryAtBank && userRole === 'Admin';
  return (
    <>
      <AnimatedSpinner fetching={isProcessing} />
      <div
        className={`status-block status-block__pending 
        ${isProcessing ? 'content--hide' : 'content--show'}`}
      >
        <ProcessTransactionModal
          id={id}
          declineAction={declineAction}
          isVerified={isVerified}
          processAction={processAction}
          processText={processText}
          createBeneficiaryOnPlatform={createBeneficiaryOnPlatform}
          isAvailableBeneficiaryAtBank={isAvailableBeneficiaryAtBank}
          userRole={userRole}
          subRole={subRole}
          approveTransaction={approveTransaction}
        />

        <If condition={hasInfoReason}>
          <InfoReason id={id} declineReason={declineReason} />
        </If>
      </div>
    </>
  );
};

TransactionPendingFormatter.propTypes = {
  transactionsStatusChanging: PropsTypes.shape({
    fetching: PropsTypes.bool.isRequired,
    inProcessing: PropsTypes.arrayOf(
      PropsTypes.object,
    ).isRequired,
  }).isRequired,
  createBeneficiaryOnPlatform: PropsTypes.func.isRequired,
  userRole: PropsTypes.string.isRequired,
  subRole: PropsTypes.string.isRequired,
  isVerified: PropsTypes.bool.isRequired,
  processAction: PropsTypes.func.isRequired,
  approveTransaction: PropsTypes.func.isRequired,
  declineAction: PropsTypes.func.isRequired,
  id: PropsTypes.string.isRequired,
  processText: PropsTypes.string.isRequired,
  declineReason: PropsTypes.string.isRequired,
};

export default memo(TransactionPendingFormatter);
