import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, omit } from 'lodash';

import CustomSpinner from '../../Spinners/CustomSpinner';
import UniversalInputsForm from '../../generic/UniversalInputsForm';
import Button from '../../generic/Button';
import Header from '../../Layouts/Header';
import InvoiceComponent from './InvoiceComponent';
import Tooltip from '../../generic/Tooltip';

const CreateTransactionPage = (props) => {
  const {
    match: { params: { id: pathId } },
    history,
    submitForm,
    header,
    data: { data },
    accounts: { data: accountsData },
    fetching,
    lists: {
      beneficiaryId, ledgerId, currencyId, countryId, routingTypes,
    },
    toLink, // TODO: change logic
    toPage,
    path,
    formId,
    fields,
    grid,
    validationSchema,
    fetchGetData,
    fetchOneBeneficiary,
    fetchGetOneAccount,
    cleanData,
    fetchGetConvertedBalance,
  } = props;

  const prepareData = useMemo(() => {
    let accounts = {};
    let beneficiary = {};

    if (!isEmpty(accountsData)) {
      accounts = {
        ...omit(accountsData, ['accountNumber']),
        ledgerId: accountsData.id,
      };
    }
    if (!isEmpty(data)) {
      beneficiary = {
        ...omit(data, ['currencyId']),
        paymentCurrencyId: data.currencyId,
      };
    }
    return { ...beneficiary, ...accounts };
  }, [data, accountsData]);

  useEffect(() => () => cleanData(), []);

  useEffect(() => {
    if (formId === 'view-transaction') fetchGetData(pathId);
  }, []);

  const onSubmitForm = (values) => {
    submitForm(values);
  };

  const onChange = ({ name, value }) => {
    switch (name) {
      case 'beneficiaryId':
        fetchOneBeneficiary(value);
        break;
      case 'ledgerId':
        fetchGetOneAccount(value);
        break;
      case 'isInBeneficiaryCurrency':
        if (value) {
          return fetchGetConvertedBalance({
            beneficiaryId: data.beneficiaryId,
            ledgerId: accountsData.id,
          });
        }
        break;
    }
  };

  const HeaderTools = header;

  const goBack = () => history.push('/transactions');

  return (
    <div className={`transactions-page full-height main_padding ${formId}`}>
      <Header>
        {HeaderTools && <HeaderTools toLink={toLink} toPage={toPage} path={path} />}
      </Header>

      <div className={`forms-page right-labels ${fetching ? 'spinner' : ''}`}>
        <div className={`page-content-container ${!fetching ? 'content--show' : 'content--hide-without-transition'}`}>
          <Choose>
            <When condition={formId === 'create-transaction'}>
              <UniversalInputsForm
                multiForm={grid}
                validationSchema={validationSchema}
                fields={fields}
                onSubmit={onSubmitForm}
                formId={formId}
                propsData={prepareData}
                lists={{
                  ledgerId,
                  beneficiaryId,
                  currencyId,
                  paymentCurrencyId: currencyId,
                  bankCountryId: countryId,
                  bankCodeType: routingTypes,
                }}
                additionalHandler={onChange}
                withLabel
              />
            </When>
            <Otherwise>
              <UniversalInputsForm
                multiForm={grid}
                validationSchema={validationSchema}
                fields={fields}
                formId={formId}
                propsData={data}
                withLabel
              />
            </Otherwise>
          </Choose>
          <If condition={formId === 'view-transaction'}>
            <InvoiceComponent isInvoice={data.hasInvoice} id={pathId}/>
          </If>
          <div className="buttons-group">
            <If condition={formId === 'create-transaction'} >
              <Choose>
                <When condition={accountsData.balance < 1 }>
                  <span
                    data-tip="top-up-balance"
                    data-for="top-up-balance"
                  >
                    <Button
                      disabled
                      color="gray"
                      type="primary"
                      size="lg"
                      text="Save"
                    />
                  </span>
                  <Tooltip id="top-up-balance" text="Top up the balance or contact the administrator." />
                </When>
                <Otherwise>
                  <Button
                    color="gray"
                    type="primary"
                    size="lg"
                    submit
                    form={formId}
                    text="Save"
                  />
                </Otherwise>
              </Choose>
             </If>
            <Button
              onClick={goBack}
              color="gray"
              type="primary"
              size="lg"
              text="Cancel"
            />
          </div>
        </div>
        <CustomSpinner fetching={fetching} />
      </div>
    </div>
  );
};

CreateTransactionPage.defaultProps = {
  path: 'transactions',
  accounts: {},
  submitForm: () => {
  },
  lists: {
    currencyId: {},
    countryId: {},
    beneficiaryId: {},
    ledgerId: {},
    routingTypes: {},
  },
  fetchGetData: () => {
  },
  fetchOneBeneficiary: () => {
  },
  fetchGetOneAccount: () => {
  },
  fetchGetConvertedBalance: () => {
  },
};

CreateTransactionPage.propTypes = {
  data: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.string,
    PropTypes.array,
    PropTypes.bool,
  ])).isRequired,
  accounts: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.string,
    PropTypes.array,
    PropTypes.bool,
  ])),
  lists: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ])),
  fetchGetData: PropTypes.func,
  fetchOneBeneficiary: PropTypes.func,
  fetchGetOneAccount: PropTypes.func,
  cleanData: PropTypes.func.isRequired,
  submitForm: PropTypes.func,
  header: PropTypes.func.isRequired,
  path: PropTypes.string,
  validationSchema: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.string,
    PropTypes.array,
    PropTypes.bool,
    PropTypes.func,
  ])).isRequired,
  fetching: PropTypes.bool.isRequired,
  formId: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]))).isRequired,
  grid: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchGetConvertedBalance: PropTypes.func,

};

export default CreateTransactionPage;
