import { createAction } from 'redux-actions';
import { toastr } from 'react-redux-toastr';

import history from '../../lib/history';

import {
  GET_USER,
  INCREMENT,
  DECREMENT,
  LOGIN_USER,
  LOGOUT_USER,
  LOGOUT,
  REFRESH_SESSION,
  USER_REGISTER,
  GET_USERS,
  GET_USER_ACTIVITY_LOGS,
  CREATE_USER,
  CREATE_SUB_USER,
  CREATE_SUB_USER_BY_ADMIN,
  UPDATE_USER,
  DELETE_USER,
  CLEAN_USER_DATA,
  CHANGE_USER_STATUS,
  GET_LINK_FOR_RESTORE_PASSWORD,
  RESET_PASSWORD,
  GET_LINK_FOR_RESET_PASSWORD,
  COMPLETE_SUB_USER,
  GET_USER_CUSTOMERS,
  GET_TOKEN_FOR_CHANGING_USER,
} from '../../constants/constants';

import { apiCallAction } from '../actionHelpers';
import { apiCallActionHandler } from '../commonLogic/requestLogic';

import {
  authenticateUser, deauthenticateUser, parseJwt,
  addSubRoleToUserInfo, changeUserToken,
} from '../localStorage';
import {
  notifWaitApproving,
  notifUserStatusChanging,
  notifAccountInactive,
  notifCheckEmail,
  notifCongratulationAccountCreation,
  notifCheckEmailAnotherUser,
  notifUserCreated, notifUserChanged, notifPasswordChanged,
} from '../../helpers/notificatios';

const actions = {
  loginUser: apiCallAction(LOGIN_USER),
  logoutUser: apiCallAction(LOGOUT_USER),
  refreshSession: apiCallAction(REFRESH_SESSION),
  logout: createAction(LOGOUT),
  getOneUser: apiCallAction(GET_USER),
  updateUser: apiCallAction(UPDATE_USER),
  userRegister: apiCallAction(USER_REGISTER),
  getLinkForRestorePassword: apiCallAction(GET_LINK_FOR_RESTORE_PASSWORD),
  resetPassword: apiCallAction(RESET_PASSWORD),
  getLinkForResetPassword: apiCallAction(GET_LINK_FOR_RESET_PASSWORD),
  getUsers: apiCallAction(GET_USERS),
  getUserActivityLogs: apiCallAction(GET_USER_ACTIVITY_LOGS),
  createUser: apiCallAction(CREATE_USER),
  createSubUser: apiCallAction(CREATE_SUB_USER),
  createSubUserByAdmin: apiCallAction(CREATE_SUB_USER_BY_ADMIN),
  deleteUser: apiCallAction(DELETE_USER),
  changeUserStatus: apiCallAction(CHANGE_USER_STATUS),
  cleanUserData: createAction(CLEAN_USER_DATA),
  increment: createAction(INCREMENT),
  decrement: createAction(DECREMENT),
  completeSubUser: apiCallAction(COMPLETE_SUB_USER),
  getUserCustomers: apiCallAction(GET_USER_CUSTOMERS),
  getTokenForChangingUser: apiCallAction(GET_TOKEN_FOR_CHANGING_USER),
};

const loginUserLogic = apiCallActionHandler({
  name: 'loginUser',
  fetchAction: actions.loginUser,
  makeApiCall(payload, { api }) {
    return api.auth.loginUser(payload);
  },
  transformResponse({ data }) {
    if (data) {
      const { auth_token: authToken, refresh_token: refreshToken } = data;
      const userData = parseJwt(authToken) || {};
      return {
        data: addSubRoleToUserInfo(userData),
        authToken,
        refreshToken,
      };
    }
    return {
      data: {},
    };
  },
  onSuccess({ authToken, refreshToken }) {
    authenticateUser(authToken, refreshToken);

    const { Status } = parseJwt(authToken);
    if (Status === '1') {
      toastr.success(notifCheckEmail);
    }
    if (Status === '11' || Status === '3' || Status === '13') { // OnHold, Pending Approval,
      toastr.success(notifWaitApproving);
    }
    if (Status === '12') {
      toastr.success(notifAccountInactive);
    }
  },
});

const logoutUserLogic = apiCallActionHandler({
  name: 'logoutUser',
  fetchAction: actions.logoutUser,
  makeApiCall(payload, { api }) {
    return api.auth.logoutUser();
  },
});

const refreshSession = apiCallActionHandler({
  name: 'refreshSession',
  fetchAction: actions.refreshSession,
  makeApiCall(payload, { api }) {
    return api.auth.loginUserWithRefreshToken(payload);
  },
  onSuccess({ authToken, refreshToken }) {
    authenticateUser(authToken, refreshToken);
  },
  transformPayload: () => {
    return localStorage.getItem('refresh_token');
  },
  transformResponse({ data }) {
    if (data) {
      const { auth_token: authToken, refresh_token: refreshToken } = data;
      const userData = parseJwt(authToken) || {};
      return {
        data: addSubRoleToUserInfo(userData),
        authToken,
        refreshToken,
      };
    }
    return {
      data: {},
    };
  },
  onError() {
    deauthenticateUser();
  },
});

const userRegisterLogic = apiCallActionHandler({
  name: 'userRegister',
  fetchAction: actions.userRegister,
  makeApiCall(payload, { api }) {
    return api.user.userRegister(payload);
  },
  transformPayload(payload) {
    return payload;
  },
  transformResponse(res) {
    return res;
  },
});

const getOneUserLogic = apiCallActionHandler({
  name: 'getOneUser',
  fetchAction: actions.getOneUser,
  makeApiCall(payload, { api }) {
    return api.user.getOneUser(payload);
  },
});

const getUsersLogic = apiCallActionHandler({
  name: 'getUsers',
  fetchAction: actions.getUsers,
  makeApiCall(payload, { api }) {
    return api.user.getUsers(payload);
  },
  transformPayload({ dateFromFormatted, dateToFormatted }) {
    return {
      dateFrom: dateFromFormatted,
      dateTo: dateToFormatted,
    };
  },
});

const getUserActivityLogsLogic = apiCallActionHandler({
  name: 'getUserActivityLogs',
  fetchAction: actions.getUserActivityLogs,
  makeApiCall(payload, { api }) {
    return api.user.getUserActivityLogs(payload);
  },
});

const changeUserStatusLogic = apiCallActionHandler({
  name: 'changeUserStatus',
  fetchAction: actions.changeUserStatus,
  makeApiCall(payload, { api }) {
    return api.users.changeUserStatus(payload);
  },
  onSuccess() {
    toastr.success(
      'Success',
      notifUserStatusChanging,
    );
  },
});

const completeSubUserLogic = apiCallActionHandler({
  name: 'completeSubUser',
  fetchAction: actions.completeSubUser,
  makeApiCall(payload, { api }) {
    return api.users.completeSubUser(payload);
  },
  onSuccess(data, payload, { dispatch }) {
    const logoutFunc = actions.logout;
    dispatch(logoutFunc());
    toastr.success(
      'Success',
      notifCongratulationAccountCreation,
    );
  },
});

const updateUserLogic = apiCallActionHandler({
  name: 'updateUser',
  fetchAction: actions.updateUser,
  makeApiCall({ id, values }, { api }) {
    return api.user.updateUser(id, values);
  },
  onSuccess() {
    toastr.success(
      'Success',
      notifUserChanged,
    );
    history.push('/users');
  },
});

const getLinkForRestorePasswordLogic = apiCallActionHandler({
  name: 'getLinkForRestorePassword',
  fetchAction: actions.getLinkForRestorePassword,
  makeApiCall(payload, { api }) {
    return api.user.getLinkForRestorePassword(payload);
  },
  onSuccess(response, { email }) {
    toastr.success(
      'Success',
      notifCheckEmailAnotherUser(email),
    );

    history.push('/auth/login');
  },
});

const getLinkForResetPasswordLogic = apiCallActionHandler({
  name: 'getLinkForResetPassword',
  fetchAction: actions.getLinkForResetPassword,
  makeApiCall(payload, { api }) {
    return api.user.getLinkForResetPassword(payload);
  },
  onSuccess(response, { email }) {
    toastr.success(
      'Success',
      notifCheckEmailAnotherUser(email),
    );
  },
});

const resetPasswordLogic = apiCallActionHandler({
  name: 'resetPassword',
  fetchAction: actions.resetPassword,
  makeApiCall(payload, { api }) {
    return api.user.resetPassword(payload);
  },
  onSuccess() {
    toastr.success(
      'Success',
      notifPasswordChanged,
    );
    history.push('/auth/login');
  },
});

const createUserLogic = apiCallActionHandler({
  name: 'createUser',
  fetchAction: actions.createUser,
  makeApiCall(payload, { api }) {
    return api.user.createUser(payload);
  },
  onSuccess() {
    toastr.success(
      'Success',
      notifUserCreated,
    );
  },
});

const createSubUserLogic = apiCallActionHandler({
  name: 'createSubUser',
  fetchAction: actions.createSubUser,
  makeApiCall(payload, { api }) {
    return api.user.createSubUser(payload);
  },
  onSuccess() {
    toastr.success(
      'Success',
      notifUserCreated,
    );
  },
});

const createSubUserByAdminLogic = apiCallActionHandler({
  name: 'createSubUserByAdmin',
  fetchAction: actions.createSubUserByAdmin,
  makeApiCall(payload, { api }) {
    return api.user.createSubUserByAdmin(payload);
  },
  onSuccess() {
    toastr.success(
      'Success',
      notifUserCreated,
    );
  },
});

const getUserCustomersLogic = apiCallActionHandler({
  name: 'getUserCustomers',
  fetchAction: actions.getUserCustomers,
  makeApiCall(payload, { api }) {
    return api.user.getUserCustomers(payload);
  },
});

const getTokenForChangingUserLogic = apiCallActionHandler({
  name: 'getTokenForChangingUser',
  fetchAction: actions.getTokenForChangingUser,
  makeApiCall(payload, { api }) {
    return api.user.getTokenForChangingUser(payload);
  },
  onSuccess({ auth_token }) {
    changeUserToken(auth_token);
  },
});

export const logics = [
  loginUserLogic,
  logoutUserLogic,
  refreshSession,
  getOneUserLogic,
  updateUserLogic,
  userRegisterLogic,
  getUsersLogic,
  getUserActivityLogsLogic,
  createUserLogic,
  createSubUserLogic,
  createSubUserByAdminLogic,
  getLinkForRestorePasswordLogic,
  getLinkForResetPasswordLogic,
  changeUserStatusLogic,
  completeSubUserLogic,
  resetPasswordLogic,
  getUserCustomersLogic,
  getTokenForChangingUserLogic,
];

export default actions;
