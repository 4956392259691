import { customFilter } from 'react-bootstrap-table2-filter';
import React from 'react';

import CustomSelectFilter from '../../components/CustomTable/CustomFilter';
import {
  sortHeaderFormatter,
  dateFormatter,
  priceFormatter,
  defaultFormatter,
} from '../tableUtils/tablesUtils';

import AccountNameFormatter from '../tableUtils/AccountFormatters/AccountNameFormatter';
import AccountsStatusFormatter from '../tableUtils/AccountFormatters/AccountStatusFormatter';
import customSelectionFilter from '../customSelectionFilter';
import { convertDateToLocaleFormat } from '../../helpers/dates';
import { currencyFormatter, insertElementsToArray } from '../../helpers/other';
import { selectUniqValues } from '../../helpers/status';

const accountsColumns = (currentData, userRole, subRole) => {
  const accountsStatuses = selectUniqValues(currentData, 'status', {}, [
    'Active',
    'OnHold',
    'Canceled',
  ]);

  const fields = [{
    dataField: 'customerName',
    text: 'Customer',
    sort: true,
    formatter: defaultFormatter,
    headerFormatter: sortHeaderFormatter,
    headerStyle: () => ({ width: '23%' }),
    wpx: 160,
  }, {
    dataField: 'name',
    text: 'Name',
    sort: true,
    classes: 'main-field',
    formatter: (cell, { id }) => (
      <AccountNameFormatter cell={cell} id={id} userRole={userRole} subRole={subRole} />
    ),
    headerFormatter: sortHeaderFormatter,
    headerStyle: () => ({ width: '23%' }),
    wpx: 160,
  }, {
    dataField: 'accountNumber',
    text: 'Account number',
    sort: true,
    formatter: defaultFormatter,
    headerFormatter: sortHeaderFormatter,
    headerStyle: () => ({ width: '17%' }),
    wpx: 160,
  }, {
    dataField: 'currency',
    text: 'Currency',
    sort: true,
    formatter: defaultFormatter,
    headerFormatter: sortHeaderFormatter,
    headerStyle: () => ({ width: '7%' }),
    wpx: 50,
  }, {
    dataField: 'createdDate',
    text: 'Created Date',
    sort: true,
    formatter: (cell, { id }) => dateFormatter(cell, id),
    headerFormatter: sortHeaderFormatter,
    headerStyle: () => ({ width: '8%' }),
    transformationItem: cell => convertDateToLocaleFormat(cell),
    wpx: 140,
  }, {
    dataField: 'balance',
    text: 'Balance',
    sort: true,
    formatter: priceFormatter,
    headerFormatter: sortHeaderFormatter,
    headerStyle: () => ({ width: '7%' }),
    transformationItem: currencyFormatter,
    wpx: 70,
  }, {
    dataField: 'status',
    text: 'Status',
    sort: true,
    headerClasses: 'with-filter',
    classes: 'status',
    headerStyle: () => ({ width: '9%' }),
    formatter: (cell, data) => (
      <AccountsStatusFormatter cell={cell} data={data} />
    ),
    filter: customFilter({
      onFilter: (filterObj, data) => customSelectionFilter(filterObj, data, 'status'),
    }),
    filterRenderer: (onFilter, column) => (
      <CustomSelectFilter
        onFilter={onFilter}
        column={column}
        selectItems={accountsStatuses}
        formClass="custom-filter-status"
      />
    ),
    wpx: 70,
  }];

  const fieldsForAdmin = [{
    fieldName: 'dataField',
    fieldValue: 'accountNumber',
    insertingObject: {
      dataField: 'erpId',
      text: 'ERP Customer ID',
      sort: true,
      formatter: defaultFormatter,
      headerFormatter: sortHeaderFormatter,
      headerStyle: () => ({ width: '10%' }),
      wpx: 80,
    },
  }];

  return userRole === 'Admin'
    ? insertElementsToArray(fields, fieldsForAdmin)
    : fields;
};

export default accountsColumns;
