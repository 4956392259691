import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Switch } from 'react-router-dom';
import { isEmpty } from 'lodash';

import RouteWrapper from './RouteWrapper';

export const mapRoutes = items => items.map(route => (
  <RouteWrapper key={route.title} {...route} />
));


const CustomRouteWrapper = ({ routes, redirect }) => (
  <Switch>
    {mapRoutes(routes)}
    <If condition={!isEmpty(redirect)}>
      <Redirect to={redirect} />
    </If>
  </Switch>
);

CustomRouteWrapper.defaultProps = {
  redirect: '',
};

CustomRouteWrapper.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  redirect: PropTypes.string,
};

export default memo(CustomRouteWrapper);
