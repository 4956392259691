import { cloneDeep, pickBy, uniqBy } from 'lodash';
import currency from 'currency-formatter';
import { toastr } from 'react-redux-toastr';

// TODO: we have function changingFiledsReadOnlyValue - remove it
export const addReadOnlyProperties = arr => cloneDeep(arr)
  .map((i) => {
    i.readOnly = true;
    return i;
  });

export const removeReadOnlyProperties = arr => cloneDeep(arr)
  .map((i) => {
    i.readOnly = false;
    return i;
  });

export const cutFileNameLength = (initial, num) => {
  if (initial.length < num) {
    return initial;
  }

  const split = initial.split('.');
  const extension = split[split.length - 1];
  let filename = initial.slice(0, -split.length);

  filename = filename.substring(0, num - extension.length);

  return `${filename}....${extension}`;
};

export const downloadBlob = (blob, name = 'file.txt') => {
  // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
  const blobUrl = URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement('a');

  // Set link's href to point to the Blob URL
  link.href = blobUrl;
  link.download = name;

  // Append link to the body
  document.body.appendChild(link);

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  );

  // Remove link from body
  document.body.removeChild(link);
};

export const prepareContentForToastr = (fields, fieldName, value, lists) => {
  const currentItem = fields.find(item => item.id === fieldName);

  if (currentItem.list) {
    return {
      name: currentItem.label,
      value: lists[currentItem.list].data[value],
    };
  }

  return {
    name: currentItem.label,
    value,
  };
};

export const currencyFormatter = value => currency.format(value, { code: '' });

export const setFieldsToReadOnly = (
  fields,
  requiredFields = [],
  optionalFields = [],
) => cloneDeep(fields)
  .map(({ readOnly, required, ...props }) => {
    switch (true) {
      case requiredFields.includes(props.name):
        return {
          ...props,
          required: true,
          readOnly: false,
        };
      case optionalFields.includes(props.name):
        return {
          ...props,
          required: false,
          readOnly: false,
        };
      default:
        return {
          ...props,
          required: false,
          readOnly: true,
        };
    }
  });

export const setFieldsToEdit = (
  fields,
  readOnlyFields = [],
  optionalFields = [],
) => cloneDeep(fields)
  .map(({ readOnly, required, ...props }) => {
    switch (true) {
      case readOnlyFields.includes(props.name):
        return {
          ...props,
          required: false,
          readOnly: true,
        };
      case optionalFields.includes(props.name):
        return {
          ...props,
          required: false,
          readOnly: false,
        };
      default:
        return {
          ...props,
          required: true,
          readOnly: false,
        };
    }
  });

export const selectionOfFilledValues = data => pickBy(data, (val, key) => val !== 'n/a' && val !== null);

export function isJson(str) {
  try {
    return JSON.parse(str);
  } catch (e) {
    return false;
  }
}

// this functions get array with fields and array with arrays for insert, and return a new array
export const insertElementsToArray = (defaultArray, insertingItems) => {
  const finalArray = cloneDeep(defaultArray);

  insertingItems.forEach((obj) => {
    // find index of object before inserting
    const idx = defaultArray.findIndex(item => item[obj.fieldName] === obj.fieldValue);

    // inserting element
    finalArray.splice(idx + 1, 0, obj.insertingObject);
  });

  return finalArray;
};

export const canEditBeneficiary = (subRole, isValidated) => {
  if (subRole === 'Admin' || subRole === 'SuperAdmin') {
    return true;
  }
  if (typeof isValidated === 'boolean') {
    if (!isValidated && subRole !== 'ReadOnlyAdmin') {
      return true;
    }
  }

  return false;
};

export const getUnique = (arr, key) => {
  const unique = uniqBy(arr, key);
  if (arr.length !== unique.length) {
    toastr.warning(`duplicate ${key}`);
    return unique;
  }
  return unique;
};

export const COLORS = {
  volume: '#007CEF',
  count: '#41A44C',
  approved: '#41A44C',
  averageVolumeOfOneTransaction: '#D2A44F',
  averageCountOfTransactionsPerDay: '#A6B33D',
  declined: '#DCAAAA',
  averageVolumeOfTransactionsPerDay: '#7FD1AE',
};

export const compareCurrenciesLabelsHelper = {
  volume: 'Volume',
  count: 'Count',
  averageVolumeOfOneTransaction: 'Average Volume of One Transaction',
  averageCountOfTransactionsPerDay: 'Average Count of Transactions Per Day',
  averageVolumeOfTransactionsPerDay: 'Average Volume of Transactions Per Day',
};

export const customCompose = (...fns) => arg => fns.reduce((composed, f) => f(composed), arg);

export const sortBy = (field, reverse = false) => (a, b) => (reverse ? -1 : 1 * ((a[field] > b[field]) - (b[field] > a[field])));

export function addNumbersToDuplicates(items) {
  const names = Object.values(items);

  const uniqueNames = new Set(names);

  if (uniqueNames.size !== names.length) {
    const keys = Object.keys(items);
    const newItems = { ...items };

    uniqueNames.forEach((item) => {
      const valueCount = names.filter(name => name === item).length;

      if (valueCount > 1) {
        const counter = {};
        counter[item] = 1;

        names.forEach((i, idx) => {
          if (newItems[keys[idx]] === item) {
            if (counter[item] > 1) { newItems[keys[idx]] = `${item}(${counter[item]})`; }

            counter[item] += 1;
          }
        });
      }
    });

    return newItems;
  }

  return items;
}
