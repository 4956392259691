import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import RegisterForm from '../../components/Auth/RegisterForm';

import registrationActions from '../../actions/registration';
import { registrationValidationSchema, registrationFieldsForNewUser } from '../../components/Auth/LoginFormConfig';

const { userRegistration } = registrationActions.registration;

const mapStateToProps = state => {
  return {
    fetching: state.getIn(['userInfo', 'fetching']),
    formId: 'registration-form',
    title: 'Register a WireBloom individual Account',
    registrationValidationSchema,
    fields: registrationFieldsForNewUser,
  };
};

export default connect(mapStateToProps, {
  userRegister: userRegistration.action,
})(withImmutablePropsToJS(RegisterForm));
