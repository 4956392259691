import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import accountsActions from '../../actions/accounts';
import AccountStatusHelper from '../../lib/statusHelper/AccountStatusHelper';
import PendingFormatter from '../../lib/statusFormatters/PendingFormatter';

const { setAccountStatusToCanceled } = accountsActions.accounts;

const mapStateToProps = (state) => {
  return {
    requestData: state.getIn(['accounts', 'withChangedStatus']),
    component: AccountStatusHelper,
    userRole: state.getIn(['userInfo', 'data', 'role']),
    subRole: state.getIn(['userInfo', 'data', 'subRole']),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    declineAction: payload => dispatch(setAccountStatusToCanceled.action(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withImmutablePropsToJS(PendingFormatter));
