import React, { memo } from 'react';
import PropTypes from 'prop-types';

import BeneficiaryFormatter from '../../../containers/Modals/BeneficiaryFormatter';
import SearchContainer from '../../../containers/Modals/SearchContainer';
import InfoReason from '../../../components/Layouts/InfoReason';

const BeneficiariesStatusFormatter = ({
  cell,
  data: {
    status, id, declineReason,
  },
}) => {
  const stat = status ? status.toString()
    .toLowerCase() : 'N/a';

  if (stat === 'approved' || stat === 'onhold') {
    return (
      <BeneficiaryFormatter
        content={status}
        id={id}
      />
    );
  }

  return (
    <div className={`status-block status-block__${stat}`}>
      <span className={`point point__${stat}`} />
      <SearchContainer text={cell} className="content" />
      {declineReason && declineReason !== 'n/a' && (
        <InfoReason
          declineReason={declineReason}
          id={id}
        />
      )}
    </div>
  );
};

export default memo(BeneficiariesStatusFormatter);

BeneficiariesStatusFormatter.propTypes = {
  cell: PropTypes.string.isRequired,
  data: PropTypes.shape({
    status: PropTypes.string,
    id: PropTypes.string,
    declineReason: PropTypes.string,
  }).isRequired,
};
