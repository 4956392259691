import * as yup from 'yup';

export const profileFields = [
  {
    id: 'lowestAmountPerTransaction',
    name: 'lowestAmountPerTransaction',
    type: 'currency',
    label: 'Lowest amount per transaction',
    validation: true,
    required: true,
  }, {
    id: 'highestAmountPerTransaction',
    name: 'highestAmountPerTransaction',
    type: 'currency',
    label: 'Highest amount per transaction',
    validation: true,
    required: true,
  },
  {
    id: 'monthlyVolumeLimit',
    name: 'monthlyVolumeLimit',
    type: 'currency',
    label: 'Monthly volume limit',
    validation: true,
    required: true,
  }, {
    id: 'averageMonthlyVolume',
    name: 'averageMonthlyVolume',
    type: 'currency',
    label: 'Average monthly volume',
    validation: true,
    required: true,
  }, {
    id: 'averageTransactionVolume',
    name: 'averageTransactionVolume',
    type: 'currency',
    label: 'Average transaction volume',
    validation: true,
    required: true,
  },
  {
    id: 'averageTransactionCount',
    name: 'averageTransactionCount',
    label: 'Average transaction count',
    type: 'currency',
    validation: true,
    required: true,
  },
  {
    id: 'transactionCountLimit',
    name: 'transactionCountLimit',
    label: 'Transaction count limit',
    type: 'currency',
    validation: true,
    required: true,
  },
];

export const profileValidationSchema = yup.object({
  lowestAmountPerTransaction: yup.number().required('Required!'),
  highestAmountPerTransaction: yup.number().required('Required!'),
  monthlyVolumeLimit: yup.number().required('Required!'),
  averageMonthlyVolume: yup.number().required('Required!'),
  averageTransactionVolume: yup.number().required('Required!'),
  averageTransactionCount: yup.number().required('Required!'),
  transactionCountLimit: yup.number().required('Required!'),
});
