import React, { memo } from 'react';

import PropTypes from 'prop-types';
import AnimatedSpinner from '../../components/Spinners/AnimatedSpinner';
import InfoReason from '../../components/Layouts/InfoReason';

const PendingFormatter = ({
  declineAction,
  processAction,
  declineText,
  processText,
  id,
  isVerified,
  requestData: { requestId, fetching },
  component,
  declineReason,
  status,
  userRole,
  subRole,
  entity,
}) => {
  const StatusComponent = component;

  return (
    <>
      <AnimatedSpinner fetching={id === requestId && fetching} />
      <div
        className={`status-block status-block__pending ${id === requestId && fetching ? 'content--hide' : 'content--show'}`}
      >
        <StatusComponent
          id={id}
          declineAction={declineAction}
          declineText={declineText}
          isVerified={isVerified}
          processAction={processAction}
          processText={processText}
          status={status}
          userRole={userRole}
          subRole={subRole}
          entity={entity}
        />

        <If condition={declineReason && declineReason !== 'n/a'}>
          <InfoReason
            id={id}
            declineReason={declineReason}
          />
        </If>
      </div>
    </>
  );
};

PendingFormatter.defaultProps = {
  isVerified: false,
  processAction: null,
  declineAction: () => {},
  declineText: '',
  declineReason: '',
  entity: null,
};

PendingFormatter.propTypes = {
  declineAction: PropTypes.func,
  processAction: PropTypes.func,
  declineText: PropTypes.string,
  processText: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isVerified: PropTypes.bool,
  requestData: PropTypes.shape({
    requestId: PropTypes.string.isRequired,
    fetching: PropTypes.bool.isRequired,
  }).isRequired,
  declineReason: PropTypes.string,
  status: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  subRole: PropTypes.string.isRequired,
  entity: PropTypes.string,
  component: PropTypes.elementType.isRequired,
};

export default memo(PendingFormatter);
