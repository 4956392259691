import { combineActions, handleActions } from 'redux-actions';
import { fromJS } from 'immutable';

import progressActions from '../actions/progress';
import customersActions from '../actions/customers';
import registrationActions from '../actions/registration';

const {
  addProgressItem,
  deleteProgressItem,
} = progressActions;

const {
  uploadDocument,
} = registrationActions.registration;

const {
  uploadCustomerDocument,
} = customersActions.customers;

const initialState = fromJS({
  progressItems: [],
});

const progressBar = handleActions(
  {
    [addProgressItem]: (state, { payload }) => {
      const progressItems = state.getIn(['progressItems']);
      const item = progressItems.find(i => i.get('documentTypeId') === payload.documentTypeId);

      return item
        ? state.set('progressItems', progressItems.map(i => (i.get('documentTypeId') === payload.documentTypeId ? fromJS(payload) : i)))
        : state.set('progressItems', fromJS([...progressItems, fromJS(payload)]));
    },
    [combineActions(
      uploadCustomerDocument.failure,
      uploadDocument.failure,
    )]: (state, { payload }) => {
      const progressItems = state.get('progressItems');
      const correctProgressItems = progressItems
        .filter(i => i.get('documentTypeId') !== payload.errorPayload.documentTypeId);
      return state.set('progressItems', correctProgressItems);
    },
    [deleteProgressItem]: (state, { payload }) => {
      const progressItems = state.get('progressItems');
      const correctProgressItems = progressItems
        .filter(i => i.get('documentTypeId') !== payload.documentTypeId);
      return state.set('progressItems', correctProgressItems);
    },
  },
  initialState,
);

export default progressBar;
