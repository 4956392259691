import * as yup from 'yup';

import { latinFieldWithNumbersAndPunctuationMarks } from '../../helpers/fieldsValidation';
import { setFieldsToReadOnly, setFieldsToEdit } from '../../helpers/other';

const accountFields = [
  {
    id: 'platformId',
    name: 'platformId',
    label: 'Bank',
    placeholder: 'Select Bank',
    type: 'select',
  },
  {
    id: 'enduserId',
    name: 'enduserId',
    label: 'Customer',
    placeholder: 'Select Customer',
    type: 'select',
  },
  {
    id: 'name',
    name: 'name',
    label: 'Name',
    placeholder: '',
    type: 'text',
  },
  {
    id: 'accountNumber',
    name: 'accountNumber',
    label: 'Account number',
    placeholder: '',
    type: 'text',
  },
  {
    id: 'currencyId',
    name: 'currencyId',
    label: 'Currency',
    placeholder: 'Select Currency',
    type: 'select',
  },
  {
    id: 'statusId',
    name: 'statusId',
    label: 'Status',
    placeholder: 'Select Status',
    type: 'select',
    defaultSorting: true,
  },
  {
    id: 'ledgerType',
    name: 'ledgerType',
    label: 'Ledger Type',
    placeholder: 'Select Ledger Type',
    type: 'select',
  },
];

const editFieldsId = ['statusId'];

export const editAccountFields = setFieldsToReadOnly(accountFields, editFieldsId);

export const viewAccountFields = setFieldsToReadOnly(accountFields);

export const editAccountValidationSchema = yup.object({
  platformId: yup.string()
    .required('Required!'),
  enduserId: yup.string()
    .required('Required!'),
  name: latinFieldWithNumbersAndPunctuationMarks()
    .trim()
    .required('Required!'),
  accountNumber: yup.string()
    .trim()
    .required('Required!'),
  currencyId: yup.string()
    .required('Required!'),
  statusId: yup.string()
    .required('Required!'),
  ledgerType: yup.string()
    .required('Required!'),
});
