import { combineActions, handleActions } from 'redux-actions';
import { fromJS } from 'immutable';

import accountsActions from '../actions/accounts/accountsActions';
import journalActions from '../actions/journal';
import feesActions from '../actions/fees';
import transactionsActions from '../actions/transactions';
import adminUserActions from '../actions/adminUser';
import userActions from '../actions/user';
import modalActions from '../actions/modal';

const {
  setModalVisibility,
  openModal,
  closeModal,
  cleanModalData,
  setModalFormInfo,
  setChosenItemId,
  setModalData,
} = modalActions;

const {
  createAccount,
  editAccount,
  getOneAccount,
  createAdjustment,
  getMonthlyStatement,
  getAvailableStatementsDates,
  uploadBatchFile,
} = accountsActions;
const { getOneJournal, editOneJournal } = journalActions.journal;
const { getFeesByLedgerId, changeFeesTemplateForLedgerById } = feesActions.fees;
const {
  getOneTransaction,
  getTransactionDetails,
  downloadBatchFile,
} = transactionsActions.transactions;
const { createAdminUser, getOneAdminUser, editAdminUser } = adminUserActions.adminUser;
const {
  createUser, logout, createSubUser, createSubUserByAdmin,
} = userActions.user;

const initialState = fromJS({
  isModalOpen: false,
  modalFormInfo: {
    modalId: '',
    chosenItemId: null,
    data: {},
    customModalOptions: null,
  },
  fetching: false,
});

const modal = handleActions(
  {
    [setModalVisibility]: (state, { payload }) => state.set('isModalOpen', fromJS(payload)),
    [openModal]: state => state.set('isModalOpen', fromJS(true)),
    [closeModal]: state => state.set('isModalOpen', fromJS(false)),
    [cleanModalData]: state => state.set('modalFormInfo', initialState.get('modalFormInfo')),
    [setModalFormInfo]: (state, { payload }) => state.set('modalFormInfo', fromJS(payload)),
    [setChosenItemId]: (state, { payload }) => state.set('chosenItemId', fromJS(payload)),
    [combineActions(
      getOneAccount.fetching,
      createAccount.fetching,
      editAccount.fetching,
      createAdjustment.fetching,
      getOneJournal.fetching,
      editOneJournal.fetching,
      // getTransactionDetails.fetching,
      getOneTransaction.fetching,
      getFeesByLedgerId.fetching,
      createAdminUser.fetching,
      createUser.fetching,
      getOneAdminUser.fetching,
      editAdminUser.fetching,
      createSubUser.fetching,
      createSubUserByAdmin.fetching,
      changeFeesTemplateForLedgerById.fetching,
      uploadBatchFile.fetching,
      getMonthlyStatement.fetching,
      getAvailableStatementsDates.fetching,
      downloadBatchFile.fetching,
    )]: state => state.set('fetching', true),
    [combineActions(
      getOneAccount.failure,
      getOneAccount.error,
      createAccount.failure,
      createAccount.error,
      editAccount.failure,
      editAccount.error,
      createAdjustment.failure,
      createAdjustment.error,
      getOneJournal.failure,
      getOneJournal.error,
      editOneJournal.failure,
      editOneJournal.error,
      getTransactionDetails.failure,
      getTransactionDetails.error,
      getOneTransaction.failure,
      getOneTransaction.error,
      getFeesByLedgerId.failure,
      getFeesByLedgerId.error,
      changeFeesTemplateForLedgerById.failure,
      changeFeesTemplateForLedgerById.error,
      createAdminUser.failure,
      createAdminUser.error,
      createUser.failure,
      createUser.error,
      getOneAdminUser.failure,
      getOneAdminUser.error,
      editAdminUser.failure,
      editAdminUser.error,
      createSubUser.failure,
      createSubUser.error,
      createSubUserByAdmin.failure,
      createSubUserByAdmin.error,
      getMonthlyStatement.failure,
      getMonthlyStatement.error,
      uploadBatchFile.failure,
      uploadBatchFile.error,
      downloadBatchFile.failure,
      downloadBatchFile.error,
    )]: state => state.set('fetching', false),
    [combineActions(
      getOneAccount.success,
      getOneJournal.success,
      getTransactionDetails.success,
      getOneTransaction.success,
      getFeesByLedgerId.success,
      getOneAdminUser.success,
      changeFeesTemplateForLedgerById.success,
      setModalData,
    )]: (state, { payload }) => state
      .setIn(['modalFormInfo', 'data'], fromJS(payload))
      .set('fetching', fromJS(false)),
    [combineActions(
      getMonthlyStatement.success,
      getAvailableStatementsDates.success,
    )]: state => state.set('fetching', fromJS(false)),
    [combineActions(
      createAccount.success,
      createAdjustment.success,
      editAccount.success,
      editOneJournal.success,
      createAdminUser.success,
      createUser.success,
      editAdminUser.success,
      createSubUser.success,
      createSubUserByAdmin.success,
      uploadBatchFile.success,
      downloadBatchFile.success,
      getMonthlyStatement.error,
      getMonthlyStatement.failure,
      getAvailableStatementsDates.error,
      getAvailableStatementsDates.failure,
      logout,
    )]: () => initialState,
  },
  initialState,
);

export default modal;
