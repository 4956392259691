import { createAction } from 'redux-actions';
import { GET_USER_ACTIVITY, SET_USER_ACTIVITY_TYPE } from '../../constants/constants';

import { apiCallAction } from '../actionHelpers';
import { apiCallActionHandler } from '../commonLogic/requestLogic';

const actions = {
  getUserActivity: apiCallAction(GET_USER_ACTIVITY),
  setUserActivityType: createAction(SET_USER_ACTIVITY_TYPE),
};

const getUserActivityLogic = apiCallActionHandler({
  name: 'getUserActivity',
  fetchAction: actions.getUserActivity,
  makeApiCall(payload, { api }) {
    return api.UserActivity.getUserActivity(payload);
  },
});

export const logics = [
  getUserActivityLogic,
];

export default actions;
