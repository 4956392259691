import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';

import Chat from '../Messages/Chat';
import Button from '../../generic/Button';

const Comments = (props) => {
  const {
    isSectionOpen,
    comments,
    changeSectionOpen,
    userId,
    sendComments,
    canEdit,
    editComment,
    canRemove,
    removeComment,
  } = props;

  useEffect(() => {
    if (isSectionOpen) {
      const block = document.querySelector('.account-setup_step-container');
      block.scrollTop = block.scrollHeight;
    }
  }, [isSectionOpen]);

  return (
    <div className="comments-container">
      <Choose>
        <When condition={isSectionOpen}>
          <div>
            <Chat
              data={{
                messages: comments,
                status: 'Open',
              }}
              closeBtnText="Close Comments"
              isCloseChatBtn
              closeBtnFunc={() => changeSectionOpen(false)}
              userId={userId}
              sendMessage={sendComments}
              canEdit={canEdit}
              editMessage={editComment}
              canRemove={canRemove}
              removeMessage={removeComment}
            />
          </div>
        </When>
        <Otherwise>
          <div className={`last-comment ${comments[0] ? 'comments_exist' : ''}`}>
            <div className="comment">
              <span>{`${comments[0] ? `${comments[0].userName}:` : ''}`}</span>
              {comments[0] ? comments[0].text : 'Not comments'}
            </div>
            <Button
              color="gray"
              type="primary"
              size="md"
              text="Show all comments"
              onClick={() => changeSectionOpen(true)}
            />
          </div>
        </Otherwise>
      </Choose>
    </div>
  );
};

export default memo(Comments);

Comments.propTypes = {
  isSectionOpen: PropTypes.bool.isRequired,
  comments: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    userName: PropTypes.string,
  })).isRequired,
  changeSectionOpen: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  sendComments: PropTypes.func.isRequired,
  canEdit: PropTypes.func.isRequired,
  editComment: PropTypes.func.isRequired,
  canRemove: PropTypes.func.isRequired,
  removeComment: PropTypes.func.isRequired,
};
