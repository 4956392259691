import React from 'react';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';

import UniversalModalButton from '../Modals/UniversalModalButton';

const MessagesHeader = ({ userRole }) => (
  <>
    <div className="breadcrumbs-block">
      <div>Messages</div>
      <If condition={userRole === 'User'}>
        <UniversalModalButton
          modalId="create-chat"
          title="New chat"
          text="+"
          color="green"
          type="primary"
          size="sm"
        />
      </If>
    </div>
  </>
);

const mapStateToProps = store => {
  const role = store.getIn(['userInfo', 'data', 'role']);

  return {
    userRole: role,
  };
};

export default connect(mapStateToProps, {
  rangeCalendarAction: () => {},
})(withImmutablePropsToJS(MessagesHeader));

MessagesHeader.propTypes = {
  userRole: PropTypes.string.isRequired,
};
