import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import ListPage from '../../../components/Pages/ListPage';

import userActions from '../../../actions/user';
import withLists from '../../../components/HOC/withLists';
import AdminUserActivityLogsHeader from '../../Headers/AdminUserActivityLogsHeader';

const {
  getUserActivityLogs,
} = userActions.user;

const mapStateToProps = (state) => {
  const currentState = state.getIn(['users', 'activity']);

  return {
    data: currentState,
    header: AdminUserActivityLogsHeader,
    title: 'Admin User Activity Logs',
    pageWithId: true,
    defaultSorted: [{
      dataField: 'time',
      order: 'desc',
    }],
  };
};

export default connect(mapStateToProps, {
  fetchGetData: getUserActivityLogs.action,
})(withLists(withImmutablePropsToJS(ListPage)));
