import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import ApplicationsDashboardHeader from './ApplicationsDashboardHeader';
import CustomPieChart from '../CustomPieChart';
import CustomSpinner from '../../../Spinners/CustomSpinner';
import EmptyValidation from '../../../HOC/EmptyValidation';

const ApplicationsDashboard = ({ data: { fetching, data: { countList } } }) => {

  return (
    <div className="full-height main_padding">
      <ApplicationsDashboardHeader />
      <h3>Users statuses diagram</h3>
      <div className="charts-block applications">
        <CustomSpinner fetching={fetching} />
        <div
          className={`${!fetching ? 'content--show' : 'content--hide-without-transition'}`}
        >
          <EmptyValidation
            isNotEmpty={!isEmpty(countList)}
            whenIsEmptyContent="There is no users data."
          >
            <CustomPieChart
              data={countList}
            />
          </EmptyValidation>
        </div>
      </div>
    </div>
  );
};

ApplicationsDashboard.propTypes = {
  data: PropTypes.shape({
    fetching: PropTypes.bool.isRequired,
    data: PropTypes.shape({
      countList: PropTypes.array.isRequired,
    }),
  }).isRequired,
};

export default ApplicationsDashboard;
