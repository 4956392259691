import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import transactionsActions from '../../actions/transactions';
import beneficiariesActions from '../../actions/beneficiaries';
import TransactionPendingFormatter from '../../lib/statusFormatters/TransactionPendingFormatter';

const { createBeneficiaryOnPlatform } = beneficiariesActions.beneficiaries;
const {
  processOneTransaction,
  declineOneTransaction,
  approveOneTransaction,
} = transactionsActions.transactions;

const mapStateToProps = state => ({
  transactionsStatusChanging: state.getIn(['transactions', 'transactionsStatusChanging']),
  subRole: state.getIn(['userInfo', 'data', 'subRole']),
  userRole: state.getIn(['userInfo', 'data', 'role']),
});

export default connect(mapStateToProps, {
  declineAction: declineOneTransaction.action,
  processAction: processOneTransaction.action,
  approveTransaction: approveOneTransaction.action,
  createBeneficiaryOnPlatform: createBeneficiaryOnPlatform.action,
})(withImmutablePropsToJS(TransactionPendingFormatter));
