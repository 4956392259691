import React from 'react';

import {
  sortHeaderFormatter,
  defaultFormatter,
  falseFormatter,
} from '../../tableUtils/tablesUtils';

const processingErrorsColumns = () => (
  [{
    dataField: 'type',
    text: 'Type',
    sort: true,
    classes: 'main-field',
    formatter: defaultFormatter,
    headerFormatter: sortHeaderFormatter,
    headerStyle: () => ({ width: '7%' }),
    wpx: 80,
  },{
    dataField: 'request',
    text: 'Request',
    sort: true,
    formatter: defaultFormatter,
    headerFormatter: sortHeaderFormatter,
    headerStyle: () => ({ width: '30%' }),
    wpx: 300,
  },{
    dataField: 'response',
    text: 'Response',
    sort: true,
    formatter: defaultFormatter,
    headerFormatter: sortHeaderFormatter,
    headerStyle: () => ({ width: '30%' }),
    wpx: 300,
  },{
    dataField: 'createdAt',
    text: 'Created At',
    sort: true,
    formatter: defaultFormatter,
    headerFormatter: sortHeaderFormatter,
    headerStyle: () => ({ width: '6%' }),
    wpx: 120,
  },{
    dataField: 'createdUser',
    text: 'Created User',
    sort: true,
    formatter: falseFormatter,
    headerFormatter: sortHeaderFormatter,
    headerStyle: () => ({ width: '6%' }),
    wpx: 80,
  }, {
    dataField: 'message',
    text: 'Message',
    sort: true,
    formatter: defaultFormatter,
    headerFormatter: sortHeaderFormatter,
    headerStyle: () => ({ width: '12%' }),
    wpx: 100,
  }, {
    dataField: 'id',
    text: 'Id',
    sort: true,
    formatter: defaultFormatter,
    headerFormatter: sortHeaderFormatter,
    headerStyle: () => ({ width: '8%' }),
    wpx: 100,
  }]
);

export default processingErrorsColumns;
