import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import ListPage from '../../../components/Pages/ListPage';
import TransactionsHeader from '../../Headers/TransactionsHeader';

const mapStateToProps = (state) => {
  const currentState = state.getIn(['transactions', 'list']);
  return {
    data: currentState,
    list: [],
    header: TransactionsHeader,
    title: 'transactions',
    defaultSorted: [{
      dataField: 'postDate',
      order: 'desc',
    }],
  };
};

export default connect(mapStateToProps, null)(withImmutablePropsToJS(ListPage));
