import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { isUserAuthenticated } from '../actions/localStorage';


const SecretRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Choose>
        <When condition={isUserAuthenticated()}>
          <Component {...props} />
        </When>
        <Otherwise>
          <Redirect to={{
            pathname: '/auth',
            state: { from: props.location },
          }}
          />
        </Otherwise>
      </Choose>

    )}
  />
);

export default SecretRoute;
