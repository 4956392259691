import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import ListPage from '../../components/Pages/ListPage';
import feesChargesActions from '../../actions/feesCharges';
import FeesChargesHeader from '../Headers/FeesChargesHeader';

const { getFeesCharges } = feesChargesActions.feesCharges;

const mapStateToProps = (state) => {
  const currentState = state.getIn(['feesCharges', 'list']);
  return {
    data: currentState,
    header: FeesChargesHeader,
    title: 'Fees Charges',
    defaultSorted: [{
      dataField: 'postDate',
      order: 'desc',
    }],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchGetData: () => dispatch(getFeesCharges.action()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withImmutablePropsToJS(ListPage));
