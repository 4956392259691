import { customFilter } from 'react-bootstrap-table2-filter';
import React from 'react';

import CustomSelectFilter from '../../../components/CustomTable/CustomFilter';
import UserEmailFormatter from '../../tableUtils/UserEmailFormatter';
import {
  sortHeaderFormatter,
  dateFormatter,
  defaultFormatter,
  userStatusFormatter,
} from '../../tableUtils/tablesUtils';
import customSelectionFilter from '../../customSelectionFilter';
import { selectUniqValues } from '../../../helpers/status';

const userColumns = (currentData, userRole) => {
  const usersStatuses = selectUniqValues(currentData, 'status');

  return (
    [{
      dataField: 'companyName',
      text: 'Company Name',
      sort: true,
      formatter: defaultFormatter,
      headerFormatter: sortHeaderFormatter,
      headerStyle: () => ({ width: '6%' }),
      wpx: 100,
    }, {
      dataField: 'firstName',
      text: 'First Name',
      sort: true,
      formatter: defaultFormatter,
      headerFormatter: sortHeaderFormatter,
      headerStyle: () => ({ width: '6%' }),
      wpx: 100,
    }, {
      dataField: 'lastName',
      text: 'Last Name',
      sort: true,
      formatter: defaultFormatter,
      headerFormatter: sortHeaderFormatter,
      headerStyle: () => ({ width: '6%' }),
      wpx: 100,
    }, {
      dataField: 'email',
      text: 'Email',
      sort: true,
      formatter: (cell, fields) => (
        <UserEmailFormatter cell={cell} fields={fields} userRole={userRole} />
      ),
      headerFormatter: sortHeaderFormatter,
      headerStyle: () => ({ width: '10%' }),
      wpx: 80,
    }, {
      dataField: 'phoneNumber',
      text: 'Phone Number',
      sort: true,
      formatter: defaultFormatter,
      headerFormatter: sortHeaderFormatter,
      headerStyle: () => ({ width: '7%' }),
      wpx: 120,
    }, {
      dataField: 'lastLogInDate',
      text: 'Last LogIn Date',
      sort: true,
      formatter: (cell, { id }) => dateFormatter(cell, `${id}-lastLogInDate`),
      headerFormatter: sortHeaderFormatter,
      headerStyle: () => ({ width: '5%' }),
      wpx: 120,
    }, {
      dataField: 'createdDate',
      text: 'Created Date',
      sort: true,
      formatter: (cell, { id }) => dateFormatter(cell, `${id}-createdDate`),
      headerFormatter: sortHeaderFormatter,
      headerStyle: () => ({ width: '5%' }),
      wpx: 120,
    }, {
      dataField: 'status',
      text: 'Status',
      sort: true,
      headerClasses: 'with-filter',
      classes: 'status',
      headerStyle: () => ({ width: '7%' }),
      formatter: userStatusFormatter,
      filter: customFilter({
        onFilter: (filterObj, data) => customSelectionFilter(filterObj, data, 'status'),
      }),
      filterRenderer: (onFilter, column) => (
        <CustomSelectFilter
          onFilter={onFilter}
          column={column}
          selectItems={usersStatuses}
          formClass="custom-filter-status"
        />
      ),
      wpx: 100,
    }]
  );
};

export default userColumns;
