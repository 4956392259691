import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import withImmutablePropsToJS from 'with-immutable-props-to-js/lib/index';

import DocumentsUpload from '../../components/Pages/AccountSetup/DocumentsUpload';
import modalActions from '../../actions/modal';
import progressActions from '../../actions/progress';

const { openModal, setModalFormInfo } = modalActions;
const { addProgressItem, deleteProgressItem } = progressActions;

const mapStateToProps = state => {
  const { documentTypes } = state.get('lists')
    .toJS();

  return {
    formName: 'identification',
    lists: {
      documentTypes: documentTypes.data,
    },
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openModal: () => dispatch(openModal()),
    setModalFormInfo: info => dispatch(setModalFormInfo(info)),
    dispatchProgress: {
      addProgressItem: info => dispatch(addProgressItem(info)),
      deleteProgressItem: info => dispatch(deleteProgressItem(info)),
    },
  };
};

export default connect(
    mapStateToProps, mapDispatchToProps,
)(withImmutablePropsToJS(withRouter(DocumentsUpload)));
