import { combineActions, handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import { combineReducers } from 'redux-immutable';

import userActivityActions from '../actions/userActivity';
import userActions from '../actions/user/userActions';
const { logout } = userActions;

const { getUserActivity, setUserActivityType } = userActivityActions.userActivity;

const initialState = fromJS({
  list: {
    data: [],
    error: false,
    fetching: false,
  },
  one: {
    data: {},
    error: false,
    fetching: false,
  },
  type: ""
});

const UserActivityReducer = handleActions(
  {
    [combineActions(
      getUserActivity.fetching,
    )]: state => state
      .set('fetching', true)
      .set('error', false),
    [combineActions(
      getUserActivity.done,
      getUserActivity.failure,
      getUserActivity.error,
    )]: state => state
      .set('fetching', false)
      .set('error', true),
    [getUserActivity.success]: (state, { payload }) => state
      .set('data', fromJS(payload))
      .set('fetching', false),
    [logout]: () => initialState.get('list'),
  },
  initialState.get('list'),
);

const UserActivityTypeReducer = handleActions(
  {
    [setUserActivityType]: (state, { payload }) => payload,
    [logout]: () => initialState.get('type'),
  },
  initialState.get('type'),
);

export default combineReducers({
  list: UserActivityReducer,
  type: UserActivityTypeReducer,
});
