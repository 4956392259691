import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import SearchContainer from '../../containers/Modals/SearchContainer';
import Tooltip from '../../components/generic/Tooltip';

const CustomerNameFormatter = ({
  cell, id, userRole, status,
}) => (
  <NavLink
    to={{
      pathname: (userRole === 'Admin' || userRole === 'SuperAdmin')
        ? `/customers/edit/${id}`
        : `/customers/view/${id}`,
      state: { customer: id },
    }}
    activeClassName="active"
  >
    <SearchContainer text={`${cell}`} data-tip="customer" data-for={`customer-${id}`} />

    <Tooltip
      id={`customer-${id}`}
      text={((status === 'Active' && userRole === 'Admin') || userRole === 'SuperAdmin')
        ? 'Edit Customer Profile'
        : 'View Customer Profile'}
    />
  </NavLink>
);

CustomerNameFormatter.propTypes = {
  cell: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

export default memo(CustomerNameFormatter);
