import React from 'react';
import { textFilter } from 'react-bootstrap-table2-filter';

import RemoveSelectFee from '../selectionTransactionFormatters/RemoveSelectFeeContainer';
import RemoveSelectTransaction from '../selectionTransactionFormatters/RemoveSelectTransactionContainer';

import {
  sortHeaderFormatter,
  priceFormatter,
  defaultFormatter,
} from '../tableUtils/tablesUtils';
import { currencyFormatter } from '../../helpers/other';

const selectListToProcessing = [
  {
    dataField: 'id',
    text: 'Id',
    sort: true,
    headerFormatter: sortHeaderFormatter,
    headerStyle: () => ({ width: '7%' }),
  },
  {
    dataField: 'accountCurrency',
    text: 'Account Currency',
    sort: true,
    filter: textFilter(),
    formatter: defaultFormatter,
    headerFormatter: sortHeaderFormatter,
    headerStyle: () => ({ width: '4%' }),
  },
  {
    dataField: 'amount',
    text: 'Amount',
    sort: true,
    formatter: priceFormatter,
    headerFormatter: sortHeaderFormatter,
    headerStyle: () => ({ width: '3%' }),
    transformationItem: currencyFormatter,
  },
];

export const transactionsSelectListToProcessing = [
  ...selectListToProcessing,
  {
    dataField: 'transactionCurrency',
    text: 'Transaction  Currency',
    sort: true,
    formatter: defaultFormatter,
    headerFormatter: sortHeaderFormatter,
    headerStyle: () => ({ width: '4%' }),
  },
  {
    dataField: 'processingType',
    text: 'Processing Type',
    sort: true,
    headerClasses: 'with-filter',
    formatter: defaultFormatter,
    headerStyle: () => ({ width: '4%' }),
  },
  {
    dataField: 'remove',
    text: 'Remove',
    formatter: (cell, { id }) => <RemoveSelectTransaction id={id} />,
    headerStyle: () => ({ width: '2%' }),
  },
];

export const chargesSelectListToProcessing = [
  ...selectListToProcessing,
  {
    dataField: 'feeName',
    text: 'Fee',
    sort: true,
    headerClasses: 'with-filter',
    formatter: defaultFormatter,
    headerStyle: () => ({ width: '7%' }),
  },
  {
    dataField: 'remove',
    text: 'Remove',
    formatter: (cell, { id }) => <RemoveSelectFee id={id} />,
    headerStyle: () => ({ width: '2%' }),
  },
];
