import { createAction } from 'redux-actions';
import { toastr } from 'react-redux-toastr';
import {
  cloneDeep, mapValues, keyBy, isEmpty,
} from 'lodash';

import { prepareContentForToastr } from '../../helpers/other';

import {
  GET_CUSTOMERS,
  GET_ONE_CUSTOMER,
  DELETE_CUSTOMER_CONTACT,
  DELETE_CUSTOMER_DOCUMENT,
  UPLOAD_CUSTOMER_DOCUMENT,
  DOWNLOAD_CUSTOMER_DOCUMENT,
  UPDATE_CUSTOMER_FIELD,
  CHANGE_CUSTOMER_STATUS,
  CLEAN_CUSTOMER_DATA,
  CREATE_CUSTOMER_CONTACT,
  GET_COMMENTS_FOR_CUSTOMER,
  ADD_COMMENTS_FOR_CUSTOMER,
  REMOVE_COMMENT_FOR_CUSTOMER,
  EDIT_COMMENTS_FOR_CUSTOMER,
} from '../../constants/constants';

import { apiCallAction } from '../actionHelpers';
import { apiCallActionHandler } from '../commonLogic/requestLogic';
import { adminUserPersonalInfoFields, personalInfoFields } from '../../schemes/forms/companyInfo';
import { accountActivityFields } from '../../schemes/forms/accountActivity';
import { ownershipFields } from '../../schemes/forms/ownership';
import { profileFields } from '../../schemes/forms/profile';
import {
  notifChangeCustomerStatus, notifCreateCustomerContact,
  notifDeleteCustomerContact,
  notifDeleteDoc, notifUpdateCustomerField, notifUploadDoc
} from '../../helpers/notificatios';

const actions = {
  getCustomers: apiCallAction(GET_CUSTOMERS),
  getOneCustomer: apiCallAction(GET_ONE_CUSTOMER),
  changeCustomerStatus: apiCallAction(CHANGE_CUSTOMER_STATUS),
  updateCustomerField: apiCallAction(UPDATE_CUSTOMER_FIELD),
  downloadCustomerDocument: apiCallAction(DOWNLOAD_CUSTOMER_DOCUMENT),
  uploadCustomerDocument: apiCallAction(UPLOAD_CUSTOMER_DOCUMENT),
  deleteCustomerContact: apiCallAction(DELETE_CUSTOMER_CONTACT),
  createCustomerContact: apiCallAction(CREATE_CUSTOMER_CONTACT),
  deleteCustomerDocument: apiCallAction(DELETE_CUSTOMER_DOCUMENT),
  cleanCustomerData: createAction(CLEAN_CUSTOMER_DATA),
  getCommentsForCustomer: apiCallAction(GET_COMMENTS_FOR_CUSTOMER),
  addCommentsForCustomer: apiCallAction(ADD_COMMENTS_FOR_CUSTOMER),
  editCommentsForCustomer: apiCallAction(EDIT_COMMENTS_FOR_CUSTOMER),
  removeCommentForCustomer: apiCallAction(REMOVE_COMMENT_FOR_CUSTOMER),
};

const getOneCustomerLogic = apiCallActionHandler({
  name: 'getOneCustomer',
  fetchAction: actions.getOneCustomer,
  makeApiCall(payload, { api }) {
    return api.customers.getOneCustomer(payload);
  },
  transformResponse(response) {
    if (response && response.data) {
      const {
        contacts, enduser: { address, ...item }, limits, ...rest
      } = response.data;

      const transformContacts = contacts.map(({ contactAddress, ...restProps }) => ({
        ...contactAddress, ...restProps,
      }));

      return {
        limits: isEmpty(limits) ? {} : mapValues(keyBy(limits, 'name'), 'value'),
        contacts: transformContacts,
        enduser: { ...address, ...item },
        ...rest,
      };
    }

    return response;
  },
});

const getCustomersLogic = apiCallActionHandler({
  name: 'getCustomers',
  fetchAction: actions.getCustomers,
  makeApiCall(payload, { api }) {
    return api.customers.getCustomers(payload);
  },
  transformPayload({ dateFromFormatted, dateToFormatted }) {
    return {
      dateFrom: dateFromFormatted,
      dateTo: dateToFormatted,
    };
  },
});

const changeCustomerStatusLogic = apiCallActionHandler({
  name: 'changeCustomerStatus',
  fetchAction: actions.changeCustomerStatus,
  makeApiCall(payload, { api }) {
    return api.customers.changeCustomerStatus(payload);
  },
  onSuccess() {
    toastr.success(
      'Success',
      notifChangeCustomerStatus,
    );
  },
});

const downloadCustomerDocumentLogic = apiCallActionHandler({
  name: 'downloadCustomerDocument',
  fetchAction: actions.downloadCustomerDocument,
  makeApiCall(payload, { api }) {
    return api.customers.downloadCustomerDocument(payload);
  },
});

const deleteCustomerDocumentLogic = apiCallActionHandler({
  name: 'deleteCustomerDocument',
  fetchAction: actions.deleteCustomerDocument,
  makeApiCall(payload, { api }) {
    return api.customers.deleteCustomerDocument(payload);
  },
  onSuccess(response, payload) {
    toastr.success('Success', notifDeleteDoc(payload.id), { timeOut: 10000 });
  },
  transformResponse(response, payload) {
    return payload;
  },
});

const deleteCustomerContactLogic = apiCallActionHandler({
  name: 'deleteCustomerContact',
  fetchAction: actions.deleteCustomerContact,
  makeApiCall(payload, { api }) {
    return api.customers.deleteCustomerContact(payload);
  },
  onSuccess(response, payload, other) {
    const {
      customers: {
        one: {
          data: {
            contacts,
          },
        },
      },
    } = other.getState()
      .toJS();
    const { firstName, lastName } = contacts.find(item => item.id === payload.id);
    toastr.success('Success', notifDeleteCustomerContact(firstName, lastName), { timeOut: 10000 });
  },
  transformResponse(res, data) {
    return data;
  },
});

const createCustomerContactLogic = apiCallActionHandler({
  name: 'createCustomerContact',
  fetchAction: actions.createCustomerContact,
  makeApiCall(payload, { api }) {
    return api.customers.createCustomerContact(payload);
  },
  onSuccess({ id }) {
    toastr.success('Success', notifCreateCustomerContact(id), { timeOut: 10000 });
  },
});

const uploadCustomerDocumentLogic = apiCallActionHandler({
  name: 'uploadCustomerDocument',
  fetchAction: actions.uploadCustomerDocument,
  makeApiCall(payload, { api }) {
    return api.customers.uploadCustomerDocument(payload);
  },
  onSuccess(response, { fileName }) {
    toastr.success('Success', notifUploadDoc(fileName));
  },
  transformResponse({ data }, { documentTypeId, fileName, description = null }, { getState }) {
    return {
      documentTypeList: getState().getIn(['lists', 'documentTypes', 'data']),
      data: {
        id: data,
        documentType: documentTypeId,
        fileName,
        description,
      },
    };
  },
});

const updateCustomerFieldLogic = apiCallActionHandler({
  name: 'updateCustomerField',
  fetchAction: actions.updateCustomerField,
  makeApiCall(payload, { api }) {
    return api.customers.updateCustomerField(payload);
  },
  onSuccess(response, { fieldName, value, objectName }, other) {
    const { lists, userInfo: { data: { role } } } = other.getState()
      .toJS();

    let content = {
      name: '',
      value: '',
    };

    let fields;

    switch (objectName) {
      case 'enduser':
        fields = role === 'Admin'
          ? cloneDeep([
            ...personalInfoFields,
            ...adminUserPersonalInfoFields,
          ])
          : personalInfoFields;
        content = prepareContentForToastr(fields, fieldName, value, lists);
        break;
      case 'accountActivity':
        content = prepareContentForToastr(accountActivityFields, fieldName, value, lists);
        break;
      case 'contacts':
        content = prepareContentForToastr(ownershipFields, fieldName, value, lists);
        break;
      case 'limits':
        content = prepareContentForToastr(profileFields, fieldName, value, lists);
        break;
      default:
      // code block
    }
    toastr.success('Success', notifUpdateCustomerField(content.name, content.value), { timeOut: 10000 });
  },
  transformResponse(res, data) {
    return data;
  },
});

const getCommentsForCustomerLogic = apiCallActionHandler({
  name: 'getCommentsForCustomer',
  fetchAction: actions.getCommentsForCustomer,
  makeApiCall(payload, { api }) {
    return api.customers.getCommentsForCustomer(payload);
  },
});

const addCommentsForCustomerLogic = apiCallActionHandler({
  name: 'addCommentsForCustomer',
  fetchAction: actions.addCommentsForCustomer,
  makeApiCall(payload, { api }) {
    return api.customers.addCommentsForCustomer(payload);
  },
  transformPayload: (payload, { getState }) => {
    const userId = getState().getIn(['userInfo', 'data', 'sub']);

    return { ...payload, senderId: userId, id: payload.id || Date.now() };
  },
  transformResponse: (res, data) => ({
    messKey: data.id,
    data: res.data,
  }),
});

const editCommentsForCustomerLogic = apiCallActionHandler({
  name: 'editCommentsForCustomer',
  fetchAction: actions.editCommentsForCustomer,
  makeApiCall(payload, { api }) {
    return api.customers.editCommentsForCustomer(payload);
  },
  transformResponse: (res, data) => ({
    ...data,
    status: '',
    isEdited: true,
  }),
});

const removeCommentForCustomerLogic = apiCallActionHandler({
  name: 'removeCommentForCustomer',
  fetchAction: actions.removeCommentForCustomer,
  makeApiCall(payload, { api }) {
    return api.customers.removeCommentForCustomer(payload);
  },
  transformResponse: (res, data) => ({
    id: data.id,
  }),
});

export const logics = [
  getOneCustomerLogic,
  updateCustomerFieldLogic,
  getCustomersLogic,
  changeCustomerStatusLogic,
  downloadCustomerDocumentLogic,
  deleteCustomerDocumentLogic,
  uploadCustomerDocumentLogic,
  deleteCustomerContactLogic,
  createCustomerContactLogic,
  getCommentsForCustomerLogic,
  addCommentsForCustomerLogic,
  removeCommentForCustomerLogic,
  editCommentsForCustomerLogic,
];

export default actions;
