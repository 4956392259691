import feesChargesColumns from './tablesSchema/admin/feesCharges';
import transactionsColumns from './tablesSchema/transactions';
import beneficiariesColumns from './tablesSchema/beneficiaries';
import journalOfSelectedAccount from './tablesSchema/journalOfSelectedAccount';
import userActivityLogsColumns from './tablesSchema/userActivityLogs';
import userActivityColumns from './tablesSchema/admin/userActivityColumns';
import journalColumns from './tablesSchema/admin/journal';
import accountsColumns from './tablesSchema/accounts';
import { transactionsReportColumns, transactionsExceptionReportColumns } from './tablesSchema/reports/transactionsReport';
import processingErrorsColumns from './tablesSchema/admin/processingErrors';
import usersColumns from './tablesSchema/admin/users';
import adminUsersColumns from './tablesSchema/admin/adminUsers';
import customersColumns from './tablesSchema/customers';
// import batchHistoryColumns from './tablesSchema/admin/batchHistory';
import { transactionsERPReportColumns } from './tablesSchema/reports/transactionERPReport';

export default function getColumns(pageTitle, data, userRole, subRole, isReadOnlySubUser) {
  switch (pageTitle) {
    case 'Accounts':
      return accountsColumns(data, userRole, subRole);
    case 'Fees Charges':
      return feesChargesColumns(data);
    case 'transactions':
      return transactionsColumns(data, userRole, subRole);
    case 'Beneficiaries':
      return beneficiariesColumns(data, userRole, subRole, isReadOnlySubUser);
    case 'Journal':
      return journalColumns(data, subRole);
    case 'Journal by Account':
      return journalOfSelectedAccount(data);
    case 'User Activity Logs':
    case 'Admin User Activity Logs':
      return userActivityLogsColumns(data);
    case 'User Activity':
      return userActivityColumns(data);
    case 'Processed Transactions':
      return transactionsReportColumns(data);
    case 'Unknown Exception Transactions':
      return transactionsExceptionReportColumns(data);
    case 'Out of Limits Transactions':
      return transactionsExceptionReportColumns(data);
    case 'Failed Transactions':
      return transactionsExceptionReportColumns(data);
    case 'ERP Transactions':
      return transactionsERPReportColumns;
    case 'Processing Errors':
      return processingErrorsColumns(data);
    case 'Users':
      return usersColumns(data, userRole);
    case 'Admin Users':
      return adminUsersColumns(data);
    case 'Customers':
      return customersColumns(data, subRole);
    default:
      return [];
  }
}
