import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import PasswordForm from '../../components/Auth/PasswordForm';
import userActions from '../../actions/user';
import {
  newPassFields,
  newPassValidationSchema,
} from '../../components/Auth/LoginFormConfig';

const { resetPassword } = userActions.user;

const mapStateToProps = (state) => {
  return {
    titleForm: 'Reset Password',
    validationSchema: newPassValidationSchema,
    fields: newPassFields,
    submitBtnText: 'Reset',
    formId: 'reset-pass-form',
    fetching: state.getIn(['userInfo', "fetching"]),
  };
};

const mapDispatchToProps = dispatch => ({
  submitForm: options => {
    return dispatch(resetPassword.action(options));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withImmutablePropsToJS(PasswordForm));
