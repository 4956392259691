import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import CreateChatModal from '../../components/Modals/CreateChatModal';

import modalActions from '../../actions/modal';

const { closeModal, cleanModalData } = modalActions;

const mapStateToProps = (state) => {
  const { isModalOpen } = state.get('modal')
    .toJS();
  return {
    isModalOpen,
    modalId: 'create-chat',
    // fields: {
    //   journalFieldsForTransactions,
    // },
    // validationSchema: {
    //   validationSchemaForCharge: journalValidationSchemaForCharge(transactionFees.data),
    // },
  };
};

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal()),
  cleanModalData: () => dispatch(cleanModalData()),
  onSubmit: () => {},
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(withImmutablePropsToJS(CreateChatModal));
