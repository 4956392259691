import React, {
  memo, useEffect, useState, useRef,
} from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from 'recharts';
import Button from '../../generic/Button';
import CustomSpinner from '../../Spinners/CustomSpinner';
import { convertDateToLocaleFormat } from '../../../helpers/dates';
import MessSendingStatus from '../../generic/messSendingStatus';

const Chat = (props) => {
  const {
    fetching,
    data,
    userId,
    isCloseChatBtn,
    closeBtnText,
    closeBtnFunc,
    sendMessage,
    canEdit,
    editMessage,
    canRemove,
    removeMessage,
  } = props;

  const textArea = useRef();

  const [newMessText, setNewMessText] = useState('');
  const [contextMenu, setContextMenu] = useState('');

  const initEditMess = {
    isEditMess: false,
  };
  const [{ isEditMess, id: editMessId = '', ...editMessData }, setEditMessData] = useState(initEditMess);

  useEffect(() => {
    window.addEventListener('click', () => {
      if (contextMenu) {
        setContextMenu('');
      }
    });
  }, [contextMenu]);

  const openContextMenu = messId => (e) => {
    e.preventDefault();
    if (e.target.className === 'text') {
      setContextMenu(messId);
    }
  };

  const submitNewMess = () => {
    sendMessage(newMessText);
    setNewMessText('');
  };

  const setMessForEdit = mess => () => {
    setEditMessData({
      isEditMess: true,
      ...mess,
    });
    setNewMessText(mess.text);
    textArea.current.focus();
  };

  const submitEditMess = () => {
    if (newMessText !== editMessData.text) {
      editMessage({
        ...editMessData,
        id: editMessId,
        text: newMessText,
        adminId: userId,
      });
      setEditMessData(initEditMess);
      setNewMessText('');
    }
  };

  const cancelEdit = () => {
    setEditMessData(initEditMess);
    setNewMessText('');
  };

  return (
    <div className="chat">
      <div className={`${fetching ? 'spinner' : 'd-none'}`}>
        <CustomSpinner fetching={fetching} />
      </div>
      <If condition={!fetching}>
        <If condition={isCloseChatBtn}>
          <div className="chat__closeChatBtn">
            <Button
              color="green"
              type="primary"
              size="md"
              text={closeBtnText}
              onClick={closeBtnFunc}
            />
          </div>
        </If>

        <div className="chat__messages">
          {data.messages.map((mess) => {
            const {
              text,
              id,
              senderId,
              createdAt,
              status,
              userName,
              isEdited,
            } = mess;

            return (
              <div className={`chat__messages--item ${userId === senderId ? 'right' : 'left'}`} key={id}>
                <p className="time">
                  {`${userName && userId !== senderId ? `${userName}, ` : ''}${convertDateToLocaleFormat(createdAt, 'h:mm A D.MM.YYYY')}`}
                  <If condition={isEdited}>
                    <i className="iconfont-edit" />
                  </If>
                </p>
                <div className="text_block">
                  <Choose>
                    <When condition={editMessId === id}>
                      <p className="text edit-mode">{text}</p>
                    </When>
                    <Otherwise>
                      <p
                        className="text"
                        onContextMenu={openContextMenu(id)}
                      >
                        {text}
                      </p>
                    </Otherwise>
                  </Choose>

                  <MessSendingStatus
                    status={status}
                    replayseMessage={() => sendMessage(text, id)}
                    replayseEditMessage={() => editMessage(mess)}
                  />

                  <If condition={contextMenu === id && (canEdit(senderId) || canRemove(senderId))}>
                    <div className="contextMenu">
                      <div className="contextMenu__block">
                        <If condition={canEdit(senderId)}>
                          <div
                            className="contextMenu__block-item"
                            onClick={setMessForEdit(mess)}
                          >
                            <i className="iconfont-edit" />
                            <span>Edit</span>
                          </div>
                        </If>
                        <If condition={canRemove(senderId)}>
                          <div
                            className="contextMenu__block-item"
                            onClick={() => removeMessage(mess)}
                          >
                            <i className="iconfont-delete" />
                            <span>Remove</span>
                          </div>
                        </If>
                      </div>
                    </div>
                  </If>
                </div>
              </div>
            );
          })}
        </div>

        <If condition={data.status === 'Open'}>
          <div className="chat__newMessage">
            <textarea
              className={isEditMess ? 'editMode' : ''}
              placeholder="Write a message..."
              ref={textArea}
              value={newMessText}
              onChange={({ target }) => setNewMessText(target.value)}
            />
            <Choose>
              <When condition={isEditMess}>
                <i
                  className={`icon iconfont-check-mark ${newMessText === editMessData.text ? 'disabled' : ''}`}
                  onClick={submitEditMess}
                />
                <i
                  className="icon iconfont-x-mark"
                  data-tip="edit-mess"
                  data-for="edit-mess"
                  onClick={cancelEdit}
                />
                <Tooltip id="edit-mess" text="Cancel edit" />
              </When>
              <Otherwise>
                <Button
                  className="send-btn"
                  color="green"
                  type="primary"
                  size="lg"
                  text="Send"
                  onClick={submitNewMess}
                />
              </Otherwise>
            </Choose>
          </div>
        </If>
      </If>
    </div>
  );
};

Chat.defaultProps = {
  isCloseChatBtn: false,
  data: {
    messages: [],
    status: '',
  },
  closeBtnText: '',
  fetching: false,
  closeBtnFunc: () => {},
  sendMessage: () => {},
};

Chat.propTypes = {
  data: PropTypes.shape({
    messages: PropTypes.arrayOf(PropTypes.object),
    status: PropTypes.string,
  }),
  fetching: PropTypes.bool,
  isCloseChatBtn: PropTypes.bool,
  userId: PropTypes.string.isRequired,
  closeBtnText: PropTypes.string,
  closeBtnFunc: PropTypes.func,
  sendMessage: PropTypes.func,
  canEdit: PropTypes.func.isRequired,
  editMessage: PropTypes.func.isRequired,
  canRemove: PropTypes.func.isRequired,
  removeMessage: PropTypes.func.isRequired,
};

export default memo(Chat);
