import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ChartsByStatusByCurrencyContainer from './ChartsByStatusByCurrencyContainer';
import ChartsByStatusByTypeContainer from './ChartsByStatusByTypeContainer';

const CompareChartsByStatuses = ({ defaultKeys, defaultValues }) => {
  const [activeItems, setActiveItems] = useState('volume');
  const [keys, setKeys] = useState(defaultKeys);
  const [values, setValues] = useState(defaultValues);

  const getVolume = () => {
    setActiveItems('volume');
    setKeys({
      Approved: 'volumeApproved',
      Declined: 'volumeDeclined',
    });
    setValues({
      Approved: 'percentApprovedByValue',
      Declined: 'percentDeclinedByValue',
    });
  };

  const getCount = () => {
    setActiveItems('count');
    setKeys({
      Approved: 'countApproved',
      Declined: 'countDeclined',
    });
    setValues({
      Approved: 'percentApprovedByValue',
      Declined: 'percentDeclinedByValue',
    });
  };

  return (
    <>
      {/* <CustomSpinner fetching={fetching} /> */}
      <div
        className="flexible-block"
      >
        <div className="flexible-block__header">
          <h3 className="flexible-block__title">Comparison charts of transactions by statuses, %</h3>
          <span className="select-group__block">
            <span
              className={`select-group__item ${activeItems === 'volume' ? 'active' : ''}`}
              onClick={getVolume}
            >
              Volume
            </span>
            <span
              className={`select-group__item ${activeItems === 'count' ? 'active' : ''}`}
              onClick={getCount}
            >
              Count
            </span>
          </span>
        </div>
        <div className="flexible-block__content flexible-section">
          <ChartsByStatusByCurrencyContainer
            type="currency"
            dataKey1={values.Declined}
            dataKey2={values.Approved}
            name1="Declined"
            name2="Approved"
            keys={keys}
          />
          <ChartsByStatusByTypeContainer
            type="type"
            // dataKey1="countDeclined"
            // dataKey2="countApproved"
            dataKey1={values.Declined}
            dataKey2={values.Approved}
            name1="Declined"
            name2="Approved"
            keys={keys}
          />
        </div>
      </div>
    </>
  );
};

CompareChartsByStatuses.defaultProps = {
  defaultKeys: {
    Approved: 'volumeApproved',
    Declined: 'volumeDeclined',
  },
  defaultValues: {
    Approved: 'percentApprovedByValue',
    Declined: 'percentDeclinedByValue',
  },
};

CompareChartsByStatuses.propTypes = {
  defaultKeys: PropTypes.shape({
    Approved: PropTypes.string,
    Declined: PropTypes.string,
  }),
  defaultValues: PropTypes.shape({
    Approved: PropTypes.string,
    Declined: PropTypes.string,
  }),
};

export default CompareChartsByStatuses;
