import userEndpoint from './userEndpoint';
import adminUserEndpoint from './adminUserEndpoint';
import authEndpoint from './authEndpoint';
import filesEndpoint from './filesEndpoint';
import accountsEndpoint from './accountsEndpoint';
import transactionsEndpoint from './transactionsEndpoint';
import beneficiariesEndpoint from './beneficiariesEndpoint';
import setupsEndpoint from './setupsEndpoint';
import listsEndpoint from './listsEndpoint';
import adminEndpoints from './admin';
import feesEndpoint from './feesEndpoint';
import feesChargesEndpoint from './feesChargesEndpoint';
import registrationEndpoint from './registrationEndpoint';
import customersEndpoint from './customersEndpoint';
import dashboardEndpoint from './dashboardEndpoint';

const {
  reportsEndpoint,
  UserActivityEndpoint,
  processingErrorsEndpoint,
  journalEndpoint,
} = adminEndpoints;

export default {
  lists: listsEndpoint('/'),
  accounts: accountsEndpoint('/accounts'),
  transactions: transactionsEndpoint('/transactions'),
  fees_charges: feesChargesEndpoint('/fees-charges'),
  beneficiaries: beneficiariesEndpoint('/beneficiaries'),
  files: filesEndpoint('/files'),
  exceptions: transactionsEndpoint('/exceptions'),
  user: userEndpoint('/user'),
  adminUser: adminUserEndpoint('/adminUser'),
  setups: setupsEndpoint('/setups'),
  auth: authEndpoint('/auth'),
  registration: registrationEndpoint(),

  UserActivity: UserActivityEndpoint('/invalid-transactions'),
  processingErrors: processingErrorsEndpoint('/processing-errors'),
  journal: journalEndpoint('/journal'),
  reports: reportsEndpoint('/reports'),
  fees: feesEndpoint(),
  users: userEndpoint('/users'),
  customers: customersEndpoint('/customers'),
  dashboard: dashboardEndpoint('/dashboard'),
};
