import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { withRouter } from 'react-router-dom';

import EditJournalModal from '../../components/Modals/EditJournalModal';

import modalActions from '../../actions/modal';
import journalActions from '../../actions/journal';
import { filterStatuses } from '../../helpers/status';
import {
  valuesForTransactionsStatus,
  valuesForTransactionsChargeType,
} from '../../schemes/templatesForLists';
import {
  journalFieldsForTransactions,
  journalFieldsForCharge,
  // journalValidationSchemaForCharge,
  // journalValidationSchemaForTransactions,
} from '../../schemes/forms/editJournal';
import { setFieldsToReadOnly } from '../../helpers/other';

const { closeModal, cleanModalData } = modalActions;
const { editOneJournal } = journalActions.journal;

const mapStateToProps = (state) => {
  const {
    ledgers, chargeFees, transactionFees, transactionTypes, transactionStatuses,
  } = state.get('lists')
    .toJS();
  const data = state.getIn(['journal', 'one', 'data']);
  const subRole = state.getIn(['userInfo', 'data', 'subRole']);
  const beneficiaryList = data.get('beneficiaryIdNameDictionary').toJS();
  const defaultLists = {
    accountId: ledgers.data,
    statusId: filterStatuses(valuesForTransactionsStatus, transactionStatuses.data),
  };
  return {
    subRole,
    isModalOpen: state.getIn(['modal', 'isModalOpen']),
    fields: {
      journalFieldsForTransactions: subRole === 'ReadOnlyAdmin'
        ? setFieldsToReadOnly(journalFieldsForTransactions)
        : journalFieldsForTransactions,
      journalFieldsForCharge: subRole === 'ReadOnlyAdmin'
        ? setFieldsToReadOnly(journalFieldsForCharge)
        : journalFieldsForCharge,
    },
    lists: {
      listsForTransactions: {
        ...defaultLists,
        feeId: transactionFees.data,
        beneficiaryId: beneficiaryList,
        type: transactionTypes.data,
      },
      listsForCharge: {
        ...defaultLists,
        feeId: chargeFees.data,
        type: filterStatuses(valuesForTransactionsChargeType, transactionTypes.data),
      },
    },
    transactionFees,
  };
};

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal()),
  cleanModalData: () => dispatch(cleanModalData()),
  onSubmit: payload => dispatch(editOneJournal.action(payload)),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(withRouter(withImmutablePropsToJS(EditJournalModal)));
