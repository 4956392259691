import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  PieChart, Pie, Sector, Cell, Legend, Tooltip
} from 'recharts';

import { COLORS } from '../../../helpers/other';

const convertNumToPercent = number => `${(number * 100).toFixed(0)}%`;


const RenderLegend = ({ payload }) => {

  return (
    <ul className="recharts-legend">
      {
        payload.map((entry, index) => (
          <li key={`item-${index}`} className="recharts-legend__item">
            <span className="recharts-legend__item__marker" style={{ backgroundColor: entry.color }}/>
            <span className="recharts-legend__item__value">
              {`${entry.value}: ${entry.payload.value}(${convertNumToPercent(entry.payload.percent)})`}
            </span>
          </li>
        ))
      }
    </ul>
  );
}

const RADIAN = Math.PI / 180;
const RenderCustomizedLabel = ({
  cx, cy, midAngle, innerRadius, outerRadius, percent, index, payload,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {convertNumToPercent(percent)}
    </text>
  );
};

const CustomPieChart = ({
  data,
}) => {

  return (
    <>
      <PieChart width={600} height={500}>
        <Pie
          data={data}
          cx={300}
          cy={200}
          labelLine={false}
          label={RenderCustomizedLabel}
          // label
          outerRadius={160}
          fill="#8884d8"
          dataKey="value"
        >
          {
            data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
          }
        </Pie>
        <Legend content={RenderLegend} />
      </PieChart>
    </>
  );
};

CustomPieChart.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.object,
  ).isRequired,
};

export default CustomPieChart;
