import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { debounce } from 'lodash';

import searchActions from '../../actions/search';


class Search extends Component {
  constructor(props) {
    super(props);
    this.handleSearch = debounce(this.handleSearch, 200);
  }

  handleSearch(event) {
    this.props.setSearch(event.target.value.toString().trim());
  }

  render() {
    return (
      <Form.Group className="search-form">
        <i className="iconfont-search search-icon"  />

        <label htmlFor="search-input" className="hide">
          search
        </label>

        <Form.Control
          type="text"
          id="search-input"
          placeholder="Search..."
          onChange={event => {
            event.persist();
            this.handleSearch(event);
          }}
        />
      </Form.Group>
    );
  }
}

// const Search = ({ setSearch }) => {
//   return (
//     <Form.Group className="search-form">
//       <img src={searchIcon} alt="" className="search-icon"/>
//
//       <Form.Control
//         type="text"
//         placeholder="Search..."
//         onChange={e => setSearch(e.target.value)}
//       />
//     </Form.Group>
//   );
// };

const mapDispatchToProps = dispatch => ({
  setSearch: searchText => dispatch(searchActions.search(searchText)),
});

export default connect(null, mapDispatchToProps)(Search);
