import React from 'react';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js/lib/index';
import AccountSetup from '../../../components/Pages/AccountSetup/AccountSetup';

const mapStateToProps = state => {
  return {

  };
};

const mapDispatchToProps = dispatch => {
  return {

  };
};


export default connect(mapStateToProps, mapDispatchToProps)(withImmutablePropsToJS(AccountSetup));
