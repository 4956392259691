import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';

import adminUserActions from '../../actions/adminUser';
import RangeCalendar from '../../components/Layouts/RangeCalendar';
import Search from '../../components/Layouts/Search';
import ExportData from '../../components/Layouts/ExportData';
import UniversalModalButton from '../Modals/UniversalModalButton';
import { periodForLongRequests, periodForLongRequestsForCalendar } from '../../helpers/dates';

const { getAdminUsers } = adminUserActions.adminUser;

const AdminUsersHeader = ({
  rangeCalendarAction, onExport, subRole, canExport,
}) => {
  useEffect(() => {
    rangeCalendarAction(periodForLongRequests);
  }, []);

  return (
    <>
      <div className="breadcrumbs-block">
        <div>Admin / Admin Users</div>
        <If condition={subRole === 'SuperAdmin'}>
          <UniversalModalButton
            modalId="create-admin-user"
            title="New Admin User"
            text="+"
            color="green"
            type="primary"
            size="sm"
          />
        </If>
      </div>

      <div className="data_tools">
        <RangeCalendar
          rangeCalendarAction={rangeCalendarAction}
          customDefaultDate={periodForLongRequestsForCalendar}
        />

        <Search />

        <ExportData onExport={onExport} canExport={canExport} />
      </div>
    </>
  );
};

AdminUsersHeader.propTypes = {
  rangeCalendarAction: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  subRole: PropTypes.string.isRequired,
  canExport: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  subRole: state.getIn(['userInfo', 'data', 'subRole']),
});

export default connect(mapStateToProps, {
  rangeCalendarAction: getAdminUsers.action,
})(withImmutablePropsToJS(AdminUsersHeader));
