import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';

import customersActions from '../../actions/customers';
import RangeCalendar from '../../components/Layouts/RangeCalendar';
import Search from '../../components/Layouts/Search';
import ExportData from '../../components/Layouts/ExportData';
import {
  periodForLongRequestsForCalendar,
  periodForLongRequests,
} from '../../constants/constants';

const { getCustomers } = customersActions.customers;

const CustomersHeader = ({
  rangeCalendarAction,
  onExport,
  canExport,
  datesForRequest,
  datesForCalendar,
}) => {
  useEffect(() => {
    rangeCalendarAction(datesForRequest);
  }, []);

  return (
    <>
      <div className="breadcrumbs-block">
        <div>Customers</div>
      </div>

      <div className="data_tools">
        <RangeCalendar
          rangeCalendarAction={rangeCalendarAction}
          customDefaultDate={datesForCalendar}
        />

        <Search />

        <ExportData onExport={onExport} canExport={canExport} />
      </div>
    </>
  );
};

CustomersHeader.propTypes = {
  rangeCalendarAction: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  datesForRequest: PropTypes.shape({
    dateFromFormatted: PropTypes.string.isRequired,
    dateToFormatted: PropTypes.string.isRequired,
  }).isRequired,
  datesForCalendar: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date).isRequired,
    endDate: PropTypes.instanceOf(Date).isRequired,
  }).isRequired,
  canExport: PropTypes.bool.isRequired,
};

const mapStateToProps = () => ({
  datesForRequest: periodForLongRequests(),
  datesForCalendar: periodForLongRequestsForCalendar(),
});

export default connect(mapStateToProps, {
  rangeCalendarAction: getCustomers.action,
})(withImmutablePropsToJS(CustomersHeader));
