import { combineActions, handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import { combineReducers } from 'redux-immutable';
import { pick } from 'lodash';

import dashboardActions from '../actions/dashboard';
import userActions from '../actions/user/userActions';
import listsActions from '../actions/lists/listsActions';
import { defaultDateRange } from '../constants/constants';

const { logout } = userActions;
const { datesFromPreviousMonth } = defaultDateRange;

const {
  getChartUsersByStatus,
  getChartByDay,
  getTransactionsCurrenciesCompare,
  changeAmountType,
  clearTransactionsDashboard,
  getTransactionsByStatusByCurrency,
  getTransactionsByStatusByType,
} = dashboardActions.dashboard;

const {
  getCurrencies,
  getLedgers,
} = listsActions;

const initialState = fromJS({
  options: {
    ledgers: {},
    currencies: {},
    ledgerId: null,
    enduserId: null,
    amountType: 'incoming',
    currencyId: 1,
    fetching: false,
    error: false,
    ...datesFromPreviousMonth,
  },
  applications: {
    data: {
      allUserCount: 0,
      countList: [],
    },
    fetching: false,
    error: false,
  },
  reportsByDays: {
    data: {
      incoming: [],
      outgoing: [],
      charge: [],
    },
    fetching: false,
    error: false,
  },
  transactionsCurrenciesCompare: {
    data: {
      incoming: [],
      outgoing: [],
      charge: [],
    },
    fetching: false,
    error: false,
  },
  selectionsByStatusByTypes: {
    data: {
      incoming: [],
      outgoing: [],
      charge: [],
    },
    fetching: false,
    error: false,
  },
  selectionsByStatusByCurrency: {
    data: {
      incoming: [],
      outgoing: [],
      charge: [],
    },
    fetching: false,
    error: false,
  },
});

const applications = handleActions(
  {
    [getChartUsersByStatus.fetching]: state => state
      .set('fetching', true)
      .set('error', false),
    [combineActions(
      getChartUsersByStatus.failure,
      getChartUsersByStatus.error,
    )]: state => state
      .set('fetching', false)
      .set('error', true),
    [getChartUsersByStatus.success]: (state, { payload }) => state
      .set('data', payload)
      .set('error', false)
      .set('fetching', false),
    [combineActions(
      logout, clearTransactionsDashboard,
    )]: () => initialState.get('applications'),
  },
  initialState.get('applications'),
);

const transactionsWithReportsByDays = handleActions(
  {
    [getChartByDay.fetching]: state => state
      .set('fetching', true)
      .set('error', false),
    [combineActions(
      getChartByDay.failure,
      getChartByDay.error,
    )]: state => state
      .set('fetching', false)
      .set('error', true),
    [getChartByDay.success]: (state, { payload }) => state
      .set('data', pick(payload, ['incoming', 'outgoing', 'charge']))
      .set('error', false)
      .set('fetching', false),
    [combineActions(logout, clearTransactionsDashboard)]: () => initialState.get('reportsByDays'),
  },
  initialState.get('reportsByDays'),
);

const transactionsCurrenciesCompareReport = handleActions(
  {
    [getTransactionsCurrenciesCompare.fetching]: state => state
      .set('fetching', true)
      .set('error', false),
    [combineActions(
      getTransactionsCurrenciesCompare.failure,
      getTransactionsCurrenciesCompare.error,
    )]: state => state
      .set('fetching', false)
      .set('error', true),
    [getTransactionsCurrenciesCompare.success]: (state, { payload }) => state
      .set('data', pick(payload, ['incoming', 'outgoing', 'charge', 'maxOutgoing', 'maxCharge', 'maxIncomingValues']))
      .set('error', false)
      .set('fetching', false),
    [combineActions(
      logout,
      clearTransactionsDashboard,
    )]: () => initialState.get('transactionsCurrenciesCompare'),
  },
  initialState.get('transactionsCurrenciesCompare'),
);

const transactionsWithSelectionsByStatusByTypes = handleActions({
  [getTransactionsByStatusByType.fetching]: (state, { payload }) => state
    .set('requestId', payload.id)
    .set('fetching', true)
    .set('error', false),
  [combineActions(
    getTransactionsByStatusByType.failure,
    getTransactionsByStatusByType.error,
  )]: state => state
    .set('fetching', false)
    .set('error', true),
  [getTransactionsByStatusByType.success]: (state, { payload }) => state.set('data', payload).set('fetching', false),
  [logout]: () => initialState.get('selectionsByStatusByTypes'),
},
initialState.get('selectionsByStatusByTypes'));

const dashboardReportOptions = handleActions({
  [combineActions(
    getChartByDay.fetching,
  )]: state => state.set('fetching', true),
  [combineActions(
    getChartByDay.error,
  )]: state => state.set('fetching', false),
  [combineActions(
    getChartByDay.success,
  )]: (state, { payload: { currencyId, enduserId, ledgerId } }) => state.set('fetching', false)
    .set('currencyId', currencyId).set('enduserId', enduserId).set('ledgerId', ledgerId),
  [getCurrencies.success]: (state, { payload }) => state.set('currencies', payload),
  [getLedgers.success]: (state, { payload }) => state.set('ledgers', payload),
  [changeAmountType]: (state, { payload }) => state.set('amountType', payload),
  [combineActions(logout, clearTransactionsDashboard)]: () => initialState.get('options'),
},
initialState.get('options'));

const transactionsWithSelectionsByStatusByCurrency = handleActions(
  {
    [getTransactionsByStatusByCurrency.fetching]: (state, { payload }) => state
      .set('requestId', payload.id)
      .set('fetching', true)
      .set('error', false),
    [combineActions(
      getTransactionsByStatusByCurrency.failure,
      getTransactionsByStatusByCurrency.error,
    )]: state => state
      .set('fetching', false)
      .set('error', true),
    [getTransactionsByStatusByCurrency.success]: (state, { payload }) => state.set('data', payload).set('fetching', false),
    [logout]: () => initialState.get('selectionsByStatusByCurrency'),
  },
  initialState.get('selectionsByStatusByCurrency'),
);


export default combineReducers({
  applications,
  options: dashboardReportOptions,
  reportsByDays: transactionsWithReportsByDays,
  transactionsCurrenciesCompare: transactionsCurrenciesCompareReport,
  selectionsByStatusByTypes: transactionsWithSelectionsByStatusByTypes,
  selectionsByStatusByCurrency: transactionsWithSelectionsByStatusByCurrency,
});
