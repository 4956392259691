import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { isEmpty } from 'lodash';

import InputDropdown from '../../../generic/InputDropdown';
import BiaxialLineChart from '../../../generic/charts/BiaxialLineChart';
import EmptyValidation from '../../../HOC/EmptyValidation';
import dashboardActions from '../../../../actions/dashboard';
import { COLORS } from '../../../../helpers/other';

const {
  getChartByDay,
} = dashboardActions.dashboard;

const ReportsByDayChart = ({
  items, options: { currencyId, currencies },
  options,
  fetchGetData,
}) => {
  const [selectedCurrencyId, setSelectedCurrencyId] = useState(currencyId);

  useEffect(() => {
    if (selectedCurrencyId !== currencyId) {
      fetchGetData({
        ...options,
        currencyId: selectedCurrencyId,
      });
    }
  }, [selectedCurrencyId]);

  useEffect(() => {
    setSelectedCurrencyId(currencyId);
  }, [currencyId]);

  const colors = {
    left: COLORS.volume,
    right: COLORS.count,
  };

  return (
    <span>
      <div className="flexible-block__header">
        <h3 className="flexible-block__title">Transactions report by currency</h3>
        <div className="currencies-select">
          <span className="currencies-select__title">Transactions currency:</span>
          <InputDropdown
            onChange={id => setSelectedCurrencyId(id)}
            items={currencies}
            placeholder="Select currency"
            selected={selectedCurrencyId.toString()}
          />
        </div>
      </div>
      <EmptyValidation
        isNotEmpty={!isEmpty(items)}
        whenIsEmptyContent="There is no transactions."
      >
        <BiaxialLineChart
          dataKeyLeft="volume"
          dataKeyRight="count"
          firstLineName="volume"
          secondLineName="count"
          items={items}
          strokeColor={colors}
        />
      </EmptyValidation>
    </span>
  );
};

ReportsByDayChart.defaultProps = {};

ReportsByDayChart.propTypes = {
  options: PropTypes.shape({
    currencyId: PropTypes.number.isRequired,
    currencies: PropTypes.object.isRequired,
  }).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.object,
  ).isRequired,
  fetchGetData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const options = state.getIn(['dashboard', 'options'])
    .toJS();
  const { amountType } = options;
  const transactionsWithDayReport = state.getIn(['dashboard', 'reportsByDays', 'data', amountType]);

  return {
    items: transactionsWithDayReport,
    options,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchGetData: data => dispatch(getChartByDay.action(data)),
});

export default connect(mapStateToProps,
  mapDispatchToProps)(withImmutablePropsToJS(ReportsByDayChart));
