import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import listsActions from '../../../actions/lists';
import withLists from '../../../components/HOC/withLists';
import MessagesList from '../../../components/Pages/Messages/ChatList';

const {
  getLedgerStatuses,
} = listsActions.lists;

const mapStateToProps = (state) => {
  const currentState = state.getIn(['messages', 'list']);
  const activeChatId = state.getIn(['messages', 'one', 'data', 'chatId']);
  const userRole = state.getIn(['userInfo', 'data', 'role']);

  return {
    chatList: currentState.get('chatList'),
    activeChatId,
    isAdmin: userRole === 'Admin',
    // requiredLists: [
    //   {
    //     data: [],
    //     action: getLedgerStatuses.action,
    //   },
    // ],
  };
};

export default connect(mapStateToProps, null)(withLists(withImmutablePropsToJS(MessagesList)));
