import moment from 'moment';

import { defaultDateRange } from '../constants/constants';

const { dateFromPreviousThreeYearsFormatted, dateToFormatted } = defaultDateRange;


export const convertDateToLocaleFormat = (cell, outFormat = 'DD.MM.YYYY; h:mm:ss A') => (
  cell
    ? moment.utc(cell).local().format(outFormat)
    : ''
);

export const convertLocaleDateToUTCFormat = (momentTime, outFormat = 'MM.DD.YYYY HH:mm:ss') => {
  return moment.utc(moment(momentTime)).format(outFormat);
};

export const periodForLongRequests = {
  dateToFormatted,
  dateFromFormatted: dateFromPreviousThreeYearsFormatted,
};

export const periodForLongRequestsForCalendar = {
  startDate: new Date(dateFromPreviousThreeYearsFormatted),
  endDate: new Date(dateToFormatted),
};

export const subtractDateFromCurrent = years => new Date(moment()
  .subtract(years, 'years')
  .endOf('day')
  .format());

export const verificationIsAdult = date => moment(date).isBefore(subtractDateFromCurrent(18));

export const dayAndMonthFormatter = date => moment(date).format('D MMM');
