import React, { memo, useCallback } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import AnimatedSpinner from '../Spinners/AnimatedSpinner';
import InputDropdown from './InputDropdown';
import InputStandart from './InputStandart';
import InputDatePicker from './InputDatePicker';
import InputToggle from './InputToggle';
import InputDuallist from './InputDuallist';
import InputCurrency from './InputCurrency';
import InputCheckbox from './InputCheckbox';
import InputFile from './InputFile';
import GeoInput from './GeoInput';
import CustomToggle from './CustomToggle';
import Tooltip from './Tooltip';
import InputPhoneNumber from './InputPhoneNumber';
import InputPassword from './InputPassword';

const InputsWrapper = (props) => {
  const {
    id,
    label,
    value,
    values,
    name,
    handleChange,
    setValues,
    handleBlur,
    isOnBlur,
    onBlurPost,
    type,
    withLabel,
    dropdownList,
    additionalHandler,
    gridArea,
    // TODO: rename hide to isHide (because this is a bool);
    // TODO: rename isOnBlur to function name (because this is a function);
    hide,
    labelClassName,
    showingContentClass,
    fetching,
    ...rest
  } = props;

  const key = name;
  const isSelectFromKey = type === 'select';
  const isDate = type === 'date';
  const isToggle = type === 'toggle';
  const isCustomToggle = type === 'custom_toggle';
  const isCheckbox = type === 'checkbox';
  const isDualList = type === 'dualList';
  const isCurrency = type === 'currency';
  const isPercent = type === 'percent';
  const isFile = type === 'file';
  const isGeo = type === 'geo';
  const isPhoneNumber = type === 'phone-number';
  const isPassword = type === 'password';

  const handleChangeValidation = useCallback((currentValue) => {
    if (additionalHandler) {
      additionalHandler({ name, value: currentValue });
    }
    handleChange({ target: { name, value: currentValue } });
  }, [additionalHandler, name]);

  const onBlur = (e) => {
    handleBlur(e);
  };

  return (
    <If condition={!hide}>
      <div
        className={classNames('basic-form__group', type, name, { required: rest.required })}
        style={{ gridArea }}
      >
        <If condition={withLabel && label}>
          <label
            htmlFor={id}
            className={`basic-form__group__label ${labelClassName && labelClassName}`}
          >
            {`${label}:`}
          </label>
        </If>
        <AnimatedSpinner fetching={fetching} />
        <div
          className={classNames('basic-form__group__block', fetching ? 'content--hide-without-transition' : 'content--show',
            { infobox: rest.infoDescription })}
          data-tip={id}
          data-for={id}
        >
          <Choose>
            <When condition={isPhoneNumber}>
              <InputPhoneNumber
                key={key}
                name={name}
                selected={value.toString()}
                onChange={handleChangeValidation}
                onBlurPost={onBlurPost}
                {...rest}
              />
            </When>
            <When condition={isSelectFromKey}>
              <InputDropdown
                key={key}
                name={name}
                selected={value.toString()}
                items={dropdownList || {}}
                onChange={handleChangeValidation}
                onBlurPost={onBlurPost}
                {...rest}
              />
            </When>
            <When condition={isDate}>
              <InputDatePicker
                key={key}
                name={name}
                value={value}
                items={dropdownList}
                onChange={handleChangeValidation}
                onBlur={onBlur}
                isField
                showMonthDropdown
                showYearDropdown
                onBlurPost={onBlurPost}
                {...rest}
              />
            </When>
            <When condition={isToggle}>
              <InputToggle
                key={key}
                checked={value}
                onChange={handleChangeValidation}
                size="sm"
              />
            </When>
            <When condition={isCustomToggle}>
              <CustomToggle
                key={key}
                checked={value}
                onChange={handleChangeValidation}
                id={id}
                {...rest}
              />
            </When>
            <When condition={isCheckbox}>
              <InputCheckbox
                key={key}
                onChange={handleChangeValidation}
                type={type}
                id={id}
                name={name}
                value={value}
                onBlur={onBlur}
                {...rest}
              />
            </When>
            <When condition={isDualList}>
              <InputDuallist
                onChange={handleChangeValidation}
                value={value}
                items={dropdownList}
                {...rest}
              />
            </When>
            <When condition={isCurrency}>
              <InputCurrency
                key={key}
                name={name}
                value={value}
                items={dropdownList}
                onChange={handleChangeValidation}
                id={id}
                // onBlur={onBlur}
                onBlurPost={onBlurPost}
                allowNegative
                decimalLimit={2}
                integerLimit={11}
                {...rest}
              />
            </When>
            <When condition={isPercent}>
              <InputCurrency
                key={key}
                name={name}
                value={value}
                items={dropdownList}
                onChange={handleChangeValidation}
                id={id}
                // onBlur={onBlur}
                onBlurPost={onBlurPost}
                allowNegative={false}
                decimalLimit={4}
                integerLimit={2}
                {...rest}
              />
            </When>
            <When condition={isPassword}>
              <InputPassword
                key={key}
                name={name}
                value={value}
                onChange={handleChangeValidation}
                id={id}
                {...rest}
              />
            </When>
            <When condition={isFile}>
              <InputFile
                value={value}
                id={id}
                name={name}
                onChange={handleChangeValidation}
                label={label}
                {...rest}
              />
            </When>
            <When condition={isGeo}>
              <GeoInput
                id={id}
                name={name}
                onChange={handleChangeValidation}
                value={value}
                values={values}
                handleChange={handleChange}
                additionalHandler={additionalHandler}
                setValues={setValues}
                onBlurPost={onBlurPost}
                isOnBlur={isOnBlur}
                {...rest}
              />
            </When>
            <Otherwise>
              <InputStandart
                type={type}
                id={id}
                name={name}
                value={value}
                onChange={handleChangeValidation}
                // onBlur={onBlur}
                onBlurPost={onBlurPost}
                {...rest}
              />
            </Otherwise>
          </Choose>

          <div className={`basic-form__group__block__invalid-feedback ${rest.error ? 'show' : ''}`}>
            {rest.error}
          </div>

          <If condition={rest.infoDescription}>
            <i
              className="iconfont-infobox basic-form__group__block__infobox"
              data-tip={`info-description-${id}`}
              data-for={`info-description-${id}`}
            />

            <Tooltip id={`info-description-${id}`} text={rest.infoDescription} />
          </If>
        </div>
      </div>
    </If>
  );
};

InputsWrapper.defaultProps = {
  id: '',
  value: '',
  error: '',
  placeholder: '',
  handleChange: null,
  handleBlur: null,
  onBlurPost: null,
  readOnly: false,
  disabled: false,
  type: 'text',
  multiple: false,
  withLabel: false,
  dropdownList: null,
  additionalHandler: null,
  autoComplete: '', // on
  gridArea: '',
  infoDescription: null,
  fetching: false,
};

InputsWrapper.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.bool,
    PropTypes.array,
  ]),
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  onBlurPost: PropTypes.func,
  additionalHandler: PropTypes.func,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  fetching: PropTypes.bool,
  type: PropTypes.string,
  multiple: PropTypes.bool,
  withLabel: PropTypes.bool,
  dropdownList: PropTypes.any,
  autoComplete: PropTypes.string,
  gridArea: PropTypes.string,
  infoDescription: PropTypes.string,
};

// const mapStateToProps = state => {
//   const currentState = state.getIn(['fetchingList', 'one']);
//
//   return {
//     fetching: false,
//   };
// };

// export default connect(mapStateToProps, null)(memo(InputsWrapper));
export default memo(InputsWrapper);
