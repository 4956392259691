import { forEach, isObject, isDate } from 'lodash';

export const prepareFormData = (values) => {
  const formData = new FormData();
  forEach(values, (value, key) => {
    formData.append(key, value);
  });
  return formData;
};

export const prepareFormDataWithFile = (values, additionalData) => {
  let valuesData = {};

  forEach(values, (value, key) => {
    if (isDate(value)) {
      valuesData = { ...valuesData, ...{ [key]: value.toISOString() } };
    } else valuesData = { ...valuesData, ...{ [key]: value } };
  });

  valuesData = {
    ...valuesData,
    ...additionalData,
  };

  return prepareFormData(valuesData);
};
