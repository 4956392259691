import React, { memo } from 'react';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { NavLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import AnimatedSpinner from '../../components/Spinners/AnimatedSpinner';
import accountsActions from '../../actions/accounts';
import Search from '../../components/Layouts/Search';
import ExportData from '../../components/Layouts/ExportData';
import UniversalModalButton from '../Modals/UniversalModalButton';
import { currencyFormatter } from '../../helpers/other';
import Button from '../../components/generic/Button';
import ReadOnlyPermission from '../../components/HOC/ReadOnlyPermissionHOC';
import { tooltipCantAddFee, tooltipCantCreateAdjustment } from '../../helpers/notificatios';
import Tooltip from '../../components/generic/Tooltip';

const { getOneAccount } = accountsActions.accounts;

const JournalOfSelectedAccountHeader = ({
  currency,
  ledgerName,
  onExport,
  fetching,
  userRole,
  subRole,
  balance,
  canExport,
  balanceFetching,
  hasMonthlyStatement,
  firstName,
  lastName,
  match: { params: { id } },
}) => (
  <>
    <div className="breadcrumbs-block journal-of-account">
      <div className="journal-of-account__user-info">
        <div className="spinner-block">
          <NavLink to="/accounts">
            Accounts
          </NavLink>
          <AnimatedSpinner fetching={fetching} />
          <If condition={!fetching}>
            {` / Journal - ${ledgerName} (${currency})`}
          </If>
        </div>
        <div className="balance spinner-block">
          Account balance:
          <AnimatedSpinner fetching={balanceFetching} />
          <If condition={!balanceFetching}>
            { ` ${currencyFormatter(balance)}` }
          </If>
        </div>
      </div>
      <div className="journal-of-account__buttons spinner-block">
        <If condition={userRole === 'Admin'}>
          <ReadOnlyPermission
            subRole={subRole}
            tooltipId="adjustment"
            key="adjustment"
            declinePermissionText={tooltipCantCreateAdjustment}
            readOnlyComponent={(
              <span
                data-tip="adjustment"
                data-for="adjustment"
              >
                <Button
                  type="secondary"
                  text="Adjustment"
                  color="green"
                  size="sm"
                  disabled
                  className="disabled"
                />
              </span>
            )}
          >
            <UniversalModalButton
              modalId="create-adjustment"
              text="Adjustment"
              color="green"
              type="primary"
              size="sm"
              chosenItemId={id}
            />
          </ReadOnlyPermission>
          <ReadOnlyPermission
            subRole={subRole}
            tooltipId="fees"
            key="fees"
            declinePermissionText={tooltipCantAddFee}
            readOnlyComponent={(
              <span
                data-tip="fees"
                data-for="fees"
              >
                <Button
                  type="secondary"
                  text="Fees"
                  color="green"
                  size="sm"
                  disabled
                  className="disabled"
                />
              </span>
            )}
          >
            <UniversalModalButton
              modalId="create-fee"
              text="Fees"
              color="green"
              type="primary"
              size="sm"
              chosenItemId={id}
            />
          </ReadOnlyPermission>
        </If>
        <AnimatedSpinner fetching={fetching} />
        <If condition={!fetching}>
          <Choose>
            <When condition={hasMonthlyStatement}>
              <UniversalModalButton
                modalId="monthly-statement"
                text="Monthly statements"
                color="green"
                type="primary"
                size="sm"
                chosenItemId={id}
              />
            </When>
            <Otherwise>
              <span
                data-tip="monthly-statements"
                data-for="monthly-statements"
              >
                <Button
                  type="secondary"
                  text="Monthly statements"
                  color="green"
                  size="sm"
                  disabled
                  className="disabled"
                />
              </span>
              <Tooltip
                id="monthly-statements"
                text="There is no transactions for monthly statements"
              />
            </Otherwise>
          </Choose>
        </If>
      </div>
    </div>
    <div className="data_tools">
      <Search />
      <ExportData
        onExport={onExport}
        canExport={canExport}
        fileHeaderText={`Wirebloom - generated for ${firstName} ${lastName} - Account: ${ledgerName}`}
        fileNameText={`Wirebloom - Account - ${ledgerName}`}
      />
    </div>
  </>
);

JournalOfSelectedAccountHeader.defaultProps = {
  ledgerName: '',
  currency: '',
  onExport: () => {
  },
};

JournalOfSelectedAccountHeader.propTypes = {
  ledgerName: PropTypes.string,
  onExport: PropTypes.func,
  currency: PropTypes.string,
  fetching: PropTypes.bool.isRequired,
  hasMonthlyStatement: PropTypes.bool.isRequired,
  balanceFetching: PropTypes.bool.isRequired,
  userRole: PropTypes.string.isRequired,
  subRole: PropTypes.string.isRequired,
  balance: PropTypes.number.isRequired,
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,
  canExport: PropTypes.bool.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const userRole = state.getIn(['userInfo', 'data', 'role']);
  const subRole = state.getIn(['userInfo', 'data', 'subRole']);
  const journalAccount = state.getIn(['accounts', 'journalsOfAccount']).toJS();

  const {
    currency,
    ledgerName,
    balance,
    balanceFetching,
    hasMonthlyStatement,
  } = journalAccount;

  return {
    currency,
    ledgerName,
    userRole,
    subRole,
    balance,
    balanceFetching,
    hasMonthlyStatement,
    firstName: state.getIn(['userInfo', 'data', 'firstName']),
    lastName: state.getIn(['userInfo', 'data', 'lastName']),
  };
};

export default connect(mapStateToProps, {
  rangeCalendarAction: getOneAccount.action,
})(withImmutablePropsToJS(withRouter(memo(JournalOfSelectedAccountHeader))));
