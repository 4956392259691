import React, { createRef, useCallback, memo, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import { isDate } from 'lodash';

import { verificationIsAdult } from '../../helpers/dates';

const InputDatePicker = (props) => {
  const {
    onChange,
    value,
    range,
    isField,
    onBlurPost,
    name,
    readOnly,
    mustBeAdult,
    ...rest
  } = props;

  const datePicker = createRef();

  // TODO: check date implementing
  const date = useMemo(
    () => isDate(new Date(value)) ? new Date(value) : new Date(),
    [value],
  );

  const selectedDate = useMemo(
    () => (!mustBeAdult || verificationIsAdult(date) ? date : null),
    [date],
  );

  const onChangeValue = useCallback((e) => {
    onChange(e);
    if (!onBlurPost) {
      return;
    }

    onBlurPost({
      name,
      value: e,
    });
  }, [name]);

  return (
    <div className={`input-date-picker ${readOnly && 'read-only'}`}>
      <DatePicker
        ref={datePicker}
        dateFormat="d/MMMM/yyyy"
        className={`transparent_picker ${range || ''}`}
        selected={selectedDate}
        showYearDropdown
        dropdownMode="select"
        onChange={onChangeValue}
        readOnly={readOnly}
        {...rest}
      />

      <If condition={isField}>
        <i
          onClick={() => datePicker.current.setOpen(true)}
          className="iconfont-calendar calendar clickable"
        />
      </If>
    </div>
  );
};

InputDatePicker.defaultProps = {
  isField: false,
  mustBeAdult: false,
  onBlurPost: null,
  readOnly: false,
};

InputDatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
  isField: PropTypes.bool,
  mustBeAdult: PropTypes.bool,
  onBlurPost: PropTypes.func,
  name: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  // range: PropTypes.bool,
};

export default memo(InputDatePicker);
