import * as yup from 'yup';

import { latinFieldWithNumbersAndPunctuationMarks } from '../../helpers/fieldsValidation';
import { setFieldsToEdit } from '../../helpers/other';

export const userFields = [
  {
    id: 'email',
    name: 'email',
    label: 'Email',
    placeholder: '',
    type: 'text',
  },
  {
    id: 'phoneNumber',
    name: 'phoneNumber',
    label: 'Phone number',
    placeholder: '',
    type: 'phone-number',
  },
  {
    id: 'firstName',
    name: 'firstName',
    label: 'First Name',
    placeholder: '',
    type: 'text',
  },
  {
    id: 'lastName',
    name: 'lastName',
    label: 'Last Name',
    placeholder: '',
    type: 'text',
  },
];

const isReadOnlyField = {
  id: 'isReadOnly',
  name: 'isReadOnly',
  label: 'Read Only',
  type: 'checkbox',
};

export const createAdminUserFields = setFieldsToEdit([
  ...userFields,
  {
    id: 'password',
    name: 'password',
    label: 'Password',
    placeholder: '',
    type: 'password',
  },
  isReadOnlyField,
], [],
['isReadOnly']);

export const createUserFields = (userRole) => {
  if (userRole === 'Admin') {
    return setFieldsToEdit(
      [
        ...userFields,
        {
          id: 'enduserId',
          name: 'enduserId',
          label: 'Customer',
          placeholder: 'Select Customer',
          type: 'select',
        },
        isReadOnlyField,
      ],
      [],
      ['phoneNumber', 'firstName', 'lastName', 'isReadOnly'],
    );
  }
  return setFieldsToEdit(
    [
      ...userFields,
      isReadOnlyField,
    ],
    [],
    ['phoneNumber', 'firstName', 'lastName', 'isReadOnly'],
  );
};

export const adminUserValidationSchema = yup.object({
  email: yup.string().required('Required!'),
  phoneNumber: yup.string().required('Required'),
  firstName: latinFieldWithNumbersAndPunctuationMarks().trim().required('Required!'),
  lastName: latinFieldWithNumbersAndPunctuationMarks().trim().required('Required!'),
  password: yup.string().required('Required!'),
  isReadOnly: yup.boolean(),
});

export const userValidationSchema = (userRole) => {
  const validationSchema = {
    email: yup.string().required('Required!'),
    phoneNumber: yup.string(),
    firstName: latinFieldWithNumbersAndPunctuationMarks().trim(),
    lastName: latinFieldWithNumbersAndPunctuationMarks().trim(),
    isReadOnly: yup.boolean(),
  };

  if (userRole === 'Admin') {
    return yup.object({
      ...validationSchema,
      enduserId: yup.string().required('Required!'),
    });
  }

  return yup.object(validationSchema);
};
