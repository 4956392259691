import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import userActions from '../../actions/user';
import CustomerStatusHelper from '../../lib/statusHelper/CustomerStatusHelper';
import PendingFormatter from '../../lib/statusFormatters/PendingFormatter';


// TODO: make one formatter with users and customers
const { changeUserStatus } = userActions.user;

const mapStateToProps = state => ({
  requestData: state.getIn(['users', 'withChangedStatus']),
  component: CustomerStatusHelper,
  userRole: state.getIn(['userInfo', 'data', 'role']),
  subRole: state.getIn(['userInfo', 'data', 'subRole']),
});

const mapDispatchToProps = dispatch => ({
  processAction: payload => dispatch(changeUserStatus.action(payload)),
});

export default connect(mapStateToProps,
  mapDispatchToProps)(withImmutablePropsToJS(PendingFormatter));
