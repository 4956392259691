import React, {
  memo, useState, useEffect, useRef,
} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const InputFile = ({
  error, id, name, readOnly, disabled, onChange, value,
}) => {
  const [fileName, setFileName] = useState(null);
  const [status, setStatus] = useState('');

  useEffect(() => {
    if (error) setStatus('error');
  }, [error]);

  const fileInput = useRef();

  const handleUpload = () => {
    const { files, value } = fileInput.current;

    if (value) {
      const file = files[0];

      if (file) {
        setFileName(file.name);
        setStatus('upload');
        onChange(file);
      }
    } else {
      // cancel exception, clearFile can be called
      // clearFile()
    }
  };

  const clearFile = (e) => {
    if (e) {
      e.preventDefault();
    }

    onChange(null);
    setFileName(null);
    setStatus('');
    fileInput.current.value = null;
  };

  useEffect(() => {
    if (!value) clearFile();
  }, [value]);

  return (
    <div className="custom_input_file">
      <label className={classNames('custom_input_file_label', status)} htmlFor={id}>
        <i className="iconfont-download download" />

        <span className="file_name">{fileName || 'Choose a file…'}</span>

        <If condition={!!fileName}>
          <i className="iconfont-close remove_file" onClick={clearFile} />
        </If>
      </label>

      <input
        className={classNames('basic-form__group__block__control input file', { error: !!error })}
        type="file"
        ref={fileInput}
        id={id}
        name={name}
        onChange={handleUpload}
        readOnly={readOnly}
        disabled={disabled}
      />
    </div>
  );
};
export default memo(InputFile);

InputFile.defaultProps = {
  error: '',
  readOnly: false,
  disabled: false,
  value: null,
};

InputFile.propTypes = {
  error: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.instanceOf(File),
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};
