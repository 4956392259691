import { makeSecureRequest } from './apiCallHandlers/index';

export default route => ({
  getBeneficiaries: (params = {}) => {
    return makeSecureRequest('get', 'Beneficiary', null, params);
  },
  getOneBeneficiary: (id = null) => makeSecureRequest('get', `Beneficiary/${id}`),
  createBeneficiary: ({ payload }) => makeSecureRequest('post', 'Beneficiary', payload),
  createBeneficiaryOnPlatform: transactionId => makeSecureRequest('post', `Beneficiary/CreateOnPlatform/${transactionId}`),
  editBeneficiary: ({ values, id }) => makeSecureRequest('put', `Beneficiary/${id}`, values),
  deleteBeneficiary: ({ id }) => {
    return makeSecureRequest('delete', `Beneficiary/${id}`);
  },
  setChangeBeneficiaryStatus: ({
    statusId = 8, // Canceled
    id,
    comment = null,
  }) => {
    return makeSecureRequest('put', `Beneficiary/${id}/SetStatus/${statusId}`, { value: comment || ' ' });
  },
});
