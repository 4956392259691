import React from 'react';
import CustomRouteWrapper from '../../router/CostomRouteWrapper';
import loginRoutes from '../../router/routerItems/loginRoutes';

const AuthLayout = () => {
  return (
    <div className="login-layout">
      <CustomRouteWrapper
        routes={loginRoutes}
        redirect="/auth/login"
        exact
      />
    </div>
  );
};

export default AuthLayout;
