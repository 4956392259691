import {
  dateFormatter,
  defaultFormatter,
  priceFormatter,
  sortHeaderFormatter,
} from '../../tableUtils/tablesUtils';

// import sortHeaderFormatter from '../../tableUtils/sortHeaderFormatter';
import { convertDateToLocaleFormat } from '../../../helpers/dates';
import { currencyFormatter } from '../../../helpers/other';

export const transactionsERPReportColumns = [
  {
    dataField: 'transactionDate',
    text: 'Transaction Date',
    formatter: (cell, { id }) => dateFormatter(cell, id),
    headerFormatter: sortHeaderFormatter,
    transformationItem: cell => convertDateToLocaleFormat(cell, 'MM/DD/YYYY'),
    headerStyle: () => ({ width: '8%' }),
    sort: true,
    isUpperCase: false,
    wpx: 180,
  },
  {
    dataField: 'customerName',
    text: 'Customer Name',
    formatter: defaultFormatter,
    headerFormatter: sortHeaderFormatter,
    isUpperCase: false,
    wpx: 130,
  },
  {
    dataField: 'iban',
    text: 'Iban',
    formatter: defaultFormatter,
    headerFormatter: sortHeaderFormatter,
	headerStyle: () => ({ width: '12%' }),
    isUpperCase: false,
    wpx: 80,
  },
  {
    dataField: 'currency',
    text: 'Currency',
    formatter: defaultFormatter,
    headerFormatter: sortHeaderFormatter,
    isUpperCase: false,
	headerStyle: () => ({ width: '6%' }),
    wpx: 50,
  },
  {
    dataField: 'customerErpId',
    text: 'Customer ID',
    formatter: defaultFormatter,
    headerFormatter: sortHeaderFormatter,
    sort: true,
    isUpperCase: false,
	headerStyle: () => ({ width: '8%' }),
    wpx: 130,
  },
  {
    dataField: 'inventoryId',
    text: 'Inventory ID',
    formatter: defaultFormatter,
    headerFormatter: sortHeaderFormatter,
    sort: true,
    isUpperCase: false,
    wpx: 180,
  },
  {
    dataField: 'feeName',
    text: 'Inventory Detail',
    formatter: defaultFormatter,
    headerFormatter: sortHeaderFormatter,
    isUpperCase: false,
    wpx: 180,
  },
  {
    dataField: 'displayTransactionId',
    text: 'Transaction ID',
    formatter: defaultFormatter,
    headerFormatter: sortHeaderFormatter,
    sort: true,
    isUpperCase: false,
    wpx: 150,
  },
  {
    dataField: 'amount',
    text: 'Amount',
    formatter: priceFormatter,
    headerFormatter: sortHeaderFormatter,
    transformationItem: currencyFormatter,
    headerStyle: () => ({ width: '7%' }),
    isUpperCase: false,
    wpx: 100,
  },  
  {
    dataField: 'transactionType',
    text: 'Transaction Type',
    formatter: defaultFormatter,
    headerFormatter: sortHeaderFormatter,
    transformationItem: currencyFormatter,
    headerStyle: () => ({ width: '7%' }),
    isUpperCase: false,
    wpx: 130,
  },  
  {
    dataField: 'transactionAmount',
    text: 'Transaction Amount',
    formatter: priceFormatter,
    headerFormatter: sortHeaderFormatter,
    transformationItem: currencyFormatter,
    headerStyle: () => ({ width: '7%' }),
    isUpperCase: false,
    wpx: 130,
  },
];
