import React, { memo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import DropZone from '../generic/DropZone';

import { fileValidationForRegistration } from '../../helpers/fieldsValidation';
import progressActions from '../../actions/progress';

const { addProgressItem, deleteProgressItem } = progressActions;

const UploadDocumentSelectModal = (props) => {
  const {
    isModalOpen,
    handleClose,
    title,
    submitAction,
    dispatchAction,
    dispatchProgress,
    customModalOptions: { documentsList, profileId },
  } = props;

  const [documentTypeId, setDocumentTypeId] = useState(documentsList[0].id);

  const uploadFile = (data) => {
    handleClose();
    dispatchAction(submitAction({
      ...data,
      profileId,
      documentTypeId,
      dispatchProgress,
    }));
  };

  return (
    <Modal
      show={isModalOpen}
      onHide={handleClose}
      className="custom-modal documents-upload-modal"
      centered
    >
      <Modal.Header closeButton>
        <h3 className="custom-modal__title">{title}</h3>
      </Modal.Header>

      <Modal.Body className="custom-modal documents-upload-modal_body">
        <div className="select-document_list">
          {
            documentsList.map(({ id, label }) => (
              <span
                className={
                  classNames(
                    'select-document_list_item',
                    { active: id === documentTypeId },
                  )
                }
                onClick={() => setDocumentTypeId(id)}
                key={id}
              >
                {label}
              </span>
            ))
          }
        </div>

        <DropZone
          onDrop={uploadFile}
          validationSchema={fileValidationForRegistration}
        />
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  const { isModalOpen } = state.get('modal')
    .toJS();

  return {
    isModalOpen,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchAction: action => dispatch(action),
  dispatchProgress: {
    addProgressItem: info => dispatch(addProgressItem(info)),
    deleteProgressItem: info => dispatch(deleteProgressItem(info)),
  },
});

export default memo(connect(
  mapStateToProps, mapDispatchToProps,
)(withImmutablePropsToJS(UploadDocumentSelectModal)));

UploadDocumentSelectModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  submitAction: PropTypes.func.isRequired,
  dispatchAction: PropTypes.func.isRequired,
  dispatchProgress: PropTypes.objectOf(PropTypes.func).isRequired,
};
