import * as yup from 'yup';

export const accountActivityFields = [
  {
    id: 'accountCurrenciesNeeded',
    name: 'accountCurrenciesNeeded',
    type: 'text',
    label: 'Account Currencies Needed',
    validation: true,
    required: true,
  }, {
    id: 'listOfCountriesYouWillBeSendingFundsTo',
    name: 'listOfCountriesYouWillBeSendingFundsTo',
    type: 'text',
    label: 'List of Countries you will be Sending Funds to',
    validation: true,
    required: true,
  },
  {
    id: 'listOfCountriesYouWillBeReceivingFundsFrom',
    name: 'listOfCountriesYouWillBeReceivingFundsFrom',
    type: 'text',
    label: 'List of Countries you will be Receiving Funds from',
    validation: true,
    required: true,
  }, {
    id: 'volumeOfIncomingPaymentsPerMonth',
    name: 'volumeOfIncomingPaymentsPerMonth',
    type: 'text',
    label: 'Volume of Incoming Payments per Month (EUR Equivalent)',
    validation: true,
    required: true,
  }, {
    id: 'volumeOfOutgoingPaymentsPerMonth',
    name: 'volumeOfOutgoingPaymentsPerMonth',
    type: 'text',
    label: 'Volume of Outgoing Payments per Month (EUR Equivalent)',
    validation: true,
    required: true,
  },
  {
    id: 'numberOfIncomingPaymentsPerMonth',
    name: 'numberOfIncomingPaymentsPerMonth',
    label: 'Number of Incoming Payments per Month',
    type: 'text',
    validation: true,
    defaultSorting: false,
    required: true,
  },
  {
    id: 'numberOfOutgoingPaymentsPerMonth',
    name: 'numberOfOutgoingPaymentsPerMonth',
    label: 'Number of Outgoing Payments per Month',
    type: 'text',
    validation: true,
    defaultSorting: false,
    required: true,
  },
  {
    id: 'highestAmountPerIncomingPayment',
    name: 'highestAmountPerIncomingPayment',
    type: 'text',
    label: 'Highest Amount per Incoming Payment (EUR Equivalent)',
    validation: true,
    required: true,
  }, {
    id: 'highestAmountPerOutgoingPayment',
    name: 'highestAmountPerOutgoingPayment',
    type: 'text',
    label: 'Highest Amount per Outgoing Payment (EUR Equivalent)',
    validation: true,
    required: true,
  },
  {
    id: 'lowestAmountPerIncomingPayment',
    name: 'lowestAmountPerIncomingPayment',
    type: 'text',
    label: 'Lowest Amount per Incoming Payment (EUR Equivalent)',
    validation: true,
    required: true,
  },
  {
    id: 'lowestAmountPerOutgoingPayment',
    name: 'lowestAmountPerOutgoingPayment',
    type: 'text',
    label: 'Lowest Amount per Outgoing Payment (EUR Equivalent)',
    validation: true,
    required: true,
  },
];

export const accountActivityValidationSchema = yup.object({
  listOfCountriesYouWillBeSendingFundsTo: yup.string().trim().required('Required!'),
  lowestAmountPerOutgoingPayment: yup.string().trim().required('Required!'),
  volumeOfIncomingPaymentsPerMonth: yup.string().trim().required('Required!'),
  volumeOfOutgoingPaymentsPerMonth: yup.string().trim().required('Required!'),
  listOfCountriesYouWillBeReceivingFundsFrom: yup.string().trim().required('Required!'),
  lowestAmountPerIncomingPayment: yup.string().trim().required('Required!'),
  accountCurrenciesNeeded: yup.string().trim().required('Required!'),
  numberOfIncomingPaymentsPerMonth: yup.string().trim().required('Required!'),
  numberOfOutgoingPaymentsPerMonth: yup.string().trim().required('Required!'),
  highestAmountPerOutgoingPayment: yup.string().trim().required('Required!'),
  highestAmountPerIncomingPayment: yup.string().trim().required('Required!'),
});
