import React from 'react';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import processingErrorsActions from '../../actions/processingErrors';
import RangeCalendar from '../../components/Layouts/RangeCalendar';
import Search from '../../components/Layouts/Search';
import ExportData from '../../components/Layouts/ExportData';

const { getProcessingErrors } = processingErrorsActions.processingErrors;

const ProcessingErrorsHeader = ({
  rangeCalendarAction, onExport, canExport,
}) => (
  <>
    <div className="breadcrumbs-block">
      <div>Admin / Processing Errors</div>
    </div>

    <div className="data_tools">
      <RangeCalendar rangeCalendarAction={rangeCalendarAction} />
      <Search />
      <ExportData onExport={onExport} canExport={canExport} />
    </div>
  </>
);

const mapDispatchToProps = dispatch => ({
  rangeCalendarAction: payload => dispatch(getProcessingErrors.action(payload)),
});

export default connect(null, mapDispatchToProps)(withImmutablePropsToJS(ProcessingErrorsHeader));
