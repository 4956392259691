import { makeSecureRequest } from './apiCallHandlers/index';

export default () => ({
  getFeesByLedgerId: (payload) => makeSecureRequest('get', `Ledger/${payload.id}/fee`),
  changeFeesFieldsForLedgerById:
    ({ ledgerId, feeName, feeValue }) => makeSecureRequest('put', `Ledger/${ledgerId}/fee`, { feeName, feeValue }),
  changeFeesTemplateForLedgerById: (payload) => {
    const { ledgerId, templateId, updatedFields } = payload;
    return makeSecureRequest('post', `Ledger/${ledgerId}/fee/AttachTemplate/${templateId}`, updatedFields);
  },
  updateFeeTemplate: ({ templateId, feeName, rate }) => {
    return makeSecureRequest('put', `Fee/${templateId}`, {
      feeName,
      rate,
    });
  },
});
