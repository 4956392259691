import React from 'react';

const Footer = () => (
  <footer className="footer">
    © WireBloom LLC. All rights reserved.
    <span className="footer_vertical-divider">|</span>
      <a href="https://wirebloom.com/privacy-policy/"><p className="footer_pol-link">Term of use</p></a>
    <span className="footer_vertical-divider">|</span>
      <a href="https://wirebloom.com/privacy-policy/"><p className="footer_pol-link">Privacy Policy</p></a>
  </footer>
);

export default Footer;
