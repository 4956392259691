import moment from 'moment';
import { defineCustomAction } from '../actions/actionHelpers';

export const GET_TRANSACTIONS = defineCustomAction('GET_TRANSACTIONS');
export const GET_ONE_TRANSACTION = defineCustomAction('GET_ONE_TRANSACTION');
export const GET_TRANSACTION_DETAILS = defineCustomAction('GET_TRANSACTION_DETAILS');
export const PROCESS_ONE_TRANSACTION = defineCustomAction('PROCESS_ONE_TRANSACTION');
export const APPROVE_ONE_TRANSACTION = defineCustomAction('APPROVE_ONE_TRANSACTION');
export const DECLINE_ONE_TRANSACTION = defineCustomAction('DECLINE_ONE_TRANSACTION');
export const SELECT_TRANSACTION = 'SELECT_TRANSACTION';
export const SELECT_MANY_TRANSACTION = 'SELECT_MANY_TRANSACTION';
export const CLEAR_MANY_TRANSACTION = 'CLEAR_MANY_TRANSACTION';
export const CLEAR_ONE_TRANSACTION = 'CLEAR_ONE_TRANSACTION';
export const CREATE_TRANSACTION = defineCustomAction('CREATE_TRANSACTION');
export const GET_CONVERTED_BALANCE = defineCustomAction('GET_CONVERTED_BALANCE');
export const DOWNLOAD_INVOICE = defineCustomAction('DOWNLOAD_INVOICE');

export const GET_USER_ACTIVITY = defineCustomAction('GET_USER_ACTIVITY');
export const GET_PROCESSING_ERRORS = defineCustomAction('GET_PROCESSING_ERRORS');
export const SET_USER_ACTIVITY_TYPE = 'SET_USER_ACTIVITY_TYPE';

export const GET_BENEFICIARES = defineCustomAction('GET_BENEFICIARES');
export const GET_ONE_BENEFICIARY = defineCustomAction('GET_ONE_BENEFICIARY');
export const GET_ONE_BENEFICIARY_WITH_LIST = defineCustomAction('GET_ONE_BENEFICIARY_WITH_LIST');
export const CREATE_BENEFICIARY = defineCustomAction('CREATE_BENEFICIARY');
export const CREATE_BENEFICIARY_ON_PLATFORM = defineCustomAction('CREATE_BENEFICIARY_ON_PLATFORM');
export const SET_CHANGE_BENEFICIARY_STATUS = defineCustomAction('SET_CHANGE_BENEFICIARY_STATUS');
export const EDIT_BENEFICIARY = defineCustomAction('EDIT_BENEFICIARY');
export const DELETE_BENEFICIARY = defineCustomAction('DELETE_BENEFICIARY');
export const SWITCH_BENEFICIARY_TYPE = 'SWITCH_BENEFICIARY_TYPE';
export const CLEAR_ONE_BENEFICIARY = 'CLEAR_ONE_BENEFICIARY';

export const GET_ACCOUNTS = defineCustomAction('GET_ACCOUNTS');
export const GET_ONE_ACCOUNT = defineCustomAction('GET_ONE_ACCOUNT');
export const GET_ONE_ACCOUNT_WITH_LIST = defineCustomAction('GET_ONE_ACCOUNT_WITH_LIST');
export const GET_MONTHLY_BALANCE_OF_ACCOUNT = defineCustomAction('GET_MONTHLY_BALANCE_OF_ACCOUNT');
export const CREATE_ACCOUNT = defineCustomAction('CREATE_ACCOUNT');
export const EDIT_ACCOUNT = defineCustomAction('EDIT_ACCOUNT');
export const DELETE_ACCOUNT = defineCustomAction('DELETE_ACCOUNT');
export const CLEAN_ACCOUNT_DATA = 'CLEAN_ACCOUNT_DATA';
export const SET_ACCOUNT = 'SET_ACCOUNT';
export const GET_JOURNALS_OF_ACCOUNT = defineCustomAction('GET_JOURNALS_OF_ACCOUNT');
export const SET_ACCOUNT_STATUS_TO_CANCELED = defineCustomAction('SET_ACCOUNT_STATUS_TO_CANCELED');
export const GET_MONTHLY_STATEMENT = defineCustomAction('GET_MONTHLY_STATEMENT');
export const DOWNLOAD_BUTCH_FILE = defineCustomAction('DOWNLOAD_BUTCH_FILE');
export const UPLOAD_BUTCH_FILE = defineCustomAction('UPLOAD_BUTCH_FILE');
export const GET_AVAILABLE_STATEMENTS_DATES = defineCustomAction('GET_AVAILABLE_STATEMENTS_DATES');
export const CLEAR_MONTHLY_STATEMENT_DATA = 'CLEAR_MONTHLY_STATEMENT_DATA';

export const CREATE_ADJUSTMENT = defineCustomAction('CREATE_ADJUSTMENT');
export const CLEAR_ONE_ACCOUNT = 'CLEAR_ONE_ACCOUNT';

export const PRINT_FILE = 'PRINT_FILE';
export const EXPORT_FILE_TO_PDF = 'EXPORT_FILE_TO_PDF';
export const EXPORT_FILE_TO_EXCEL = 'EXPORT_FILE_TO_EXCEL';
export const GET_PDF_FILE = defineCustomAction('GET_PDF_FILE');

export const GET_JOURNAL = defineCustomAction('GET_JOURNAL');
export const GET_ONE_JOURNAL = defineCustomAction('GET_ONE_JOURNAL');
export const EDIT_ONE_JOURNAL = defineCustomAction('EDIT_ONE_JOURNAL');

export const GET_ONE_LIST = defineCustomAction('GET_ONE_LIST');
export const SET_ONE_LIST = defineCustomAction('SET_ONE_LIST');
export const GET_LISTS = defineCustomAction('GET_LISTS');
export const GET_BANK_LIST = defineCustomAction('GET_BANK_LIST');
export const GET_LEDGER_STATUS_LIST = defineCustomAction('GET_LEDGER_STATUS_LIST');
export const GET_FEES_TYPE_LIST = defineCustomAction('GET_FEES_TYPE_LIST');
export const GET_COUNTRY_LIST = defineCustomAction('GET_COUNTRY_LIST');
export const GET_SEPA_COUNTRY_LIST = defineCustomAction('GET_SEPA_COUNTRY_LIST');
export const GET_CURRENCY_LIST = defineCustomAction('GET_CURRENCY_LIST');
export const GET_CUSTOMER_LIST = defineCustomAction('GET_CUSTOMER_LIST');
export const GET_TRANSACTION_STATUS_LIST = defineCustomAction('GET_TRANSACTION_STATUS_LIST');
export const GET_ROUTING_TYPES_LIST = defineCustomAction('GET_ROUTING_TYPES_LIST');
export const GET_CHARGE_TYPES_LIST = defineCustomAction('GET_CHARGE_TYPES_LIST');
export const GET_CONVERSION_FIXED_TYPE_LIST = defineCustomAction('GET_CONVERSION_FIXED_TYPE_LIST');
export const GET_ENDUSER_TYPE_LIST = defineCustomAction('GET_ENDUSER_TYPE_LIST');
export const GET_INTEGRATION_STATUS_LIST = defineCustomAction('GET_INTEGRATION_STATUS_LIST');
export const GET_TRANSACTIONS_TYPES_LIST = defineCustomAction('GET_TRANSACTIONS_TYPES_LIST');
export const GET_FEES_LIST = defineCustomAction('GET_FEES_LIST');
export const GET_LEDGER_TYPES = defineCustomAction('GET_LEDGER_TYPES');
export const GET_MONEY_GRADATIONS = defineCustomAction('GET_MONEY_GRADATIONS');
export const GET_LEDGERS_LIST = defineCustomAction('GET_LEDGERS_LIST');
export const GET_FEES_TEMPLATES_LIST = defineCustomAction('GET_FEES_TEMPLATES_LIST');
export const GET_CHARGE_FEES_LIST = defineCustomAction('GET_CHARGE_FEES_LIST');
export const GET_TRANSACTION_FEES_LIST = defineCustomAction('GET_TRANSACTION_FEES_LIST');
export const GET_INDUSTRY_TYPES_LIST = defineCustomAction('GET_INDUSTRY_TYPES_LIST');
export const GET_TYPES_OF_OWNERSHIP_LIST = defineCustomAction('GET_TYPES_OF_OWNERSHIP_LIST');
export const GET_BUSINESS_TYPES_LIST = defineCustomAction('GET_BUSINESS_TYPES_LIST');
export const GET_BENEFICIARIES_LIST = defineCustomAction('GET_BENEFICIARIES_LIST');
export const GET_DOCUMENT_TYPES = defineCustomAction('GET_DOCUMENT_TYPES');
export const GET_BATCH_PLATFORMS = defineCustomAction('GET_BATCH_PLATFORMS');
export const CLEAR_LISTS = 'CLEAR_LISTS';

export const GET_FEES_CHARGES = defineCustomAction('GET_FEES_CHARGES');
export const EXECUTE_ONE_FEE = defineCustomAction('EXECUTE_ONE_FEE');
export const DECLINE_ONE_FEE = defineCustomAction('DECLINE_ONE_FEE');
export const SELECT_FEE = 'SELECT_FEE';
export const SELECT_MANY_FEE = 'SELECT_MANY_FEE';
export const CLEAR_MANY_FEE = 'CLEAR_MANY_FEE';

export const ADD_PROGRESS_ITEM = 'ADD_PROGRESS_ITEM';
export const DELETE_PROGRESS_ITEM = 'DELETE_PROGRESS_ITEM';

export const GET_CUSTOMERS = defineCustomAction('GET_CUSTOMERS');
export const CREATE_CUSTOMER_CONTACT = defineCustomAction('CREATE_CUSTOMER_CONTACT');
export const GET_ONE_CUSTOMER = defineCustomAction('GET_ONE_CUSTOMER');
export const DELETE_CUSTOMER_CONTACT = defineCustomAction('DELETE_CUSTOMER_CONTACT');
export const DELETE_CUSTOMER_DOCUMENT = defineCustomAction('DELETE_CUSTOMER_DOCUMENT');
export const UPLOAD_CUSTOMER_DOCUMENT = defineCustomAction('UPLOAD_CUSTOMER_DOCUMENT');
export const DOWNLOAD_CUSTOMER_DOCUMENT = defineCustomAction('DOWNLOAD_CUSTOMER_DOCUMENT');
export const UPDATE_CUSTOMER_FIELD = defineCustomAction('UPDATE_CUSTOMER_FIELD');
export const CHANGE_CUSTOMER_STATUS = defineCustomAction('CHANGE_CUSTOMER_STATUS');
export const GET_COMMENTS_FOR_CUSTOMER = defineCustomAction('GET_COMMENTS_FOR_CUSTOMER');
export const ADD_COMMENTS_FOR_CUSTOMER = defineCustomAction('ADD_COMMENTS_FOR_CUSTOMER');
export const REMOVE_COMMENT_FOR_CUSTOMER = defineCustomAction('REMOVE_COMMENT_FOR_CUSTOMER');
export const EDIT_COMMENTS_FOR_CUSTOMER = defineCustomAction('EDIT_COMMENTS_FOR_CUSTOMER');
export const CLEAN_CUSTOMER_DATA = 'CLEAN_CUSTOMER_DATA';

export const GET_USER = defineCustomAction('GET_USER');
export const UPDATE_USER = defineCustomAction('UPDATE_USER');

export const CHANGE_DEFAULT_TEMPLATE = 'CHANGE_DEFAULT_TEMPLATE';
export const CHANGE_FEES_TEMPLATE_FOR_LEDGER_BY_ID = defineCustomAction('CHANGE_FEES_TEMPLATE_FOR_LEDGER_BY_ID');
export const CLEAN_DEFAULT_TEMPLATE = 'CLEAN_DEFAULT_TEMPLATE';
export const CHANGE_FEES_FIELDS_FOR_JOURNALS_OF_ACCOUNT = defineCustomAction('CHANGE_FEES_FIELDS_FOR_JOURNALS_OF_ACCOUNT');
export const GET_FEES_BY_LEDGER_ID = defineCustomAction('GET_FEES_BY_LEDGER_ID');
export const UPDATE_FEE_TEMPLATE = defineCustomAction('UPDATE_FEE_TEMPLATE');

export const LOGIN_USER = defineCustomAction('LOGIN_USER');
export const LOGOUT_USER = defineCustomAction('LOGOUT_USER');
export const LOGOUT = 'LOGOUT';
export const REFRESH_SESSION = defineCustomAction('REFRESH_SESSION');
export const USER_REGISTER = defineCustomAction('USER_REGISTER');
export const GET_LINK_FOR_RESTORE_PASSWORD = defineCustomAction('GET_LINK_FOR_RESTORE_PASSWORD');
export const GET_LINK_FOR_RESET_PASSWORD = defineCustomAction('GET_LINK_FOR_RESET_PASSWORD');
export const RESET_PASSWORD = defineCustomAction('RESET_PASSWORD');

export const GET_USERS = defineCustomAction('GET_USERS');
export const GET_USER_ACTIVITY_LOGS = defineCustomAction('GET_USER_ACTIVITY_LOGS');
export const CREATE_USER = defineCustomAction('CREATE_USER');
export const CREATE_SUB_USER = defineCustomAction('CREATE_SUB_USER');
export const CREATE_SUB_USER_BY_ADMIN = defineCustomAction('CREATE_SUB_USER_BY_ADMIN');
export const DELETE_USER = defineCustomAction('DELETE_USER');
export const CHANGE_USER_STATUS = defineCustomAction('CHANGE_USER_STATUS');
export const COMPLETE_SUB_USER = defineCustomAction('CLEAN_USER_DATA');
export const GET_USER_CUSTOMERS = defineCustomAction('GET_USER_CUSTOMERS');
export const GET_TOKEN_FOR_CHANGING_USER = defineCustomAction('GET_TOKEN_FOR_CHANGING_USER');
export const CLEAN_USER_DATA = 'CLEAN_USER_DATA';

export const GET_ADMIN_USERS = defineCustomAction('GET_ADMIN_USERS');
export const GET_ONE_ADMIN_USER = defineCustomAction('GET_ONE_ADMIN_USER');
export const CREATE_ADMIN_USER = defineCustomAction('CREATE_ADMIN_USER');
export const EDIT_ADMIN_USER = defineCustomAction('EDIT_ADMIN_USER');
export const CHANGE_ADMIN_USER_STATUS = defineCustomAction('CHANGE_ADMIN_USER_STATUS');

export const UPLOAD_FILE = defineCustomAction('UPLOAD_FILE');
export const DELETE_FILE = defineCustomAction('DELETE_FILE');
export const DOWNLOAD_FILE = defineCustomAction('DOWNLOAD_FILE');
export const CONFIRM_REGISTRATION = defineCustomAction('CONFIRM_REGISTRATION');
export const USER_REGISTRATION = defineCustomAction('USER_REGISTRATION');
export const GET_REGISTRATION_INFO = defineCustomAction('GET_REGISTRATION_INFO');
export const UPDATE_REGISTRATION_INFO = defineCustomAction('UPDATE_REGISTRATION_INFO');
export const CREATE_PASSWORD_LOGIC = defineCustomAction('CREATE_PASSWORD_LOGIC');
export const CLEAN_REGISTRATION_DATA = defineCustomAction('CLEAN_REGISTRATION_DATA');
export const CREATE_CONTACT = defineCustomAction('CREATE_CONTACT');
export const DELETE_CONTACT = defineCustomAction('DELETE_CONTACT');

export const INCREMENT = 'INCREMENT';
export const DECREMENT = 'DECREMENT';

export const GET_REPORT_FOR_PROCESSED_TRANSACTIONS = defineCustomAction('GET_REPORT_FOR_PROCESSED_TRANSACTIONS');
export const GET_TRANSACTION_FAILED = defineCustomAction('GET_TRANSACTION_FAILED');
export const GET_TRANSACTION_FAILED_OUTSIDE_PRE_SET = defineCustomAction('GET_TRANSACTION_FAILED_OUTSIDE_PRE_SET');
export const GET_TRANSACTION_NON_POSTED = defineCustomAction('GET_TRANSACTION_NON_POSTED');
export const GET_TRANSACTION_ERP = defineCustomAction('GET_TRANSACTION_ERP');

export const SET_SEARCH = 'SET_SEARCH';
export const CLEAR_SEARCH = 'CLEAR_SEARCH';
export const SET_MODAL = 'SET_MODAL';
export const SET_MODAL_DATA = 'SET_MODAL_DATA';
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const CLEAN_MODAL_DATA = 'CLEAN_MODAL_DATA';
export const SET_MODAL_FORM_INFO = 'SET_MODAL_FORM_INFO';
export const SET_CHOOSE_ITEM_ID = 'SET_CHOOSE_ITEM_ID';

export const GET_CHART_BY_DAY = defineCustomAction('GET_CHART_BY_DAY');
export const GET_TRANSACTIONS_CURRENCIES_COMPARE = defineCustomAction('GET_TRANSACTIONS_CURRENCIES_COMPARE');
export const GET_TRANSACTIONS_BY_STATUS_BY_TYPE = defineCustomAction('GET_TRANSACTIONS_BY_STATUS_BY_TYPE');
export const GET_TRANSACTIONS_BY_STATUS_BY_CURRENCY = defineCustomAction('GET_TRANSACTIONS_BY_STATUS_BY_CURRENCY');
export const GET_APP_STATISTICS = defineCustomAction('GET_APP_STATISTICS');
export const CLEAR_TRANSACTIONS_DASHBOARD = 'CLEAR_TRANSACTIONS_DASHBOARD';
export const CHANGE_AMOUNT_TYPE = 'CHANGE_AMOUNT_TYPE';

const previousMonth = () => moment().subtract(1, 'month').startOf('month').format('MM.DD.YYYY');
const dateToFormatted = () => moment().format('MM.DD.YYYY');
const dateFromPreviousThreeYearsFormatted = () => moment().subtract(3, 'years').startOf('year').format('MM.DD.YYYY');

/** *
 * the variables
 * datesFromPreviousMonthFormatted,
 * datesFromPreviousMonth,
 * periodForLongRequests
 * periodForLongRequestsForCalendar
 * are declared as functions to update the date with each new call
 * in the component that they are imported.
 * ** */
export const datesFromPreviousMonthFormatted = () => ({
  dateToFormatted: dateToFormatted(),
  dateFromFormatted: previousMonth(),
});

export const datesFromPreviousMonth = () => ({
  startDate: new Date(previousMonth()),
  endDate: new Date(),
});

export const periodForLongRequests = () => ({
  dateToFormatted: dateToFormatted(),
  dateFromFormatted: dateFromPreviousThreeYearsFormatted(),
});

export const periodForLongRequestsForCalendar = () => ({
  startDate: new Date(dateFromPreviousThreeYearsFormatted()),
  endDate: new Date(),
});

export const defaultDateRange = {
  dateFrom: moment().subtract(1, 'months').startOf('month').format('MM.DD.YYYY'),
  dateFromFormatted: moment().subtract(1, 'months').startOf('month').format('MM.DD.YYYY hh:mm:ss'),
  dateFromPreviousThreeYearsFormatted: moment().subtract(3, 'years').startOf('year').format('MM.DD.YYYY'),
  dateFromPreviousHoursFormatted: moment().subtract(1, 'hours').format('MM.DD.YYYY HH:mm:ss'),
  dateFromPreviousDayFormatted: moment().subtract(1, 'days').format('MM.DD.YYYY'),
  dateFromPreviousWeekFormatted: moment().subtract(1, 'week').format('MM.DD.YYYY'),
  dateFromPreviousMonthFormatted: previousMonth(),
  dateTo: moment().format('MM.DD.YYYY hh:mm:ss'),
  dateToWithHours: moment().format('MM.DD.YYYY HH:mm:ss'),
  dateToFormatted: dateToFormatted(),
  datesFromPreviousMonthFormatted: {
    dateFromFormatted: previousMonth(),
    dateToFormatted: dateToFormatted(),
  },
  datesFromPreviousMonth: {
    startDate: new Date(previousMonth()),
    endDate: new Date(),
  },
};
