import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js/lib/index';

import registrationActions from '../../actions/registration';
import AccountSetupTabs from '../../components/Pages/AccountSetup/AccountSetupTabs';
import { sections } from '../../schemes/forms/documentUpload';
import {
  personalInfoFields,
  personalInfoValidationSchema,
} from '../../schemes/forms/companyInfo';
import {
  ownershipFields,
  ownershipValidationSchema,
} from '../../schemes/forms/ownership';
import {
  accountActivityFields,
  accountActivityValidationSchema,
} from '../../schemes/forms/accountActivity';

const {
  updateRegistrationInfo,
  getRegistrationInfo,
  uploadDocument,
  deleteDocument,
  downloadDocument,
  confirmRegistration,
  createContact,
  deleteContact,
} = registrationActions.registration;

const mapStateToProps = (state) => {
  const currentState = state.getIn(['registration', 'registrationInfo']);

  const fetchingFields = currentState.get('fetchingFields');

  return {
    isRegistrationTool: true,
    data: currentState,
    redirectPath: '/user',
    tabsTitle: 'ACCOUNT SETUP',
    specialTabsClass: 'registration-tool',
    documentsSections: sections,
    personalInfoValidationSchema,
    personalInfoFields,
    ownershipFields,
    ownershipValidationSchema,
    accountActivityFields,
    accountActivityValidationSchema,
    fetchingFields,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchGetData: () => dispatch(getRegistrationInfo.action()),
  uploadDocument: payload => dispatch(uploadDocument.action(payload)),
  deleteDocument: id => dispatch(deleteDocument.action(id)),
  downloadDocument: id => dispatch(downloadDocument.action(id)),
  confirmForm: () => dispatch(confirmRegistration.action()),
  updateAccountActivityField: options => dispatch(updateRegistrationInfo.action({
    objectName: 'accountActivity',
    ...options,
  })),
  updateCompanyInfoField: options => dispatch(updateRegistrationInfo.action({
    objectName: 'enduser',
    ...options,
  })),
  updateOwnershipField: options => dispatch(updateRegistrationInfo.action({
    objectName: 'contacts',
    ...options,
  })),
  createContact: () => dispatch(createContact.action()),
  deleteContact: id => dispatch(deleteContact.action(id)),
});

export default connect(mapStateToProps,
  mapDispatchToProps)(withImmutablePropsToJS(AccountSetupTabs));
