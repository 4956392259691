import { createStore, applyMiddleware } from 'redux';
import { createLogicMiddleware } from 'redux-logic';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { commonLogics } from '../actions/commonLogic';

import reducers from '../reducers';
import api from '../endpoints';

const commonLogicMiddleware = createLogicMiddleware(commonLogics, { api });

const middleware = [
  thunk,
  commonLogicMiddleware,
];

const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(...middleware)),
);

export default store;
