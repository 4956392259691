import React, { useState } from 'react';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import SimpleBarChart from '../../../generic/charts/SimpleBarChart';
import EmptyValidation from '../../../HOC/EmptyValidation';

const CompareCurrenciesChart = ({ items }) => {
  return (
    <>
      <EmptyValidation
        isNotEmpty={!isEmpty(items)}
        whenIsEmptyContent="There is no transactions."
      >
        <SimpleBarChart items={items} />
      </EmptyValidation>
    </>
  );
};

CompareCurrenciesChart.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.object.isRequired,
  ).isRequired,
};

const mapStateToProps = (state) => {
  const options = state.getIn(['dashboard', 'options'])
    .toJS();

  const transactionsCurrenciesCompare = state.getIn(['dashboard', 'transactionsCurrenciesCompare'])
    .toJS();

  const { amountType } = options;

  return {
    items: transactionsCurrenciesCompare.data[amountType],
  };
};

export default connect(mapStateToProps)(withImmutablePropsToJS(CompareCurrenciesChart));
