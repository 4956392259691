import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import BeneficiaryPage from '../../../components/Pages/Beneficiary/BeneficiaryPage';
import beneficiariesActions from '../../../actions/beneficiaries';
import listActions from '../../../actions/lists';
import withLists from '../../../components/HOC/withLists';
import { DefaultChildHeader } from '../../../components/HOC/headerHelper';
import {
  valuesForBeneficiariesStatus,
} from '../../../schemes/templatesForLists';

import {
  editBeneficiaryValidationSchemaForCompany,
  editBeneficiaryValidationSchemaForIndividual,
  editBeneficiarySchema,
  viewBeneficiarySchema,
  editBankTable,
  editBeneficiaryTable,
} from '../../../schemes/forms/benficiaries/editBeneficiary';
import { filterStatuses } from '../../../helpers/status';
import { canEditBeneficiary, setFieldsToEdit } from '../../../helpers/other';
import { getBankCodeType } from '../../../schemes/forms/benficiaries/beneficiaryHelpers';

const {
  getOneBeneficiary,
  editBeneficiary,
  clearOneBeneficiary,
  setChangeBeneficiaryStatus,
} = beneficiariesActions.beneficiaries;

const {
  getCustomersList,
  clearLists,
} = listActions.lists;

const mapStateToProps = (state) => {
  const {
    currencies, countries, sepaCountries, enduserTypes, routingTypes, ledgerStatuses, customers,
  } = state.get('lists')
    .toJS();

  const beneficiaryData = state.getIn(['beneficiaries', 'one'])
    .toJS();

  const correctEditBeneficiaryTable = () => {
    if (beneficiaryData.data.beneficiaryTypeId === 2) {
      const newFields = {
        title: editBeneficiaryTable.title,
        fields: editBeneficiaryTable.fields.filter((item) => {
          return item !== 'beneficiaryFirstName' && item !== 'beneficiaryLastName';
        }),
      };

      return newFields;
    }
    const newFields = {
      title: editBeneficiaryTable.title,
      fields: editBeneficiaryTable.fields.filter(item => item !== 'beneficiaryName'),
    };

    return newFields;
  };

  const correctBeneficiaryValidationSchema = beneficiaryData.data.beneficiaryTypeId === 2
    ? editBeneficiaryValidationSchemaForCompany(currencies.data, sepaCountries.data)
    : editBeneficiaryValidationSchemaForIndividual(currencies.data, sepaCountries.data);

  const userRole = state.getIn(['userInfo', 'data', 'role']);
  const subRole = state.getIn(['userInfo', 'data', 'subRole']);
  const userCustomers = state.getIn(['userInfo', 'userCustomers']).toJS();

  const isReadOnlySubUser = Object.values(userCustomers).every(i => i === 'ReadOnlySubUser');

  const editSchema = editBeneficiarySchema(userRole);

  const canEdit = (!isReadOnlySubUser || userRole === 'Admin') && canEditBeneficiary(subRole, beneficiaryData.data.isValidated);

  const setCorrectBeneficiarySchema = (currencyId = '', bankCountryId = '') => {
    const readOnlyFields = userRole === 'Admin' ? ['beneficiaryTypeId'] : ['beneficiaryTypeId', 'isValidated', 'statusId'];
    if (canEdit) {
      if (currencies.data && sepaCountries.data) {
        const bankTypeId = getBankCodeType({
          bankCountryId, currencyId,
        }, currencies.data, sepaCountries.data);

        const optionalFieldsForSepa = ['address', 'city', 'state', 'zip', 'bankAddress', 'bankCity', 'bankCode', 'bankCodeType'];

        switch (bankTypeId) {
          case 1:
          case 2:
          case 4:
          case 7:
            return setFieldsToEdit(editSchema, readOnlyFields);
          case 5:
            return setFieldsToEdit(editSchema, readOnlyFields, optionalFieldsForSepa);
          default:
            return setFieldsToEdit(editSchema, readOnlyFields, ['bankCode', 'bankCodeType']);
        }
      }
      return setFieldsToEdit(editSchema, readOnlyFields, ['bankCode', 'bankCodeType']);
    }
    return viewBeneficiarySchema;
  };

  return {
    beneficiaryTables: [correctEditBeneficiaryTable(), editBankTable],
    beneficiaryValidationSchema: correctBeneficiaryValidationSchema,
    fields: setCorrectBeneficiarySchema(),
    setCorrectBeneficiarySchema,
    data: beneficiaryData,
    one: {},
    header: DefaultChildHeader,
    editBeneficiary: true,
    isViewMode: !canEdit,
    userRole,
    toPage: 'Beneficiaries ',
    toLink: '/beneficiaries',
    path: 'Beneficiary Details',
    formId: 'edit-beneficiary',
    requiredLists: [
      {
        data: {}, // that for list request for every render
        action: getCustomersList.action,
      },
    ],
    lists: {
      currencyId: currencies.data,
      countryId: countries.data,
      bankCountryId: countries.data,
      sepaCountries: sepaCountries.data,
      bankCodeType: routingTypes.data,
      beneficiaryTypeId: enduserTypes.data,
      statusId: filterStatuses(valuesForBeneficiariesStatus, ledgerStatuses.data),
      enduserId: customers.data,
    },
  };
};

const mapDispatchToProps = dispatch => ({
  fetchGetData: payload => dispatch(getOneBeneficiary.action(payload)),
  fetchSetStatus: payload => dispatch(setChangeBeneficiaryStatus.action(payload)),
  submitForm: payload => dispatch(
    editBeneficiary.action({
      payload,
    }),
  ),
  clearData: () => {
    dispatch(clearOneBeneficiary());
    dispatch(clearLists(['customers']));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLists(withImmutablePropsToJS(BeneficiaryPage)));
