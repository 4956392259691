import React, {
  useEffect, useCallback, useRef, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';

import CustomSpinner from '../Spinners/CustomSpinner';
import Header from '../Layouts/Header';
import withSearch from '../HOC/withSearch';

import customSearching from '../../lib/customSearching';
import exportData from '../../lib/exportData';
import getColumns from '../../lib/tablesColumns';

const customOptions = {
  hidePageListOnlyOnePage: true,
  sizePerPage: 10,
  sizePerPageList: [{
    text: '10',
    value: 10,
  }, {
    text: '25',
    value: 25,
  }, {
    text: '50',
    value: 50,
  }, {
    text: '100',
    value: 100,
  }],
};

const ListPage = ({
  fetchGetData,
  pageWithId,
  match: { params },
  data: { data, fetching },
  header,
  searchText,
  title,
  isAuthTokenExpired,
  userRole,
  subRole,
  isReadOnlySubUser,
  defaultSorted,
  firstName,
  lastName,
}) => {
  const table = useRef();

  useEffect(() => {
    if (!isAuthTokenExpired) {
      if (pageWithId) {
        const { id } = params;
        fetchGetData({ id }, userRole);
      } else {
        fetchGetData();
      }
    }
  }, [isAuthTokenExpired]);

  const columns = getColumns(title, data, userRole, subRole, isReadOnlySubUser); // subRole);
  const rows = useMemo(() => customSearching(data, searchText, columns), [searchText, data, columns]);

  customOptions.hideSizePerPage = rows.length <= customOptions.sizePerPage;

  const onExport = useCallback((type, fileHeaderText, fileNameText) => {
    const listTable = table && table.current;

    if (listTable && listTable.paginationContext
      && listTable.paginationContext.props && listTable.paginationContext.props.data) {
      exportData(type, columns, listTable.paginationContext.props.data, title, fileHeaderText, fileNameText, `${firstName} ${lastName}`);
    } else {
      exportData(type, columns, rows, title, fileHeaderText, fileNameText, `${firstName} ${lastName}`);
    }
  }, [columns, data, title]);

  const HeaderTools = header;

  const rowClasses = row => (row && row.marked ? 'marked' : '');

  return (
    <div className="full-height main_padding">
      <Header>
        {HeaderTools && <HeaderTools title={title} fetching={fetching} onExport={onExport} canExport={!!rows.length} />}
      </Header>

      <span className={(fetching || isAuthTokenExpired) ? 'spinner' : ''}>
        <CustomSpinner fetching={fetching || isAuthTokenExpired} />

        <div
          className={`page-content-container list-table ${!(fetching || isAuthTokenExpired) ? 'content--show' : 'content--hide'}`}
        >
          <div
            className="custom-table"
          >
            <BootstrapTable
              ref={table}
              keyField="id"
              id="list-table"
              data={rows}
              columns={columns}
              pagination={paginationFactory(customOptions)}
              noDataIndication="There are no items yet"
              stripped
              condensed
              bordered={false}
              filter={filterFactory()}
              wrapClasses="table-responsive"
              rowClasses={rowClasses}
              defaultSorted={defaultSorted}
            />
          </div>
        </div>
      </span>
    </div>
  );
};

ListPage.defaultProps = {
  fetchGetData: () => {},
  defaultSorted: [],
  searchText: '',
  pageWithId: null,
};

ListPage.propTypes = {
  data: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.string,
    PropTypes.array,
    PropTypes.bool,
  ])).isRequired,
  defaultSorted: PropTypes.arrayOf(PropTypes.shape({
    dataField: PropTypes.string.isRequired,
    order: PropTypes.string.isRequired,
  })),
  fetchGetData: PropTypes.func,
  userRole: PropTypes.string.isRequired,
  subRole: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  isReadOnlySubUser: PropTypes.bool,
  header: PropTypes.shape().isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  pageWithId: PropTypes.bool,
  searchText: PropTypes.string,
  isAuthTokenExpired: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const userCustomers = state.getIn(['userInfo', 'userCustomers']).toJS();

  const isReadOnlySubUser = Object.values(userCustomers).every(i => i === 'ReadOnlySubUser');

  return ({
    isReadOnlySubUser,
    userRole: state.getIn(['userInfo', 'data', 'role']),
    subRole: state.getIn(['userInfo', 'data', 'subRole']),
    isAuthTokenExpired: state.getIn(['userInfo', 'isAuthTokenExpired']),
    firstName: state.getIn(['userInfo', 'data', 'firstName']),
    lastName: state.getIn(['userInfo', 'data', 'lastName']),
  });
};

export default connect(mapStateToProps)(withSearch(ListPage));
