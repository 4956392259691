import * as yup from 'yup';

export const batchFileFields = [
  {
    id: 'platformId',
    name: 'platformId',
    label: 'Bank',
    placeholder: 'Select Bank',
    type: 'select',
    required: true,
  },
];

export const batchFileFieldsValidationSchema = yup.object({
  platformId: yup.string()
    .required('Required!'),
});
