import React, { useCallback, useState, memo } from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import UniversalModalButton from '../../containers/Modals/UniversalModalButton';

const DropDownInactiveStatus = ({
  selectChange, userId, processText, iconName, statuses, isActive, entity
}) => {
  const [show, setShow] = useState(false);

  const changeStatus = useCallback(statusId => () => {
    setShow(false);
    toastr.confirm(`${processText} ${statusId === 6 ? 'Active?' : 'OnHold?'}`, {
      onOk: () => selectChange({ statusId, id: userId }),
    });
  }, []);

  const onBlur = (e) => {
    const { currentTarget } = e;
    setTimeout(() => {
      // Check if the new activeElement is a child of the original container
      if (!currentTarget.contains(document.activeElement)) {
        // You can invoke a callback or add custom logic here
        setShow(false);
      }
    }, 0);
  };

  return (
    <div
      className={`filter-wrapper ${show ? 'show' : ''}`}
      onClick={e => e.stopPropagation()}
      onBlur={onBlur}
      tabIndex="11"
    >
      <i onClick={() => setShow(!show)} className={`${iconName} clickable`} />
      <div className="custom-filter">
        <div className="select-form">
          <Choose>
            <When condition={isActive}>
              <div className="status-helper">
                <UniversalModalButton
                  text="Inactive"
                  modalId={`inactive-${entity}-modal`}
                  inlineButton
                  chosenItemId={userId}
                />
              </div>
              <div className="status-helper-with-modal">
                <UniversalModalButton
                  text="OnHold"
                  modalId={`onHold-${entity}-modal`}
                  inlineButton
                  chosenItemId={userId}
                />
              </div>
            </When>
            <Otherwise>
              {statuses.map(({ statusId, statusName }) => (
                <div
                  className="status-helper"
                  onClick={changeStatus(statusId)}
                  key={statusId}
                >
                  {statusName}
                </div>
              ))}
            </Otherwise>
          </Choose>
        </div>
      </div>
    </div>
  );
};

DropDownInactiveStatus.defaultProps = {
  entity: null,
  isActive: false,
};

DropDownInactiveStatus.propTypes = {
  selectChange: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  userId: PropTypes.string.isRequired,
  statuses: PropTypes.arrayOf(
    PropTypes.shape({
      statusId: PropTypes.number.isRequired,
      statusName: PropTypes.string.isRequired,
    }),
  ).isRequired,
  processText: PropTypes.string.isRequired,
  entity: PropTypes.string,
  iconName: PropTypes.string.isRequired,
};

export default memo(DropDownInactiveStatus);
