import React from 'react';
import PropTypes from 'prop-types';
import Toggle from 'react-toggle';
import classNames from 'classnames';

const InputToggle = ({
  checked,
  disabled,
  onChange,
  size,
}) => (
  <Toggle
    className={classNames('switch-button', size)}
    onChange={({ target }) => onChange(target.checked)}
    checked={checked}
    disabled={disabled}
  />
);

InputToggle.defaultProps = {
  disabled: false,
  size: 'md',
  checked: false,
};
InputToggle.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md']),
};

export default InputToggle;
