import * as yup from 'yup';

import {
  latinFieldWithNumbersAndPunctuationMarks,
  moneyField,
  // transactionsMoneyField,
} from '../../helpers/fieldsValidation';
import { yupExceptionReferenceNotValid } from '../../helpers/notificatios';

const revertPositiveAndNegativeNumbers = (num) => {
  if (num === 0) {
    return num;
  }
  if (num < 0) {
    return Math.abs(num);
  }
  return -Math.abs(num);
};

export const adjustmentFields = [
  {
    id: 'isCharge',
    name: 'isCharge',
    label: 'Type',
    type: 'custom_toggle',
    dependedFunctionForValues: (setValues, e) => {
      setValues({
        isCharge: e.target.value,
        feeId: '',
      });
    },
  },
  {
    id: 'postDate',
    name: 'postDate',
    label: 'Post Date',
    placeholder: '',
    type: 'date',
    required: true,
  },
  {
    id: 'feeId',
    name: 'feeId',
    label: 'Fee',
    placeholder: 'Select Fee',
    type: 'select',
    defaultSorting: true,
    required: true,
    dependedFunctionForValues: (setValues, e, lists, { isCharge }) => {
      const { currentFees, feeId: feeList } = lists;
      const feeValue = feeList[e.target.value];

      if (isCharge && feeValue !== 'Adjustment fee' && !feeValue.includes('%')) {
        const amountValue = currentFees[feeValue] || 0;

        setValues({
          amount: revertPositiveAndNegativeNumbers(amountValue),
          feeId: e.target.value,
        });
      } else {
        setValues({
          feeId: e.target.value,
          amount: 0,
        });
      }
    },
  },
  {
    id: 'amount',
    name: 'amount',
    label: 'Amount',
    placeholder: '',
    type: 'currency',
    required: true,
  },
  {
    id: 'reference',
    name: 'reference',
    label: 'Reference',
    placeholder: '',
    type: 'text',
    required: true,
    infoDescription: yupExceptionReferenceNotValid,
  },
];

export const newAdjustmentFieldsValidationSchema = (/* feeList */) => yup.object({
  postDate: yup.date()
    .required('Required!'),
  amount: moneyField().required('Required!'),
  // amount: transactionsMoneyField(feeList),
  reference: latinFieldWithNumbersAndPunctuationMarks()
    .max(100, yupExceptionReferenceNotValid)
    .trim()
    .required('Required!'),
  feeId: yup.string()
    .required('Required!'),
});
