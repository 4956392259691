import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';

import userActions from '../../actions/user';
import { deauthenticateUser } from '../../actions/localStorage';

import Tooltip from '../generic/Tooltip';
import NotificationBarContainer from '../../containers/Pages/Messages/NotificationBarContainer';

const { logout } = userActions.user;

const Header = ({
  logoutFunc,
  children,
  firstName,
  lastName,
}) => (
  <div className="header">
    <div className="user_toolbar">

      <Dropdown>
        <Dropdown.Toggle
          variant="success"
          className="logout-toggle"
          aria-label="logout-toggle"
        >
          <div className="avatar" data-tip="user-avatar" data-for="user-avatar">
            {firstName[0] + lastName[0]}
          </div>
          <Tooltip id="user-avatar" text={`${firstName} ${lastName}`} />
          <i className="iconfont-select-arrow select-icon" />
        </Dropdown.Toggle>

        <Dropdown.Menu alignRight>
          <Dropdown.Item
            href="/"
            onClick={(e) => {
              e.preventDefault();
              logoutFunc();
              deauthenticateUser(false);
            }}
          >
            Logout
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>

    {/*<NotificationBarContainer />*/}

    <div className="header__tools">
      {children}
    </div>
  </div>
);

Header.defaultProps = {
  firstName: 'n/a',
  lastName: 'n/a',
};

Header.propTypes = {
  logoutFunc: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
};

const mapStateToProps = state => ({
  firstName: state.getIn(['userInfo', 'data', 'firstName']),
  lastName: state.getIn(['userInfo', 'data', 'lastName']),
});

export default connect(mapStateToProps, { logoutFunc: logout })(withImmutablePropsToJS(Header));
