import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import withImmutablePropsToJS from 'with-immutable-props-to-js';
import customerActions from '../../actions/customers';
import Comments from '../../components/Pages/AccountSetup/Comments';

const {
  getCommentsForCustomer,
  addCommentsForCustomer,
  removeCommentForCustomer,
  editCommentsForCustomer,
} = customerActions.customers;

const CommentsContainer = (props) => {
  const {
    comments,
    fetchData,
    enduserId,
    userId,
    addComment,
    editComment,
    removeComment,
    subRole,
  } = props;

  const [isSectionOpen, changeSectionOpen] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const sendComments = (text, id = '') => {
    addComment({
      enduserId,
      text,
      id,
    });
  };

  const canRemoveAndEdit = (senderId) => {
    return subRole === 'SuperAdmin' || senderId === userId;
  };

  return (
    <Comments
      userId={userId}
      isSectionOpen={isSectionOpen}
      comments={comments}
      changeSectionOpen={changeSectionOpen}
      sendComments={sendComments}
      canEdit={canRemoveAndEdit}
      editComment={editComment}
      canRemove={canRemoveAndEdit}
      removeComment={removeComment}
    />
  );
};

const mapStateToProps = (state) => {
  const subRole = state.getIn(['userInfo', 'data', 'subRole']);
  const comments = state.getIn(['customers', 'one', 'comments']);
  const userId = state.getIn(['userInfo', 'data', 'sub']);

  return {
    userId,
    subRole,
    comments,
  };
};

const mapDispatchToProps = (dispatch, { enduserId }) => ({
  fetchData: () => dispatch(getCommentsForCustomer.action(enduserId)),
  addComment: data => dispatch(addCommentsForCustomer.action(data)),
  removeComment: data => dispatch(removeCommentForCustomer.action(data)),
  editComment: data => dispatch(editCommentsForCustomer.action(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withImmutablePropsToJS(CommentsContainer));

CommentsContainer.defaultProps = {
  comments: [],
};

CommentsContainer.propTypes = {
  comments: PropTypes.arrayOf(PropTypes.object),
  fetchData: PropTypes.func.isRequired,
  addComment: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  enduserId: PropTypes.string.isRequired,
  removeComment: PropTypes.func.isRequired,
  subRole: PropTypes.string.isRequired,
  editComment: PropTypes.func.isRequired,
};
