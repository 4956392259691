import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import StackedBarChart from '../../../generic/charts/StackedBarChart';

const mapStateToProps = (state) => {
  const options = state.getIn(['dashboard', 'options'])
    .toJS();
  const { amountType } = options;
  const items = state.getIn(['dashboard', 'selectionsByStatusByCurrency', 'data', amountType]);
  const fetching = state.getIn(['dashboard', 'selectionsByStatusByCurrency', 'fetching']);

  return {
    items,
    options,
    fetching,
  };
};

export default connect(mapStateToProps)(withImmutablePropsToJS(StackedBarChart));
