import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { dayAndMonthFormatter } from '../../../helpers/dates';

const CustomTooltip = ({
  active, payload, label, currency,
}) => {
  if (active) {
    return (
      <div className="custom-tooltip">
        <span className="label">{`${dayAndMonthFormatter(label)}`}</span>
        <span className="desc">{`Carried out ${payload && payload[0].payload.count} transactions`}</span>
        <span className="desc">{`For the amount ${payload && payload[0].payload.volume} ${currency}`}</span>
      </div>
    );
  }

  return null;
};

CustomTooltip.defaultProps = {
  label: null,
  payload: [
    {
      payload: {
        count: 0,
        volume: 0,
      },
    },
  ],
  active: false,
  currency: null,
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(PropTypes.any),
  label: PropTypes.string,
  currency: PropTypes.string,
};

const mapStateToProps = (state) => {
  const {
    currencies,
  } = state.get('lists')
    .toJS();

  const currencyId = state.getIn(['dashboard', 'options', 'currencyId']);

  return {
    currency: currencies.data[currencyId],
  };
};

export default connect(mapStateToProps)(withImmutablePropsToJS(CustomTooltip));
