import React, { useState } from 'react';
import { isNaN } from 'lodash';

import { convertDateToLocaleFormat } from '../../helpers/dates';
import { isJson } from '../../helpers/other';

const DataParsingContent = ({ data = {} }) => {
  return Object
    .entries(data)
    .map(([key, value], index) => (
      <div className="info-table_item" key={index}>
        <span className="info-table_label">{key}</span>
        <Choose>
          <When condition={typeof value !== 'number' && !isNaN(Date.parse(value))}>
            <span className="info-table_value">
              {convertDateToLocaleFormat(value)}
            </span>
          </When>
          <When condition={typeof value === 'number' || (typeof value === 'string' && !isJson(value))}>
            <span className="info-table_value">
              {value}
            </span>
          </When>
          <Otherwise>
            <ContentWithShowMoreButton content={value}/>
          </Otherwise>
        </Choose>
      </div>
    ));
};

export const ContentWithShowMoreButton = ({ content }) => {
  return (<span className="info-table_value">
            <textarea value={content}>{content}</textarea>
    </span>);
};

export default DataParsingContent;
