import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import transactionsActions from '../../actions/transactions';
import RangeCalendar from '../../components/Layouts/RangeCalendar';
import Search from '../../components/Layouts/Search';
import ExportData from '../../components/Layouts/ExportData';
import Button from '../../components/generic/Button';
import ReadOnlyPermission from '../../components/HOC/ReadOnlyPermissionHOC';
import { tooltipCantCreateTransaction } from '../../helpers/notificatios';
import { datesFromPreviousMonthFormatted, datesFromPreviousMonth } from '../../constants/constants';
import UniversalModalButton from '../Modals/UniversalModalButton';

const TransactionsHeader = ({
  rangeCalendarAction,
  onExport,
  canExport,
  datesForRequest,
  datesForCalendar,
  userRole,
}) => {
  useEffect(() => {
    rangeCalendarAction(datesForRequest);
  }, []);

  return (
    <>
      <div className="breadcrumbs-block">
        <div>Transactions</div>

        <div className="buttons-block">
          <ReadOnlyPermission
            tooltipId="add-transaction"
            declinePermissionText={tooltipCantCreateTransaction}
            readOnlyComponent={(
              <span
                data-tip="add-transaction"
                data-for="add-transaction"
              >
                <Button
                  className="disabled"
                  color="green"
                  type="primary"
                  size="sm"
                  text="+"
                />
              </span>
            )}
          >
            <NavLink to="/transactions/create" activeClassName="active">
              <Button
                color="green"
                type="primary"
                size="sm"
                text="+"
              />
            </NavLink>
          </ReadOnlyPermission>
        </div>
        <If condition={userRole === 'Admin'}>
          <UniversalModalButton
            modalId="download-batch-file"
            text="Download Batch"
            color="green"
            type="primary"
            size="sm"
          />
        </If>
      </div>

      <div className="data_tools">
        <RangeCalendar
          rangeCalendarAction={rangeCalendarAction}
          customDefaultDate={datesForCalendar}
        />

        <Search />

        <ExportData onExport={onExport} canExport={canExport} />
      </div>
    </>
  );
};

const { getTransactions } = transactionsActions.transactions;

TransactionsHeader.propTypes = {
  onExport: PropTypes.func.isRequired,
  rangeCalendarAction: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
  datesForRequest: PropTypes.shape({
    dateFromFormatted: PropTypes.string.isRequired,
    dateToFormatted: PropTypes.string.isRequired,
  }).isRequired,
  datesForCalendar: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date).isRequired,
    endDate: PropTypes.instanceOf(Date).isRequired,
  }).isRequired,
  canExport: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  userRole: state.getIn(['userInfo', 'data', 'role']),
  datesForRequest: datesFromPreviousMonthFormatted(),
  datesForCalendar: datesFromPreviousMonth(),
});

export default connect(mapStateToProps, {
  rangeCalendarAction: getTransactions.action,
})(withImmutablePropsToJS(TransactionsHeader));
