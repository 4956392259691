import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';

import UniversalModalButton from '../Modals/UniversalModalButton';
import accountsActions from '../../actions/accounts';
import RangeCalendar from '../../components/Layouts/RangeCalendar';
import Search from '../../components/Layouts/Search';
import ExportData from '../../components/Layouts/ExportData';
import {
  periodForLongRequestsForCalendar,
  periodForLongRequests,
} from '../../constants/constants';
import ReadOnlyPermission from '../../components/HOC/ReadOnlyPermissionHOC';
import { tooltipCantUploadBatch, tooltipCantCreateAccount } from '../../helpers/notificatios';
import Button from '../../components/generic/Button';

const AccountsHeader = ({
  rangeCalendarAction,
  onExport,
  userRole,
  canExport,
  datesForRequest,
  datesForCalendar,
}) => {
  useEffect(() => {
    rangeCalendarAction(datesForRequest);
  }, []);

  return (
    <>
      <div className="breadcrumbs-block">
        <div>Accounts</div>
        <If condition={userRole === 'Admin'}>
          <ReadOnlyPermission
            tooltipId="create_account"
            key="create_account"
            declinePermissionText={tooltipCantCreateAccount}
            readOnlyComponent={(
              <span
                data-tip="create_account"
                data-for="create_account"
              >
                <Button
                  type="secondary"
                  text="+"
                  color="green"
                  size="sm"
                  disabled
                  className="disabled"
                />
              </span>
            )}
          >
            <UniversalModalButton
              modalId="create-account"
              title="New Account"
              text="+"
              color="green"
              type="primary"
              size="sm"
            />
          </ReadOnlyPermission>
        </If>
        <If condition={userRole === 'Admin'}>
          <ReadOnlyPermission
            tooltipId="batch_upload"
            key="batch_upload"
            declinePermissionText={tooltipCantUploadBatch}
            readOnlyComponent={(
              <span
                data-tip="batch_upload"
                data-for="batch_upload"
              >
                <Button
                  type="secondary"
                  text="Upload Batch"
                  color="green"
                  size="sm"
                  disabled
                  className="disabled"
                />
              </span>
            )}
          >
            <UniversalModalButton
              modalId="upload-batch-file"
              text="Upload Batch"
              color="green"
              type="primary"
              size="sm"
            />
          </ReadOnlyPermission>
        </If>
      </div>

      <div className="data_tools">
        <RangeCalendar
          rangeCalendarAction={rangeCalendarAction}
          customDefaultDate={datesForCalendar}
        />

        <Search />

        <ExportData onExport={onExport} canExport={canExport} />
      </div>
    </>
  );
};

AccountsHeader.defaultProps = {
  userRole: '',
};

AccountsHeader.propTypes = {
  rangeCalendarAction: PropTypes.func.isRequired,
  userRole: PropTypes.string,
  onExport: PropTypes.func.isRequired,
  datesForRequest: PropTypes.shape({
    dateFromFormatted: PropTypes.string.isRequired,
    dateToFormatted: PropTypes.string.isRequired,
  }).isRequired,
  datesForCalendar: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date).isRequired,
    endDate: PropTypes.instanceOf(Date).isRequired,
  }).isRequired,
  canExport: PropTypes.bool.isRequired,
};

const mapStateToProps = (store) => {
  const role = store.getIn(['userInfo', 'data', 'role']);

  return {
    userRole: role,
    datesForRequest: periodForLongRequests(),
    datesForCalendar: periodForLongRequestsForCalendar(),
  };
};

export default connect(mapStateToProps, {
  rangeCalendarAction: accountsActions.accounts.getAccounts.action,
})(withImmutablePropsToJS(AccountsHeader));
