import { combineActions, handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import { combineReducers } from 'redux-immutable';
import { isEmpty } from 'lodash';

import feesChargesActions from '../actions/feesCharges';
import beneficiariesActions from '../actions/beneficiaries';
import userActions from '../actions/user/userActions';

const { logout } = userActions;

const {
  getFeesCharges,
  executeOneFee,
  selectFee,
  selectAllFees,
  clearAllFees,
  declineOneFee,
} = feesChargesActions.feesCharges;

const { createBeneficiaryOnPlatform } = beneficiariesActions.beneficiaries;

const initialState = fromJS({
  list: {
    data: [],
    error: false,
    fetching: false,
  },
  one: {
    data: {},
    error: false,
    fetching: false,
  },
  feesStatusChanging: {
    selected: [],
    inProcessing: [],
    fetching: false,
  },
});

const feesChargesReducer = handleActions(
  {
    [combineActions(
      getFeesCharges.fetching,
    )]: state => state
      .set('fetching', true)
      .set('error', false),
    [combineActions(
      getFeesCharges.done,
      getFeesCharges.failure,
      getFeesCharges.error,
    )]: state => state
      .set('fetching', false)
      .set('error', true),
    [getFeesCharges.success]: (state, { payload }) => state
      .set('data', fromJS(payload))
      .set('fetching', false),
    [createBeneficiaryOnPlatform.success]: (state, { payload }) => {
      const { transactionId = '', transactionDeclineReason = '' } = payload;
      const itemIndex = state.get('data')
        .findKey(obj => obj.get('id') === transactionId);

      return state.setIn(['data', itemIndex, 'declineReason'], fromJS(transactionDeclineReason));
    },
    [combineActions(
      executeOneFee.success,
      declineOneFee.success,
    )]: (state, { payload }) => {
      const itemIndex = state.get('data')
        .findKey(obj => obj.get('id') === payload.id);

      if (itemIndex) {
        const currentItem = state.get('data')
          .get(itemIndex)
          .toJS();

        return state.setIn(['data', itemIndex], fromJS({
          ...currentItem,
          ...payload,
          marked: true,
        }));
      }
      return state;
    },
    [logout]: () => initialState.get('list'),
  },
  initialState.get('list'),
);

const feeStatusChangingReducer = handleActions(
  {
    [selectFee]: (state, { payload }) => {
      const { addNew = false, key = '' } = payload;
      const currentState = state.get('selected')
        .toJS();

      if (addNew) {
        if (currentState.indexOf(key) !== -1) {
          return state;
        }
        return state.set('selected', fromJS([...currentState, key]));
      } else {
        return state.set('selected', fromJS(currentState.filter(id => key !== id)));
      }
    },
    [selectAllFees]: (state, { payload }) => {
      const { addNew = false, transactionsIds = [] } = payload;
      const currentSelectedState = state.get('selected')
        .toJS();

      if (addNew) {
        return state.set('selected', fromJS([...currentSelectedState, ...transactionsIds]));
      } else {
        const newSelectedState = currentSelectedState.filter(id => transactionsIds.indexOf(id) === -1);

        return state.set('selected', fromJS(newSelectedState));
      }
    },
    [clearAllFees]: state => state.set('selected', fromJS([])),
    [combineActions(
      executeOneFee.fetching,
    )]: (state, { payload }) => {
      const currentState = state.get('inProcessing')
        .toJS();
      const newSelectedState = state.get('selected')
        .toJS()
        .filter(id => payload !== id);

      if (currentState.indexOf(payload) !== -1) {
        return state.set('selected', fromJS(newSelectedState))
          .set('fetching', true);
      }
      return state
        .set('inProcessing', fromJS([...currentState, payload]))
        .set('selected', fromJS(newSelectedState))
        .set('fetching', true);
    },
    [declineOneFee.fetching]: (state, { payload }) => {
      const currentState = state.get('inProcessing')
        .toJS();
      const newSelectedState = state.get('selected')
        .toJS()
        .filter(id => payload !== id);

      if (currentState.indexOf(payload) !== -1) {
        return state.set('selected', fromJS(newSelectedState))
          .set('fetching', true);
      }
      return state
        .set('inProcessing', fromJS([...currentState, payload.id]))
        .set('selected', fromJS(newSelectedState))
        .set('fetching', true);
    },
    [combineActions(
      executeOneFee.success,
      declineOneFee.success,
    )]: (state, { payload }) => {
      const newProcessingState = state.get('inProcessing')
        .toJS()
        .filter(id => payload.id !== id);

      if (isEmpty(newProcessingState)) {
        return state.set('inProcessing', fromJS(newProcessingState))
          .set('fetching', false);
      }
      return state.set('inProcessing', fromJS(newProcessingState));
    },
    [combineActions(
      executeOneFee.failure,
      executeOneFee.error,
      executeOneFee.failure,
      executeOneFee.error,
    )]: (state, { payload }) => {
      const { errorPayload } = payload;
      const newProcessingState = state.get('inProcessing')
        .toJS()
        .filter(id => errorPayload !== id);

      if (isEmpty(newProcessingState)) {
        return state.set('inProcessing', fromJS(newProcessingState))
          .set('fetching', false);
      }
      return state.set('inProcessing', fromJS(newProcessingState));
    },
    [combineActions(
      executeOneFee.failure,
      executeOneFee.error,
    )]: (state, { payload }) => {
      const { errorPayload } = payload;
      const newProcessingState = state.get('inProcessing')
        .toJS()
        .filter(id => errorPayload.id !== id);

      if (isEmpty(newProcessingState)) {
        return state.set('inProcessing', fromJS(newProcessingState))
          .set('fetching', false);
      }
      return state.set('inProcessing', fromJS(newProcessingState));
    },
    [logout]: () => initialState.get('feesStatusChanging'),
  },
  initialState.get('feesStatusChanging'),
);

export default combineReducers({
  list: feesChargesReducer,
  // one: oneTransactionReducer,
  feeStatusChanging: feeStatusChangingReducer,
});
