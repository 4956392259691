import React from 'react';
import PropsTypes from 'prop-types';

const EmptyValidation = ({
  children,
  isNotEmpty,
  whenIsEmptyContent,
}) => (
  <Choose>
    <When condition={isNotEmpty}>
      {children}
    </When>
    <Otherwise>
      <span>
        {whenIsEmptyContent}
      </span>
    </Otherwise>
  </Choose>
);

EmptyValidation.propTypes = {
  isNotEmpty: PropsTypes.bool.isRequired,
  whenIsEmptyContent: PropsTypes.string.isRequired,
  children: PropsTypes.element.isRequired,
};

export default EmptyValidation;
