import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import AccountSetupStep from '../../components/Pages/AccountSetup/AccountSetupStep';

const mapStateToProps = state => {
  return {
    formName: 'limits',
  };
};

export default connect(mapStateToProps)(withImmutablePropsToJS(AccountSetupStep));
