import { createAction } from 'redux-actions';
import moment from 'moment';
import {
  pickBy, identity, pick, maxBy, isEmpty,
} from 'lodash';

import {
  CHANGE_AMOUNT_TYPE,
  GET_CHART_BY_DAY,
  GET_APP_STATISTICS,
  CLEAR_TRANSACTIONS_DASHBOARD,
  GET_TRANSACTIONS_CURRENCIES_COMPARE,
  GET_TRANSACTIONS_BY_STATUS_BY_CURRENCY,
  GET_TRANSACTIONS_BY_STATUS_BY_TYPE,
} from '../../constants/constants';

import { apiCallAction } from '../actionHelpers';
import { apiCallActionHandler } from '../commonLogic/requestLogic';

const cleanData = data => pickBy(data, identity);

const actions = {
  getChartByDay: apiCallAction(GET_CHART_BY_DAY),
  getChartUsersByStatus: apiCallAction(GET_APP_STATISTICS),
  getTransactionsCurrenciesCompare: apiCallAction(GET_TRANSACTIONS_CURRENCIES_COMPARE),
  getTransactionsByStatusByCurrency: apiCallAction(GET_TRANSACTIONS_BY_STATUS_BY_CURRENCY),
  getTransactionsByStatusByType: apiCallAction(GET_TRANSACTIONS_BY_STATUS_BY_TYPE),
  changeAmountType: createAction(CHANGE_AMOUNT_TYPE),
  clearTransactionsDashboard: createAction(CLEAR_TRANSACTIONS_DASHBOARD),
};

const getChartByDayLogic = apiCallActionHandler({
  name: 'getChartByDay',
  fetchAction: actions.getChartByDay,
  makeApiCall(payload, { api }) {
    return api.dashboard.getChartByDay(payload);
  },
  transformPayload({
    dateFrom, dateTo, ...data
  }) {
    const clearingData = pick(data, ['enduserId', 'ledgerId', 'currencyId']);

    const payload = {
      ...clearingData,
      dateTo: moment(dateTo).format('MM.DD.YYYY'),
      dateFrom: moment(dateFrom).format('MM.DD.YYYY'),
    };

    return cleanData(payload);
  },
});

const getTransactionsCurrenciesCompareLogic = apiCallActionHandler({
  name: 'getTransactionsCurrenciesCompare',
  fetchAction: actions.getTransactionsCurrenciesCompare,
  makeApiCall(payload, { api }) {
    return api.dashboard.getTransactionsCurrenciesCompare(payload);
  },
  transformPayload({
    dateFrom, dateTo, ...rest
  }) {
    const clearingData = pick(rest, ['enduserId', 'ledgerId', 'currencyId']);

    const payload = {
      ...clearingData,
      dateTo: moment(dateTo).format('MM.DD.YYYY'),
      dateFrom: moment(dateFrom).format('MM.DD.YYYY'),
    };

    return cleanData(payload);
  },
  transformResponse({ data }, payload) {
    const calculateMaxValues = (objsArr) => {
      if (!isEmpty(objsArr)) {
        const maxValuesObj = {};

        const keys = Object.keys(objsArr[0]);
        keys.forEach((item) => {
          maxValuesObj[item] = maxBy(objsArr, item)[item];
        });

        return maxValuesObj;
      }

      return [];
    };

    data.maxIncomingValues = calculateMaxValues(data.incoming);
    data.maxOutgoing = calculateMaxValues(data.outgoing);
    data.maxCharge = calculateMaxValues(data.charge);

    return data;
  },
});

const getTransactionsByStatusByCurrencyLogic = apiCallActionHandler({
  name: 'getTransactionsByStatusByCurrency',
  fetchAction: actions.getTransactionsByStatusByCurrency,
  makeApiCall(payload, { api }) {
    return api.dashboard.getTransactionsByStatusByCurrency(payload);
  },
  transformPayload({
    dateFrom, dateTo,
  }) {
    return {
      dateTo: moment(dateTo).format('MM.DD.YYYY'),
      dateFrom: moment(dateFrom).format('MM.DD.YYYY'),
    };
  },
});

const getTransactionsByStatusByTypeLogic = apiCallActionHandler({
  name: 'getTransactionsByStatusByType',
  fetchAction: actions.getTransactionsByStatusByType,
  makeApiCall(payload, { api }) {
    return api.dashboard.getTransactionsByStatusByType(payload);
  },
  transformPayload({
    dateFrom, dateTo,
  }) {
    return {
      dateTo: moment(dateTo).format('MM.DD.YYYY'),
      dateFrom: moment(dateFrom).format('MM.DD.YYYY'),
    };
  },
});

const getChartUsersByStatusLogic = apiCallActionHandler({
  name: 'getChartUsersByStatusLogic',
  fetchAction: actions.getChartUsersByStatus,
  makeApiCall(payload, { api }) {
    return api.dashboard.getChartUsersByStatus(payload);
  },
  transformPayload({
    dateFrom, dateTo,
  }) {
    return {
      dateTo: moment(dateTo).format('MM.DD.YYYY'),
      dateFrom: moment(dateFrom).format('MM.DD.YYYY'),
    };
  },
});

export const logics = [
  getChartByDayLogic,
  getChartUsersByStatusLogic,
  getTransactionsCurrenciesCompareLogic,
  getTransactionsByStatusByTypeLogic,
  getTransactionsByStatusByCurrencyLogic,
];

export default actions;
