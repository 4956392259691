import React from 'react';

import AnimatedSpinner from '../../components/Spinners/AnimatedSpinner';
import InfoReason from '../../components/Layouts/InfoReason';
import { FeesChargesStatusHelper } from '../../lib/statusHelper/statusHelper';

const FeesChargesPendingFormatter = props => {
  const {
    processAction,
    processText,
    id,
    isVerified,
    transactionsStatusChanging,
    declineReason,
  } = props;
  const { inProcessing, fetching } = transactionsStatusChanging;

  const isProcessing = inProcessing.some(it => it === id) && fetching;

  return (
    <>
      <AnimatedSpinner fetching={isProcessing}/>
      <div
        className={`status-block status-block__pending ${isProcessing ? 'content--hide' : 'content--show'}`}
      >
        <FeesChargesStatusHelper
          id={id}
          isVerified={isVerified}
          processAction={processAction}
          processText={processText}
        />

        <If condition={declineReason && declineReason !== 'n/a'}>
          <InfoReason id={id} declineReason={declineReason}/>
        </If>
      </div>
    </>
  );
};

export default FeesChargesPendingFormatter;
