import React, { memo } from 'react';
import { Switch, Route } from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';

import AuthContainer from '../containers/AuthContainers/AuthContainer';
import MainLayoutContainer from '../containers/MainLayoutContainer';

const App = ({ isAuth, userStatus }) => (
  <>
    <Switch>
      <Route
        path="/"
        component={isAuth && (userStatus === '2' || userStatus === '6') ? MainLayoutContainer : AuthContainer}
      />
    </Switch>

    <ReduxToastr
      timeOut={10000}
      newestOnTop={false}
      preventDuplicates
      position="top-right"
      getState={state => state.getIn(['toastr'])}
      transitionIn="fadeIn"
      transitionOut="fadeOut"
      progressBar
      closeOnToastrClick
    />
  </>
);

App.defaultProps = {
  userStatus: null,
};

App.propTypes = {
  isAuth: PropTypes.bool.isRequired,
  userStatus: PropTypes.string,
};

const mapStateToProps = state => ({
  isAuth: state.getIn(['userInfo', 'isAuth']),
  userStatus: state.getIn(['userInfo', 'data', 'Status']),
});

export default connect(mapStateToProps)(withImmutablePropsToJS(memo(App)));
