import { cloneDeep, isEmpty } from 'lodash';
import React from 'react';

// export default function customSearching(rows = [], value = '') {
//   if (value && value.trim() && !isEmpty(rows) ) {
//     const data = cloneDeep(rows);
//     const searchText = value.toString().toLowerCase();
//
//     return data
//       .filter(it => Object.values(it)
//       .join()
//       .toLowerCase()
//       .indexOf(searchText) > -1);
//   }
//   return rows;
// }

// Filtering with support custom items likes dates
// !!! If you decide to implement it, search --> customSearching(data, searchText
export default function customSearching(rows = [], value = '', columns) {
  if (value && value.trim() && !isEmpty(rows) ) {
    const data = cloneDeep(rows);
    const searchText = value.toString().toLowerCase();
    const formattingFields = columns.filter(it => it.transformationItem);

    if (!isEmpty(formattingFields)) {
      return data.filter(item => {
        const testItem = cloneDeep(item);

        formattingFields.forEach(({ dataField, transformationItem }) => {
          testItem[dataField] = transformationItem(testItem[dataField]);
        });

        return Object.values(testItem).join().toLowerCase().indexOf(searchText) > -1
      });
    }

    return data.filter(it => Object.values(it).join().toLowerCase().indexOf(searchText) > -1);
  }

  return cloneDeep(rows);
}
