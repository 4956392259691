import { createAction } from 'redux-actions';
import {
  SET_MODAL,
  OPEN_MODAL,
  CLOSE_MODAL,
  CLEAN_MODAL_DATA,
  SET_MODAL_FORM_INFO,
  SET_CHOOSE_ITEM_ID,
  SET_MODAL_DATA,
} from '../../constants/constants';

export default {
  setModalVisibility: createAction(SET_MODAL),
  openModal: createAction(OPEN_MODAL),
  setModalData: createAction(SET_MODAL_DATA),
  closeModal: createAction(CLOSE_MODAL),
  cleanModalData: createAction(CLEAN_MODAL_DATA),
  setModalFormInfo: createAction(SET_MODAL_FORM_INFO),
  setChosenItemId: createAction(SET_CHOOSE_ITEM_ID),
};
