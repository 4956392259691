import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';

import searchActions from '../actions/search';
import userActions from '../actions/user/userActions';
const { logout } = userActions;

const initialState = fromJS("");

const search = handleActions(
  {
    [searchActions.search]: (state, {payload}) => fromJS(payload),
    [searchActions.clear]: () => fromJS(initialState),
    [logout]: () => initialState,
  },
  initialState
);

export default search;
