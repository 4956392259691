import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

import { routesByRoleSeparation } from '../../router/routerItems/mainRoutes';
import CustomRouteWrapper from '../../router/CostomRouteWrapper';
import Footer from './Footer';
import {
  notAllowedForAdmin,
  notAllowedForReadOnlyAdmin,
  notAllowedForUser,
} from '../../helpers/routes';

const getIsAllowedPaths = (role, subRole, path) => {
  const page = path.substr(1);
  if (role === 'User') {
    return notAllowedForUser.includes(page);
  }
  if (subRole === 'ReadOnlyAdmin') {
    return notAllowedForReadOnlyAdmin.includes(page);
  }
  return notAllowedForAdmin.includes(page);
};

const Content = ({ role, subRole }) => {
  const routes = useMemo(() => routesByRoleSeparation(role, subRole), [role, subRole]);

  const savedPath = localStorage.getItem('lastRoute');
  const isSavedPath = savedPath && !getIsAllowedPaths(role, subRole, savedPath) && !savedPath.includes('auth');

  const redirectPath = isSavedPath ? savedPath : '/accounts';

  return (
    <main className="main-block__content-block">
      <CustomRouteWrapper
        routes={routes}
        redirect={redirectPath}
      />

      <Footer />
    </main>
  );
};

Content.propTypes = {
  role: PropTypes.string.isRequired,
  subRole: PropTypes.string.isRequired,
};

export default memo(Content);
