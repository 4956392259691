import * as yup from 'yup';

import { cloneDeep } from 'lodash';
import { setFieldsToReadOnly } from '../../helpers/other';
import { latinFieldWithNumbersAndPunctuationMarks } from '../../helpers/fieldsValidation';

export const beneficiary = {
  title: 'Beneficiary',
  fields: [
    'beneficiaryId',
    'bankAddress',
    'bankCity',
    'bankCountryId',
    'accountNumber',
    'bankCodeType',
    'bankCode',
    'swiftCode',
    'bankName',
    'file',
  ],
};

export const account = {
  title: 'Account',
  fields: [
    'ledgerId',
    'currencyId',
    'paymentCurrencyId',
    'balance',
    'convertedBalance',
    'amount',
    'reference',
    'postDate',
    'isInBeneficiaryCurrency',
    'isUrgent',

  ],
};

export const propertiesTable = [
  {
    id: 'beneficiaryId',
    name: 'beneficiaryId',
    label: 'Name',
    type: 'select',
    placeholder: 'Select Beneficiary',
    defaultSorting: true,
  },
  {
    id: 'bankAddress',
    name: 'bankAddress',
    label: 'Address',
    type: 'text',
    validation: true,
  },
  {
    id: 'bankCity',
    name: 'bankCity',
    label: 'City',
    placeholder: '',
    type: 'text',
  },
  {
    id: 'bankCountryId',
    name: 'bankCountryId',
    label: 'Country',
    placeholder: '',
    type: 'select',
  },
  {
    id: 'accountNumber',
    name: 'accountNumber',
    label: 'Account Number',
    type: 'text',
  },

  {
    id: 'bankCodeType',
    name: 'bankCodeType',
    label: 'Bank Code Type',
    type: 'select',
  },
  {
    id: 'bankCode',
    name: 'bankCode',
    label: 'Bank Code',
    type: 'text',
  },
  {
    id: 'swiftCode',
    name: 'swiftCode',
    label: 'swiftCode',
    type: 'text',
  },
  {
    id: 'bankName',
    name: 'bankName',
    label: 'Bank Name',
    type: 'text',
  },
  {
    id: 'ledgerId',
    name: 'ledgerId',
    label: 'Name',
    type: 'select',
    placeholder: 'Select Account',
    defaultSorting: true,
  },
  {
    id: 'currencyId',
    name: 'currencyId',
    label: 'Account Currency',
    placeholder: '',
    type: 'select',
  },
  {
    id: 'file',
    name: 'file',
    label: 'File',
    type: 'file',
  },
  {
    id: 'balance',
    name: 'balance',
    label: 'Account Balance',
    type: 'number',
  },
  {
    id: 'amount',
    name: 'amount',
    label: 'transfer Amount',
    placeholder: '',
    type: 'text',
  },
  {
    id: 'reference',
    name: 'reference',
    label: 'reference',
    type: 'text',
  },
  {
    id: 'postDate',
    name: 'postDate',
    label: 'post date',
    type: 'date',
  },
  {
    id: 'paymentCurrencyId',
    name: 'paymentCurrencyId',
    label: 'Payment currency',
    placeholder: '',
    type: 'select',
  },
  {
    id: 'status',
    name: 'status',
    label: 'Status',
    placeholder: '',
    type: 'text',
  },
  {
    id: 'isUrgent',
    name: 'isUrgent',
    label: 'is Urgent',
    type: 'checkbox',
  },
];
export const transactionPropertiesForFirstRender = setFieldsToReadOnly(cloneDeep(propertiesTable),
  ['beneficiaryId', 'ledgerId']);

export const transactionPropertiesForSameCurrency = setFieldsToReadOnly(cloneDeep(propertiesTable),
  ['beneficiaryId', 'ledgerId', 'reference', 'postDate', 'amount', 'status'],
  ['isUrgent']);

const differentCurrencyProperties = [
  {
    id: 'convertedBalance',
    name: 'convertedBalance',
    label: 'Account Balance',
    placeholder: '',
    type: 'text',
    hide: true,
  },
  {
    id: 'isInBeneficiaryCurrency',
    name: 'isInBeneficiaryCurrency',
    label: 'Send In Beneficiary Currency',
    placeholder: '',
    type: 'checkbox',
  },
];

export const transactionPropertiesForDifferentCurrency = setFieldsToReadOnly(
  cloneDeep([...propertiesTable, ...differentCurrencyProperties]),
  ['beneficiaryId', 'ledgerId', 'reference', 'postDate', 'amount', 'status'],
  ['isInBeneficiaryCurrency', 'isUrgent'],
);
// transactionPropertiesForDifferentCurrency,

export const createTransactionsValidationSchema = yup.object({
  beneficiaryId: yup.string()
    .required('Required!'),
  ledgerId: yup.string()
    .required('Required!'),
  amount: yup.number()
    .test('is-42', 'should be more than 1 and must not exceed the account balance', function (value) {
      const balance = this.resolve(yup.ref('isInBeneficiaryCurrency'))
        ? this.resolve(yup.ref('convertedBalance'))
        : this.resolve(yup.ref('balance'));

      return Math.abs(value) >= 1 && Math.abs(value) <= balance;
    })
    .required('Required!'),
  reference: latinFieldWithNumbersAndPunctuationMarks()
    .trim()
    .test('len', 'should be less than 30 characters', value => value && value.length <= 30),
});
