import React from 'react';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import transactionsActions from '../../actions/transactions';
import RangeCalendar from '../../components/Layouts/RangeCalendar';
import Search from '../../components/Layouts/Search';
import ExportData from '../../components/Layouts/ExportData';
import feesChargesActions from '../../actions/feesCharges';

const { getFeesCharges } = feesChargesActions.feesCharges;

const TransactionsHeader = ({
  rangeCalendarAction, onExport, canExport,
}) => (
  <>
    <div className="breadcrumbs-block">
      <div>Fees Charges</div>
    </div>

    <div className="data_tools">
      <RangeCalendar rangeCalendarAction={rangeCalendarAction} />

      <Search />

      <ExportData onExport={onExport} canExport={canExport} />
    </div>
  </>
);

export default connect(null, {
  rangeCalendarAction: getFeesCharges.action,
})(withImmutablePropsToJS(TransactionsHeader));
