import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import ListPage from '../../../components/Pages/ListPage';
import withLists from '../../../components/HOC/withLists';
import UsersHeader from '../../Headers/UsersHeader';

const mapStateToProps = (state) => {
  const currentState = state.getIn(['users', 'list']);

  return {
    data: currentState,
    header: UsersHeader,
    title: 'Users',
    defaultSorted: [{
      dataField: 'createdDate',
      order: 'desc',
    }],
  };
};

export default connect(mapStateToProps)(withLists(withImmutablePropsToJS(ListPage)));
