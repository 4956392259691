import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import ListPage from '../../../components/Pages/ListPage';

import withLists from '../../../components/HOC/withLists';
import UserActivityLogsHeader from '../../Headers/UserActivityLogsHeader';

const mapStateToProps = (state) => {
  const currentState = state.getIn(['users', 'activity']);

  return {
    data: currentState,
    header: UserActivityLogsHeader,
    title: 'User Activity Logs',
    pageWithId: true,
    defaultSorted: [{
      dataField: 'time',
      order: 'desc',
    }],
  };
};

export default connect(mapStateToProps)(withLists(withImmutablePropsToJS(ListPage)));
