import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';

import journalActions from '../../actions/journal';
import RangeCalendar from '../../components/Layouts/RangeCalendar';
import Search from '../../components/Layouts/Search';
import ExportData from '../../components/Layouts/ExportData';
import { datesFromPreviousMonth, datesFromPreviousMonthFormatted } from '../../constants/constants';

const { getJournal } = journalActions.journal;

const JournalHeader = ({
  rangeCalendarAction,
  onExport,
  canExport,
  datesForRequest,
  datesForCalendar,
}) => {
  useEffect(() => {
    rangeCalendarAction(datesForRequest);
  }, []);

  return (
    <>
      <div className="breadcrumbs-block">
        <div>Admin / Journal</div>
      </div>

      <div className="data_tools">
        <RangeCalendar
          rangeCalendarAction={rangeCalendarAction}
          customDefaultDate={datesForCalendar}
        />

        <Search />

        <ExportData onExport={onExport} canExport={canExport} />
      </div>
    </>
  );
};

JournalHeader.propTypes = {
  rangeCalendarAction: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  datesForRequest: PropTypes.shape({
    dateFromFormatted: PropTypes.string.isRequired,
    dateToFormatted: PropTypes.string.isRequired,
  }).isRequired,
  datesForCalendar: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date).isRequired,
    endDate: PropTypes.instanceOf(Date).isRequired,
  }).isRequired,
  canExport: PropTypes.bool.isRequired,
};

const mapStateToProps = () => ({
  datesForRequest: datesFromPreviousMonthFormatted(),
  datesForCalendar: datesFromPreviousMonth(),
});

export default connect(mapStateToProps, {
  rangeCalendarAction: getJournal.action,
})(withImmutablePropsToJS(JournalHeader));
