import { apiDownloadFile, makeSecureRequest } from './apiCallHandlers/index';

export default route => ({
  getTransactions: (params = {}) => {
    return makeSecureRequest('get', 'transactions', null, params);
  },
  processOneTransaction: (id = null) => {
    return makeSecureRequest('post', `Transactions/${id}/Execute`);
  },
  approveOneTransaction: (id = null) => {
    return makeSecureRequest('post', `Transactions/${id}/SetApprove`);
  },
  declineOneTransaction: ({ id = null, comment = ' ', userRole }) => {
    return makeSecureRequest(
      'put',
      `Transactions/${id}/${userRole === 'Admin' ? 'SetDeclined' : 'Cancel'}`,
      { value: comment || ' ' },
    );
  },
  getOneTransaction: id => makeSecureRequest('get', `Transactions/${id}`),
  getTransactionDetails: id => makeSecureRequest('get', `Transactions/Details/${id}`),
  getConvertedBalance: values => makeSecureRequest('get', 'transactions/ConvertedBalance', null, values),

  createTransaction: form => makeSecureRequest('post', 'transactions/', form),
  downloadInvoice: id => apiDownloadFile('get', `Transactions/Invoice/${id}/Download`),
  downloadBatchFile: payload => apiDownloadFile('get', 'Transactions/Batch/Download', null, payload),
  updateTransaction: (id, form) => makeSecureRequest('put', 'transactions/', form, { id }),
  deleteTransaction: id => makeSecureRequest('delete', 'transactions/', null, { id }),
});
