import {connect} from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import ListPage from '../../components/Pages/ListPage';
import UserActivityHeader from '../Headers/UserActivityHeader';
import userActivityActions from '../../actions/userActivity';
import { defaultDateRange } from '../../constants/constants';

const { getUserActivity } = userActivityActions.userActivity;
const { dateFromPreviousHoursFormatted, dateToWithHours } = defaultDateRange;

const mapStateToProps = (state) => {
  const currentState = state.getIn(['userActivity', 'list']);
  return {
    data: currentState,
    list: [],
    header: UserActivityHeader,
    title: 'User Activity',
    defaultSorted: [{
      dataField: 'requestDate',
      order: 'desc',
    }],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchGetData: () => dispatch(getUserActivity.action({
      startDate: new Date(dateFromPreviousHoursFormatted),
      endDate: new Date(dateToWithHours),
    })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withImmutablePropsToJS(ListPage));
