import React, { useCallback, useRef } from 'react';
import classNames from 'classnames';
import { withScriptjs } from 'react-google-maps';
import StandaloneSearchBox from 'react-google-maps/lib/components/places/StandaloneSearchBox';
import PropTypes from 'prop-types';

import { parseGeoLocation } from '../../helpers/parseGeoLocation';
import Tooltip from './Tooltip';

const SearchBox = (props) => {
  const {
    name,
    id,
    error,
    setGeoModalVisibility,
    setPosition,
    onBlurPost,
    onChange,
    changeLocationData,
    placeholder,
    value,
    propsValue,
    readOnly,
  } = props;

  const searchBox = useRef();

  // let timerId = null;

  const submitWithAutoComplete = useCallback(() => {
    const place = searchBox.current.getPlaces()[0];
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    // const locationData = await getLocationData(lat, lng);
    changeLocationData(parseGeoLocation(place));
    setPosition({
      lat,
      lng,
    });
  }, [searchBox]);

  const submitHandler = useCallback(() => {
    onBlurPost({ name, value });
  }, [value]);

  // const onPlacesChanged = useCallback(type => () => {
  //   if (timerId) {
  //     clearTimeout(timerId);
  //     timerId = null;
  //   }
  //
  //   if (type === 'autoComplete') {
  //     submitWithAutoComplete();
  //   }
  //
  //   if (type === 'onBlur' && onBlurPost) {
  //     timerId = setTimeout(() => {
  //       submitHandler();
  //
  //       timerId = null;
  //     }, 300);
  //   }
  // }, [searchBox, submitHandler, submitWithAutoComplete]);

  const changeValue = useCallback((e) => {
    onChange(e.target.value);
  }, []);

  return (
    <>
      <If condition={!readOnly}>
        <StandaloneSearchBox
          ref={searchBox}
          onPlacesChanged={submitWithAutoComplete}
        >
          <div className="geo-input_wrapper">
            <input
              className={classNames('basic-form__group__block__control input', { error: !!error })}
              id={id}
              name={name}
              value={value}
              onChange={changeValue}
              placeholder={placeholder}
              // onBlur={onPlacesChanged('onBlur')}
            />
            <If condition={onBlurPost && value !== propsValue}>
              <i
                className="iconfont-save-file save-file"
                onClick={submitHandler}
                data-tip="save-file"
                data-for="save-file"
              />
              <Tooltip id="save-file" text="Save current address" />
            </If>
            <i className="iconfont-marker marker" onClick={() => setGeoModalVisibility(true)} />
          </div>
        </StandaloneSearchBox>
      </If>
      <If condition={readOnly}>
        <div className="geo-input_wrapper">
          <input
            className={classNames('basic-form__group__block__control input', { error: !!error })}
            id={id}
            name={name}
            value={value}
            placeholder={placeholder}
            readOnly
          />
        </div>
      </If>
    </>
  );
};

SearchBox.defaultProps = {
  error: null,
  lists: {},
  name: null,
  id: null,
  onBlurPost: null,
};

SearchBox.propTypes = {
  lists: PropTypes.shape({}),
  name: PropTypes.string,
  id: PropTypes.string,
  error: PropTypes.string,
  setGeoModalVisibility: PropTypes.func.isRequired,
  setPosition: PropTypes.func.isRequired,
  changeLocationData: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlurPost: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  propsValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

export default withScriptjs(SearchBox);
