import React, { useState, useEffect, useMemo } from 'react';
import config from 'config';
import PropTypes from 'prop-types';
import { isEmpty, pick } from 'lodash';

import ModalGeoMap from '../Modals/ModalGeoMap';
import GeoSearchInput from './GeoSearchInput';
import AnimatedSpinner from '../Spinners/AnimatedSpinner';

const { GOOGLE_MAP_URL, DEFAULT_COORDINATES, DEFAULT_MAP_ZOOM } = config;

const GeoInput = (props) => {
  const {
    onChange,
    placeholder,
    value,
    propsValue,
    name,
    values: formValues,
    additionalHandler,
    setValues,
    relativeFieldsForGeo,
    lists,
    onBlurPost,
    isOnBlur,
    readOnly,
  } = props;
  const [position, setPosition] = useState(DEFAULT_COORDINATES);
  const [zoom, setZoom] = useState(DEFAULT_MAP_ZOOM);
  const [isModalOpen, setGeoModalVisibility] = useState(false);

  const isAllListsLoading = useMemo(
    () => {
      let currentLists = relativeFieldsForGeo.filter(i => i.hasOwnProperty('listId'));
      if (!isEmpty(currentLists)) currentLists = pick(lists, currentLists.map(i => i.key));

      if (!isEmpty(currentLists)) {
        return Object.values(currentLists).every(list => !isEmpty(list));
      }
      return false;
    },
    [lists],
  );

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(({ coords }) => {
        setPosition({
          lat: coords.latitude,
          lng: coords.longitude,
        });
      });
      setZoom(14);
    }
  }, []);

  const changeLocationData = (data) => {
    const values = {};
    const setNewData = (key, val) => {
      if (isOnBlur) {
        onBlurPost({
          name: key,
          value: val,
        });
      } else if (additionalHandler) {
        additionalHandler({ name: key, value: val });
      }
      values[key] = val;
    };

    relativeFieldsForGeo.forEach(({
      key, geoKey, listId = null, customFormatter,
    }) => {
      if (listId && !customFormatter) {
        const list = lists[listId];

        if (list) {
          const listValue = Object.entries(list)
            .find(it => it[1] === data[geoKey]);

          const finalValue = listValue ? listValue[0] : '';

          setNewData(key, finalValue);
        }
      } else if (customFormatter) {
        // TODO: MAKE THIS FUNCTION UNIVERSAL
        const finalValue = customFormatter({ ...formValues, ...values },
          lists.currencyId, lists.sepaCountries);

        setNewData(key, finalValue);
      } else {
        setNewData(key, data[geoKey]);
      }
    });
    setValues(values);
  };

  return (
    <>
      <If condition={isAllListsLoading}>
        <GeoSearchInput
          setGeoModalVisibility={setGeoModalVisibility}
          setPosition={setPosition}
          onChange={onChange}
          changeLocationData={changeLocationData}
          onBlurPost={onBlurPost}
          value={value}
          propsValue={propsValue}
          name={name}
          placeholder={placeholder}
          googleMapURL={GOOGLE_MAP_URL}
          loadingElement={<input className="basic-form__group__block__control input" />}
          containerElement={<div />}
          readOnly={readOnly}
        />
        <If condition={!readOnly}>
          <ModalGeoMap
            setPosition={setPosition}
            changeLocationData={changeLocationData}
            position={position}
            isModalOpen={isModalOpen}
            setGeoModalVisibility={setGeoModalVisibility}
            zoom={zoom}
          />
        </If>
      </If>
      <AnimatedSpinner fetching={!isAllListsLoading} />
    </>
  );
};

GeoInput.defaultProps = {
  onBlurPost: null,
  additionalHandler: null,
  isOnBlur: null,
  lists: {},
  values: {},
};

GeoInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  propsValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  name: PropTypes.string.isRequired,
  additionalHandler: PropTypes.func,
  setValues: PropTypes.func.isRequired,
  relativeFieldsForGeo: PropTypes.arrayOf(
    PropTypes.object,
  ).isRequired,
  readOnly: PropTypes.bool.isRequired,
  onBlurPost: PropTypes.func,
  values: PropTypes.shape({}),
  isOnBlur: PropTypes.func,
  lists: PropTypes.objectOf(
    PropTypes.object,
  ),
};

export default GeoInput;
