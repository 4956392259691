import React, { useCallback, memo } from 'react';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';

import modalActions from '../../actions/modal';
import Button from '../../components/generic/Button';
import SearchContainer from './SearchContainer';

const UniversalModalButton = (props) => {
  const {
    openModal,
    setModalFormInfo,
    modalId,
    chosenItemId,
    text,
    inlineButton,
    className,
    customIcon,
    iconClasses,
    width,
    ...rest
  } = props;

  const handleClick = useCallback(() => {
    openModal();
    setModalFormInfo({ modalId, chosenItemId, width });
  }, [modalId, chosenItemId, width]);

  return (
    <Choose>
      <When condition={inlineButton}>
        <Choose>
          <When condition={customIcon}>
            <i className={iconClasses} onClick={handleClick} />
          </When>

          <Otherwise>
            <SearchContainer
              text={text}
              onClick={handleClick}
              className={className}
              {...rest}
            />
          </Otherwise>
        </Choose>
      </When>

      <Otherwise>
        <Button
          text={text}
          onClick={handleClick}
          color="green"
          // type="primary"
          size="sm"
          {...rest}
        />
      </Otherwise>
    </Choose>
  );
};

const mapDispatchToProps = (dispatch) => {
  const { openModal, setModalFormInfo } = modalActions;

  return {
    openModal: () => dispatch(openModal()),
    setModalFormInfo: info => dispatch(setModalFormInfo(info)),
  };
};

UniversalModalButton.defaultProps = {
  modalId: '',
  chosenItemId: null,
  text: '+',
  inlineButton: false,
  className: 'modal-button',
  customIcon: false,
  iconClasses: '',
  width: 0,
};
UniversalModalButton.propTypes = {
  openModal: PropTypes.func.isRequired,
  setModalFormInfo: PropTypes.func.isRequired,
  modalId: PropTypes.string,
  chosenItemId: PropTypes.string,
  text: PropTypes.string,
  inlineButton: PropTypes.bool,
  className: PropTypes.string,
  customIcon: PropTypes.bool,
  iconClasses: PropTypes.string,
  width: PropTypes.number,
};

export default connect(null, mapDispatchToProps)(withImmutablePropsToJS(memo(UniversalModalButton)));
