import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { Tabs, Tab } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import { toastr } from 'react-redux-toastr';

import Button from '../../components/generic/Button';
import AccountSetupStep from '../../components/Pages/AccountSetup/AccountSetupStep';

const OwnershipTabs = ({
  location,
  history,
  propsData,
  updateField,
  createContact,
  deleteContact,
  withRemovingPermission,
  ownershipFields,
  ownershipValidationSchema,
  ...props
}) => {
  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    if (isEmpty(propsData)) {
      return;
    }

    if (!activeTab || propsData.find(item => item.id === activeTab) === undefined) {
      setActiveTab(propsData[0].id);
    }
  }, [propsData]);

  const onBlurPost = useCallback((values, id) => {
    updateField({
      ...values,
      objectId: id,
    });
  }, []);

  const confirmText = 'Would you like delete this contact?';

  const confirmDeleteContact = id => toastr.confirm(confirmText, {
    onOk: () => deleteContact(id),
  });

  return (
    <>
      <Choose>
        <When condition={withRemovingPermission}>
          <Button
            text="+"
            onClick={createContact}
            color="green"
            type="primary"
            size="sm"
            className="nav-item nav-tabs tabs-button"
          />
        </When>
        <Otherwise>
          <Button
            text="+"
            color="green"
            type="primary"
            size="sm"
            className="nav-item nav-tabs tabs-button disabled"
          />
        </Otherwise>
      </Choose>
      <Tabs
        activeKey={activeTab}
        className="account-setup_navigation second-tabs"
        id={activeTab}
        onSelect={key => setActiveTab(key)}
      >
        {propsData.map((values) => {
          const { firstName, lastName, id } = values;

          const updateFields = val => onBlurPost(val, id);

          return (
            <Tab
              key={id}
              eventKey={id}
              title={(`${firstName || 'n/a'} ${lastName || 'n/a'}`)}
            >
              <If condition={withRemovingPermission}>
                <i
                  className="delete-contact iconfont-close clickable"
                  onClick={() => confirmDeleteContact(id)}
                />
              </If>
              <AccountSetupStep
                {...props}
                formName={id}
                customClass="outgoing-activity"
                propsData={values}
                updateField={updateFields}
              />
            </Tab>
          );
        })}

      </Tabs>
    </>
  );
};

OwnershipTabs.defaultProps = {
  propsData: [
    {
      id: '',
    },
  ],
};

const mapStateToProps = (state) => {
  const { countries, typesOfOwnership } = state.get('lists')
    .toJS();

  return {
    lists: {
      countryId: countries.data,
      typeOfOwnershipId: typesOfOwnership.data,
    },
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: (form) => {
    ownProps.nextTab();
  },
});

export default connect(mapStateToProps,
  mapDispatchToProps)(withImmutablePropsToJS(withRouter(OwnershipTabs)));
