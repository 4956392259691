import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import Dashboard from '../../../components/Pages/Dashboard/transactions/TransactionsDashboard';
import withLists from '../../../components/HOC/withLists';
import listsActions from '../../../actions/lists';

const {
  getCurrencies,
  getLedgers,
  getCustomersList,
} = listsActions.lists;

const mapStateToProps = (state) => {
  const {
    currencies,
    ledgers,
    customers,
  } = state.get('lists')
    .toJS();

  const fetching = state.getIn(['dashboard', 'options', 'fetching']);

  return {
    fetching,
    requiredLists: [
      {
        data: currencies.data,
        action: getCurrencies.action,
      },
      {
        data: ledgers.data,
        action: getLedgers.action,
      },
      {
        data: customers.data,
        action: getCustomersList.action,
      },
    ],
  };
};

export default connect(mapStateToProps)(withLists(withImmutablePropsToJS(Dashboard)));
