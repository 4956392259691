import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import MenuItem from './MenuItem';
import MenuItemCollapsible from './MenuItemCollapsible';

const MenuItems = ({ items, role, subRole }) => items.map(({
  isParent, withChildren, parentId, id, path, ...rest
}) => (
  <Choose>
    <When condition={isParent && withChildren}>
      <li key={id}>
        <MenuItemCollapsible
          path=""
          id={id}
          role={role}
          subRole={subRole}
          isParent={isParent}
          {...rest}
        />
      </li>
    </When>
    <When condition={!parentId}>
      <li key={id}>
        <NavLink to={path} activeClassName="active">
          <MenuItem
            isParent={isParent}
            {...rest}
          />
        </NavLink>
      </li>
    </When>
  </Choose>
));

// const MenuItems = ({ items, ...rest }) => console.log(items, rest);

MenuItems.defaultProps = {
  path: '',
  id: '',
  isParent: false,
  withChildren: false,
  parentId: '',
};

MenuItems.propTypes = {
  id: PropTypes.string,
  path: PropTypes.string,
  isParent: PropTypes.bool,
  withChildren: PropTypes.bool,
  parentId: PropTypes.string,
};

export default memo(MenuItems);
