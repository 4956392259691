import * as yup from 'yup';

import { latinFieldWithNumbersAndPunctuationMarks } from '../../helpers/fieldsValidation';

export const newAccountFields = [
  {
    id: 'platformId',
    name: 'platformId',
    label: 'Bank',
    placeholder: 'Select Bank',
    type: 'select',
    defaultSorting: true,
    required: true,
    dependedFunctionForFields: (fields, value, { platformId }) => {
      const bankValue = platformId[value];
      const isAccountNameHide = fields.find(field => field.id === 'accountNumber').hide;

      if (bankValue !== 'CurrencyCloud' && isAccountNameHide) {
        return fields.map(field => (field.id === 'accountNumber'
          ? { ...field, hide: false }
          : field));
      }
      if (bankValue === 'CurrencyCloud' && !isAccountNameHide) {
        return fields.map(field => (field.id === 'accountNumber'
          ? { ...field, hide: true }
          : field));
      }
      return fields;
    },
  },
  {
    id: 'enduserId',
    name: 'enduserId',
    label: 'Customer',
    placeholder: 'Select Customer',
    type: 'select',
    defaultSorting: true,
    required: true,
  },
  {
    id: 'name',
    name: 'name',
    label: 'Name',
    placeholder: '',
    type: 'text',
    defaultSorting: true,
    required: true,
  },
  {
    id: 'accountNumber',
    name: 'accountNumber',
    label: 'Account Number',
    placeholder: '',
    type: 'text',
    defaultSorting: true,
    required: true,
    hide: true,
  },
  {
    id: 'currencyId',
    name: 'currencyId',
    label: 'Currency',
    placeholder: 'Select Currency',
    type: 'select',
    defaultSorting: true,
    required: true,
  },
  {
    id: 'ledgerType',
    name: 'ledgerType',
    label: 'Ledger Type',
    placeholder: 'Select Ledger Type',
    type: 'select',
    defaultSorting: true,
    required: true,
  },
];

const checkBank = (platformId, banks) => banks[platformId] !== 'CurrencyCloud';

const accountNumberSchema = banks => yup.string().when('platformId',
  (platformId, schema) => {
    if (checkBank(platformId, banks)) {
      return schema.required('Required!');
    }
    return schema;
  });

export const newAccountValidationSchema = banks => yup.object({
  platformId: yup.string().required('Required!'),
  enduserId: yup.string().required('Required!'),
  name: latinFieldWithNumbersAndPunctuationMarks().trim().required('Required!'),
  currencyId: yup.string().required('Required!'),
  accountNumber: accountNumberSchema(banks),
  ledgerType: yup.string().required('Required!'),
});
