import { logics as userLogics } from '../user';
import { logics as accountsLogics } from '../accounts';
import { logics as UserActivityLogics } from '../userActivity';
import { logics as transactionsLogics } from '../transactions';
import { logics as beneficiariesLogics } from '../beneficiaries';
import { logics as journalLogics } from '../journal';
import { logics as listsLogics } from '../lists';
import { logics as reportsLogics } from '../reports';
import { logics as feesLogics } from '../fees';
import { logics as feesChargesLogics } from '../feesCharges';
import { logics as registrationLogics } from '../registration';
import { logics as processingErrorsLogics } from '../processingErrors';
import { logics as customersLogics } from '../customers';
import { logics as adminUserLogics } from '../adminUser';
import { logics as dashboardLogics } from '../dashboard';

export const commonLogics = [
  ...accountsLogics,
  ...journalLogics,
  ...transactionsLogics,
  ...UserActivityLogics,
  ...beneficiariesLogics,
  ...listsLogics,
  ...userLogics,
  ...reportsLogics,
  ...feesLogics,
  ...feesChargesLogics,
  ...registrationLogics,
  ...processingErrorsLogics,
  ...customersLogics,
  ...adminUserLogics,
  ...dashboardLogics,
];
