import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import React from 'react';

const Tooltip = ({ id, text }) => (
  <ReactTooltip
    id={id}
    place="bottom"
    type="light"
    className="styled-tooltip"
  >
    {text}
  </ReactTooltip>
);

Tooltip.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default Tooltip;
