import { combineActions, handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import { combineReducers } from 'redux-immutable';
import { isEmpty } from 'lodash';

import transactionsActions from '../actions/transactions';
import beneficiariesActions from '../actions/beneficiaries';
import userActions from '../actions/user/userActions';

const { logout } = userActions;

const {
  getTransactions,
  getOneTransaction,
  getTransactionDetails,
  selectTransaction,
  selectAllTransactions,
  clearAllTransactions,
  processOneTransaction,
  approveOneTransaction,
  declineOneTransaction,
  createTransaction,
} = transactionsActions.transactions;

const { createBeneficiaryOnPlatform } = beneficiariesActions.beneficiaries;

const initialState = fromJS({
  list: {
    data: [],
    markedItemId: null,
    error: false,
    fetching: false,
  },
  one: {
    data: {},
    error: false,
    fetching: false,
  },
  details: {
    data: {
      beneficiaryName: 'string',
      beneficiaryAddress: 'string',
      beneficiaryCity: 'string',
      beneficiaryCountry: 'string',
      beneficiaryAccountNumber: 'string',
      paymentCurrency: 'string',
      reference: 'string',
      postDate: '2020-05-19T12:07:38.504Z',
      accountName: 'string',
      beneficiaryBankName: 'string',
      accountAddress: 'string',
      accountCity: 'string',
      accountCountry: 'string',
      accountNumber: 'string',
      swiftCode: 'string',
      bankCode: 'string',
      transferAmount: 0,
      sendInBeneficiaryCurrency: true,
      isUrgent: true,
      status: 'string',
    },
    error: false,
    fetching: false,
  },
  transactionsStatusChanging: {
    selected: [],
    inProcessing: [],
    fetching: false,
  },
});

const transactionsReducer = handleActions(
  {
    [combineActions(
      getTransactions.fetching,
    )]: state => state
      .set('fetching', true)
      .set('error', false),
    [combineActions(
      getTransactions.done,
      getTransactions.failure,
      getTransactions.error,
    )]: state => state
      .set('fetching', false)
      .set('error', true),
    [getTransactions.success]: (state, { payload }) => {
      const markedItemId = state.get('markedItemId');

      if (markedItemId) {
        const response = payload.map((item) => {
          return item.id === markedItemId ? {
            ...item,
            marked: true,
          } : item;
        });

        return state
          .set('data', fromJS(response))
          .set('fetching', false);
      }

      return state
        .set('data', fromJS(payload))
        .set('fetching', false);
    },
    [createBeneficiaryOnPlatform.success]: (state, { payload }) => {
      const { transactionId = '', transactionDeclineReason = '' } = payload;
      const itemIndex = state.get('data')
        .findKey(obj => obj.get('id') === transactionId);

      return state.setIn(['data', itemIndex, 'declineReason'], fromJS(transactionDeclineReason));
    },
    [combineActions(
      approveOneTransaction.success,
      processOneTransaction.success,
      declineOneTransaction.success,
    )]: (state, { payload }) => {
      const itemIndex = state.get('data')
        .findKey(obj => obj.get('id') === payload.id);

      if (itemIndex) {
        const currentItem = state.get('data')
          .get(itemIndex)
          .toJS();

        return state.setIn(['data', itemIndex], fromJS({ ...currentItem, ...payload }));
      }
      return state;
    },
    [combineActions(
      approveOneTransaction.success,
      processOneTransaction.success,
      declineOneTransaction.success,
    )]: (state, { payload }) => {
      const itemIndex = state.get('data')
        .findKey(obj => obj.get('id') === payload.id);

      return state.setIn(['data', itemIndex], fromJS({
        ...payload,
        marked: true,
      }));
    },
    [createTransaction.success]: (state, { payload }) => state.set('markedItemId', fromJS(payload.id)),
    [logout]: () => initialState.get('list'),
  },
  initialState.get('list'),
);

const oneTransactionReducer = handleActions(
  {
    [combineActions(
      getOneTransaction.fetching,
      createTransaction.fetching,
    )]: state => state
      .set('fetching', true)
      .set('error', false),
    [combineActions(
      getOneTransaction.failure,
      getOneTransaction.error,
      createTransaction.failure,
      createTransaction.error,
    )]: state => state
      .set('fetching', false)
      .set('error', true),
    [combineActions(
      getOneTransaction.done,
      getOneTransaction.success,
    )]: (state, { payload }) => state.set('data', fromJS(payload))
      .set('fetching', false),
    [[createTransaction.success]]: (state) => state.set('fetching', false),
    [logout]: () => initialState.get('one'),
  },
  initialState.get('one'),
);

const transactionDetailsReducer = handleActions(
  {
    [combineActions(
      getTransactionDetails.fetching,
    )]: state => state.set('fetching', true)
      .set('error', false),
    [combineActions(
      getTransactionDetails.failure,
      getTransactionDetails.error,
    )]: state => state
      .set('fetching', false)
      .set('error', true),
    [combineActions(
      getTransactionDetails.done,
      getTransactionDetails.success,
    )]: (state, { payload }) => state.set('data', fromJS(payload))
      .set('fetching', false),
    [logout]: () => initialState.get('details'),
  },
  initialState.get('details'),
);

const transactionsStatusChangingReducer = handleActions(
  {
    [selectTransaction]: (state, { payload }) => {
      const { addNew = false, key = '' } = payload;
      const currentState = state.get('selected')
        .toJS();

      if (addNew) {
        if (currentState.indexOf(key) !== -1) {
          return state;
        }
        return state.set('selected', fromJS([...currentState, key]));
      } else {
        return state.set('selected', fromJS(currentState.filter(id => key !== id)));
      }
    },
    [selectAllTransactions]: (state, { payload }) => {
      const { addNew = false, transactionsIds = [] } = payload;
      const currentSelectedState = state.get('selected')
        .toJS();

      if (addNew) {
        return state.set('selected', fromJS([...currentSelectedState, ...transactionsIds]));
      } else {
        const newSelectedState = currentSelectedState.filter(id => transactionsIds.indexOf(id) === -1);

        return state.set('selected', fromJS(newSelectedState));
      }
    },
    [clearAllTransactions]: state => state.set('selected', fromJS([])),
    [combineActions(
      processOneTransaction.fetching,
      approveOneTransaction.fetching,
      createBeneficiaryOnPlatform.fetching,
    )]: (state, { payload }) => {
      const currentState = state.get('inProcessing')
        .toJS();
      const newSelectedState = state.get('selected')
        .toJS()
        .filter(id => payload !== id);

      if (currentState.indexOf(payload) !== -1) {
        return state.set('selected', fromJS(newSelectedState))
          .set('fetching', true);
      }
      return state
        .set('inProcessing', fromJS([...currentState, payload]))
        .set('selected', fromJS(newSelectedState))
        .set('fetching', true);
    },
    [declineOneTransaction.fetching]: (state, { payload }) => {
      const currentState = state.get('inProcessing')
        .toJS();
      const newSelectedState = state.get('selected')
        .toJS()
        .filter(id => payload !== id);

      if (currentState.indexOf(payload) !== -1) {
        return state.set('selected', fromJS(newSelectedState))
          .set('fetching', true);
      }
      return state
        .set('inProcessing', fromJS([...currentState, payload.id]))
        .set('selected', fromJS(newSelectedState))
        .set('fetching', true);
    },
    [combineActions(
      processOneTransaction.success,
      declineOneTransaction.success,
      createBeneficiaryOnPlatform.success,
    )]: (state, { payload }) => {
      const newProcessingState = state.get('inProcessing')
        .toJS()
        .filter(id => payload.id !== id);

      if (isEmpty(newProcessingState)) {
        return state.set('inProcessing', fromJS(newProcessingState))
          .set('fetching', false);
      }
      return state.set('inProcessing', fromJS(newProcessingState));
    },
    [combineActions(
      processOneTransaction.failure,
      createBeneficiaryOnPlatform.failure,
      processOneTransaction.error,
      createBeneficiaryOnPlatform.error,
    )]: (state, { payload }) => {
      const { errorPayload } = payload;
      const newProcessingState = state.get('inProcessing')
        .toJS()
        .filter(id => errorPayload !== id);

      if (isEmpty(newProcessingState)) {
        return state.set('inProcessing', fromJS(newProcessingState))
          .set('fetching', false);
      }
      return state.set('inProcessing', fromJS(newProcessingState));
    },
    [combineActions(
      declineOneTransaction.failure,
      declineOneTransaction.error,
    )]: (state, { payload }) => {
      const { errorPayload } = payload;
      const newProcessingState = state.get('inProcessing')
        .toJS()
        .filter(id => errorPayload.id !== id);

      if (isEmpty(newProcessingState)) {
        return state.set('inProcessing', fromJS(newProcessingState))
          .set('fetching', false);
      }
      return state.set('inProcessing', fromJS(newProcessingState));
    },
    [logout]: () => initialState.get('transactionsStatusChanging'),
  },
  initialState.get('transactionsStatusChanging'),
);

export default combineReducers({
  list: transactionsReducer,
  one: oneTransactionReducer,
  details: transactionDetailsReducer,
  transactionsStatusChanging: transactionsStatusChangingReducer,
});
