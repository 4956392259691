import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

// import adminUserActions from '../../actions/adminUser';
import listsActions from '../../../actions/lists';
import withLists from '../../../components/HOC/withLists';
import MessagesPage from '../../../components/Pages/Messages/MessagesPage';
import MessagesHeader from '../../Headers/MessagesHeader';

// const {
//   getAdminUsers,
// } = adminUserActions.adminUser;
const {
  getLedgerStatuses,
} = listsActions.lists;

const mapStateToProps = (state) => {
  const currentState = state.getIn(['messages', 'list']);

  return {
    fetching: currentState.get('fetching'),
    // requiredLists: [
    //   {
    //     data: [],
    //     action: getLedgerStatuses.action,
    //   },
    // ],
    header: MessagesHeader,
    title: 'Messages',
  };
};

export default connect(mapStateToProps, null)(withLists(MessagesPage));
