import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import adminUserActions from '../../actions/adminUser';
import { AdminUserStatusHelper } from '../../lib/statusHelper/statusHelper';
import PendingFormatter from '../../lib/statusFormatters/PendingFormatter';

const { changeAdminUserStatus } = adminUserActions.adminUser;

const mapStateToProps = (state) => {
  return {
    requestData: state.getIn(['customers', 'withChangedStatus']),
    component: AdminUserStatusHelper,
    userRole: state.getIn(['userInfo', 'data', 'role']),
    subRole: state.getIn(['userInfo', 'data', 'subRole']),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    processAction: payload => dispatch(changeAdminUserStatus.action({
      ...payload,
      statusId: 6,
    })),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withImmutablePropsToJS(PendingFormatter));
