import {
  sortHeaderFormatter,
  dateFormatter,
  defaultFormatter,
} from '../tableUtils/tablesUtils';

import { convertDateToLocaleFormat } from '../../helpers/dates';

const userActivityLogsColumns = () => ([
  {
    dataField: 'activityType',
    text: 'Activity Type',
    sort: true,
    formatter: defaultFormatter,
    headerFormatter: sortHeaderFormatter,
    headerStyle: () => ({ width: '25%' }),
    wpx: 140,
  },
  {
    dataField: 'time',
    text: 'Time',
    sort: true,
    formatter: (cell, { id }) => dateFormatter(cell, id),
    headerFormatter: sortHeaderFormatter,
    headerStyle: () => ({ width: '10%' }),
    transformationItem: convertDateToLocaleFormat,
    wpx: 120,
  },
  {
    dataField: 'ownership',
    text: 'Ownership',
    sort: true,
    classes: 'main-field',
    formatter: defaultFormatter,
    headerFormatter: sortHeaderFormatter,
    headerStyle: () => ({ width: '15%' }),
    wpx: 120,
  },
  {
    dataField: 'description',
    text: 'Description',
    sort: true,
    formatter: defaultFormatter,
    headerFormatter: sortHeaderFormatter,
    headerStyle: () => ({ width: '50%' }),
    wpx: 140,
  },
]);

export default userActivityLogsColumns;
