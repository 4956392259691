import {connect} from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import ListPage from '../../components/Pages/ListPage';
import ProcessingErrorHeader from '../Headers/ProcessingErrorHeader';

import processingErrorsActions from '../../actions/processingErrors';

const { getProcessingErrors } = processingErrorsActions.processingErrors;

const mapStateToProps = (state) => {
  const currentState = state.getIn(['processingErrors', 'list']);
  return {
    data: currentState,
    list: [],
    header: ProcessingErrorHeader,
    title: 'Processing Errors',
    defaultSorted: [{
      dataField: 'createdAt',
      order: 'desc',
    }],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchGetData: () => dispatch(getProcessingErrors.action()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withImmutablePropsToJS(ListPage));
