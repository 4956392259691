import { combineActions, handleActions } from 'redux-actions';
import { fromJS } from 'immutable';

import { combineReducers } from 'redux-immutable';

import feesActions from '../actions/fees';
import listsActions from '../actions/lists';
import modalActions from '../actions/modal';

const { cleanModalData } = modalActions;

const {
  changeDefaultTemplate,
  cleanDefaultTemplate,
  getFeesByLedgerId,
  changeFeesTemplateForLedgerById,
  changeFeesFieldsForLedgerById,
} = feesActions.fees;

const { getFeesTemplates } = listsActions.lists;

const initialState = fromJS({
  ledgerFees: {
    error: false,
    fetching: false,
    ledgerId: '',
    values: {},
    defaultTemplate: '',
  },
  fetching: false,
});


const ledgerFeesReducer = handleActions(
  {
    [changeDefaultTemplate]: (state, { payload }) => state
      .set('defaultTemplate', fromJS(payload)),
    [cleanDefaultTemplate]: state => state
      .set('defaultTemplate', fromJS(null)),
    [combineActions(
      getFeesByLedgerId.fetching,
      changeFeesTemplateForLedgerById.fetching,
    )]: state => {
      return state.set('fetching', true);
    },
    [combineActions(
      changeFeesTemplateForLedgerById.failure,
      changeFeesTemplateForLedgerById.error,
      changeFeesTemplateForLedgerById.done,
      getFeesByLedgerId.failure,
      getFeesByLedgerId.error,
      getFeesByLedgerId.done,
    )]: state => state.set('fetching', false)
      .set('error', true),
    [combineActions(
      changeFeesTemplateForLedgerById.success,
    )]: (state, {payload: { templateRateDictionary }}) => {
      return state.set('fetching', false).set('values', templateRateDictionary).set('defaultTemplate', fromJS(null));
    },
    [cleanModalData]: state => {
      return state.set('values', {}).set('defaultTemplate', fromJS(null));
    },
    [combineActions(
      changeFeesFieldsForLedgerById.success,
    )]: (state, { payload: { feeName, feeValue } }) => {
      return state.setIn(['values', feeName], feeValue);
    },
    [combineActions(
      getFeesByLedgerId.success,
    )]: (state, { payload }) => state.set('values', fromJS(payload))
      .set('fetching', false),
  },
  initialState.get('ledgerFees'),
);

const fetching = handleActions(
  {
    [getFeesTemplates.fetching]: () => fromJS(true),
    [combineActions(
      getFeesTemplates.failure,
      getFeesTemplates.error,
      getFeesTemplates.success,
    )]:() => fromJS(false)
  },
  initialState.get('fetching'),
);

export default combineReducers({
  // accounts: accountsReducer,
  ledgerFees: ledgerFeesReducer,
  fetching,
});
