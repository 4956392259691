import { makeSecureRequest } from '../apiCallHandlers';
import { defaultDateRange } from '../../constants/constants';

export default route => ({
  getProcessingErrors: (payload = {}) => {
    const {
      dateFromFormatted = defaultDateRange.dateFromFormatted,
      dateToFormatted = defaultDateRange.dateToFormatted,
    } = payload;

    return makeSecureRequest('get', 'Report/ProcessingErrors', null, {
      dateFrom: dateFromFormatted,
      dateTo: dateToFormatted,
    });
  },
});
