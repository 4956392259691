import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';

import { combineReducers } from 'redux-immutable';
import userActions from '../actions/user/userActions';

// const { logout } = userActions;

const initialState = fromJS({
  list: {
    chatList: [
      {
        chatId: '321123',
        lastMessages: 'Thanks, wait a couple of minutes, I\'ll check the transaction',
        postDate: '2020-10-20T07:03:00',
        lastName: 'Hoekstra',
        firstName: 'Karl',
        topic: 'Transaction issue',
        unReadMessages: 2,
        status: 'Open',
      },
      {
        chatId: '123321',
        lastMessages: 'Hello, I can not check my journal.',
        postDate: '2020-09-30T10:03:00',
        lastName: 'Hoekstra',
        firstName: 'Karl',
        topic: 'Journal issue',
        unReadMessages: null,
        status: 'Closed',
      },
      {
        chatId: '123123',
        lastMessages: 'Hello, I can not check my journal.',
        postDate: '2020-09-30T10:03:00',
        lastName: 'Hoekstra',
        firstName: 'Karl',
        topic: 'Journal issue',
        unReadMessages: '100',
        status: 'Open',
      },
    ],
    fetching: false,
  },
  unReadChatList: {
    chatList: [
      {
        chatId: '321123',
        lastMessages: 'Thanks, wait a couple of minutes, I\'ll check the transaction',
        postDate: '2020-10-20T07:03:00',
        lastName: 'Hoekstra',
        firstName: 'Karl',
        topic: 'Transaction issue',
        unReadMessages: 2,
        status: 'Open',
      },
      {
        chatId: '123321',
        lastMessages: 'Hello, I can not check my journal.',
        postDate: '2020-09-30T10:03:00',
        lastName: 'Hoekstra',
        firstName: 'Karl',
        topic: 'Journal issue',
        unReadMessages: null,
        status: 'Closed',
      },
      {
        chatId: '123123',
        lastMessages: 'Hello, I can not check my journal.',
        postDate: '2020-09-30T10:03:00',
        lastName: 'Hoekstra',
        firstName: 'Karl',
        topic: 'Journal issue',
        unReadMessages: '100',
        status: 'Open',
      },
    ],
    unReadMess: 5,
    fetching: false,
  },
  one: {
    fetching: false,
    data: {
      chatId: '321123',
      status: 'Open',
      messages: [
        {
          text: 'Thanks, wait a couple of minutes, I\'ll check the transaction',
          userName: 'Admin',
          createdAt: '2020-10-20T07:03:00',
          id: '54959595992304',
          senderId: '0beec1fb-e69d-4e73-b03b-624edf85ec86',
          isEdited: false,
        },
        {
          text: 'Ok, transaction ID: 091020TCCLSWOUT28120',
          userName: 'User',
          createdAt: '2020-10-20T07:01:00',
          id: '6546534563456',
          senderId: '1d732f5c-dff4-48f2-8a44-43eaeeab5da1',
          isEdited: true,
        },
        {
          text: 'Hello, please send the ID of the transaction so that I could help you. Thanks.',
          userName: 'Admin',
          createdAt: '2020-10-20T06:50:00',
          id: '54959595992308',
          senderId: '0beec1fb-e69d-4e73-b03b-624edf85ec86',
          isEdited: false,
        },
        {
          text: 'Hello, I created a transaction a few hours ago, but it still hasn\'t been approved. Why?',
          userName: 'User',
          createdAt: '2020-10-20T06:48:00',
          id: '54959595992309',
          senderId: '1d732f5c-dff4-48f2-8a44-43eaeeab5da1',
          isEdited: false,
        },
      ],
    },
  },
});

const messagesReducer = handleActions(
  {
    // [logout]: state => console.log(state.toJS()),
  },
  initialState.get('list'),
);

const unReadMessagesReducer = handleActions(
  {
    // [logout]: state => console.log(state.toJS()),
  },
  initialState.get('unReadChatList'),
);

const chatReducer = handleActions(
  {
    // [logout]: state => console.log(state.toJS()),
  },
  initialState.get('one'),
);

export default combineReducers({
  list: messagesReducer,
  one: chatReducer,
  unReadChatList: unReadMessagesReducer,
});
