import React, { memo } from 'react';
import PropTypes from 'prop-types';


export const RemoveSelectTransactionFormatter = ({ id, removeTransaction }) => (
  <div className="remove-cell">
    <i
      className="iconfont-close clickable remove-cell_icon"
      onClick={() => removeTransaction(id)}
    />
  </div>
);

RemoveSelectTransactionFormatter.propTypes = {
  id: PropTypes.string.isRequired,
  removeTransaction: PropTypes.func.isRequired,
};

export default memo(RemoveSelectTransactionFormatter);
