import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import currency from 'currency-formatter';
import { isEqual } from 'lodash';

const InputCurrency = ({
  error,
  name,
  onBlurPost,
  value,
  id,
  placeholder,
  readOnly,
  disabled,
  onChange,
  prefix,
  suffix,
  thousandsSeparatorSymbol,
  decimalSymbol,
  allowDecimal,
  allowLeadingZeroes,
  allowNegative,
  decimalLimit,
  integerLimit,
  propsValue,
}) => {
  const currencyMask = createNumberMask({
    prefix,
    suffix,
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol,
    allowDecimal,
    decimalSymbol,
    decimalLimit,
    integerLimit,
    allowNegative,
    allowLeadingZeroes,
  });

  const [intToCurrency, setIntToCurrency] = useState(value);

  const onBlurValue = useCallback(() => {
    if (onBlurPost && propsValue !== intToCurrency
      && intToCurrency) {
      // TODO: refactor after Anna request
      // && !(isUndefined(propsValue) && intToCurrency === null)) {
      onBlurPost({
        name,
        value: intToCurrency,
      });
    } else if (onBlurPost) {
      setIntToCurrency(propsValue);
    }
  }, [intToCurrency, propsValue]);

  const changeValue = ({ target }) => {
    const correctValue = currency.unformat(target.value, { code: '' });
    if (target.value && correctValue !== 0) {
      setIntToCurrency(correctValue);
    } else if (target.value) {
      setIntToCurrency(target.value);
    } else {
      setIntToCurrency(null);
    }
  };

  useEffect(() => {
    onChange(intToCurrency);
  }, [intToCurrency]);

  useEffect(() => {
    if (value !== intToCurrency) {
      setIntToCurrency(value);
    }
  }, [value]);

  return (
    <>
      <MaskedInput
        mask={currencyMask}
        className={classNames('basic-form__group__block__control input', { error: !!error })}
        value={intToCurrency}
        placeholder={placeholder}
        id={id}
        name={name}
        readOnly={readOnly}
        disabled={disabled}
        onBlur={onBlurValue}
        onChange={changeValue}
      />
    </>
  );
};

InputCurrency.defaultProps = {
  value: '',
  error: '',
  onChange: null,
  onBlurPost: null,
  id: '',
  placeholder: '',
  prefix: '',
  suffix: '',
  decimalSymbol: '.',
  readOnly: false,
  disabled: false,
  allowDecimal: true,
  allowLeadingZeroes: false,
  thousandsSeparatorSymbol: ',',
  propsValue: null,
};

InputCurrency.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  error: PropTypes.string,
  allowNegative: PropTypes.bool.isRequired,
  decimalLimit: PropTypes.number.isRequired,
  integerLimit: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  onBlurPost: PropTypes.func,
  id: PropTypes.string,
  prefix: PropTypes.string,
  decimalSymbol: PropTypes.string,
  suffix: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  thousandsSeparatorSymbol: PropTypes.string,
  readOnly: PropTypes.bool,
  allowDecimal: PropTypes.bool,
  allowLeadingZeroes: PropTypes.bool,
  disabled: PropTypes.bool,
  propsValue: PropTypes.number,
};

const areEqualFunc = (nextProps, prevProps) => isEqual(nextProps, prevProps);

export default memo(InputCurrency, areEqualFunc);
