import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import classNames from 'classnames';
import { isEmpty, transform, isNumber } from 'lodash';

import InputsHelper from './InputsHelper';

const InputsForm = memo(({
  multiFields,
  multiForm,
  formId,
  values,
  errors,
  touched,
  setValues,
  setFieldValue,
  handleChange,
  handleBlur,
  handleSubmit,
  fields,
  withLabel,
  lists,
  additionalHandler,
  autoComplete,
  className,
  onBlurPost,
  propsData,
  fetchingFields,
}) => (
  <>
    <form
      id={formId}
      className={classNames('basic-form', className)}
      onSubmit={handleSubmit}
    >
      {(() => {
        if (multiForm) {
          return (
            <div className="multiform">
              {multiForm.map((item, i) => {
                const selectedFields = fields.filter(val => item.fields.includes(val.id));
                return (
                  <div className="multiform__item" key={i}>
                    <h4>{item.title}</h4>
                    <InputsHelper
                      propsData={propsData}
                      fields={selectedFields}
                      values={values}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      setValues={setValues}
                      handleBlur={handleBlur}
                      onBlurPost={onBlurPost}
                      withLabel={withLabel}
                      additionalHandler={additionalHandler}
                      autoComplete={autoComplete}
                      errors={errors}
                      lists={lists}
                      touched={touched}
                      fetchingFields={fetchingFields}
                    />
                  </div>
                );
              })}
            </div>
          );
        }
        if (multiFields) {
          return (
            <div className="multi-fields">
              {multiFields.map((item, i) => {
                const selectedFields = fields.filter(val => item.fields.includes(val.id));
                return (
                  <div className={`multi-fields__item ${item.className || ''}`} key={i}>
                    <span className="multi-fields__title">{item.title}</span>
                    <div className="multi-fields__content">
                      <InputsHelper
                        propsData={propsData}
                        onBlurPost={onBlurPost}
                        fields={selectedFields}
                        values={values}
                        handleChange={handleChange}
                        setValues={setValues}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        withLabel={withLabel}
                        additionalHandler={additionalHandler}
                        autoComplete={autoComplete}
                        errors={errors}
                        lists={lists}
                        touched={touched}
                        fetchingFields={fetchingFields}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          );
        }
        return (
          <InputsHelper
            propsData={propsData}
            fields={fields}
            values={values}
            handleChange={handleChange}
            setValues={setValues}
            setFieldValue={setFieldValue}
            handleBlur={handleBlur}
            onBlurPost={onBlurPost}
            withLabel={withLabel}
            additionalHandler={additionalHandler}
            autoComplete={autoComplete}
            errors={errors}
            lists={lists}
            touched={touched}
            fetchingFields={fetchingFields}
          />
        );
      })()}
    </form>
  </>
));

InputsForm.defaultProps = {
  errors: {},
  withLabel: false,
  additionalHandler: null,
  onBlurPost: null,
  multiForm: null,
  multiFields: null,
  autoComplete: '', // on
  lists: {},
  className: '',
  fetchingFields: [],
};

InputsForm.propTypes = {
  formId: PropTypes.string.isRequired,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any),
  handleChange: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onBlurPost: PropTypes.func,
  additionalHandler: PropTypes.func,
  withLabel: PropTypes.bool,
  autoComplete: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  multiForm: PropTypes.arrayOf(PropTypes.object),
  multiFields: PropTypes.arrayOf(PropTypes.object),
  propsData: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.string,
    PropTypes.array,
    PropTypes.bool,
  ])).isRequired,
  fetchingFields: PropTypes.arrayOf(PropTypes.object),
  lists: PropTypes.shape({}),
  className: PropTypes.string,
};

const InputsFormWithFormik = withFormik({
  validateOnBlur: true,
  enableReinitialize: true,
  mapPropsToValues: ({
    propsData = {
      email: '',
      password: '',
    }, fields,
  }) => {
    const defaultValue = (type) => {
      switch (type) {
        case 'toggle':
        case 'custom_toggle':
        case 'checkbox':
          return false;
        case 'select':
          return '';
        case 'dropdown':
          // case 'duration':
          return ''; // TODO need to check
        case 'combobox':
          return 0;
        case 'number':
        case 'currency':
          return null;
        case 'percent':
          return null;
        case 'date':
          return new Date();
        case 'dualList':
          return [];
        case 'duration':
          return {};
        case 'file':
          return null;
        default:
          return '';
      }
    };

    return transform(fields, (result, field) => {
      const { name, type } = field;
      const isValid = !isEmpty(propsData) && (propsData[name] || isNumber(propsData[name]));

      result[name] = isValid
        ? propsData[name]
        : defaultValue(type);
    }, {});
  },
  handleSubmit(values, { props: { onSubmit }, resetForm }) {
    onSubmit(values, resetForm);
  },
  validationSchema: ({ validationSchema }) => validationSchema,
})(InputsForm);

export default InputsFormWithFormik;
