import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import MainLayout from '../components/Layouts/MainLayout';
import userActions from '../actions/user';

const { refreshSession } = userActions.user;

const mapStateToProps = (state) => {
  const { isAuthTokenExpired, isRefreshTokenTimer, data } = state.get('userInfo').toJS();

  return {
    isAuthTokenExpired,
    isRefreshTokenTimer,
    userInfo: data,
    isModalOpen: state.getIn(['modal', 'isModalOpen']),
    userRole: state.getIn(['userInfo', 'data', 'role']),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    refreshSession: token => dispatch(refreshSession.action(token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withImmutablePropsToJS(MainLayout));
