import { defaultDateRange } from '../constants/constants';
import { makeSecureRequest } from './apiCallHandlers/index';

export default route => ({
  getFeesCharges: (payload = defaultDateRange) => {
    const { dateFromFormatted, dateToFormatted } = payload;
    return makeSecureRequest('get', 'FeesCharges', null, {
      dateFrom: dateFromFormatted,
      dateTo: dateToFormatted,
    });
  },
  executeOneFee: (id = null) => {
    return makeSecureRequest('post', `FeesCharges/${id}/Execute`);
  },
  declineOneFee: ({ id = null, comment = '' }) => {
    return makeSecureRequest(
      'post',
      `FeesCharges/${id}/SetCanceled`,
      { value: comment },
    );
  },
});
