import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from '../../generic/Button';
import DocumentValidationComponent from './DocumentValidationComponent';
import ModalDocumentComponent from './ModalDocumentComponent';

import { getKeyByValue } from '../../../helpers/status';

const DocumentSection = (props) => {
  const {
    lists,
    title,
    columns,
    schema,
    uploadDocument,
    openModal,
    setModalFormInfo,
    propsData,
    deleteDocument,
    downloadDocument,
    dispatchProgress,
    withRemovingPermission,
    profileId,
  } = props;

  const getDocumentId = item => getKeyByValue(lists.documentTypes, item);

  return (
    <div className="document_section" id={title}>
      <h6 className="document_section_title">{title}</h6>
      <div className="document_section_grid">
        {
          columns.map(({ subTitle, items }) => (
            <div className="grid-column" id={subTitle}>
              <div className="grid-item">{subTitle}</div>
              {
                  items.map((elem) => {
                    if (schema[elem].properties.documents) {
                      const documentsList = schema[elem].properties.documents.map(item => ({
                        label: item,
                        id: getDocumentId(item),
                      }));

                      if (schema[elem].type === 'modal') {
                        return (
                          <ModalDocumentComponent
                            key={elem}
                            openModal={openModal}
                            setModalFormInfo={setModalFormInfo}
                            {...schema[elem].properties}
                            documentsList={documentsList}
                            profileId={profileId}
                          />
                        );
                      }
                    }

                    const listKey = getDocumentId(elem);

                    if (schema[elem].type === 'modal') {
                      if (elem === 'Other') {
                        const documents = propsData.filter(val => val.documentType == listKey);

                        return (
                          <>
                            <ModalDocumentComponent
                              key={elem}
                              openModal={openModal}
                              setModalFormInfo={setModalFormInfo}
                              {...schema[elem].properties}
                              documentTypeId={listKey}
                              profileId={profileId}
                            />
                            {documents.map(item => (
                              <DocumentValidationComponent
                                key={item.documentId}
                                {...schema[elem].properties}
                                uploadDocument={data => uploadDocument({
                                  ...data,
                                  documentTypeId: listKey,
                                })}
                                documentTypeId={listKey}
                                deleteDocument={deleteDocument}
                                document={item}
                                downloadDocument={downloadDocument}
                                withRemovingPermission={withRemovingPermission}
                              />
                            ))}
                          </>
                        );
                      }
                      return (
                        <ModalDocumentComponent
                          key={elem}
                          openModal={openModal}
                          setModalFormInfo={setModalFormInfo}
                          {...schema[elem].properties}
                          documentTypeId={listKey}
                          profileId={profileId}
                        />
                      );
                    }

                    const document = propsData.find(val => val.documentType == listKey);

                    if (schema[elem].properties.viewOnly && !document) {
                      return <span className="d-none" />;
                    }

                    return (
                      <DocumentValidationComponent
                        key={elem}
                        {...schema[elem].properties}
                        uploadDocument={data => uploadDocument({
                          ...data,
                          documentTypeId: listKey,
                          dispatchProgress,
                        })}
                        documentTypeId={listKey}
                        deleteDocument={deleteDocument}
                        document={document}
                        downloadDocument={downloadDocument}
                        withRemovingPermission={withRemovingPermission}
                      />
                    );
                  })
                }
            </div>
          ))
        }

      </div>
    </div>
  );
};

const DocumentsUpload = ({
  formName,
  hasPrevButton,
  hasNextButton,
  prevTab,
  isLastTab,
  confirmForm,
  documentsSections,
  isRegistrationTool,
  nextTab,
  ...props
}) => {
  useEffect(() => {
    window.ondragover = (e) => {
      e.preventDefault();
      return false;
    };
    window.ondrop = (e) => {
      e.preventDefault();
      return false;
    };
  }, []);

  return (
    <div className={`account-setup_step ${formName}`}>
      <div className="basic-form account-setup_step_form">
        {documentsSections.map(item => <DocumentSection {...props} {...item} key={item.title} />)}
      </div>

      <div className="account-setup_step_footer">
        <If condition={hasPrevButton}>
          <Button
            color="gray"
            type="primary"
            className="prev"
            size="lg"
            onClick={prevTab}
            text="Previous"
          />
        </If>

        <If condition={hasNextButton && !isRegistrationTool}>
          <Button
            color="gray"
            type="primary"
            size="lg"
            onClick={nextTab}
            text="Next"
          />
        </If>

        <If condition={isRegistrationTool}>
          <Button
            color="gray"
            type="primary"
            size="lg"
            onClick={confirmForm}
            text="Submit"
          />
        </If>
      </div>
    </div>
  );
};

export default DocumentsUpload;

DocumentSection.defaultProps = {
  propsData: [
    {},
  ],
  withRemovingPermission: false,
};

DocumentsUpload.defaultProps = {
  isRegistrationTool: false,
  hasPrevButton: false,
  hasNextButton: false,
  isLastTab: false,
};

DocumentSection.propTypes = {
  lists: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.object,
  ])).isRequired,
  title: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object,
  ])).isRequired,
  schema: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.object,
  ])).isRequired,
  uploadDocument: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  setModalFormInfo: PropTypes.func.isRequired,
  propsData: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object,
  ])),
  deleteDocument: PropTypes.func.isRequired,
  downloadDocument: PropTypes.func.isRequired,
  dispatchProgress: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.func,
  ])).isRequired,
  withRemovingPermission: PropTypes.bool,
  profileId: PropTypes.string.isRequired,
};

DocumentsUpload.propTypes = {
  formName: PropTypes.string.isRequired,
  hasPrevButton: PropTypes.bool,
  hasNextButton: PropTypes.bool,
  prevTab: PropTypes.func.isRequired,
  isLastTab: PropTypes.bool,
  confirmForm: PropTypes.func.isRequired,
  documentsSections: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object,
  ])).isRequired,
  isRegistrationTool: PropTypes.bool,
  nextTab: PropTypes.func.isRequired,
};
