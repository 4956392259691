import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const getClassNames = (color, type, size) => {
  const colorClassName = `btn-${type}-${color}`;
  const sizeClassName = `btn-${size}`;

  return classNames(colorClassName, sizeClassName);
};

const Button = ({
  fetching,
  color,
  type,
  size,
  id,
  className,
  text,
  onClick,
  style,
  disabled,
  submit,
  ...rest
}) => (
    <button
      {...rest}
      type={submit ? 'submit' : 'button'}
      id={id}
      onClick={onClick}
      className={classNames(
        'button',
        className,
        disabled,
        getClassNames(color, type, size),
      )}
      style={style}
      disabled={disabled || fetching}
    >
      {text}
    </button>

);

export const colorTypes = ['green', 'blue', 'dark-blue', 'gray'];
export const typeTypes = ['primary', 'secondary'];
export const sizeTypes = ['sm', 'md', 'lg'];

Button.defaultProps = {
  fetching: false,
  className: '',
  id: '',
  text: '',
  onClick: null,
  style: {},
  disabled: false,
  submit: false,
};
Button.propTypes = {
  fetching: PropTypes.bool,
  color: PropTypes.oneOf(colorTypes).isRequired,
  type: PropTypes.oneOf(typeTypes).isRequired,
  size: PropTypes.oneOf(sizeTypes).isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.objectOf(PropTypes.any),
  disabled: PropTypes.bool,
  submit: PropTypes.bool,
};

export default Button;
