import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import Chat from '../../../components/Pages/Messages/Chat';

const mapStateToProps = (state) => {
  const currentState = state.getIn(['messages', 'one']);
  const userRole = state.getIn(['userInfo', 'data', 'role']);
  const subRole = state.getIn(['userInfo', 'data', 'subRole']);
  const userId = state.getIn(['userInfo', 'data', 'sub']);

  return {
    userId,
    data: currentState.get('data'),
    fetching: currentState.get('fetching'),
    isCloseChatBtn: userRole === 'Admin',
    closeBtnText: 'Close chat',
    closeBtnFunc: () => {},
    canEdit: () => subRole === 'SuperAdmin',
    canRemove: () => subRole === 'SuperAdmin',
  };
};

const mapDispatchToProps = (dispatch) => {
  const ttt = 'ttt';

  return {
    sendMessage: () => {},
    editMessage: () => {},
    removeMessage: () => {},
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withImmutablePropsToJS(Chat));
