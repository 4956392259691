import { customFilter } from 'react-bootstrap-table2-filter';
import React from 'react';

import CustomSelectFilter from '../../components/CustomTable/CustomFilter';
import {
  sortHeaderFormatter,
  dateFormatter,
  statusJournalOfAccountProps,
  referenceProps,
  priceFormatter,
} from '../tableUtils/tablesUtils';
import customSelectionFilter from '../customSelectionFilter';

import {convertDateToLocaleFormat } from '../../helpers/dates';
import { selectUniqValues } from '../../helpers/status';

const journalOfSelectedAccount = (currentData) => {
  const accountsStatuses = selectUniqValues(currentData, 'status');
  const references = selectUniqValues(currentData, 'reference');

  return (
    [
      {
        dataField: 'type',
        text: 'Transaction Type',
        sort: true,
        headerClasses: 'with-filter',
        headerStyle: () => ({ width: '8%' }),
        wpx: 100,
      },
      {
        dataField: 'reference',
        text: 'Reference',
        sort: true,
        headerStyle: () => ({ width: '23%' }),
        headerClasses: 'with-filter',
        filter: customFilter({
          onFilter: (filterObj, data) => customSelectionFilter(filterObj, data, 'reference')
        }),
        filterRenderer: (onFilter, column) =>
          <CustomSelectFilter
            onFilter={onFilter}
            column={column}
            selectItems={references}
            formClass="custom-filter-status"
          />,
        wpx: 80,
      },
      {
        dataField: 'postDate',
        text: 'Post Date',
        sort: true,
        formatter: (cell, { id }) => dateFormatter(cell, id),
        headerFormatter: sortHeaderFormatter,
        headerStyle: () => ({ width: '6%' }),
        transformationItem: cell => convertDateToLocaleFormat(cell),
        wpx: 100,
      },
      {
        dataField: 'amount',
        text: 'Amount',
        sort: true,
        formatter: priceFormatter,
        headerFormatter: sortHeaderFormatter,
        headerStyle: () => ({ width: '6%' }),
        wpx: 100,
      },
      {
        dataField: 'balance',
        text: 'Balance',
        sort: true,
        formatter: priceFormatter,
        headerFormatter: sortHeaderFormatter,
        headerStyle: () => ({ width: '8%' }),
        wpx: 80,
      },
      {
        dataField: 'status',
        text: 'Status',
        sort: true,
        headerStyle: () => ({ width: '6%' }),
        headerClasses: 'with-filter',
        filter: customFilter({
          onFilter: (filterObj, data) => customSelectionFilter(filterObj, data, 'status')
        }),
        filterRenderer: (onFilter, column) =>
          <CustomSelectFilter
            onFilter={onFilter}
            column={column}
            selectItems={accountsStatuses}
            formClass="custom-filter-status"
          />,
        wpx: 80,
      },
    ]
  );
};

export default journalOfSelectedAccount;
