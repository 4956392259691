import { isEmpty, groupBy } from 'lodash';

export const getKeyByValue = (object, value) => {
  const key = Object.keys(object)
    .find(k => object[k] === value);
  return Number(key);
};

export const filterStatuses = (filterValArr, statusesObj) => {
  const filteredObj = {};
  filterValArr.forEach((value) => {
    const key = getKeyByValue(statusesObj, value);
    filteredObj[key] = value;
  });

  return filteredObj;
};

export const selectUniqValues = (columnData, fieldName, customCases = {}, correctItems) => {
  const cases = isEmpty(customCases);
  const groupData = groupBy(columnData, fieldName);
  const items = correctItems || Object.keys(groupData);

  const replacedData = {};

  const getItemNums = item => (groupData[item] ? groupData[item].length : 0);

  if (cases) {
    items.forEach((item) => {
      replacedData[item] = getItemNums(item);
    });
  } else {
    items.forEach((item) => {
      replacedData[customCases[item]] = getItemNums(item);
    });
  }

  const customItems = cases ? items : items.map(i => customCases[i]);
  return {
    items: customItems.sort(),
    classes: [],
    countItems: replacedData,
  };
};
