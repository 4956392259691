import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import ApplicationsDashboard from '../../../../components/Pages/Dashboard/applications/ApplicationsDashboard';

const mapStateToProps = (state) => {
  return {
    data: state.getIn(['dashboard', 'applications']),
  };
};

export default connect(mapStateToProps)(withImmutablePropsToJS(ApplicationsDashboard));
