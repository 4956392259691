import React, { useCallback } from 'react';
import PropsTypes from 'prop-types';

import UniversalModalButton from '../../containers/Modals/UniversalModalButton';
import SearchContainer from '../../containers/Modals/SearchContainer';
import { confirmModal } from './statusHelper';
import ReadOnlyPermission from '../../components/HOC/ReadOnlyPermissionHOC';
import { tooltipCantChangeAccountStatus } from '../../helpers/notificatios';

const AccountStatusHelper = (props) => {
  const {
    isVerified,
    processAction,
    processText,
    id,
    status,
    userRole,
    subRole,
  } = props;

  const confirm = useCallback(
    () => confirmModal(processAction, processText, 'Active', id),
    [processAction, processText, id],
  );

  return (
    <>
      <If condition={userRole === 'Admin'}>
        <ReadOnlyPermission
          subRole={subRole}
          tooltipId={`info-reason-${id}`}
          declinePermissionText={tooltipCantChangeAccountStatus}
          readOnlyComponent={(
            <>
              <i
                data-tip={`info-reason-${id}`}
                data-for={`info-reason-${id}`}
                className="iconfont-close disabled"
              />
            </>
          )}
        >
          <UniversalModalButton
            modalId="reason-account-modal"
            inlineButton
            iconClasses="iconfont-close clickable"
            customIcon
            chosenItemId={id}
          />
        </ReadOnlyPermission>
      </If>

      <SearchContainer text={status} className="content" />

      <If condition={isVerified && status === 'Pending'}>
        <i onClick={confirm} className="iconfont-pending clickable" />
      </If>
    </>
  );
};

AccountStatusHelper.defaultProps = {
  isVerified: false,
  processAction: () => {},
};

AccountStatusHelper.propTypes = {
  isVerified: PropsTypes.bool,
  processAction: PropsTypes.func,
  processText: PropsTypes.string.isRequired,
  id: PropsTypes.string.isRequired,
  status: PropsTypes.string.isRequired,
  userRole: PropsTypes.string.isRequired,
  subRole: PropsTypes.string.isRequired,
};

export default AccountStatusHelper;
