import { createAction } from 'redux-actions';

import { toastr } from 'react-redux-toastr';
import { isEmpty } from 'lodash';
import { apiCallAction } from '../actionHelpers';
import { apiCallActionHandler } from '../commonLogic/requestLogic';

import {
  GET_ONE_LIST,
  SET_ONE_LIST,
  GET_LISTS,
  GET_BANK_LIST,
  GET_LEDGER_STATUS_LIST,
  GET_FEES_TYPE_LIST,
  GET_COUNTRY_LIST,
  GET_SEPA_COUNTRY_LIST,
  GET_CURRENCY_LIST,
  GET_CUSTOMER_LIST,
  GET_TRANSACTION_STATUS_LIST,
  GET_ROUTING_TYPES_LIST,
  GET_CHARGE_TYPES_LIST,
  GET_CONVERSION_FIXED_TYPE_LIST,
  GET_ENDUSER_TYPE_LIST,
  GET_INTEGRATION_STATUS_LIST,
  GET_TRANSACTIONS_TYPES_LIST,
  GET_FEES_LIST,
  GET_LEDGER_TYPES,
  GET_LEDGERS_LIST,
  GET_FEES_TEMPLATES_LIST,
  GET_MONEY_GRADATIONS,
  GET_TRANSACTION_FEES_LIST,
  GET_CHARGE_FEES_LIST,
  GET_INDUSTRY_TYPES_LIST,
  GET_TYPES_OF_OWNERSHIP_LIST,
  GET_BUSINESS_TYPES_LIST,
  GET_DOCUMENT_TYPES,
  GET_BENEFICIARIES_LIST,
  GET_BATCH_PLATFORMS,
  CLEAR_LISTS,
} from '../../constants/constants';

const actions = {
  getBusinessTypes: apiCallAction(GET_BUSINESS_TYPES_LIST),
  getDocumentTypes: apiCallAction(GET_DOCUMENT_TYPES),
  getIndustryTypes: apiCallAction(GET_INDUSTRY_TYPES_LIST),
  getBanks: apiCallAction(GET_BANK_LIST),
  getBeneficiariesList: apiCallAction(GET_BENEFICIARIES_LIST),
  getLedgerStatuses: apiCallAction(GET_LEDGER_STATUS_LIST),
  getFeesTypes: apiCallAction(GET_FEES_TYPE_LIST),
  getCountries: apiCallAction(GET_COUNTRY_LIST),
  getSepaCountries: apiCallAction(GET_SEPA_COUNTRY_LIST),
  getCurrencies: apiCallAction(GET_CURRENCY_LIST),
  getChargeFees: apiCallAction(GET_CHARGE_FEES_LIST),
  getOneList: apiCallAction(GET_ONE_LIST),
  setOneList: apiCallAction(SET_ONE_LIST),
  getLists: apiCallAction(GET_LISTS),
  getCustomersList: apiCallAction(GET_CUSTOMER_LIST),
  getLedgers: apiCallAction(GET_LEDGERS_LIST),
  getTransactionStatuses: apiCallAction(GET_TRANSACTION_STATUS_LIST),
  getRoutingTypes: apiCallAction(GET_ROUTING_TYPES_LIST),
  getChargeTypes: apiCallAction(GET_CHARGE_TYPES_LIST),
  getConversionFixedTypes: apiCallAction(GET_CONVERSION_FIXED_TYPE_LIST),
  getEnduserTypes: apiCallAction(GET_ENDUSER_TYPE_LIST),
  getIntegrationStatuses: apiCallAction(GET_INTEGRATION_STATUS_LIST),
  getTransactionTypes: apiCallAction(GET_TRANSACTIONS_TYPES_LIST),
  getTransactionFees: apiCallAction(GET_TRANSACTION_FEES_LIST),
  getFees: apiCallAction(GET_FEES_LIST),
  getFeesTemplates: apiCallAction(GET_FEES_TEMPLATES_LIST),
  getLedgerTypes: apiCallAction(GET_LEDGER_TYPES),
  getMoneyGradations: apiCallAction(GET_MONEY_GRADATIONS),
  getTypesOfOwnership: apiCallAction(GET_TYPES_OF_OWNERSHIP_LIST),
  getBatchPlatforms: apiCallAction(GET_BATCH_PLATFORMS),
  clearLists: createAction(CLEAR_LISTS),
};

const getIndustryTypesLogic = apiCallActionHandler({
  name: 'getIndustryTypes',
  fetchAction: actions.getIndustryTypes,
  makeApiCall(payload, { api }) {
    return api.lists.getIndustryTypes();
  },
});

const getDocumentTypesLogic = apiCallActionHandler({
  name: 'getDocumentTypes',
  fetchAction: actions.getDocumentTypes,
  makeApiCall(payload, { api }) {
    return api.lists.getDocumentTypes();
  },
});

const getBanksLogic = apiCallActionHandler({
  name: 'getBanks',
  fetchAction: actions.getBanks,
  makeApiCall(payload, { api }) {
    return api.lists.getBanks();
  },
});

const getBeneficiariesListLogic = apiCallActionHandler({
  name: 'getBeneficiariesList',
  fetchAction: actions.getBeneficiariesList,
  makeApiCall(payload, { api }) {
    return api.lists.getBeneficiariesList(payload);
  },
  onSuccess(data, payload) {
    if (isEmpty(data) && payload && payload.isEmptyText) {
      toastr.warning(
        'Warning',
        payload.isEmptyText,
      );
    }
  },
});

const getLedgerStatusesLogic = apiCallActionHandler({
  name: 'getLedgerStatuses',
  fetchAction: actions.getLedgerStatuses,
  makeApiCall(payload, { api }) {
    return api.lists.getLedgerStatuses(payload);
  },
});

const getBusinessTypesLogic = apiCallActionHandler({
  name: 'getBusinessTypes',
  fetchAction: actions.getBusinessTypes,
  makeApiCall(payload, { api }) {
    return api.lists.getBusinessTypes(payload);
  },
});

const getTransactionStatusesLogic = apiCallActionHandler({
  name: 'getTransactionStatuses',
  fetchAction: actions.getTransactionStatuses,
  makeApiCall(payload, { api }) {
    return api.lists.getTransactionStatuses(payload);
  },
});

const getFeesTypesLogic = apiCallActionHandler({
  name: 'getFeesTypes',
  fetchAction: actions.getFeesTypes,
  makeApiCall(payload, { api }) {
    return api.lists.getFeesTypes(payload);
  },
});

const getCountriesLogic = apiCallActionHandler({
  name: 'getCountries',
  fetchAction: actions.getCountries,
  makeApiCall(payload, { api }) {
    return api.lists.getCountries(payload);
  },
});

const getSepaCountriesLogic = apiCallActionHandler({
  name: 'getSepaCountries',
  fetchAction: actions.getSepaCountries,
  makeApiCall(payload, { api }) {
    return api.lists.getSepaCountries(payload);
  },
});

const getCurrenciesLogic = apiCallActionHandler({
  name: 'getCurrencies',
  fetchAction: actions.getCurrencies,
  makeApiCall(payload, { api }) {
    return api.lists.getCurrencies(payload);
  },
});

const getRoutingTypesLogic = apiCallActionHandler({
  name: 'getRoutingTypes',
  fetchAction: actions.getRoutingTypes,
  makeApiCall(payload, { api }) {
    return api.lists.getRoutingTypes(payload);
  },
});

const getFeesLogic = apiCallActionHandler({
  name: 'getFees',
  fetchAction: actions.getFees,
  makeApiCall(payload, { api }) {
    return api.lists.getFees(payload);
  },
});

const getTransactionFeesLogic = apiCallActionHandler({
  name: 'getTransactionFees',
  fetchAction: actions.getTransactionFees,
  makeApiCall(payload, { api }) {
    return api.lists.getTransactionFees(payload);
  },
});

const getChargeFeesLogic = apiCallActionHandler({
  name: 'getChargeFees',
  fetchAction: actions.getChargeFees,
  makeApiCall(payload, { api }) {
    return api.lists.getChargeFees(payload);
  },
});

const getCustomersListLogic = apiCallActionHandler({
  name: 'getCustomersList',
  fetchAction: actions.getCustomersList,
  makeApiCall(payload, { api }) {
    return api.lists.getCustomersList(payload);
  },
});

const getLedgersLogic = apiCallActionHandler({
  name: 'getLedgers',
  fetchAction: actions.getLedgers,
  makeApiCall(payload, { api }) {
    return api.lists.getLedgers(payload);
  },
  onSuccess(data, payload) {
    if (isEmpty(data) && payload && payload.isEmptyText) {
      toastr.warning(
        'Warning',
        payload.isEmptyText,
      );
    }
  },
});

const getTransactionTypesLogic = apiCallActionHandler({
  name: 'getTransactionTypes',
  fetchAction: actions.getTransactionTypes,
  makeApiCall(payload, { api }) {
    return api.lists.getTransactionTypes(payload);
  },
});

const getEnduserTypesLogic = apiCallActionHandler({
  name: 'getEnduserTypes',
  fetchAction: actions.getEnduserTypes,
  makeApiCall(payload, { api }) {
    return api.lists.getEnduserTypes(payload);
  },
});

const getFeesTemplatesLogic = apiCallActionHandler({
  name: 'getFeesTemplates',
  fetchAction: actions.getFeesTemplates,
  makeApiCall(payload, { api }) {
    return api.lists.getFeesTemplates(payload);
  },
});

const getTypesOfOwnershipLogic = apiCallActionHandler({
  name: 'getTypesOfOwnership',
  fetchAction: actions.getTypesOfOwnership,
  makeApiCall(payload, { api }) {
    return api.lists.getTypesOfOwnership(payload);
  },
});

const getBatchPlatformsLogic = apiCallActionHandler({
  name: 'getBatchPlatforms',
  fetchAction: actions.getBatchPlatforms,
  makeApiCall(payload, { api }) {
    return api.lists.getBatchPlatforms(payload);
  },
});

export const logics = [
  getBanksLogic,
  getBeneficiariesListLogic,
  getLedgerStatusesLogic,
  getTransactionStatusesLogic,
  getFeesTypesLogic,
  getCountriesLogic,
  getSepaCountriesLogic,
  getCurrenciesLogic,
  getRoutingTypesLogic,
  getFeesLogic,
  getCustomersListLogic,
  getTransactionTypesLogic,
  getEnduserTypesLogic,
  getLedgersLogic,
  getFeesTemplatesLogic,
  getTransactionFeesLogic,
  getChargeFeesLogic,
  getIndustryTypesLogic,
  getTypesOfOwnershipLogic,
  getBusinessTypesLogic,
  getDocumentTypesLogic,
  getBatchPlatformsLogic,
];

export default actions;
