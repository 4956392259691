import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Header from '../../Layouts/Header';
import CustomSpinner from '../../Spinners/CustomSpinner';
import ChatListContainer from '../../../containers/Pages/Messages/ChatListContainer';
import ChatContainer from '../../../containers/Pages/Messages/ChatContainer';
import RangeCalendar from '../../Layouts/RangeCalendar';
import { defaultDateRange } from '../../../constants/constants';

const MessagesPage = (props) => {
  const {
    title,
    header,
    fetching,
  } = props;

  const isOnlyUnReadValue = ['All', 'Unread'];
  const chatStatusValue = ['Open', 'OnHold', 'Closed'];

  const [onlyUnread, setOnlyUnread] = useState('All');
  const [activePeriod, setActivePeriod] = useState('week');
  const [chatStatus, setChatStatus] = useState('Open');

  const HeaderTools = header;

  const getSelectBlock = (text, block, func) => {
    const click = () => func(text);
    return (
      <span
        key={text}
        className={`select-group__item ${block === text ? 'active' : ''}`}
        onClick={click}
      >
        {text}
      </span>
    );
  };

  const rangeCalendarAction = ({ dateFromFormatted, dateToFormatted }) => {
    setActivePeriod('calendar');
    console.log(dateFromFormatted, dateToFormatted);
  };

  const getFromMonth = () => {
    setActivePeriod('month');
    // setCurrentOptions({ ...currentOptions, ...datesFromPreviousMonth });
  };

  const getFromWeek = () => {
    setActivePeriod('week');
    // setCurrentOptions({
    //   ...currentOptions,
    //   dateFrom: new Date(dateFromPreviousWeekFormatted),
    //   dateTo: new Date(),
    // });
  };

  return (
    <div className="full-height main_padding">
      <Header>
        {HeaderTools && <HeaderTools path={title} />}
      </Header>
      <div className={`messages-page ${fetching ? 'spinner' : ''}`}>
        <CustomSpinner fetching={fetching} />
        <If condition={!fetching}>
          <div className="select-group">
            <div className="select-group__block">
              {isOnlyUnReadValue.map(i => getSelectBlock(i, onlyUnread, setOnlyUnread))}
            </div>
            <div className="select-group__block">
              <span className={`calendar-section--left ${activePeriod === 'calendar' ? 'active' : ''}`}>
                <RangeCalendar
                  rangeCalendarAction={rangeCalendarAction}
                  dayFormat="D/MMM"
                  customDefaultDate={{
                    startDate: new Date(defaultDateRange.dateFromPreviousDayFormatted),
                    endDate: new Date(defaultDateRange.dateTo),
                  }}
                />
              </span>
              <span
                className={`select-group__item ${activePeriod === 'month' ? 'active' : ''}`}
                onClick={getFromMonth}
              >
                Month
              </span>
              <span
                className={`select-group__item ${activePeriod === 'week' ? 'active' : ''}`}
                onClick={getFromWeek}
              >
                Week
              </span>
            </div>
            <div className="select-group__block">
              {chatStatusValue.map(i => getSelectBlock(i, chatStatus, setChatStatus))}
            </div>
          </div>
          <div className="messages-page__container">
            <ChatListContainer
              onlyUnread={onlyUnread}
              chatStatus={chatStatus}
            />
            <ChatContainer />
          </div>
        </If>
      </div>
    </div>
  );
};

MessagesPage.propTypes = {
  title: PropTypes.string.isRequired,
  // header: PropTypes.objectOf().isRequired,
  fetching: PropTypes.bool.isRequired,
};

export default MessagesPage;
