import { combineActions, handleActions } from 'redux-actions';
import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';

import userActions from '../actions/user';

const {
  getOneUser,
  getUsers,
  changeUserStatus,
  getUserActivityLogs,
  cleanUserData,
  logout,
  completeSubUser,
  createSubUser,
  createSubUserByAdmin,
  getTokenForChangingUser,
} = userActions.user;

const initialState = fromJS({
  list: {
    data: [],
    error: false,
    fetching: false,
  },
  activity: {
    userName: '',
    data: [],
    error: false,
    fetching: false,
  },
  one: {
    data: {},
    error: false,
    fetching: false,
  },
  withChangedStatus: {
    requestId: '',
    error: false,
    fetching: false,
  },
});

const usersReducer = handleActions(
  {
    [combineActions(
      getTokenForChangingUser.fetching,
      getUsers.fetching,
    )]: state => state
      .set('fetching', true)
      .set('error', false),
    [combineActions(
      getUsers.done,
      getUsers.failure,
      getUsers.error,
      getTokenForChangingUser.done,
      getTokenForChangingUser.failure,
      getTokenForChangingUser.error,
    )]: state => state
      .set('fetching', false)
      .set('error', true),
    [getUsers.success]: (state, { payload }) => state
      .set('data', fromJS(payload))
      .set('fetching', false),
    [changeUserStatus.success]: (state, { payload }) => {
      const itemIndex = state.get('data')
        .findKey(obj => obj.get('id') === payload.id);

      return state.setIn(['data', itemIndex], fromJS({
        ...payload,
        marked: true,
      }));
    },
    [combineActions(
      createSubUser.success,
      createSubUserByAdmin.success,
    )]: (state, { payload }) => {
      const preventList = state.getIn(['data']);
      const newList = fromJS([{ ...payload, marked: true }, ...preventList]);

      return state
        .set('data', newList);
    },
    [logout]: () => initialState.get('list'),
  },
  initialState.get('list'),
);

const userActivityLogReducer = handleActions(
  {
    [combineActions(
      getUserActivityLogs.fetching,
    )]: state => state
      .set('fetching', true)
      .set('error', false),
    [combineActions(
      getUserActivityLogs.done,
      getUserActivityLogs.failure,
      getUserActivityLogs.error,
    )]: state => state
      .set('fetching', false)
      .set('error', true),
    [getUserActivityLogs.success]: (state, {
      payload: {
        userName,
        userLogs,
      },
    }) => state
      .set('userName', fromJS(userName))
      .set('data', fromJS(userLogs))
      .set('fetching', false),
    [logout]: () => initialState.get('activity'),
  },
  initialState.get('activity'),
);

const changeUserStatusReducer = handleActions(
  {
    [combineActions(
      changeUserStatus.fetching,
    )]: (state, { payload }) => state
      .set('requestId', payload.id)
      .set('fetching', true)
      .set('error', false),
    [combineActions(
      changeUserStatus.failure,
      changeUserStatus.error,
    )]: state => state
      .set('fetching', false)
      .set('error', true),
    [combineActions(
      changeUserStatus.success,
    )]: state => state.set('fetching', false),
    [logout]: () => initialState.get('withChangedStatus'),
  },
  initialState.get('withChangedStatus'),
);

const userReducer = handleActions(
  {
    [combineActions(
      getOneUser.fetching,
      completeSubUser.fetching,
    )]: state => state
      .set('fetching', true)
      .set('error', false),
    [combineActions(
      getOneUser.failure,
      getOneUser.error,
      completeSubUser.error,
      completeSubUser.error,
    )]: state => state
      .set('fetching', false)
      .set('error', true),
    [getOneUser.success]: (state, { payload }) => state
      .set('data', fromJS(payload))
      .set('fetching', false),
    [completeSubUser.success]: state => state
      .set('fetching', false)
      .set('error', true),
    [combineActions(
      cleanUserData,
      logout,
    )]: () => initialState.get('one'),
  },
  initialState.get('one'),
);

export default combineReducers({
  list: usersReducer,
  one: userReducer,
  activity: userActivityLogReducer,
  withChangedStatus: changeUserStatusReducer,
});
