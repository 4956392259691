import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import Header from '../../../Layouts/Header';
import InputDropdown from '../../../generic/InputDropdown';
import dashboardActions from '../../../../actions/dashboard/dashboardActions';
import listsActions from '../../../../actions/lists/listsActions';
import RangeCalendar from '../../../Layouts/RangeCalendar';
import { defaultDateRange } from '../../../../constants/constants';

const { dateFromPreviousWeekFormatted, datesFromPreviousMonth } = defaultDateRange;

const TransactionsDashboardHeader = ({
  lists: { customers },
  fetchGetData,
  changeAmountType,
  clearData,
  getLedgers,
  getCurrencies,
  options: {
    ledgerId,
    enduserId,
    ledgers,
  },
  options,
}) => {
  const [selectedEndUser, setSelectedEndUser] = useState(enduserId);
  const [activePeriod, setActivePeriod] = useState('month');
  const [activeType, setActiveType] = useState('incoming');
  const [selectedAccount, setSelectedAccount] = useState(ledgerId);
  const [currentOptions, setCurrentOptions] = useState(options);
  const [firstLoading, setFirstLoading] = useState(true);

  useEffect(() => {
    if (firstLoading) {
      fetchGetData({ ...currentOptions, ...datesFromPreviousMonth });
      setFirstLoading(false);
    } else {
      fetchGetData(currentOptions);
    }
  }, [currentOptions]);

  useEffect(() => {
    return function () {
      clearData();
    };
  }, []);

  const changeType = (type) => {
    setActiveType(type);
    changeAmountType(type);
  };

  const getAccountTransactions = (id) => {
    setCurrentOptions({
      ...currentOptions,
      ledgerId: id,
    });
    setSelectedAccount(id);
  };

  const getCustomerTransactions = (id) => {
    setCurrentOptions({
      ...currentOptions,
      enduserId: id,
    });
    getLedgers(id);
    getCurrencies(id);
    setSelectedEndUser(id);
  };

  const getAllItems = () => {
    setCurrentOptions({
      ...currentOptions,
      currencyId: 1,
      ledgerId: null,
      enduserId: null,
    });
    getLedgers();
    getCurrencies();
    setSelectedAccount('');
    setSelectedEndUser('');
  };

  const rangeCalendarAction = (dates) => {
    setActivePeriod('calendar');
    setCurrentOptions({
      ...currentOptions,
      dateFrom: new Date(dates.dateFromFormatted),
      dateTo: new Date(dates.dateToFormatted),
    });
  };

  const getFromMonth = () => {
    setActivePeriod('month');
    setCurrentOptions({ ...currentOptions, ...datesFromPreviousMonth });
  };

  const getFromWeek = () => {
    setActivePeriod('week');
    setCurrentOptions({
      ...currentOptions,
      dateFrom: new Date(dateFromPreviousWeekFormatted),
      dateTo: new Date(),
    });
  };

  return (
    <Header>
      <>
        <div className="column-breadcrumbs-block">
          <pre>Dashboard / Transactions</pre>
          <span className="select-block">
            <div className="beneficiaries-select select-block__item">
              <span>Customer:</span>
              <InputDropdown
                onChange={getCustomerTransactions}
                items={customers}
                placeholder="Select customer"
                selected={selectedEndUser}
                hasGetAllFunctionality
                getAll={getAllItems}
              />
            </div>
            <div className="accounts-select select-block__item">
              <span>Account:</span>
              <InputDropdown
                onChange={getAccountTransactions}
                items={ledgers}
                placeholder="Select account"
                selected={selectedAccount}
                hasGetAllFunctionality
                getAll={getAllItems}
              />
            </div>
          </span>
        </div>
        <div className="select-group">
          <div className="select-group__block right-margin">
            <span className={`calendar-section--left ${activePeriod === 'calendar' ? 'active' : ''}`}>
              <RangeCalendar
                rangeCalendarAction={rangeCalendarAction}
                dayFormat="D/MMM"
                customDefaultDate={{
                  startDate: currentOptions.dateFrom,
                  endDate: currentOptions.dateTo,
                }}
              />
            </span>
            <span
              className={`select-group__item ${activePeriod === 'month' ? 'active' : ''}`}
              onClick={getFromMonth}
            >
              Month
            </span>
            <span
              className={`select-group__item ${activePeriod === 'week' ? 'active' : ''}`}
              onClick={getFromWeek}
            >
              Week
            </span>
          </div>
          <div className="select-group__block">
            <span
              className={`select-group__item ${activeType === 'incoming' ? 'active' : ''}`}
              onClick={() => changeType('incoming')}
            >
              Incoming
            </span>
            <span
              className={`select-group__item ${activeType === 'outgoing' ? 'active' : ''}`}
              onClick={() => changeType('outgoing')}
            >
              Outgoing
            </span>
            <span
              className={`select-group__item ${activeType === 'charge' ? 'active' : ''}`}
              onClick={() => changeType('charge')}
            >
              Charge
            </span>
          </div>
        </div>
      </>
    </Header>
  );
};

TransactionsDashboardHeader.defaultProps = {};

TransactionsDashboardHeader.propTypes = {
  lists: PropTypes.objectOf(
    PropTypes.object,
  ).isRequired,
  fetchGetData: PropTypes.func.isRequired,
  getLedgers: PropTypes.func.isRequired,
  getCurrencies: PropTypes.func.isRequired,
  clearData: PropTypes.func.isRequired,
  changeAmountType: PropTypes.func.isRequired,
  options: PropTypes.shape({
    ledgers: PropTypes.object,
    customers: PropTypes.object,
    ledgerId: PropTypes.string,
    enduserId: PropTypes.string,
    amountType: PropTypes.string,
    currencyId: PropTypes.number,
    dateFrom: PropTypes.instanceOf(Date),
    dateTo: PropTypes.instanceOf(Date),
  }).isRequired,
};

const mapStateToProps = (state) => {
  const {
    customers,
  } = state.get('lists')
    .toJS();

  const options = state.getIn(['dashboard', 'options'])
    .toJS();

  return {
    options,
    lists: {
      customers: customers.data,
    },
  };
};

const {
  getChartByDay,
  changeAmountType,
  clearTransactionsDashboard,
  getTransactionsCurrenciesCompare,
  getTransactionsByStatusByType,
  getTransactionsByStatusByCurrency,
} = dashboardActions;

const {
  getLedgers,
  getCurrencies,
} = listsActions;

const mapDispatchToProps = dispatch => ({
  fetchGetData: (data) => {
    dispatch(getChartByDay.action(data));
    dispatch(getTransactionsCurrenciesCompare.action(data));
    dispatch(getTransactionsByStatusByCurrency.action(data));
    dispatch(getTransactionsByStatusByType.action(data));
  },
  getLedgers: data => dispatch(getLedgers.action(data)),
  getCurrencies: data => dispatch(getCurrencies.action(data)),
  clearData: () => dispatch(clearTransactionsDashboard()),
  changeAmountType: type => dispatch(changeAmountType(type)),
});

export default connect(mapStateToProps,
  mapDispatchToProps)(withImmutablePropsToJS(TransactionsDashboardHeader));
