import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { toastr } from 'react-redux-toastr';
import PropTypes from 'prop-types';

import { cutFileNameLength } from '../../../helpers/other';
import CustomProgressBarr from '../../generic/CustomProgressBar';
import Tooltip from '../../generic/Tooltip';
import { tooltipCantAddDocument, tooltipCantRemoveDocument } from '../../../helpers/notificatios';

const DocumentValidationComponent = ({
  id,
  name,
  label,
  validation,
  uploadDocument,
  document,
  deleteDocument,
  downloadDocument,
  viewOnly,
  readOnly,
  withRemovingPermission,
  documentTypeId,
}) => {
  const defaultState = {
    pointer: 'input-cursor',
    status: null,
    fileName: null,
    errorMessage: null,
    documentId: null,
    description: null,
  };

  const [{
    status, fileName, errorMessage, pointer, documentId, description, hide,
  }, setResult] = useState(defaultState);

  const fileInput = useRef();

  useEffect(() => {
    if (document.fileName) {
      setResult({
        pointer: '',
        status: 'valid',
        fileName: document.fileName,
        errorMessage: null,
        documentId: document.id,
        description: document.description,
        hide: document.hide,
      });
      return;
    }

    setResult(defaultState);
  }, [document]);

  const deleteText = 'Would you like delete this file?';

  const confirmModal = (func, text, fileId) => {
    return toastr.confirm(text, {
      onOk: () => {
        if (fileInput.current) {
          fileInput.current.value = null;
        }
        func(fileId);
      },
    });
  };

  const handleUpload = (e) => {
    const { files, value } = e.target;

    if (value) {
      const file = files[0];

      if (file) {
        if (validation) {
          validation
            .validate(file)
            .then(() => uploadDocument({
              fileName: file.name || '',
              content: file,
              errorMessage: null,
            }))
            .catch(({ message }) => setResult({
              status: 'error',
              fileName: null,
              errorMessage: message,
            }));
        }
      }
    } else {
      e.preventDefault();
      // cancel exception, clearFile can be called
      // clearFile()
    }
  };

  return (
    <div className="grid-item basic-form__group">
      <If condition={!hide}>
        <CustomProgressBarr documentTypeId={documentTypeId} />
      </If>
      <div className="basic-form__group__block">
        <If condition={!viewOnly}>
          <div
            className={classNames('document_custom_input', readOnly && !fileName ? 'disabled' : '', status, pointer)}
            data-tip="document_input"
            data-for="document_input"
          >
            <If condition={status && status !== 'error' && label}>
              <p className="document_title">{description || label}</p>
            </If>

            <label className="document_custom_input_label" htmlFor={fileName ? '' : id}>
              <i className="iconfont-file-all-format download" />
              <If condition={!fileName}>
                <span className="file_name">{label}</span>
              </If>

              <If condition={documentId}>
                <span className="file_name">{cutFileNameLength(fileName, 15)}</span>
                <div className="document_tools">
                  <i
                    className="iconfont-download-file upload_document"
                    onClick={() => downloadDocument(documentId)}
                  />
                  <Choose>
                    <When condition={withRemovingPermission}>
                      <i
                        className="iconfont-close remove_document"
                        onClick={() => confirmModal(deleteDocument, deleteText, document.id)}
                      />
                    </When>
                    <Otherwise>
                      <i
                        className="iconfont-close remove_document disabled"
                        data-tip="remove_document"
                        data-for="remove_document"
                      />
                      <Tooltip id="remove_document" text={tooltipCantRemoveDocument} />
                    </Otherwise>
                  </Choose>
                </div>
              </If>
            </label>

            <input
              className={classNames('basic-form__group__block__control input file', { error: !!errorMessage })}
              type="file"
              id={id}
              name={name}
              ref={fileInput}
              onChange={handleUpload}
              disabled={readOnly && !fileName}
            />

            <If condition={readOnly && !fileName}>
              <Tooltip id="document_input" text={tooltipCantAddDocument} />
            </If>
          </div>

          <div
            className={classNames(
              'basic-form__group__block__invalid-feedback',
              'document',
              { show: errorMessage },
            )}
          >
            {errorMessage}
          </div>
        </If>
        <If condition={viewOnly && fileName}>
          <div className={classNames('document_custom_input', status, pointer)}>
            <p className="document_title">{description || label}</p>

            <label className="document_custom_input_label">
              <i className="iconfont-file-all-format download" />

              <span className="file_name">{cutFileNameLength(fileName, 15)}</span>
              <div className="document_tools">
                <i
                  className="iconfont-download-file upload_document"
                  onClick={() => downloadDocument(documentId)}
                />
                <Choose>
                  <When condition={withRemovingPermission}>
                    <i
                      className="iconfont-close remove_document"
                      onClick={() => confirmModal(deleteDocument, deleteText, document.id)}
                    />
                  </When>
                  <Otherwise>
                    <i
                      className="iconfont-close remove_document disabled"
                      data-tip="remove_document"
                      data-for="remove_document"
                    />
                    <Tooltip id="remove_document" text={tooltipCantRemoveDocument} />
                  </Otherwise>
                </Choose>
              </div>
            </label>
          </div>
        </If>
      </div>
    </div>
  );
};

export default DocumentValidationComponent;

DocumentValidationComponent.defaultProps = {
  document: {
    fileName: null,
    description: '',
    hide: false,
  },
  id: '1',
  viewOnly: false,
  readOnly: false,
  validation: {},
  name: '',
  label: '',
};
DocumentValidationComponent.propTypes = {
  document: PropTypes.shape({
    id: PropTypes.string,
    fileName: PropTypes.string,
    description: PropTypes.string,
    hide: PropTypes.bool,
  }),
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  validation: PropTypes.shape(),
  uploadDocument: PropTypes.func.isRequired,
  deleteDocument: PropTypes.func.isRequired,
  downloadDocument: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool,
  readOnly: PropTypes.bool,
  withRemovingPermission: PropTypes.bool.isRequired,
  documentTypeId: PropTypes.number.isRequired,
};
