import { combineActions, handleActions } from 'redux-actions';
import { fromJS } from 'immutable';

import { combineReducers } from 'redux-immutable';
import customersActions from '../actions/customers/customersActions';
import userActions from '../actions/user/userActions';

const {
  getCustomers,
  getOneCustomer,
  changeCustomerStatus,
  createCustomerContact,
  deleteCustomerContact,
  updateCustomerField,
  uploadCustomerDocument,
  deleteCustomerDocument,
  getCommentsForCustomer,
  addCommentsForCustomer,
  editCommentsForCustomer,
  removeCommentForCustomer,
} = customersActions;

const { logout } = userActions;

const initialState = fromJS({
  list: {
    data: [],
    error: false,
    fetching: false,
  },
  one: {
    data: {},
    comments: [],
    error: false,
    fetching: false,
    successAction: true,
    fetchingFields: [],
  },
  withChangedStatus: {
    requestId: '',
    error: false,
    fetching: false,
  },
});

const customersReducer = handleActions(
  {
    [getCustomers.fetching]: state => state
      .set('fetching', true)
      .set('error', false)
      .set('data', []),
    [combineActions(
      getCustomers.failure,
      getCustomers.error,
    )]: state => state
      .set('fetching', false)
      .set('error', true)
      .set('data', []),
    [getCustomers.success]: (state, { payload }) => state
      .set('data', fromJS(payload))
      .set('fetching', false),
    [combineActions(
      changeCustomerStatus.success,
    )]: (state, { payload }) => {
      const itemIndex = state.get('data')
        .findKey(obj => obj.get('id') === payload.id);

      return state.setIn(['data', itemIndex], fromJS({
        ...payload,
        marked: true,
      }));
    },
    [logout]: () => initialState.get('list'),
  },
  initialState.get('list'),
);

const oneCustomerReducer = handleActions(
  {
    [combineActions(
      getOneCustomer.fetching,
      deleteCustomerContact.fetching,
      createCustomerContact.fetching,
    )]: state => state
      .set('fetching', true)
      .set('error', false)
      .set('successAction', false),
    [combineActions(
      getOneCustomer.failure,
      getOneCustomer.error,
    )]: state => state
      .set('fetching', false)
      .set('error', true),
    [getOneCustomer.success]: (state, { payload }) => state
      .set('data', fromJS(payload))
      .set('fetching', false),
    [createCustomerContact.success]: (state, { payload }) => state.updateIn(['data', 'contacts'],
      arr => arr.push(fromJS(payload))).set('fetching', false),
    [deleteCustomerContact.success]: (state, { payload }) => {
      const currItem = state.getIn(['data', 'contacts'])
        .findKey(obj => obj.get('id') === payload.id);

      return state.removeIn(['data', 'contacts', currItem]).set('fetching', false);
    },
    [uploadCustomerDocument.fetching]: (state, { payload }) => {
      const documentList = state.getIn(['data', 'documents']);

      const payloadInput = documentList.find(i => i.get('documentType') === payload.documentTypeId);

      const correctDocumentList = documentList
        .map(i => (i.get('documentType') === payload.documentTypeId ? i.set('hide', true) : i));

      return payloadInput
        ? state.setIn(['data', 'documents'], correctDocumentList)
        : state;
    },
    [combineActions(
      uploadCustomerDocument.failure,
      uploadCustomerDocument.error,
    )]: (state, { payload }) => {
      const { documentTypeId } = payload.errorPayload;
      const documentList = state.getIn(['data', 'documents']);

      const payloadInput = documentList.find(i => i.get('documentType') === documentTypeId);

      const correctDocumentList = documentList
        .map(i => (i.get('documentType') === documentTypeId ? i.delete('hide') : i));

      return payloadInput
        ? state.setIn(['data', 'documents'], correctDocumentList)
        : state;
    },
    [uploadCustomerDocument.success]: (state, { payload: { documentTypeList, data } }) => {
      const documentList = state.getIn(['data', 'documents']);
      const documentTypeById = documentTypeList.get(String(data.documentType));

      const isNewItem = documentList.find(i => i.get('documentType') === data.documentType);

      const correctDocumentList = documentList
        .map(i => (i.get('documentType') === data.documentType ? fromJS(data) : i));

      return isNewItem && documentTypeById !== 'Other'
        ? state.setIn(['data', 'documents'], correctDocumentList)
        : state.updateIn(['data', 'documents'], arr => arr.push(fromJS(data)));
    },
    [deleteCustomerDocument.success]: (state, { payload }) => {
      const currItem = state.getIn(['data', 'documents'])
        .findKey(obj => obj.get('id') === payload.id);

      return state.removeIn(['data', 'documents', currItem]);
    },
    [updateCustomerField.fetching]: (state, {
      payload: { fieldName },
    }) => state.updateIn(['fetchingFields'],
      arr => arr.concat([fromJS(fieldName)])),
    [updateCustomerField.success]: (state, {
      payload: {
        objectName, fieldName, value, objectId = '',
      },
    }) => {
      if (objectName === 'contacts') {
        const currItem = state.getIn(['data', 'contacts'])
          .findKey(obj => obj.get('id') === objectId);

        return state.setIn(['data', objectName, currItem, fieldName], value);
      }

      return state.setIn(['data', objectName, fieldName], value);
    },
    [combineActions(
      updateCustomerField.failure,
    )]: (state, {
      errorPayload: { objectName, fieldName },
    }) => {
      if (objectName === 'contacts') {
        return state.set('fetchingFields', state.get('fetchingFields')
          .filter(el => el !== fieldName));
      }

      return state.set('fetchingFields', state.get('fetchingFields')
        .filter(el => el !== fieldName));
    },
    [combineActions(
      updateCustomerField.error,
      updateCustomerField.done,
    )]: state => state.set('fetchingFields', []),
    [combineActions(
      getCommentsForCustomer.success,
    )]: (state, { payload }) => state.set('comments', fromJS(payload)),
    [updateCustomerField.success]: (state, {
      payload: {
        objectName, fieldName, value, objectId = '',
      },
    }) => {
      if (objectName === 'contacts') {
        const currItem = state.getIn(['data', 'contacts'])
          .findKey(obj => obj.get('id') === objectId);

        return state.setIn(['data', objectName, currItem, fieldName], value)
          .set('fetchingFields', state.get('fetchingFields')
            .filter(el => el !== fieldName));
      }

      return state.setIn(['data', objectName, fieldName], value)
        .set('fetchingFields',
          state.get('fetchingFields')
            .filter(el => el !== fieldName));
    },
    [combineActions(
      addCommentsForCustomer.fetching,
      removeCommentForCustomer.fetching,
      editCommentsForCustomer.fetching,
    )]: (state, { payload }) => {
      const prevComments = state.get('comments');

      if (prevComments.find(i => i.get('id') === payload.id)) {
        const newComments = prevComments
          .map(comm => (comm.get('id') === payload.id ? fromJS({ ...payload, status: 'fetching' }) : comm));

        return state.set('comments', newComments);
      }

      return state.set('comments', fromJS([{ ...payload, status: 'fetching' }, ...prevComments]));
    },
    [combineActions(
      addCommentsForCustomer.error,
      addCommentsForCustomer.failure,
    )]: (state, { payload: { errorPayload } }) => {
      const newComments = state.get('comments')
        .map(comm => (comm.get('id') === errorPayload.id ? comm.set('status', 'error') : comm));
      return state.set('comments', newComments);
    },
    [combineActions(
      editCommentsForCustomer.error,
      editCommentsForCustomer.failure,
    )]: (state, { payload: { errorPayload } }) => {
      const newComments = state.get('comments')
        .map(comm => (comm.get('id') === errorPayload.id ? comm.set('status', 'errorEdit') : comm));
      return state.set('comments', newComments);
    },
    [addCommentsForCustomer.success]: (state, { payload: { messKey, data } }) => {
      const newComments = state.get('comments')
        .map(comm => (comm.get('id') === messKey ? fromJS(data) : comm));
      return state.set('comments', newComments);
    },
    [editCommentsForCustomer.success]: (state, { payload }) => {
      const newComments = state.get('comments')
        .map(comm => (comm.get('id') === payload.id ? fromJS(payload) : comm));
      return state.set('comments', newComments);
    },
    [removeCommentForCustomer.success]: (state, { payload: { id } }) => {
      const newComments = state.get('comments')
        .filter(comm => (comm.get('id') !== id));
      return state.set('comments', newComments);
    },
  },
  initialState.get('one'),
);

const changeCustomerStatusReducer = handleActions(
  {
    [combineActions(
      changeCustomerStatus.fetching,
    )]: (state, { payload }) => state
      .set('requestId', payload.id)
      .set('fetching', true)
      .set('error', false),
    [combineActions(
      changeCustomerStatus.failure,
      changeCustomerStatus.error,
    )]: state => state
      .set('fetching', false)
      .set('error', true),
    [combineActions(
      changeCustomerStatus.success,
    )]: state => state.set('fetching', false),
  },
  initialState.get('withChangedStatus'),
);

export default combineReducers({
  list: customersReducer,
  one: oneCustomerReducer,
  withChangedStatus: changeCustomerStatusReducer,
});
