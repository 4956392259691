import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import SearchContainer from '../../../containers/Modals/SearchContainer';
import Tooltip from '../../../components/generic/Tooltip';
import { canEditBeneficiary } from '../../../helpers/other';

const BeneficiaryEditFormatter = ({
  cell,
  props: {
    id,
    beneficiaryFirstName,
    beneficiaryLastName,
    isValidated,
  },
  userRole,
  isReadOnlySubUser,
  subRole,
}) => (
  <NavLink
    to={{
      pathname: `/beneficiaries/${id}`,
      state: { beneficiary: id },
    }}
    activeClassName="active"
  >
    <div className="validated-box" data-tip="beneficiary" data-for={`beneficiary-${id}`}>
      <Choose>
        <When condition={cell && cell !== 'n/a'}>
          <SearchContainer text={cell} />
        </When>
        <Otherwise>
          <SearchContainer text={`${beneficiaryFirstName} ${beneficiaryLastName}`} />
        </Otherwise>
      </Choose>
      <If condition={isValidated}>
        <span className="validated-box_marker active">Validated</span>
      </If>
    </div>

    <Choose>
      <When condition={(!isReadOnlySubUser || userRole === 'Admin') && canEditBeneficiary(subRole, isValidated)}>
        <Tooltip id={`beneficiary-${id}`} text="Edit Beneficiary" />
      </When>
      <Otherwise>
        <Tooltip id={`beneficiary-${id}`} text="Beneficiary Info" />
      </Otherwise>
    </Choose>
  </NavLink>
);

export default memo(BeneficiaryEditFormatter);

BeneficiaryEditFormatter.propTypes = {
  cell: PropTypes.string.isRequired,
  props: PropTypes.shape({
    id: PropTypes.string.isRequired,
    beneficiaryFirstName: PropTypes.string.isRequired,
    beneficiaryLastName: PropTypes.string.isRequired,
    isValidated: PropTypes.bool.isRequired,
  }).isRequired,
  subRole: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  isReadOnlySubUser: PropTypes.bool.isRequired,
};
