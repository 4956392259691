import * as yup from 'yup';

import { subtractDateFromCurrent } from '../../helpers/dates';
import { latinFieldWithNumbersAndPunctuationMarks } from '../../helpers/fieldsValidation';

export const ownershipFields = [
  {
    id: 'firstName',
    name: 'firstName',
    label: 'First Name',
    type: 'text',
    validation: true,
    gridArea: '1 / 1 / 2 / 3',
    required: true,
  }, {
    id: 'lastName',
    name: 'lastName',
    label: 'Last Name',
    type: 'text',
    validation: true,
    gridArea: '1 / 3 / 2 / 5',
    required: true,
  }, {
    id: 'typeOfOwnershipId',
    name: 'typeOfOwnershipId',
    label: 'type Of Ownership',
    type: 'select',
    validation: true,
    gridArea: '1 / 5 / 2 / 7',
    required: true,
    list: 'typesOfOwnership',
    defaultSorting: true,
  }, {
    id: 'title',
    name: 'title',
    label: 'Title',
    type: 'text',
    validation: true,
    gridArea: '2 / 1 / 2 / 4',
  }, {
    id: 'dateOfBirth',
    name: 'dateOfBirth',
    label: 'Date of Birth',
    type: 'date',
    validation: true,
    gridArea: '2 / 4 / 2 / 5',
    required: true,
    mustBeAdult: true,
    maxDate: subtractDateFromCurrent(18),
  }, {
    id: 'ownership',
    name: 'ownership',
    label: 'Ownership %',
    type: 'text',
    validation: true,
    gridArea: '2 / 5 / 2 / 7',
    required: true,
  }, {
    id: 'addressLine1',
    name: 'addressLine1',
    label: 'Home Address',
    type: 'geo',
    relativeFieldsForGeo: [
      { key: 'addressLine1', geoKey: 'address' },
      { key: 'postTown', geoKey: 'city' },
      { key: 'stateOrProvince', geoKey: 'state' },
      { key: 'countryId', geoKey: 'country', listId: 'countryId' },
      { key: 'postCode', geoKey: 'zip' },
    ],
    required: true,
    validation: true,
    gridArea: '3 / 1 / 3 / 7',
  },
  {
    id: 'countryId',
    name: 'countryId',
    label: 'Country',
    type: 'select',
    validation: true,
    gridArea: '4 / 1 / 4 / 3',
    required: true,
    list: 'countries',
    defaultSorting: true,
  },
  {
    id: 'postTown',
    name: 'postTown',
    label: 'City',
    type: 'text ',
    validation: true,
    gridArea: '4 / 3 / 4 / 4',
    required: true,
  },
  {
    id: 'stateOrProvince',
    name: 'stateOrProvince',
    label: 'State',
    type: 'text',
    validation: true,
    gridArea: '4 / 4 / 4 / 6',
    required: true,
  },
  {
    id: 'postCode',
    name: 'postCode',
    label: 'Zip',
    type: 'text',
    validation: true,
    gridArea: '4 / 6 / 4 / 7',
    required: true,
  },
  {
    id: 'idNumber',
    name: 'idNumber',
    label: 'Passport/ID Card',
    type: 'text',
    validation: true,
    gridArea: '5 / 1 / 5 / 2',
    required: true,
  },
  {
    id: 'email',
    name: 'email',
    label: 'E-mail',
    type: 'text',
    validation: true,
    gridArea: '5 / 2 / 5 / 4',
    required: true,
  },
  {
    id: 'skypeId',
    name: 'skypeId',
    label: 'Skype Id',
    type: 'text',
    validation: true,
    gridArea: '5 / 4 / 5 / 5',
  },
  {
    id: 'phoneNumber',
    name: 'phoneNumber',
    label: 'Phone Number',
    type: 'phone-number',
    validation: true,
    gridArea: '5 / 5/ 5 / 7',
    required: true,
  },
];

export const ownershipValidationSchema = yup.object({
  firstName: latinFieldWithNumbersAndPunctuationMarks().trim().required('Required!'),
  lastName: latinFieldWithNumbersAndPunctuationMarks().trim().required('Required!'),
  addressLine1: yup.string().required('Required!'),
  postCode: yup.string().required('Required!'),
  countryId: yup.string().required('Required!'),
  stateOrProvince: yup.string().required('Required!'),
  postTown: yup.string().required('Required!'),
  ownership: yup.number().required('Required!'),
  idNumber: yup.string().required('Required!'),
  typeOfOwnershipId: yup.number().required('Required!'),
  email: yup.string().email('email address is not valid').trim().required('Required!'),
  phoneNumber: yup.string().required('Required!'),
  dateOfBirth: yup.date().max(subtractDateFromCurrent(18), 'You must have 18 years old!').required('Required!'),
});
