import { connect } from 'react-redux';
import { memo } from 'react';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { withRouter } from 'react-router-dom';

import FilePreviewModal from '../../components/Modals/FilePreviewModal';

const mapStateToProps = (state) => {
  const { isModalOpen, modalFormInfo: { chosenItemId, width, modalId } } = state.get('modal')
    .toJS();

  return {
    width,
    chosenItemId,
    isModalOpen,
    modalId,
  };
};

export default connect(
  mapStateToProps,
  null,
)(withImmutablePropsToJS(withRouter(memo(FilePreviewModal))));
