import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, isEmpty } from 'lodash';

import { convertDateToLocaleFormat } from '../../../helpers/dates';

const ChatList = (props) => {
  const {
    chatList,
    activeChatId,
    chatStatus,
    onlyUnread,
    period,
    isAdmin,
  } = props;

  const [correctChatList, setCorrectChatList] = useState(chatList);

  const filterChatList = () => {
    // filtered by chatStatus
    let newChatList = cloneDeep(chatList);

    if (chatStatus) {
      newChatList = newChatList.filter(chat => chat.status === chatStatus);
    }

    // filtered by onlyUnread
    if (onlyUnread === 'Unread') {
      newChatList = newChatList.filter(chat => chat.unReadMessages);
    }

    setCorrectChatList(newChatList);
  };

  useEffect(() => {
    filterChatList();
  }, [onlyUnread, chatList]);

  return (
    <div className="chat-list">
      {correctChatList.map((item) => {
        const {
          chatId,
          firstName,
          lastName,
          topic,
          lastMessages,
          postDate,
          unReadMessages,
        } = item;
        return (
          <div className={`chat-list__item ${activeChatId === chatId ? 'active' : ''}`} key={chatId}>
            <If condition={isAdmin}>
              <div className="avatar">
                {firstName[0] + lastName[0]}
              </div>
            </If>
            <div className="chat-data">
              <div className="chat-data__left-side">
                <If condition={isAdmin}>
                  <h4>{`${firstName} ${lastName}`}</h4>
                </If>
                <h5>{topic}</h5>
                <p>{lastMessages}</p>
              </div>
              <div className="chat-data__right-side">
                <p className="time">{convertDateToLocaleFormat(postDate, 'h:mm A')}</p>
                <If condition={unReadMessages}>
                  <div className="unread-messages-circle">{unReadMessages > 99 ? '99+' : unReadMessages}</div>
                </If>
              </div>
            </div>
          </div>
        );
      })}
      <If condition={isEmpty(correctChatList)}>
        <div className="empty-chats-list">
          <p>Not Messages..</p>
        </div>
      </If>
    </div>
  );
};

ChatList.defaultProps = {
  chatList: [],
  activeChatId: null,
};

ChatList.propTypes = {
  chatList: PropTypes.arrayOf(PropTypes.object),
  activeChatId: PropTypes.string,
  isAdmin: PropTypes.bool.isRequired,
};

export default ChatList;
