import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import BeneficiaryPage from '../../../components/Pages/Beneficiary/BeneficiaryPage';
import beneficiariesActions from '../../../actions/beneficiaries';
import listActions from '../../../actions/lists';
import { DefaultChildHeader } from '../../../components/HOC/headerHelper';
import withLists from '../../../components/HOC/withLists';
import {
  newBankTable,
  newBeneficiaryCompanyTable,
  newBeneficiaryIndividualTable,
  newBeneficiaryCompanyValidationSchema,
  newBeneficiaryIndividualValidationSchema,
  newBeneficiarySchema,
} from '../../../schemes/forms/benficiaries/newBeneficiary';
import { getBankCodeType } from '../../../schemes/forms/benficiaries/beneficiaryHelpers';
import { setFieldsToEdit } from '../../../helpers/other';

const {
  createBeneficiary,
  switchBeneficiaryType,
  clearOneBeneficiary,
} = beneficiariesActions.beneficiaries;

const {
  getCustomersList,
  clearLists,
} = listActions.lists;

const mapStateToProps = (state) => {
  const {
    currencies, countries, sepaCountries, routingTypes, ledgerStatuses, enduserTypes, customers,
  } = state.get('lists')
    .toJS();

  const {
    beneficiaryType,
  } = state.getIn(['beneficiaries', 'one'])
    .toJS();

  const setCorrectBeneficiarySchema = (currencyId = '', bankCountryId = '') => {
    if (currencies.data && sepaCountries.data) {
      const bankTypeId = getBankCodeType({
        bankCountryId, currencyId,
      }, currencies.data, sepaCountries.data);

      const optionalFieldsForSepa = ['address', 'city', 'state', 'zip', 'bankAddress', 'bankCity', 'bankCode', 'bankCodeType'];

      switch (bankTypeId) {
        case 1:
        case 2:
        case 4:
        case 7:
          return setFieldsToEdit(newBeneficiarySchema, []);
        case 5:
          return setFieldsToEdit(newBeneficiarySchema, [], optionalFieldsForSepa);
        default:
          return setFieldsToEdit(newBeneficiarySchema, [], ['bankCode', 'bankCodeType']);
      }
    }
    return setFieldsToEdit(newBeneficiarySchema, [], ['bankCode', 'bankCodeType']);
  };

  return {
    beneficiaryTables: [
      beneficiaryType === 'company'
        ? newBeneficiaryCompanyTable
        : newBeneficiaryIndividualTable,
      newBankTable],
    beneficiaryValidationSchema: beneficiaryType === 'company'
      ? newBeneficiaryCompanyValidationSchema(currencies.data, sepaCountries.data)
      : newBeneficiaryIndividualValidationSchema(currencies.data, sepaCountries.data),
    fields: setCorrectBeneficiarySchema(),
    setCorrectBeneficiarySchema,
    data: state.getIn(['beneficiaries', 'one']),
    one: {},
    toPage: 'Beneficiaries ',
    toLink: '/beneficiaries',
    path: 'Create Beneficiary',
    formId: 'create-beneficiary',
    header: DefaultChildHeader,
    editBeneficiary: false,
    beneficiaryType,
    requiredLists: [
      {
        data: {}, // that for list request for every render
        action: getCustomersList.action,
      },
    ],
    lists: {
      currencyId: currencies.data,
      countryId: countries.data,
      sepaCountries: sepaCountries.data,
      bankCountryId: countries.data,
      bankCodeType: routingTypes.data,
      beneficiaryTypeId: enduserTypes.data,
      statusId: ledgerStatuses.data,
      enduserId: customers.data,
    },
  };
};

const mapDispatchToProps = dispatch => ({
  submitForm: (payload) => dispatch(createBeneficiary.action(
    {
      payload,
    },
  )),
  switchBeneficiaryType: payload => dispatch(switchBeneficiaryType(payload)),
  clearData: () => {
    dispatch(clearOneBeneficiary());
    dispatch(clearLists(['customers']));
  },
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(withLists(withImmutablePropsToJS(BeneficiaryPage)));
