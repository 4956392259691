import { makeRequest, apiDownloadFile } from './apiCallHandlers/index';

export default route => ({
  getFiles: ({ companyId = '', projectId = '' }) => makeRequest('get', 'files/', null, { companyId, projectId }),
  // createFile: (form, step = '') => makeRequest('post', `file${step}/`, form),
  createFile: form => makeRequest('post', 'file/', form),
  updateFile: (id, form) => makeRequest('put', 'file/', form, { id }),
  // getOneFile: id => makeRequest('get', 'preview_file/', null, { id }),
  getOneFile: id => makeRequest('get', 'file/', null, { id }),
  deleteFile: id => makeRequest('delete', 'file/', null, { id }),
  downloadFile: link => apiDownloadFile('get', link),
  findPeaks: id => makeRequest('get', 'file/plot/', null, { id }),
});
