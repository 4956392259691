import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { connect } from 'react-redux';

import SuccessfulSetup from './SuccessfulSetup';
import AccountSetupTabsContainer from '../../../containers/AccountSetup/AccountSetupTabsContainer';
import withLists from '../../HOC/withLists';
import listsActions from '../../../actions/lists';

const {
  getCountries,
  getIndustryTypes,
  getTypesOfOwnership,
  getBusinessTypes,
  getDocumentTypes,
} = listsActions.lists;

const AccountSetup = () => (
  <div className="login-layout">
    <Switch>
      <Route exact path='/user' component={SuccessfulSetup}/>
      <Route path='/user/setUp' component={AccountSetupTabsContainer}/>

      <Redirect to='/user/setUp?tab=companyInfo'/>
    </Switch>
  </div>
);

const mapStateToProps = (state) => {
  const { countries, industryTypes, typesOfOwnership, businessTypes, documentTypes } = state.get('lists')
    .toJS();

  return {
    requiredLists: [
      {
        data: industryTypes.data,
        action: getIndustryTypes.action,
      },
      {
        data: documentTypes.data,
        action: getDocumentTypes.action,
      },
      {
        data: countries.data,
        action: getCountries.action,
      },
      {
        data: typesOfOwnership.data,
        action: getTypesOfOwnership.action,
      },
      {
        data: businessTypes.data,
        action: getBusinessTypes.action,
      },
    ],
  };
};

export default connect(mapStateToProps)(withLists(withImmutablePropsToJS(AccountSetup)));
