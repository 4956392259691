import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import AccountSetupStep from '../../components/Pages/AccountSetup/AccountSetupStep';


const mapStateToProps = () => {
  return {
    formName: 'outgoing-activity',
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmit: () => {
      ownProps.nextTab();
    },
  };
};

export default connect(mapStateToProps,
  mapDispatchToProps)(withImmutablePropsToJS(AccountSetupStep));
