import React, { memo, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';


import Button from '../generic/Button';

const ConfirmModalWithReason = ({
  isModalOpen, handleClose, title, submitAction, dispatchAction, id,
}) => {
  const comment = useRef(false);

  const handleSubmit = () => {
    handleClose();
    dispatchAction(submitAction({
      id,
      comment: (comment && comment.current) ? comment.current.value : '',
    }));
  };

  return (
    <Modal
      show={isModalOpen}
      onHide={handleClose}
      className="custom-modal info-modal"
      centered
    >
      <Modal.Header closeButton>
        <h3 className="custom-modal__title">{title}</h3>
      </Modal.Header>
      <Modal.Body>
        <div className="transactions-comment">
          <span>Comment:</span>
          <textarea rows="3" cols="50" ref={comment} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={handleSubmit}
          color="green"
          type="primary"
          size="lg"
          text="Confirm"
        />
        <Button
          onClick={handleClose}
          color="gray"
          type="primary"
          size="lg"
          text="Close"
        />
      </Modal.Footer>
    </Modal>
  );
};

ConfirmModalWithReason.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired,
  dispatchAction: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const { isModalOpen } = state.get('modal').toJS();

  return {
    isModalOpen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchAction: action => dispatch(action),
  };
};


export default memo(connect(mapStateToProps,
  mapDispatchToProps)(withImmutablePropsToJS(ConfirmModalWithReason)));
