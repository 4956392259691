import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


const MenuItem = ({
  icon,
  title,
  onClick,
  withToggleIcon,
  isToggleOpen,
  parentId,
}) => (
  <div
    className={classNames('menu-item', { 'sub-item': parentId })}
    onClick={onClick}
  >
    <div className="menu-item-icon">
      <If condition={icon}>
        <i
          className={`iconfont-${icon} icons-font`}
        />
        {/* eslint-disable-next-line global-require */}
        {/*<img src={require(`../../../../img/icons/${icon}.svg`)} alt="Kiwi standing on oval" />*/}
        {/* <FontAwesomeIcon className="icon" icon={icon} /> */}
      </If>
    </div>
    <div className="text hide-tablet">
      {title}
    </div>
  </div>
);

MenuItem.defaultProps = {
  icon: '',
  onClick: null,
  withToggleIcon: false,
  isToggleOpen: false,
  isParent: false,
  parentId: '',
};
MenuItem.propTypes = {
  // id: PropTypes.string.isRequired,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  withToggleIcon: PropTypes.bool,
  isToggleOpen: PropTypes.bool,
  isParent: PropTypes.bool,
  parentId: PropTypes.string,
};

export default MenuItem;
