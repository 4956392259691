import React, {
  useState, useRef, useEffect, useCallback,
} from 'react';
import moment from 'moment';
import { Popover, Overlay } from 'react-bootstrap';
import { DateRange } from 'react-date-range';
import PropTypes from 'prop-types';

import { defaultDateRange } from '../../constants/constants';
import { convertLocaleDateToUTCFormat } from '../../helpers/dates';

const { dateFrom } = defaultDateRange;

const RangeCalendar = ({
  rangeCalendarAction,
  customDefaultDate,
  hasHours,
  dayFormat,
  ...rest
}) => {
  const [{ startDate, endDate }, setDateRange] = useState(customDefaultDate);

  const [overlayVisibility, setOverlayVisibility] = useState(false);
  const calendarWrapper = useRef();

  const handleClickOutside = useCallback((e) => {
    const popover = document.getElementById('popover-calendar-modal');
    const calendarWrap = calendarWrapper && calendarWrapper.current;

    if (popover && !popover.contains(e.target)
      && calendarWrap && !calendarWrap.contains(e.target)) {
      setOverlayVisibility(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const makeRequestByFilteredDate = () => {
    if (hasHours) {
      rangeCalendarAction({
        dateFromFormatted: convertLocaleDateToUTCFormat(startDate),
        dateToFormatted: convertLocaleDateToUTCFormat(endDate),
        ...rest,
      });
    } else {
      rangeCalendarAction({
        dateFromFormatted: moment(startDate)
          .format('MM.DD.YYYY'),
        dateToFormatted: moment(endDate)
          .format('MM.DD.YYYY'),
        ...rest,
      });
    }

    setOverlayVisibility(false);
  };

  const setStartHours = (e) => {
    const times = e.target.value.split(':');
    const d = startDate.setHours(times[0], times[1]);

    console.log(d, new Date(d));

    setDateRange({
      startDate: new Date(d),
      endDate,
    });
  };

  const setEndHours = (e) => {
    const times = e.target.value.split(':');
    const d = endDate.setHours(times[0], times[1]);

    setDateRange({
      startDate,
      endDate: new Date(d),
    });
  };

  const setDate = ({ select: { startDate: selectStart, endDate: selectEnd } }) => {
    const newStartDate = moment(selectStart)
      .set({ hour: startDate.getHours(), minute: startDate.getMinutes() });

    const newEndDate = moment(selectEnd)
      .set({ hour: endDate.getHours(), minute: endDate.getMinutes() });

    setDateRange({
      startDate: new Date(newStartDate),
      endDate: new Date(newEndDate),
    });
  };

  const startTime = `${moment(startDate)
    .hours()}:${moment(startDate)
    .minutes()}`;
  const endTime = `${moment(endDate)
    .hours()}:${moment(endDate)
    .minutes()}`;

  return (
    <>
      <Overlay
        trigger="click"
        placement="bottom"
        target={calendarWrapper && calendarWrapper.current}
        show={overlayVisibility}
      >
        <Popover className="calendar-modal" id="popover-calendar-modal">
          <div className="calendar-modal_header">
            <p className="calendar-modal_header_title">
              Select date range
            </p>

            <If condition={hasHours}>
              <div className="hours-range">
                <div className="start-time">
                  <label htmlFor="start">From:</label>
                  <input
                    type="time"
                    id="start"
                    defaultValue={startTime}
                    onBlur={setStartHours}
                  />
                </div>

                <div className="end-time">
                  <label htmlFor="end">To:</label>
                  <input
                    type="time"
                    id="end"
                    defaultValue={endTime}
                    onBlur={setEndHours}
                  />
                </div>
              </div>
            </If>

            <i
              className="iconfont-check clickable calendar-trigger"
              onClick={makeRequestByFilteredDate}
            />
          </div>

          <DateRange
            showSelectionPreview
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={[{
              startDate,
              endDate,
              key: 'select',
              showDateDisplay: true,
            }]}
            direction="horizontal"
            onChange={setDate}
            rangeColors={['rgba(0, 157, 0, 0.75)']}
          />
        </Popover>
      </Overlay>

      <div
        className="range-calendar"
        onClick={() => setOverlayVisibility(!overlayVisibility)}
        ref={calendarWrapper}
      >
        <span className="range-calendar_date">
          {`${moment(startDate)
            .format(dayFormat)} - ${moment(endDate)
            .format(dayFormat)}`}
        </span>

        <i className="iconfont-calendar calendar-icon clickable" />
      </div>
    </>
  );
};

RangeCalendar.defaultProps = {
  hasHours: false,
  customDefaultDate: {
    startDate: new Date(dateFrom),
    endDate: new Date(),
  },
  dayFormat: 'D/MMM/YYYY',
};

RangeCalendar.propTypes = {
  rangeCalendarAction: PropTypes.func.isRequired,
  customDefaultDate: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
  ])),
  dayFormat: PropTypes.string,
  hasHours: PropTypes.bool,
};

export default RangeCalendar;
