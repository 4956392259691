import { customFilter } from 'react-bootstrap-table2-filter';
import React from 'react';

import CustomSelectFilter from '../../../components/CustomTable/CustomFilter';
import {
  sortHeaderFormatter,
  dateFormatter,
  priceFormatter,
  statusJournalProps,
  feeProps,
  typeProps,
  transactionInfoIdFormatter,
  journalNameFormatter,
  defaultFormatter,
} from '../../tableUtils/tablesUtils';
import { convertDateToLocaleFormat } from '../../../helpers/dates';
import customSelectionFilter from '../../customSelectionFilter';
import { currencyFormatter } from '../../../helpers/other';
import InfoReason from '../../../components/Layouts/InfoReason';
import { selectUniqValues } from '../../../helpers/status';

const journalColumns = (currentData, subRole) => {
  const transactionsStatuses = selectUniqValues(currentData, 'status');
  const transactionsTypes = selectUniqValues(currentData, 'type');
  const transactionsFeeTypes = selectUniqValues(currentData, 'fee');
  const transactionsChargeTypes = selectUniqValues(currentData, 'isCharge');

  return (
    [
      {
        dataField: 'accountName',
        text: 'Account',
        sort: true,
        classes: 'main-field',
        headerFormatter: sortHeaderFormatter,
        formatter: (cell, props) => journalNameFormatter(cell, props, subRole),
        headerStyle: () => ({ width: '9%' }),
        wpx: 160,
      },
      {
        dataField: 'displayTransactionId',
        text: 'Display Transaction ID',
        sort: true,
        classes: 'transaction-id',
        formatter: transactionInfoIdFormatter,
        headerFormatter: sortHeaderFormatter,
        headerStyle: () => ({ width: '10%' }),
        wpx: 220,
      },
      {
        dataField: 'fee',
        text: 'Fee',
        sort: true,
        headerClasses: 'with-filter',
        formatter: defaultFormatter,
        filter: customFilter({
          onFilter: (filterObj, data) => customSelectionFilter(filterObj, data, 'fee'),
        }),
        filterRenderer: (onFilter, column) => (
          <CustomSelectFilter
            onFilter={onFilter}
            column={column}
            selectItems={transactionsFeeTypes}
            formClass="custom-filter-fee"
          />
        ),
        headerStyle: () => ({ width: '10%' }),
        wpx: 140,
      },
      {
        dataField: 'type',
        text: 'Type',
        sort: true,
        headerClasses: 'with-filter',
        formatter: defaultFormatter,
        filter: customFilter({
          onFilter: (filterObj, data) => customSelectionFilter(filterObj, data, 'type'),
        }),
        filterRenderer: (onFilter, column) => (
          <CustomSelectFilter
            onFilter={onFilter}
            column={column}
            selectItems={transactionsTypes}
            formClass="custom-filter-type"
          />
        ),
        headerStyle: () => ({ width: '7%' }),
        wpx: 120,
      },
      {
        dataField: 'reference',
        text: 'Reference',
        sort: true,
        formatter: defaultFormatter,
        headerFormatter: sortHeaderFormatter,
        headerStyle: () => ({ width: '7%' }),
        wpx: 80,
      },
      {
        dataField: 'isCharge',
        text: 'Charge',
        sort: true,
        headerClasses: 'with-filter',
        filter: customFilter({
          onFilter: (filterObj, data) => customSelectionFilter(filterObj, data, 'isCharge'),
        }),
        filterRenderer: (onFilter, column) =>
          <CustomSelectFilter
            onFilter={onFilter}
            column={column}
            selectItems={transactionsChargeTypes}
            formClass="custom-filter-status"
          />,
        formatter: defaultFormatter,
        headerStyle: () => ({ width: '6%' }),
        wpx: 80,
      },
      {
        dataField: 'beneficiary',
        text: 'Beneficiary',
        sort: true,
        formatter: defaultFormatter,
        headerFormatter: sortHeaderFormatter,
        headerStyle: () => ({ width: '9%' }),
        wpx: 120,
      },
      {
        dataField: 'postDate',
        text: 'Post Date',
        sort: true,
        formatter: (cell, { id }) => dateFormatter(cell, id),
        headerFormatter: sortHeaderFormatter,
        headerStyle: () => ({ width: '6%' }),
        transformationItem: cell => convertDateToLocaleFormat(cell),
        wpx: 120,
      },
      {
        dataField: 'currency',
        text: 'Currency',
        sort: true,
        formatter: defaultFormatter,
        headerFormatter: sortHeaderFormatter,
        headerStyle: () => ({ width: '5%' }),
        wpx: 80,
      },
      {
        dataField: 'amount',
        text: 'Amount',
        sort: true,
        formatter: priceFormatter,
        headerFormatter: sortHeaderFormatter,
        headerStyle: () => ({ width: '5%' }),
        transformationItem: currencyFormatter,
        wpx: 80,
      },
      {
        dataField: 'status',
        text: 'Status',
        sort: true,
        formatter: (cell, { declineReason, id }) => (
          <div className="status-block">
            <span className="content">{cell}</span>
            {(declineReason && declineReason !== 'n/a') && (
              <InfoReason
                declineReason={declineReason}
                id={id}
              />
            )}
          </div>
        ),
        headerStyle: () => ({ width: '7%' }),
        headerClasses: 'with-filter',
        filter: customFilter({
          onFilter: (filterObj, data) => customSelectionFilter(filterObj, data, 'status'),
        }),
        filterRenderer: (onFilter, column) => (
          <CustomSelectFilter
            onFilter={onFilter}
            column={column}
            selectItems={transactionsStatuses}
            formClass="custom-filter-status"
          />
        ),
        wpx: 80,
      }]
  );
};

export default journalColumns;
