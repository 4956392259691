import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import FormWithSelectingTemplate from '../../components/Modals/FormWithSelectingTemplate';
import feesActions from '../../actions/fees';
import accountActions from '../../actions/accounts';

const {
  changeDefaultTemplate,
  cleanDefaultTemplate,
  changeFeesFieldsForLedgerById,
  getFeesByLedgerId,
  changeFeesTemplateForLedgerById,
} = feesActions.fees;

const {
  getJournalsOfAccount,
  getMonthlyBalanceOfAccount,
} = accountActions.accounts;

const mapStateToProps = (state) => {
  const { defaultTemplate, values } = state.getIn(['fees', 'ledgerFees']).toJS();
  const { data } = state.getIn(['lists', 'feesTemplates']).toJS();
  const { feesTemplates, feesRates } = data;
  const { isModalOpen } = state.get('modal').toJS();

  return {
    defaultTemplate,
    isModalOpen,
    dropdownList: feesTemplates,
    propsData: defaultTemplate ? feesRates[defaultTemplate] : values,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDismiss: () => dispatch(cleanDefaultTemplate()),
    updateJournal: (id) => {
      dispatch(getJournalsOfAccount.action({ id }));
      dispatch(getMonthlyBalanceOfAccount.action({ id }));
    },
    onChangeTemplate: templateId => dispatch(changeDefaultTemplate(templateId)),
    onTemplateSelect: templateId => dispatch(changeDefaultTemplate(templateId)),
    onApplyTemplate: payload => dispatch(changeFeesTemplateForLedgerById.action(payload)),
    // onChangeFields: payload => dispatch(changeFeesFieldsForLedgerById.action(payload)),
    loadData: payload => dispatch(getFeesByLedgerId.action(payload)),
    updateField: options => dispatch(changeFeesFieldsForLedgerById.action(options)),
  };
};

export default connect(mapStateToProps,
  mapDispatchToProps)(withImmutablePropsToJS(FormWithSelectingTemplate));
