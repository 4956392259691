import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const InputCheckbox = props => {
  const {
    error,
    name,
    value = false,
    readOnly,
    disabled,
    onChange,
    onBlur,
    id
  } = props;

  return (
    <Choose>
      <When condition={readOnly}>
        <input
          className={'basic-form__group__block__control input disabled-checkbox'}
          type='checkbox'
          id={id}
          name={name}
          checked={value}
          readOnly
        />
      </When>

      <When condition={!readOnly}>
        <input
          className={classNames('basic-form__group__block__control input',
            {
              error: !!error,
            })}
          type='checkbox'
          id={id}
          name={name}
          checked={value}
          onChange={e => onChange(e.target.checked)}
          onBlur={onBlur}
        />
      </When>
    </Choose>
  );
};

InputCheckbox.defaultProps = {
  value: false,
  error: '',
  onChange: null,
  onBlur: null,
  id: '',
  type: 'checkbox',
  multiple: false,
  readOnly: false,
  disabled: false,
  formatter: null,
  // validation: null,
};

InputCheckbox.propTypes = {
  value: PropTypes.bool,
  error: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  id: PropTypes.string,
  type: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  arrows: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  formatter: PropTypes.func,
  // validation: PropTypes.func,
};

export default memo(InputCheckbox);
