export default function customSelectionFilter(filterObj, data, key) {
  const { type = 'text', value = ''} = filterObj;

  // console.log(filterObj, data, key);
  // console.log(typeof value === 'boolean');

  if (typeof value === 'boolean') {
    return data.filter(item => item[key] === value);
  }
  if (value.trim()) {
    if (type === 'text') {
      return data.filter(item => item[key].toLowerCase()
        .indexOf(value.toLowerCase()) !== -1);

    } else if (type === 'select') {
      return data.filter(item => item[key] === value);
    }
  } else {
    return data;
  }
}
