import { combineActions, handleActions } from 'redux-actions';
import { fromJS } from 'immutable';

import { combineReducers } from 'redux-immutable';
import registrationActions from '../actions/registration';

const {
  getRegistrationInfo,
  updateRegistrationInfo,
  deleteContact,
  createContact,
  deleteDocument,
  uploadDocument,
  confirmRegistration,
} = registrationActions.registration;

const initialState = fromJS({
  registrationInfo: {
    data: {},
    error: false,
    fetching: false,
    fetchingFields: [],
  },
});

const registrationInfoReducer = handleActions(
  {
    [confirmRegistration.fetching]: state => state.set('fetching', true),
    [combineActions(
      confirmRegistration.failure,
      confirmRegistration.error,
      confirmRegistration.done,
      confirmRegistration.success,
    )]: state => state.set('fetching', false),
    [combineActions(
      getRegistrationInfo.fetching,
    )]: state => state
      .set('fetching', true)
      .set('error', false)
      .set('data', []),
    // [combineActions(
    //   deleteContact.fetching,
    //   createContact.fetching,
    // )]: state => state
    //   .set('fetching', true)
    //   .set('error', false),
    [combineActions(
      getRegistrationInfo.failure,
      getRegistrationInfo.error,
    )]: state => state
      .set('fetching', false)
      .set('error', true)
      .set('data', []),
    // [combineActions(
    //   deleteContact.failure,
    //   deleteContact.error,
    // )]: state => state
    //   .set('fetching', false)
    //   .set('error', true),
    [getRegistrationInfo.success]: (state, { payload }) => state
      .set('data', fromJS(payload))
      .set('fetching', false),
    [updateRegistrationInfo.fetching]: (state, {
      payload: { fieldName },
    }) => state.updateIn(['fetchingFields'],
      arr => arr.concat([fromJS(fieldName)])),
    [combineActions(
      updateRegistrationInfo.failure,
    )]: (state, {
      errorPayload: { objectName, fieldName },
    }) => {
      if (objectName === 'contacts') {
        return state.set('fetchingFields', state.get('fetchingFields')
          .filter(el => el !== fieldName));
      }

      return state.set('fetchingFields', state.get('fetchingFields')
        .filter(el => el !== fieldName));
    },
    [combineActions(
      updateRegistrationInfo.error,
      updateRegistrationInfo.done,
    )]: state => state.set('fetchingFields', []),
    [updateRegistrationInfo.success]: (state, {
      payload: {
        objectName, fieldName, value, objectId = '',
      },
    }) => {
      if (objectName === 'contacts') {
        const currItem = state.getIn(['data', 'contacts'])
          .findKey(obj => obj.get('id') === objectId);

        return state.setIn(['data', objectName, currItem, fieldName], value)
          .set('fetchingFields', state.get('fetchingFields')
            .filter(el => el !== fieldName));
      }

      return state.setIn(['data', objectName, fieldName], value)
        .set('fetchingFields',
          state.get('fetchingFields')
            .filter(el => el !== fieldName));
    },
    [deleteContact.success]: (state, { payload }) => {
      const currItem = state.getIn(['data', 'contacts'])
        .findKey(obj => obj.get('id') === payload);

      return state.removeIn(['data', 'contacts', currItem]);
    },
    [uploadDocument.fetching]: (state, { payload }) => {
      const documentList = state.getIn(['data', 'documents']);

      const payloadInput = documentList.find(i => i.get('documentType') === payload.documentTypeId);

      const correctDocumentList = documentList
        .map(i => (i.get('documentType') === payload.documentTypeId ? i.set('hide', true) : i));

      return payloadInput
        ? state.setIn(['data', 'documents'], correctDocumentList)
        : state;
    },
    [combineActions(
      uploadDocument.failure,
      uploadDocument.error,
    )]: (state, { payload }) => {
      const { documentTypeId } = payload.errorPayload;
      const documentList = state.getIn(['data', 'documents']);

      const payloadInput = documentList.find(i => i.get('documentType') === documentTypeId);

      const correctDocumentList = documentList
        .map(i => (i.get('documentType') === documentTypeId ? i.delete('hide') : i));

      return payloadInput
        ? state.setIn(['data', 'documents'], correctDocumentList)
        : state;
    },
    [uploadDocument.success]: (state, { payload: { documentTypeList, data } }) => {
      const documentList = state.getIn(['data', 'documents']);
      const documentTypeById = documentTypeList.get(String(data.documentType));

      const isNewItem = documentList.find(i => i.get('documentType') === data.documentType);

      const correctDocumentList = documentList
        .map(i => (i.get('documentType') === data.documentType ? fromJS(data) : i));

      return isNewItem && documentTypeById !== 'Other'
        ? state.setIn(['data', 'documents'], correctDocumentList)
        : state.updateIn(['data', 'documents'], arr => arr.push(fromJS(data)));
    },
    [deleteDocument.success]: (state, { payload }) => {
      const currItem = state.getIn(['data', 'documents'])
        .findKey(obj => obj.get('id') === payload);

      return state.removeIn(['data', 'documents', currItem]);
    },
    [createContact.success]: (state, { payload }) => state.updateIn(['data', 'contacts'], arr => arr.push(fromJS(payload))),
  },
  initialState.get('registrationInfo'),
);

export default combineReducers({
  registrationInfo: registrationInfoReducer,
});
