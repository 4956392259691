import {
  GET_REPORT_FOR_PROCESSED_TRANSACTIONS,
  GET_TRANSACTION_FAILED,
  GET_TRANSACTION_FAILED_OUTSIDE_PRE_SET,
  GET_TRANSACTION_NON_POSTED,
  GET_TRANSACTION_ERP,
} from '../../constants/constants';

import { apiCallAction } from '../actionHelpers';
import { apiCallActionHandler } from '../commonLogic/requestLogic';

const actions = {
  getReportForProcessedTransactions: apiCallAction(GET_REPORT_FOR_PROCESSED_TRANSACTIONS),
  getTransactionFailed: apiCallAction(GET_TRANSACTION_FAILED),
  getTransactionFailedOutsidePreSet: apiCallAction(GET_TRANSACTION_FAILED_OUTSIDE_PRE_SET),
  getTransactionNonPosted: apiCallAction(GET_TRANSACTION_NON_POSTED),
  getTransactionERP: apiCallAction(GET_TRANSACTION_ERP),
};

const getReportForProcessedTransactionsLogic = apiCallActionHandler({
  name: 'getReportForProcessedTransactions',
  fetchAction: actions.getReportForProcessedTransactions,
  makeApiCall(payload, { api }) {
    return api.reports.getReportForProcessedTransactions(payload);
  },
});

const getTransactionFailedLogic = apiCallActionHandler({
  name: 'getReportForProcessedTransactions',
  fetchAction: actions.getTransactionFailed,
  makeApiCall(payload, { api }) {
    return api.reports.getTransactionFailed(payload);
  },
});

const getTransactionFailedOutsidePreSetLogic = apiCallActionHandler({
  name: 'getReportForProcessedTransactions',
  fetchAction: actions.getTransactionFailedOutsidePreSet,
  makeApiCall(payload, { api }) {
    return api.reports.getTransactionFailedOutsidePreSet(payload);
  },
});

const getTransactionNonPostedLogic = apiCallActionHandler({
  name: 'getReportForProcessedTransactions',
  fetchAction: actions.getTransactionNonPosted,
  makeApiCall(payload, { api }) {
    return api.reports.getTransactionNonPosted(payload);
  },
});

const getTransactionERPLogic = apiCallActionHandler({
  name: 'getReportForERPTransactions',
  fetchAction: actions.getTransactionERP,
  makeApiCall(payload, { api }) {
    return api.reports.getTransactionERP(payload);
  },
});

export const logics = [
  getReportForProcessedTransactionsLogic,
  getTransactionFailedLogic,
  getTransactionFailedOutsidePreSetLogic,
  getTransactionNonPostedLogic,
  getTransactionERPLogic,
];

export default actions;
