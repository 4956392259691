import { makeSecureRequest } from './apiCallHandlers';

export default route => ({
  getChartByDay: payload => makeSecureRequest('get', 'Dashboard/ChartByDay',
    null, payload),
  getChartUsersByStatus: payload => makeSecureRequest('get', 'Dashboard/ChartUsersByStatus',
    null, payload),
  getTransactionsCurrenciesCompare: payload => makeSecureRequest('get',
    'Dashboard/ChartByCurrencyDetailed', null, payload),
  getTransactionsByStatusByCurrency: payload => makeSecureRequest('get',
    'Dashboard/ChartByStatusByCurrency', null, payload),
  getTransactionsByStatusByType: payload => makeSecureRequest('get',
    'Dashboard/ChartByStatusByType', null, payload),
});
