import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ChatList from './ChatList';

const NotificationBar = (props) => {
  const {
    unReadMess,
    chatList,
    userRole,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    window.addEventListener('click', (e) => {
      const isBell = e.path.some(p => p.className === 'notification-bell');
      if (isOpen && !isBell) {
        setIsOpen(false);
      }
    });
  }, [isOpen]);

  return (
    <div className="notification-bar">
      <div
        className="notification-bell"
        onClick={() => setIsOpen(!isOpen)}
      >
        <i className="icon iconfont-bell" />
        <If condition={unReadMess}>
          <div className="unReadMessCircle">
            <p>{unReadMess}</p>
          </div>
        </If>
      </div>
      <If condition={isOpen}>
        <div className="notification-bar_container">
          <ChatList
            chatList={chatList}
            isAdmin={userRole === 'Admin'}
          />
        </div>
      </If>
    </div>
  );
};

export default NotificationBar;

NotificationBar.defaultProps = {
  unReadMess: null,
  chatList: [],
};

NotificationBar.propTypes = {
  unReadMess: PropTypes.number,
  chatList: PropTypes.arrayOf(PropTypes.object),
  userRole: PropTypes.string.isRequired,
};
