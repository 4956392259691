import React from 'react';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js/lib/index';
import { withRouter } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';

import customersActions from '../../../actions/customers';
import AccountSetupTabs from '../../../components/Pages/AccountSetup/AccountSetupTabs';
import Header from '../../../components/Layouts/Header';
import { DefaultChildHeader } from '../../../components/HOC/headerHelper';
import { sections } from '../../../schemes/forms/documentUpload';
import {
  personalInfoFields,
  adminUserPersonalInfoFields,
  personalInfoValidationSchema,
} from '../../../schemes/forms/companyInfo';
import {
  ownershipFields,
  ownershipValidationSchema,
} from '../../../schemes/forms/ownership';
import {
  accountActivityFields,
  accountActivityValidationSchema,
} from '../../../schemes/forms/accountActivity';

import {
  profileFields,
  profileValidationSchema,
} from '../../../schemes/forms/profile';

const AccountSetupTabsHOC = ({
  match: { params },
  fetchGetData,
  userRole,
  createContact,
  deleteContact,
  uploadDocument,
  deleteDocument,
  downloadDocument,
  saveCustomerField,
  subRole,
  ...props
}) => {
  const redirectPath = (subRole === 'Admin' || subRole === 'SuperAdmin')
    ? `/customers/edit/${params.id}`
    : `/customers/view/${params.id}`;
  const fetchGetDataFromID = () => fetchGetData(params.id);
  const createContactWithProfileId = () => createContact(params.id);
  const deleteContactWithProfileId = (id) => {
    deleteContact({
      profileId: params.id,
      id,
    });
  };
  const uploadCustomerDocumentWithProfileId = (payload) => {
    uploadDocument({
      ...payload,
      profileId: params.id,
    });
  };
  const deleteCustomerDocumentWithProfileId = (id) => {
    deleteDocument({
      id,
      profileId: params.id,
    });
  };
  const downloadCustomerDocumentWithProfileId = (id) => {
    downloadDocument({
      id,
      profileId: params.id,
    });
  };
  const updateAccountActivityField = options => saveCustomerField({
    profileId: params.id,
    objectName: 'accountActivity',
    ...options,
  });
  const updateCompanyInfoField = options => saveCustomerField({
    profileId: params.id,
    objectName: 'enduser',
    ...options,
  });
  const updateOwnershipField = options => saveCustomerField({
    profileId: params.id,
    objectName: 'contacts',
    ...options,
  });
  const updateProfileField = options => saveCustomerField({
    profileId: params.id,
    objectName: 'limits',
    ...options,
  });

  return (
    <div className="main_padding">
      <Header>
        <DefaultChildHeader path="Customer Profile" toPage="Customers " toLink="/customers" />
      </Header>
      <AccountSetupTabs
        {...props}
        userRole={userRole}
        profileId={params.id}
        redirectPath={redirectPath}
        fetchGetData={fetchGetDataFromID}
        createContact={createContactWithProfileId}
        deleteContact={deleteContactWithProfileId}
        uploadDocument={uploadCustomerDocumentWithProfileId}
        deleteDocument={deleteCustomerDocumentWithProfileId}
        downloadDocument={downloadCustomerDocumentWithProfileId}
        updateAccountActivityField={updateAccountActivityField}
        updateCompanyInfoField={updateCompanyInfoField}
        updateOwnershipField={updateOwnershipField}
        updateProfileField={updateProfileField}
        enduserId={params.id}
      />
    </div>
  );
};

const {
  getOneCustomer,
  updateCustomerField,
  downloadCustomerDocument,
  uploadCustomerDocument,
  deleteCustomerContact,
  createCustomerContact,
  deleteCustomerDocument,
} = customersActions.customers;

const mapStateToProps = (state, { match: { params: { id } } }) => {
  const currentState = state.getIn(['customers', 'one']);

  const fetchingFields = currentState.get('fetchingFields');

  const role = state.getIn(['userInfo', 'data', 'role']);
  const subRole = state.getIn(['userInfo', 'data', 'subRole']);

  const enduserStatus = currentState.getIn(['data', 'enduser', 'status']);

  const { userCustomers } = state.get('userInfo').toJS();

  const newSections = cloneDeep(sections);

  const customerSections = newSections.map((item) => {
    const schemaObj = cloneDeep(item.schema);
    const schemaKeys = Object.keys(schemaObj);
    schemaKeys.forEach((el) => {
      const elem = schemaObj[el];

      if (subRole === 'ReadOnlyAdmin' || (role === 'User' && userCustomers[id] !== 'User') || (enduserStatus !== 'Active' && subRole === 'Admin')) {
        elem.properties.readOnly = true;
      }

      if (elem.type === 'modal') {
        elem.properties.modalId = `${elem.properties.modalId}-customer`;

        schemaObj[el] = elem;
      }

      schemaObj[el] = elem;
    });

    return ({
      ...item,
      schema: schemaObj,
    });
  });

  let correctPersonalInfoFields;
  let correctOwnershipFields;
  let correctAccountActivityFields;
  let correctProfileFields;

  const changeFieldsForUser = (fields) => {
    const newFields = cloneDeep(fields);

    return newFields.map((item) => {
      item.validation = false;
      item.readOnly = true;

      return item;
    });
  };

  if (subRole === 'ReadOnlyAdmin' || (enduserStatus !== 'Active' && subRole === 'Admin')) {
    correctPersonalInfoFields = changeFieldsForUser([
      ...personalInfoFields,
      ...adminUserPersonalInfoFields,
    ]);
    correctOwnershipFields = changeFieldsForUser(ownershipFields);
    correctAccountActivityFields = changeFieldsForUser(accountActivityFields);
    correctProfileFields = changeFieldsForUser(profileFields);
  } else if (role === 'Admin') {
    correctPersonalInfoFields = cloneDeep([
      ...personalInfoFields,
      ...adminUserPersonalInfoFields,
    ]);
    correctOwnershipFields = cloneDeep(ownershipFields);
    correctAccountActivityFields = cloneDeep(accountActivityFields);
    correctProfileFields = cloneDeep(profileFields);
  } else {
    correctPersonalInfoFields = changeFieldsForUser(personalInfoFields);
    correctOwnershipFields = changeFieldsForUser(ownershipFields);
    correctAccountActivityFields = changeFieldsForUser(accountActivityFields);
  }

  return {
    data: currentState,
    userRole: role,
    specialTabsClass: 'customer-profile',
    withRemovingPermission: (enduserStatus === 'Active' && subRole === 'Admin') || subRole === 'SuperAdmin',
    documentsSections: customerSections,
    personalInfoFields: correctPersonalInfoFields,
    personalInfoValidationSchema,
    ownershipFields: correctOwnershipFields,
    ownershipValidationSchema,
    accountActivityFields: correctAccountActivityFields,
    accountActivityValidationSchema,
    profileFields: correctProfileFields,
    profileValidationSchema,
    subRole,
    fetchingFields,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchGetData: id => dispatch(getOneCustomer.action(id)),
  uploadDocument: payload => dispatch(uploadCustomerDocument.action(payload)),
  deleteDocument: id => dispatch(deleteCustomerDocument.action(id)),
  downloadDocument: id => dispatch(downloadCustomerDocument.action(id)),
  saveCustomerField: options => dispatch(updateCustomerField.action(options)),
  createContact: id => dispatch(createCustomerContact.action(id)),
  deleteContact: id => dispatch(deleteCustomerContact.action(id)),
});

AccountSetupTabsHOC.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  fetchGetData: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
  subRole: PropTypes.string.isRequired,
  createContact: PropTypes.func.isRequired,
  deleteContact: PropTypes.func.isRequired,
  uploadDocument: PropTypes.func.isRequired,
  deleteDocument: PropTypes.func.isRequired,
  downloadDocument: PropTypes.func.isRequired,
  saveCustomerField: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps,
  mapDispatchToProps)(withImmutablePropsToJS(AccountSetupTabsHOC)));
