import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import listsActions from '../../../actions/lists';
import withLists from '../../HOC/withLists';
import CustomersContainer from '../../../containers/Pages/Customers/CustomersContainer';
import CustomerSetupContainer
  from '../../../containers/Pages/Customers/CustomerSetupContainer';
import userActions from '../../../actions/user';

const {
  getCountries,
  getIndustryTypes,
  getTypesOfOwnership,
  getBusinessTypes,
  getDocumentTypes,
} = listsActions.lists;

const {
  getUserCustomers,
} = userActions.user;

const Customers = ({ userRole, ...props }) => (
  <Switch>
    <Route exact path="/customers/" component={CustomersContainer} />
    <Choose>
      <When condition={(userRole === 'Admin' || userRole === 'SuperAdmin')}>
        <Route path="/customers/edit/:id" component={CustomerSetupContainer} />
      </When>
      <Otherwise>
        <Route path="/customers/view/:id" component={CustomerSetupContainer} />
      </Otherwise>
    </Choose>

    <Redirect to="/customers/" />
  </Switch>
);

const mapStateToProps = (state) => {
  const {
    countries, industryTypes, typesOfOwnership, businessTypes, documentTypes,
  } = state.get('lists')
    .toJS();
  const { userCustomers } = state.get('userInfo').toJS();

  const userRole = state.getIn(['userInfo', 'data', 'subRole']);
  const role = state.getIn(['userInfo', 'data', 'role']);

  const lists = [
    {
      data: industryTypes.data,
      action: getIndustryTypes.action,
    },
    {
      data: documentTypes.data,
      action: getDocumentTypes.action,
    },
    {
      data: countries.data,
      action: getCountries.action,
    },
    {
      data: typesOfOwnership.data,
      action: getTypesOfOwnership.action,
    },
    {
      data: businessTypes.data,
      action: getBusinessTypes.action,
    },
  ];
  const listsForUser = [
    {
      data: userCustomers,
      action: getUserCustomers.action,
    },
  ];

  const requiredLists = role === 'Admin' ? lists : [...lists, ...listsForUser];

  return {
    userRole,
    requiredLists,
  };
};

export default connect(mapStateToProps)(withLists(withImmutablePropsToJS(Customers)));
