import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import React from 'react';

import SelectionTransactionFormatter
  from '../../lib/selectionTransactionFormatters/SelectionTransactionFormatter';
import feesChargesActions from '../../actions/feesCharges';

const {
  selectFee,
} = feesChargesActions.feesCharges;

const mapStateToProps = (state) => {
  const {
    selected,
    fetching,
    inProcessing,
  } = state.getIn(['feesCharges', 'feeStatusChanging']).toJS();

  return {
    selected,
    inProcessing,
    fetching,
  };
};

const SelectionFeesChargesFormatterContainer = connect(mapStateToProps,
  { selectTransaction: selectFee })(withImmutablePropsToJS(SelectionTransactionFormatter));


export default (cell, transaction) => (
  <SelectionFeesChargesFormatterContainer
    transaction={transaction}
  />
);
