import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { NavLink, withRouter } from 'react-router-dom';

import MenuItem from './MenuItem';
import { getSubItemsByParentId } from '../../../router/routerItems/mainRoutes';

const MenuItemCollapsible = ({
  id,
  title,
  path,
  icon,
  history,
  location,
  role,
  subRole,
  ...rest
}) => {
  const isAdminRoute = getSubItemsByParentId(id, role, subRole)
    .some(it => it.path === location.pathname);

  const [isOpen, setToggle] = useState(isAdminRoute);
  const toggleMenu = () => setToggle(!isOpen);

  useEffect(() => {
    if (!isAdminRoute) {
      setToggle(false);
    }
  }, [location]);

  return (
    <span className="parent">
      <MenuItem
        onClick={toggleMenu}
        withToggleIcon
        isToggleOpen={isOpen}
        title={title}
        icon={icon}
        {...rest}
      />
      <If condition={isOpen}>
        <div className="subnav">
          {
            map(getSubItemsByParentId(id, role, subRole), ({
              path,
              icon,
              title,
              parentId,
            }) => (
              <NavLink key={title} to={path} activeClassName="active">
                <MenuItem icon={icon} title={title} parentId={parentId} />
              </NavLink>
            ))
          }
        </div>
      </If>
    </span>
  );
};

MenuItemCollapsible.defaultProps = {
  icon: '',
};

MenuItemCollapsible.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  icon: PropTypes.string,
  history: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  role: PropTypes.string.isRequired,
  subRole: PropTypes.string.isRequired,
};

export default withRouter(memo(MenuItemCollapsible));
