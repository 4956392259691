import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

import Button from '../generic/Button';
import CustomSpinner from '../Spinners/CustomSpinner';
import InputsWrapper from '../generic/InputsWrapper';
import UniversalInputsForm from '../generic/UniversalInputsForm';

const FormWithSelectingTemplate = ({
  isModalOpen,
  handleClose,
  title,
  submitAction,
  modalId,
  fetching,
  submitBtnText,
  closeBtnText,
  hideSubmitBtn,
  hideCloseBtn,
  defaultTemplate,
  dropdownList,
  onDismiss,
  fields,
  onChangeTemplate,
  onApplyTemplate,
  loadData,
  updateField,
  values,
  propsData,
  id: ledgerId,
  updateJournal,
  ...props
}) => {
  const [updatedFieldsOfTemplate, setUpdatedFieldsOfTemplate] = useState({});

  useEffect(() => {
    loadData({ id: ledgerId });

    return () => {
      updateJournal(ledgerId);
    };
  }, [ledgerId]);

  const onBlurPost = useCallback(({ name, value }) => {
    if (defaultTemplate) {
      updatedFieldsOfTemplate[name] = value;
      setUpdatedFieldsOfTemplate(updatedFieldsOfTemplate);
    } else {
      updateField({
        ledgerId,
        feeName: name,
        feeValue: value,
      });
    }
  }, [ledgerId, defaultTemplate, updatedFieldsOfTemplate]);

  const applyTemplate = useCallback(() => {
    onApplyTemplate({
      ledgerId,
      templateId: defaultTemplate,
      updatedFields: updatedFieldsOfTemplate,
    });
    setUpdatedFieldsOfTemplate({});
  }, [ledgerId, defaultTemplate, updatedFieldsOfTemplate]);

  const handleChangeTemplate = useCallback((e) => {
    onChangeTemplate(e.target.value);
  }, []);

  return (
    <Modal
      show={isModalOpen}
      onHide={handleClose}
      centered
      className={`custom-modal ${modalId} select-template-form`}
      // className={`custom-modal right-labels ${modalId} select-template-form`}
    >
      <Modal.Header closeButton>
        <h3 className="custom-modal__title">{title}</h3>
      </Modal.Header>

      <Modal.Body>
        <div className="select-template-input">
          <InputsWrapper
            name="pricingTemplate"
            value={defaultTemplate || ''}
            dropdownList={dropdownList}
            type="select"
            label="Pricing Template"
            handleChange={handleChangeTemplate}
            withLabel
            placeholder="Select Pricing Template"
          />
        </div>
        <div className="custom-modal__content">
          <CustomSpinner fetching={fetching} />
          <div className={`info-table ${!fetching ? 'content--show' : 'content--hide'}`}>
            <UniversalInputsForm
              onBlurPost={onBlurPost}
              formId={modalId}
              fields={fields}
              values={values}
              propsData={propsData}
              {...props}
            />
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <If condition={!hideSubmitBtn}>
          <Button
            color="gray"
            type="primary"
            size="lg"
            submit
            form={modalId}
            text={submitBtnText}
          />
        </If>

        <If condition={defaultTemplate}>
          <Button
            color="gray"
            type="primary"
            size="lg"
            disabled={fetching}
            onClick={applyTemplate}
            form={modalId}
            text="Apply template"
          />
          <Button
            color="gray"
            type="primary"
            size="lg"
            disabled={fetching}
            onClick={onDismiss}
            text="Dismiss"
          />
        </If>

        <If condition={!hideCloseBtn}>
          <Button
            onClick={handleClose}
            color="gray"
            type="primary"
            size="lg"
            text={closeBtnText}
          />
        </If>
      </Modal.Footer>
    </Modal>
  );
};

export default memo(FormWithSelectingTemplate);

FormWithSelectingTemplate.defaultProps = {
  submitAction: () => {},
  submitBtnText: '',
  closeBtnText: '',
  hideSubmitBtn: false,
  hideCloseBtn: false,
  defaultTemplate: null,
  values: {},
};

FormWithSelectingTemplate.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  updateJournal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  submitAction: PropTypes.func,
  modalId: PropTypes.string.isRequired,
  values: PropTypes.shape({}),
  fetching: PropTypes.bool.isRequired,
  submitBtnText: PropTypes.string,
  closeBtnText: PropTypes.string,
  hideSubmitBtn: PropTypes.bool,
  hideCloseBtn: PropTypes.bool,
  defaultTemplate: PropTypes.string,
  dropdownList: PropTypes.objectOf(PropTypes.string).isRequired,
  onDismiss: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChangeTemplate: PropTypes.func.isRequired,
  onApplyTemplate: PropTypes.func.isRequired,
  loadData: PropTypes.func.isRequired,
  updateField: PropTypes.func.isRequired,
  propsData: PropTypes.objectOf(PropTypes.number).isRequired,
  id: PropTypes.string.isRequired,
};
