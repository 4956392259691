import config from 'config';
import {
  set, assignIn, forEach, isArray, isEmpty, cloneDeep,
} from 'lodash';

import { downloadBlob } from '../../helpers/other';

import { UserError, ServerError } from './errors';

const { API_URL } = config;

export const getRequestUrl = (path, params) => {
  const url = new URL(`${API_URL}/api/${path}`);

  if (params) {
    forEach(params, (val, key) => {
      if (isArray(val)) {
        forEach(val, (v) => {
          url.searchParams.append(key, v);
        });
      } else {
        url.searchParams.append(key, val);
      }
    });
  }

  return url;
};

const serverResponseProcessing = (url, options, resType = 'json') => new Promise((res, rej) => {
  let filename = '';

  fetch(url, options)
    .then(async (response) => {
      if (!response.ok) {
        const error = new Error();
        error.statusCode = response.status;
        throw error;
      }

      if (resType === 'file') {
        const disposition = response.headers.get('content-disposition');
        if (disposition && disposition.indexOf('attachment') !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
        }

        const data = await response.blob();

        if (data.type === 'application/json') {
          const text = JSON.parse(await data.text());

          if (text.hasOwnProperty('isSuccessStatusCode')) {
            const err = new UserError({
              message: text.message,
              statusCode: text.statusCode,
              reasonPhrase: text.reasonPhrase,
            });
            return rej(err);
          }

          return data;
        }

        return data;
      }

      return response.json();
    })
    .then((result) => {
      const {
        isSuccessStatusCode,
        statusCode,
        content,
        error: message,
        reasonPhrase,
      } = result;

      if (resType === 'file') {
        downloadBlob(result, filename);
        res({
          statusCode: 200,
        });
      }

      if (!isSuccessStatusCode) {
        const err = new UserError({
          message,
          statusCode,
          reasonPhrase,
        });
        rej(err);
      } else {
        res({
          data: content,
          statusCode,
        });
      }
    })
    .catch(({ message, statusCode, reasonPhrase }) => {
      if (statusCode >= 400 && statusCode < 500) {
        const error = new UserError({
          message,
          statusCode,
          reasonPhrase,
        });
        return rej(error);
      }
      return rej(new ServerError({
        message,
        statusCode,
        reasonPhrase,
      }));
    });
});

const serverResponseFileWithProgress = (url, {
  method, headers, body,
}, dispatchProgress, name, documentTypeId) =>
  // TODO: replace with async/await
  new Promise((res, rej) => {
    const xhr = new XMLHttpRequest();

    xhr.upload.onprogress = function ({ loaded, total }) {
      const percent = Math.floor(loaded * 100 / total);
      if (!isEmpty(dispatchProgress)) {
        dispatchProgress.addProgressItem({ documentTypeId, percent });
      }
    };

    xhr.onload = xhr.onerror = function () {
      try {
        const {
          isSuccessStatusCode,
          statusCode = 500,
          content,
          error: message = 'unexpected error render for backend',
          reasonPhrase = 'unexpected error render for backend',
        } = JSON.parse(this.response);
        if (this.status >= 200 && this.status < 300) {
          if (!isSuccessStatusCode) {
            const err = new UserError({
              message,
              statusCode,
              reasonPhrase,
            });
            rej(err);
          } else {
            res({
              data: content,
              statusCode,
            });
          }
        } else if (this.status >= 400 && this.status < 500) {
          const error = new UserError({
            message: 'message',
            statusCode: this.status,
            reasonPhrase: 'unexpected error',
          });
          return rej(error);
        }
        dispatchProgress.deleteProgressItem({ documentTypeId });
      } catch (err) {
        const error = new ServerError({
          message: 'unexpected error render for backend',
          statusCode: this.status,
          reasonPhrase: 'unexpected error render for backend',
        });
        return rej(error);
      }
    };

    xhr.open(method, url, true);
    const headersValues = Object.keys(headers);

    headersValues.forEach((item) => {
      xhr.setRequestHeader(item, headers[item]);
    });
    xhr.send(body);
  });

export const makeSecureRequest = (method = 'get', path = '', data = null, params = null) => {
  const url = getRequestUrl(path, params);
  const options = {
    method,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
      'access-control-allow-credentials': true,
      'Access-Control-Allow-Origin': '*',
    },
  };

  if (data) {
    if (data instanceof FormData) {
      set(options, 'body', data);
    } else {
      set(options, 'body', JSON.stringify(data));
      assignIn(options.headers, {
        'Content-Type': 'application/json',
      });
    }
  }

  return serverResponseProcessing(url, options);
};

export const apiDownloadFile = (method = 'GET', path = '', data = null, params = null) => {
  const url = getRequestUrl(path, params);
  const options = {
    method,
    body: data,
    // credentials: 'include',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
      'access-control-allow-credentials': true,
      'Access-Control-Allow-Origin': '*',
    },
  };

  return serverResponseProcessing(url, options, 'file');
};

export const makeSecureRequestWithFormData = (method = 'get', path = '', data = null, params = null, dispatchProgress) => {
  const url = getRequestUrl(path, params);
  const { name } = data.formFile;
  const form = new FormData();
  // TODO: Duplicate the same
  // request logic >226, except progress bar >271
  forEach(data, (value, key) => form.append(key, value));

  const options = {
    method,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
      'access-control-allow-credentials': true,
      'Access-Control-Allow-Origin': '*',
    },
    body: form,
  };

  return serverResponseFileWithProgress(url, options, dispatchProgress, name, data.documentTypeId);
};

export const makeRequest = (method = 'get', path = '', data = null, params = null) => {
  const url = getRequestUrl(path, params);

  const options = {
    method,
    headers: {
      'access-control-allow-credentials': true,
      'Access-Control-Allow-Origin': '*',
    },
    // credentials: 'include',
  };

  if (data) {
    if (data instanceof FormData) {
      set(options, 'body', data);
    } else {
      set(options, 'body', JSON.stringify(data));
      assignIn(options.headers, {
        'Content-Type': 'application/json',
      });
    }
  }

  return serverResponseProcessing(url, options);
};
