import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import RegisterForm from '../../components/Auth/RegisterForm';

import userActions from '../../actions/user';
import { registrationFieldsForSubUser, registrationValidationSchema } from '../../components/Auth/LoginFormConfig';

const { getOneUser, completeSubUser, logout } = userActions.user;

const mapStateToProps = state => ({
  fetching: state.getIn(['users', 'one', 'fetching']),
  id: state.getIn(['userInfo', 'data', 'sub']),
  formId: 'registration-sub-user-form',
  title: 'Register a new user',
  fields: registrationFieldsForSubUser,
  registrationValidationSchema,
  data: state.getIn(['users', 'one', 'data']),
  isLogout: true,
});

export default connect(mapStateToProps, {
  getData: getOneUser.action,
  userRegister: completeSubUser.action,
  logoutFunc: logout,
})(withImmutablePropsToJS(RegisterForm));
