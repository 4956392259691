import * as yup from 'yup';
import { addReadOnlyProperties } from '../../helpers/other';

export const beneficiary = {
  title: 'Beneficiary',
  fields: [
    'beneficiaryName',
    'beneficiaryAddress',
    'beneficiaryCity',
    'beneficiaryCountry',
    'beneficiaryAccountNumber',
    'bankCodeType',
    'bankCode',
    'swiftCode',
    'beneficiaryBankName',
    'beneficiaryBankAddress',
    'beneficiaryBankCity',
    'beneficiaryBankCountry',
  ],
};

export const account = {
  title: 'Account & Transaction',
  fields: [
    'accountName',
    'accountNumber',
    'accountCurrency',
    'transferAmount',
    'reference',
    'postDate',
    'status',
    'paymentCurrency',
    'sendInBeneficiaryCurrency',
    'isUrgent',
  ],
};

export const propertiesTable = [
  {
    id: 'beneficiaryName',
    name: 'beneficiaryName',
    label: 'Name',
    type: 'text',
    validation: true,
  },
  {
    id: 'beneficiaryAddress',
    name: 'beneficiaryAddress',
    label: 'Address',
    type: 'text',
    validation: true,
  },
  {
    id: 'beneficiaryCity',
    name: 'beneficiaryCity',
    label: 'City',
    placeholder: '',
    type: 'text',
    validation: true,
  },
  {
    id: 'beneficiaryCountry',
    name: 'beneficiaryCountry',
    label: 'Country',
    placeholder: '',
    type: 'text',
    validation: true,
  },
  {
    id: 'beneficiaryAccountNumber',
    name: 'beneficiaryAccountNumber',
    label: 'Account Number',
    type: 'text',
    validation: true,
  },
  {
    id: 'bankCodeType',
    name: 'bankCodeType',
    label: 'Bank Code Type',
    type: 'text',
    validation: true,
  },
  {
    id: 'bankCode',
    name: 'bankCode',
    label: 'Bank Code',
    type: 'text',
    validation: true,
  },
  {
    id: 'swiftCode',
    name: 'swiftCode',
    label: 'swiftCode',
    type: 'text',
    validation: true,
  },
  {
    id: 'beneficiaryBankName',
    name: 'beneficiaryBankName',
    label: 'Bank Name',
    type: 'text',
    validation: true,
  },
  {
    id: 'beneficiaryBankAddress',
    name: 'beneficiaryBankAddress',
    label: 'Bank Address',
    type: 'text',
    validation: true,
  },
  {
    id: 'beneficiaryBankCity',
    name: 'beneficiaryBankCity',
    label: 'Bank City',
    type: 'text',
    validation: true,
  },
  {
    id: 'beneficiaryBankCountry',
    name: 'beneficiaryBankCountry',
    label: 'Bank Country',
    type: 'text',
    validation: true,
  },
  {
    id: 'accountName',
    name: 'accountName',
    label: 'Name',
    type: 'text',
    validation: true,
  },
  {
    id: 'accountNumber',
    name: 'accountNumber',
    label: 'Number',
    placeholder: '',
    type: 'text',
    validation: true,
  },
  {
    id: 'accountCurrency',
    name: 'accountCurrency',
    label: 'Account Currency',
    placeholder: '',
    type: 'text',
    validation: true,
  },
  {
    id: 'transferAmount',
    name: 'transferAmount',
    label: 'transfer Amount',
    placeholder: '',
    type: 'text',
    validation: true,
  },
  {
    id: 'reference',
    name: 'reference',
    label: 'reference',
    type: 'text',
    validation: true,
  },
  {
    id: 'postDate',
    name: 'postDate',
    label: 'post date',
    type: 'date',
    validation: true,
  },
  {
    id: 'paymentCurrency',
    name: 'paymentCurrency',
    label: 'Payment currency',
    placeholder: '',
    type: 'text',
    validation: true,
  },
  {
    id: 'status',
    name: 'status',
    label: 'Status',
    placeholder: '',
    type: 'text',
    validation: true,
  },
  {
    id: 'sendInBeneficiaryCurrency',
    name: 'sendInBeneficiaryCurrency',
    label: 'send In Beneficiary Currency',
    placeholder: '',
    type: 'checkbox',
    validation: true,
  },
  {
    id: 'isUrgent',
    name: 'isUrgent',
    label: 'is Urgent',
    type: 'checkbox',
    validation: true,
  },
];

export const readOnlyPropertiesTable = addReadOnlyProperties(propertiesTable);

export const transactionsValidationSchema = yup.object({
  beneficiaryBankName: yup.string().required('Required!'),
});
