import React, {
  useState,
  useEffect,
  useCallback,
  memo,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  isEmpty,
  isEqual,
  isNull,
  omit,
  isString,
} from 'lodash';

const InputStandart = ({
  error,
  type,
  multiple,
  id,
  name,
  value,
  placeholder,
  propsValue,
  autoComplete,
  readOnly,
  disabled,
  onChange,
  onBlurPost,
  rows,
  cols,
  min,
  max,
}) => {
  const [stateValue, setStateValue] = useState(value);

  useEffect(() => {
    setStateValue(value);
  }, [value]);

  const validateMinMax = (val) => {
    if ((val < min) && !isNull(min) && !isEmpty(val)) {
      return min;
    } if ((val > max) && !isNull(max) && !isEmpty(val)) {
      return max;
    }
    return val;
  };

  const onBlurValue = useCallback((e) => {
    if (!onBlurPost || isEqual(propsValue, stateValue) || (!isString(propsValue) && stateValue === '')) {
      e.preventDefault();
      return;
    }

    onBlurPost({
      name,
      value: stateValue,
    });
  }, [stateValue, propsValue]);

  const onChangeValue = useCallback(({ target, target: { files } }) => {
    if (type === 'file') {
      setStateValue(files);
    } else if (type === 'number') {
      setStateValue(parseInt(target.value));
    } else {
      setStateValue(validateMinMax(target.value));
    }

    onChange(target.value);
  }, []);

  return (
    <Choose>
      <When condition={type === 'file'}>
        <input
          className={classNames('basic-form__group__block__control input',
            {
              error: !!error,
            })}
          type={type}
          multiple={multiple}
          id={id}
          name={name}
          onChange={onChange}
          readOnly={readOnly}
          disabled={disabled}
        />
      </When>
      <When condition={type === 'textarea'}>
        <textarea
          rows={rows}
          cols={cols}
          className={classNames('basic-form__group__block__control textarea',
            {
              error: !!error,
            })}
          id={id}
          name={name}
          value={stateValue}
          onChange={onChangeValue}
          placeholder={placeholder}
          autoComplete={autoComplete}
          readOnly={readOnly}
          disabled={disabled}
          onBlur={onBlurValue}
        />
      </When>
      <Otherwise>
        <input
          className={classNames('basic-form__group__block__control input',
            {
              error: !!error,
            })}
          type={type}
          id={id}
          name={name}
          value={stateValue}
          onChange={onChangeValue}
          placeholder={placeholder}
          autoComplete={autoComplete}
          readOnly={readOnly}
          disabled={disabled}
          onBlur={onBlurValue}
        />
      </Otherwise>
    </Choose>
  );
};

InputStandart.defaultProps = {
  value: '',
  error: '',
  onChange: null,
  autoComplete: '',
  id: '',
  type: 'text',
  multiple: false,
  placeholder: '',
  min: null,
  max: null,
  readOnly: false,
  disabled: false,
  onBlurPost: null,
  rows: 3,
  cols: 100,
  propsValue: null,
};

InputStandart.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  error: PropTypes.string,
  onChange: PropTypes.func,
  autoComplete: PropTypes.string,
  onBlurPost: PropTypes.func,
  id: PropTypes.string,
  type: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  rows: PropTypes.number,
  cols: PropTypes.number,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  propsValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default memo(InputStandart);
