import * as yup from 'yup';

import {
  latinFieldWithNumbersAndPunctuationMarks,
  moneyField,
  // transactionsMoneyField,
} from '../../helpers/fieldsValidation';

const journalFields = [
  {
    id: 'isCharge',
    name: 'isCharge',
    label: 'Type',
    type: 'custom_toggle',
  },
  {
    id: 'accountId',
    name: 'accountId',
    label: 'Account',
    placeholder: 'Select account',
    type: 'select',
    defaultSorting: true,
    required: true,
  },
  {
    id: 'feeId',
    name: 'feeId',
    label: 'Fee',
    placeholder: 'Select fee',
    type: 'select',
    defaultSorting: true,
    required: true,
  },
  {
    id: 'reference',
    name: 'reference',
    label: 'Reference',
    placeholder: '',
    type: 'text',
    required: true,
    infoDescription: 'Should be less than 100 characters',
  },
  {
    id: 'postDate',
    name: 'postDate',
    label: 'Post Date',
    placeholder: '',
    type: 'date',
    required: true,
  },
  {
    id: 'statusId',
    name: 'statusId',
    label: 'Status',
    placeholder: 'Select status',
    type: 'select',
    defaultSorting: true,
    required: true,
  },
];

export const journalFieldsForTransactions = [
  ...journalFields,
  {
    id: 'beneficiaryId',
    name: 'beneficiaryId',
    label: 'Beneficiary',
    placeholder: 'Select beneficiary',
    type: 'select',
    defaultSorting: true,
  },
  {
    id: 'amount',
    name: 'amount',
    label: 'Amount',
    placeholder: '',
    type: 'currency',
    required: true,
  },
  {
    id: 'type',
    name: 'type',
    label: 'Payment Type',
    placeholder: 'Select type',
    type: 'select',
    defaultSorting: true,
    required: true,
  },
];

export const journalFieldsForCharge = [
  ...journalFields,
  {
    id: 'amount',
    name: 'amount',
    label: 'Amount',
    placeholder: '',
    type: 'currency',
    required: true,
  },
  {
    id: 'type',
    name: 'type',
    label: 'Payment Type',
    placeholder: '',
    type: 'select',
    defaultSorting: true,
    readOnly: true,
  },
];

const journalValidationSchema = {
  accountId: yup.string()
    .required('Required!'),
  feeId: yup.string()
    .required('Required!'),
  type: yup.string()
    .trim()
    .required('Required!'),
  reference: latinFieldWithNumbersAndPunctuationMarks()
    .max(100, 'Too long, should be less than 100 characters')
    .trim()
    .required('Required!'),
  postDate: yup.date()
    .required('Required!'),
  amount: moneyField()
    .required('Required!'),
};

export const journalValidationSchemaForCharge = (/* feeList */) => yup.object({
  ...journalValidationSchema,
  // amount: transactionsMoneyField(feeList),
});

export const journalValidationSchemaForTransactions = (/* feeList */) => yup.object({
  ...journalValidationSchema,
  // amount: transactionsMoneyField(feeList),
  beneficiaryId: yup.string(),
});
