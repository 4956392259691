import React from 'react';

const AnimatedSpinner = ({fetching = true}) => (
  <If condition={fetching}>
    <div className="animated_spinner">
      <svg xmlns="http://www.w3.org/2000/svg"
           width="209pt"
           height="192pt"
           viewBox="0 0 209 192"
      >
        <g
          transform="translate(0.000000,192.000000) scale(0.100000,-0.100000)"
          fill="#000"
          stroke="none"
        >
          <path
            d="M952 1843 c-133 -88 -260 -233 -334 -378 -25 -49 -55 -122 -83 -204
          -4 -9 -9 -10 -18 -4 -41 32 -224 82 -328 90 -73 6 -77 5 -92 -18 -37 -56 -60
          -179 -65 -343 -5 -177 3 -234 51 -360 90 -238 351 -467 629 -551 206 -62 466
          -60 661 6 148 51 289 132 388 225 158 146 235 274 280 464 20 81 17 297 -5
          395 -25 111 -73 233 -97 246 -14 7 -36 5 -82 -7 -67 -19 -220 -82 -274 -114
          l-33 -19 -14 33 c-7 18 -17 44 -21 57 -28 89 -121 241 -206 333 -78 86 -230
          196 -270 196 -8 0 -47 -21 -87 -47z m148 -100 c145 -97 297 -313 342 -486 l12
          -47 -39 -32 c-67 -54 -162 -158 -215 -234 -49 -70 -113 -197 -126 -248 -3 -14
          -9 -26 -13 -26 -4 0 -18 26 -31 58 -63 157 -189 318 -330 422 -39 29 -70 56
          -70 62 0 5 11 43 25 84 46 136 134 273 240 372 58 55 130 110 145 111 3 1 30
          -16 60 -36z m800 -520 c69 -223 48 -466 -56 -643 -80 -137 -261 -290 -426
          -358 -80 -33 -238 -75 -238 -62 0 4 18 20 40 35 67 47 186 179 234 261 96 164
          143 361 133 558 -3 61 -7 117 -9 125 -4 19 112 87 211 122 42 15 79 28 83 28
          4 1 16 -29 28 -66z m-1570 -20 c30 -9 84 -28 119 -44 50 -23 62 -32 57 -46 -4
          -9 -9 -57 -13 -107 -16 -263 80 -531 258 -716 34 -36 112 -103 148 -127 2 -2
          2 -5 -1 -9 -9 -9 -162 33 -244 67 -147 61 -313 193 -392 309 -50 75 -97 194
          -111 281 -19 114 -11 267 19 378 l11 43 47 -7 c26 -3 72 -13 102 -22z m1157
          -288 c-13 -216 -88 -402 -220 -543 -54 -57 -123 -112 -140 -112 -27 0 23 315
          67 429 54 137 158 290 245 362 l36 29 9 -32 c4 -18 6 -78 3 -133z m-820 116
          c205 -165 313 -393 338 -718 8 -100 4 -102 -80 -39 -187 138 -295 331 -325
          580 -11 97 -6 216 10 216 5 0 30 -17 57 -39z"
          />
        </g>
      </svg>
    </div>
  </If>
);

export default AnimatedSpinner;
