import React, { useState, useEffect, memo, useCallback } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import queryString from '../../../lib/parseQuery';
import CompanyInfoContainer from '../../../containers/AccountSetup/CompanyInfoContainer';
import OwnershipContainer from '../../../containers/AccountSetup/OwnershipContainer';
import AccountActivityContainer from '../../../containers/AccountSetup/AccountActivityContainer';
import ProfileContainer from '../../../containers/AccountSetup/ProfileContainer';
import CustomSpinner from '../../Spinners/CustomSpinner';
import DocumentsUploadContainer from '../../../containers/AccountSetup/DocumentsUploadContainer';
import userActions from '../../../actions/user';
import { deauthenticateUser } from '../../../actions/localStorage';
import Button from '../../generic/Button';

const { logout } = userActions.user;

const AccountSetupTabs = ({
  history,
  location,
  fetchGetData,
  data: {
    data: {
      contacts,
      enduser,
      accountActivity,
      documents,
      limits,
    },
    fetching,
  },
  data,
  userRole,
  lists,
  logoutFunc,
  redirectPath,
  tabsTitle,
  isRegistrationTool,
  updateCompanyInfoField,
  updateAccountActivityField,
  updateOwnershipField,
  updateProfileField,
  createContact,
  deleteContact,
  uploadDocument,
  deleteDocument,
  downloadDocument,
  confirmForm,
  specialTabsClass,
  documentsSections,
  profileId,
  withRemovingPermission,
  personalInfoValidationSchema,
  personalInfoFields,
  ownershipFields,
  ownershipValidationSchema,
  accountActivityFields,
  accountActivityValidationSchema,
  profileFields,
  profileValidationSchema,
  fetchingFields,
  enduserId,
}) => {
  const { tab } = queryString(location.search);
  const [key, setKey] = useState(tab || 'companyInfo');
  useEffect(() => {
  }, [data]);

  useEffect(() => {
    fetchGetData();
    if (!tab) history.push(`${redirectPath}/setUp?tab=companyInfo`);
  }, []);

  // TODO: func handleSelect and handleSelectFromTab the same, its from prevent rerender in two diff cases.
  const handleSelect = selectKey => () => {
    setKey(selectKey);
    history.push(`${redirectPath}/setUp?tab=${selectKey}`);
  };

  const handleSelectFromTab = (selectKey) => {
    setKey(selectKey);
    history.push(`${redirectPath}/setUp?tab=${selectKey}`);
  };

  const logoutUser = useCallback((e) => {
    e.preventDefault();
    logoutFunc();
    deauthenticateUser();
  }, []);

  return (
    <div className={`account-setup ${fetching ? 'fetching' : ''} ${specialTabsClass}`}>
      <If condition={isRegistrationTool}>
        <div className="account-setup__header">
          <div className="account-setup__header__title">
            <h2>{tabsTitle}</h2>
          </div>
          <div className="account-setup__header__logout">
            <Button
              color="gray"
              type="primary"
              size="lg"
              text="Logout"
              onClick={logoutUser}
            />
          </div>
        </div>
      </If>
      <Tabs
        activeKey={key}
        onSelect={handleSelectFromTab}
        defaultActiveKey="companyInfo"
        className="account-setup_navigation"
        id="account_setup_tabs"
      >
        <Tab
          eventKey="companyInfo"
          title="Company Info"
        >
          <CompanyInfoContainer
            isRegistrationTool={isRegistrationTool}
            enduserId={enduserId}
            fetchingFields={fetchingFields}
            validationSchema={personalInfoValidationSchema}
            fields={personalInfoFields}
            updateField={updateCompanyInfoField}
            propsData={enduser}
            lists={lists}
            hasNextButton
            nextTab={handleSelect('ownership')}
          />
        </Tab>

        <Tab
          eventKey="ownership"
          title="Ownership"
        >
          <OwnershipContainer
            fetchingFields={fetchingFields}
            withRemovingPermission={withRemovingPermission}
            createContact={createContact}
            deleteContact={deleteContact}
            updateField={updateOwnershipField}
            propsData={contacts}
            lists={lists}
            hasPrevButton
            hasNextButton
            prevTab={handleSelect('companyInfo')}
            nextTab={handleSelect('accountActivity')}
            fields={ownershipFields}
            validationSchema={ownershipValidationSchema}
          />
        </Tab>

        <Tab
          eventKey="accountActivity"
          title="Account Activity"
        >
          <AccountActivityContainer
            fetchingFields={fetchingFields}
            updateField={updateAccountActivityField}
            propsData={accountActivity || {}}
            hasPrevButton
            hasNextButton
            prevTab={handleSelect('ownership')}
            nextTab={handleSelect('identification')}
            fields={accountActivityFields}
            validationSchema={accountActivityValidationSchema}
          />
        </Tab>

        <Tab
          eventKey="identification"
          title="Document Upload"
        >
          <DocumentsUploadContainer
            isRegistrationTool={isRegistrationTool}
            propsData={documents}
            hasPrevButton
            hasNextButton={userRole === 'Admin'}
            prevTab={handleSelect('accountActivity')}
            nextTab={handleSelect('profile')}
            uploadDocument={uploadDocument}
            deleteDocument={deleteDocument}
            downloadDocument={downloadDocument}
            confirmForm={confirmForm}
            documentsSections={documentsSections}
            withRemovingPermission={withRemovingPermission}
            profileId={profileId}
          />
        </Tab>

        <If condition={!isRegistrationTool && userRole === 'Admin'}>
          <Tab
            eventKey="profile"
            title="Profile"
          >
            <ProfileContainer
              fetchingFields={fetchingFields}
              updateField={updateProfileField}
              propsData={limits || {}}
              hasPrevButton
              prevTab={handleSelect('identification')}
              fields={profileFields}
              validationSchema={profileValidationSchema}
            />
          </Tab>
        </If>
      </Tabs>
      <CustomSpinner fetching={fetching} />
    </div>
  );
};

AccountSetupTabs.defaultProps = {
  withRemovingPermission: true,
  fetchGetData: null,
  userRole: null,
  specialTabsClass: '',
  isRegistrationTool: false,
  confirmForm: () => {},
  updateProfileField: () => {},
  tabsTitle: '',
  lists: {},
  data: {
    data: {},
    fetching: false,
  },
  profileValidationSchema: null,
  profileFields: [],
  profileId: '',
  enduserId: '',
};

AccountSetupTabs.propTypes = {
  enduserId: PropTypes.string,
  data: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.string,
    PropTypes.array,
    PropTypes.bool,
  ])),
  history: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.number,
    PropTypes.string,
    PropTypes.array,
    PropTypes.bool,
  ])).isRequired,
  location: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.string,
    PropTypes.array,
    PropTypes.bool,
  ])).isRequired,
  fetchGetData: PropTypes.func,
  lists: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.object,
  ])),
  logoutFunc: PropTypes.func.isRequired,
  redirectPath: PropTypes.string.isRequired,
  tabsTitle: PropTypes.string,
  isRegistrationTool: PropTypes.bool,
  updateCompanyInfoField: PropTypes.func.isRequired,
  updateAccountActivityField: PropTypes.func.isRequired,
  updateOwnershipField: PropTypes.func.isRequired,
  updateProfileField: PropTypes.func,
  createContact: PropTypes.func.isRequired,
  deleteContact: PropTypes.func.isRequired,
  uploadDocument: PropTypes.func.isRequired,
  deleteDocument: PropTypes.func.isRequired,
  downloadDocument: PropTypes.func.isRequired,
  confirmForm: PropTypes.func,
  specialTabsClass: PropTypes.string,
  profileValidationSchema: PropTypes.shape(),
  documentsSections: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object,
  ])).isRequired,
  profileId: PropTypes.string,
  withRemovingPermission: PropTypes.bool,
  personalInfoValidationSchema: PropTypes.shape({}).isRequired,
  personalInfoFields: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object,
  ])).isRequired,
  ownershipFields: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object,
  ])).isRequired,
  ownershipValidationSchema: PropTypes.shape({}).isRequired,
  accountActivityFields: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object,
  ])).isRequired,
  profileFields: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object,
  ])),
  accountActivityValidationSchema: PropTypes.shape({}).isRequired,
  fetchingFields: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object,
  ])).isRequired,
  userRole: PropTypes.string,
};

export default connect(null, { logoutFunc: logout })(AccountSetupTabs);
