import React from 'react';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import customersActions from '../../actions/customers';
import CustomerStatusHelper from '../../lib/statusHelper/CustomerStatusHelper';
import PendingFormatter from '../../lib/statusFormatters/PendingFormatter';

const { changeCustomerStatus } = customersActions.customers;

const mapStateToProps = state => ({
  requestData: state.getIn(['customers', 'withChangedStatus']),
  component: CustomerStatusHelper,
  userRole: state.getIn(['userInfo', 'data', 'role']),
  subRole: state.getIn(['userInfo', 'data', 'subRole']),
});

const mapDispatchToProps = dispatch => ({
  processAction: payload => dispatch(changeCustomerStatus.action(payload)),
});

export default connect(mapStateToProps,
  mapDispatchToProps)(withImmutablePropsToJS(PendingFormatter));
