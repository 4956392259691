import { createAction } from 'redux-actions';
import {
  ADD_PROGRESS_ITEM,
  DELETE_PROGRESS_ITEM,
} from '../../constants/constants';

export default {
  addProgressItem: createAction(ADD_PROGRESS_ITEM),
  deleteProgressItem: createAction(DELETE_PROGRESS_ITEM),
};
