import RegisterFormContainer from '../../containers/AuthContainers/RegisterFormContainer';
import ForgotPassFormContainer from '../../containers/AuthContainers/ForgotPassFormContainer';
import LoginFormContainer from '../../containers/AuthContainers/LoginFormContainer';
import ResetPassFormContainer from '../../containers/AuthContainers/ResetPassFormContainer';
import CreatePassFormContainer from '../../containers/AuthContainers/CreatePassFormContainer';

const loginRoutes = [
  {
    title: 'AuthContainers',
    path: '/auth/login',
    component: LoginFormContainer,
  },
  {
    title: 'Register',
    path: '/auth/registration',
    component: RegisterFormContainer,
  },
  {
    title: 'Forgot password',
    path: '/auth/forgot-password',
    component: ForgotPassFormContainer,
  },
  {
    title: 'Create password',
    path: '/auth/create-password/:token/:id',
    component: CreatePassFormContainer,
  },
  {
    title: 'Reset password',
    path: '/auth/reset-password/:token/:id',
    component: ResetPassFormContainer,
  },
  // {
  //   title: 'Revert password',
  //   path: '/auth/create-password',
  //   component: RevertPassFormContainer,
  // },
];

export default loginRoutes;
