import React from 'react';
import { NavLink } from 'react-router-dom';

import UserPendingFormatter from '../../containers/Modals/UserPendingFormatter';
import AdminUserPendingFormatter from '../../containers/Modals/AdminUserPendingFormatter';
import CustomerPendingFormatter from '../../containers/Modals/CustomerPendingFormatter';
import FeesChargesPendingFormatter from '../../containers/Modals/FeesChargesPendingFormatter';
import SearchContainer from '../../containers/Modals/SearchContainer';
import Tooltip from '../../components/generic/Tooltip';
import InfoReason from '../../components/Layouts/InfoReason';
import EllipsisFormatterWithModal from './EllipsisFormatterWithModal';
import { convertDateToLocaleFormat } from '../../helpers/dates';
import { currencyFormatter } from '../../helpers/other';

import UniversalModalButton from '../../containers/Modals/UniversalModalButton';
import ReadOnlyPermission from '../../components/HOC/ReadOnlyPermissionHOC';
import {
  notifActivateAdmin,
  notifActivateCustomer, notifActivateUser,
  notifExecuteFeeCharge, notifPermissionErrEditJournal,
} from '../../helpers/notificatios';

export const defaultFormatter = cell => <SearchContainer text={cell} />;

export const responseFormatter = (cell, props) => (
  <EllipsisFormatterWithModal
    text={cell}
    rowProps={props}
  />
);

export const falseFormatter = (cell) => {
  const content = cell ? 'true' : 'false';

  return <SearchContainer text={content} />;
};

export const priceFormatter = cell => (
  <SearchContainer
    className={cell < 0 ? 'decimal-price' : ''}
    text={currencyFormatter(cell)}
  />
);

export const journalNameFormatter = (cell, { id }, subRole) => (
  <div className="journal-block">
    <span data-tip="edit-journal" data-for={`edit-journal-${id}`}>
      <UniversalModalButton
        modalId="edit-journal"
        text={cell}
        chosenItemId={id}
        inlineButton
      />
    </span>
    <Tooltip id={`edit-journal-${id}`} text={`${subRole === 'ReadOnlyAdmin' ? 'View Journal' : 'Edit Journal'}`} />
  </div>
);

export const feesChargesStatusFormatter = (cell, item) => {
  const {
    status, id, isVerified, declineReason,
  } = item;
  const stat = status ? status.toString()
    .toLowerCase() : 'N/a';

  if (stat === 'pending') {
    return (
      <>
        <FeesChargesPendingFormatter
          processText={notifExecuteFeeCharge}
          id={id}
          isVerified={isVerified}
          declineReason={declineReason}
        />
      </>
    );
  }
  return (
    <div className={`status-block status-block__${stat}`}>
      <span className={`point point__${stat}`} />
      <SearchContainer text={cell} className="content" />
      {declineReason && declineReason !== 'n/a' && (
        <InfoReason
          declineReason={declineReason}
          id={id}
        />
      )}
    </div>
  );
};

export const userStatusFormatter = (cell, {
  status, id, declineReason,
}) => (
  <>
    <UserPendingFormatter
      processText={notifActivateUser}
      id={id}
      declineReason={declineReason}
      status={status}
      entity="user"
    />
  </>
);

export const adminUserStatusFormatter = (cell, {
  status, id, declineReason,
}) => (
  <>
    <AdminUserPendingFormatter
      processText={notifActivateAdmin}
      id={id}
      declineReason={declineReason}
      status={status}
    />
  </>
);

export const customerStatusFormatter = (cell, {
  status, id, declineReason,
}) => (
  <>
    <CustomerPendingFormatter
      processText={notifActivateCustomer}
      id={id}
      declineReason={declineReason}
      status={status}
      entity="customer"
    />
  </>
);

export const transactionInfoIdFormatter = (cell, { id }) => {
  if (typeof cell === 'string') {
    return (
      <>
        <span data-tip="transaction-info" data-for={`transaction-info-${id}`}>
          <UniversalModalButton
            modalId="transaction-info"
            text={cell}
            chosenItemId={id}
            inlineButton
            className="border-link"
          />
        </span>
        <Tooltip id={`transaction-info-${id}`} text="Transaction Status Info" />
      </>
    );
  }

  return cell;
};

export const transactionIdFormatter = (cell, { id, isUrgent }) => {
  if (typeof cell === 'string') {
    return (
      <div className="transactionIdFormatter" transaction-id={id}>
        <span data-tip="transaction-id" data-for={`transaction-id-${id}`}>
          <NavLink to={`/transactions/${id}`} activeClassName="active">
            <span data-tip="transaction-info" data-for={`transaction-info-${id}`}>
              <SearchContainer
                text={cell}
                transaction-id={id}
              />
            </span>
          </NavLink>
        </span>
        <If condition={isUrgent}>
          <span className="validated-box_marker decline">Urgent</span>
        </If>
        <Tooltip id={`transaction-id-${id}`} text="Transaction Info" />
      </div>
    );
  }

  return cell;
};

export const feesChargesIdFormatter = (cell, { id }) => (
  <SearchContainer
    text={cell}
    transaction-id={id}
  />
);

export const sortHeaderFormatter = column => (
  <div className="desc-sort">
    <span className="desc-sort__content">{column.text}</span>
    <span className="order desc-sort__icon" />
  </div>
);

export const dateFormatter = (cell, id, dateOptions = {}, showTooltip = true) => {
  const {
    outTooltipFormat = 'h:mm:ss A',
    outFormat = 'DD.MM.YYYY',
  } = dateOptions;

  if (!cell) {
    return '';
  }
  return (
    <>
      <SearchContainer
        data-tip="date"
        data-for={id}
        text={convertDateToLocaleFormat(cell, outFormat)}
      />
      <If condition={showTooltip}>
        <Tooltip
          id={id}
          text={convertDateToLocaleFormat(cell, outTooltipFormat)}
        />
      </If>
    </>
  );
};

export const statusTransactionProps = {
  items: ['Approved', 'Canceled', 'Declined', 'Failed', 'Pending', 'Processing'],
  classes: ['point point__approved', 'point point__declined', 'point point__declined', 'point point__declined', 'point point__pending', 'point point__processing'],
};

export const statusAccountsProps = {
  items: ['Active', 'Canceled', 'Pending', 'OnHold'],
  classes: ['point point__approved', 'point point__declined', 'point point__pending', 'point point__pending'],
};

export const statusUsersProps = {
  items: ['Active', 'Inactive', 'OnHold', 'Pending', 'Processing'],
  classes: ['point point__approved', 'point point__declined', 'point point__pending', 'point point__pending', 'point point__processing'],
};

export const statusCustomerProps = {
  items: ['Active', 'Inactive'],
  classes: ['point point__approved', 'point point__declined'],
};

export const statusBeneficiaresProps = {
  items: ['Active', 'Approved', 'Declined', 'Pending', 'Canceled', 'OnHold'],
  classes: ['point point__approved', 'point point__approved', 'point point__declined', 'point point__pending'],
};

export const validatedBeneficiariesProps = {
  items: ['Validated', 'Invalidated'],
  classes: ['point point__approved', 'point point__declined'],
};

export const validatedTransactionsProps = {
  items: ['Urgent', 'Unurgent'],
  classes: ['point point__approved', 'point point__declined'],
};

export const statusJournalProps = {
  items: ['Active', 'Approved', 'Canceled', 'Closed', 'Declined', 'Failed', 'Pending', 'Processing', 'Hidden', 'Exception'],
  classes: ['point point__approved', 'point point__approved', 'point point__declined', 'point point__declined', 'point point__declined', 'point point__declined', 'point point__pending', 'point point__processing'],
};

export const statusJournalOfAccountProps = {
  items: ['Active', 'Approved', 'Canceled', 'Closed', 'Declined', 'Failed', 'Pending', 'Processing'],
  classes: ['point point__approved', 'point point__approved', 'point point__declined', 'point point__declined', 'point point__declined', 'point point__declined', 'point point__pending', 'point point__processing'],
};

export const responseStatusCodes = {
  items: ['200', '201 ', '400', '401', '403', '404', '415', '500', '502', '504'],
  classes: [
    'point point__success',
    'point point__success',
    'point point__error',
    'point point__error',
    'point point__error',
    'point point__error',
    'point point__error',
    'point point__error',
    'point point__error',
    'point point__error',
  ],
};

export const requestTypes = {
  items: ['GET', 'POST', 'PUT', 'DELETE', 'OPTIONS', 'CONNECT'],
  classes: [],
};

export const feeProps = {
  items: [
    'Adjustment fee',
    'Correspondent\'s Bank Charge',
    'FX fee',
    'FX fee %',
    'Incoming Cross-Border Payment',
    'Incoming Cross-Border Payment %',
    'Incoming Local Payment',
    'Incoming Local Payment %',
    'Monthly fee',
    'Outgoing Cross-Border Payment',
    'Outgoing Cross-Border Payment %',
    'Outgoing Local Payment',
    'Outgoing Local Payment %',
    'Recall Fee',
    'Set up fee',
    'SWIFT Confirmation Request',
    'Urgent Transfer Fee',
  ],
  classes: [],
};

export const referenceProps = {
  items: ['Credit Setup Fee', 'Setup Fee', 'Incoming Wire Item'],
  classes: [],
};

export const chargeProps = {
  items: ['Charge', 'Transaction'],
  classes: [],
};

export const typeProps = {
  items: ['ADJUSTMENT', 'Bank Charge', 'CONVERSION', 'FASTER', 'FX SWIFT', 'INCOMING PAYMENT', 'INTER LEDGER', 'OUTGOING CROSS BORDER', 'OUTGOING SEPA', 'Outgoing SEPA', 'Recall Or Reversal', 'SEPA', 'Swift', 'Transfer', 'WB Ops', 'WIREBLOOM CHARGES', 'WIREBLOOM CHARGES MOUNTHLY', 'Wire'],
  classes: [],
};

export const processingTypeProps = {
  items: ['CONVERSION', 'FASTER', 'FX SWIFT', 'INTER LEDGER', 'OUTGOING CROSS BORDER', 'OUTGOING SEPA', 'SEPA', 'SWIFT', 'Transfer', 'Wire'],
  classes: [],
};
