import React, { memo, useCallback } from 'react';
import PropsTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';

import ReadOnlyPermission from '../../components/HOC/ReadOnlyPermissionHOC';
import { tooltipCantChangeStatus } from '../../helpers/notificatios';
import DropDownInactiveStatus from '../../components/generic/DropDownInactiveStatus';
import UniversalModalButton from '../../containers/Modals/UniversalModalButton';
import SearchContainer from '../../containers/Modals/SearchContainer';

const CustomerStatusHelper = (props) => {
  const {
    processAction,
    processText,
    id,
    status,
    userRole,
    subRole,
    entity,
  } = props;

  const processWithConfirm = useCallback(
    () => toastr.confirm(`${processText} Active?`, {
      onOk: () => processAction({ statusId: 6, id }),
    }),
    [processAction, processText, id],
  );

  const inactiveStatuses = [
    {
      statusId: 11,
      statusName: 'OnHold',
    },
    {
      statusId: 6,
      statusName: 'Active',
    },
  ];

  const activeStatuses = [
    {
      statusId: 11,
      statusName: 'OnHold',
    },
    {
      statusId: 12,
      statusName: 'Inactive',
    },
  ];

  return (
    <>
      <If condition={(userRole === 'Admin' && status === 'Active')}>
        <ReadOnlyPermission
          tooltipId={`decline-customer-btn-${id}`}
          declinePermissionText={tooltipCantChangeStatus}
          readOnlyComponent={(
            <span
              className="disabled"
              data-tip={`decline-customer-btn-${id}`}
              data-for={`decline-customer-btn-${id}`}
            >
              <i className="iconfont-close" />
            </span>
          )}
        >
          <DropDownInactiveStatus
            isActive
            processText={processText}
            selectChange={processAction}
            userId={id}
            statuses={activeStatuses}
            iconName="iconfont-close"
            entity={entity}
          />
        </ReadOnlyPermission>
      </If>

      <If condition={userRole === 'Admin' && (status === 'OnHold' || status === 'Pending Approval')}>
        <ReadOnlyPermission
          tooltipId={`decline-customer-btn-${id}`}
          declinePermissionText={tooltipCantChangeStatus}
          readOnlyComponent={(
            <span
              className="disabled"
              data-tip={`decline-customer-btn-${id}`}
              data-for={`decline-customer-btn-${id}`}
            >
              <i className="iconfont-close" />
            </span>
          )}
        >
          <UniversalModalButton
            modalId={`inactive-${entity}-modal`}
            inlineButton
            iconClasses="iconfont-close clickable"
            customIcon
            chosenItemId={id}
          />
        </ReadOnlyPermission>
      </If>

      <SearchContainer text={status} className="content" />

      <If condition={(userRole === 'Admin' && (status === 'OnHold' || status === 'Pending Approval'))}>
        <ReadOnlyPermission
          tooltipId={`process-customer-btn-${id}`}
          declinePermissionText={tooltipCantChangeStatus}
          readOnlyComponent={(
            <span
              className="disabled"
              data-tip={`process-customer-btn-${id}`}
              data-for={`process-customer-btn-${id}`}
            >
              <i className="iconfont-pending" />
            </span>
          )}
        >
          <i onClick={processWithConfirm} className="iconfont-pending clickable" />
        </ReadOnlyPermission>
      </If>
      <If condition={(status === 'Inactive' && subRole === 'SuperAdmin')}>
        <DropDownInactiveStatus
          processText={processText}
          selectChange={processAction}
          userId={id}
          statuses={inactiveStatuses}
          iconName="iconfont-pending"
        />
      </If>
    </>
  );
};

CustomerStatusHelper.propTypes = {
  processAction: PropsTypes.func.isRequired,
  entity: PropsTypes.string.isRequired,
  processText: PropsTypes.string.isRequired,
  id: PropsTypes.string.isRequired,
  status: PropsTypes.string.isRequired,
  userRole: PropsTypes.string.isRequired,
  subRole: PropsTypes.string.isRequired,
};

export default memo(CustomerStatusHelper);
