import * as yup from 'yup';

import { latinFieldWithNumbersAndPunctuationMarks } from '../../helpers/fieldsValidation';
import { setFieldsToReadOnly, setFieldsToEdit } from '../../helpers/other';

const adminUserFields = [
  {
    id: 'email',
    name: 'email',
    label: 'Email',
    placeholder: '',
    type: 'text',
  },
  // {
  //   id: 'userName',
  //   name: 'userName',
  //   label: 'User name',
  //   placeholder: '',
  //   type: 'text',
  //   required: true,
  // },
  {
    id: 'phoneNumber',
    name: 'phoneNumber',
    label: 'Phone number',
    placeholder: '',
    type: 'phone-number',
  },
  {
    id: 'firstName',
    name: 'firstName',
    label: 'First Name',
    placeholder: '',
    type: 'text',
  },
  {
    id: 'lastName',
    name: 'lastName',
    label: 'Last Name',
    placeholder: '',
    type: 'text',
  },
  {
    id: 'statusId',
    name: 'statusId',
    label: 'Status',
    placeholder: 'Select Status',
    type: 'select',
    defaultSorting: true,
  },
];

const readOnlyFields = ['email'];

export const viewAdminUserFields = setFieldsToReadOnly(adminUserFields);
export const editAdminUserFields = setFieldsToEdit(adminUserFields, readOnlyFields);

export const editAdminUserValidationSchema = yup.object({
  email: yup.string().required('Required!'),
  phoneNumber: yup.string().required('Required'),
  firstName: latinFieldWithNumbersAndPunctuationMarks().trim().required('Required!'),
  lastName: latinFieldWithNumbersAndPunctuationMarks().trim().required('Required!'),
  statusId: yup.string().required('Required!'),
});
