import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import UniversalInputForm from '../generic/UniversalInputsForm';
import Button from '../generic/Button';
import logo from '../../../img/bloom-auth.png';
import CustomSpinner from '../Spinners/CustomSpinner';

const RegisterForm = ({
  userRegister,
  fetching,
  formId,
  title,
  registrationValidationSchema,
  fields,
  getData,
  data,
  id,
  logoutFunc,
  isLogout,
}) => {
  useEffect(() => {
    if (id) {
      getData(id);
    }
  }, []);

  const onSubmit = (values, resetForm) => {
    userRegister({ values, resetForm });
  };

  const backToLogin = () => {
    logoutFunc();
  };

  return (
    <div className="auth-form auth-form--registration">
      <div className="auth_logo">
        <img src={logo} alt="logo" />
      </div>
      <div className="auth-form__content">
        <div className="auth-form__title">
          { title }
        </div>
        <CustomSpinner fetching={fetching} fullDimScreen />
        <UniversalInputForm
          propsData={data}
          validationSchema={registrationValidationSchema}
          fields={fields}
          onSubmit={onSubmit}
          formId={formId}
        />
        <div className="auth-form__submit-button">
          <Choose>
            <When condition={isLogout}>
              <Button
                color="gray"
                type="primary"
                size="lg"
                text="Back to login"
                onClick={backToLogin}
              />
            </When>
            <Otherwise>
              <NavLink to="/auth/login">
                <Button
                  color="gray"
                  type="primary"
                  size="lg"
                  text="Back to login"
                />
              </NavLink>
            </Otherwise>
          </Choose>
          <Button
            color="gray"
            type="primary"
            size="lg"
            text="Register"
            form={formId}
            submit
          />
        </div>
      </div>
    </div>
  );
};

RegisterForm.defaultProps = {
  isLogout: false,
  fetching: false,
  getData: () => {},
  id: '',
  logoutFunc: () => {},
  data: {},
};

RegisterForm.propTypes = {
  userRegister: PropTypes.func.isRequired,
  formId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isLogout: PropTypes.bool,
  fetching: PropTypes.bool,
  registrationValidationSchema: PropTypes.shape().isRequired,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  getData: PropTypes.func,
  data: PropTypes.shape(),
  id: PropTypes.string,
  logoutFunc: PropTypes.func,
};

export default RegisterForm;
