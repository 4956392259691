import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import React, { memo } from 'react';

import SelectionTransactionFormatterHeader
  from '../../lib/selectionTransactionFormatters/SelectionTransactionFormatterHeader';
import transactionsActions from '../../actions/transactions';
import { transactionsSelectListToProcessing } from '../../lib/tablesSchema/selectListToProcessing';

const {
  selectAllTransactions,
  clearAllTransactions,
  processOneTransaction,
} = transactionsActions.transactions;

const mapStateToProps = (state) => {
  const {
    selected,
    fetching,
    inProcessing,
  } = state.getIn(['transactions', 'transactionsStatusChanging'])
    .toJS();

  const subRole = state.getIn(['userInfo', 'data', 'subRole']);

  return {
    selected,
    fetching,
    inProcessing,
    subRole,
    fields: transactionsSelectListToProcessing,
  };
};

const mapDispatchToProps = dispatch => ({
  selectAll: arrIds => dispatch(selectAllTransactions(arrIds)),
  clearAll: () => dispatch(clearAllTransactions()),
  processOne: id => dispatch(processOneTransaction.action(id)),
});

const SelectionTransactionFormatterHeaderContainer = connect(mapStateToProps,
  mapDispatchToProps)(withImmutablePropsToJS(memo(SelectionTransactionFormatterHeader)));


export default data => <SelectionTransactionFormatterHeaderContainer data={data} />;
