import React, { useState } from 'react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import PropTypes from 'prop-types';

import { dayAndMonthFormatter } from '../../../helpers/dates';
import CustomTooltip from '../../Pages/Dashboard/CustomTooltip';
import RenderLegend from './RenderLegend';

const BiaxialLineChart = ({
  items,
  dataKeyLeft,
  dataKeyRight,
  firstLineName,
  secondLineName,
  strokeColor
}) => {
  const [hidingItems, toggleHidingItems] = useState([]);

  const toggleItemFromChart = (item) => {
    if (hidingItems.includes(item)) {
      toggleHidingItems(hidingItems.filter(el => el !== item));
    } else {
      toggleHidingItems([...hidingItems, item]);
    }
  };

  return (
    <>
      <LineChart
        width={800}
        height={550}
        data={items}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          domain={['dataMin', 'dataMax']}
          tickFormatter={dayAndMonthFormatter}
          tickCount={items.length}
        />
        <YAxis yAxisId="left" />
        <YAxis yAxisId="right" orientation="right" />
        <Tooltip content={<CustomTooltip />} />
        <Legend content={(
          <RenderLegend
            onChangeFunc={toggleItemFromChart}
            hidingItems={hidingItems}
          />
          )}
        />
        <Line
          name={firstLineName}
          yAxisId="left"
          type="monotone"
          dataKey={!hidingItems.includes(dataKeyLeft) ? dataKeyLeft : 'none'}
          stroke={strokeColor.left}  // #8884d8
          activeDot={{ r: 8 }}
        />
        <Line
          name={secondLineName}
          yAxisId="right"
          type="monotone"
          dataKey={!hidingItems.includes(dataKeyRight) ? dataKeyRight : 'none'}
          stroke={strokeColor.right}
        />
      </LineChart>
    </>
  );
};

BiaxialLineChart.defaultProps = {
  items: [],
};

BiaxialLineChart.propTypes = {
  strokeColor: PropTypes.shape({
    left: PropTypes.string.isRequired,
    right: PropTypes.string.isRequired,
  }).isRequired,
  dataKeyLeft: PropTypes.string.isRequired,
  dataKeyRight: PropTypes.string.isRequired,
  firstLineName: PropTypes.string.isRequired,
  secondLineName: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.object,
  ),
};

export default BiaxialLineChart;
