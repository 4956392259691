import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';

import listsActions from '../../../actions/lists';
import BeneficiariesContainer from '../../../containers/Pages/Beneficiares/BeneficiaresContainer';
import EditBeneficiaryContainer from '../../../containers/Pages/Beneficiares/EditBeneficiaryContainer';
import CreateBeneficiaryContainer from '../../../containers/Pages/Beneficiares/CreateBeneficiaryContainer';
import withLists from '../../HOC/withLists';
import userActions from '../../../actions/user';

const {
  getCountries,
  getSepaCountries,
  getCurrencies,
  getRoutingTypes,
  getEnduserTypes,
  getLedgerStatuses,
  getCustomersList,
} = listsActions.lists;

const {
  getUserCustomers,
} = userActions.user;

const Beneficiaries = () => {
  return (
    <Switch>
      <Route exact path="/beneficiaries/" component={BeneficiariesContainer} />
      <Route path="/beneficiaries/create/" component={CreateBeneficiaryContainer} />
      <Route path="/beneficiaries/:beneficiary" component={EditBeneficiaryContainer} />

      <Redirect to="/beneficiaries/" />
    </Switch>
  );
};

const mapStateToProps = (state) => {
  const {
    currencies, countries, enduserTypes, routingTypes, ledgerStatuses, sepaCountries,
  } = state.get('lists').toJS();
  const { userCustomers } = state.get('userInfo').toJS();
  const role = state.getIn(['userInfo', 'data', 'role']);

  const lists = [
    {
      data: currencies.data,
      action: getCurrencies.action,
    },
    {
      data: countries.data,
      action: getCountries.action,
    },
    {
      data: sepaCountries.data,
      action: getSepaCountries.action,
    },
    {
      data: routingTypes.data,
      action: getRoutingTypes.action,
    },
    {
      data: ledgerStatuses.data,
      action: getLedgerStatuses.action,
    },
    {
      data: enduserTypes.data,
      action: getEnduserTypes.action,
    },
  ];

  const listsForUser = [
    {
      data: userCustomers,
      action: getUserCustomers.action,
    },
  ];

  const requiredLists = role === 'Admin' ? lists : [...lists, ...listsForUser];

  return {
    beneficiary: state.getIn(['beneficiaries', 'one', 'data']),
    subRole: state.getIn(['userInfo', 'data', 'subRole']),
    userRole: state.getIn(['userInfo', 'data', 'role']),
    requiredLists,
  };
};

Beneficiaries.propTypes = {
  userRole: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(withLists(withImmutablePropsToJS(Beneficiaries)));
