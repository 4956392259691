import {
  fileValidationForRegistrationRequired,
  fileValidationForRegistration
} from '../../helpers/fieldsValidation';

export const identityListDocuments = [
  'Valid Passport',
  'Valid Driver License',
  'Valid National Identity Card',
];

export const residentialAddressList = [
  'Utility Bill',
  'Utility Statement', 'Certificate From a Supplier',
  'Letter From a Supplier',
];

const documentsSchema = {
  'Official List Of All Directors Including CEO': {
    type: 'document',
    properties: {
      id: 'officialList',
      name: 'officialList',
      label: 'Official List of all directors including CEO',
      validation: fileValidationForRegistrationRequired,
    },
  },
  'Valid Passport': {
    type: 'document',
    properties: {
      viewOnly: true,
      id: 'valid-passport',
      name: 'valid-passport',
      label: 'Valid Passport',
      validation: fileValidationForRegistrationRequired,
    },
  },
  'Valid Driver License': {
    type: 'document',
    properties: {
      viewOnly: true,
      id: 'valid-driver-license',
      name: 'valid-driver-license',
      label: 'Valid Driver License',
      validation: fileValidationForRegistrationRequired,
    },
  },
  'Valid National Identity Card': {
    type: 'document',
    properties: {
      viewOnly: true,
      id: 'valid-id-card',
      name: 'valid-id-card',
      label: 'Valid National Identity Card',
      validation: fileValidationForRegistrationRequired,
    },
  },
  'Utility Bill': {
    type: 'document',
    properties: {
      viewOnly: true,
      id: 'utility-bill',
      name: 'utility-bill',
      label: 'Utility Bill',
      validation: fileValidationForRegistrationRequired,
    },
  },
  'Utility Statement': {
    type: 'document',
    properties: {
      viewOnly: true,
      id: 'utility-statement',
      name: 'utility-statement',
      label: 'Utility Statement',
      validation: fileValidationForRegistrationRequired,
    },
  },
  'Certificate From a Supplier': {
    type: 'document',
    properties: {
      viewOnly: true,
      id: 'certificate-form-supplier',
      name: 'certificate-form-supplier',
      label: 'Certificate From a Supplier',
      validation: fileValidationForRegistrationRequired,
    },
  },
  'Letter From a Supplier': {
    type: 'document',
    properties: {
      viewOnly: true,
      id: 'letter-form-supplier',
      name: 'letter-form-supplier',
      label: 'Letter From a Supplier',
      validation: fileValidationForRegistrationRequired,
    },
  },
  'Proof OF Idenity': {
    type: 'modal',
    properties: {
      label: 'Upload Proof OF identity',
      modalId: 'identity-proof',
      documents: identityListDocuments,
    },
  },
  'Proof OF Address': {
    type: 'modal',
    properties: {
      label: 'Proof of residential address of all the beneficial owners',
      modalId: 'residential-proof',
      documents: residentialAddressList,
    },
  },
  'Certificate Of Incorporation And Any Name Change Certificates': {
    type: 'document',
    properties: {
      id: 'incorporationCertificate',
      name: 'incorporationCertificate',
      label: 'Certificate of incorporation and any name change certificates',
      validation: fileValidationForRegistrationRequired,
    },
  },
  'Shareholder Agreement / Shareholder Ledger': {
    type: 'document',
    properties: {
      id: 'shareholderAgreement',
      name: 'shareholderAgreement',
      label: 'Shareholder Agreement / Shareholder Ledger',
      validation: fileValidationForRegistrationRequired,
    },
  },
  'Other': {
    type: 'modal',
    properties: {
      modalId: 'other-document',
      label: 'Other document',
    },
  },
  'Bank Settlement Account Verification / Letter Of Good Standing': {
    type: 'document',
    properties: {
      id: 'bankSettlementAccountVerification',
      name: 'bankSettlementAccountVerification',
      label: 'Bank Settlement Account Verification / Letter of Good Standing',
      validation: fileValidationForRegistration,
    },
  },
  'Articles & Memorandum Of Association': {
    type: 'document',
    properties: {
      id: 'articlesMemorandumofAssociation',
      name: 'articlesMemorandumofAssociation',
      label: 'Articles & Memorandum of Association',
      validation: fileValidationForRegistration,
    },
  },
  'Trade Register Copy': {
    type: 'document',
    properties: {
      id: 'tradeRegisterCopy',
      name: 'tradeRegisterCopy',
      label: 'Trade Register Copy',
      validation: fileValidationForRegistration,
    },
  },
  'Business Plan': {
    type: 'document',
    properties: {
      id: 'businessPlan',
      name: 'businessPlan',
      label: 'Business Plan (only if start-up/new business)',
      validation: fileValidationForRegistration,
    },
  },
};

export const sections = [{
  title: 'Mandatory documents',
  schema: documentsSchema,
  columns: [{
    subTitle: 'Directors Info',
    items: [
      'Official List Of All Directors Including CEO',
      'Proof OF Idenity',
      'Proof OF Address',
      'Valid Passport',
      'Valid Driver License',
      'Valid National Identity Card',
      'Letter From a Supplier',
      'Utility Bill',
      'Utility Statement',
      'Certificate From a Supplier',
    ],
  }, {
    subTitle: 'Company info',
    items: ['Certificate Of Incorporation And Any Name Change Certificates'],
  }, {
    subTitle: 'Confirmation',
    items: ['Shareholder Agreement / Shareholder Ledger'],
  }, {
    subTitle: 'Additional information',
    items: ['Other'],
  }],
}, {
  title: 'Optional documents',
  schema: documentsSchema,
  columns: [{
    subTitle: '',
    items: ['Trade Register Copy'],
  }, {
    subTitle: '',
    items: ['Articles & Memorandum Of Association', 'Business Plan'],
  }, {
    subTitle: '',
    items: ['Bank Settlement Account Verification / Letter Of Good Standing'],
  }, {
    subTitle: '',
    items: [],
  }],
}];

export const userDocumentSections = [{
  title: 'Mandatory documents',
  schema: documentsSchema,
  columns: [{
    subTitle: 'Directors Info',
    items: [
      'Official List Of All Directors Including CEO',
      'Valid Passport',
      'Valid Driver License',
      'Valid National Identity Card',
      'Letter From a Supplier',
      'Utility Bill',
      'Utility Statement',
      'Certificate From a Supplier',
    ],
  }, {
    subTitle: 'Company info',
    items: ['Certificate Of Incorporation And Any Name Change Certificates'],
  }, {
    subTitle: 'Confirmation',
    items: ['Shareholder Agreement / Shareholder Ledger'],
  }, {
    subTitle: 'Additional information',
    items: ['Other'],
  }],
}, {
  title: 'Optional documents',
  schema: documentsSchema,
  columns: [{
    subTitle: '',
    items: ['Trade Register Copy'],
  }, {
    subTitle: '',
    items: ['Articles & Memorandum Of Association', 'Business Plan'],
  }, {
    subTitle: '',
    items: ['Bank Settlement Account Verification / Letter Of Good Standing'],
  }, {
    subTitle: '',
    items: [],
  }],
}];
