import { createAction } from 'redux-actions';
import { defineAction } from 'redux-define';
import { DEFAULT_ACTIONS_STATES } from '../constants/constantStates';

export const apiCallAction = action => ({
  fetching: createAction(action.FETCHING),
  action: createAction(action.ACTION),
  success: createAction(action.SUCCESS),
  error: createAction(action.ERROR),
  failure: createAction(action.FAILURE),
  done: createAction(action.DONE),
  progress: createAction(action.PROGRESS),
});

export const defineCustomAction = (actionConstName, extraStates = []) => (
  defineAction(actionConstName, [...DEFAULT_ACTIONS_STATES, ...extraStates])
);
