import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import searchActions from '../../actions/search';

const withSearch = Component => ({ clearSearch, ...props }) => {
  useEffect(() => { return () => clearSearch() }, []);

  return <Component {...props}/>;
};

const mapStateToProps = state => {
  return {
    searchText: state.get('search')
  };
};

const mapDispatchToProps = dispatch => {
  return {
    clearSearch: () => dispatch(searchActions.clear()),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withSearch);
