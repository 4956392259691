import React, { memo } from 'react';
import PropTypes from 'prop-types';

import CustomProgressBar from '../../generic/CustomProgressBar';
import Tooltip from '../../generic/Tooltip';
import { tooltipCantAddDocument } from '../../../helpers/notificatios';

const ModalDocumentComponent = ({
  label,
  openModal,
  setModalFormInfo,
  modalId,
  documentTypeId,
  documentsList,
  profileId,
  readOnly,
}) => {
  const handleClick = () => {
    if (!readOnly) {
      openModal();
      setModalFormInfo({
        modalId,
        customModalOptions: {
          documentsList,
          documentTypeId,
          profileId,
        },
      });
    }
  };

  return (
    <div
      className="grid-item basic-form__group"
      onClick={handleClick}
      data-tip="document_custom_input"
      data-for="document_custom_input"
    >
      {documentTypeId && <CustomProgressBar documentTypeId={documentTypeId} />}
      {documentsList.map(i => <CustomProgressBar documentTypeId={i.id} />)}
      <div className="basic-form__group__block">
        <div className={`document_custom_input input-cursor ${readOnly ? 'disabled' : ''}`}>
          <label className="document_custom_input_label">
            <i className="iconfont-file-all-format download" />

            <span className="file_name">{label}</span>
          </label>
        </div>
      </div>

      <If condition={readOnly}>
        <Tooltip id="document_custom_input" text={tooltipCantAddDocument} />
      </If>
    </div>
  );
};

export default memo(ModalDocumentComponent);

ModalDocumentComponent.defaultProps = {
  documentTypeId: null,
  documentsList: [],
  profileId: null,
  readOnly: false,
  label: '',
};

ModalDocumentComponent.propTypes = {
  label: PropTypes.string,
  openModal: PropTypes.func.isRequired,
  setModalFormInfo: PropTypes.func.isRequired,
  modalId: PropTypes.string.isRequired,
  documentTypeId: PropTypes.number,
  documentsList: PropTypes.arrayOf(PropTypes.object),
  profileId: PropTypes.string,
  readOnly: PropTypes.bool,
};
