import React from 'react';
import PropTypes from 'prop-types';

import SearchContainer from '../../containers/Modals/SearchContainer';

const TransactionsStatusFormatterColors = ({ cell }) => {
  const status = cell.toString().toLowerCase();

  return (
    <div className={`status-block status-block__${status}`}>
      <span className={`point point__${status}`} />
      <SearchContainer text={cell} className="content" />
    </div>
  );
};

TransactionsStatusFormatterColors.propTypes = {
  cell: PropTypes.string.isRequired,
};

export default TransactionsStatusFormatterColors;
