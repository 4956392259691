import React, { useEffect, useState } from 'react';
import { isEmpty, isEqual, uniq } from 'lodash';

import PropsTypes from 'prop-types';
import TransactionSelectListToProcessing
  from '../../components/Modals/TransactionSelectListToProcessing';
import CustomCheckbox from '../../components/generic/CustomCheckbox';
import {
  getTableBody, allChecked, allDisabled, getCurrentAvailableTransactions,
  getCurrentTransactionsIds,
} from '../../helpers/transactionsSelection';
import ReadOnlyPermission from '../../components/HOC/ReadOnlyPermissionHOC';
import { tooltipCantProcessTransaction } from '../../helpers/notificatios';

const SelectionTransactionFormatterHeader = ({
  selectAll,
  clearAll,
  selected,
  data,
  processOne,
  fetching,
  inProcessing,
  fields,
}) => {
  const [isAllChecked, setAllCheckboxChecked] = useState(false);
  const [isAllDisabled, setAllCheckboxDisabled] = useState(false);

  const isReadyToProcessing = !isEmpty(selected);

  // Clean all selected items when page was unmount
  useEffect(() => () => clearAll(), []);

  useEffect(() => {
    const tableBody = getTableBody();

    if (tableBody) {
      const callback = () => {
        setAllCheckboxChecked(allChecked(selected, data));
        setAllCheckboxDisabled(allDisabled(selected, data));
      };

      const observer = new MutationObserver(callback);
      observer.observe(tableBody, {
        childList: true,
        subtree: true,
      });

      return () => observer.disconnect();
    }
  }, [data]);

  useEffect(() => {
    if (!fetching) {
      setAllCheckboxChecked(allChecked(selected, data));
      setAllCheckboxDisabled(allDisabled(selected, data));
    }
  }, [selected]);

  const onChange = (e) => {
    const { checked } = e.target;
    const availableTransactions = getCurrentAvailableTransactions(data,
      getCurrentTransactionsIds(), true);

    if (isEqual(availableTransactions, inProcessing)) {
      setAllCheckboxChecked(false);
    } else {
      const filteredOnProcessing = availableTransactions.filter(
        id => inProcessing.indexOf(id) === -1,
      );

      setAllCheckboxChecked(checked);
      selectAll({
        addNew: checked,
        transactionsIds: filteredOnProcessing,
      });
    }
  };

  const processSelected = () => {
    if (isReadyToProcessing) {
      const unique = uniq(selected);
      unique.forEach(id => processOne(id));
    }
  };

  const clearSelected = () => {
    if (isReadyToProcessing) {
      clearAll();
    }
  };

  return (
    <div className="selection-wrap">
      <ReadOnlyPermission
        tooltipId="process-transactions"
        declinePermissionText={tooltipCantProcessTransaction}
        readOnlyComponent={(
          <span
            data-tip="process-transactions"
            data-for="process-transactions"
          >
            <div className="selection-wrap_description">
              <span>Process it?</span>
            </div>
            <CustomCheckbox
              onChange={e => e.preventDefault()}
              disabled
            />
          </span>
        )}
      >
        <>
          <div className="selection-wrap_description">
            <i
              className={`iconfont-close selection-icon ${isReadyToProcessing ? 'ready' : ''}`}
              onClick={clearSelected}
            />

            <span>Process it?</span>

            <TransactionSelectListToProcessing
              onSubmit={processSelected}
              isReady={isReadyToProcessing}
              transactions={getCurrentAvailableTransactions(data, selected)}
              fields={fields}
            />
          </div>

          <CustomCheckbox
            onChange={onChange}
            checked={isAllChecked}
            disabled={isAllDisabled}
          />
        </>
      </ReadOnlyPermission>
    </div>
  );
};

SelectionTransactionFormatterHeader.propTypes = {
  selectAll: PropsTypes.func.isRequired,
  clearAll: PropsTypes.func.isRequired,
  selected: PropsTypes.arrayOf(
    PropsTypes.string,
  ).isRequired,
  data: PropsTypes.arrayOf(
    PropsTypes.object,
  ).isRequired,
  processOne: PropsTypes.func.isRequired,
  fetching: PropsTypes.bool.isRequired,
  inProcessing: PropsTypes.arrayOf(
    PropsTypes.object,
  ).isRequired,
  fields: PropsTypes.arrayOf(
    PropsTypes.object,
  ).isRequired,
};

export default SelectionTransactionFormatterHeader;
