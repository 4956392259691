import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { isEmpty, findIndex } from 'lodash';

import CreateTransactionPage from '../../../components/Pages/Transactions/CreateTransactionPage';
import beneficiariesActions from '../../../actions/beneficiaries';
import transactionsActions from '../../../actions/transactions';
import accountsActions from '../../../actions/accounts';
import listActions from '../../../actions/lists';
import withLists from '../../../components/HOC/withLists';
import { DefaultChildHeader } from '../../../components/HOC/headerHelper';
import {
  beneficiary,
  account,
  transactionPropertiesForFirstRender,
  transactionPropertiesForSameCurrency,
  transactionPropertiesForDifferentCurrency,
  createTransactionsValidationSchema,
} from '../../../schemes/forms/createTransactionsConfigs';

const { getOneBeneficiaryWithList, clearOneBeneficiary } = beneficiariesActions.beneficiaries;
const { getOneAccountWithList, clearOneAccount } = accountsActions.accounts;
const { createTransaction, getConvertedBalance } = transactionsActions.transactions;
const {
  clearLists,
  getLedgers,
  getBeneficiariesList,
} = listActions.lists;

const mapStateToProps = (state) => {
  const {
    currencies, countries, ledgers, beneficiaries, routingTypes,
  } = state.get('lists')
    .toJS();

  const beneficiariesData = state.getIn(['beneficiaries', 'one'])
    .toJS();
  const accountsData = state.getIn(['accounts', 'one'])
    .toJS();

  // const { data: beneficiariesList, clear: clearBeneficiariesList } = accountsData.beneficiariesList;
  // const { data: accountsList, clear: clearAccountsList } = beneficiariesData.accountsList;

  const prepareFields = () => {
    if (isEmpty(accountsData.data) || isEmpty(beneficiariesData.data)) {
      return transactionPropertiesForFirstRender;
    }

    if (accountsData.data.currencyId === beneficiariesData.data.currencyId) {
      return transactionPropertiesForSameCurrency;
    }

    const fields = transactionPropertiesForDifferentCurrency;

    const idx = findIndex(
      fields, { id: 'convertedBalance' },
    );

    fields[idx].label = `Account Balance(${currencies.data[beneficiariesData.data.currencyId]})`;

    fields[idx].hide = accountsData.data.convertedBalance === null;

    return fields;
  };

  const fields = prepareFields();

  return {
    grid: [beneficiary, account],
    validationSchema: createTransactionsValidationSchema,
    fields,
    data: beneficiariesData,
    one: {},
    toPage: 'Transactions ',
    toLink: '/transactions',
    path: 'Create Transaction',
    formId: 'create-transaction',
    header: DefaultChildHeader,
    requiredLists: [
      {
        data: ledgers.data,
        action: getLedgers.action,
      },
      {
        data: beneficiaries.data,
        action: getBeneficiariesList.action,
      },
    ],
    lists: {
      currencyId: currencies.data,
      countryId: countries.data,
      beneficiaryId: beneficiaries.data,
      ledgerId: ledgers.data,
      routingTypes: routingTypes.data,
    },
    accounts: accountsData,
    fetching: state.getIn(['beneficiaries', 'one', 'fetching'])
      || state.getIn(['transactions', 'one', 'fetching'])
      || state.getIn(['accounts', 'one', 'fetching']),
  };
};

const mapDispatchToProps = dispatch => ({
  fetchOneBeneficiary: data => dispatch(getOneBeneficiaryWithList.action(data)),
  fetchGetOneAccount: data => dispatch(getOneAccountWithList.action(data)),
  fetchGetConvertedBalance: data => dispatch(getConvertedBalance.action(data)),
  submitForm: data => dispatch(createTransaction.action(data)),
  cleanData: () => {
    dispatch(clearOneBeneficiary());
    dispatch(clearOneAccount());
    dispatch(clearLists(['beneficiaries', 'ledgers']));
  },
});

export default connect(mapStateToProps,
  mapDispatchToProps)(withLists(withImmutablePropsToJS(CreateTransactionPage)));
