import React, {
  memo, useState, useEffect,
} from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { isEmpty, isEqual } from 'lodash';

import Button from '../generic/Button';
import CustomSpinner from '../Spinners/CustomSpinner';
import UniversalInputsForm from '../generic/UniversalInputsForm';
import {
  journalValidationSchemaForCharge,
  journalValidationSchemaForTransactions,
} from '../../schemes/forms/editJournal';

const EditJournalModal = (props) => {
  const {
    isModalOpen,
    handleClose,
    title,
    id,
    modalId,
    fetching,
    onSubmit,
    data,
    lists: {
      listsForTransactions,
      listsForCharge,
    },
    transactionFees,
    subRole,
    fields: {
      journalFieldsForTransactions,
      journalFieldsForCharge,
    },
  } = props;

  const [correctData, setCorrectData] = useState({});
  const [correctLists, switchCorrectLists] = useState({});
  const [correctFields, switchCorrectFields] = useState([]);
  const [correctValidationSchema, switchCorrectValidationSchema] = useState({});

  const recalculateProperties = (isCharge) => {
    switchCorrectLists(isCharge ? listsForCharge : listsForTransactions);
    switchCorrectFields(isCharge ? journalFieldsForCharge : journalFieldsForTransactions);
    switchCorrectValidationSchema(isCharge
      ? journalValidationSchemaForCharge(transactionFees.data)
      : journalValidationSchemaForTransactions(transactionFees.data));
  };

  useEffect(() => {
    if (!isEmpty(data)) {
      recalculateProperties(data.isCharge);
      setCorrectData(data.isCharge ? { ...data, type: 5 } : data);
    }
  }, [data]);

  const onChange = ({ name, value }) => {
    switch (name) {
      case 'isCharge':
        recalculateProperties(value);
        setCorrectData({
          ...correctData,
          isCharge: value,
          feeId: '',
          type: value ? 5 : null,
        });
        break;
    }
  };

  const onSubmitForm = (formData) => {
    onSubmit({
      id,
      data: formData,
    });
  };

  return (
    <Modal
      show={isModalOpen}
      onHide={handleClose}
      centered
      className={`custom-modal right-labels ${modalId}`}
    >
      <Modal.Header closeButton>
        <h3 className="custom-modal__title">{title}</h3>
      </Modal.Header>

      <Modal.Body>
        <div className="custom-modal__content">
          <CustomSpinner fetching={fetching} />
          <div className={`info-table ${!fetching ? 'content--show' : 'content--hide'}`}>
            <UniversalInputsForm
              // propsData={correctData}
              propsData={correctData}
              fields={correctFields}
              validationSchema={correctValidationSchema}
              onSubmit={onSubmitForm}
              formId={modalId}
              withLabel
              lists={correctLists}
              additionalHandler={onChange}
            />
          </div>
        </div>

      </Modal.Body>

      <Modal.Footer>
        <If condition={subRole !== 'ReadOnlyAdmin'}>
          <Button
            color="gray"
            type="primary"
            size="lg"
            submit
            form={modalId}
            text="Submit"
            disabled={fetching}
          />
        </If>

        <Button
          onClick={handleClose}
          color="gray"
          type="primary"
          size="lg"
          text="Cancel"
        />
      </Modal.Footer>
    </Modal>
  );
};

EditJournalModal.defaultProps = {
  isModalOpen: false,
  lists: {
    listsForTransactions: {},
    listsForCharge: {},
  },
};

EditJournalModal.propTypes = {
  isModalOpen: PropTypes.bool,
  subRole: PropTypes.string.isRequired,
  fields: PropTypes.shape({
    journalFieldsForTransactions: PropTypes.array,
    journalFieldsForCharge: PropTypes.array,
  }).isRequired,
  lists: PropTypes.shape({
    listsForTransactions: PropTypes.object,
    listsForCharge: PropTypes.object,
  }),
  data: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.string,
    PropTypes.array,
    PropTypes.bool,
  ])).isRequired,
  title: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  modalId: PropTypes.string.isRequired,
  fetching: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const review = (prevProps, newProps) => isEqual(prevProps, newProps);

export default memo(EditJournalModal, review);
