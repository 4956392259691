import React, { memo } from 'react';

const CustomCheckbox = ({ onChange, checked = false, disabled = false }) => {

  return(
    <label className="custom-checkbox">
      <input
        className="custom-checkbox_input"
        type="checkbox"
        onChange={onChange}
        checked={checked}
        disabled={disabled}
      />

      <span className="custom-checkbox_checkmark"/>
    </label>
  )
};

export default memo(CustomCheckbox);
