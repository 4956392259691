import { customFilter, textFilter } from 'react-bootstrap-table2-filter';
import React from 'react';

import CustomSelectFilter from '../../../components/CustomTable/CustomFilter';
import {
  sortHeaderFormatter,
  dateFormatter,
  priceFormatter,
  defaultFormatter,
  transactionInfoIdFormatter,
} from '../../tableUtils/tablesUtils';
import { convertDateToLocaleFormat } from '../../../helpers/dates';
import customSelectionFilter from '../../customSelectionFilter';
import { currencyFormatter } from '../../../helpers/other';
import { selectUniqValues } from '../../../helpers/status';
import TransactionsStatusFormatterColors from '../../tableUtils/TransactionsStatusFormatterColors';

const displayTransactionId = {
  dataField: 'displayTransactionId',
  text: 'Display Transaction ID',
  sort: true,
  formatter: transactionInfoIdFormatter,
  headerFormatter: sortHeaderFormatter,
  headerStyle: () => ({ width: '10%' }),
  wpx: 140,
  pdfWidth: 45,
};

const processDate = {
  dataField: 'processDate',
  text: 'Processed Date',
  sort: true,
  formatter: (cell, { id }) => dateFormatter(cell, id),
  headerFormatter: sortHeaderFormatter,
  headerStyle: () => ({ width: '6%' }),
  transformationItem: convertDateToLocaleFormat,
  wpx: 130,
};

const postDate = {
  dataField: 'postDate',
  text: 'Post Date',
  sort: true,
  formatter: (cell, { id }) => dateFormatter(cell, `${id}_for_create_date`),
  headerFormatter: sortHeaderFormatter,
  headerStyle: () => ({ width: '6%' }),
  transformationItem: convertDateToLocaleFormat,
  wpx: 130,
  isExport: false,
};

const accountName = {
  dataField: 'accountName',
  text: 'Account',
  sort: true,
  classes: 'main-field',
  formatter: defaultFormatter,
  headerFormatter: sortHeaderFormatter,
  headerStyle: () => ({ width: '9%' }),
  wpx: 150,
};

const beneficiaryName = {
  dataField: 'beneficiaryName',
  text: 'Beneficiary',
  sort: true,
  formatter: defaultFormatter,
  headerFormatter: sortHeaderFormatter,
  headerStyle: () => ({ width: '9%' }),
  wpx: 130,
};

const accountCurrency = {
  dataField: 'accountCurrency',
  text: 'Account Currency',
  sort: true,
  filter: textFilter(),
  formatter: defaultFormatter,
  headerFormatter: sortHeaderFormatter,
  headerStyle: () => ({ width: '6%' }),
  wpx: 130,
};

const transactionCurrency = {
  dataField: 'transactionCurrency',
  text: 'Transaction  Currency',
  sort: true,
  formatter: defaultFormatter,
  headerFormatter: sortHeaderFormatter,
  headerStyle: () => ({ width: '7%' }),
  wpx: 150,
};

const amount = {
  dataField: 'amount',
  text: 'Amount',
  sort: true,
  formatter: priceFormatter,
  headerFormatter: sortHeaderFormatter,
  headerStyle: () => ({ width: '5%' }),
  wpx: 80,
  transformationItem: currencyFormatter,
};

export const transactionsReportColumns = (currentData) => {
  const transactionsStatuses = selectUniqValues(currentData, 'status');
  const processingTypes = selectUniqValues(currentData, 'processingType');

  return (
    [
      displayTransactionId,
      processDate,
      postDate,
      accountName,
      beneficiaryName,
      accountCurrency,
      transactionCurrency,
      amount,
      {
        dataField: 'processingType',
        text: 'Processing Type',
        sort: true,
        headerClasses: 'with-filter',
        formatter: defaultFormatter,
        headerStyle: () => ({ width: '8%' }),
        filter: customFilter({
          onFilter: (filterObj, data) => customSelectionFilter(filterObj, data, 'processingType'),
        }),
        filterRenderer: (onFilter, column) => (
          <CustomSelectFilter
            onFilter={onFilter}
            column={column}
            selectItems={processingTypes}
            formClass="custom-filter-processing-type"
          />
        ),
        wpx: 120,
      },
      {
        dataField: 'declineReason',
        text: 'Decline Reason',
        sort: true,
        headerFormatter: sortHeaderFormatter,
        headerStyle: () => ({ width: '11%' }),
        wpx: 200,
        pdfWidth: 35,
      },
      {
        dataField: 'status',
        text: 'Transaction Status',
        sort: true,
        headerClasses: 'with-filter',
        classes: 'status',
        headerStyle: () => ({ width: '9%' }),
        formatter: cell => (<TransactionsStatusFormatterColors cell={cell} />),
        filter: customFilter({
          onFilter: (filterObj, data) => customSelectionFilter(filterObj, data, 'status'),
        }),
        filterRenderer: (onFilter, column) => (
          <CustomSelectFilter
            onFilter={onFilter}
            column={column}
            selectItems={transactionsStatuses}
            formClass="custom-filter-status"
          />
        ),
        wpx: 60,
      },
    ]
  );
};

export const transactionsExceptionReportColumns = (currentData) => {
  const transactionsStatuses = selectUniqValues(currentData, 'status');
  const processingTypes = selectUniqValues(currentData, 'processingType');

  return (
    [
      displayTransactionId,
      {
        dataField: 'exceptionCreationDate',
        text: 'Exception Creation Date',
        sort: true,
        formatter: (cell, { id }) => dateFormatter(cell, id),
        headerFormatter: sortHeaderFormatter,
        headerStyle: () => ({ width: '6%' }),
        transformationItem: convertDateToLocaleFormat,
        wpx: 150,
      },
      processDate,
      postDate,
      accountName,
      beneficiaryName,
      accountCurrency,
      transactionCurrency,
      amount,
      {
        dataField: 'processingType',
        text: 'Processing Type',
        sort: true,
        headerClasses: 'with-filter',
        formatter: defaultFormatter,
        headerStyle: () => ({ width: '8%' }),
        filter: customFilter({
          onFilter: (filterObj, data) => customSelectionFilter(filterObj, data, 'processingType'),
        }),
        filterRenderer: (onFilter, column) => (
          <CustomSelectFilter
            onFilter={onFilter}
            column={column}
            selectItems={processingTypes}
            formClass="custom-filter-processing-type"
          />
        ),
        wpx: 120,
      },
      {
        dataField: 'declineReason',
        text: 'Decline Reason',
        sort: true,
        headerFormatter: sortHeaderFormatter,
        headerStyle: () => ({ width: '10%' }),
        wpx: 200,
        pdfWidth: 40,
      },
      {
        dataField: 'status',
        text: 'Transaction Status',
        sort: true,
        headerClasses: 'with-filter',
        classes: 'status',
        headerStyle: () => ({ width: '9%' }),
        formatter: cell => (<TransactionsStatusFormatterColors cell={cell} />),
        filter: customFilter({
          onFilter: (filterObj, data) => customSelectionFilter(filterObj, data, 'status'),
        }),
        filterRenderer: (onFilter, column) => (
          <CustomSelectFilter
            onFilter={onFilter}
            column={column}
            selectItems={transactionsStatuses}
            formClass="custom-filter-status"
          />
        ),
        wpx: 60,
      },
    ]
  );
};
