import { makeSecureRequest } from './apiCallHandlers/index';

export default () => ({
  getOneAdminUser: (id = null) => makeSecureRequest('get', `AdminUser/${id}`),
  getAdmins: (params) => {
    return makeSecureRequest('get', 'AdminUser', null, params);
  },
  createAdminUser: ({ data }) => makeSecureRequest('post', 'AdminUser', data),
  editAdminUser: ({ id, data }) => makeSecureRequest('put', `AdminUser/${id}`, { ...data, id }),
});
