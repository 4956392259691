import React from 'react';
import { NavLink } from 'react-router-dom';

export const DefaultHeader = ({ path }) => (
  <div className="breadcrumbs-buttons">
    <div>
      {path}
    </div>
  </div>);

export const DefaultChildHeader = ({ path, toPage, toLink }) => (
  <div className="breadcrumbs-buttons">
    <NavLink to={toLink}>
      {toPage}
    </NavLink>
    <div>
      {` / ${path}`}
    </div>
  </div>
);
