import { GET_PROCESSING_ERRORS } from '../../constants/constants';

import { apiCallAction } from '../actionHelpers';
import { apiCallActionHandler } from '../commonLogic/requestLogic';

const actions = {
  getProcessingErrors: apiCallAction(GET_PROCESSING_ERRORS),
};

const getUserActivityLogic = apiCallActionHandler({
  name: 'getProcessingErrors',
  fetchAction: actions.getProcessingErrors,
  makeApiCall(payload, { api }) {
    return api.processingErrors.getProcessingErrors(payload);
  },
});

export const logics = [
  getUserActivityLogic,
];

export default actions;
