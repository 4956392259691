import React from 'react';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';

import userActions from '../../actions/user';
import RangeCalendar from '../../components/Layouts/RangeCalendar';
import Search from '../../components/Layouts/Search';
import ExportData from '../../components/Layouts/ExportData';
import AnimatedSpinner from '../../components/Spinners/AnimatedSpinner';

const { getUserActivityLogs } = userActions.user;

const AdminUserActivityLogsHeader = ({
  rangeCalendarAction,
  onExport,
  canExport,
  userName,
  fetching,
  match: { params: { id } },
}) => {
  const fetchData = (params) => {
    rangeCalendarAction({
      ...params,
      id,
    });
  };

  return (
    <>
      <div className="breadcrumbs-block user-activity">
        Admin /
        <NavLink to="/admin-users">
          <pre> Admin Users </pre>
        </NavLink>
        <div className="user-activity__user-name">
          <AnimatedSpinner fetching={fetching} />
          <span className={`${!fetching ? 'content--show' : 'content--hide'}`}>
            {`/ ${userName} - `}
          </span>
        </div>
        <pre> Activity Log </pre>
      </div>

      <div className="data_tools">
        <RangeCalendar rangeCalendarAction={fetchData} />

        <Search />

        <ExportData onExport={onExport} canExport={canExport} />
      </div>
    </>
  );
};

AdminUserActivityLogsHeader.propTypes = {
  rangeCalendarAction: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  userName: PropTypes.string.isRequired,
  fetching: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  canExport: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  userName: state.getIn(['users', 'activity', 'userName']),
  fetching: state.getIn(['users', 'activity', 'fetching']),
  userRole: state.getIn(['userInfo', 'data', 'role']),
});

export default connect(mapStateToProps, {
  rangeCalendarAction: getUserActivityLogs.action,
})(withImmutablePropsToJS(withRouter(AdminUserActivityLogsHeader)));
