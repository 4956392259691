import { combineActions, handleActions } from 'redux-actions';
import { fromJS } from 'immutable';

import { combineReducers } from 'redux-immutable';
import accountsActions from '../actions/accounts';
import beneficiariesActions from '../actions/beneficiaries';
import transactionsActions from '../actions/transactions';
import userActions from '../actions/user/userActions';
import modalActions from '../actions/modal';

const { closeModal } = modalActions;
const { logout } = userActions;

const {
  getAccounts,
  getOneAccount,
  getOneAccountWithList,
  createAccount,
  editAccount,
  getJournalsOfAccount,
  setAccountStatusToCanceled,
  createAdjustment,
  clearOneAccount,
  getMonthlyBalanceOfAccount,
  getMonthlyStatement,
  getAvailableStatementsDates,
  clearMonthlyStatement,
} = accountsActions.accounts;

const {
  getOneBeneficiaryWithList,
} = beneficiariesActions.beneficiaries;

const {
  getConvertedBalance,
} = transactionsActions.transactions;

const initialState = fromJS({
  list: {
    data: [],
    error: false,
    fetching: false,
  },
  one: {
    data: {},
    error: false,
    fetching: false,
    successAction: true,
  },
  adjustment: {
    error: false,
    fetching: false,
    successAction: true,
  },
  journalsOfAccount: {
    data: [],
    currency: '',
    ledgerName: '',
    hasBatch: false,
    balance: 0,
    error: false,
    fetching: false,
    balanceFetching: false,
    hasMonthlyStatement: false,
    monthlyStatement: {
      data: {},
      statements: [],
    },
  },
  withChangedStatus: {
    requestId: '',
    error: false,
    fetching: false,
  },
});

const accountsReducer = handleActions(
  {
    [getAccounts.fetching]: state => state
      .set('fetching', true)
      .set('error', false)
      .set('data', []),
    [combineActions(
      getAccounts.failure,
      getAccounts.error,
    )]: state => state
      .set('fetching', false)
      .set('error', true)
      .set('data', []),
    [getAccounts.success]: (state, { payload }) => state
      .set('data', fromJS(payload))
      .set('fetching', false),
    [createAccount.success]: (state, { payload }) => {
      return state.set('data', state.get('data').insert(state.get('data').size,
        fromJS({
          ...payload,
          marked: true,
        })));
    },
    [combineActions(
      editAccount.success,
      setAccountStatusToCanceled.success,
    )]: (state, { payload }) => {
      const itemIndex = state.get('data')
        .findKey(obj => obj.get('id') === payload.id);

      return state.setIn(['data', itemIndex], fromJS({
        ...payload,
        marked: true,
      }));
    },
    [combineActions(
      setAccountStatusToCanceled.success,
    )]: (state, { payload }) => state
      .set('data', fromJS(state.get('data')
        .toJS()
        .map((item) => {
          if (item.id === payload.id) {
            return { ...item, ...payload };
          }
          return item;
        }))),
    [logout]: () => initialState.get('list'),
  },
  initialState.get('list'),
);

const oneAdjustmentReducer = handleActions(
  {
    [createAdjustment.fetching]: state => state
      .set('fetching', true)
      .set('error', false)
      .set('successAction', false),
    [combineActions(
      createAdjustment.failure,
      createAdjustment.error,
    )]: state => state
      .set('fetching', false)
      .set('error', true),
    [createAdjustment.success]: state => state
      .set('successAction', true)
      .set('fetching', false),
    [logout]: () => initialState.get('adjustment'),
  },
  initialState.get('adjustment'),
);

const oneAccountReducer = handleActions(
  {
    [combineActions(
      getOneAccount.fetching,
      getOneAccountWithList.fetching,
      getConvertedBalance.fetching,
      createAccount.fetching,
      editAccount.fetching,
    )]: state => state
      .set('fetching', true)
      .set('error', false)
      .set('successAction', false),
    [combineActions(
      getOneAccount.failure,
      getOneAccount.error,
      getOneAccountWithList.failure,
      getOneAccountWithList.error,
      createAccount.failure,
      createAccount.error,
      editAccount.failure,
      editAccount.error,
      getConvertedBalance.failure,
      getConvertedBalance.error,
    )]: state => state
      .set('fetching', false)
      .set('error', true),
    [combineActions(
      createAccount.success,
      editAccount.success,
    )]: state => state
      .set('successAction', true)
      .set('fetching', false),
    [combineActions(
      getOneAccount.success,
      getOneAccountWithList.success,
    )]: (state, { payload }) => state
      .set('data', fromJS(payload))
      .set('fetching', false),
    [getConvertedBalance.success]: (state, { payload: { convertedBalance } }) => state
      .setIn(['data', 'convertedBalance'], fromJS(convertedBalance))
      .setIn(['data', 'isInBeneficiaryCurrency'], fromJS(true))
      .set('fetching', false),
    [getOneBeneficiaryWithList.success]: state => state
      .setIn(['data', 'isInBeneficiaryCurrency'], false)
      .setIn(['data', 'convertedBalance'], null),
    [combineActions(
      logout,
      clearOneAccount,
      closeModal,
    )]: () => initialState.get('one'),
  },
  initialState.get('one'),
);

const journalsOfAccountReducer = handleActions({
  [getJournalsOfAccount.fetching]: state => state.set('fetching', true),
  [getMonthlyBalanceOfAccount.fetching]: state => state.set('balanceFetching', true),
  [getJournalsOfAccount.success]: (state, {
    payload: {
      transactions,
      currency,
      ledgerName,
      hasMonthlyStatement,
      hasBatch,
    },
  }) => state.set('data', fromJS(transactions))
    .set('ledgerName', fromJS(ledgerName))
    .set('hasBatch', fromJS(hasBatch))
    .set('hasMonthlyStatement', fromJS(hasMonthlyStatement))
    .set('currency', fromJS(currency))
    .set('fetching', false),
  [getMonthlyBalanceOfAccount.success]: (state, {
    payload: {
      balance,
    },
  }) => state.set('balance', fromJS(balance))
    .set('balanceFetching', false),
  [createAdjustment.success]: (state, { payload }) => state.set('data', state.get('data')
    .insert(0, fromJS({
      ...payload,
      marked: true,
    }))),
  [combineActions(
    getJournalsOfAccount.failure,
    getJournalsOfAccount.error,
  )]: state => state.set('data', fromJS([]))
    .set('fetching', false),
  [combineActions(
    getMonthlyBalanceOfAccount.failure,
    getMonthlyBalanceOfAccount.error,
  )]: state => state.set('balanceFetching', false)
    .set('balance', 0),
  [getMonthlyStatement.success]: (state, { payload }) => state.setIn(['monthlyStatement', 'data'], payload),
  [getAvailableStatementsDates.success]: (state, { payload }) => state.setIn(['monthlyStatement', 'statements'], payload),
  [clearMonthlyStatement]: state => state.set('monthlyStatement', initialState.getIn(['journalsOfAccount', 'monthlyStatement'])),
  [logout]: () => initialState.get('journalsOfAccount'),
},
initialState.get('journalsOfAccount'));

const changeAccountsStatusReducer = handleActions(
  {
    [combineActions(
      setAccountStatusToCanceled.fetching,
    )]: (state, { payload }) => state
      .set('requestId', payload.id)
      .set('fetching', true)
      .set('error', false),
    [combineActions(
      setAccountStatusToCanceled.failure,
      setAccountStatusToCanceled.error,
    )]: state => state
      .set('fetching', false)
      .set('error', true),
    [combineActions(
      setAccountStatusToCanceled.success,
    )]: state => state.set('fetching', false),
    [logout]: () => initialState.get('withChangedStatus'),
  },
  initialState.get('withChangedStatus'),
);

export default combineReducers({
  list: accountsReducer,
  one: oneAccountReducer,
  adjustment: oneAdjustmentReducer,
  journalsOfAccount: journalsOfAccountReducer,
  withChangedStatus: changeAccountsStatusReducer,
});
