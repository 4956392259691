import { connect } from 'react-redux';

import RemoveTransactionFormatter from './RemoveSelectTransactionFormatter';
import transactionsActions from '../../actions/transactions';

const { selectTransaction } = transactionsActions.transactions;

const mapDispatchToProps = dispatch => ({
  removeTransaction: id => dispatch(selectTransaction({ addNew: false, key: id })),
});

export default connect(null, mapDispatchToProps)(RemoveTransactionFormatter);
