import React from 'react';
import PropTypes from 'prop-types';

import TransactionsDashboardHeader from './TransactionsDashboardHeader';
import ReportsByDayChart from './ReportsByDaysChart';
import CustomSpinner from '../../../Spinners/CustomSpinner';
import CompareCurrenciesChart from './CompareCurrenciesChart';
import CompareChartsByStatuses from './CompareChartsByStatuses';

const TransactionsDashboard = ({ fetching }) => (
  <div className="full-height main_padding">
    <TransactionsDashboardHeader />
    <CustomSpinner fetching={fetching} />
    <div
      className={`${!fetching ? 'content--show dashboard-overflow' : 'content--hide-without-transition'}`}
    >
      <div className="flexible-section">
        <ReportsByDayChart />
        <CompareCurrenciesChart />
      </div>
      <CompareChartsByStatuses />
    </div>
  </div>
);

TransactionsDashboard.propTypes = {
  fetching: PropTypes.bool.isRequired,
};

export default TransactionsDashboard;
