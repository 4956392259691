import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import React, { memo } from 'react';

import SelectionTransactionFormatterHeader
  from '../../lib/selectionTransactionFormatters/SelectionTransactionFormatterHeader';
import feesChargesActions from '../../actions/feesCharges';
import { chargesSelectListToProcessing } from '../../lib/tablesSchema/selectListToProcessing';

const {
  executeOneFee,
  selectAllFees,
  clearAllFees,
} = feesChargesActions.feesCharges;

const mapStateToProps = (state) => {
  const {
    selected,
    fetching,
    inProcessing,
  } = state.getIn(['feesCharges', 'feeStatusChanging'])
    .toJS();

  return {
    selected,
    fetching,
    inProcessing,
    fields: chargesSelectListToProcessing,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectAll: arrIds => dispatch(selectAllFees(arrIds)),
    clearAll: () => dispatch(clearAllFees()),
    processOne: id => dispatch(executeOneFee.action(id)),
  };
};

const SelectionFeesChargesFormatterHeaderContainer = connect(mapStateToProps,
  mapDispatchToProps)(withImmutablePropsToJS(memo(SelectionTransactionFormatterHeader)));

export default data => <SelectionFeesChargesFormatterHeaderContainer data={data} />;
