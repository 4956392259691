import React from 'react';
import { connect } from 'react-redux';
import PropsTypes from 'prop-types';

const CustomProgressBar = ({ documentTypeId, progressList }) => {
  const progressItem = progressList.find(i => i.get('documentTypeId') === documentTypeId);

  const progressItemWidth = progressItem ? progressItem.get('percent') : 0;

  return (
    <If condition={progressItem}>
      <div className="progressBar">
        <div className="progressBar__item" style={{ width: `${progressItemWidth}%` }} />
      </div>
    </If>
  );
};

CustomProgressBar.defaultProps = {
  documentTypeId: null,
};

CustomProgressBar.propTypes = {
  documentTypeId: PropsTypes.number,
  progressList: PropsTypes.shape().isRequired,
};

const mapStateToProps = state => ({
  progressList: state.getIn(['progressBar', 'progressItems']),
});

export default connect(mapStateToProps, null)(CustomProgressBar);
