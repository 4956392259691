import React from 'react';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import Highlighter from "react-highlight-words";

const SearchContainer = ({ searchWord = '', ownProps: { text = '', ...props } }) => (
  <Highlighter
    highlightClassName="marked"
    searchWords={[searchWord]}
    autoEscape={true}
    textToHighlight={text ? text.toString() : ""}
    {...props}
  />
);


const mapStateToProps = (state, ownProps) => {
  return {
    searchWord: state.get('search'),
    ownProps,
  };
};

export default connect(mapStateToProps)(withImmutablePropsToJS(SearchContainer));
