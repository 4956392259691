import React, {useState} from 'react';
import {Form, Modal} from 'react-bootstrap';
import CustomSpinner from '../Spinners/CustomSpinner';
import Button from '../generic/Button';

const CreateChatModal = (props) => {
  const {
    isModalOpen,
    handleClose,
    modalId,
    fetching,
    title,
  } = props;

  const [urgency, setUrgency] = useState('low');
  const [chatDescription, setChanDescription] = useState('');

  return (
    <Modal
      show={isModalOpen}
      onHide={handleClose}
      className={`custom-modal ${modalId}`}
    >
      <Modal.Header closeButton>
        <h3 className="custom-modal__title">{title}</h3>
      </Modal.Header>

      <Modal.Body>
        <div className="custom-modal__content">
          <CustomSpinner fetching={fetching} />
          <div
            className={`info-table ${!fetching ? 'content--show' : 'content--hide-without-transition'}`}
          >
            {// TODO: refactored to toggle from schema
               }
            <div className="switch-toggle">
              <div className="switch-toggle__content">
                <span className="label-for-switch">Urgency:</span>
                <span className="first-switch">low</span>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label="high"
                  onChange={() => (urgency === 'low'
                    ? setUrgency('high')
                    : setUrgency('low'))}
                />
              </div>
            </div>
            <h1>Topics:</h1>
            <div className="chat-description">
              <label className="chat-description_label">Document description</label>

              <textarea
                className="chat-description_textarea"
                value={chatDescription}
                onChange={e => setChanDescription(e.target.value)}
                rows="3"
                placeholder="Describe your problem in more detail"
              />
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          onClick={handleClose}
          color="gray"
          type="primary"
          size="lg"
          text="Close"
        />
      </Modal.Footer>
    </Modal>
  );
};

export default CreateChatModal;
