import React, { memo } from 'react';
import PropsTypes from 'prop-types';
import AnimatedSpinner from '../Spinners/AnimatedSpinner';
import Tooltip from './Tooltip';

const MessSendingStatus = ({ status, replayseMessage, replayseEditMessage }) => (
  <>
    <If condition={status === 'error'}>
      <i
        className="icon iconfont-restart-arrow"
        data-tip="mess-status"
        data-for="mess-status"
        onClick={replayseMessage}
      />
      <Tooltip id="mess-status" text="Failed sending, click to replase." />
    </If>
    <If condition={status === 'errorEdit'}>
      <i
        className="icon iconfont-restart-arrow"
        data-tip="edit-mess-status"
        data-for="edit-mess-status"
        onClick={replayseEditMessage}
      />
      <Tooltip id="edit-mess-status" text="Failed edit message, click to replase." />
    </If>
    <If condition={status === 'fetching'}>
      <div>
        <AnimatedSpinner />
      </div>
    </If>
  </>
);

export default memo(MessSendingStatus);

MessSendingStatus.defaultProps = {
  status: '',
};

MessSendingStatus.propTypes = {
  status: PropsTypes.string,
  replayseMessage: PropsTypes.func.isRequired,
  replayseEditMessage: PropsTypes.func.isRequired,
};
