import {
  apiDownloadFile,
  makeSecureRequest,
  makeSecureRequestWithFormData,
} from './apiCallHandlers/index';

export default route => ({
  getAccounts: (params = {}) => {
    return makeSecureRequest('get', 'Ledger', null, params);
  },
  getJournalsOfAccount: ({ id }) => makeSecureRequest('get', 'Journal/GetJournalsByLedger', null, { ledgerId: id }),
  getMonthlyBalanceOfAccount: ({ id }) => makeSecureRequest('get', `Ledger/${id}/MonthlyBalance`, null),
  getOneAccount: id => makeSecureRequest('get', `Ledger/${id}`),
  createAccount: form => makeSecureRequest('post', 'Ledger', form),
  editAccount: ({ id, data }) => makeSecureRequest('put', `Ledger/${id}`, data),
  deleteAccount: id => makeSecureRequest('delete', 'Ledger/', null, { id }),
  setAccountStatusToCanceled: ({ id, comment = '' }) => {
    const params = {
      value: comment,
      statusId: 8, // canceled from ledgerStatuses list
    };

    return makeSecureRequest('put', `Ledger/${id}/SetStatus`, params);
  },
  createAdjustment: ({ id, ...data }) => makeSecureRequest('post', `Ledger/${id}/Adjustment`, data),
  getMonthlyStatement: payload => makeSecureRequest('get', 'Journal/GetMonthlyStatements', null, payload),
  getAvailableStatementsDates: payload => makeSecureRequest('get', 'Journal/GetAvailableTransactionsDates', null, payload),
  uploadBatchFile: ({ content, platformId }) => makeSecureRequestWithFormData('post', 'Ledger/Batch/Upload', {
    formFile: content,
  }, { platformId }),
});
