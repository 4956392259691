import { isEmpty } from 'lodash';

export const getTableBody = () => {
  const table = document.getElementById('list-table');

  if (table && table.childNodes && table.childNodes[1]) {
    return table.childNodes[1];
  }
  return null;
};

export const getCurrentTransactionsIds = () => {
  const currentTransactionIds = [];
  const tableBody = getTableBody();

  if (tableBody) {
    tableBody
      .querySelectorAll('[transaction-id]')
      .forEach((elem) => {
        const transactionId = elem.getAttribute('transaction-id');
        currentTransactionIds.push(transactionId);
      });
  }

  return currentTransactionIds;
};

export const getCurrentAvailableTransactions = (data = [], transactionsIds = [], returnIds = false) => {
  if (!isEmpty(transactionsIds)) {
    const filtered = data.filter(({ isVerified, id, status }) => (transactionsIds.indexOf(id) !== -1 && isVerified && status === 'Pending'));

    if (returnIds) {
      return filtered.map(({ id }) => id);
    }
    return filtered;
  }
  return [];
};

export const allChecked = (selected, data) => {
  const availableTransactions = getCurrentAvailableTransactions(data,
    getCurrentTransactionsIds(), true);

  if (!isEmpty(availableTransactions) && !isEmpty(selected)) {
    return availableTransactions.every(id => selected.indexOf(id) !== -1);
  }
  return false;
};

export const allDisabled = (selected, data) => {
  const availableTransactions = getCurrentAvailableTransactions(data,
    getCurrentTransactionsIds(), true);

  return isEmpty(data.filter(({ isVerified, id, status }) => (availableTransactions.indexOf(id) !== -1 && isVerified && status === 'Pending')));
};
