import { customFilter } from 'react-bootstrap-table2-filter';
import React from 'react';

import CustomSelectFilter from '../../../components/CustomTable/CustomFilter';
import {
  sortHeaderFormatter,
  dateFormatter,
  defaultFormatter,
  responseFormatter,
  falseFormatter,
  responseStatusCodes,
  requestTypes,
} from '../../tableUtils/tablesUtils';
import customSelectionFilter from '../../customSelectionFilter';
import { convertDateToLocaleFormat } from '../../../helpers/dates';
import { selectUniqValues } from '../../../helpers/status';

const userActivityColumns = (currentData) => {
  const requestsTypes = selectUniqValues(currentData, 'requestType');
  const responsesStatuses = selectUniqValues(currentData, 'responseStatusCode');

  return (
    [{
      dataField: 'userName',
      text: 'User Name',
      sort: true,
      classes: 'main-field',
      formatter: defaultFormatter,
      headerFormatter: sortHeaderFormatter,
      headerStyle: () => ({ width: '8%' }),
      wpx: 120,
    },{
      dataField: 'requestType',
      text: 'Request Type',
      sort: true,
      formatter: defaultFormatter,
      headerStyle: () => ({ width: '6%' }),
      headerClasses: 'with-filter',
      filter: customFilter({
        onFilter: (filterObj, data) => customSelectionFilter(filterObj, data, 'requestType')
      }),
      filterRenderer: (onFilter, column) =>
        <CustomSelectFilter
          onFilter={onFilter}
          column={column}
          selectItems={requestsTypes}
          formClass="custom-filter-status"
        />,
      wpx: 80,
    }, {
      dataField: 'requestPath',
      text: 'Request Path',
      sort: true,
      formatter: defaultFormatter,
      headerFormatter: sortHeaderFormatter,
      headerStyle: () => ({ width: '7%' }),
      wpx: 120,
    },{
      dataField: 'responseStatusCode',
      text: 'Response Status Code',
      sort: true,
      formatter: defaultFormatter,
      headerStyle: () => ({ width: '6%' }),
      headerClasses: 'with-filter',
      filter: customFilter({
        onFilter: (filterObj, data) => customSelectionFilter(filterObj, data, 'responseStatusCode')
      }),
      filterRenderer: (onFilter, column) =>
        <CustomSelectFilter
          onFilter={onFilter}
          column={column}
          selectItems={responsesStatuses}
          formClass="custom-filter-status"
        />,
      wpx: 100,
    },{
      dataField: 'isAuthenticatedRequest',
      text: 'Is Auth Request',
      sort: true,
      formatter: falseFormatter,
      headerFormatter: sortHeaderFormatter,
      headerStyle: () => ({ width: '4%' }),
      wpx: 80,
    },{
      dataField: 'requestHost',
      text: 'Request Host',
      sort: true,
      formatter: defaultFormatter,
      headerFormatter: sortHeaderFormatter,
      headerStyle: () => ({ width: '7%' }),
      wpx: 100,
    },{
      dataField: 'remoteIpAddress',
      text: 'Remote IP Address',
      sort: true,
      formatter: defaultFormatter,
      headerFormatter: sortHeaderFormatter,
      headerStyle: () => ({ width: '6%' }),
      wpx: 120,
    }, {
      dataField: 'requestDate',
      text: 'Request Time',
      sort: true,
      formatter: (cell, { id }) => dateFormatter(cell, `${id}-requestDate`, {
        outFormat: 'h:mm:ss A', outTooltipFormat: 'DD.MM.YYYY' }, true),
      headerFormatter: sortHeaderFormatter,
      headerStyle: () => ({ width: '5%' }),
      transformationItem: convertDateToLocaleFormat,
      wpx: 120,
    }, {
      dataField: 'responseDate',
      text: 'Response Time',
      sort: true,
      formatter: (cell, { id }) => dateFormatter(cell, `${id}-responseDate`, {
        outFormat: 'h:mm:ss A', outTooltipFormat: 'DD.MM.YYYY' }, true),
      headerFormatter: sortHeaderFormatter,
      headerStyle: () => ({ width: '5%' }),
      transformationItem: convertDateToLocaleFormat,
      wpx: 120,
    }, {
      dataField: 'requestQueryString',
      text: 'Request Query String',
      sort: true,
      formatter: defaultFormatter,
      headerFormatter: sortHeaderFormatter,
      headerStyle: () => ({ width: '8%' }),
      wpx: 140,
    }, {
      dataField: 'responseBody',
      text: 'Response Body',
      sort: true,
      formatter: responseFormatter,
      headerFormatter: sortHeaderFormatter,
      headerStyle: () => ({ width: '8%' }),
      wpx: 140,
    },]
  );
};

export default userActivityColumns;
