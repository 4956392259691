import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'redux-actions/lib/utils/isEmpty';

import modalActions from '../../actions/modal';
import UniversalModalButton from '../../containers/Modals/UniversalModalButton';
import { selectionOfFilledValues } from '../../helpers/other';

const EllipsisFormatterWithModal = ({ text, rowProps: { id }, setModalData }) => {
  const tooltipId = `${id}-response-body`;

  const isEmptyData = text === 'n/a';

  let preparedData = {};

  if (!isEmptyData) {
    const parsedJson = JSON.parse(text);
    const parsedJsonKeys = Object.keys(parsedJson);

    parsedJsonKeys.forEach(item => preparedData[item] = JSON.stringify(parsedJson[item], null, '\t'));
  }

  return (
    <Choose>
      <When condition={!isEmptyData}>
        <div
          title={text}
          data-tip="response-body"
          data-for={tooltipId}
          className="text-ellipsis"
          onClick={() => setModalData(preparedData)}
        >
          <UniversalModalButton
            modalId="response-body-info"
            text={text}
            color="green"
            type="primary"
            size="sm"
            inlineButton
          />
        </div>
      </When>
      <Otherwise>
        <span>n/a</span>
      </Otherwise>
    </Choose>
  );
};

EllipsisFormatterWithModal.propTypes = {
  text: PropTypes.string.isRequired,
  setModalData: PropTypes.func.isRequired,
  rowProps: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

const { setModalData } = modalActions;

const mapDispatchToProps = dispatch => ({
  setModalData: options => dispatch(setModalData(options)),
});

export default connect(
  null,
  mapDispatchToProps,
)(EllipsisFormatterWithModal);
