import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { isEmpty } from 'lodash';

const withLists = Component => ({ requiredLists = [], getList, ...props }) => {
  useEffect(() => {
    requiredLists.forEach(({ data, action }) => isEmpty(data) && getList(action()));
  }, []);

  return <Component {...props} />;
};

const mapDispatchToProps = dispatch => {
  return {
    getList: action => dispatch(action),
  };
};

export default compose(connect(null, mapDispatchToProps), withLists);
