import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-actions';
import { isEmpty } from 'lodash';

import history from '../../lib/history';
import { apiCallAction } from '../actionHelpers';
import { apiCallActionHandler } from '../commonLogic/requestLogic';
import transactionsActions from '../transactions';
import listsActions from '../lists';
import {
  GET_BENEFICIARES,
  GET_ONE_BENEFICIARY,
  GET_ONE_BENEFICIARY_WITH_LIST,
  CREATE_BENEFICIARY,
  CREATE_BENEFICIARY_ON_PLATFORM,
  SET_CHANGE_BENEFICIARY_STATUS,
  EDIT_BENEFICIARY,
  DELETE_BENEFICIARY,
  SWITCH_BENEFICIARY_TYPE,
  CLEAR_ONE_BENEFICIARY,
} from '../../constants/constants';
import {
  notifChangeBeneficiary,
  notifChangeBeneficiaryStatus, notifCreateAccountForBeneficiary, notifCreateBeneficiary,
  notifCreateBeneficiaryForTransaction, notifDeleteBeneficiary
} from '../../helpers/notificatios';

const { processOneTransaction } = transactionsActions.transactions;
const { getLedgers } = listsActions.lists;

const actions = {
  getBeneficiaries: apiCallAction(GET_BENEFICIARES),
  getOneBeneficiary: apiCallAction(GET_ONE_BENEFICIARY),
  editBeneficiary: apiCallAction(EDIT_BENEFICIARY),
  deleteBeneficiary: apiCallAction(DELETE_BENEFICIARY),
  createBeneficiary: apiCallAction(CREATE_BENEFICIARY),
  createBeneficiaryOnPlatform: apiCallAction(CREATE_BENEFICIARY_ON_PLATFORM),
  setChangeBeneficiaryStatus: apiCallAction(SET_CHANGE_BENEFICIARY_STATUS),
  switchBeneficiaryType: createAction(SWITCH_BENEFICIARY_TYPE),
  clearOneBeneficiary: createAction(CLEAR_ONE_BENEFICIARY),
  getOneBeneficiaryWithList: apiCallAction(GET_ONE_BENEFICIARY_WITH_LIST),
};

const getBeneficiariesLogic = apiCallActionHandler({
  name: 'getBeneficiaries',
  fetchAction: actions.getBeneficiaries,
  makeApiCall(payload, { api }) {
    return api.beneficiaries.getBeneficiaries(payload);
  },
  transformPayload({ dateFromFormatted, dateToFormatted }) {
    return {
      dateFrom: dateFromFormatted,
      dateTo: dateToFormatted,
    };
  },
});

const getOneBeneficiaryLogic = apiCallActionHandler({
  name: 'getOneBeneficiary',
  fetchAction: actions.getOneBeneficiary,
  makeApiCall(payload, { api }) {
    return api.beneficiaries.getOneBeneficiary(payload);
  },
});

const getOneBeneficiaryWithListLogic = apiCallActionHandler({
  name: 'getOneBeneficiaryWithList',
  fetchAction: actions.getOneBeneficiaryWithList,
  makeApiCall(payload, { api }) {
    return api.beneficiaries.getOneBeneficiary(payload);
  },
  onSuccess({ enduserId }, data, { dispatch }) {
    dispatch(getLedgers.action({
      enduserId,
      isEmptyText: notifCreateAccountForBeneficiary,
    }));
  },
});

const editBeneficiaryLogic = apiCallActionHandler({
  name: 'editBeneficiary',
  fetchAction: actions.editBeneficiary,
  makeApiCall({ payload }, { api }) {
    return api.beneficiaries.editBeneficiary(payload);
  },
  onSuccess() {
    history.push('/beneficiaries');
    toastr.success(
      'Success',
      notifChangeBeneficiary,
    );
  },
});

const deleteBeneficiaryLogic = apiCallActionHandler({
  name: 'deleteBeneficiary',
  fetchAction: actions.deleteBeneficiary,
  makeApiCall(id, { api }) {
    return api.beneficiaries.deleteBeneficiary(id);
  },
  onSuccess() {
    history.push('/beneficiaries');
    toastr.success(
      'Success',
      notifDeleteBeneficiary,
    );
  },
});

const createBeneficiaryLogic = apiCallActionHandler({
  name: 'createBeneficiary',
  fetchAction: actions.createBeneficiary,
  makeApiCall(payload, { api }) {
    return api.beneficiaries.createBeneficiary(payload);
  },
  onSuccess() {
    history.push('/beneficiaries');
    toastr.success(
      'Success',
      notifCreateBeneficiary,
    );
  },
});

const createBeneficiaryOnPlatformLogic = apiCallActionHandler({
  name: 'createBeneficiaryOnPlatform',
  fetchAction: actions.createBeneficiaryOnPlatform,
  makeApiCall(id, { api }) {
    return api.beneficiaries.createBeneficiaryOnPlatform(id);
  },
  onSuccess(response, id, { dispatch }) {
    dispatch(processOneTransaction.action(id));

    toastr.success(
      'Success',
      notifCreateBeneficiaryForTransaction,
    );
  },
});

const setChangeBeneficiaryStatusLogic = apiCallActionHandler({
  name: 'setChangeBeneficiaryStatus',
  fetchAction: actions.setChangeBeneficiaryStatus,
  makeApiCall(payload, { api }) {
    return api.beneficiaries.setChangeBeneficiaryStatus(payload);
  },
  onSuccess({ status }) {
    history.push('/beneficiaries');
    toastr.success(
      'Success',
      notifChangeBeneficiaryStatus(status),
    );
  },
});

export const logics = [
  getBeneficiariesLogic,
  getOneBeneficiaryLogic,
  createBeneficiaryLogic,
  createBeneficiaryOnPlatformLogic,
  setChangeBeneficiaryStatusLogic,
  editBeneficiaryLogic,
  deleteBeneficiaryLogic,
  getOneBeneficiaryWithListLogic,
];

export default actions;
