import { NavLink } from 'react-router-dom';
import React, { memo } from 'react';
import PropTypes from 'prop-types';

import UniversalModalButton from '../../../containers/Modals/UniversalModalButton';
import Tooltip from '../../../components/generic/Tooltip';

const AccountNameFormatter = ({
  cell, id, userRole, subRole,
}) => {
  const isEditModal = userRole === 'Admin' && subRole !== 'ReadOnlyAdmin';

  return (
    <div className="journal-block">
      <span data-tip="edit-account" data-for={`edit-account-${id}`}>
        <UniversalModalButton
          modalId={isEditModal ? 'edit-account' : 'view-account'}
          text={cell}
          chosenItemId={id}
          inlineButton
        />
      </span>
      <Choose>
        <When condition={isEditModal}>
          <Tooltip id={`edit-account-${id}`} text="Edit Account" />
        </When>
        <Otherwise>
          <Tooltip id={`edit-account-${id}`} text="Account Info" />
        </Otherwise>
      </Choose>

      <NavLink to={`/accounts/journal/${id}`} activeClassName="active">
        <i
          className="journal-icon iconfont-journal"
          data-tip="journal-icon"
          data-for={`journal-${id}`}
        />
        <Tooltip id={`journal-${id}`} text="Journal" />
      </NavLink>
    </div>
  );
};

AccountNameFormatter.propTypes = {
  cell: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  subRole: PropTypes.string.isRequired,
};

export default memo(AccountNameFormatter);
