import React, { memo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import DropZone from '../generic/DropZone';

import { fileValidationForRegistration } from '../../helpers/fieldsValidation';
import Button from '../generic/Button';
import progressActions from '../../actions/progress';

const { addProgressItem, deleteProgressItem } = progressActions;

const UploadCustomDocumentModal = (props) => {
  const {
    isModalOpen,
    handleClose,
    title,
    submitAction,
    dispatchAction,
    customModalOptions: { documentTypeId, profileId },
    dispatchProgress,
  } = props;

  const defaultFile = {
    status: null,
    fileName: null,
    content: null,
    type: null,
  };
  const [{
    status, fileName = '', content, type,
  }, setFile] = useState(defaultFile);
  const [documentDescription, setDocumentDescription] = useState('');

  const uploadFile = () => {
    handleClose();
    dispatchAction(submitAction({
      profileId,
      documentTypeId,
      fileName,
      content,
      description: documentDescription,
      dispatchProgress,
    }));
  };

  return (
    <Modal
      show={isModalOpen}
      onHide={handleClose}
      className="custom-modal documents-upload-modal with-submit"
      centered
    >
      <Modal.Header closeButton>
        <h3 className="custom-modal__title">{title}</h3>
      </Modal.Header>

      <Modal.Body className="custom-modal documents-upload-modal_body">
        <div className="upload-with-descriptions">

          <label className="input_label">Document description</label>

          <textarea
            className="input"
            value={documentDescription}
            onChange={e => setDocumentDescription(e.target.value)}
            rows="3"
            cols="46"
            placeholder="Type information about document"
          />
        </div>

        <If condition={!content}>
          <DropZone
            onDrop={setFile}
            validationSchema={fileValidationForRegistration}
          />
        </If>

        <If condition={content}>
          <div
            className={classNames('uploaded', status)}
          >
            <div className="document">
              <i className="iconfont-file-all-format document_icon" />
              <span className="document_name">{fileName}</span>
              <div className="document_tools">
                <i
                  className="iconfont-close remove_document"
                  onClick={() => setFile(defaultFile)}
                />
              </div>
            </div>
          </div>
          <Button
            className="upload_document"
            onClick={uploadFile}
            color="gray"
            type="primary"
            size="lg"
            text="Upload document"
          />
        </If>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  const { isModalOpen } = state.get('modal')
    .toJS();

  return {
    isModalOpen,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchAction: action => dispatch(action),
  dispatchProgress: {
    addProgressItem: info => dispatch(addProgressItem(info)),
    deleteProgressItem: info => dispatch(deleteProgressItem(info)),
  },
});

export default memo(connect(
  mapStateToProps, mapDispatchToProps,
)(withImmutablePropsToJS(UploadCustomDocumentModal)));

UploadCustomDocumentModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  submitAction: PropTypes.func.isRequired,
  dispatchAction: PropTypes.func.isRequired,
  dispatchProgress: PropTypes.objectOf(PropTypes.func).isRequired,
};
