import { combineActions, handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import { isEmpty } from 'lodash';
import moment from 'moment';

import { parseJwt, addSubRoleToUserInfo } from '../actions/localStorage';
import userActions from '../actions/user';
import registrationActions from '../actions/registration';

const {
  loginUser, logout, refreshSession, getLinkForRestorePassword, resetPassword, getUserCustomers,
} = userActions.user;
const { userRegistration } = registrationActions.registration;

const authTokenExpiring = (userData) => {
  if (userData && !isEmpty(userData) && userData.exp) {
    return moment(+(userData.exp) * 1000)
      .isBefore(moment());
  }
  return true;
};

const authToken = localStorage.getItem('auth_token');
const refreshToken = localStorage.getItem('refresh_token');
// console.log('auth_token', authToken, 'refresh_token', refreshToken);
const userData = authToken ? addSubRoleToUserInfo(parseJwt(authToken)) : {};

const initialState = fromJS({
  isAuth: !!authToken && !!refreshToken,
  data: userData,
  isAuthTokenExpired: authTokenExpiring(userData),
  isRefreshTokenTimer: false,
  fetching: false,
  userCustomers: {},
});

const userInfo = handleActions(
  {
    [combineActions(
      loginUser.fetching,
      userRegistration.fetching,
      getLinkForRestorePassword.fetching,
      resetPassword.fetching,
    )]: state => state.set('fetching', true),
    [loginUser.success]: (state, { payload: { data } }) => state
      .set('data', data)
      .set('isAuth', true)
      .set('isAuthTokenExpired', false)
      .set('fetching', false),
    [combineActions(
      userRegistration.failure,
      userRegistration.error,
      userRegistration.success,
      getLinkForRestorePassword.failure,
      getLinkForRestorePassword.error,
      getLinkForRestorePassword.success,
      resetPassword.failure,
      resetPassword.error,
      resetPassword.success,
    )]: state => state
      .set('fetching', false),
    [combineActions(
      loginUser.failure,
      loginUser.error,
    )]: state => state
      .set('data', {})
      .set('fetching', false),
    [refreshSession.fetching]: state => state
      .set('fetching', true)
      .set('isRefreshTokenTimer', false),
    [combineActions(
      refreshSession.failure,
      refreshSession.error,
    )]: state => state
      .set('isAuth', false)
      .set('data', {})
      .set('fetching', false),
    [refreshSession.success]: (state, { payload: { data } }) => {
      return state
        .set('fetching', false)
        .set('data', data)
        .set('isAuthTokenExpired', false)
        .set('isRefreshTokenTimer', true);
    },
    [getUserCustomers.success]: (state, { payload }) => state.set('userCustomers', fromJS(payload)),
    [logout]: state => state
      .set('isAuth', false)
      .set('data', {})
      .set('isAuthTokenExpired', true)
      .set('userCustomers', fromJS({})),
  },
  initialState,
);

export default userInfo;
