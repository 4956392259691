import React from 'react';
import { NavLink } from 'react-router-dom';
import Tooltip from '../../../components/generic/Tooltip';

const TransactionIdFormatter = (cell, { id, isUrgent }) => {
  if (typeof cell === 'string') {
    return (
      <div className="transactionIdFormatter" transaction-id={id}>
        <span data-tip="transaction-id" data-for={`transaction-id-${id}`}>
          <NavLink to={`/transactions/${id}`} activeClassName="active">
            <span data-tip="transaction-info" data-for={`transaction-info-${id}`}>
              {cell}
            </span>
          </NavLink>
        </span>
        <If condition={isUrgent}>
          <span className="validated-box_marker decline">Urgent</span>
        </If>
        <Tooltip id={`transaction-id-${id}`} text="Transaction Info" />
      </div>
    );
  }

  return cell;
};

export default TransactionIdFormatter;
