import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-actions';
import { isEmpty, pick } from 'lodash';

import {
  GET_ACCOUNTS,
  GET_ONE_ACCOUNT,
  CREATE_ACCOUNT,
  EDIT_ACCOUNT,
  DELETE_ACCOUNT,
  CLEAN_ACCOUNT_DATA,
  SET_ACCOUNT,
  GET_JOURNALS_OF_ACCOUNT,
  SET_ACCOUNT_STATUS_TO_CANCELED,
  CREATE_ADJUSTMENT,
  CLEAR_ONE_ACCOUNT,
  GET_ONE_ACCOUNT_WITH_LIST,
  GET_MONTHLY_BALANCE_OF_ACCOUNT,
  GET_MONTHLY_STATEMENT,
  UPLOAD_BUTCH_FILE,
  GET_AVAILABLE_STATEMENTS_DATES,
  CLEAR_MONTHLY_STATEMENT_DATA,
} from '../../constants/constants';

import { apiCallAction } from '../actionHelpers';
import { apiCallActionHandler } from '../commonLogic/requestLogic';
import listsActions from '../lists';
import {
  notifCancelAccount,
  notifCreateAccount, notifCreateAdjustment, notifDeleteAccount,
  notifEditAccount, notifUploadBatch,
  notifWarnFirstCreateBeneficiary,
} from '../../helpers/notificatios';

const { getBeneficiariesList } = listsActions.lists;

const actions = {
  getAccounts: apiCallAction(GET_ACCOUNTS),
  getOneAccount: apiCallAction(GET_ONE_ACCOUNT),
  getOneAccountWithList: apiCallAction(GET_ONE_ACCOUNT_WITH_LIST),
  getJournalsOfAccount: apiCallAction(GET_JOURNALS_OF_ACCOUNT),
  createAccount: apiCallAction(CREATE_ACCOUNT),
  editAccount: apiCallAction(EDIT_ACCOUNT),
  deleteAccount: apiCallAction(DELETE_ACCOUNT),
  cleanAccountData: createAction(CLEAN_ACCOUNT_DATA),
  setAccount: createAction(SET_ACCOUNT),
  setAccountStatusToCanceled: apiCallAction(SET_ACCOUNT_STATUS_TO_CANCELED),
  createAdjustment: apiCallAction(CREATE_ADJUSTMENT),
  clearOneAccount: createAction(CLEAR_ONE_ACCOUNT),
  getMonthlyBalanceOfAccount: apiCallAction(GET_MONTHLY_BALANCE_OF_ACCOUNT),
  getMonthlyStatement: apiCallAction(GET_MONTHLY_STATEMENT),
  uploadBatchFile: apiCallAction(UPLOAD_BUTCH_FILE),
  getAvailableStatementsDates: apiCallAction(GET_AVAILABLE_STATEMENTS_DATES),
  clearMonthlyStatement: createAction(CLEAR_MONTHLY_STATEMENT_DATA),
};

const getAccountsLogic = apiCallActionHandler({
  name: 'getAccounts',
  fetchAction: actions.getAccounts,
  makeApiCall(payload, { api }) {
    return api.accounts.getAccounts(payload);
  },
  transformPayload({ dateFromFormatted, dateToFormatted }) {
    return {
      dateFrom: dateFromFormatted,
      dateTo: dateToFormatted,
    };
  },
  // transformResponse({ data }) {
  //   return data.map((item) => {
  //     item.createdDate = new Date(item.createdDate);
  //     return pick(item, [
  //       'currency',
  //       'status',
  //       'name',
  //       'customerName',
  //       'createdDate',
  //       'accountNumber',
  //       'declineReason',
  //       'id',
  //       'balance',
  //       'erpId']);
  //   });
  // },
});

const getOneAccountLogic = apiCallActionHandler({
  name: 'getOneAccount',
  fetchAction: actions.getOneAccount,
  makeApiCall(payload, { api }) {
    return api.accounts.getOneAccount(payload);
  },
});

const getOneAccountWithListLogic = apiCallActionHandler({
  name: 'getOneAccountWithList',
  fetchAction: actions.getOneAccountWithList,
  makeApiCall(payload, { api }) {
    return api.accounts.getOneAccount(payload);
  },
  onSuccess({ enduserId }, data, { dispatch }) {
    dispatch(getBeneficiariesList.action({
      enduserId,
      isEmptyText: notifWarnFirstCreateBeneficiary,
    }));
  },
});

const getJournalsOfAccountLogic = apiCallActionHandler({
  name: 'getJournalsOfAccount',
  fetchAction: actions.getJournalsOfAccount,
  makeApiCall(payload, { api }) {
    return api.accounts.getJournalsOfAccount(payload);
  },
});

const getMonthlyBalanceOfAccountLogic = apiCallActionHandler({
  name: 'getMonthlyBalanceOfAccount',
  fetchAction: actions.getMonthlyBalanceOfAccount,
  makeApiCall(payload, { api }) {
    return api.accounts.getMonthlyBalanceOfAccount(payload);
  },
});

const createAccountLogic = apiCallActionHandler({
  name: 'createAccount',
  fetchAction: actions.createAccount,
  makeApiCall(payload, { api }) {
    return api.accounts.createAccount(payload);
  },
  transformPayload(payload) {
    if (!payload.accountNumber) {
      delete payload.accountNumber;
    }

    return payload;
  },
  onSuccess() {
    toastr.success(
      'Success',
      notifCreateAccount,
    );
  },
});

const editAccountLogic = apiCallActionHandler({
  name: 'editAccount',
  fetchAction: actions.editAccount,
  makeApiCall(payload, { api }) {
    return api.accounts.editAccount(payload);
  },
  onSuccess() {
    toastr.success(
      'Success',
      notifEditAccount,
    );
  },
});

const deleteAccountLogic = apiCallActionHandler({
  name: 'deleteAccount',
  fetchAction: actions.deleteAccount,
  makeApiCall(payload, { api }) {
    return api.accounts.deleteAccount(payload);
  },
  onSuccess(data, payload, { dispatch }) {
    const { getAccounts } = actions;
    dispatch(getAccounts.action());

    toastr.success(
      'Success',
      notifDeleteAccount,
    );
  },
});

const setAccountStatusToCanceledLogic = apiCallActionHandler({
  name: 'setAccountStatusToCanceled',
  fetchAction: actions.setAccountStatusToCanceled,
  makeApiCall(payload, { api }) {
    return api.accounts.setAccountStatusToCanceled(payload);
  },
  onSuccess() {
    toastr.success('Success', notifCancelAccount);
  },
});

const uploadBatchFileLogic = apiCallActionHandler({
  name: 'uploadButchFile',
  fetchAction: actions.uploadBatchFile,
  makeApiCall(payload, { api }) {
    return api.accounts.uploadBatchFile(payload);
  },
  onSuccess() {
    toastr.success(
      'Success',
      notifUploadBatch,
    );
  },
});

const createAdjustmentLogic = apiCallActionHandler({
  name: 'createAdjustment',
  fetchAction: actions.createAdjustment,
  makeApiCall(payload, { api }) {
    return api.accounts.createAdjustment(payload);
  },
  onSuccess(response, { id }, { dispatch }) {
    const { getJournalsOfAccount, getMonthlyBalanceOfAccount } = actions;
    dispatch(getJournalsOfAccount.action({ id }));
    dispatch(getMonthlyBalanceOfAccount.action({ id }));
    toastr.success(
      'Success',
      notifCreateAdjustment,
    );
  },
});

const getMonthlyStatementLogic = apiCallActionHandler({
  name: 'getMonthlyStatement',
  fetchAction: actions.getMonthlyStatement,
  makeApiCall(payload, { api }) {
    return api.accounts.getMonthlyStatement(payload);
  },
});

const getAvailableStatementsDatesLogic = apiCallActionHandler({
  name: 'getAvailableStatementsDates',
  fetchAction: actions.getAvailableStatementsDates,
  makeApiCall(payload, { api }) {
    return api.accounts.getAvailableStatementsDates(payload);
  },
});

export const logics = [
  getOneAccountWithListLogic,
  getAccountsLogic,
  createAccountLogic,
  getOneAccountLogic,
  editAccountLogic,
  deleteAccountLogic,
  getJournalsOfAccountLogic,
  setAccountStatusToCanceledLogic,
  createAdjustmentLogic,
  getMonthlyBalanceOfAccountLogic,
  getMonthlyStatementLogic,
  uploadBatchFileLogic,
  getAvailableStatementsDatesLogic,
];

export default actions;
