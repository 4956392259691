import * as yup from 'yup';
import { addReadOnlyProperties } from '../../helpers/other';

export const multiFields = [
  {
    title: 'Monthly Fee',
    fields: [
      'Monthly fee',
    ],
    className: 'currency-field one-item',
  }, {
    title: `Correspondent's Bank Charge`,
    fields: [
      `Correspondent's Bank Charge`,
    ],
    className: 'half-part currency-field one-item first-at-row group-title',
  }, {
    title: 'FX Fee',
    fields: [
      'FX fee %',
      'FX fee',
    ],
    className: 'half-part currency-field group-title',
  }, {
    title: 'Urgent Transfer Fee',
    fields: [
      'Urgent Transfer Fee',
    ],
    className: 'half-part currency-field one-item first-at-row group-title',
  }, {
    title: 'Recall Fee',
    fields: [
      'Recall Fee',
    ],
    className: 'half-part currency-field one-item group-title ',
  }, {
    title: 'SWIFT Confirmation Request',
    fields: [
      'SWIFT Confirmation Request',
    ],
    className: 'half-part currency-field one-item first-at-row group-title',
  }, {
    title: 'Set Up Fee',
    fields: [
      'Set up fee',
    ],
    className: 'half-part currency-field one-item group-title',
  }, {
    title: 'Incoming Crossborder',
    fields: [
      'Incoming Cross-Border Payment',
      'Incoming Cross-Border Payment %',
      'Incoming Cross-Border Payment Over',
      'Incoming Cross-Border Payment Over %',
      'Incoming Cross-Border Payment Over Amount',
    ],
    className: 'group-title',
  }, {
    title: 'Incoming Local Payment',
    fields: [
      'Incoming Local Payment',
      'Incoming Local Payment %',
      'Incoming Local Payment Over',
      'Incoming Local Payment Over %',
      'Incoming Local Payment Over Amount',
    ],
    className: 'group-title',
  }, {
    title: 'Outgoing Crossborder',
    fields: [
      'Outgoing Cross-Border Payment',
      'Outgoing Cross-Border Payment %',
      'Outgoing Cross-Border Payment Over',
      'Outgoing Cross-Border Payment Over %',
      'Outgoing Cross-Border Payment Over Amount',
    ],
    className: 'group-title',
  }, {
    title: 'Outgoing Local Payment',
    fields: [
      'Outgoing Local Payment',
      'Outgoing Local Payment %',
      'Outgoing Local Payment Over',
      'Outgoing Local Payment Over %',
      'Outgoing Local Payment Over Amount',
    ],
    className: 'group-title',
  },
];

export const newFeeFields = [
  {
    id: 'pricingTemplate',
    name: 'pricingTemplate',
    label: 'Pricing Templates',
    placeholder: 'Select Template',
    type: 'select',
    validation: true,
  },
  {
    id: 'Adjustment fee',
    name: 'Adjustment fee',
    label: 'Currency',
    type: 'currency',
    validation: true,
    labelClassName: 'currency-icon hide-label-text',
  },
  {
    id: `Correspondent's Bank Charge`,
    name: `Correspondent's Bank Charge`,
    label: 'Currency',
    type: 'currency',
    labelClassName: 'currency-icon hide-label-text',
    validation: true,
  },
  {
    id: 'Urgent Transfer Fee',
    name: 'Urgent Transfer Fee',
    label: 'Urgent Transfer Fee',
    type: 'currency',
    labelClassName: 'currency-icon hide-label-text',
    validation: true,
  },
  {
    id: 'FX fee',
    name: 'FX fee',
    label: 'Currency',
    type: 'currency',
    labelClassName: 'currency-icon hide-label-text',
    validation: true,
  },
  {
    id: 'FX fee %',
    name: 'FX fee %',
    label: '%',
    type: 'percent',
    validation: true,
  },
  {
    id: 'Incoming Cross-Border Payment',
    name: 'Incoming Cross-Border Payment',
    label: 'Currency',
    type: 'currency',
    labelClassName: 'currency-icon hide-label-text',
    validation: true,
  },
  {
    id: 'Incoming Cross-Border Payment %',
    name: 'Incoming Cross-Border Payment %',
    label: '%',
    type: 'percent',
    validation: true,
  },
  {
    id: 'Incoming Cross-Border Payment Over Amount',
    name: 'Incoming Cross-Border Payment Over Amount',
    label: 'over',
    type: 'select',
    defaultSorting: false,
    validation: true,
  },
  {
    id: 'Incoming Cross-Border Payment Over',
    name: 'Incoming Cross-Border Payment Over',
    label: 'Currency',
    type: 'currency',
    labelClassName: 'currency-icon hide-label-text',
    validation: true,
  },
  {
    id: 'Incoming Cross-Border Payment Over %',
    name: 'Incoming Cross-Border Payment Over %',
    label: '%',
    type: 'percent',
    validation: true,
  },
  {
    id: 'Incoming Local Payment',
    name: 'Incoming Local Payment',
    label: 'Currency',
    type: 'currency',
    labelClassName: 'currency-icon hide-label-text',
    validation: true,
  },
  {
    id: 'Incoming Local Payment %',
    name: 'Incoming Local Payment %',
    label: '%',
    type: 'percent',
    validation: true,
  },
  {
    id: 'Incoming Local Payment Over Amount',
    name: 'Incoming Local Payment Over Amount',
    label: 'over',
    type: 'select',
    defaultSorting: false,
    validation: true,
  },
  {
    id: 'Incoming Local Payment Over',
    name: 'Incoming Local Payment Over',
    label: 'Currency',
    type: 'currency',
    labelClassName: 'currency-icon hide-label-text',
    validation: true,
  },
  {
    id: 'Incoming Local Payment Over %',
    name: 'Incoming Local Payment Over %',
    label: '%',
    type: 'percent',
    validation: true,
  },
  {
    id: 'Outgoing Cross-Border Payment',
    name: 'Outgoing Cross-Border Payment',
    label: 'Currency',
    type: 'currency',
    labelClassName: 'currency-icon hide-label-text',
    validation: true,
  },
  {
    id: 'Outgoing Cross-Border Payment %',
    name: 'Outgoing Cross-Border Payment %',
    label: '%',
    type: 'percent',
    validation: true,
  },
  {
    id: 'Outgoing Cross-Border Payment Over Amount',
    name: 'Outgoing Cross-Border Payment Over Amount',
    label: 'over',
    type: 'select',
    defaultSorting: false,
    validation: true,
  },
  {
    id: 'Outgoing Cross-Border Payment Over',
    name: 'Outgoing Cross-Border Payment Over',
    label: 'Currency',
    type: 'currency',
    labelClassName: 'currency-icon hide-label-text',
    validation: true,
  },
  {
    id: 'Outgoing Cross-Border Payment Over %',
    name: 'Outgoing Cross-Border Payment Over %',
    label: '%',
    type: 'percent',
    validation: true,
  },
  {
    id: 'Outgoing Local Payment',
    name: 'Outgoing Local Payment',
    label: 'Currency',
    type: 'currency',
    labelClassName: 'currency-icon hide-label-text',
    validation: true,
  },
  {
    id: 'Outgoing Local Payment %',
    name: 'Outgoing Local Payment %',
    label: '%',
    type: 'percent',
    validation: true,
  },
  {
    id: 'Outgoing Local Payment Over Amount',
    name: 'Outgoing Local Payment Over Amount',
    label: 'over',
    type: 'select',
    defaultSorting: false,
    validation: true,
  },
  {
    id: 'Outgoing Local Payment Over',
    name: 'Outgoing Local Payment Over',
    label: 'Currency',
    type: 'currency',
    labelClassName: 'currency-icon hide-label-text',
    validation: true,
  },
  {
    id: 'Outgoing Local Payment Over %',
    name: 'Outgoing Local Payment Over %',
    label: '%',
    type: 'percent',
    validation: true,
  },
  {
    id: 'outgoingLocalPayment',
    name: 'outgoingLocalPayment',
    label: 'Outgoing Local Payment',
    type: 'currency',
    validation: true,
  },
  {
    id: 'outgoingCrossborder',
    name: 'outgoingCrossborder',
    label: 'Outgoing Crossborder',
    type: 'currency',
    validation: true,
  },
  {
    id: 'Monthly fee',
    name: 'Monthly fee',
    label: 'Monthly Fee',
    type: 'currency',
    labelClassName: 'currency-icon hide-label-text',
    validation: true,
  },
  {
    id: 'Recall Fee',
    name: 'Recall Fee',
    label: 'Recall Fee',
    type: 'currency',
    labelClassName: 'currency-icon hide-label-text',
    validation: true,
  },
  {
    id: 'Set up fee',
    name: 'Set up fee',
    label: 'Set up fee',
    type: 'currency',
    labelClassName: 'currency-icon hide-label-text',
    validation: true,
  },
  {
    id: 'SWIFT Confirmation Request',
    name: 'SWIFT Confirmation Request',
    label: 'SWIFT Confirmation Request',
    type: 'currency',
    labelClassName: 'currency-icon hide-label-text',
    validation: true,
  },
];

export const readOnlyFeeFields = addReadOnlyProperties(newFeeFields);

export const newFeeValidationSchema = yup.object({
  pricingTemplate: yup.string()
    .required('Required!'),
  correspondentBankCharge: yup.number()
    .required('Required!'),
  fxFee: yup.number()
    .required('Required!'),
  incomingLocalPayment: yup.number()
    .required('Required!'),
  incomingCrossborder: yup.number()
    .required('Required!'),
  ['Monthly fee']: yup.number()
    .required('Required!'),
  outgoingCrossborder: yup.number()
    .required('Required!'),
  outgoingSEPA: yup.number()
    .required('Required!'),
  ['Recall Fee']: yup.number()
    .required('Required!'),
  ['Set up fee']: yup.number()
    .required('Required!'),
  ['SWIFT Confirmation Request']: yup.number()
    .required('Required!'),
  ['Urgent Transfer Fee']: yup.number()
    .required('Required!'),
});
