import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { notifChangeUserAccount } from '../../helpers/notificatios';

import userActions from '../../actions/user';
import Tooltip from '../../components/generic/Tooltip';

const {
  getTokenForChangingUser,
} = userActions.user;

const ChangeUserFormatter = ({
  subRole, id, userName, changeUser,
}) => {
  const confirmModal = () => toastr.confirm(notifChangeUserAccount(userName), {
    onOk: () => changeUser(id),
  });

  return (
    <If condition={subRole === 'SuperAdmin'}>
      <i
        className="iconfont-switch-user switch-user-icon"
        onClick={confirmModal}
        data-tip={`impersonate-${id}`}
        data-for={`impersonate-${id}`}
      />
      <Tooltip id={`impersonate-${id}`} text="Impersonate" />
    </If>
  );
};

const mapStateToProps = state => ({
  subRole: state.getIn(['userInfo', 'data', 'subRole']),
});

export default connect(mapStateToProps, {
  changeUser: getTokenForChangingUser.action,
})(ChangeUserFormatter);

ChangeUserFormatter.propTypes = {
  subRole: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  changeUser: PropTypes.func.isRequired,
};
