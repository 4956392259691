import React from 'react';
import { customFilter, textFilter } from 'react-bootstrap-table2-filter';

import CustomSelectFilter from '../../../components/CustomTable/CustomFilter';
import SelectionFeesChargesFormatterContainer
  from '../../../containers/Modals/SelectionFeesChargesFormatterContainer';

import selectionFeesChargesFormatterHeaderContainer
  from '../../../containers/Modals/SelectionFeesChargesFormatterHeaderContainer';
import {
  sortHeaderFormatter,
  dateFormatter,
  priceFormatter,
  feesChargesStatusFormatter,
  defaultFormatter,
  feesChargesIdFormatter,
} from '../../tableUtils/tablesUtils';
import customSelectionFilter from '../../customSelectionFilter';
import { convertDateToLocaleFormat } from '../../../helpers/dates';
import { currencyFormatter } from '../../../helpers/other';
import { selectUniqValues } from '../../../helpers/status';

const feesChargesColumns = (currentData) => {
  const feeChargesStatuses = selectUniqValues(currentData, 'status');
  const feeTypes = selectUniqValues(currentData, 'feeName');

  return (
    [
      {
        dataField: 'id',
        text: 'Id',
        sort: true,
        formatter: feesChargesIdFormatter,
        headerFormatter: sortHeaderFormatter,
        headerStyle: () => ({ width: '15%' }),
        wpx: 200,
      },
      {
        dataField: 'processDate',
        text: 'Process Date',
        sort: true,
        formatter: (cell, { id }) => dateFormatter(cell, id),
        headerFormatter: sortHeaderFormatter,
        headerStyle: () => ({ width: '6%' }),
        transformationItem: convertDateToLocaleFormat,
        wpx: 120,
      },
      {
        dataField: 'postDate',
        text: 'Post Date',
        sort: true,
        formatter: (cell, { id }) => dateFormatter(cell, `${id}_for_create_date`),
        headerFormatter: sortHeaderFormatter,
        headerStyle: () => ({ width: '6%' }),
        transformationItem: convertDateToLocaleFormat,
        wpx: 120,
      },
      {
        dataField: 'accountName',
        text: 'Account',
        sort: true,
        classes: 'main-field',
        formatter: defaultFormatter,
        headerFormatter: sortHeaderFormatter,
        headerStyle: () => ({ width: '9%' }),
        wpx: 140,
      },
      {
        dataField: 'accountCurrency',
        text: 'Account Currency',
        sort: true,
        filter: textFilter(),
        formatter: defaultFormatter,
        headerFormatter: sortHeaderFormatter,
        headerStyle: () => ({ width: '6%' }),
        wpx: 80,
      },
      {
        dataField: 'amount',
        text: 'Amount',
        sort: true,
        formatter: priceFormatter,
        headerFormatter: sortHeaderFormatter,
        headerStyle: () => ({ width: '5%' }),
        wpx: 80,
        transformationItem: currencyFormatter,
      },
      {
        dataField: 'feeName',
        text: 'Fee',
        sort: true,
        headerClasses: 'with-filter',
        formatter: defaultFormatter,
        headerStyle: () => ({ width: '8%' }),
        filter: customFilter({
          onFilter: (filterObj, list) => customSelectionFilter(filterObj, list, 'feeName'),
        }),
        filterRenderer: (onFilter, column) => (
          <CustomSelectFilter
            onFilter={onFilter}
            column={column}
            selectItems={feeTypes}
            formClass="custom-filter-fee"
          />
        ),
        wpx: 120,
      },
      {
        dataField: 'status',
        text: 'Status',
        sort: true,
        headerClasses: 'with-filter',
        classes: 'status',
        headerStyle: () => ({ width: '7%' }),
        formatter: feesChargesStatusFormatter,
        filter: customFilter({
          onFilter: (filterObj, data) => customSelectionFilter(filterObj, data, 'status'),
        }),
        filterRenderer: (onFilter, column) => (
          <CustomSelectFilter
            onFilter={onFilter}
            column={column}
            selectItems={feeChargesStatuses}
            formClass="custom-filter-status"
          />
        ),
        wpx: 60,
      },
      {
        dataField: 'processTransaction',
        text: 'processTransaction',
        headerClasses: 'selection-cell-header',
        classes: 'selection-cell',
        headerStyle: () => ({ width: '8%' }),
        headerFormatter: () => selectionFeesChargesFormatterHeaderContainer(currentData),
        formatter: SelectionFeesChargesFormatterContainer,
        hidden: currentData && currentData.every(it => !(it && it.status === 'Pending' && it.isVerified)),
        isExport: false,
      },
    ]
  );
};

export default feesChargesColumns;
