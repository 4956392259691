import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import ListPage from '../../../components/Pages/ListPage';
import TransactionsReportHeader from '../../Headers/TransactionsReportHeader';

const mapStateToProps = (state) => {
  const currentState = state.getIn(['reports', 'failedOutsidePreSetTransactions']);
  return {
    data: currentState,
    header: TransactionsReportHeader,
    title: 'Out of Limits Transactions',
    defaultSorted: [{
      dataField: 'exceptionCreationDate',
      order: 'desc',
    }],
  };
};

export default connect(mapStateToProps)(withImmutablePropsToJS(ListPage));
