import { combineActions, handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import { combineReducers } from 'redux-immutable';

import listsActions from '../actions/lists';
import userActions from '../actions/user';
import { moneyGradation, ledgerType } from '../lists/lists';
import feesActions from '../actions/fees';
import beneficiariesActions from '../actions/beneficiaries';
import dashboardActions from '../actions/dashboard/dashboardActions';
import accountsActions from '../actions/accounts';

const { clearOneAccount } = accountsActions.accounts;
const {
  clearTransactionsDashboard,
} = dashboardActions;

const {
  clearOneBeneficiary,
} = beneficiariesActions.beneficiaries;

const { logout } = userActions.user;

const {
  updateFeeTemplate,
  getFeesByLedgerId,
  changeFeesFieldsForLedgerById,
  changeFeesTemplateForLedgerById,
} = feesActions.fees;

const {
  getDocumentTypes,
  getBanks,
  getBeneficiariesList,
  getLedgerStatuses,
  getFeesTypes,
  getCountries,
  getSepaCountries,
  getCurrencies,
  getCustomersList,
  getTransactionStatuses,
  getRoutingTypes,
  getChargeTypes,
  getConversionFixedTypes,
  getEnduserTypes,
  getIntegrationStatuses,
  getTransactionTypes,
  getFees,
  getFeesTemplates,
  getLedgerTypes,
  getMoneyGradations,
  getLedgers,
  getChargeFees,
  getTransactionFees,
  getIndustryTypes,
  getTypesOfOwnership,
  getBusinessTypes,
  getBatchPlatforms,
  clearLists,
} = listsActions.lists;

const listsStateHelper = listName => ({
  // data: getListFromStorage(listName),
  // data: listName,
  data: {},
  error: false,
});

const initialState = fromJS({
  banks: listsStateHelper(),
  beneficiaries: listsStateHelper(),
  feesTypes: listsStateHelper(),
  ledgerStatuses: listsStateHelper(),
  currencies: listsStateHelper(),
  countries: listsStateHelper(),
  sepaCountries: listsStateHelper(),
  customers: listsStateHelper(),
  transactionStatuses: listsStateHelper(),
  routingTypes: listsStateHelper(),
  chargeTypesList: listsStateHelper(),
  conversionFixedTypes: listsStateHelper(),
  enduserTypes: listsStateHelper(),
  integrationStatuses: listsStateHelper(),
  transactionTypes: listsStateHelper(),
  fees: listsStateHelper(),
  ledgers: listsStateHelper(),
  documentTypes: listsStateHelper(),
  chargeFees: listsStateHelper(),
  transactionFees: listsStateHelper(),
  feesTemplates: listsStateHelper(),
  industryTypes: listsStateHelper(),
  typesOfOwnership: listsStateHelper(),
  businessTypes: listsStateHelper(),
  batchPlatforms: listsStateHelper(),
  currentFees: {
    data: {},
    error: false,
  },
  ledgerType: {
    data: ledgerType,
    error: false,
  },
  moneyGradation: {
    data: moneyGradation,
    error: false,
  },
});

const listsReducerHelper = (listName, action) => handleActions(
  {
    [action.error]: state => state.set('error', true),
    [action.success]: (state, { payload }) => state
      .set('data', fromJS(payload)),
    [logout]: () => initialState.get(listName),
    [clearLists]: (state, { payload = [] }) => {
      return payload.find(list => list === listName) ? initialState.get(listName) : state;
    },
  },
  initialState.get(listName),
);

const ledgersReducer = handleActions(
  {
    [getLedgers.error]: state => state.set('error', true),
    [getLedgers.success]: (state, { payload }) => state
      .set('data', fromJS(payload)),
    [combineActions(
      // clearOneAccount,
      clearTransactionsDashboard,
      logout,
      // clearOneAccount,
    )]: () => initialState.get('ledgers'),
    [clearLists]: (state, { payload = [] }) => {
      return payload.find(list => list === 'ledgers') ? initialState.get('ledgers') : state;
    },
  },

  initialState.get('ledgers'),
);

const currenciesReducer = handleActions(
  {
    [getCurrencies.error]: state => state.set('error', true),
    [getCurrencies.success]: (state, { payload }) => state
      .set('data', fromJS(payload)),
    [combineActions(
      clearTransactionsDashboard,
      logout,
      // clearOneAccount,
    )]: () => initialState.get('currencies'),
    [clearLists]: (state, { payload = [] }) => {
      return payload.find(list => list === 'currencies') ? initialState.get('currencies') : state;
    },
  },

  initialState.get('currencies'),
);

const beneficiariesReducer = handleActions(
  {
    [getBeneficiariesList.error]: state => state.set('error', true),
    [getBeneficiariesList.success]: (state, { payload }) => state
      .set('data', fromJS(payload)),
    [combineActions(
      logout,
      // clearOneBeneficiary,
    )]: () => initialState.get('beneficiaries'),
    [clearLists]: (state, { payload = [] }) => {
      return payload.find(list => list === 'beneficiaries') ? initialState.get('beneficiaries') : state;
    },
  },
  initialState.get('beneficiaries'),
);

const customersReducer = handleActions(
  {
    [getCustomersList.error]: state => state.set('error', true),
    [getCustomersList.success]: (state, { payload }) => state
      .set('data', fromJS(payload)),
    [combineActions(
      // clearOneBeneficiary,
      logout,
    )]: () => initialState.get('customers'),
    [clearLists]: (state, { payload = [] }) => {
      return payload.find(list => list === 'customers') ? initialState.get('customers') : state;
    },
  },
  initialState.get('customers'),
);

const banksReducer = listsReducerHelper('banks', getBanks);

const feesTypesReducer = listsReducerHelper('feesTypes', getFeesTypes);

const ledgerStatusesReducer = listsReducerHelper('ledgerStatuses', getLedgerStatuses);

const countriesReducer = listsReducerHelper('countries', getCountries);
const sepaCountriesReducer = listsReducerHelper('sepaCountries', getSepaCountries);

const feesReducer = listsReducerHelper('fees', getFees);
const transactionFeesReducer = listsReducerHelper('transactionFees', getTransactionFees);
const chargeFeesReducer = listsReducerHelper('chargeFees', getChargeFees);
const industryTypesReducer = listsReducerHelper('industryTypes', getIndustryTypes);
const businessTypesReducer = listsReducerHelper('businessTypes', getBusinessTypes);
const documentTypesReducer = listsReducerHelper('documentTypes', getDocumentTypes);
const batchPlatformsReducer = listsReducerHelper('batchPlatforms', getBatchPlatforms);
const feesTemplatesReducer = handleActions(
  {
    [getFeesTemplates.success]: (state, { payload }) => state.set('data', fromJS(payload)),
    [getFeesTemplates.error]: state => state.set('error', true),
    [updateFeeTemplate.success]: (state, {
      payload: {
        templateId,
        feeName,
        rate,
      },
    }) => state.setIn(['data', 'feesRates', templateId, feeName], fromJS(rate)),
    [clearLists]: (state, { payload = [] }) => {
      return payload.find(list => list === 'feesTemplates') ? initialState.get('feesTemplates') : state;
    },
  },
  initialState.get('feesTemplates'),
);

const currentFeesReducer = handleActions(
  {
    [getFeesByLedgerId.error]: state => state.set('error', true),
    [getFeesByLedgerId.success]: (state, { payload }) => state
      .set('data', fromJS(payload)),
    [changeFeesTemplateForLedgerById.success]: (state, { payload: { templateRateDictionary } }) => state
      .set('data', fromJS(templateRateDictionary)),
    [changeFeesFieldsForLedgerById.success]: (state, { payload: { feeName, feeValue } }) => {
      return state.setIn(['data', feeName], fromJS(feeValue));
    },
    [clearLists]: (state, { payload = [] }) => {
      return payload.find(list => list === 'currentFees') ? initialState.get('currentFees') : state;
    },
  },
  initialState.get('currentFees'),
);

const transactionStatusesReducer = listsReducerHelper('transactionStatuses', getTransactionStatuses);
const transactionTypesReducer = listsReducerHelper('transactionTypes', getTransactionTypes);
const routingTypesReducer = listsReducerHelper('routingTypes', getRoutingTypes);
const chargeTypesListReducer = listsReducerHelper('chargeTypesList', getChargeTypes);
const conversionFixedTypesReducer = listsReducerHelper('conversionFixedTypes', getConversionFixedTypes);
const enduserTypesReducer = listsReducerHelper('enduserTypes', getEnduserTypes);
const integrationStatusesReducer = listsReducerHelper('integrationStatuses', getIntegrationStatuses);
const ledgerTypeReducer = listsReducerHelper('ledgerType', getLedgerTypes);
const moneyGradationReducer = listsReducerHelper('moneyGradation', getMoneyGradations);
const typesOfOwnershipReducer = listsReducerHelper('typesOfOwnership', getTypesOfOwnership);

export default combineReducers({
  banks: banksReducer,
  businessTypes: businessTypesReducer,
  beneficiaries: beneficiariesReducer,
  documentTypes: documentTypesReducer,
  transactionStatuses: transactionStatusesReducer,
  routingTypes: routingTypesReducer,
  currencies: currenciesReducer,
  countries: countriesReducer,
  sepaCountries: sepaCountriesReducer,
  fees: feesReducer,
  transactionFees: transactionFeesReducer,
  chargeFees: chargeFeesReducer,
  feesTemplates: feesTemplatesReducer,
  customers: customersReducer,
  feesTypes: feesTypesReducer,
  chargeTypesList: chargeTypesListReducer,
  conversionFixedTypes: conversionFixedTypesReducer,
  ledgerStatuses: ledgerStatusesReducer,
  enduserTypes: enduserTypesReducer,
  integrationStatuses: integrationStatusesReducer,
  transactionTypes: transactionTypesReducer,
  ledgerTypes: ledgerTypeReducer,
  moneyGradation: moneyGradationReducer,
  ledgers: ledgersReducer,
  currentFees: currentFeesReducer,
  industryTypes: industryTypesReducer,
  typesOfOwnership: typesOfOwnershipReducer,
  batchPlatforms: batchPlatformsReducer,
});
