import { toastr } from 'react-redux-toastr';

import {
  GET_JOURNAL,
  GET_ONE_JOURNAL,
  EDIT_ONE_JOURNAL,
} from '../../constants/constants';

import { apiCallAction } from '../actionHelpers';
import { apiCallActionHandler } from '../commonLogic/requestLogic';
import { notifEditJournal } from '../../helpers/notificatios';


const actions = {
  getJournal: apiCallAction(GET_JOURNAL),
  getOneJournal: apiCallAction(GET_ONE_JOURNAL),
  editOneJournal: apiCallAction(EDIT_ONE_JOURNAL),
};

const getJournalLogic = apiCallActionHandler({
  name: 'getJournal',
  fetchAction: actions.getJournal,
  makeApiCall(payload, { api }) {
    return api.journal.getJournal(payload);
  },
  transformResponse(response) {
    if (response && response.data) {
      return response.data.map(({ isCharge, ...item }) => {
        return {
          ...item,
          isCharge: isCharge ? 'Charge' : 'Transaction',
        };
      });
    }

    return response;
  },
});

const getOneJournalLogic = apiCallActionHandler({
  name: 'getOneJournal',
  fetchAction: actions.getOneJournal,
  makeApiCall(payload, { api }) {
    return api.journal.getOneJournal(payload);
  },
});

const editOneJournalLogic = apiCallActionHandler({
  name: 'editOneJournal',
  fetchAction: actions.editOneJournal,
  makeApiCall(payload, { api }) {
    return api.journal.editOneJournal(payload);
  },
  onSuccess() {
    toastr.success(
      'Success',
      notifEditJournal,
    );
  },
  transformResponse(response) {
    if (response && response.data) {
      return {
        ...response.data,
        isCharge: response.data.isCharge ? 'Charge' : 'Transaction',
      };
    }

    return response;
  },
});

export const logics = [
  getJournalLogic,
  getOneJournalLogic,
  editOneJournalLogic,
];

export default actions;
