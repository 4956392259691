import {
  makeSecureRequestWithFormData,
  makeSecureRequest,
  makeRequest,
  apiDownloadFile,
} from './apiCallHandlers/index';

export default () => ({
  uploadDocument: ({ documentTypeId, content: formFile, description = '', dispatchProgress }) => {
    return makeSecureRequestWithFormData('post', 'Registration/Document/Upload',
      {
        documentTypeId,
        formFile,
        description,
      }, null, dispatchProgress);
  },
  deleteDocument: (id) => makeSecureRequest('delete', `Registration/Document/${id}`),
  downloadDocument: (id) => apiDownloadFile('get', `Registration/Document/${id}/Download`),
  userRegistration: (data = {}) => makeRequest('post', 'User', data),
  createContact: () => makeSecureRequest('post', 'Registration/CreateContact'),
  deleteContact: (id) => makeSecureRequest('delete', `Registration/DeleteContact/${id}`),
  getRegistrationInfo: () => makeSecureRequest('get', 'Registration'),
  updateRegistrationInfo: (payload) => makeSecureRequest('put', 'Registration', payload),
  confirmRegistration: () => makeSecureRequest('post', 'Registration/Confirm'),
});
