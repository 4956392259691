import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import transactionsActions from '../../../actions/transactions';
import UniversalModalButton from '../../../containers/Modals/UniversalModalButton';
import Tooltip from '../../generic/Tooltip';

const { downloadInvoice } = transactionsActions.transactions;

const InvoiceComponent = (props) => {
  const { isInvoice, id, getInvoice } = props;

  return (
    <div className="invoice">
      <Choose>
        <When condition={isInvoice}>
          <span>Transaction invoice</span>
          <i
            className="iconfont-download-file upload_document clickable"
            onClick={() => getInvoice(id)}
          />
          <span
            data-tip={`info-description-${id}`}
            data-for={`info-description-${id}`}
          >
            <UniversalModalButton
              modalId="transaction-invoice"
              inlineButton
              iconClasses="icons pdf iconfont-pdf clickable"
              customIcon
              chosenItemId={id}
              width={1142}
            />
          </span>
          <Tooltip id={`info-description-${id}`} text="Preview the invoice" />
        </When>
        <Otherwise>
          <span>Invoice is not uploaded</span>
        </Otherwise>
      </Choose>
    </div>
  );
};

InvoiceComponent.defaultProps = {
  isInvoice: false,
};

InvoiceComponent.propTypes = {
  isInvoice: PropTypes.bool,
  getInvoice: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const { hasInvoice = false } = state.getIn(['transactions', 'details', 'data'])
    .toJS();

  return {
    isInvoice: hasInvoice,
  };
};

export default connect(mapStateToProps, {
  getInvoice: downloadInvoice.action,
})(withImmutablePropsToJS(InvoiceComponent));
