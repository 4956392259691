import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import PasswordForm from '../../components/Auth/PasswordForm';
import {
  newPassFields,
  newPassValidationSchema,
} from '../../components/Auth/LoginFormConfig';
import userActions from '../../actions/user';

const { resetPassword } = userActions.user;

const mapStateToProps = state => ({
  titleForm: 'Create a new Password',
  validationSchema: newPassValidationSchema,
  fields: newPassFields,
  submitBtnText: 'Create',
  formId: 'create-pass-form',
  fetching: state.getIn(['userInfo', 'fetching']),
});

const mapDispatchToProps = dispatch => ({
  submitForm: options => dispatch(resetPassword.action(options)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withImmutablePropsToJS(PasswordForm));
