import React, { useState, useEffect, memo } from 'react';
import { map } from 'lodash';

import InputsWrapper from './InputsWrapper';

const InputsHelper = ({
  fields,
  errors,
  values,
  lists,
  touched,
  onBlurPost,
  propsData,
  handleChange,
  setValues,
  setFieldValue,
  fetchingFields,
  ...props
}) => {
  const [correctFields, setCorrectFields] = useState(fields);

  useEffect(() => {
    setCorrectFields(fields);
  }, [fields]);

  return (
    map(correctFields, ({
      id,
      name,
      dependedFunctionForFields = null,
      dependedFunctionForValues = null,
      ...rest
    }) => {
      const key = name;
      const value = values[name];
      const errorText = errors[name];
      let dropdownList = [];
      if (lists) {
        dropdownList = lists[id];
      }

      const setNewValues = (newValues) => {
        Object.keys(newValues).forEach(fieldName => setFieldValue(fieldName, newValues[fieldName]));
      };

      const onChange = (e) => {
        if (dependedFunctionForFields != null) {
          setCorrectFields(dependedFunctionForFields(correctFields, e.target.value, lists));
        }
        if (dependedFunctionForValues != null) {
          dependedFunctionForValues(setNewValues, e, lists, values);
        } else {
          handleChange(e);
        }
      };

      return (
        <InputsWrapper
          id={id}
          key={key}
          value={value}
          values={values}
          error={touched[name] && errorText}
          name={name}
          propsValue={propsData[key]}
          isInvalid={!!errorText}
          dropdownList={dropdownList}
          lists={lists}
          onBlurPost={onBlurPost}
          isOnBlur={onBlurPost}
          handleChange={onChange}
          setValues={setNewValues}
          fetching={fetchingFields.includes(name)}
          {...props}
          {...rest}
        />
      );
    })
  );
};

InputsHelper.defaultProps = {
  propsData: {},
};

export default memo(InputsHelper);
