import React from 'react';

const SuccessfulSetup = () => (
  <div className='account-setup successful' >
    <h2>ACCOUNT SETUP</h2>

    <div className="account-setup_successful-setup">
      YOUR APPLICATION HAS BEEN SENT TO OUR COMPLIANCE TEAM FOR VERIFICATION AND APPROVAL.
      IF YOU NEED ANY FURTHER ASSISTANCE OR HAVE ANY QUESTIONS PLEASE DON'T HESITATE TO
      CONTACT US AT CUSTOMERCARE@WIREBLOOM.COM
    </div>
  </div>
);

export default SuccessfulSetup;
