import { combineReducers } from 'redux-immutable';
import { reducer as toastr } from 'react-redux-toastr';
import userInfo from './userInfoReducer';
import users from './usersReducer';
import adminUsers from './adminUsersReducer';
import accounts from './accountsReducer';
import journal from './journalReducer';
import transactions from './transactionsReducer';
import userActivity from './userActivityReducer';
import beneficiaries from './beneficiariesReducer';
import lists from './listsReducer';
import search from './search';
import reports from './reportsReducer';
import modal from './modal';
import fees from './feesReducer';
import feesCharges from './feesChargesReducer';
import processingErrors from './processingErrorsReducer';
import registration from './registrationReducer';
import progressBar from './progress';
import customers from './customersReducer';
import messages from './messagesReducer';
import dashboard from './dashboardReducer';

const rootReducer = combineReducers({
  userInfo,
  users,
  adminUsers,
  transactions,
  userActivity,
  beneficiaries,
  accounts,
  lists,
  toastr,
  journal,
  search,
  reports,
  modal,
  fees,
  feesCharges,
  processingErrors,
  registration,
  progressBar,
  customers,
  messages,
  dashboard,
});

export default rootReducer;
