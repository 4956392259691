import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import Tooltip from '../../components/generic/Tooltip';
import ResetPasswordFormatterForUser from './ResetPasswordFormatterForUser';
import SearchContainer from '../../containers/Modals/SearchContainer';
import ChangeUserFormatter from './ChangeUserFormatter';

const UserEmailFormatter = ({
  userRole,
  cell,
  fields: {
    id,
    email,
    role,
    firstName,
    lastName,
  },
}) => {
  const maxLength = 37;

  const editCell = cell.length > maxLength
    ? `${cell.slice(0, maxLength - 2)}...`
    : cell;

  const cellId = `reset-password-${id}`;

  return (
    <div className="reset-password-formatter with-cell">
      <If condition={userRole === 'Admin'}>
        <NavLink to={`/users/activity/${id}`} activeClassName="active">
          <i
            className="journal-icon iconfont-journal"
            data-tip="journal-icon"
            data-for={`users-activity-${id}`}
          />
          <Tooltip id={`users-activity-${id}`} text="Activity" />
        </NavLink>
      </If>
      <div
        data-tip="reset-password"
        data-for={cellId}
        className="reset-password-formatter__email"
      >
        <SearchContainer text={editCell} />
      </div>
      <If condition={cell.length > maxLength}>
        <Tooltip id={cellId} text={cell} />
      </If>
      <ResetPasswordFormatterForUser email={email} id={id} />
      <ChangeUserFormatter id={id} userName={`${firstName} ${lastName}`} />
    </div>
  );
};

export default memo(UserEmailFormatter);

UserEmailFormatter.defaultProps = {
  fields: {
    id: '',
  },
  cell: '',
};

UserEmailFormatter.propTypes = {
  cell: PropTypes.string,
  userRole: PropTypes.string.isRequired,
  fields: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
  ])),
};
