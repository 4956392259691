import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router';
import SecretRoute from './SecretRoute';

const RouteWrapper = ({
  path, exact, component, secret,
}) => {
  return (
    <Choose>
      <When condition={secret}>
        <SecretRoute
          path={path}
          exact={exact}
          component={component}
        />
      </When>
      <Otherwise>
        <Route
          path={path}
          exact={exact}
          component={component}
        />
      </Otherwise>
    </Choose>
  );
};

RouteWrapper.defaultProps = {
  exact: false,
  secret: false,
  component: null,
};

RouteWrapper.propTypes = {
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  secret: PropTypes.bool,
  component: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.element,
    PropTypes.object,
  ]),
};

export default RouteWrapper;
