import React, { useState, memo, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import CustomSpinner from '../Spinners/CustomSpinner';
import UniversalInputsForm from '../generic/UniversalInputsForm';
import Header from '../Layouts/Header';
import InputsWrapper from '../generic/InputsWrapper';

import getModalFormFields from '../../lib/getModalFormFields';
import { readOnlyFeeFields } from '../../schemes/forms/newFee';

const PricingPage = (props) => {
  const {
    header,
    path,
    formId,
    fetching,
    defaultTemplate,
    updateFeeTemplate,
    globalLists: {
      feesTemplates: {
        data: {
          feesTemplates,
          feesRates,
        },
      },
    },
    globalLists,
    subRole,
  } = props;

  const [template, setTemplate] = useState(defaultTemplate);
  const {
    fields, multiFields, validationSchema, lists,
  } = useMemo(() => getModalFormFields(formId, globalLists), [formId, globalLists]);

  useEffect(() => {
    setTemplate(defaultTemplate);
  }, [defaultTemplate]);

  const onBlurPost = useCallback(({ name, value }) => {
    updateFeeTemplate({
      templateId: template,
      feeName: name,
      rate: value,
    });
  }, [template]);

  const correctFields = subRole !== 'ReadOnlyAdmin'
    ? fields
    : readOnlyFeeFields;

  const HeaderTools = header;

  return (
    <div className={`full-height main_padding ${formId}`}>
      <Header>
        {HeaderTools && <HeaderTools path={path} />}
      </Header>

      <div className={`forms-page pricing-page ${fetching ? 'spinner' : ''}`}>
        <CustomSpinner fetching={fetching} />
        <div
          className={`custom-modal ${!fetching ? 'content--show' : 'content--hide'}`}
        >
          <If
            condition={feesTemplates && !isEmpty(feesTemplates) && feesRates && !isEmpty(feesRates)}
          >
            <div className="select-template-input">
              <InputsWrapper
                name="pricingTemplate"
                value={template || ''}
                dropdownList={feesTemplates}
                type="select"
                label="Pricing Template"
                // TODO: check children reRender and use the memo
                handleChange={e => setTemplate(e.target.value)}
                withLabel
                placeholder="Select Pricing Template"
              />
            </div>
            <UniversalInputsForm
              onBlurPost={onBlurPost}
              formId={formId}
              fields={correctFields}
              validationSchema={validationSchema}
              multiFields={multiFields}
              withLabel
              propsData={feesRates[template]}
              lists={lists}
              {...props}
            />
          </If>
        </div>
      </div>
    </div>
  );
};

PricingPage.defaultProps = {
  path: 'Admin / Pricing',
  globalLists: {
    feesTemplates: {
      data: {
        feesTemplates: {},
        feesRates: {},
      },
    },
  },
  formId: 'create-fee',
  fetching: false,
  defaultTemplate: '',
  dropdownList: {},
  feesRates: {},
};

PricingPage.propTypes = {
  path: PropTypes.string,
  formId: PropTypes.string,
  subRole: PropTypes.string.isRequired,
  updateFeeTemplate: PropTypes.func.isRequired,
  fetching: PropTypes.bool,
  header: PropTypes.func.isRequired,
  defaultTemplate: PropTypes.string,
  dropdownList: PropTypes.shape({}),
  feesRates: PropTypes.shape({}),
  globalLists: PropTypes.shape({
    feesTemplates: PropTypes.shape({
      data: PropTypes.shape({
        feesTemplates: PropTypes.shape({}),
        feesRates: PropTypes.shape({}),
      }),
    }),
  }),
};

export default memo(PricingPage);
