import jsPDF from 'jspdf';
import { isEmpty } from 'lodash';
import { greenLogo } from './base64img';
import { convertDateToLocaleFormat } from './dates';
import { currencyFormatter } from './other';

export const generateMonthlyStatementPdfFile = ({
  openingBalance,
  closingBalance,
  debitSum,
  creditSum,
  ibanNumber,
  transactions = [],
  currency,
  ledgerName,
  periodFrom,
  periodTo,
  address,
  chargesSum,
}) => {
  const doc = jsPDF();

  doc.addImage(greenLogo, 'PNG', 10, 5);

  doc.line(10, 20, 200, 20);

  const startDate = convertDateToLocaleFormat(periodFrom, 'MMM D, YYYY');
  const endDate = convertDateToLocaleFormat(periodTo, 'MMM D, YYYY');
  const period = `${startDate} - ${endDate}`;
  doc.setFontSize(20).text('Monthly Statement', 10, 30);
  doc.setFontSize(15).setTextColor(165, 165, 166).text('Period:', 199 - doc.getTextWidth(period), 30, { align: 'right' });
  doc.setFontSize(15).setTextColor(0).text(period, 200, 30, { align: 'right' });

  doc.setFontSize(13).setTextColor(165, 165, 166).text('Client:', 10, 40);
  doc.setFontSize(13).setTextColor(0).text(`${ledgerName}`, 24, 40);

  doc.setFontSize(13).setTextColor(165, 165, 166).text('Address:', 10, 47);
  doc.setFontSize(13).setTextColor(0).text(`${address}`, 29, 47);

  doc.setFontSize(13).setTextColor(165, 165, 166).text('IBAN Account No:', 10, 54);
  doc.setFontSize(13).setTextColor(0).text(`${ibanNumber}`, 48, 54);

  doc.setFontSize(13).setTextColor(165, 165, 166).text('Currency:', 10, 61);
  doc.setFontSize(13).setTextColor(0).text(`${currency}`, 31, 61);

  const reportTable = {
    headers: ['Opening Balance', 'Closing Balance', 'Credit Turnover', 'Debit Turnover', 'Charge'],
    body: [
      [openingBalance, closingBalance, creditSum, debitSum, chargesSum],
    ],
  };

  doc.autoTable({
    startY: 64,
    margin: 10,
    styles: {
      valign: 'middle',
      halign: 'center',
      fontSize: 10,
      minCellWidth: 20,
    },
    headStyles: {
      fillColor: [0, 157, 0],
    },
    columnStyles: {
      2: { textColor: [255, 0, 0] },
      3: { textColor: [0, 172, 0] },
    },
    bodyStyles: {
      textColor: 0,
    },
    head: [reportTable.headers],
    body: reportTable.body,
    didParseCell: ({ column, cell, section }) => {
      // set two decimal places
      if (section === 'body' && cell.raw) {
        if (column.dataKey > 0 && cell.raw) {
          cell.text = [currencyFormatter(cell.raw)];
        }
      }
    },
  });

  const { finalY } = doc.lastAutoTable;

  if (isEmpty(transactions)) {
    doc.setFontSize(13).text('No current account transactions', 105, finalY + 10, { align: 'center' });
  } else {
    const operationHeaders = [
      { header: 'Date', dataKey: 'date' },
      { header: 'Type', dataKey: 'type' },
      { header: 'Reference', dataKey: 'reference' },
      { header: 'Amount', dataKey: 'amount' },
      { header: 'Balance', dataKey: 'balance' },
    ];

    const operationBody = transactions.map(({
      postDate, reference, amount, balance, type,
    }) => [convertDateToLocaleFormat(postDate, 'DD.MM.YYYY'), type, reference, amount, balance]);

    doc.autoTable({
      startY: finalY + 10,
      margin: 10,
      theme: 'striped',
      styles: {
        valign: 'middle',
        halign: 'left',
        fontSize: 10,
        minCellWidth: 20,
      },
      columnStyles: {
        date: { cellWidth: 25 },
        type: { cellWidth: 40 },
        amount: { cellWidth: 25 },
        balance: { cellWidth: 25 },
      },
      headStyles: {
        textColor: [165, 165, 166],
        fillColor: 255,
      },
      bodyStyles: {
        textColor: 0,
      },
      columns: operationHeaders,
      body: operationBody,
      didParseCell: ({ column, cell, section }) => {
        // set color for positive and negative amount
        if (column.dataKey === 'amount' && section === 'body') {
          if (cell.raw >= 0) {
            cell.styles.textColor = [0, 172, 0];
          } else {
            cell.styles.textColor = [255, 0, 0];
          }
        }

        // set the balance to the center
        if (column.dataKey === 'balance') {
          cell.styles.halign = 'right';
        }

        // set two decimal places
        if (section === 'body' && cell.raw) {
          if (column.dataKey === 'amount' || column.dataKey === 'balance') {
            cell.text = [currencyFormatter(cell.raw)];
          }
        }
      },
    });
  }

  return doc;
};
