import React from 'react';
import PropTypes from 'prop-types';

const RenderLegend = ({
  payload, onChangeFunc, hidingItems, markerType,
}) => {
  const handleChange = (value) => {
    onChangeFunc(value);
  };

  return (
    <ul className="recharts-legend">
      {
        payload.map((entry, index) => (
          <li key={`item-${index}`} className="recharts-legend__item">
            <Choose>
              <When condition={markerType === 'line'}>
                <span
                  className="recharts-legend__item__marker--line"
                  style={{ borderColor: entry.color }}
                />
              </When>
              <Otherwise>
                <span
                  className="recharts-legend__item__marker"
                  style={{ backgroundColor: entry.color }}
                />
              </Otherwise>
            </Choose>
            <span
              className="recharts-legend__item__value"
            >
              {entry.value}
            </span>
            <input
              className="recharts-legend__item__checkbox"
              type="checkbox"
              onChange={() => handleChange(entry.value)}
              checked={!hidingItems.includes(entry.value)}
            />
          </li>
        ))
      }
    </ul>
  );
};

RenderLegend.defaultProps = {
  markerType: '',
};

RenderLegend.propTypes = {
  payload: PropTypes.arrayOf(
    PropTypes.object,
  ).isRequired,
  markerType: PropTypes.string,
  onChangeFunc: PropTypes.func.isRequired,
  hidingItems: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default RenderLegend;
