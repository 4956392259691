import React, { useEffect, memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isEqual } from 'lodash';

import SideMenu from './SideMenu/SideMenu';
import UniversalModalContainer from '../../containers/Modals/UniversalModalContainer';
import Content from './Content';
import AccountSetupContainer from '../../containers/Pages/AccountSetup/AccountSetupContainer';
import RegistrationSubUser from '../Auth/RegistrationSubUser';

const MainLayout = ({
  isAuthTokenExpired,
  refreshSession,
  userInfo: {
    subRole,
    role,
    exp,
    Status,
  },
  isRefreshTokenTimer,
  isModalOpen,
}) => {
  const refreshSessionAfterTimeout = () => {
    const timestamp = moment(exp * 1000)
      .subtract({ minutes: 1 });
    const difference = timestamp.diff(moment(), 'milliseconds');

    console.info(
      'Auth token will refresh in',
      timestamp.format('DD.MM.YYYY HH:mm:ss'),
    );

    setTimeout(refreshSession, difference);
  };

  useEffect(() => {
    if (isAuthTokenExpired) {
      refreshSession();
    } else {
      refreshSessionAfterTimeout();
    }
  }, []);

  useEffect(() => {
    if (isRefreshTokenTimer) {
      refreshSessionAfterTimeout();
    }
  }, [isRefreshTokenTimer]);

  return (
    <>
      <Choose>
        <When condition={subRole === 'ProcessingUser'}>
          <RegistrationSubUser />
        </When>
        <Otherwise>
          <If condition={Status === '6'}>
            <div className="main-block">
              <SideMenu />
              <Content
                role={role}
                subRole={subRole}
              />
            </div>
          </If>
          <If condition={Status === '2'}>
            <AccountSetupContainer />
          </If>
        </Otherwise>
      </Choose>
      {/*<UniversalModalContainer />*/}
      <If condition={isModalOpen}>
        <UniversalModalContainer />
      </If>
    </>
  );
};

MainLayout.defaultProps = {
  isModalOpen: false,
};

MainLayout.propTypes = {
  isRefreshTokenTimer: PropTypes.bool.isRequired,
  refreshSession: PropTypes.func.isRequired,
  isAuthTokenExpired: PropTypes.bool.isRequired,
  userInfo: PropTypes.shape(
    {
      role: PropTypes.string.isRequired,
      subRole: PropTypes.string.isRequired,
      exp: PropTypes.number.isRequired,
      Status: PropTypes.string.isRequired,
    },
  ).isRequired,
  isModalOpen: PropTypes.bool,
};

const propsAreEqual = ({ userInfo, isModalOpen, isAuthTokenExpired, isRefreshTokenTimer,
}, nextProps) => {
  return (isEqual(userInfo, nextProps.userInfo) && isEqual(isModalOpen, nextProps.isModalOpen) && isEqual(isAuthTokenExpired, nextProps.isAuthTokenExpired) && isEqual(isRefreshTokenTimer, nextProps.isRefreshTokenTimer))
};

export default memo(MainLayout);
