import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import UniversalInputForm from '../generic/UniversalInputsForm';
import { loginFields, loginValidationSchema } from './LoginFormConfig';
import Button from '../generic/Button';
import CustomSpinner from '../Spinners/CustomSpinner';
import logo from '../../../img/bloom-auth.png';

const LoginForm = ({ login, fetching }) => {
  const formId = 'basic-form';

  return (
    <>
      <CustomSpinner fetching={fetching} fullDimScreen />
      <div className="auth-form auth-form--login">
        <div className="auth_logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="auth-form__content">
          <div className="auth-form__title">
            Welcome to WireBloom
          </div>
          <UniversalInputForm
            validationSchema={loginValidationSchema}
            fields={loginFields}
            onSubmit={values => login(values)}
            withLabel
            formId={formId}
          />
          <div className="links-wrapper">
            <NavLink to="/auth/forgot-password" activeClassName="active">
              Forgot Password?
            </NavLink>
          </div>
          <div className="auth-form__submit-button">
            <Button
              color="gray"
              type="primary"
              size="lg"
              text="LogIn"
              form={formId}
              submit
            />
            <NavLink to="/auth/registration">
              <Button
                color="gray"
                type="primary"
                size="lg"
                text="Register"
              />
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

LoginForm.propTypes = {
  login: PropTypes.func.isRequired,
};

export default LoginForm;
