import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import ListPage from '../../../components/Pages/ListPage';
import withLists from '../../../components/HOC/withLists';
import CustomersHeader from '../../Headers/CustomersHeader';

const mapStateToProps = (state) => {
  const currentState = state.getIn(['customers', 'list']);

  return {
    data: currentState,
    header: CustomersHeader,
    title: 'Customers',
    defaultSorted: [{
      dataField: 'createdDate',
      order: 'desc',
    }],
  };
};

export default connect(mapStateToProps)(withLists(withImmutablePropsToJS(ListPage)));
