import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

const CustomToggle = ({
  checked,
  onChange,
  readOnly,
}) => {
  const changeStatus = (e) => {
    onChange(e.target.checked);
  };

  return (
    <Choose>
      <When condition={readOnly}>
        <input
          className="basic-form__group__block__control input"
          readOnly
          value={checked ? 'Transaction' : 'Charge'}
        />
      </When>
      <Otherwise>
        <div className="switch-toggle modal_form">
          <div className="switch-toggle__content">
            <label className="first-switch" htmlFor="custom-switch">Transaction</label>
            <Form.Check
              type="switch"
              id="custom-switch"
              label="Charge"
              checked={checked}
              onChange={changeStatus}
            />
          </div>
        </div>
      </Otherwise>
    </Choose>
  );
};

export default memo(CustomToggle);

CustomToggle.defaultProps = {
  checked: false,
  readOnly: false,
};

CustomToggle.propTypes = {
  checked: PropTypes.bool,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};
