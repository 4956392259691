import React, { memo, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { GoogleMap, Marker, withGoogleMap } from 'react-google-maps';
import config from 'config';
import PropTypes from 'prop-types';

import Button from '../generic/Button';
import getLocationData from '../../helpers/parseGeoLocation';

const { GOOGLE_MAP_URL, DEFAULT_MAP_ZOOM } = config;

const MapComponent = ({ position, setPosition, zoom }) => {
  const map = useRef();

  const handleMapClick = async (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();

    setPosition({ lat, lng });
  };

  return (
    <GoogleMap
      ref={map}
      defaultZoom={DEFAULT_MAP_ZOOM}
      zoom={zoom}
      defaultCenter={position}
      onClick={handleMapClick}
    >
      <Marker position={position} />
    </GoogleMap>
  )
};

const WrapperMap = withGoogleMap(MapComponent);


const ModalGeoMap = ({
  isModalOpen,
  setGeoModalVisibility,
  position,
  setPosition,
  changeLocationData,
  zoom,
}) => {

  const handleClose = () => setGeoModalVisibility(false);

  const handleSubmit = async () => {
    handleClose();
    const { lat, lng } = position;
    const locationData = await getLocationData(lat, lng);
    await changeLocationData(locationData);
  };

  return (
    <Modal
      show={isModalOpen}
      onHide={handleClose}
      className="custom-modal geo-modal"
      centered
    >
      <Modal.Header closeButton>
        <h3 className="custom-modal__title">Choose location</h3>
      </Modal.Header>
      <Modal.Body>
        <WrapperMap
          position={position}
          setPosition={setPosition}
          googleMapURL={GOOGLE_MAP_URL}
          containerElement={<div className="map-container" />}
          loadingElement={<div />}
          mapElement={<div className="map" />}
          zoom={zoom}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={handleSubmit}
          color="green"
          type="primary"
          size="lg"
          text="Confirm"
        />
        <Button
          onClick={handleClose}
          color="gray"
          type="primary"
          size="lg"
          text="Close"
        />
      </Modal.Footer>
    </Modal>
  );
};

MapComponent.propTypes = {
  position: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }).isRequired,
  setPosition: PropTypes.func.isRequired,
  zoom: PropTypes.number.isRequired,
};

ModalGeoMap.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  setGeoModalVisibility: PropTypes.func.isRequired,
  position: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }).isRequired,
  setPosition: PropTypes.func.isRequired,
  changeLocationData: PropTypes.func.isRequired,
  zoom: PropTypes.number.isRequired,
};

export default memo(ModalGeoMap);
